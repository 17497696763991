import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

import MainLayout from 'components/layout/main';
import QuestionMarkIconSmall from 'assets/icons/QuestionMarkIconSmall';
import { useMeRole } from 'store/hooks/useMeRole';
import FacilityGroupCasingsFilter from './components/FacilityGroupCasingsFilter';
import { useFacilityGroupTitle } from '../facilityGroup/hooks/useFacilityGroupTitle';
import useFacilityGroupMenu from '../../utils/hooks/headerMenu/useFacilityGroupMenu';
import { useFacilityGroupBreadcrumbs } from '../facilityGroup/hooks/useFacilityGroupBreadcrumbs';
import { FacilityGroupCasingsSorting, Role, SortingWay } from 'generated/graphql';
import { useParams } from 'react-router';
import CasingRow from './components/сasingRow';
import HeaderItem from './components/сasingRow/headerItem';
import Tooltip from 'antd/lib/tooltip';
import { useFacilityGroupCasingsQuery } from './api/queries/generated/FacilityGroupCasings';
import { ScrollSyncPane } from 'react-scroll-sync';
import CustomPagination from 'ui/pagination';
import SkeletonLayout from 'components/table/Skeleton';
import { facilityGroupCasingsColumns } from 'components/table/columns';

const FacilityGroupCasings = () => {
  const router = useParams();
  const id = router.id;
  const isMeAdminOrSuper = useMeRole([Role.Admin, Role.Supervisor]);
  const title = useFacilityGroupTitle();
  const headerMenu = useFacilityGroupMenu();
  const breadcrumbs = useFacilityGroupBreadcrumbs();
  const [params] = useSearchParams();

  const facilityIdParam = params.get('facilityId');
  const cityParam = params.get('cityId');
  const positionIdParam = params.get('positionId');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const searchParam = params.get('search');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const { data, loading } = useFacilityGroupCasingsQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        facilityGroupId: id || '',
        facilityId: facilityIdParam,
        cityId: cityParam,
        positionId: positionIdParam,
        sortBy: sortBy as FacilityGroupCasingsSorting,
        sortWay: sortWay as SortingWay,
        page,
        searchQuery: searchParam,
        size,
      },
    },
  });
  const dataPagination = data?.facilityGroupCasings;
  const dataCasings = dataPagination?.casings;
  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadcrumbs}>
      <FacilityGroupCasingsFilter />
      <div className="add_profession border rounded-lg shadow-smena bg-smena_white pb-4 overflow-auto my-6">
        <div
          className={clsx(
            'grid border-b px-6 border-smena_gray-30 Subtitle2 text-smena_text-secondary w-fit add_profession-group',
          )}
        >
          <span className="pt-3 pb-6 pr-5 mt-3">
            <HeaderItem
              item={{
                name: 'Профессия',
                id: FacilityGroupCasingsSorting.Position,
                sort: true,
              }}
            />
          </span>
          <span className="pt-3 pb-6 pr-5 mt-3">
            <HeaderItem
              item={{
                name: 'Объект',
                id: FacilityGroupCasingsSorting.Facility,
                sort: true,
              }}
            />
          </span>
          <span className="pt-3 pb-6 pr-5 mt-3">
            <HeaderItem
              item={{
                name: 'Город',
                id: FacilityGroupCasingsSorting.City,
                sort: true,
              }}
            />
          </span>
          <span className="pt-3 pb-6 pr-5 mt-3">Тип оплаты</span>
          <span className="pt-3 pb-6 pr-5 mt-3">Ед. измерения</span>
          <span className="pt-3 pb-6 pr-5 mt-3">Ставка, ₽</span>
          <span className="pt-3 pb-6 pl-4 pr-4 ml-2 mr-2 mt-3 rounded-t-lg bg-smena_bb-background_base">
            Ставка с НПД, ₽
          </span>
          <span className="pt-3 pb-6 pr-5 mt-3">Проб. п.</span>
          <div className="flex gap-x-1 items-start pt-3 pb-6 pr-5 mt-3">
            <span>Кол-во дней</span>
            <Tooltip
              title={
                'Пробный период считается с даты первой назначенной смены. В стажировочном периоде может быть любое количество смен и все они будут считаться стажировочными.'
              }
            >
              <button className="relative">
                <QuestionMarkIconSmall className={'text-primary'} />
              </button>
            </Tooltip>
          </div>
          <span className="pt-3 pb-6 mt-3">Ставка п.п., ₽</span>
          <span className="pt-3 pb-6 pl-4 pr-4 mt-3 rounded-t-lg bg-smena_bb-background_base ml-2 mr-2">
            Ставка п.п. с НПД, ₽
          </span>
          <span className="pt-3 pb-6 mt-3">Мед. книжка</span>
          {isMeAdminOrSuper && (
            <span
              className={clsx(
                'pt-3 pb-6 pl-4 mt-3 rounded-t-lg',
                isMeAdminOrSuper ? 'bg-smena_bb-background_base' : '',
              )}
            >
              Действия
            </span>
          )}
        </div>

        {loading ? (
          <SkeletonLayout columns={facilityGroupCasingsColumns} />
        ) : dataCasings?.length ? (
          <ScrollSyncPane>
            <>{dataCasings?.map(casing => <CasingRow key={casing.positionId + casing.facilityId} casing={casing} />)}</>
          </ScrollSyncPane>
        ) : (
          <div className="flex justify-center my-5">
            <h3 className="text-smena_text Body1">
              По вашему запросу <span className="font-bold">{searchParam}</span> ничего не нашлось
            </h3>
          </div>
        )}
      </div>
      {dataPagination && <CustomPagination currentPage={page} total={dataPagination.pages} />}
    </MainLayout>
  );
};

export default FacilityGroupCasings;
