import { ShiftStage } from 'generated/graphql';

export const getStatus = (status: ShiftStage | string) => {
  const statusList = [
    {
      status: ShiftStage.New,
      statusRu: 'Новая, ожидает подтверждения',
    },
    {
      status: ShiftStage.Accepted,
      statusRu: 'Подтверждена, запланирована',
    },
    {
      status: ShiftStage.Working,
      statusRu: 'В работе',
    },
    {
      status: ShiftStage.Finished,
      statusRu: 'Завершена',
    },
    {
      status: ShiftStage.Wasted,
      statusRu: 'Не выполнена',
    },
    {
      status: ShiftStage.Ineligible,
      statusRu: 'Отклонена',
    },
    {
      status: ShiftStage.Refused,
      statusRu: 'Отменена',
    },
  ];

  return statusList.find(item => status === item.status)?.statusRu;
};
