import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { WorkerFacilityWorkPostsFragmentDoc } from '../../../../user/api/fragments/generated/WorkerFacilityWorkPosts.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnassignedWorkerFromFacilityMutationVariables = Types.Exact<{
  input: Types.UnassignWorkerFromFacilityInput;
}>;


export type UnassignedWorkerFromFacilityMutation = { __typename?: 'Mutation', unassignWorkerFromFacility: Array<{ __typename?: 'WorkerFacilityWorkPostsOutput', workposts: Array<{ __typename?: 'WorkPostExperienceOutput', assignedAt: any, positionId: string, facilityId: string, userId: string, status: Types.WorkPostStatus, userExperience: Array<{ __typename?: 'UserExperience', assignedAt?: any | null, unassignAt?: any | null }> }> }> };


export const UnassignedWorkerFromFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnassignedWorkerFromFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UnassignWorkerFromFacilityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unassignWorkerFromFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WorkerFacilityWorkPosts"}}]}}]}},...WorkerFacilityWorkPostsFragmentDoc.definitions]} as unknown as DocumentNode;
export type UnassignedWorkerFromFacilityMutationFn = Apollo.MutationFunction<UnassignedWorkerFromFacilityMutation, UnassignedWorkerFromFacilityMutationVariables>;

/**
 * __useUnassignedWorkerFromFacilityMutation__
 *
 * To run a mutation, you first call `useUnassignedWorkerFromFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignedWorkerFromFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignedWorkerFromFacilityMutation, { data, loading, error }] = useUnassignedWorkerFromFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignedWorkerFromFacilityMutation(baseOptions?: Apollo.MutationHookOptions<UnassignedWorkerFromFacilityMutation, UnassignedWorkerFromFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignedWorkerFromFacilityMutation, UnassignedWorkerFromFacilityMutationVariables>(UnassignedWorkerFromFacilityDocument, options);
      }
export type UnassignedWorkerFromFacilityMutationHookResult = ReturnType<typeof useUnassignedWorkerFromFacilityMutation>;
export type UnassignedWorkerFromFacilityMutationResult = Apollo.MutationResult<UnassignedWorkerFromFacilityMutation>;
export type UnassignedWorkerFromFacilityMutationOptions = Apollo.BaseMutationOptions<UnassignedWorkerFromFacilityMutation, UnassignedWorkerFromFacilityMutationVariables>;