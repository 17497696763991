import { useGetReferralProgramQuery } from 'pages/settingLeads/api/generated/GetReferralProgram';
import { useParams } from 'react-router';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';
import { getReferralProgramStatus } from 'utils/helpers/get/getReferralProgramStatus';
import { getReferralProgramStatusColor } from 'utils/helpers/get/getReferralProgramStatusColor';
import Modal from 'ui/modal';
import { useState } from 'react';
import { useArchiveReferralProgramMutation } from 'pages/settingLeads/api/generated/ArchiveReferralProgram';
import { Link } from 'react-router-dom';
import { useGetSchedulerReferralProgramsLazyQuery } from 'pages/settingLeads/api/generated/GetSchedulerReferralPrograms';

const Information = () => {
  const { settingId } = useParams();
  const [archiveReferralProgram] = useArchiveReferralProgramMutation();

  const [archiveModalVisible, setArchiveModalVisible] = useState(false);

  const { data, refetch } = useGetReferralProgramQuery({ variables: { input: { id: settingId ?? '' } } });
  const [getReferralPrograms, { refetch: refetchReferralPrograms }] = useGetSchedulerReferralProgramsLazyQuery({
    variables: { input: { referralProgramId: settingId ?? '' } },
  });

  console.log(getReferralPrograms);

  const handleArchiveReferralProgram = () => {
    archiveReferralProgram({ variables: { input: { id: settingId ?? '' } } }).then(() => {
      setArchiveModalVisible(false);
      refetch();
      refetchReferralPrograms();
    });
  };

  return (
    <div className="blockTemplate mb-6">
      <div className="flex justify-between items-end">
        <div>
          <h3 className="blockTitleTemplate mb-5">Информация</h3>
          <div className="flex gap-6">
            <div>
              <div className="Subtitle2 mb-2">Статус</div>
              <div
                className={`${getReferralProgramStatusColor(data?.getReferralProgram.currentReferralProgram.status)} rounded-2xl pr-3 pl-3 pt-0.5 pb-0.5`}
              >
                {getReferralProgramStatus(data?.getReferralProgram.currentReferralProgram.status)}
              </div>
            </div>

            <div>
              <div className="Subtitle2 mb-2">Область действия</div>
              <div>
                {data?.getReferralProgram.currentReferralProgram.facilityGroup ? (
                  <Link to={`/groups/${data?.getReferralProgram.currentReferralProgram.facilityGroup.id}`}>
                    Группа:{' '}
                    <span className="text-primary">
                      {data?.getReferralProgram.currentReferralProgram.facilityGroup.name}
                    </span>
                  </Link>
                ) : null}

                {data?.getReferralProgram.currentReferralProgram.facility ? (
                  <Link to={`/facilities/${data?.getReferralProgram.currentReferralProgram.facility.id}`}>
                    Объект:{' '}
                    <span className="text-primary">
                      {data?.getReferralProgram.currentReferralProgram.facility.name}
                    </span>
                  </Link>
                ) : null}
              </div>
            </div>

            <div>
              <div className="Subtitle2 mb-2">Период</div>
              <div>
                {data?.getReferralProgram.currentReferralProgram?.startDatetime
                  ? dayjs(data?.getReferralProgram.currentReferralProgram?.startDatetime).format(
                      `${RU_DATE} ${TIME_DATE}`,
                    )
                  : null}
                {data?.getReferralProgram.currentReferralProgram?.endDatetime
                  ? dayjs(data?.getReferralProgram.currentReferralProgram.endDatetime).format(
                      ` - ${RU_DATE} ${TIME_DATE}`,
                    )
                  : ' - Бессрочно'}
              </div>
            </div>

            <div>
              <div className="Subtitle2 mb-2">Следующее изменение</div>
              <div>
                {data?.getReferralProgram.nextReferralProgram?.startDatetime
                  ? dayjs(data?.getReferralProgram.nextReferralProgram?.startDatetime).format(`${RU_DATE} ${TIME_DATE}`)
                  : null}

                {data?.getReferralProgram.nextReferralProgram?.startDatetime &&
                data?.getReferralProgram.nextReferralProgram.endDatetime
                  ? dayjs(data?.getReferralProgram.nextReferralProgram?.endDatetime).format(
                      ` - ${RU_DATE} ${TIME_DATE}`,
                    )
                  : data?.getReferralProgram.nextReferralProgram?.startDatetime &&
                      !data?.getReferralProgram.nextReferralProgram.endDatetime
                    ? ' - Бессрочно'
                    : null}
              </div>
            </div>
          </div>
        </div>

        {data?.getReferralProgram.currentReferralProgram.status !== 'ARCHIVE' && (
          <button className="btn-reject" onClick={() => setArchiveModalVisible(true)}>
            Архивировать реферальную программу
          </button>
        )}
      </div>

      <Modal
        open={archiveModalVisible}
        onCancel={() => setArchiveModalVisible(false)}
        onOk={handleArchiveReferralProgram}
        type="delete"
        title="Архивировать реферальную
программу"
        okText="Архивировать"
      >
        Вы уверены, что хотите архивировать реферальную программу?
      </Modal>
    </div>
  );
};

export default Information;
