import { useMemo } from 'react';
import useSupervisorFacilities from 'utils/hooks/userCalendar/useSuperFacilities';
import { useUserShifts } from './useUserShifts';

const useSupervisorShifts = () => {
  const { userShifts } = useUserShifts();
  const supervisorFacilities = useSupervisorFacilities();
  return useMemo(
    () =>
      userShifts?.filter(shift =>
        supervisorFacilities?.some(superFac => superFac.facility?.id === shift?.facility?.id)
      ),
    [userShifts, supervisorFacilities]
  );
};

export default useSupervisorShifts;
