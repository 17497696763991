import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import { StoryFragment } from 'pages/story/api/fragments/generated/Strory.fragment';
import { getImagePath } from 'utils/helpers/get/getImagePath';
import { StoryStage } from 'generated/graphql';
import { convertNumber } from 'utils/helpers/convertNumber';
import More from './more';
import { getPushRepeatRu } from 'pages/pushes/utils/helpers/getPushRepeatRu';
import { getStoryStatus } from 'utils/helpers/get/getStoryStatus';

export const useStoriesTable = (data: StoryFragment[]) => {
  const isStoryPublished = (story: StoryFragment) => story.stage === StoryStage.Published;
  const isStoryRemoved = (story: StoryFragment) => story.stage === StoryStage.Removed;
  const columns: ColumnDef<StoryFragment>[] = useMemo(() => {
    return [
      {
        id: 'publication',
        header: 'Публикация',
        minSize: 340,
        cell: ({ row: { original } }) => {
          const icon = original.icon;
          return (
            <div className={'flex items-center gap-x-6'}>
              <div
                className={clsx('w-[75px] h-[75px] bg-cover bg-no-repeat bg-center rounded-lg')}
                style={
                  icon
                    ? {
                        backgroundImage: `url(${getImagePath(icon?.path)}`,
                      }
                    : {
                        backgroundColor: original.iconBackgroundColor,
                      }
                }
              ></div>
              <div className={'flex flex-col gap-y-2'}>
                <Link
                  to={
                    isStoryPublished(original) || isStoryRemoved(original)
                      ? `/stories/${original.id}/publish`
                      : `/stories/${original.id}`
                  }
                  className={'Subtitle1'}
                >
                  {original.iconTitle}
                </Link>
                <div className={'flex gap-x-1 Body2'}>
                  <span className={'text-smena_text-secondary'}>Кол-во слайдов:</span>
                  <span className={'text-smena_text-primary'}>{original.storySlides?.length}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: 'status',
        header: 'Статус',
        size: 200,
        cell: ({ row: { original } }) => {
          const { name, className } = getStoryStatus(original.stage);
          return <div className={clsx(className, 'rounded-full inline-flex px-2 py-[2px] Table-small')}>{name}</div>;
        },
      },
      {
        id: 'viewers',
        header: 'Просмотры',
        size: 140,
        cell: ({ row: { original } }) => (
          <div className={clsx('Body2')}>{original.viewsCount ? convertNumber(original.viewsCount) : '-'}</div>
        ),
      },
      {
        id: 'receivers',
        header: 'получатели',
        size: 140,
        cell: ({ row: { original } }) => (
          <div className={clsx('Body2')}>
            {original.publicCondition?.recipientsCount ? convertNumber(original.publicCondition.recipientsCount) : '-'}
          </div>
        ),
      },
      {
        id: 'publishFrom',
        header: 'Дата и время отправки',
        size: 240,
        cell: ({ row: { original } }) => (
          <div className={'flex gap-x-1 Body2'}>
            <span className={'text-smena_text-secondary'}>Начало:</span>
            <span className={'text-smena_text-primary'}>
              {original.publicCondition?.publishFrom
                ? dayjs(original.publicCondition?.publishFrom).format(`${RU_DATE} ${TIME_DATE}`)
                : null}
            </span>
          </div>
        ),
      },
      {
        id: 'interval',
        header: 'Повтор',
        size: 105,
        cell: ({ row: { original } }) => (
          <div className={clsx('Body2')}>{getPushRepeatRu(original.publicCondition?.interval)}</div>
        ),
      },
      {
        id: 'erp',
        header: 'ERP',
        size: 135,
        cell: ({ row: { original } }) => (
          <>
            <div className={'flex gap-x-1 Body2'}>
              <span className={'text-smena_text-secondary'}>ID источника:</span>
              <span className={'text-smena_text-primary'}>{original.erpSourceId || '-'}</span>
            </div>
            <div className={'flex gap-x-1 Body2'}>
              <span className={'text-smena_text-secondary'}>ID группы:</span>
              <span className={'text-smena_text-primary'}>{original.erpGroupId || '-'}</span>
            </div>
          </>
        ),
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 105,
        cell: ({ row: { original } }) => <More story={original} />,
      },
    ];
  }, []);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    meta: {
      // inviteFriendDate: true,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};
