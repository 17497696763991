import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ReportFragmentDoc } from '../../fragments/generated/Report.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTimesheetByMonthAndFacilityMutationVariables = Types.Exact<{
  input: Types.CreateTimesheetByMonthAndFacilityInput;
}>;


export type CreateTimesheetByMonthAndFacilityMutation = { __typename?: 'Mutation', createTimesheetByMonthAndFacility: { __typename?: 'Report', id: string, type: Types.ReportType, filePath: string } };


export const CreateTimesheetByMonthAndFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTimesheetByMonthAndFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateTimesheetByMonthAndFacilityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTimesheetByMonthAndFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Report"}}]}}]}},...ReportFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateTimesheetByMonthAndFacilityMutationFn = Apollo.MutationFunction<CreateTimesheetByMonthAndFacilityMutation, CreateTimesheetByMonthAndFacilityMutationVariables>;

/**
 * __useCreateTimesheetByMonthAndFacilityMutation__
 *
 * To run a mutation, you first call `useCreateTimesheetByMonthAndFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimesheetByMonthAndFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimesheetByMonthAndFacilityMutation, { data, loading, error }] = useCreateTimesheetByMonthAndFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimesheetByMonthAndFacilityMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimesheetByMonthAndFacilityMutation, CreateTimesheetByMonthAndFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimesheetByMonthAndFacilityMutation, CreateTimesheetByMonthAndFacilityMutationVariables>(CreateTimesheetByMonthAndFacilityDocument, options);
      }
export type CreateTimesheetByMonthAndFacilityMutationHookResult = ReturnType<typeof useCreateTimesheetByMonthAndFacilityMutation>;
export type CreateTimesheetByMonthAndFacilityMutationResult = Apollo.MutationResult<CreateTimesheetByMonthAndFacilityMutation>;
export type CreateTimesheetByMonthAndFacilityMutationOptions = Apollo.BaseMutationOptions<CreateTimesheetByMonthAndFacilityMutation, CreateTimesheetByMonthAndFacilityMutationVariables>;