import { DayjsOrNull } from 'interfaces/CustomTypes';
import dayjs from 'dayjs';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { useCreateRequestScheduleMutation } from '../../../../../facilityRequests/api/mutations/generated/CreateZayavkaSchedule';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { dateEndFn } from 'utils/helpers/dateEndFn';
import dateHour from '../../../../../userSchedule/components/sidebar/newSchedule/helpers/dateHour';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { ShiftTime } from 'generated/graphql';

interface InitialValues {
  positionId?: string;
  shiftStartTime: DayjsOrNull;
  shiftEndTime: DayjsOrNull;
  shiftStartDate: DayjsOrNull;
  shiftEndDate: DayjsOrNull;
  lunchDuration: number;
  countSchedules?: number;
  timesOfDay?: ShiftTime;
  scheduleType?: string;
}

export const useCreateRequestScheduleForm = (setShowCreateGraphicSidebar: (value: boolean) => void) => {
  const { request, id } = useCurrentRequest();
  const { twoXTwo } = useGetScheduleTypes();

  const [createRequestSchedule, { loading }] = useCreateRequestScheduleMutation();
  const initialValues: InitialValues = {
    positionId: request?.plans.length ? request.plans[0].position.id : undefined,
    shiftStartTime: request?.dateFrom ? dayjs(request.dateFrom).startOf('day') : null,
    shiftEndTime: request?.dateTo ? dayjs(request.dateTo).startOf('day') : null,
    shiftStartDate: request?.dateFrom ? dayjs(request.dateFrom) : null,
    shiftEndDate: request?.dateTo ? dayjs(request.dateTo) : null,
    lunchDuration: 0,
    countSchedules: undefined,
    timesOfDay: ShiftTime.Day,
    scheduleType: twoXTwo,
  };

  const validate = (values: InitialValues) => {
    const errors: any = {};
    if (!values.positionId) {
      errors.positionId = 'Обязательное поле';
    }
    if (!values.shiftStartTime) {
      errors.shiftStartTime = 'Обязательное поле';
    }
    if (!values.shiftEndTime) {
      errors.shiftEndTime = 'Обязательное поле';
    }
    if (!values.shiftStartDate) {
      errors.shiftStartDate = 'Обязательное поле';
    }
    if (!values.shiftEndDate) {
      errors.shiftEndDate = 'Обязательное поле';
    }
    if (!values.scheduleType) {
      errors.scheduleType = 'Обязательное поле';
    }
    if (values.countSchedules) {
      if (values.countSchedules < 1) {
        errors.countSchedules = 'Поле не должно быть меньше 1';
      } else if (values.countSchedules > 100) {
        errors.countSchedules = 'Поле не должно быть больше 100';
      }
    }
    return errors;
  };

  const onSubmit = (values: InitialValues) => {
    const facilityId = request?.facilityId;
    if (!facilityId) return;
    if (!id) return;
    const dateStart = dateHour(values.shiftStartDate, values.shiftStartTime);
    const dateEnd = dateEndFn({
      date: values.shiftEndDate,
      endTime: values.shiftEndTime,
      startTime: values.shiftStartTime,
    });
    const shiftEndDateVariable = dateEndFn({
      date: values.shiftStartDate,
      startTime: dateHour(values.shiftStartDate, values.shiftStartTime),
      endTime: dateHour(values.shiftStartDate, values.shiftEndTime),
    });

    createRequestSchedule({
      variables: {
        input: {
          dateStart,
          dateEnd,
          facilityId,
          positionId: values.positionId!,
          zayavkaId: id,
          lunchDuration: values.lunchDuration,
          dayInterleavingId: values.scheduleType,
          shiftEndDate: shiftEndDateVariable,
          countSchedules: values.countSchedules || undefined,
          timesOfDay: values.timesOfDay,
        },
      },
    })
      .then(() => successToast('График создан'))
      .catch(e => errorToast(errorHandler(e)))
      .finally(() => setShowCreateGraphicSidebar(false));
  };

  return { initialValues, validate, onSubmit, loading };
};
