import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { VacancyFragmentDoc } from '../../fragments/generated/Vacancy.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateVacancyMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  input: Types.VacancyInput;
}>;


export type UpdateVacancyMutation = { __typename?: 'Mutation', updateVacancy: { __typename?: 'Vacancy', coverPhotoId?: string | null, id: string, facilityId: string, lunchDuration?: number | null, erpSourceId?: string | null, erpGroupId?: string | null, timeStart: string, timeEnd: string, hourRate: number, hasEducation?: boolean | null, probationPeriod?: number | null, is_active: boolean, period: Types.ShiftTime, positionId: string, documentsNotes?: string | null, requiredDocuments: Array<Types.CasingDocumentType>, section?: string | null, payout?: Types.PayoutType | null, unit?: Types.UnitType | null, isPublishCostOfShift: boolean, isRangeCost: boolean, averageCost: number, shiftCostFrom: number, shiftCostUpTo: number, payoutFrequency?: Types.PayoutFrequency | null, description?: string | null, maxAge?: number | null, minAge?: number | null, notes?: string | null, requirements?: string | null, responsibilities?: string | null, sex?: Types.Sex | null, citizenshipsIds?: Array<string> | null, transport?: string | null, workwear: boolean, coverPhoto?: { __typename?: 'File', id: string, path: string } | null, facility: { __typename?: 'Facility', name: string, cityId?: string | null, facilityGroupId?: string | null }, position?: { __typename?: 'Position', name: string } | null } };


export const UpdateVacancyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateVacancy"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VacancyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateVacancy"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Vacancy"}},{"kind":"Field","name":{"kind":"Name","value":"coverPhotoId"}},{"kind":"Field","name":{"kind":"Name","value":"coverPhoto"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]}},...VacancyFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateVacancyMutationFn = Apollo.MutationFunction<UpdateVacancyMutation, UpdateVacancyMutationVariables>;

/**
 * __useUpdateVacancyMutation__
 *
 * To run a mutation, you first call `useUpdateVacancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVacancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVacancyMutation, { data, loading, error }] = useUpdateVacancyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVacancyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVacancyMutation, UpdateVacancyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVacancyMutation, UpdateVacancyMutationVariables>(UpdateVacancyDocument, options);
      }
export type UpdateVacancyMutationHookResult = ReturnType<typeof useUpdateVacancyMutation>;
export type UpdateVacancyMutationResult = Apollo.MutationResult<UpdateVacancyMutation>;
export type UpdateVacancyMutationOptions = Apollo.BaseMutationOptions<UpdateVacancyMutation, UpdateVacancyMutationVariables>;