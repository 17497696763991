import ArmeniaIcon from 'assets/icons/flags/armeniaIcon';
import AzerbaijanIcon from 'assets/icons/flags/azerbaijan';
import BelarusIcon from 'assets/icons/flags/belarusIcon';
import GeorgiaIcon from 'assets/icons/flags/georgia';
import KazakhstanIcon from 'assets/icons/flags/kazakhstanIcon';
import KyrgystanIcon from 'assets/icons/flags/kyrgystanIcon';
import MoldovaIcon from 'assets/icons/flags/moldova';
import RussiaIcon from 'assets/icons/flags/russiaIcon';
import TajikistanIcon from 'assets/icons/flags/tajikistan';
import TurkmenistanIcon from 'assets/icons/flags/turkmenistan';
import UkraineIcon from 'assets/icons/flags/ukraine';
import UnknownIcon from 'assets/icons/flags/unknownIcon';
import UzbekistanIcon from 'assets/icons/flags/uzbekistan';
import { CountryCode } from 'libphonenumber-js';

interface Props {
  code?: CountryCode;
  name?: string;
}

const flagIcons: { [key in CountryCode]?: JSX.Element } = {
  AM: <ArmeniaIcon />,
  BY: <BelarusIcon />,
  KZ: <KazakhstanIcon />,
  KG: <KyrgystanIcon />,
  RU: <RussiaIcon />,
  UA: <UkraineIcon />,
  GE: <GeorgiaIcon />,
  UZ: <UzbekistanIcon />,
  TM: <TurkmenistanIcon />,
  MD: <MoldovaIcon />,
  TJ: <TajikistanIcon />,
  AZ: <AzerbaijanIcon />,
};

const CitizenshipWithFlag = ({ code, name }: Props) => {
  const Icon = code ? flagIcons[code] : <UnknownIcon />;

  return (
    <span className="flex items-center">
      {Icon}
      <span className="ml-2">{name || 'Не определено'}</span>
    </span>
  );
};

export default CitizenshipWithFlag;
