import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityCasingFragmentDoc } from '../../../../facilityCasings/api/fragments/generated/FacilityCasing.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilityGroupCasingsQueryVariables = Types.Exact<{
  input: Types.FacilityGroupCasingsInput;
}>;


export type FacilityGroupCasingsQuery = { __typename?: 'Query', facilityGroupCasings: { __typename?: 'PaginatedCasingsOutput', pages: number, count: number, casings: Array<{ __typename?: 'Casing', id: string, createdAt: any, facilityId: string, payout: Types.PayoutType, unit: Types.UnitType, hourRate: number, positionId: string, isSelfEmployed?: boolean | null, selfEmployedRate?: number | null, hasProbation: boolean, probationPeriod?: number | null, probationRate?: number | null, isMedBookRequired: boolean, hasEducation?: boolean | null, currentRate: { __typename?: 'CasingRateEntity', rate: number }, currentProbationRate?: { __typename?: 'CasingRateEntity', rate: number } | null, facility: { __typename?: 'Facility', name: string, cityId?: string | null, city?: { __typename?: 'City', name: string } | null }, probationRateHistory?: Array<{ __typename?: 'CasingRateEntity', rate: number, startDatetime: any }> | null, rateHistory?: Array<{ __typename?: 'CasingRateEntity', rate: number, startDatetime: any }> | null, plannedRate?: { __typename?: 'CasingRateEntity', id: string, rate: number, startDatetime: any } | null, plannedProbationRate?: { __typename?: 'CasingRateEntity', id: string, rate: number, startDatetime: any } | null }> } };


export const FacilityGroupCasingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FacilityGroupCasings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FacilityGroupCasingsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilityGroupCasings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"casings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityCasing"}},{"kind":"Field","name":{"kind":"Name","value":"currentRate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rate"}}]}},{"kind":"Field","name":{"kind":"Name","value":"currentProbationRate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rate"}}]}}]}}]}}]}},...FacilityCasingFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useFacilityGroupCasingsQuery__
 *
 * To run a query within a React component, call `useFacilityGroupCasingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityGroupCasingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityGroupCasingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFacilityGroupCasingsQuery(baseOptions: Apollo.QueryHookOptions<FacilityGroupCasingsQuery, FacilityGroupCasingsQueryVariables> & ({ variables: FacilityGroupCasingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilityGroupCasingsQuery, FacilityGroupCasingsQueryVariables>(FacilityGroupCasingsDocument, options);
      }
export function useFacilityGroupCasingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilityGroupCasingsQuery, FacilityGroupCasingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilityGroupCasingsQuery, FacilityGroupCasingsQueryVariables>(FacilityGroupCasingsDocument, options);
        }
export function useFacilityGroupCasingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FacilityGroupCasingsQuery, FacilityGroupCasingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FacilityGroupCasingsQuery, FacilityGroupCasingsQueryVariables>(FacilityGroupCasingsDocument, options);
        }
export type FacilityGroupCasingsQueryHookResult = ReturnType<typeof useFacilityGroupCasingsQuery>;
export type FacilityGroupCasingsLazyQueryHookResult = ReturnType<typeof useFacilityGroupCasingsLazyQuery>;
export type FacilityGroupCasingsSuspenseQueryHookResult = ReturnType<typeof useFacilityGroupCasingsSuspenseQuery>;
export type FacilityGroupCasingsQueryResult = Apollo.QueryResult<FacilityGroupCasingsQuery, FacilityGroupCasingsQueryVariables>;