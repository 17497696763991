import { Link, useLocation } from 'react-router-dom';
import Home from 'assets/icons/Home';
import React from 'react';

interface IBreadcrumbs {
  links?: { path: string; name: string }[];
}

const Breadcrumbs = React.memo(({ links }: IBreadcrumbs) => {
  const location = useLocation();
  const { pathname } = location;
  if (pathname === '/') {
    return <div></div>;
  }
  return (
    <div className="flex items-center breadcrumbs">
      <Link to="/" className="breadcrumb flex items-center Tag text-smena_text-secondary">
        <Home />
      </Link>
      {links?.map((link, index) => {
        const isLast = links.length - 1 === index;
        if (isLast) {
          return (
            <span className="breadcrumb flex items-center Tag text-smena_text" key={link.path}>
              {link.name}
            </span>
          );
        }
        return (
          <Link
            to={link.path ? link.path : ''}
            className="breadcrumb flex items-center Tag text-smena_text-secondary"
            key={index}
          >
            {link.name}
          </Link>
        );
      })}
    </div>
  );
});

export default Breadcrumbs;
