import { ApolloCache } from '@apollo/client';
import { ChangeFacilityStatusMutation } from '../mutations/generated/ChangeFacilityStatus';

interface Props {
  data?: ChangeFacilityStatusMutation | null;
}

export const updateFacilityCache = (cache: ApolloCache<any>, { data }: Props) => {
  if (!data) return;
  const { changeFacilityStatus } = data;
  const { is_active } = changeFacilityStatus;
  cache.modify({
    id: cache.identify(changeFacilityStatus),
    fields: {
      is_active() {
        if (!is_active) return null;
        return is_active;
      },
    },
  });
};
