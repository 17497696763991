import { promotionColumns, promotionGroupColumns } from 'components/table/columns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { mapColumns } from 'utils/helpers/mapColumns';
import clsx from 'clsx';
import ChevronRight from 'assets/icons/ChevronRight';
import { FacilityGroupFragment } from 'pages/facilityGroup/api/fragments/generated/FacilityGroup.fragment';
import { getImagePath } from 'utils/helpers/get/getImagePath';
import PromotionColumn from './PromotionColumn';
import { useGetListPromotionsForFacilityGroupLazyQuery } from 'pages/facilityGroup/api/queries/generated/GetListPromotionsForFacilityGroup';
import Promotion from './Promotion';
import Skeleton from 'antd/es/skeleton';

type Props = {
  group: FacilityGroupFragment;
};

const PromotionRow = ({ group }: Props) => {
  const [show, setShow] = useState(false);
  const imagePath = getImagePath(group.logo?.path);

  const [loadPromotions, { data, loading }] = useGetListPromotionsForFacilityGroupLazyQuery();
  const promotions = data?.getListPromotionsForFacilityGroup;

  useEffect(() => {
    if (show) {
      loadPromotions({
        variables: { input: { facilityGroupId: group.id } },
      });
    }
  }, [group.id, loadPromotions, show]);
  return (
    <div className="bg-smena_white rounded-lg relative border border-smena_gray-30">
      <div
        className="grid"
        style={{
          gridTemplateColumns: mapColumns(promotionGroupColumns),
        }}
        onClick={() => setShow(value => !value)}
      >
        <div className={promotionGroupColumns[0].className}>
          <img src={imagePath} alt="group_image" className="max-w-[24px] max-h-[24px] w-full h-full object-cover" />
          <Link to={`/groups/${group.id}`} className={'link'}>
            {group.name}
          </Link>
        </div>
        <div className={clsx(promotionGroupColumns[1].className)}>
          <ChevronRight className={clsx(show ? '-rotate-90' : 'rotate-90', 'inline-flex text-smena_text')} />
        </div>
      </div>
      {show ? (
        loading ? (
          <Skeleton active />
        ) : (
          <div className={'py-4 col-span-full'}>
            <div
              className={'grid border-b border-smena_gray-5 px-4'}
              style={{
                gridTemplateColumns: mapColumns(promotionColumns),
              }}
            >
              {promotionColumns.map(column => (
                <PromotionColumn key={column.name} column={column} />
              ))}
            </div>
            {promotions?.map((promotion, index) => <Promotion key={promotion.id} promotion={promotion} index={index} />)}
          </div>
        )
      ) : null}
    </div>
  );
};

export default PromotionRow;
