import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { NotifyLetterFragmentDoc } from '../../fragments/generated/NotifyLetter.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotifyLetterQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetNotifyLetterQuery = { __typename?: 'Query', getNotifyLetter: { __typename?: 'NotifyLetter', id: string, name: string, title: string, body?: string | null, status: Types.NotifyLetterStatus, route?: Types.PushRoute | null, updatedAt: any, publicCondition?: { __typename?: 'PublicCondition', recipientsCount: number, useCondition: boolean, pushToUnassigned: Types.PushToUnassignedFilter, id: string, indefinitely: boolean, publishFrom?: any | null, publishTo?: any | null, roles: Array<Types.Role>, interval?: number | null, cities?: Array<{ __typename?: 'City', id: string, name: string }> | null, facilities?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, facilityGroups?: Array<{ __typename?: 'FacilityGroup', id: string, name: string }> | null, positions?: Array<{ __typename?: 'Position', id: string, name: string }> | null } | null } };


export const GetNotifyLetterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNotifyLetter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getNotifyLetter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotifyLetter"}}]}}]}},...NotifyLetterFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetNotifyLetterQuery__
 *
 * To run a query within a React component, call `useGetNotifyLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotifyLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotifyLetterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotifyLetterQuery(baseOptions: Apollo.QueryHookOptions<GetNotifyLetterQuery, GetNotifyLetterQueryVariables> & ({ variables: GetNotifyLetterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotifyLetterQuery, GetNotifyLetterQueryVariables>(GetNotifyLetterDocument, options);
      }
export function useGetNotifyLetterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotifyLetterQuery, GetNotifyLetterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotifyLetterQuery, GetNotifyLetterQueryVariables>(GetNotifyLetterDocument, options);
        }
export function useGetNotifyLetterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotifyLetterQuery, GetNotifyLetterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotifyLetterQuery, GetNotifyLetterQueryVariables>(GetNotifyLetterDocument, options);
        }
export type GetNotifyLetterQueryHookResult = ReturnType<typeof useGetNotifyLetterQuery>;
export type GetNotifyLetterLazyQueryHookResult = ReturnType<typeof useGetNotifyLetterLazyQuery>;
export type GetNotifyLetterSuspenseQueryHookResult = ReturnType<typeof useGetNotifyLetterSuspenseQuery>;
export type GetNotifyLetterQueryResult = Apollo.QueryResult<GetNotifyLetterQuery, GetNotifyLetterQueryVariables>;