import { useMemo, useRef } from 'react';
import dayjs from 'dayjs';

import { isInt } from 'utils/helpers/isInt';
import InformationIcon from 'assets/icons/InformationIcon';
import { BalanceOperationType } from 'generated/graphql';
import { getBalanceOperationRu } from 'utils/helpers/get/getBalanceOperationRu';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import { convertMoney } from 'utils/helpers/convertMoney';
import { mapColumns } from 'utils/helpers/mapColumns';
import { balanceColumns } from 'components/table/columns';
import { FacilityGroupBalanceOperationFragment } from '../api/fragments/generated/FacilityGroupBalanceOperation.fragment';
import Tooltip from 'antd/lib/tooltip';

interface TableInterface {
  el: FacilityGroupBalanceOperationFragment;
}

const BalanceRow = ({ el }: TableInterface) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  const isAddition = useMemo(() => el.operation === BalanceOperationType.Addition, [el.operation]);

  return (
    <div
      className="grid table__row"
      style={{
        gridTemplateColumns: mapColumns(balanceColumns),
      }}
    >
      <div className={balanceColumns[0].className}>{getBalanceOperationRu(el.operation)}</div>
      <div className={balanceColumns[1].className}>{dayjs(el.dayAndTime).format(RU_DATE)}</div>
      <div className={balanceColumns[2].className}>{dayjs(el.dayAndTime).format(TIME_DATE)}</div>
      <div className={balanceColumns[3].className}>
        <span className={'truncate mr-2'}>{el.description || 'Нет данных'}</span>
        {el.description ? (
          <Tooltip placement={'top'} title={el.description}>
            <button ref={btnRef}>
              <InformationIcon />
            </button>
          </Tooltip>
        ) : null}
      </div>
      <div className={balanceColumns[4].className}>
        {isAddition ? (
          <span className={'text-smena_green-dark'}>{'+' + convertMoney(el.amount)}</span>
        ) : (
          <span className={'text-smena_red-dark'}>{'-' + convertMoney(el.amount)}</span>
        )}
      </div>
      <div className={balanceColumns[5].className}>
        {isInt(el.balanceAfter) > 0 ? (
          <span>{convertMoney(el.balanceAfter)}</span>
        ) : (
          <span className={'text-smena_red-dark'}>{convertMoney(el.balanceAfter)}</span>
        )}
      </div>
    </div>
  );
};

export default BalanceRow;
