import Check from 'assets/icons/check';
import { successToast } from 'utils/helpers/notify';
import SpinnerLoad from 'ui/loader';

interface ITimesheetLoader {
  loading: boolean;
  resLink: string;
}

const TimesheetLoader = ({ loading, resLink }: ITimesheetLoader) => {
  return (
    <>
      {loading ? (
        <div className="flex items-end mb-2">
          <div className="flex gap-x-1">
            <SpinnerLoad />
            <span className="Subtitle1 text-smena_text-secondary">Формируем отчет...</span>
          </div>
        </div>
      ) : resLink ? (
        <div className="flex items-center gap-x-8 mb-2">
          <div className="flex items-center gap-x-1">
            <span className="text-smena_green-dark">
              <Check />
            </span>
            <span className="Subtitle1 text-smena_text-secondary">Отчет готов!</span>
          </div>
          <a
            href={resLink}
            onClick={() => {
              successToast('Отчет успешно скачан');
            }}
            className="btn-secondary_small"
          >
            Скачать
          </a>
        </div>
      ) : null}
    </>
  );
};

export default TimesheetLoader;
