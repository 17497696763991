import { FacilityCasingFragment } from 'pages/facilityCasings/api/fragments/generated/FacilityCasing.fragment';
import { useMemo } from 'react';
import { usePositions } from '../../api/usePositions';

export type IFacilityCasings = {
  id?: string;
  name?: string;
  facilityId?: string;
  isMedBookRequired: boolean;
};

const useFacilityCasingsPositions = (facilityCasings?: FacilityCasingFragment[]): IFacilityCasings[] | undefined => {
  const { positions } = usePositions();
  return useMemo(
    () =>
      facilityCasings?.map(el => ({
        ...positions?.find(pos => pos.id === el.positionId),
        facilityId: el.facilityId,
        isMedBookRequired: el.isMedBookRequired,
      })),
    [positions, facilityCasings],
  );
};

export default useFacilityCasingsPositions;
