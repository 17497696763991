const ReferralIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7603 5.49198C14.9223 5.4005 15.1228 5.51754 15.1228 5.70359V16.2504C15.1228 16.435 14.9251 16.5521 14.7631 16.4636L8.44475 13.007C8.39833 12.9816 8.36237 12.9424 8.34088 12.896C8.48124 12.6486 8.56139 12.3625 8.56139 12.0578V9.38462C8.56139 9.25777 8.5475 9.13416 8.52117 9.01523L14.7603 5.49198ZM7.61359 7.85899C7.64963 7.83482 7.68675 7.81192 7.72492 7.79037L14.0433 4.22235C15.1773 3.58198 16.5809 4.40128 16.5809 5.70359V16.2504C16.5809 17.5425 15.197 18.3629 14.0633 17.7427L7.74496 14.2862C7.65889 14.2391 7.57796 14.1852 7.50269 14.1254L8.29722 18.916C8.45094 19.8428 7.82421 20.7188 6.89738 20.8725L6.26666 20.9771C5.33983 21.1308 4.46388 20.5041 4.31016 19.5773L3.33875 13.7202C2.57334 13.5542 2 12.8729 2 12.0578V9.38462C2 8.44513 2.76161 7.68352 3.7011 7.68352H6.86029C7.13078 7.68352 7.38652 7.74666 7.61359 7.85899ZM6.86524 9.14166C6.86359 9.14163 6.86194 9.14161 6.86029 9.14161H3.7011C3.56689 9.14161 3.45809 9.25041 3.45809 9.38462V12.0578C3.45809 12.192 3.56689 12.3008 3.7011 12.3008H6.86029L6.86028 9.27161C6.86028 9.22803 6.86195 9.18468 6.86524 9.14166ZM4.82317 13.7589H5.9639L6.85879 19.1546C6.88075 19.287 6.79121 19.4121 6.65881 19.4341L6.0281 19.5387C5.89569 19.5606 5.77056 19.4711 5.7486 19.3387L4.82317 13.7589Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7959 11.3287C17.7959 10.926 18.1223 10.5996 18.525 10.5996H20.9551C21.3578 10.5996 21.6842 10.926 21.6842 11.3287C21.6842 11.7313 21.3578 12.0577 20.9551 12.0577H18.525C18.1223 12.0577 17.7959 11.7313 17.7959 11.3287Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8938 8.29153C17.6925 7.94283 17.812 7.49695 18.1607 7.29563L20.2652 6.08056C20.6139 5.87924 21.0598 5.99872 21.2611 6.34741C21.4624 6.69611 21.343 7.14198 20.9943 7.3433L18.8897 8.55837C18.541 8.75969 18.0951 8.64022 17.8938 8.29153Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8938 14.3657C18.0951 14.017 18.541 13.8976 18.8897 14.0989L20.9942 15.314C21.3429 15.5153 21.4624 15.9612 21.2611 16.3099C21.0598 16.6586 20.6139 16.778 20.2652 16.5767L18.1606 15.3616C17.8119 15.1603 17.6925 14.7144 17.8938 14.3657Z"
      />
    </svg>
  );
};

export default ReferralIcon;
