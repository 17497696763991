import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useStore } from 'store';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import { timeDifference } from 'utils/helpers/timeDifference';
import SimpleCheckbox from 'ui/checkbox';
import FacilitySelect from './selects/FacilitySelect';
import PositionSelect from './selects/PositionSelect';
import { useMeRole } from 'store/hooks/useMeRole';
import useWorkpostFacilities from 'utils/hooks/userCalendar/useWorkpostFacilities';
import useFacilityCasingsPositions from 'utils/hooks/userCalendar/useFacilityCasingsPositions';
import useSupervisorWorkposts from 'utils/hooks/userCalendar/useSupervisorWorkposts';
import useFacilityCasingsAtCalendarHeader from 'utils/hooks/userCalendar/useFacilityCasingsAtCalendarHeader';
import Sidebar from 'pages/userSchedule/components/sidebar';
import { WarningExclamation } from 'assets/icons/WarningExclamation';
import { useFacilityCasingsLazyQuery } from 'pages/facilityCasings/api/queries/generated/FacilityCasings';
import { useCurrentUser } from 'utils/api/useUser';
import dayjs from 'dayjs';
import { Role } from 'generated/graphql';
import { useFacilityGroupById } from '../../../utils/api/useFacilityGroup';
import { useGetFacilityLazyQuery } from 'pages/userSchedule/api/queries/generated/GetFacility';

const CalendarHeader = () => {
  const { user } = useCurrentUser();
  const facilityId = useStore(state => state.facilityId);
  const [loadFacility, { data }] = useGetFacilityLazyQuery();
  const { isAuchan } = useFacilityGroupById(data?.facility.facilityGroupId);
  const {
    setFacilityId,
    setFacilityIdError,
    setWorkpostId,
    workpostId,
    setWorkpostIdError,
    hideCanceledShifts,
    setHideCanceledShifts,
    setLunchDuration,
    startScheduleTime,
    endScheduleTime,
    createScheduleMenu,
    setCreateScheduleMenu,
  } = useStore();

  const location = useLocation();
  const [loadData, { data: facilityCasingsData }] = useFacilityCasingsLazyQuery();

  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const schedulePage = location.pathname.includes('schedule');
  const workpostsPositions = user?.workposts;
  const workpostsFacilities = useWorkpostFacilities();
  const supervisorWorkposts = useSupervisorWorkposts();
  const facilityCasingsPositions = useFacilityCasingsPositions(facilityCasingsData?.facilityCasings);
  const workposts = useMemo(
    () => (isMeSupervisor && supervisorWorkposts ? supervisorWorkposts : workpostsFacilities),
    [isMeSupervisor, supervisorWorkposts, workpostsFacilities],
  );

  const facilityCasingPositions = useFacilityCasingsAtCalendarHeader(
    facilityId,
    facilityCasingsPositions,
    workpostsPositions,
  );

  const selectFacilityHandler = useCallback(
    (value: string) => {
      setFacilityId(value);
      loadData({
        variables: { facilityId: value },
      });
      setWorkpostId(undefined);
      if (value) {
        setFacilityIdError('');
      }
    },
    [loadData, setFacilityId, setWorkpostId, setFacilityIdError],
  );

  const selectWorkpostHandler = useCallback(
    (value: string) => {
      setWorkpostId(value);
      if (value) {
        setWorkpostIdError('');
      }
    },
    [setWorkpostId, setWorkpostIdError],
  );

  const hiddenCanceledHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setHideCanceledShifts(checked);
    },
    [setHideCanceledShifts],
  );

  const isMedBookInvalidValid = useMemo(() => {
    if (dayjs(user?.requisite?.medicalAttestationEnd).isValid()) {
      return (
        dayjs(user?.requisite?.medicalAttestationEnd).isAfter(dayjs()) ||
        dayjs(user?.requisite?.medicalAttestationEnd).isSame(dayjs())
      );
    }
    return dayjs(user?.requisite?.medicalInvoiceIssueDate).isValid();
  }, [user?.requisite?.medicalAttestationEnd, user?.requisite?.medicalInvoiceIssueDate]);

  const btnRef = useRef<HTMLButtonElement>(null);

  const isDisabled = useMemo(
    () => !isMedBookInvalidValid && facilityCasingPositions.find(casing => casing.id === workpostId)?.medBook,
    [facilityCasingPositions, isMedBookInvalidValid, workpostId],
  );

  useEffect(() => {
    return () => {
      setLunchDuration(0);
      setFacilityId('');
      setWorkpostId(undefined);
      setHideCanceledShifts(false);
      setFacilityIdError('');
      setWorkpostIdError('');
    };
  }, [setFacilityId, setFacilityIdError, setHideCanceledShifts, setLunchDuration, setWorkpostId, setWorkpostIdError]);

  useEffect(() => {
    if (isAuchan) setLunchDuration(lunchDurationHandle(timeDifference(startScheduleTime, endScheduleTime)));
  }, [endScheduleTime, isAuchan, setLunchDuration, startScheduleTime]);

  useEffect(() => {
    if (workposts.length === 1) {
      const firstFacilityId = workposts[0].facilityId;
      loadData({
        variables: { facilityId: firstFacilityId },
      });
      setFacilityId(firstFacilityId);
    }
  }, [loadData, setFacilityId, setWorkpostId, workposts]);

  useEffect(() => {
    if (facilityCasingPositions.length && facilityCasingPositions.length === 1) {
      const workpostId = facilityCasingPositions[0].id;
      setWorkpostId(workpostId);
    }
  }, [facilityCasingPositions, setWorkpostId]);

  useEffect(() => {
    if (!facilityId) return;
    loadFacility({
      variables: {
        id: facilityId,
      },
    });
  }, [facilityId, loadFacility]);
  return (
    <div className="flex items-end justify-between bg-smena_white p-6 rounded-lg schedule mb-5 flex-wrap shadow-smena gap-y-2">
      <div className="flex flex-wrap items-end gap-2">
        <FacilitySelect
          selectFacilityHandler={selectFacilityHandler}
          workpostsFacilities={workpostsFacilities}
          supervisorWorkposts={supervisorWorkposts}
        />
        <PositionSelect selectWorkpostHandler={selectWorkpostHandler} positionsFromCasings={facilityCasingPositions} />
        {schedulePage && (
          <SimpleCheckbox
            divClassName="flex items-center mb-1"
            label="Скрыть отмененные и отклоненные"
            onChange={hiddenCanceledHandler}
            checked={hideCanceledShifts}
            name={'hiddenCanceledShifts'}
          />
        )}
      </div>
      {isDisabled ? (
        <div className={'flex items-center gap-x-2 Caption'}>
          <WarningExclamation />
          <span className={'text-smena_red-dark'}>
            {dayjs(user?.requisite?.medicalAttestationEnd).isValid()
              ? 'Срок действия медицинской книжки истек.'
              : dayjs(user?.requisite?.medicalInvoiceIssueDate).isValid()
                ? 'Срок действия корешка истек.'
                : 'Отсутствует медицинская книжка'}
          </span>
          <Link className={'link'} to={`/users/${user?.id}/documents`}>
            Страница пользователя.
          </Link>
        </div>
      ) : null}
      {schedulePage && isMeAdminOrSupervisor && (
        <button
          type="button"
          className="btn-primary md:my-0 my-5"
          ref={btnRef}
          disabled={isDisabled}
          onClick={() => {
            setCreateScheduleMenu(!createScheduleMenu);
          }}
        >
          Добавить смены
        </button>
      )}
      <Sidebar triggerRef={btnRef} />
    </div>
  );
};

export default CalendarHeader;
