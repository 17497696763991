import { useEffect, useState } from 'react';

import NotificationBell from 'assets/icons/NotificationBell';
import NotificationSidebar from './sidebar';
import { useNewNotificationSubSubscription } from 'graphql/subscriptions/generated/NewNotificationSub';
import { useGetNotificationsLazyQuery } from 'graphql/queries/generated/GetNotifications';
import { useMe } from 'utils/api/useMe';
import { useNotificationsStore } from './store/notificationsStore';

const NotificationBlock = () => {
  const me = useMe();
  const newCount = useNotificationsStore(state => state.newCount);
  const { setNotifications, setNewCount, setReadCount } = useNotificationsStore();

  useNewNotificationSubSubscription({
    variables: {
      userId: me?.id || '',
    },
    onData: res => {
      const data = res.data.data?.newNotificationSub;
      if (data) {
        setNotifications([data]);
        setNewCount(newCount + 1);
      }
    },
  });

  const [showNotificationSidebar, setShowNotificationSidebar] = useState(false);
  const [loadNewNotifications] = useGetNotificationsLazyQuery();
  const [loadNotifications] = useGetNotificationsLazyQuery();

  useEffect(() => {
    loadNotifications({
      variables: {
        input: {
          size: 10,
          offset: 0,
          isRead: true,
        },
      },
    }).then(res => {
      const data = res.data;
      if (data?.getNotifications.notifications) {
        setNotifications(data.getNotifications.notifications);
        setReadCount(data.getNotifications.count);
      }
    });
  }, [loadNotifications, setNotifications, setReadCount]);

  useEffect(() => {
    loadNewNotifications({
      variables: {
        input: {
          size: 10,
          offset: 0,
          isRead: false,
        },
      },
    }).then(res => {
      const data = res.data;
      if (data?.getNotifications.notifications) {
        setNotifications(data.getNotifications.notifications);
        setNewCount(data.getNotifications.count);
      }
    });
  }, [loadNewNotifications, setNewCount, setNotifications]);

  return (
    <>
      <button
        className="relative"
        onClick={() => {
          setShowNotificationSidebar(value => !value);
        }}
      >
        <NotificationBell />
        {Boolean(newCount) && (
          <div className="absolute top-[5px] right-[3px] min-w-[20px] min-h-[20px] p-0.5 bg-smena_red inline-flex justify-center rounded-full border border-smena_white">
            <span title={newCount.toString()} className="Button3 font-inter text-smena_white text-center truncate">
              {newCount > 99 ? '+99' : newCount}
            </span>
          </div>
        )}
      </button>
      <NotificationSidebar
        setShowNotificationSidebar={setShowNotificationSidebar}
        showNotificationSidebar={showNotificationSidebar}
      />
    </>
  );
};

export default NotificationBlock;
