import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMeRole } from '../../../store/hooks/useMeRole';
import { useCurrentUser } from '../../api/useUser';
import { userRole } from '../../../pages/main/lib/helpers/userRole';
import { getLink } from '../../helpers/get/getLink';
import { Role } from 'generated/graphql';

export const useUserHeaderMenu = () => {
  const [params] = useSearchParams();

  const { user, userId } = useCurrentUser();

  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  const isCurrentUserWorkerOrForeman = userRole([Role.Worker, Role.Foreman], user);
  const isCurrentUserNewbie = userRole([Role.Newbie], user);

  const dateFrom = params.get('dateFrom');
  const dateTo = params.get('dateTo');
  const dateParams = dateFrom && dateTo ? `?dateFrom=${dateFrom}&dateTo=${dateTo}` : '';

  const userURL = `/users/${userId}`;
  const information = getLink(userURL);
  const create = getLink('/users/create');
  const personalData = getLink(`${userURL}/personal-data`);
  const additionalDocuments = getLink(`${userURL}/additional-documents`);
  const paymentInfo = getLink(`${userURL}/payment-info`);
  const questionnaire = getLink(`${userURL}/questionnaire`);
  const schedule = getLink(`${userURL}/schedule${dateParams}`);
  const shifts = getLink(`${userURL}/shifts${dateParams}`);

  const profileLink = userId ? information : create;

  return useMemo(() => {
    const array: { name: string; link: string; disabled?: boolean }[] = [
      {
        name: 'Профиль',
        link: profileLink,
      },
    ];
    if (isCurrentUserWorkerOrForeman || isCurrentUserNewbie) {
      array.push(
        {
          name: 'Перс. данные',
          link: personalData,
          disabled: !userId,
        },
        {
          name: 'Доп. документы',
          link: additionalDocuments,
          disabled: !userId,
        },
        {
          name: 'Платеж. данные',
          link: paymentInfo,
          disabled: !userId,
        },
        {
          name: 'Анкета',
          link: questionnaire,
        },
      );
      if (isCurrentUserWorkerOrForeman) {
        array.push({
          name: 'График',
          link: schedule,
        });
        if (isMeAdminOrSupervisor) {
          array.push({
            name: 'Учет времени',
            link: shifts,
          });
        }
      }
    }
    return array;
  }, [
    profileLink,
    isCurrentUserWorkerOrForeman,
    isCurrentUserNewbie,
    personalData,
    userId,
    additionalDocuments,
    paymentInfo,
    questionnaire,
    isMeAdminOrSupervisor,
    schedule,
    shifts,
  ]);
};
