import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNaimixObjectsQueryVariables = Types.Exact<{
  legalEntityId: Types.Scalars['String']['input'];
}>;


export type GetNaimixObjectsQuery = { __typename?: 'Query', getNaimixObjects?: Array<{ __typename?: 'NaimixObjectOutput', naimixObjectName?: string | null, naimixObjectId?: string | null }> | null };


export const GetNaimixObjectsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNaimixObjects"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"legalEntityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getNaimixObjects"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"legalEntityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"legalEntityId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"naimixObjectName"}},{"kind":"Field","name":{"kind":"Name","value":"naimixObjectId"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetNaimixObjectsQuery__
 *
 * To run a query within a React component, call `useGetNaimixObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNaimixObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNaimixObjectsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetNaimixObjectsQuery(baseOptions: Apollo.QueryHookOptions<GetNaimixObjectsQuery, GetNaimixObjectsQueryVariables> & ({ variables: GetNaimixObjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNaimixObjectsQuery, GetNaimixObjectsQueryVariables>(GetNaimixObjectsDocument, options);
      }
export function useGetNaimixObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNaimixObjectsQuery, GetNaimixObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNaimixObjectsQuery, GetNaimixObjectsQueryVariables>(GetNaimixObjectsDocument, options);
        }
export function useGetNaimixObjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNaimixObjectsQuery, GetNaimixObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNaimixObjectsQuery, GetNaimixObjectsQueryVariables>(GetNaimixObjectsDocument, options);
        }
export type GetNaimixObjectsQueryHookResult = ReturnType<typeof useGetNaimixObjectsQuery>;
export type GetNaimixObjectsLazyQueryHookResult = ReturnType<typeof useGetNaimixObjectsLazyQuery>;
export type GetNaimixObjectsSuspenseQueryHookResult = ReturnType<typeof useGetNaimixObjectsSuspenseQuery>;
export type GetNaimixObjectsQueryResult = Apollo.QueryResult<GetNaimixObjectsQuery, GetNaimixObjectsQueryVariables>;