import { NotifyLetterStatus } from 'generated/graphql';

export const getPushStatus = (status?: NotifyLetterStatus | null) => {
  const statuses = [
    {
      status: NotifyLetterStatus.New,
      statusRu: 'Черновик',
      className: 'bg-smena_white border border-smena_text-secondary text-smena_text-secondary',
    },
    {
      status: NotifyLetterStatus.Pending,
      statusRu: 'Ожидает публикации',
      className: 'bg-smena_yellow-extra_light text-smena_orange-dark',
    },
    {
      status: NotifyLetterStatus.Published,
      statusRu: 'Опубликовано',
      className: 'bg-smena_green-extra_light text-smena_green-dark',
    },
    {
      status: NotifyLetterStatus.Removed,
      statusRu: 'Снято с публикации',
      className: 'bg-smena_white border border-smena_text-secondary text-smena_text-secondary',
    },
  ];
  const response = statuses.find(element => element.status === status);
  if (!response)
    return {
      statusRu: 'Статус не найден',
      className: 'bg-smena_white border border-smena_red text-smena_red',
    };
  return response;
};
