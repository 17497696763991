import { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import { ErrorNotification } from '../errorNotification';

type PhoneInputProps = {
  name: string;
  onChange: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  onBlur?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  value: string | undefined;
  placeholder: string;
  className?: string;
  error?: string | boolean;
};

const PhoneInput = ({ name, onChange, onBlur, value, placeholder, error }: PhoneInputProps) => {
  return (
    <>
      <InputMask
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        mask="+7 (999) 999-99-99"
        maskPlaceholder=""
        className={`${error ? 'input-error' : 'input-primary'}`}
      />
      {error && <ErrorNotification text={String(error)} />}
    </>
  );
};

export default PhoneInput;
