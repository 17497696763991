import { LeadStatus } from 'generated/graphql';

export function leadStatus(status?: LeadStatus) {
  if (status === LeadStatus.Accepted) {
    return (
      <span className="bg-smena_green-extra_light rounded-full Table-small text-smena_green-dark px-2 py-0.5">
        работает
      </span>
    );
  }
  if (status === LeadStatus.Rejected) {
    return <span className="bg-smena_gray-30 rounded-full Table-small text-smena_gray-60 px-2 py-0.5">отказ</span>;
  }
  if (status === LeadStatus.Pending) {
    return (
      <span className="bg-smena_bb-background rounded-full Table-small text-primary-dark px-2 py-0.5">в ожидании</span>
    );
  }
}
