import { useMemo } from 'react';
import uniqueArray from 'utils/helpers/uniqueArray';
import { WorkPostStatus } from 'generated/graphql';
import { useCurrentUser } from '../../api/useUser';

const useWorkpostFacilities = () => {
  const { user } = useCurrentUser();
  return useMemo(() => {
    const activeWorkposts = user?.workposts?.filter(workpost => workpost.status === WorkPostStatus.Active);
    return uniqueArray({ array: activeWorkposts, field: 'facilityId' });
  }, [user?.workposts]);
};

export default useWorkpostFacilities;
