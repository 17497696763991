import { ApolloCache } from '@apollo/client';
import { CreateVacancyMutation } from 'pages/vacancy/api/mutations/generated/CreateVacancy';
import { VacanciesPaginatedDocument, VacanciesPaginatedQuery } from '../queries/generated/VacanciesPaginated';
import { VacancyFragment } from '../../../vacancy/api/fragments/generated/Vacancy.fragment';

interface Props {
  data?: CreateVacancyMutation | null;
  facilityId?: string;
}

export const createVacancyCache = (cache: ApolloCache<any>, { data, facilityId }: Props) => {
  const variables = {
    input: {
      page: 1,
      searchQuery: null,
      sortBy: null,
      sortWay: null,
      groupId: null,
      facilityId,
      cityId: null,
      period: null,
      schedule: [],
      isActive: null,
    },
  };
  const vacanciesPaginationQuery = cache.readQuery<VacanciesPaginatedQuery>({
    query: VacanciesPaginatedDocument,
    variables,
  });
  if (!vacanciesPaginationQuery) return;
  if (!data?.createVacancy) return;
  const {
    vacanciesPaginated: { count, pages, vacancies },
  } = vacanciesPaginationQuery;
  const newVacancies = [...vacancies];
  if (newVacancies.length === 25) {
    newVacancies.splice(-1);
  }
  const newVacancy = data.createVacancy as unknown as VacancyFragment;
  cache.writeQuery<VacanciesPaginatedQuery>({
    query: VacanciesPaginatedDocument,
    variables,
    data: {
      vacanciesPaginated: {
        count,
        pages,
        vacancies: [newVacancy, ...newVacancies],
      },
    },
  });
};
