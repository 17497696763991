import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityFragmentDoc } from '../../../../facility/api/fragments/generated/Facility.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFacilityMutationVariables = Types.Exact<{
  input: Types.FacilityCardInput;
}>;


export type CreateFacilityMutation = { __typename?: 'Mutation', createFacility: { __typename?: 'Facility', id: string, externalId?: string | null, name: string, isMedInvoiceAllowed: boolean, numberDaysWaitMedical: number, checkInMeasurement: number, checkOutMeasurement: number, naimixObjectId?: string | null, naimixObjectName?: string | null, naimixScenarioNumber?: string | null, facilityGroupId?: string | null, metro?: string | null, metroStationsIds?: Array<string> | null, cityId?: string | null, addressString?: string | null, is_active?: boolean | null, is_bonus_active: boolean, is_finance_active: boolean, showSalaryBeforeModeration: boolean, latitude: string, longitude: string, radius: number, everythingIsPossibleBonus: boolean, possibleBonusPaymentSum: number, possibleBonusShiftCount: number, possibleBonusViewSum: number, city?: { __typename?: 'City', id: string, name: string, latitude: string, longitude: string, timeZone: string, externalId?: string | null } | null, legalEntity?: { __typename?: 'LegalEntity', id: string, name: string } | null, foremen?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string }> | null, supervisors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string, clientDirectors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null } | null, penalty?: Array<{ __typename?: 'Penalty', id: string, createdAt: any, applyTimeInMinutes: number, comment?: string | null, cost: number, facilityId?: string | null, type: Types.PenaltyType, isDeleted: boolean }> | null, clientManagers?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null } };


export const CreateFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FacilityCardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Facility"}}]}}]}},...FacilityFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateFacilityMutationFn = Apollo.MutationFunction<CreateFacilityMutation, CreateFacilityMutationVariables>;

/**
 * __useCreateFacilityMutation__
 *
 * To run a mutation, you first call `useCreateFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFacilityMutation, { data, loading, error }] = useCreateFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFacilityMutation(baseOptions?: Apollo.MutationHookOptions<CreateFacilityMutation, CreateFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFacilityMutation, CreateFacilityMutationVariables>(CreateFacilityDocument, options);
      }
export type CreateFacilityMutationHookResult = ReturnType<typeof useCreateFacilityMutation>;
export type CreateFacilityMutationResult = Apollo.MutationResult<CreateFacilityMutation>;
export type CreateFacilityMutationOptions = Apollo.BaseMutationOptions<CreateFacilityMutation, CreateFacilityMutationVariables>;