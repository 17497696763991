import { useParams } from 'react-router-dom';
import { useGetNotifyLetterQuery } from '../../../pages/push/api/queries/generated/GetNotifyLetter';
import { NotifyLetterStatus } from 'generated/graphql';

export const usePush = () => {
  const router = useParams();
  const id = router.id;

  const { data, loading, error } = useGetNotifyLetterQuery({
    variables: {
      id: id || '',
    },
  });

  if (id) {
    const push = data?.getNotifyLetter;
    const isPushIsNotNew = push?.status !== NotifyLetterStatus.New;
    const isPushPublished = push?.status === NotifyLetterStatus.Published;
    const isPushPending = push?.status === NotifyLetterStatus.Pending;
    const isPushRemoved = push?.status === NotifyLetterStatus.Removed;
    return { push, loading, error, id, isPushIsNotNew, isPushPublished, isPushRemoved, isPushPending };
  }
  return {};
};
