import React, { useState } from 'react';
import { clsx } from 'clsx';

import { useStore } from 'store';
import { MenuItemProps } from '../list';
import { useMenuStore } from '../useMenuStore';
import Tooltip from 'antd/es/tooltip';

interface Props {
  item: MenuItemProps;
  target?: React.HTMLAttributeAnchorTarget;
}

const ExternalLink = React.memo(({ item, target }: Props) => {
  const showSidebarMenu = useStore(state => state.showSidebarMenu);
  const showFeedbackPopup = useMenuStore(state => state.showFeedbackPopup);
  const setShowFeedbackPopup = useMenuStore(state => state.setShowFeedbackPopup);
  const { url, name, icon, className } = item;
  const [open, setOpen] = useState(false);
  const isMenuOpen = showSidebarMenu ? 'rounded-lg' : 'rounded-full';

  return (
    <>
      <Tooltip
        placement="left"
        title={name}
        open={open}
        onOpenChange={open => {
          if (showSidebarMenu) return;
          setOpen(open);
        }}
      >
        <div
          className={clsx(showSidebarMenu ? '' : 'flex justify-center', className)}
          onClick={() => {
            if (item.id === 98) {
              setShowFeedbackPopup(!showFeedbackPopup);
            }
          }}
        >
          {url ? (
            <a href={url} className={clsx('nav-link', isMenuOpen)} target={target}>
              <div className="relative">{icon}</div>
              <span className={clsx(showSidebarMenu ? 'flex' : 'hidden', 'ml-[15px]')}>{name}</span>
            </a>
          ) : (
            <div className={clsx('nav-link', isMenuOpen)}>
              <div className="relative">{icon}</div>
              <span className={clsx(showSidebarMenu ? 'flex' : 'hidden', 'ml-[15px]')}>{name}</span>
            </div>
          )}
        </div>
      </Tooltip>
    </>
  );
});

export default ExternalLink;
