import React, { useRef, useState } from 'react';
import { Map, YMaps, YMapsApi } from 'react-yandex-maps';
import { useCityMap } from '../store';
import SearchPin from '../../vacanciesMap/components/map/searchPin';

const YandexMap = React.memo(() => {
  const [map, setMap] = useState<YMapsApi | null>(null);
  const lat = useCityMap(state => state.lat);
  const lon = useCityMap(state => state.lon);
  const mapRef = useRef<any>();
  return (
    <div className={'relative h-[300px] w-full'}>
      <YMaps query={{ apikey: process.env.REACT_APP_YA_MAP_API }}>
        <Map
          onLoad={ymaps => {
            if (ymaps && !map) {
              setMap(ymaps);
            }
          }}
          instanceRef={ref => {
            mapRef.current = ref;
          }}
          state={lat && lon ? { center: [lat, lon], zoom: 10 } : undefined}
          defaultState={{ center: [59.94044552268284, 30.314362834341292], zoom: 10 }}
          width="100%"
          height="100%"
          modules={['clusterer.addon.balloon', 'templateLayoutFactory']}
        >
          {lat && lon ? <SearchPin pin={{ lat, lon }} ymaps={map} /> : null}
        </Map>
      </YMaps>
    </div>
  );
});

export default YandexMap;
