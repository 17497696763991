import * as Types from '../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteReferralProgramMutationVariables = Types.Exact<{
  input: Types.DeleteReferralProgramInput;
}>;


export type DeleteReferralProgramMutation = { __typename?: 'Mutation', deleteReferralProgram: boolean };


export const DeleteReferralProgramDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteReferralProgram"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteReferralProgramInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteReferralProgram"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type DeleteReferralProgramMutationFn = Apollo.MutationFunction<DeleteReferralProgramMutation, DeleteReferralProgramMutationVariables>;

/**
 * __useDeleteReferralProgramMutation__
 *
 * To run a mutation, you first call `useDeleteReferralProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferralProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferralProgramMutation, { data, loading, error }] = useDeleteReferralProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReferralProgramMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReferralProgramMutation, DeleteReferralProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReferralProgramMutation, DeleteReferralProgramMutationVariables>(DeleteReferralProgramDocument, options);
      }
export type DeleteReferralProgramMutationHookResult = ReturnType<typeof useDeleteReferralProgramMutation>;
export type DeleteReferralProgramMutationResult = Apollo.MutationResult<DeleteReferralProgramMutation>;
export type DeleteReferralProgramMutationOptions = Apollo.BaseMutationOptions<DeleteReferralProgramMutation, DeleteReferralProgramMutationVariables>;