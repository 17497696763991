import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVacancyInBatchMutationVariables = Types.Exact<{
  input: Types.BatchVacancyInput;
}>;


export type CreateVacancyInBatchMutation = { __typename?: 'Mutation', createVacancyInBatch: Array<{ __typename?: 'Vacancy', id: string, facilityId: string, timeStart: string, timeEnd: string, hourRate: number, is_active: boolean, period: Types.ShiftTime, positionId: string, section?: string | null, payout?: Types.PayoutType | null, unit?: Types.UnitType | null, isPublishCostOfShift: boolean, isRangeCost: boolean, averageCost: number, shiftCostFrom: number, shiftCostUpTo: number }> };


export const CreateVacancyInBatchDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateVacancyInBatch"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BatchVacancyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createVacancyInBatch"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"facilityId"}},{"kind":"Field","name":{"kind":"Name","value":"timeStart"}},{"kind":"Field","name":{"kind":"Name","value":"timeEnd"}},{"kind":"Field","name":{"kind":"Name","value":"hourRate"}},{"kind":"Field","name":{"kind":"Name","value":"is_active"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}},{"kind":"Field","name":{"kind":"Name","value":"section"}},{"kind":"Field","name":{"kind":"Name","value":"payout"}},{"kind":"Field","name":{"kind":"Name","value":"unit"}},{"kind":"Field","name":{"kind":"Name","value":"isPublishCostOfShift"}},{"kind":"Field","name":{"kind":"Name","value":"isRangeCost"}},{"kind":"Field","name":{"kind":"Name","value":"averageCost"}},{"kind":"Field","name":{"kind":"Name","value":"shiftCostFrom"}},{"kind":"Field","name":{"kind":"Name","value":"shiftCostUpTo"}}]}}]}}]} as unknown as DocumentNode;
export type CreateVacancyInBatchMutationFn = Apollo.MutationFunction<CreateVacancyInBatchMutation, CreateVacancyInBatchMutationVariables>;

/**
 * __useCreateVacancyInBatchMutation__
 *
 * To run a mutation, you first call `useCreateVacancyInBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacancyInBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacancyInBatchMutation, { data, loading, error }] = useCreateVacancyInBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVacancyInBatchMutation(baseOptions?: Apollo.MutationHookOptions<CreateVacancyInBatchMutation, CreateVacancyInBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVacancyInBatchMutation, CreateVacancyInBatchMutationVariables>(CreateVacancyInBatchDocument, options);
      }
export type CreateVacancyInBatchMutationHookResult = ReturnType<typeof useCreateVacancyInBatchMutation>;
export type CreateVacancyInBatchMutationResult = Apollo.MutationResult<CreateVacancyInBatchMutation>;
export type CreateVacancyInBatchMutationOptions = Apollo.BaseMutationOptions<CreateVacancyInBatchMutation, CreateVacancyInBatchMutationVariables>;