import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { MetroLineFragmentDoc } from '../../fragments/generated/MetroLine.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchMetroStationsQueryVariables = Types.Exact<{
  city: Types.Scalars['String']['input'];
  search: Types.Scalars['String']['input'];
}>;


export type SearchMetroStationsQuery = { __typename?: 'Query', searchMetroStations: { __typename?: 'MetroStationsByLineOutput', lines: Array<{ __typename?: 'MetroLineOutput', name: string, color: string, firstLastOnAline: string, stations: Array<{ __typename?: 'MetroStation', id: string, color: string, name: string, city: string }> }> } };


export const SearchMetroStationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchMetroStations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"city"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchMetroStations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"city"},"value":{"kind":"Variable","name":{"kind":"Name","value":"city"}}},{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lines"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MetroLine"}}]}}]}}]}},...MetroLineFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useSearchMetroStationsQuery__
 *
 * To run a query within a React component, call `useSearchMetroStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMetroStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMetroStationsQuery({
 *   variables: {
 *      city: // value for 'city'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchMetroStationsQuery(baseOptions: Apollo.QueryHookOptions<SearchMetroStationsQuery, SearchMetroStationsQueryVariables> & ({ variables: SearchMetroStationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMetroStationsQuery, SearchMetroStationsQueryVariables>(SearchMetroStationsDocument, options);
      }
export function useSearchMetroStationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMetroStationsQuery, SearchMetroStationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMetroStationsQuery, SearchMetroStationsQueryVariables>(SearchMetroStationsDocument, options);
        }
export function useSearchMetroStationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchMetroStationsQuery, SearchMetroStationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchMetroStationsQuery, SearchMetroStationsQueryVariables>(SearchMetroStationsDocument, options);
        }
export type SearchMetroStationsQueryHookResult = ReturnType<typeof useSearchMetroStationsQuery>;
export type SearchMetroStationsLazyQueryHookResult = ReturnType<typeof useSearchMetroStationsLazyQuery>;
export type SearchMetroStationsSuspenseQueryHookResult = ReturnType<typeof useSearchMetroStationsSuspenseQuery>;
export type SearchMetroStationsQueryResult = Apollo.QueryResult<SearchMetroStationsQuery, SearchMetroStationsQueryVariables>;