import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { RequestScheduleFragmentDoc } from '../../fragments/generated/RequestSchedule.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRequestScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  input: Types.ZayavkaScheduleInput;
}>;


export type UpdateRequestScheduleMutation = { __typename?: 'Mutation', updateZayavkaSchedule: { __typename?: 'ZayavkaSchedule', authorId: string, createdAt: any, dateAccept?: any | null, dateFrom: any, dateTo: any, dayInterleaving?: number | null, facilityId: string, id: string, lunchDuration?: number | null, positionId: string, timesOfDay: Types.ShiftTime, scheduleId?: string | null, updatedAt: any, zayavkaId: string, newDateFrom?: any | null, newDateTo?: any | null, scheduleTypeId?: string | null, newScheduleTypeId?: string | null, newDayInterleaving?: number | null, newLunchDuration?: number | null, newShiftDuration?: number | null, isDeleted: boolean, isEdited: boolean, isNew: boolean, position?: { __typename?: 'Position', id: string, name: string } | null, zayavkaShift: Array<{ __typename?: 'ZayavkaShift', id: string, authorId: string, createdAt: any, dateEnd: any, dateFrom: any, facilityId: string, duration: number, lunchDuration?: number | null, payout?: Types.PayoutType | null, positionId: string, shiftId?: string | null, isFree: boolean, zayavkaScheduleId: string, unit?: Types.UnitType | null, updatedAt: any, zayavkaId: string, newDateFrom?: any | null, newDateEnd?: any | null, newLunchDuration?: number | null, isDeleted: boolean, isEdited: boolean, isNew: boolean, shift?: { __typename?: 'Shift', id: string, stage: Types.ShiftStage, userId: string, isProbation: boolean, user?: { __typename?: 'User', id: string, lastname: string, firstname: string, patronymic?: string | null } | null } | null }> } };


export const UpdateRequestScheduleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateRequestSchedule"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ZayavkaScheduleInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateZayavkaSchedule"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RequestSchedule"}}]}}]}},...RequestScheduleFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateRequestScheduleMutationFn = Apollo.MutationFunction<UpdateRequestScheduleMutation, UpdateRequestScheduleMutationVariables>;

/**
 * __useUpdateRequestScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateRequestScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestScheduleMutation, { data, loading, error }] = useUpdateRequestScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRequestScheduleMutation, UpdateRequestScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRequestScheduleMutation, UpdateRequestScheduleMutationVariables>(UpdateRequestScheduleDocument, options);
      }
export type UpdateRequestScheduleMutationHookResult = ReturnType<typeof useUpdateRequestScheduleMutation>;
export type UpdateRequestScheduleMutationResult = Apollo.MutationResult<UpdateRequestScheduleMutation>;
export type UpdateRequestScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateRequestScheduleMutation, UpdateRequestScheduleMutationVariables>;