import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeVacancyStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ChangeVacancyStatusMutation = { __typename?: 'Mutation', changeVacancyStatus: { __typename?: 'Vacancy', id: string } };


export const ChangeVacancyStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeVacancyStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeVacancyStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type ChangeVacancyStatusMutationFn = Apollo.MutationFunction<ChangeVacancyStatusMutation, ChangeVacancyStatusMutationVariables>;

/**
 * __useChangeVacancyStatusMutation__
 *
 * To run a mutation, you first call `useChangeVacancyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVacancyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVacancyStatusMutation, { data, loading, error }] = useChangeVacancyStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeVacancyStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVacancyStatusMutation, ChangeVacancyStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVacancyStatusMutation, ChangeVacancyStatusMutationVariables>(ChangeVacancyStatusDocument, options);
      }
export type ChangeVacancyStatusMutationHookResult = ReturnType<typeof useChangeVacancyStatusMutation>;
export type ChangeVacancyStatusMutationResult = Apollo.MutationResult<ChangeVacancyStatusMutation>;
export type ChangeVacancyStatusMutationOptions = Apollo.BaseMutationOptions<ChangeVacancyStatusMutation, ChangeVacancyStatusMutationVariables>;