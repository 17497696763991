import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import MainLayout from 'components/layout/main';
import FacilityForemenSupervisors from './components/facilityResponsibility/ForemanSupervisor';
import useFacilityHeaderMenu from 'utils/hooks/headerMenu/useFacilityHeaderMenu';
import useFacilityBreadcrumbs from 'utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import useFacilityPageForm from './lib/hooks/useFacilityPageForm';
import { useMeRole } from 'store/hooks/useMeRole';
import ManagersAndDirectorsBlock from './components/facilityResponsibility/ManagersAndDirectorsBlock';
import FacilityMedicalInvoice from './components/facilityMedicalInvoice';
import MainInfoFacility from './components/mainInfo';
import FacilityAccess from './components/facilityAccess';
import FacilityMapAndCoordinates from './components/mapAndCoordinates';
import FacilityPenalty from './components/facilityPenalty';
import FacilityAllIsPossible from './components/facilityAllIsPossible';
import { useFacility } from 'utils/api/useFacility';
import { useFacilityPageTitle } from './lib/hooks/useFacilityPageProps';
import { Role } from 'generated/graphql';
import PromotionBlock from './components/promotionBlock';
import PhotoList from 'components/PhotoList';
import { useUploadPhotoInFacilityGalleryMutation } from 'pages/facilityGroupCasing/api/mutations/generated/UploadPhotoInFacilityGallery';
import { uploadPhotoInFacilityGalleryCache } from 'pages/facility/api/cache/uploadPhotoInFacilityGalleryCache';
import Modal from 'ui/modal';
import { successToast } from 'utils/helpers/notify';
import { useRemovePhotoInFacilityGalleryMutation } from 'pages/userSchedule/api/mutations/generated/RemovePhotoInFacilityGallery';

const UpdateFacility = () => {
  const [photoIds, setPhotoIds] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [deletePhotoInFacility] = useRemovePhotoInFacilityGalleryMutation();

  const { error, loading, id, facility, refetchFacility } = useFacility();
  const [uploadPhotoInFacilityGallery] = useUploadPhotoInFacilityGalleryMutation({
    update: (cache, { data }) => {
      uploadPhotoInFacilityGalleryCache(cache, { image: data?.uploadPhotoInFacilityGallery.file, facilityId: id });
    },
  });
  const isMeAdmin = useMeRole([Role.Admin]);
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);

  const title = useFacilityPageTitle();
  const headerMenu = useFacilityHeaderMenu();
  const breadCrumbs = useFacilityBreadcrumbs();

  const { initialValues, validate, onSubmit, loading: facilityMutations } = useFacilityPageForm();

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs} error={error} loading={loading}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          return (
            <div className="grid grid-cols-12 md:gap-x-6 gap-y-6">
              <Form className={'col-span-full grid grid-cols-12 md:gap-x-6 gap-y-6'}>
                <div className="facilityPageBlock blockTemplate">
                  <MainInfoFacility
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    values={values}
                  />

                  <FacilityMapAndCoordinates
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                  />

                  {id && (
                    <>
                      <PhotoList
                        facilityGallery={facility?.facilityGallery || []}
                        casingGallery={[]}
                        onDownload={image => {
                          uploadPhotoInFacilityGallery({ variables: { facilityId: id || '', image } });
                        }}
                        isDownload={!facility?.facilityGallery?.length || facility.facilityGallery.length < 10}
                        handleDelete={photoId => {
                          setPhotoIds(prevState => {
                            if (prevState.includes(photoId)) {
                              return prevState.filter(id => id !== photoId);
                            }
                            return [...prevState, photoId];
                          });
                        }}
                        isDelete
                        photoIds={photoIds}
                      />

                      {photoIds.length > 0 && (
                        <button
                          type="button"
                          className="btn-reject_secondary self-end"
                          onClick={() => setModalOpen(true)}
                        >
                          Удалить {photoIds.length} фото
                        </button>
                      )}
                    </>
                  )}

                  {isMeAdminOrSupervisor && (
                    <div className="flex justify-end">
                      <button type="submit" disabled={facilityMutations} className="btn-primary_big">
                        {facilityMutations ? 'Загрузка' : id ? 'Сохранить' : 'Создать'}
                      </button>
                    </div>
                  )}
                </div>
                {isMeAdmin && <FacilityAccess handleChange={handleChange} values={values} />}
              </Form>

              {!!id && (
                <>
                  <ManagersAndDirectorsBlock />
                  <FacilityForemenSupervisors />
                  <PromotionBlock />
                  {isMeAdminOrSupervisor && <FacilityPenalty />}
                  <FacilityAllIsPossible />
                  <FacilityMedicalInvoice />
                </>
              )}
            </div>
          );
        }}
      </Formik>

      <Modal
        type="delete"
        title="Удалить фото"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() =>
          deletePhotoInFacility({ variables: { photoIds } }).then(() => {
            setModalOpen(false);
            setPhotoIds([]);
            successToast('Фото успешно удалены');
            refetchFacility();
          })
        }
      >
        Вы уверены, что хотите удалить фото?
      </Modal>
    </MainLayout>
  );
};

export default UpdateFacility;
