import { useMemo } from 'react';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';

interface Props {
  cities: string[];
  facilityGroups: string[];
}

export const useFilteredFacilities = ({ cities, facilityGroups }: Props) => {
  const { facilities } = useSelectFacilities();
  return useMemo(() => {
    return facilities.filter(facility => {
      const isCities = cities.length ? cities.some(city => city === facility.cityId) : true;
      const isFacilityGroups = facilityGroups.length
        ? facilityGroups.some(facilityGroup => facilityGroup === facility.facilityGroupId)
        : true;

      return isCities && isFacilityGroups;
    });
  }, [facilities, cities, facilityGroups]);
};
