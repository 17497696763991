const AzerbaijanIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9559_3129)">
        <path d="M0 7.22217H20V12.7777H0V7.22217Z" fill="#E00034" />
        <path
          d="M17.7778 2.77783H2.22222C1.63285 2.77783 1.06762 3.01196 0.650874 3.42871C0.234126 3.84545 0 4.41068 0 5.00005L0 7.22228H20V5.00005C20 4.41068 19.7659 3.84545 19.3491 3.42871C18.9324 3.01196 18.3671 2.77783 17.7778 2.77783Z"
          fill="#0098C3"
        />
        <path
          d="M9.91369 11.8516C9.60513 11.8516 9.30145 11.7745 9.03026 11.6273C8.75907 11.4801 8.52896 11.2675 8.36084 11.0088C8.19272 10.75 8.09193 10.4534 8.06762 10.1458C8.04331 9.83816 8.09626 9.52936 8.22164 9.24743C8.34703 8.96549 8.54089 8.71936 8.7856 8.5314C9.0303 8.34345 9.3181 8.21962 9.62284 8.17118C9.92757 8.12274 10.2396 8.15122 10.5305 8.25403C10.8214 8.35684 11.0821 8.53073 11.2887 8.75989C11.0229 8.36478 10.6372 8.06551 10.1885 7.90617C9.73972 7.74684 9.25167 7.73586 8.79622 7.87486C8.34076 8.01385 7.94201 8.29548 7.65871 8.67823C7.37541 9.06098 7.22255 9.52461 7.22266 10.0008C7.22276 10.477 7.37583 10.9405 7.6593 11.3232C7.94277 11.7058 8.34166 11.9872 8.79717 12.126C9.25268 12.2648 9.74073 12.2536 10.1894 12.0941C10.6381 11.9345 11.0236 11.6351 11.2892 11.2399C11.1156 11.4326 10.9035 11.5866 10.6665 11.692C10.4295 11.7973 10.173 11.8517 9.91369 11.8516Z"
          fill="white"
        />
        <path
          d="M13.1491 9.99871L12.4847 9.76316L12.7869 9.12649L12.1502 9.42927L11.9136 8.76538L11.678 9.42983L11.6764 9.42871L11.0408 9.1276L11.3441 9.76427L10.6797 10.0009L11.3441 10.2365L11.0419 10.8732L11.6758 10.5715L11.6786 10.5704L11.9152 11.2343L12.1508 10.5698L12.788 10.872L12.4847 10.2354L13.1491 9.99871Z"
          fill="white"
        />
        <path
          d="M2.22222 17.2223H17.7778C18.3671 17.2223 18.9324 16.9881 19.3491 16.5714C19.7659 16.1547 20 15.5894 20 15.0001V12.7778H0V15.0001C0 15.5894 0.234126 16.1547 0.650874 16.5714C1.06762 16.9881 1.63285 17.2223 2.22222 17.2223Z"
          fill="#00AE65"
        />
      </g>
      <defs>
        <clipPath id="clip0_9559_3129">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AzerbaijanIcon;
