import { statusBg } from './shiftsBgStatus';
import { ShiftStage } from 'generated/graphql';
import { IMarks } from 'interfaces/IEvent';

export const shiftsClassName = (resource: {
  stage: ShiftStage | string;
  marks?: IMarks[] | null;
  isProbation: boolean;
}) => {
  const { marks, stage, isProbation } = resource;
  if (isProbation) {
    return { className: 'internship_shift' };
  }
  if (marks && marks.length) {
    if (marks.length > 1) return { className: statusBg(stage).className };
    if (stage === ShiftStage.Working) return { className: 'working_shifts' };
    return { className: 'without_marks' };
  }
  return { className: 'without_marks' };
};
