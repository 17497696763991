import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Skeleton from 'antd/es/skeleton';

import TableHeader from 'components/table/header';
import ScheduleRequestRow from 'pages/facilityRequestsSchedule/components/ScheduleRequestRow';
import { scheduleRequestsColumns } from 'components/table/columns';
import { useActiveRequestSchedule } from 'pages/facilityRequestsSchedule/hooks';
import ScheduleSpoiler from '../scheduleSpoiler';
import ActiveScheduleCheckbox from '../activeScheduleCheckbox';
import { useGetRequestByFacilityId } from 'utils/api/useGetRequestByFacilityId';
import { useStore } from 'store';

const ScheduleHeader = () => {
  const { loading, sortedRequests } = useGetRequestByFacilityId();
  const {
    facilityScheduleHeaderCheckbox,
    facilityScheduleIds,
    setFacilityScheduleHeaderCheckbox,
    activeRequests,
    setActiveRequests,
  } = useStore();
  const [showSpoiler, setShowSpoiler] = useState(false);
  const [minus, setMinus] = useState(false);

  const { checkboxHandler } = useActiveRequestSchedule();

  const scheduleRequestsColumnsChanged = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            name="reportId"
            className={clsx('checkbox-primary', minus ? 'minus' : '')}
            checked={facilityScheduleHeaderCheckbox}
            onChange={checkboxHandler}
          />
        ),
        header_className: 'table__header !py-2',
        name: 'checkbox',
        sorted: false,
        className: 'table__content border-none !py-2',
        style: {
          minWidth: 30,
          width: 30,
        },
      },
      ...scheduleRequestsColumns,
    ],
    [checkboxHandler, minus, facilityScheduleHeaderCheckbox],
  );
  useEffect(() => {
    if (facilityScheduleIds.length) {
      setFacilityScheduleHeaderCheckbox(true);
    } else {
      setFacilityScheduleHeaderCheckbox(false);
    }
  }, [facilityScheduleIds.length, setFacilityScheduleHeaderCheckbox]);

  useEffect(() => {
    const value = facilityScheduleIds.length ? facilityScheduleIds.length !== sortedRequests?.length : false;
    setMinus(value);
  }, [facilityScheduleIds, sortedRequests]);

  return (
    <ScheduleSpoiler
      show={showSpoiler}
      setShow={setShowSpoiler}
      setShowActive={setActiveRequests}
      header={
        <div>
          <div
            className={clsx(
              'bg-smena_white flex sm:overflow-visible overflow-x-scroll cursor-default',
              showSpoiler && 'border-b border-smena_gray-30',
            )}
          >
            <button
              className={clsx(
                'menu__element Subtitle1 text-smena_text-secondary',
                showSpoiler && activeRequests && 'menu__element-active',
              )}
              onClick={() => {
                setActiveRequests(true);
              }}
            >
              <span className="menu__link">Активные заявки</span>
            </button>
            {showSpoiler && (
              <button
                className={clsx(
                  'menu__element Subtitle1 text-smena_text-secondary',
                  !activeRequests && 'menu__element-active',
                )}
                onClick={() => {
                  setActiveRequests(false);
                }}
              >
                <span className="menu__link">Закрытые заявки</span>
              </button>
            )}
          </div>

          {loading ? (
            <div className="flex gap-x-5">
              <Skeleton.Button active shape="round" />
              <Skeleton.Button active shape="round" />
              <Skeleton.Button active shape="round" />
              <Skeleton.Button active shape="round" />
              <Skeleton.Button active shape="round" />
            </div>
          ) : (
            !showSpoiler && (
              <div className="flex gap-x-2 overflow-x-auto mt-4">
                {facilityScheduleIds.map(id => (
                  <ActiveScheduleCheckbox key={id} id={id} />
                ))}
              </div>
            )
          )}
        </div>
      }
      className="xl:col-span-9 col-span-full p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col"
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="flex">
          <div className="flex flex-col w-full">
            <TableHeader columns={scheduleRequestsColumnsChanged} />
            <div className="scheduleRequestsTable">
              {sortedRequests?.map(request => (
                <ScheduleRequestRow key={request.id} request={request} columns={scheduleRequestsColumnsChanged} />
              ))}
            </div>
          </div>
        </div>
      )}
    </ScheduleSpoiler>
  );
};

export default ScheduleHeader;
