import { Sex } from 'generated/graphql';

export const getSexRu = (sex?: string) => {
  const sexArr = [
    {
      sex: Sex.Male,
      sexRu: 'Мужчина',
    },
    {
      sex: Sex.Female,
      sexRu: 'Женщина',
    },
  ];
  const res = sexArr.find(sexEl => sexEl.sex === sex);
  if (!res) return '';
  return res.sexRu;
};
