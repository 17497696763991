import type { FormikHandlers, FormikState } from 'formik';
import SimpleInput from 'ui/input';

type Props = {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  touched: FormikState<any>['touched'];
  errors: FormikState<any>['errors'];
};

const Rewards = (props: Props) => {
  const { values, handleChange, touched, errors } = props;

  return (
    <>
      <span className="Subtitle2 text-smena_text-secondary">Условия и вознаграждения</span>

      <div className="flex gap-6">
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Кол-во хороших смен"
          placeholder="Кол-во хороших смен"
          maxLength={30}
          onChange={handleChange}
          value={values.numberOfGoodShifts}
          name="numberOfGoodShifts"
          type="number"
          allowIntegerOnly
          error={touched.numberOfGoodShifts && errors.numberOfGoodShifts ? errors.numberOfGoodShifts : ''}
        />

        <SimpleInput
          divClassName="flex-[1_0]"
          label="Вознаграждение реферера"
          placeholder="Вознаграждение реферера"
          maxLength={30}
          onChange={handleChange}
          value={values.rewardForTheReferer}
          name="rewardForTheReferer"
          type="number"
          allowIntegerOnly
          error={touched.rewardForTheReferer && errors.rewardForTheReferer ? errors.rewardForTheReferer : ''}
        />
      </div>
    </>
  );
};

export default Rewards;
