import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityGroupFragmentDoc } from '../../fragments/generated/FacilityGroup.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilityGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type FacilityGroupQuery = { __typename?: 'Query', facilityGroup: { __typename?: 'FacilityGroup', id: string, name: string, balance: number, logo?: { __typename?: 'File', id: string, mimetype: string, originalName?: string | null, path: string, size: number } | null, clientDirectors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null, groupManagers?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null } };


export const FacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityGroup"}}]}}]}},...FacilityGroupFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useFacilityGroupQuery__
 *
 * To run a query within a React component, call `useFacilityGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityGroupQuery(baseOptions: Apollo.QueryHookOptions<FacilityGroupQuery, FacilityGroupQueryVariables> & ({ variables: FacilityGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilityGroupQuery, FacilityGroupQueryVariables>(FacilityGroupDocument, options);
      }
export function useFacilityGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilityGroupQuery, FacilityGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilityGroupQuery, FacilityGroupQueryVariables>(FacilityGroupDocument, options);
        }
export function useFacilityGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FacilityGroupQuery, FacilityGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FacilityGroupQuery, FacilityGroupQueryVariables>(FacilityGroupDocument, options);
        }
export type FacilityGroupQueryHookResult = ReturnType<typeof useFacilityGroupQuery>;
export type FacilityGroupLazyQueryHookResult = ReturnType<typeof useFacilityGroupLazyQuery>;
export type FacilityGroupSuspenseQueryHookResult = ReturnType<typeof useFacilityGroupSuspenseQuery>;
export type FacilityGroupQueryResult = Apollo.QueryResult<FacilityGroupQuery, FacilityGroupQueryVariables>;