import { FetchResult, NextLink, Observable, Operation } from '@apollo/client';
import { getTokens, refresh, removeMetricValues, removeTokens } from '../auth';
import { removeCurrentUserTokens } from 'components/notification/helpers';
import { REDIRECT_PAGE } from 'utils/helpers/constVariables';
import { onError } from '@apollo/client/link/error';
import { client } from '../../App';

const ErrorObservable = (operation: Operation, forward: NextLink): Observable<FetchResult> =>
  new Observable(observer => {
    const { refreshToken } = getTokens();
    refresh(refreshToken)
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        // Retry last failed request
        forward(operation).subscribe(subscriber);
      })
      .catch(error => {
        // No refresh or client token available, we force user to login
        observer.error(error);
        removeTokens();
        removeMetricValues();
        removeCurrentUserTokens();
        client.clearStore();
        localStorage.setItem(REDIRECT_PAGE, window.location.pathname);
        window.location.replace('/login');
      });
  });

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors && graphQLErrors[0].message === 'Unauthorized') {
    return ErrorObservable(operation, forward);
  }
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export default errorLink;
