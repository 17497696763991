import React from 'react';

import MainLayout from 'components/layout/main';
import CustomPagination from 'ui/pagination';
import { useFacilityTitle } from '../facilityVacancies/hooks/pageProps';
import useFacilityBreadcrumbs from 'utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import useFacilityHeaderMenu from 'utils/hooks/headerMenu/useFacilityHeaderMenu';
import FacilityUsersFilters from './components/FacilityUsersFilters';
import NewTable from 'components/table/newTable';
import { useUsersTable } from 'components/table/columns/users';
import { useUsersPaginated } from 'utils/api/useUsersPaginated';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';

const FacilityUsers = () => {
  const { userPaginated, users, loadingUsers, error, page } = useUsersPaginated(true);

  const title = useFacilityTitle();
  const headerMenu = useFacilityHeaderMenu();
  const breadCrumbs = useFacilityBreadcrumbs();
  const table = useUsersTable(users);

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs} error={error}>
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <FacilityUsersFilters table={table} />
        <NewTable<UsersPaginatedFragment>
          table={table}
          length={users.length}
          loading={loadingUsers}
          headerMenu={true}
        />
        {userPaginated && <CustomPagination total={userPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default FacilityUsers;
