import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { ShiftTime, SortingWay, VacancyFieldsSorting } from 'generated/graphql';

import MainLayout from 'components/layout/main';

import { activeHandler } from 'utils/helpers/isActiveHandler';
import useFacilityHeaderMenu from 'utils/hooks/headerMenu/useFacilityHeaderMenu';
import useFacilityBreadcrumbs from 'utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import CustomPagination from 'ui/pagination';
import { useVacanciesPaginatedQuery } from 'pages/vacancies/api/queries/generated/VacanciesPaginated';
import { useFacility } from 'utils/api/useFacility';
import { useFacilityTitle } from './hooks/pageProps';
import { useFacilityVacanciesTable } from '../../components/table/columns/facilityVacancies';
import NewTable from '../../components/table/newTable';
import { VacancyFragment } from 'pages/vacancy/api/fragments/generated/Vacancy.fragment';
import FacilityVacanciesFilter from './components/FacilityVacansiesFilter';

const FacilityVacanciesPage = () => {
  const { id } = useFacility();
  const title = useFacilityTitle();
  const [params] = useSearchParams();

  const cityId = params.get('cityId');
  const periodParam = params.get('period');
  const scheduleParam = params.get('schedule');
  const activeParam = params.get('active');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;
  const schedule = scheduleParam ? scheduleParam.split(', ') : [];

  const {
    data: dataVacancies,
    loading: loadingVacancies,
    error,
  } = useVacanciesPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        searchQuery,
        sortBy: sortBy as VacancyFieldsSorting,
        sortWay: sortWay as SortingWay,
        facilityId: id,
        groupId: null,
        cityId,
        period: periodParam as ShiftTime | undefined,
        schedule,
        isActive: activeHandler(activeParam),
        size,
      },
    },
  });

  const vacanciesPaginated = dataVacancies?.vacanciesPaginated;
  const vacancies = vacanciesPaginated?.vacancies || [];
  const table = useFacilityVacanciesTable(vacancies);
  const headerMenu = useFacilityHeaderMenu();
  const breadCrumbs = useFacilityBreadcrumbs();

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs} error={error}>
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <FacilityVacanciesFilter table={table} />
        <NewTable<VacancyFragment>
          table={table}
          length={vacancies.length}
          loading={loadingVacancies}
          headerMenu={true}
        />
        {vacanciesPaginated && <CustomPagination total={vacanciesPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default FacilityVacanciesPage;
