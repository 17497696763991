import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeclineChangesBySupervisorMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeclineChangesBySupervisorMutation = { __typename?: 'Mutation', declineChangesBySupervisor: boolean };


export const DeclineChangesBySupervisorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclineChangesBySupervisor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineChangesBySupervisor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type DeclineChangesBySupervisorMutationFn = Apollo.MutationFunction<DeclineChangesBySupervisorMutation, DeclineChangesBySupervisorMutationVariables>;

/**
 * __useDeclineChangesBySupervisorMutation__
 *
 * To run a mutation, you first call `useDeclineChangesBySupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineChangesBySupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineChangesBySupervisorMutation, { data, loading, error }] = useDeclineChangesBySupervisorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeclineChangesBySupervisorMutation(baseOptions?: Apollo.MutationHookOptions<DeclineChangesBySupervisorMutation, DeclineChangesBySupervisorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineChangesBySupervisorMutation, DeclineChangesBySupervisorMutationVariables>(DeclineChangesBySupervisorDocument, options);
      }
export type DeclineChangesBySupervisorMutationHookResult = ReturnType<typeof useDeclineChangesBySupervisorMutation>;
export type DeclineChangesBySupervisorMutationResult = Apollo.MutationResult<DeclineChangesBySupervisorMutation>;
export type DeclineChangesBySupervisorMutationOptions = Apollo.BaseMutationOptions<DeclineChangesBySupervisorMutation, DeclineChangesBySupervisorMutationVariables>;