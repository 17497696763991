import { useCallback, useRef, useState } from 'react';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import dayjs from 'dayjs';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import SimpleSelect from 'ui/select';
import SimpleInput from 'ui/input';
import TextArea from 'ui/textArea';
import Across from 'assets/icons/Across';
import CheckCircle from 'assets/icons/checkCircle';
import Edit from 'assets/icons/edit';
import { BalanceOperationType } from 'generated/graphql';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import { balanceOperationTypeOptions, TIME_DATE } from 'utils/helpers/constVariables';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useParams } from 'react-router-dom';
import { writeBalanceListCache } from './writeCahce';
import { convertMoney } from 'utils/helpers/convertMoney';
import InformationIcon from 'assets/icons/InformationIcon';
import { useUpdateBalanceOperationMutation } from './api/mutations/generated/UpdateBalanceOperation';
import { useDeleteBalanceOperationMutation } from './api/mutations/generated/DeleteBalanceOperation';
import { FacilityGroupBalanceOperationFragment } from 'pages/balance/api/fragments/generated/FacilityGroupBalanceOperation.fragment';
import Tooltip from 'antd/lib/tooltip';
import Modal from 'ui/modal';

const BalanceRow = ({ el }: { el: FacilityGroupBalanceOperationFragment }) => {
  const [isEditRow, setIsEditRow] = useState(false);
  const [amount, setAmount] = useState(String(el.amount) || '');
  const [description, setDescription] = useState(el.description);
  const [date, setDate] = useState<DayjsOrNull>(dayjs(el.dayAndTime));
  const [time, setTime] = useState<DayjsOrNull>(dayjs(el.dayAndTime));
  const [operation, setOperation] = useState<BalanceOperationType | null>(el.operation as BalanceOperationType);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const btnRef = useRef<HTMLButtonElement>(null);

  const router = useParams();
  const id = router.id ?? '';
  const [updateBalanceOperation, { loading: updateLoading }] = useUpdateBalanceOperationMutation();
  const [deleteBalanceOperation, { loading: deleteLoading }] = useDeleteBalanceOperationMutation();

  const onShowDeletePopup = useCallback(() => {
    setShowDeletePopup(value => !value);
  }, []);

  const onRemoveRow = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      deleteBalanceOperation({
        update: store => {
          writeBalanceListCache({ store, facilityGroupId: id, remove: true, id: el.id });
        },
        variables: {
          id: el.id,
        },
      })
        .then(() => successToast('Транзакция удалена'))
        .catch(error => errorToast(errorHandler(error)))
        .finally(() => setShowDeletePopup(value => !value));
    },
    [deleteBalanceOperation, el.id, id],
  );

  const onShowEditPopup = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowEditPopup(value => !value);
  }, []);

  const onEditHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const dayAndTime = dayjs(date).set('hours', dayjs(time).get('hours')).set('minutes', dayjs(time).get('minutes'));
      updateBalanceOperation({
        update: (store, { data }) => {
          writeBalanceListCache({
            store,
            facilityGroupId: id,
            id: el.id,
            data: data?.updateBalanceOperation,
            edit: true,
          });
        },
        variables: {
          id: el.id,
          input: {
            facilityGroupId: id,
            operation: operation as BalanceOperationType,
            description,
            amount: Number(amount),
            dayAndTime,
          },
        },
      })
        .then(() => {
          successToast('Транзакция обновлена');
          setIsEditRow(false);
        })
        .catch(error => errorToast(errorHandler(error)))
        .finally(() => setShowEditPopup(value => !value));
    },
    [amount, date, description, el.id, id, operation, time, updateBalanceOperation],
  );

  const isAddition = el.operation === BalanceOperationType.Addition;

  return (
    <form className={'grid grid-cols-[200px_100px_150px_180px_220px_115px] gap-x-4 px-6 group'}>
      <DatePickerInput
        popupClassName="fixed"
        value={date}
        onChange={date => {
          setDate(date);
        }}
        showText={!isEditRow}
        maxDate={dayjs().endOf('day')}
      />

      <TimePickerInput
        value={time}
        format={TIME_DATE}
        onChange={value => {
          setTime(value);
        }}
        showText={!isEditRow}
      />
      <SimpleSelect
        divClassName="Body2"
        onChange={value => {
          setOperation(value as BalanceOperationType);
        }}
        value={operation}
        placeholder="Тип оплаты"
        options={balanceOperationTypeOptions}
        showText={!isEditRow}
      />
      <SimpleInput
        divClassName={!isEditRow ? (isAddition ? 'text-smena_green-dark' : 'text-smena_red-dark') : 'Body2'}
        placeholder="0,00"
        onChange={({ target: { value } }) => {
          setAmount(value);
        }}
        value={!isEditRow ? `${isAddition ? '+' : '-'}${convertMoney(Number(amount))}` : amount}
        name="name"
        type={'text'}
        float
        showText={!isEditRow}
      />
      {isEditRow ? (
        <TextArea
          name={'description'}
          style={{ height: 'auto' }}
          divClassName={'Body2'}
          label={''}
          value={description}
          placeholder={'Оставьте комментарий'}
          onChange={({ target: { value } }) => setDescription(value)}
          // showText={!isEditRow}
        />
      ) : (
        <div className={'flex gap-x-2 Body2'}>
          <span className={'truncate'}>{el.description || 'Нет данных'}</span>
          {el.description ? (
            <Tooltip
              open={showTooltip}
              title={
                <div className={'w-[210px] flex flex-col p-3'}>
                  <span className={'Table-small'}>{el.description || 'Нет данных'}</span>
                  <div className={'flex justify-end'}>
                    <button
                      className={'Button3 text-primary'}
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        if (el.description) {
                          navigator.clipboard.writeText(el.description);
                        }
                      }}
                    >
                      Скопировать
                    </button>
                  </div>
                </div>
              }
            >
              <button
                ref={btnRef}
                type="button"
                onClick={e => {
                  e.preventDefault();
                  setShowTooltip(value => !value);
                }}
                className={'relative group-hover:block hidden'}
              >
                <InformationIcon />
              </button>
            </Tooltip>
          ) : null}
        </div>
      )}

      <div className="flex justify-center items-center gap-x-4">
        <button className="inline-flex transform hover:scale-110" type="button" onClick={onShowDeletePopup}>
          <Across className={'text-smena_red-mark'} />
        </button>
        {isEditRow ? (
          <button className="inline-flex transform hover:scale-110" type={'button'} onClick={onShowEditPopup}>
            <CheckCircle className="text-smena_green-dark" />
          </button>
        ) : (
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              setIsEditRow(value => !value);
            }}
            className="inline-flex transform hover:scale-110"
          >
            <Edit />
          </button>
        )}
      </div>

      <Modal
        title="Редактирование"
        open={showEditPopup}
        onCancel={onShowEditPopup}
        onOk={onEditHandler}
        okButtonProps={{ disabled: updateLoading }}
        okText="Сохранить"
      >
        Вы уверены, что хотите редактировать?
      </Modal>

      <Modal
        type="delete"
        title="Удаление"
        open={showDeletePopup}
        onCancel={onShowDeletePopup}
        onOk={onRemoveRow}
        okButtonProps={{ disabled: deleteLoading }}
      >
        Вы уверены, что хотите удалить?
      </Modal>
    </form>
  );
};

export default BalanceRow;
