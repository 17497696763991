import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFacility } from 'utils/api/useFacility';

export const useFacilityPageTitle = () => {
  const [param] = useSearchParams();
  const groupId = param.get('groupId');

  const { facility, id } = useFacility();
  return useMemo(() => {
    if (groupId) {
      return 'Новый объект группы';
    }
    if (id) {
      return `${facility ? 'Объект ' + facility.name : 'Загрузка'}`;
    }
    return 'Создать объект';
  }, [facility?.name, id, groupId]);
};
