import { create, StoreApi } from 'zustand';
import { Dayjs } from 'dayjs';
import { StoreSlice } from 'store';
import { devtools } from 'zustand/middleware';
import { ModerationReportTableRowFragment } from '../api/fragments/generated/ModerationReportTableRow.fragment';
import { ShiftFactFragment } from '../api/fragments/generated/ShiftFact.fragment';

type CurrentPeriodDayType = {
  currentPeriodDay?: Dayjs;
  setCurrentPeriodDay: (day?: Dayjs) => void;
};

export const CurrentPeriodDaySlice: StoreSlice<CurrentPeriodDayType> = set => ({
  currentPeriodDay: undefined,
  setCurrentPeriodDay: day => set({ currentPeriodDay: day }),
});

type ModerationPopupsType = {
  showAddEmployerPopup: boolean;
  setShowAddEmployerPopup: (value: boolean) => void;
  showImportErrorPopup: boolean;
  setShowImportErrorPopup: (value: boolean) => void;
};

export const ModerationPopupsSlice: StoreSlice<ModerationPopupsType> = set => ({
  showAddEmployerPopup: false,
  setShowAddEmployerPopup: showAddEmployerPopup => set({ showAddEmployerPopup }),
  showImportErrorPopup: false,
  setShowImportErrorPopup: showImportErrorPopup => set({ showImportErrorPopup }),
});

type ModerationReports = {
  moderationReports: string[];
  setModerationReports: (shift: string) => void;
  setModerationReportsInWork: (shift: string[]) => void;
  moderationReportsHeaderCheckbox: boolean;
  setModerationReportsHeaderCheckbox: (moderationsReportsHeaderCheckbox: boolean) => void;
  activeRow?: string;
  setActiveRow: (value?: string) => void;
};

export const ModerationReportsSlice: StoreSlice<ModerationReports> = set => ({
  moderationReports: [],
  setModerationReports: shift =>
    set(state => {
      if (state.moderationReports.find(el => el === shift)) {
        return {
          moderationReports: [...state.moderationReports.filter(el => el !== shift)],
        };
      }
      return {
        moderationReports: [...state.moderationReports, shift],
      };
    }),
  setModerationReportsInWork: shifts => set({ moderationReports: shifts }),
  moderationReportsHeaderCheckbox: false,
  setModerationReportsHeaderCheckbox: moderationReportsHeaderCheckbox => set({ moderationReportsHeaderCheckbox }),
  activeRow: undefined,
  setActiveRow: activeRow => set({ activeRow }),
});

type ModerationShiftApproveBlock = {
  showApproveBlock: boolean;
  setShowApproveBlock: (showApproveBlock: boolean) => void;
};

export const ModerationShiftApproveBlockSlice: StoreSlice<ModerationShiftApproveBlock> = set => ({
  showApproveBlock: false,
  setShowApproveBlock: showApproveBlock => set({ showApproveBlock }),
});

type UpdateModerationReports = {
  minus: boolean;
  setMinus: (value: boolean) => void;
  updateModerationReports: ModerationReportTableRowFragment[];
  setUpdateModerationReports: (
    row: ModerationReportTableRowFragment,
    rows?: ModerationReportTableRowFragment[],
  ) => void;
  setUpdateModerationReportsToModeration: (rows?: ModerationReportTableRowFragment[]) => void;
  updateModerationReportHeaderCheckbox: boolean;
  setUpdateModerationReportHeaderCheckbox: (moderationsReportsHeaderCheckbox: boolean) => void;
  selectedOption: number;
  setSelectedOption: (selectedOption: number) => void;
  hideUsersWithoutShifts: boolean;
  setHideUsersWithoutShifts: (value: boolean) => void;
};

export const UpdateModerationReportsSlice: StoreSlice<UpdateModerationReports> = set => ({
  minus: false,
  setMinus: minus => set({ minus }),
  updateModerationReports: [],
  setUpdateModerationReports: (row, rows) =>
    set(state => {
      const findCurrent = (el: ModerationReportTableRowFragment) =>
        el.user.id === row.user.id && el.position.id === row.position.id;
      const rowIndex = state.updateModerationReports?.findIndex(findCurrent);
      if (state.updateModerationReports?.find(findCurrent)) {
        const updateModerationReports = [...state.updateModerationReports];
        updateModerationReports.splice(rowIndex, 1);
        return {
          updateModerationReports,
          updateModerationReportHeaderCheckbox: rows?.length === updateModerationReports.length,
          minus: !!updateModerationReports.length,
        };
      }
      const updateModerationReports = [...state.updateModerationReports, row];
      return {
        updateModerationReports,
        updateModerationReportHeaderCheckbox: rows?.length === updateModerationReports.length,
        minus: !!updateModerationReports.length,
      };
    }),
  setUpdateModerationReportsToModeration: rows => set({ updateModerationReports: rows }),
  updateModerationReportHeaderCheckbox: false,
  setUpdateModerationReportHeaderCheckbox: updateModerationReportHeaderCheckbox =>
    set({ updateModerationReportHeaderCheckbox }),
  selectedOption: 0,
  setSelectedOption: selectedOption => set({ selectedOption }),
  hideUsersWithoutShifts: true,
  setHideUsersWithoutShifts: value => set({ hideUsersWithoutShifts: value }),
});

type SetCurrentModerationRow = {
  currentModerationRow?: ModerationReportTableRowFragment;
  changeModerationRowShift: (shift: ShiftFactFragment) => void;
  setCurrentModerationRow: (currentModerationRow?: ModerationReportTableRowFragment) => void;
};

export const SetCurrentModerationRowSlice: StoreSlice<SetCurrentModerationRow> = set => ({
  currentModerationRow: undefined,
  changeModerationRowShift: shift =>
    set(state => {
      if (state.currentModerationRow) {
        const toChange = state.currentModerationRow?.shifts.findIndex(el => el.id === shift.id);
        const copyShifts = [...state.currentModerationRow.shifts];
        copyShifts.splice(toChange, 1, shift);
        return {
          currentModerationRow: { ...state.currentModerationRow, shifts: copyShifts },
        };
      }
      return {
        currentModerationRow: state.currentModerationRow,
      };
    }),
  setCurrentModerationRow: currentModerationRow => set({ currentModerationRow }),
});

const createRootSlice = (set: StoreApi<any>['setState'], get: StoreApi<any>['getState']) => ({
  ...CurrentPeriodDaySlice(set, get),
  ...ModerationPopupsSlice(set, get),
  ...ModerationReportsSlice(set, get),
  ...ModerationShiftApproveBlockSlice(set, get),
  ...UpdateModerationReportsSlice(set, get),
  ...SetCurrentModerationRowSlice(set, get),
});

type State = CurrentPeriodDayType &
  ModerationPopupsType &
  ModerationReports &
  ModerationShiftApproveBlock &
  UpdateModerationReports &
  SetCurrentModerationRow;

export const useModerationStore = create<State>()(devtools(createRootSlice, { name: 'moderation-store' }));
