const SelfEmployer = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ background: '#4AD280', borderRadius: '56px' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60669 4C7.86409 3.22321 8.55861 3 9.375 3H10.625C11.4414 3 12.1359 3.22321 12.3933 4H13.125C14.1605 4 15 4.89543 15 6V14C15 15.1046 14.1605 16 13.125 16H6.875C5.83947 16 5 15.1046 5 14V6C5 4.89543 5.83947 4 6.875 4H7.60669ZM7.60669 5.33333H6.875C6.52982 5.33333 6.25 5.63181 6.25 6V14C6.25 14.3682 6.52982 14.6667 6.875 14.6667H13.125C13.4702 14.6667 13.75 14.3682 13.75 14V6C13.75 5.63181 13.4702 5.33333 13.125 5.33333H12.3933C12.1359 6.11013 11.4414 6.33333 10.625 6.33333H9.375C8.55861 6.33333 7.86409 6.11013 7.60669 5.33333ZM9.375 4.33333C9.02982 4.33333 8.75 4.29848 8.75 4.66667C8.75 5.03486 9.02982 5 9.375 5H10.625C10.9702 5 11.25 5.03486 11.25 4.66667C11.25 4.29848 10.9702 4.33333 10.625 4.33333H9.375ZM12.3169 8.86193C12.561 9.12228 12.561 9.54439 12.3169 9.80474L9.81694 12.4714C9.57286 12.7318 9.17714 12.7318 8.93306 12.4714L7.68306 11.1381C7.43898 10.8777 7.43898 10.4556 7.68306 10.1953C7.92714 9.93491 8.32286 9.93491 8.56694 10.1953L9.375 11.0572L11.4331 8.86193C11.6771 8.60158 12.0729 8.60158 12.3169 8.86193Z"
        fill="white"
      />
    </svg>
  );
};

export default SelfEmployer;
