import React, { useState } from 'react';

interface Values {
  hourRate: number;
  rateAmount: number;
  hardWorker: number;
  activeMonth: number;
  superWorker: number;
}
interface PaymentsInputInterface {
  name: string;
  value: number | undefined | null;
  readOnly?: boolean;
  setOnFocus: (state: boolean) => void;
  setInputState: any;
  // send: () => void;
  canEdit: boolean;
}

const PaymentsInput: React.FC<PaymentsInputInterface> = ({
  name,
  value,
  readOnly,
  setOnFocus,
  setInputState,
  // send,
  canEdit,
}) => {
  const [inputValue, setInputValue] = useState<number | undefined | null>(value);

  const [read, setRead] = useState(true);
  return (
    <input
      type="number"
      name={name}
      value={inputValue === null ? 0 : inputValue}
      min="0"
      step={0.01}
      disabled={canEdit}
      readOnly={readOnly ? readOnly : read}
      className="block px-2 w-full text-sm border-none bg-transparent font-roboto"
      style={canEdit ? { cursor: 'not-allowed' } : {}}
      onChange={e => {
        setInputValue(Number(e.target.value));
        setInputState((values: Values) => ({
          ...values,
          [e.target.name]: Number(e.target.value),
        }));
      }}
      onFocus={() => setOnFocus(true)}
      onBlur={() => {
        setOnFocus(false);
        setRead(true);
        // send();
      }}
      onDoubleClick={() => {
        setRead(false);
      }}
    />
  );
};

export default PaymentsInput;
