import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteRequestScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteRequestScheduleMutation = { __typename?: 'Mutation', deleteZayavkaSchedule: boolean };


export const DeleteRequestScheduleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteRequestSchedule"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteZayavkaSchedule"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type DeleteRequestScheduleMutationFn = Apollo.MutationFunction<DeleteRequestScheduleMutation, DeleteRequestScheduleMutationVariables>;

/**
 * __useDeleteRequestScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteRequestScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestScheduleMutation, { data, loading, error }] = useDeleteRequestScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRequestScheduleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRequestScheduleMutation, DeleteRequestScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRequestScheduleMutation, DeleteRequestScheduleMutationVariables>(DeleteRequestScheduleDocument, options);
      }
export type DeleteRequestScheduleMutationHookResult = ReturnType<typeof useDeleteRequestScheduleMutation>;
export type DeleteRequestScheduleMutationResult = Apollo.MutationResult<DeleteRequestScheduleMutation>;
export type DeleteRequestScheduleMutationOptions = Apollo.BaseMutationOptions<DeleteRequestScheduleMutation, DeleteRequestScheduleMutationVariables>;