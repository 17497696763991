import { InviteUserForm } from 'pages/main/components';
import { useState } from 'react';
import { useMeRole } from 'store/hooks/useMeRole';
import { Role } from 'generated/graphql';
import { Modal } from 'antd';

const InviteUser = () => {
  const [showPopup, setShowPopup] = useState(false);
  const isMeAdmin = useMeRole([Role.Admin]);
  return (
    <>
      {isMeAdmin && (
        <>
          <div className="flex justify-end">
            <button
              className="btn-secondary w-full xs:w-auto"
              onClick={() => {
                setShowPopup(true);
              }}
            >
              Пригласить пользователя
            </button>
          </div>

          <Modal title="Пригласить пользователя" open={showPopup} onCancel={() => setShowPopup(false)} footer={null}>
            <InviteUserForm />
          </Modal>
        </>
      )}
    </>
  );
};

export default InviteUser;
