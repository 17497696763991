import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityPaginatedFragmentDoc } from '../../fragments/generated/FacilityPaginated.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilitiesPaginatedQueryVariables = Types.Exact<{
  input: Types.FacilityListInput;
}>;


export type FacilitiesPaginatedQuery = { __typename?: 'Query', facilitiesPaginated: { __typename?: 'PaginatedFacilities', count: number, pages: number, facilities: Array<{ __typename?: 'Facility', id: string, name: string, facilityGroupId?: string | null, metro?: string | null, naimixObjectId?: string | null, naimixObjectName?: string | null, addressString?: string | null, cityId?: string | null, is_active?: boolean | null, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string } | null, metroStations?: Array<{ __typename?: 'MetroStation', id: string, name: string }> | null, address?: { __typename?: 'Address', id: string, address: string, city?: string | null, fiasId?: string | null, settlement?: string | null, timezone: string } | null }> } };


export const FacilitiesPaginatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FacilitiesPaginated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FacilityListInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilitiesPaginated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"facilities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityPaginated"}}]}}]}}]}},...FacilityPaginatedFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useFacilitiesPaginatedQuery__
 *
 * To run a query within a React component, call `useFacilitiesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilitiesPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFacilitiesPaginatedQuery(baseOptions: Apollo.QueryHookOptions<FacilitiesPaginatedQuery, FacilitiesPaginatedQueryVariables> & ({ variables: FacilitiesPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilitiesPaginatedQuery, FacilitiesPaginatedQueryVariables>(FacilitiesPaginatedDocument, options);
      }
export function useFacilitiesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilitiesPaginatedQuery, FacilitiesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilitiesPaginatedQuery, FacilitiesPaginatedQueryVariables>(FacilitiesPaginatedDocument, options);
        }
export function useFacilitiesPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FacilitiesPaginatedQuery, FacilitiesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FacilitiesPaginatedQuery, FacilitiesPaginatedQueryVariables>(FacilitiesPaginatedDocument, options);
        }
export type FacilitiesPaginatedQueryHookResult = ReturnType<typeof useFacilitiesPaginatedQuery>;
export type FacilitiesPaginatedLazyQueryHookResult = ReturnType<typeof useFacilitiesPaginatedLazyQuery>;
export type FacilitiesPaginatedSuspenseQueryHookResult = ReturnType<typeof useFacilitiesPaginatedSuspenseQuery>;
export type FacilitiesPaginatedQueryResult = Apollo.QueryResult<FacilitiesPaginatedQuery, FacilitiesPaginatedQueryVariables>;