import * as Types from '../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditReferralProgramMutationVariables = Types.Exact<{
  input: Types.EditReferralProgramInput;
}>;


export type EditReferralProgramMutation = { __typename?: 'Mutation', editReferralProgram: { __typename?: 'ReferralProgramEntity', id: string } };


export const EditReferralProgramDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditReferralProgram"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditReferralProgramInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editReferralProgram"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type EditReferralProgramMutationFn = Apollo.MutationFunction<EditReferralProgramMutation, EditReferralProgramMutationVariables>;

/**
 * __useEditReferralProgramMutation__
 *
 * To run a mutation, you first call `useEditReferralProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReferralProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReferralProgramMutation, { data, loading, error }] = useEditReferralProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditReferralProgramMutation(baseOptions?: Apollo.MutationHookOptions<EditReferralProgramMutation, EditReferralProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReferralProgramMutation, EditReferralProgramMutationVariables>(EditReferralProgramDocument, options);
      }
export type EditReferralProgramMutationHookResult = ReturnType<typeof useEditReferralProgramMutation>;
export type EditReferralProgramMutationResult = Apollo.MutationResult<EditReferralProgramMutation>;
export type EditReferralProgramMutationOptions = Apollo.BaseMutationOptions<EditReferralProgramMutation, EditReferralProgramMutationVariables>;