import { Image } from 'assets/icons/Image';
import { errorToast } from 'utils/helpers/notify';

interface Props {
  onDownload?: (image: File) => void;
}

const DownloadImage = (props: Props) => {
  const { onDownload } = props;

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    
    if (file) {
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
      const maxSize = 10 * 1024 * 1024;

      if (!allowedTypes.includes(file.type)) {
        errorToast('Пожалуйста, загрузите изображение в формате PNG, JPG или JPEG');
        return;
      }

      if (file.size > maxSize) {
        errorToast('Размер файла не должен превышать 10 МБ');
        return;
      }

      onDownload && onDownload(file);
    }
  };

  return (
    <label
      className="text-primary cursor-pointer h-[128px] w-[128px] flex flex-col justify-center items-center text-center rounded-md border-[2px] border-dashed border-primary relative"
      onClick={e => e.stopPropagation()}
    >
      <div className="mb-2">
        <Image />
      </div>
      Загрузить изображение
      <input
        className="opacity-0 absolute z-[-1]"
        type="file"
        onChange={handleFile}
        accept="image/png, image/jpeg, image/jpg"
      />
    </label>
  );
};

export default DownloadImage;
