const CopyIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4V5C14 5.55228 14.4477 6 15 6C15.5523 6 16 5.55228 16 5V4C16 3.20435 15.6839 2.44129 15.1213 1.87868C14.5587 1.31607 13.7956 1 13 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4V13C1 13.7956 1.31607 14.5587 1.87868 15.1213C2.44129 15.6839 3.20435 16 4 16H5C5.55228 16 6 15.5523 6 15C6 14.4477 5.55228 14 5 14H4C3.73478 14 3.48043 13.8946 3.29289 13.7071C3.10536 13.5196 3 13.2652 3 13V4C3 3.73478 3.10536 3.48043 3.29289 3.29289ZM11 8C10.2043 8 9.44129 8.31607 8.87868 8.87868C8.31607 9.44129 8 10.2043 8 11V20C8 20.7957 8.31607 21.5587 8.87868 22.1213C9.44129 22.6839 10.2043 23 11 23H20C20.7957 23 21.5587 22.6839 22.1213 22.1213C22.6839 21.5587 23 20.7957 23 20V11C23 10.2043 22.6839 9.44129 22.1213 8.87868C21.5587 8.31607 20.7957 8 20 8H11ZM10.2929 10.2929C10.4804 10.1054 10.7348 10 11 10H20C20.2652 10 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H11C10.7348 21 10.4804 20.8946 10.2929 20.7071C10.1054 20.5196 10 20.2652 10 20V11C10 10.7348 10.1054 10.4804 10.2929 10.2929Z"
        fill="#397DFF"
      />
    </svg>
  );
};

export default CopyIcon;
