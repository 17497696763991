import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const customFetch = (input: RequestInfo | URL, init?: RequestInit) => {
  if (typeof init?.body === 'string') {
    const { operationName } = JSON.parse(init.body);
    return fetch(`${input}?${operationName}`, init);
  }
  return fetch(input, init);
};

const httpUploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_PUBLIC_DOMAIN}/graphql`,
  credentials: 'same-origin',
  fetch: customFetch,
});

export default httpUploadLink;
