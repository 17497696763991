import { Dayjs } from 'dayjs';

export const shiftTimeDifference = (startTime: Dayjs | null, endTime: Dayjs | null) => {
  const minutes = 60;
  const minutesInDay = 24 * 60;
  if (!endTime || !startTime) return true;
  if (endTime.diff(startTime, 'minutes') > 0 && endTime.diff(startTime, 'minutes') < minutes) {
    return true;
  }
  return startTime.diff(endTime, 'minutes') > 0 && minutesInDay - startTime.diff(endTime, 'minutes') < minutes;
};
