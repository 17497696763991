import { ICustomEvent } from 'interfaces/IEvent';
import { create, StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';

import { TRegularManualAccrual } from 'interfaces/TRegularManualAccurl';
import dayjs, { Dayjs } from 'dayjs';
import { UserFragment } from 'pages/user/api/fragments/generated/User.fragment';
import { RequestScheduleFragment } from 'pages/facilityRequests/api/fragments/generated/RequestSchedule.fragment';
import { UserIntersectFragment } from 'pages/user/api/fragments/generated/UserIntersect.fragment';
import { ResolveReportUserModel, ShiftStage } from 'generated/graphql';
import { EAutoSave } from 'enums/EAutoSave';
import { FacilityCasingFragment } from 'pages/facilityCasings/api/fragments/generated/FacilityCasing.fragment';
import { FacilityRequestShiftFragment } from 'pages/facilityRequests/api/fragments/generated/FacilityRequestShift.fragment';
import { undefStr } from '../interfaces/CustomTypes';

export type StoreSlice<T extends object> = (set: StoreApi<T>['setState'], get: StoreApi<T>['getState']) => T;

type ScheduleEvent = {
  scheduleEvent: ICustomEvent | null;
  setScheduleEvent: (value: ICustomEvent) => void;
};

const ScheduleEventSlice: StoreSlice<ScheduleEvent> = set => ({
  scheduleEvent: null,
  setScheduleEvent: scheduleEvent => set({ scheduleEvent }),
});

type Schedule = {
  typeSchedule: number;
  setTypeSchedule: (value: number) => void;
};

const ScheduleTypeSlice: StoreSlice<Schedule> = set => ({
  typeSchedule: 1,
  setTypeSchedule: typeSchedule => set({ typeSchedule }),
});

type CustomScheduleEvents = {
  customScheduleEvents: ICustomEvent[];
  addCustomScheduleEvents: (obj: ICustomEvent) => void;
  setCustomScheduleEvents: (customScheduleEvents: ICustomEvent[]) => void;
};

export const CustomScheduleEventsSlice: StoreSlice<CustomScheduleEvents> = set => ({
  customScheduleEvents: [],
  addCustomScheduleEvents: obj =>
    set(state => ({
      customScheduleEvents: [...state.customScheduleEvents, obj],
    })),
  setCustomScheduleEvents: (customScheduleEvents: ICustomEvent[] | []) => set({ customScheduleEvents }),
});

type LunchDuration = {
  lunchDuration: number;
  setLunchDuration: (lunchDuration: number) => void;
};

export const LunchDurationSlice: StoreSlice<LunchDuration> = set => ({
  lunchDuration: 0,
  setLunchDuration: lunchDuration => set({ lunchDuration }),
});

type ScheduleTime = {
  startScheduleTime: Dayjs | null;
  setStartScheduleTime: (setStartScheduleTime: Dayjs | null) => void;
  endScheduleTime: Dayjs | null;
  setEndScheduleTime: (endScheduleTime: Dayjs | null) => void;
};

export const ScheduleTimeSlice: StoreSlice<ScheduleTime> = set => ({
  startScheduleTime: dayjs(),
  setStartScheduleTime: startScheduleTime => set({ startScheduleTime }),
  endScheduleTime: dayjs(),
  setEndScheduleTime: endScheduleTime => set({ endScheduleTime }),
});

type FacilityId = {
  facilityId: string;
  setFacilityId: (facilityId: string) => void;
  facilityIdError: string;
  setFacilityIdError: (facilityIdError: string) => void;
};

export const FacilityIdSlice: StoreSlice<FacilityId> = set => ({
  facilityId: '',
  setFacilityId: facilityId => set({ facilityId }),
  facilityIdError: '',
  setFacilityIdError: facilityIdError => set({ facilityIdError }),
});

type WorkpostId = {
  workpostId?: string;
  setWorkpostId: (workpost?: string) => void;
  workpostIdError: string;
  setWorkpostIdError: (workpostError: string) => void;
};

export const WorkpostIdSlice: StoreSlice<WorkpostId> = set => ({
  workpostId: undefined,
  setWorkpostId: workpostId => set({ workpostId }),
  workpostIdError: '',
  setWorkpostIdError: workpostIdError => set({ workpostIdError }),
});

type HiddenCanceledShifts = {
  hideCanceledShifts: boolean;
  setHideCanceledShifts: (hiddenCanceledShifts: boolean) => void;
};

export const HiddenCanceledShiftsSlice: StoreSlice<HiddenCanceledShifts> = set => ({
  hideCanceledShifts: false,
  setHideCanceledShifts: hiddenCanceledShifts => set({ hideCanceledShifts: hiddenCanceledShifts }),
});

type PaymentsCheckboxes = {
  paymentCheckbox: boolean;
  paymentCheckboxes: number;
  setPaymentCheckbox: (paymentCheckbox: boolean) => void;
  setPaymentCheckboxes: (paymentCheckboxes: number) => void;
};

export const PaymentsCheckboxesSlice: StoreSlice<PaymentsCheckboxes> = set => ({
  paymentCheckbox: true,
  paymentCheckboxes: 0,
  setPaymentCheckbox: paymentCheckbox => set({ paymentCheckbox }),
  setPaymentCheckboxes: paymentCheckboxes => set({ paymentCheckboxes }),
});

type PhoneNumber = {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
};

export const PhoneNumberSlice: StoreSlice<PhoneNumber> = set => ({
  phoneNumber: '',
  setPhoneNumber: phoneNumber => set({ phoneNumber }),
});

type ManualAccrual = {
  manualAccrual?: TRegularManualAccrual;
  setManualAccrual: (data?: TRegularManualAccrual) => void;
};

export const ManualAccrualSlice: StoreSlice<ManualAccrual> = set => ({
  manualAccrual: undefined,
  setManualAccrual: manualAccrual => set({ manualAccrual: manualAccrual }),
});

type ManualAccrualUserIds = {
  manualAccrualUserIds: string[];
  setManualAccrualUserIds: (manualAccrualUserIds: string[]) => void;
};

export const ManualAccrualUserIdsSlice: StoreSlice<ManualAccrualUserIds> = set => ({
  manualAccrualUserIds: [],
  setManualAccrualUserIds: (manualAccrualUserIds: string[]) => set({ manualAccrualUserIds: manualAccrualUserIds }),
});

type DisabledUsersIds = {
  disabledUsersIds: string[];
  setDisabledUsersIds: (disabledUsersIds: string[]) => void;
};

export const DisabledUsersIdsSlice: StoreSlice<DisabledUsersIds> = set => ({
  disabledUsersIds: [],
  setDisabledUsersIds: (disabledUsersIds: string[]) => set({ disabledUsersIds }),
});

type UserShifts = {
  selectedShifts?: ICustomEvent[];
  setSelectedShifts: (selectedShifts?: ICustomEvent[]) => void;
  selectedSchedule?: ICustomEvent[];
  setSelectedSchedule: (selectedSchedule?: ICustomEvent[]) => void;
};

export const UserShiftsSlice: StoreSlice<UserShifts> = set => ({
  selectedShifts: undefined,
  setSelectedShifts: selectedShifts => set({ selectedShifts }),
  selectedSchedule: undefined,
  setSelectedSchedule: selectedSchedule => set({ selectedSchedule }),
});

type ShowSidebar = {
  showSidebar: boolean;
  setShowSidebar: (sidebar: boolean) => void;
};

export const ShowSidebarSlice: StoreSlice<ShowSidebar> = set => ({
  showSidebar: false,
  setShowSidebar: showSidebar => set({ showSidebar }),
});

type UploadFile = {
  files: FileList[] | Blob[] | [];
  setFiles: (file: FileList[] | Blob[] | []) => void;
};

export const UploadFileSlice: StoreSlice<UploadFile> = set => ({
  files: [],
  setFiles: files => set({ files }),
});

type Menu = {
  showSidebarMenu: boolean;
  setShowSidebarMenu: (menu: boolean) => void;
  tooltip: boolean;
  setTooltip: (tooltip: boolean) => void;
};

export const MenuSlice: StoreSlice<Menu> = set => ({
  showSidebarMenu: false,
  setShowSidebarMenu: menu => set({ showSidebarMenu: menu }),
  tooltip: false,
  setTooltip: tooltip => set({ tooltip }),
});

type CreateScheduleSidebar = {
  createScheduleMenu: boolean;
  setCreateScheduleMenu: (menu: boolean) => void;
};

export const CreateScheduleSidebarSlice: StoreSlice<CreateScheduleSidebar> = set => ({
  createScheduleMenu: false,
  setCreateScheduleMenu: createScheduleMenu => set({ createScheduleMenu }),
});

type SplitButton = {
  selectedIndex: number;
  setSelectedIndex: (selectedIndex: number) => void;
};

export const SplitButtonSlice: StoreSlice<SplitButton> = set => ({
  selectedIndex: 0,
  setSelectedIndex: selectedIndex => set({ selectedIndex }),
});

type TrendsFacilityAndGroup = {
  trendsFacilityId?: string | null;
  trendsGroupId?: string | null;
  setTrendsFacilityId: (trendsFacilityId?: string | null) => void;
  setTrendsGroupId: (trendsGroupId?: string | null) => void;
};

const TrendsFacilityAndGroupSlice: StoreSlice<TrendsFacilityAndGroup> = set => ({
  trendsFacilityId: undefined,
  trendsGroupId: undefined,
  setTrendsFacilityId: trendsFacilityId => set({ trendsFacilityId }),
  setTrendsGroupId: trendsGroupId => set({ trendsGroupId }),
});

type GetUsersByFacilityAndPosition = {
  usersByFacilityAndPosition?: UserFragment[];
  setUsersByFacilityAndPosition: (usersByFacilityAndPosition?: UserFragment[]) => void;
};

const GetUsersByFacilityAndPositionSlice: StoreSlice<GetUsersByFacilityAndPosition> = set => ({
  usersByFacilityAndPosition: undefined,
  setUsersByFacilityAndPosition: usersByFacilityAndPosition => set({ usersByFacilityAndPosition }),
});

type FacilityScheduleProps = {
  facilityScheduleIds: string[];
  setFacilityScheduleIds: (facilityScheduleIds: string[]) => void;
  editFacilityScheduleIds: (facilityScheduleId: string) => void;
  facilityScheduleHeaderCheckbox: boolean;
  setFacilityScheduleHeaderCheckbox: (facilityScheduleHeaderCheckbox: boolean) => void;
  facilityWorkposts?: FacilityCasingFragment[];
  setFacilityWorkposts: (facilityWorkposts: FacilityCasingFragment[]) => void;
  activeRequests: boolean;
  setActiveRequests: (activeRequests: boolean) => void;
  showGraphicSidebar: boolean;
  setShowGraphicSidebar: (value: boolean) => void;
  showShiftSidebar: boolean;
  setShowShiftSidebar: (value: boolean) => void;
  showFreeScheduleSidebar: boolean;
  setShowFreeScheduleSidebar: (value: boolean) => void;
  positionId: undefStr;
  setPositionId: (value: undefStr) => void;
  workerId: undefStr;
  setWorkerId: (value: undefStr) => void;
};

const FacilityScheduleSlice: StoreSlice<FacilityScheduleProps> = set => ({
  facilityScheduleIds: [],
  setFacilityScheduleIds: facilityScheduleIds => set({ facilityScheduleIds }),
  editFacilityScheduleIds: facilityScheduleId =>
    set(state => {
      if (state.facilityScheduleIds.includes(facilityScheduleId)) {
        return { facilityScheduleIds: state.facilityScheduleIds.filter(id => id !== facilityScheduleId) };
      } else {
        return { facilityScheduleIds: [...state.facilityScheduleIds, facilityScheduleId] };
      }
    }),
  facilityScheduleHeaderCheckbox: false,
  setFacilityScheduleHeaderCheckbox: facilityScheduleHeaderCheckbox => set({ facilityScheduleHeaderCheckbox }),
  facilityWorkposts: undefined,
  setFacilityWorkposts: facilityWorkposts => set({ facilityWorkposts }),
  activeRequests: true,
  setActiveRequests: activeRequests => set({ activeRequests }),
  showGraphicSidebar: false,
  setShowGraphicSidebar: showGraphicSidebar => set({ showGraphicSidebar }),
  showShiftSidebar: false,
  setShowShiftSidebar: showShiftSidebar => set({ showShiftSidebar }),
  showFreeScheduleSidebar: false,
  setShowFreeScheduleSidebar: showFreeScheduleSidebar => set({ showFreeScheduleSidebar }),
  positionId: undefined,
  setPositionId: positionId => set({ positionId }),
  workerId: undefined,
  setWorkerId: workerId => set({ workerId }),
});

type ResolveModerationData = {
  resolveModerationData?: ResolveReportUserModel[];
  resolveModerationPosition: string[];
  setResolveModerationData: (resolveModerationData?: ResolveReportUserModel[]) => void;
  setResolveModerationPosition: (resolveModerationPosition?: string[]) => void;
  setResolveModerationDataElement: (resolveModerationDataElement: ResolveReportUserModel) => void;
};

const ResolveModerationDataSlice: StoreSlice<ResolveModerationData> = set => ({
  resolveModerationData: undefined,
  resolveModerationPosition: [],
  setResolveModerationData: resolveModerationData => set({ resolveModerationData }),
  setResolveModerationPosition: resolveModerationPosition => set({ resolveModerationPosition }),
  setResolveModerationDataElement: resolveModerationDataElement =>
    set(state => {
      if (!state.resolveModerationData) return state;
      // return {
      //   resolveModerationData: [
      //     ...state.resolveModerationData.filter(
      //       el => el.id !== resolveModerationDataElement.id
      //     ),
      //     resolveModerationDataElement,
      //   ],
      // };
      const copyArr = [...state.resolveModerationData];
      const elIndex = state.resolveModerationData.findIndex(el => el.id === resolveModerationDataElement.id);
      copyArr.splice(elIndex, 1, resolveModerationDataElement);

      return { resolveModerationData: copyArr };
    }),
});

interface IRequestDayAtRow {
  day: dayjs.Dayjs;
  positionId: string;
  requestScheduleId: string;
}

interface IRequestSchedule extends RequestScheduleFragment {
  number?: number;
}

type RequestData = {
  requestSchedule?: IRequestSchedule | null;
  setRequestSchedule: (requestSchedule?: IRequestSchedule | null) => void;
  showEditRequestShift: boolean;
  setShowEditRequestShiftSidebar: (showEditRequestShift: boolean) => void;
  showCreateRequestShiftSidebar: boolean;
  setShowCreateRequestShiftSidebar: (showCreateRequestShiftSidebar: boolean) => void;
  requestShift?: FacilityRequestShiftFragment | null;
  setRequestShift: (requestShift?: FacilityRequestShiftFragment | null) => void;
  requestDayAtRow?: IRequestDayAtRow;
  setRequestDayAtRow: (cell?: IRequestDayAtRow) => void;
};

const RequestDataSlice: StoreSlice<RequestData> = set => ({
  requestSchedule: undefined,
  setRequestSchedule: requestSchedule => set({ requestSchedule }),
  showEditRequestShift: false,
  setShowEditRequestShiftSidebar: showEditRequestShift => set({ showEditRequestShift }),
  showCreateRequestShiftSidebar: false,
  setShowCreateRequestShiftSidebar: showCreateRequestShiftSidebar => set({ showCreateRequestShiftSidebar }),
  requestShift: undefined,
  setRequestShift: requestShift => set({ requestShift }),
  requestDayAtRow: undefined,
  setRequestDayAtRow: requestDayAtRow => set({ requestDayAtRow }),
});

type ScheduleRequestData = {
  userIntersect?: UserIntersectFragment;
  setUserIntersect: (userIntersect?: UserIntersectFragment) => void;
  freeShiftUsers: string[];
  changeFreeShiftUser: (id: string) => void;
  setFreeShiftUsers: (id: string | []) => void;
};

const ScheduleRequestDataSlice: StoreSlice<ScheduleRequestData> = set => ({
  userIntersect: undefined,
  setUserIntersect: userIntersect => set({ userIntersect }),
  freeShiftUsers: [],
  changeFreeShiftUser: id => set({ freeShiftUsers: [id] }),
  setFreeShiftUsers: id =>
    set(state => {
      if (typeof id === 'string') {
        if (state.freeShiftUsers.includes(id)) {
          return { freeShiftUsers: state.freeShiftUsers.filter(el => el !== id) };
        }
        return { freeShiftUsers: [...state.freeShiftUsers, id] };
      }
      return { freeShiftUsers: [] };
    }),
});

type EditScheduleShift = {
  id: string;
  dateFrom?: string;
  dateEnd?: string;
  stage?: ShiftStage | string;
  lunchDuration?: number | null;
  action: 'remove' | 'edit' | '';
  positionId?: string;
  schedule?: {
    id?: string | null;
    dateFrom?: string;
    dateTo?: string;
  };
  facility?: {
    id: string;
    name?: string;
    latitude?: string;
    longitude?: string;
    radius?: number;
    facilityGroupId?: string | null | undefined;
  } | null;
};

type schedule = {
  id: string;
  dateFrom?: string;
  dateTo?: string;
  action: 'remove' | 'edit' | '';
};

type EditSchedule = {
  schedule?: schedule;
  setSchedule: (schedule?: schedule) => void;
  shift?: EditScheduleShift;
  setShift: (shift?: EditScheduleShift) => void;
  showEditShiftSidebar: boolean;
  setShowEditShiftSidebar: (value: boolean) => void;
  showEditScheduleSidebar: boolean;
  setShowEditScheduleSidebar: (value: boolean) => void;
};

const EditScheduleSlice: StoreSlice<EditSchedule> = set => ({
  schedule: undefined,
  setSchedule: schedule => set({ schedule }),
  shift: undefined,
  setShift: shift => set({ shift }),
  showEditShiftSidebar: false,
  setShowEditShiftSidebar: showEditShiftSidebar => set({ showEditShiftSidebar }),
  showEditScheduleSidebar: false,
  setShowEditScheduleSidebar: showEditScheduleSidebar => set({ showEditScheduleSidebar }),
});

type StopCooperationType = {
  stopCooperationDate: dayjs.Dayjs | null;
  setStopCooperationDate: (stopCooperationDate: dayjs.Dayjs | null) => void;
  stopCooperationComment?: string;
  setStopCooperationComment: (stopCooperationComment?: string) => void;
};

const StopCooperationTypeSlice: StoreSlice<StopCooperationType> = set => ({
  stopCooperationDate: null,
  setStopCooperationDate: stopCooperationDate => set({ stopCooperationDate }),
  stopCooperationComment: '',
  setStopCooperationComment: stopCooperationComment => set({ stopCooperationComment }),
});

type AutoSave = {
  autoSaveState: EAutoSave.LOADING | EAutoSave.SAVED | EAutoSave.ERROR;
  setAutoSaveState: (autoSaveState: EAutoSave) => void;
};

const AutoSaveStateSlice: StoreSlice<AutoSave> = set => ({
  autoSaveState: EAutoSave.SAVED,
  setAutoSaveState: autoSaveState => set({ autoSaveState }),
});

type TFirstVisit = {
  firstVisit: boolean;
  setFirstVisit: (firstVisit: boolean) => void;
};

const FirstVisitSlice: StoreSlice<TFirstVisit> = set => ({
  firstVisit: false,
  setFirstVisit: firstVisit => set({ firstVisit }),
});

//map
type System = {
  system: string;
  setSystem: (system: string) => void;
  cluster: boolean;
  setCluster: (cluster: boolean) => void;
  profession: string;
  setProfession: (profession: string) => void;
};

export const SystemSlice: StoreSlice<System> = set => ({
  system: '',
  setSystem: system => set({ system }),
  cluster: false,
  setCluster: cluster => set({ cluster }),
  profession: '',
  setProfession: profession => set({ profession }),
});

const createRootSlice = (set: StoreApi<any>['setState'], get: StoreApi<any>['getState']) => ({
  ...ScheduleEventSlice(set, get),
  ...ScheduleTypeSlice(set, get),
  ...CustomScheduleEventsSlice(set, get),
  ...LunchDurationSlice(set, get),
  ...ScheduleTimeSlice(set, get),
  ...FacilityIdSlice(set, get),
  ...WorkpostIdSlice(set, get),
  ...HiddenCanceledShiftsSlice(set, get),
  ...PaymentsCheckboxesSlice(set, get),
  ...PhoneNumberSlice(set, get),
  ...ManualAccrualSlice(set, get),
  ...ManualAccrualUserIdsSlice(set, get),
  ...DisabledUsersIdsSlice(set, get),
  ...UserShiftsSlice(set, get),
  ...ShowSidebarSlice(set, get),
  ...UploadFileSlice(set, get),
  ...MenuSlice(set, get),
  ...CreateScheduleSidebarSlice(set, get),
  ...SplitButtonSlice(set, get),
  ...TrendsFacilityAndGroupSlice(set, get),
  ...GetUsersByFacilityAndPositionSlice(set, get),
  ...ResolveModerationDataSlice(set, get),
  ...RequestDataSlice(set, get),
  ...ScheduleRequestDataSlice(set, get),
  ...EditScheduleSlice(set, get),
  ...StopCooperationTypeSlice(set, get),
  ...AutoSaveStateSlice(set, get),
  ...FacilityScheduleSlice(set, get),
  ...FirstVisitSlice(set, get),
  ...SystemSlice(set, get),
});

type State = ScheduleEvent &
  Schedule &
  CustomScheduleEvents &
  LunchDuration &
  ScheduleTime &
  FacilityId &
  WorkpostId &
  HiddenCanceledShifts &
  PaymentsCheckboxes &
  PhoneNumber &
  ManualAccrual &
  ManualAccrualUserIds &
  DisabledUsersIds &
  UserShifts &
  ShowSidebar &
  UploadFile &
  Menu &
  CreateScheduleSidebar &
  SplitButton &
  TrendsFacilityAndGroup &
  GetUsersByFacilityAndPosition &
  ResolveModerationData &
  RequestData &
  ScheduleRequestData &
  EditSchedule &
  StopCooperationType &
  AutoSave &
  FacilityScheduleProps &
  TFirstVisit &
  System;

export const useStore = create<State>()(devtools(createRootSlice, { name: 'dashboard-store' }));
