import { createRoot } from 'react-dom/client';
import 'dayjs/locale/ru';
import reportWebVitals from './reportWebVitals';

import './styles/fonts.css';
import './styles/rbc.css';
import './styles/globals.css';
import './styles/ant.css';
import App from './app/App';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

reportWebVitals();
