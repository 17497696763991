import LoopIcon from 'assets/icons/Loop';
import { useState } from 'react';
import { getImagePath } from 'utils/helpers/get/getImagePath';

interface Props {
  img: any;
  index: number;
  setInitialSlideIndex: any;
  isDelete?: boolean;
  handleDelete?: (photoId: string) => void;
  handleShowSlider: () => void;
  photoIds?: string[];
  isCover?: boolean;
}

const PhotoItem = (props: Props) => {
  const { img, index, setInitialSlideIndex, isDelete, handleDelete, photoIds, isCover, handleShowSlider } = props;
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="w-[128px] cursor-pointer hover:opacity-80 transition-all .2s relative"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        handleShowSlider();
        setInitialSlideIndex(index);
      }}
    >
      {isHover && (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <LoopIcon />
        </div>
      )}

      {isDelete && (
        <input
          onClick={e => e.stopPropagation()}
          type="checkbox"
          id="pay"
          checked={photoIds?.includes(img.id)}
          className="checkbox-primary absolute top-2 left-2"
          onChange={() => handleDelete && handleDelete(img.id)}
        />
      )}

      <div className="flex justify-center items-center">
        {isCover && img?.file?.id && (
          <div className="absolute bg-white/90 text-center w-[100px] rounded-md p-1">Обложка вакансии</div>
        )}

        {img?.file && (
          <img
            src={getImagePath(img?.file?.path)}
            alt={`uploaded-${img?.path}`}
            className={'w-[128px] h-[128px] rounded-md object-cover'}
          />
        )}
      </div>
    </div>
  );
};

export default PhotoItem;
