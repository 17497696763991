import { timeDifference } from 'utils/helpers/timeDifference';
import dayjs from 'dayjs';
import { MapVacancyFragment } from '../api/fragments/generated/MapVacancy.fragment';

export const durationText = (vacancy?: MapVacancyFragment) => {
  const duration = timeDifference(dayjs(vacancy?.timeStart, 'HH:mm'), dayjs(vacancy?.timeEnd, 'HH:mm'));
  const hoursDuration = duration / 60 ? (duration - (duration % 60)) / 60 : null;
  const minutesDuration = duration % 60 ? duration % 60 : null;
  let hours = '';
  let minutes = '';
  if (hoursDuration) {
    hours = `${hoursDuration} ч.`;
  }
  if (minutesDuration) {
    minutes = ` ${minutesDuration} мин.`;
  }
  return `(${hours}${minutes})`;
};

export const lunchDurationText = (lunchDuration?: number | null) => {
  if (!lunchDuration) return 'Без обеда';
  const hoursDuration = lunchDuration / 60 ? (lunchDuration - (lunchDuration % 60)) / 60 : null;
  const minutesDuration = lunchDuration % 60 ? lunchDuration % 60 : null;
  let hours = '';
  let minutes = '';
  if (hoursDuration) {
    hours = `${hoursDuration} ч.`;
  }
  if (minutesDuration) {
    minutes = ` ${minutesDuration} мин.`;
  }
  return `(${hours}${minutes})`;
};
