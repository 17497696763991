import { useLeadLazyQuery } from '../../../pages/lead/api/queries/generated/Lead';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useLead = () => {
  const router = useParams();
  const id = router.leadId;
  const [loadData, { data, loading, error, called }] = useLeadLazyQuery();

  useEffect(() => {
    if (id) {
      loadData({
        variables: {
          id,
        },
      });
    }
  }, [id, loadData]);

  const lead = data?.lead;

  if (id) {
    if (!called) return { loading: true, id };
    return { id, loading, lead, error };
  }
  return {};
};
