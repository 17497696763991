import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PromotionEntityFragmentDoc } from '../../../../facilityGroup/api/fragments/generated/PromotionEntity.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetToDefaultPromotionSettingsMutationVariables = Types.Exact<{
  input: Types.ResetToDefaultPromotionSettingsInput;
}>;


export type ResetToDefaultPromotionSettingsMutation = { __typename?: 'Mutation', resetToDefaultPromotionSettings: { __typename?: 'PromotionEntity', id: string, badShiftCount: number, dateTimeEnd?: any | null, dateTimeStart: any, name: string, repeatCondition: Types.PromotionRepeatConditionEnum, requirementBonus: Types.PromotionRequirementBonusEnum, requirementShiftCount: number, rewardSum: number, rewardType: Types.PromotionRewardTypeEnum, status: Types.PromotionStatusEnum, childPromotions?: Array<{ __typename?: 'PromotionEntity', facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null } };


export const ResetToDefaultPromotionSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetToDefaultPromotionSettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetToDefaultPromotionSettingsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetToDefaultPromotionSettings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PromotionEntity"}}]}}]}},...PromotionEntityFragmentDoc.definitions]} as unknown as DocumentNode;
export type ResetToDefaultPromotionSettingsMutationFn = Apollo.MutationFunction<ResetToDefaultPromotionSettingsMutation, ResetToDefaultPromotionSettingsMutationVariables>;

/**
 * __useResetToDefaultPromotionSettingsMutation__
 *
 * To run a mutation, you first call `useResetToDefaultPromotionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetToDefaultPromotionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetToDefaultPromotionSettingsMutation, { data, loading, error }] = useResetToDefaultPromotionSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetToDefaultPromotionSettingsMutation(baseOptions?: Apollo.MutationHookOptions<ResetToDefaultPromotionSettingsMutation, ResetToDefaultPromotionSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetToDefaultPromotionSettingsMutation, ResetToDefaultPromotionSettingsMutationVariables>(ResetToDefaultPromotionSettingsDocument, options);
      }
export type ResetToDefaultPromotionSettingsMutationHookResult = ReturnType<typeof useResetToDefaultPromotionSettingsMutation>;
export type ResetToDefaultPromotionSettingsMutationResult = Apollo.MutationResult<ResetToDefaultPromotionSettingsMutation>;
export type ResetToDefaultPromotionSettingsMutationOptions = Apollo.BaseMutationOptions<ResetToDefaultPromotionSettingsMutation, ResetToDefaultPromotionSettingsMutationVariables>;