import React from 'react';
import Skeleton from 'antd/es/skeleton';

type Props = {
  children: React.ReactElement;
  loading: boolean;
}

const TodaySkeleton = ({ loading, children }: Props) => {
  return <>{loading ? <Skeleton.Button active /> : children}</>;
};

export default TodaySkeleton;
