import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityGroupFragmentDoc } from '../../fragments/generated/FacilityGroup.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilityGroupUploadImageMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  image: Types.Scalars['Upload']['input'];
}>;


export type FacilityGroupUploadImageMutation = { __typename?: 'Mutation', facilityGroupUploadImage: { __typename?: 'FacilityGroup', id: string, name: string, balance: number, logo?: { __typename?: 'File', id: string, mimetype: string, originalName?: string | null, path: string, size: number } | null, clientDirectors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null, groupManagers?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null } };


export const FacilityGroupUploadImageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FacilityGroupUploadImage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"image"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilityGroupUploadImage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"image"},"value":{"kind":"Variable","name":{"kind":"Name","value":"image"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityGroup"}}]}}]}},...FacilityGroupFragmentDoc.definitions]} as unknown as DocumentNode;
export type FacilityGroupUploadImageMutationFn = Apollo.MutationFunction<FacilityGroupUploadImageMutation, FacilityGroupUploadImageMutationVariables>;

/**
 * __useFacilityGroupUploadImageMutation__
 *
 * To run a mutation, you first call `useFacilityGroupUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacilityGroupUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facilityGroupUploadImageMutation, { data, loading, error }] = useFacilityGroupUploadImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useFacilityGroupUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<FacilityGroupUploadImageMutation, FacilityGroupUploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FacilityGroupUploadImageMutation, FacilityGroupUploadImageMutationVariables>(FacilityGroupUploadImageDocument, options);
      }
export type FacilityGroupUploadImageMutationHookResult = ReturnType<typeof useFacilityGroupUploadImageMutation>;
export type FacilityGroupUploadImageMutationResult = Apollo.MutationResult<FacilityGroupUploadImageMutation>;
export type FacilityGroupUploadImageMutationOptions = Apollo.BaseMutationOptions<FacilityGroupUploadImageMutation, FacilityGroupUploadImageMutationVariables>;