import SimpleInput from 'ui/input';
import SimpleSelect from 'ui/select';

import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import React, { useEffect, useMemo, useState } from 'react';
import { erpReg } from 'utils/helpers/constVariables';
import { useMeRole } from 'store/hooks/useMeRole';
import DadataAddress from 'components/dadata/address';
import { useMapStore } from '../../lib/hooks/useMapStore';
import { useSearchMetroStationsLazyQuery } from 'pages/facility/api/queries/generated/SearchMetroStations';
import { MetroLineFragment } from 'pages/facility/api/fragments/generated/MetroLine.fragment';
import Select from 'antd/es/select';
import { filterFacilityGroups } from 'utils/helpers/filters/filterFacilityGroups';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useCities } from 'utils/api/useCities';
import { getCity } from 'utils/helpers/get/getCity';
import { Role } from 'generated/graphql';
import { useLegalEntities } from 'utils/api/useLegalEntities';
import { useGetNaimixObjects } from 'utils/api/useNaimixObjects';
import { useLocation } from 'react-router';

interface IMainInfoFacility {
  values: any;
  touched: any;
  errors: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any) => void;
}

const MainInfoFacility = React.memo(({ values, touched, errors, handleChange, setFieldValue }: IMainInfoFacility) => {
  const { pathname } = useLocation();

  const isGroups = pathname.includes('groups');

  const [treeData, setTreeData] = useState<MetroLineFragment[]>([]);
  const { facilitiesGroups, loading: loadingFacilityGroups } = useSelectFacilityGroups();
  const { facilities } = useSelectFacilities();
  const { cities, loading: loadingCities } = useCities();
  const facilityGroupsOptions = useMemo(
    () => filterFacilityGroups({ facilitiesGroups, facilities, cityId: values.cityId }),
    [facilities, facilitiesGroups, values.cityId],
  );
  const setLongitude = useMapStore(state => state.setLongitude);
  const setLatitude = useMapStore(state => state.setLatitude);
  const { legalEntities } = useLegalEntities();
  const [loadMetroStations] = useSearchMetroStationsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setTreeData(data?.searchMetroStations.lines || []);
    },
  });
  const { namixObjects } = useGetNaimixObjects(values.legalEntityId);
  const isUserClient = useMeRole([Role.ClientDirector, Role.ClientManager]);
  useEffect(() => {
    const city = getCity({ cityId: values.cityId, cities })?.name;
    if (!city) return;
    loadMetroStations({
      variables: {
        city,
        search: '',
      },
    });
  }, [cities, loadMetroStations, values.cityId]);
  return (
    <div className="grid grid-cols-8 gap-5">
      <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">Основная информация</span>
      <div className={'col-span-full flex gap-x-5'}>
        {!isGroups && (
          <SimpleSelect
            divClassName="flex-[0_1_180px]"
            popupClassName={'max-w-[300px]'}
            label="Группа"
            onChange={value => {
              setFieldValue('group', value);
            }}
            value={values.group}
            placeholder="Группа"
            options={facilitiesGroups || facilityGroupsOptions}
            loading={loadingFacilityGroups}
            validation
            showText={isUserClient}
            error={touched.group && errors.group ? errors.group : ''}
          />
        )}

        <SimpleInput
          divClassName="flex-[1_0]"
          label="Название объекта"
          placeholder="Введите название объекта"
          onChange={handleChange}
          value={values.name}
          type="text"
          name="name"
          showText={isUserClient}
          error={touched.name && errors.name ? errors.name : ''}
        />

        <SimpleSelect
          divClassName="flex-[1_0]"
          popupClassName={'max-w-[300px]'}
          label="Город"
          onChange={value => {
            setFieldValue('cityId', value);
          }}
          value={values.cityId}
          placeholder="Выберите город"
          options={cities}
          loading={loadingCities}
          validation
          showText={isUserClient}
          error={touched.cityId && errors.cityId ? errors.cityId : ''}
        />
      </div>

      <div className={'col-span-full flex gap-x-5'}>
        <div className={'flex-[1_0]'}>
          <DadataAddress
            label={'Адрес'}
            textValue={values.addressString}
            onChange={res => {
              if (res) {
                setFieldValue('addressString', res.value);
                setFieldValue('fiasId', res.data.fias_id);
                setFieldValue('cityFiasId', res.data.city_fias_id);
                setLatitude(res.data.geo_lat);
                setLongitude(res.data.geo_lon);
              }
            }}
            onType={value => {
              setFieldValue('addressString', value);
            }}
            filterLocations={[{ city: getCity({ cityId: values.cityId, cities })?.name }]}
            placeholder="Введите адрес"
            error={touched.addressString && errors.addressString ? errors.addressString : ''}
          />
        </div>

        <SimpleInput
          divClassName="flex-[0_0_180px]"
          label="ERP ID"
          placeholder="Введите ERP ID"
          onChange={e => {
            const value = e.target.value;
            if (erpReg.test(value) || value === '') {
              handleChange(e);
            }
          }}
          value={values.externalId}
          type="text"
          name="externalId"
          showText={isUserClient}
        />
      </div>
      <div className={'col-span-full'}>
        <label className="label-primary">Станция метро</label>
        <Select
          mode="multiple"
          allowClear
          showSearch
          // tagRender={TagRender}
          filterOption={(input, option) => (option?.name.toLowerCase() ?? '').includes(input.toLowerCase())}
          className={'w-full'}
          maxTagCount={'responsive'}
          placeholder={'Введите станции метро'}
          onChange={value => {
            setFieldValue('metroStationIds', value);
          }}
          value={values.metroStationIds}
          options={treeData.map(data => ({
            ...data,
            label: (
              <div className={'flex items-center gap-x-1'}>
                <span className={'w-2 h-2 rounded-full'} style={{ backgroundColor: `#${data.color}` }}></span>
                <span style={{ color: data.color }}>{data.name}</span>
              </div>
            ),
            options: data.stations.map(station => ({
              ...station,
              value: station.id,
              label: (
                <div className={'flex items-center gap-x-1'}>
                  <span className={'w-2 h-2 rounded-full mr-2'} style={{ backgroundColor: `#${station.color}` }}></span>
                  <span style={{ color: station.color }}>{station.name}</span>
                </div>
              ),
            })),
          }))}
        />
      </div>
      <hr className={'border-smena_gray-30 col-span-full'} />
      <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">Связь с Наймикс</span>
      <div className={'flex col-span-full gap-x-6'}>
        <SimpleSelect
          divClassName="flex-[1_0]"
          label="Юр. лицо в Наймикс"
          onChange={value => {
            setFieldValue('legalEntityId', value);
          }}
          value={values.legalEntityId}
          allOption={'Все юр. лица'}
          placeholder={'Не выбрано'}
          options={legalEntities}
        />
        <SimpleSelect
          divClassName="flex-[1_0]"
          label="Объект"
          onChange={value => {
            setFieldValue('naimixObjectId', value);
            setFieldValue('naimixObjectName', namixObjects?.find(el => el.id === value)?.name);
          }}
          value={values.naimixObjectId}
          allOption={'Все объекты'}
          placeholder={'Не выбрано'}
          options={namixObjects}
        />
        <SimpleInput
          divClassName="flex-[1_0]"
          label="ID сценария онбординга"
          placeholder="Введите ID сценария онбординга"
          regText
          onChange={handleChange}
          value={values.naimixScenarioNumber}
          type="text"
          name="naimixScenarioNumber"
          showText={isUserClient}
        />
      </div>
      <hr className={'border-smena_gray-30 col-span-full'} />
    </div>
  );
});

export default MainInfoFacility;
