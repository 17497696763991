import { useCitiesLazyQuery } from 'graphql/queries/generated/City';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo } from 'react';

export const useCities = () => {
  const [getCities, { data, loading, error }] = useCitiesLazyQuery({ variables: { input: {} } });

  useEffect(() => {
    getCities();
  }, [getCities]);

  const cities = useMemo(() => sortBy(data?.cities, el => el.name), [data?.cities]);
  return { cities, loading, error };
};
