import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserNaimixWorkStatusMutationVariables = Types.Exact<{
  input: Types.UpdateUserNaimixWorkStatusInput;
}>;


export type UpdateUserNaimixWorkStatusMutation = { __typename?: 'Mutation', updateUserNaimixWorkStatus: { __typename?: 'User', allowWorkWithoutNaimix: boolean } };


export const UpdateUserNaimixWorkStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserNaimixWorkStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserNaimixWorkStatusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserNaimixWorkStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"allowWorkWithoutNaimix"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateUserNaimixWorkStatusMutationFn = Apollo.MutationFunction<UpdateUserNaimixWorkStatusMutation, UpdateUserNaimixWorkStatusMutationVariables>;

/**
 * __useUpdateUserNaimixWorkStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserNaimixWorkStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNaimixWorkStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNaimixWorkStatusMutation, { data, loading, error }] = useUpdateUserNaimixWorkStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNaimixWorkStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNaimixWorkStatusMutation, UpdateUserNaimixWorkStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserNaimixWorkStatusMutation, UpdateUserNaimixWorkStatusMutationVariables>(UpdateUserNaimixWorkStatusDocument, options);
      }
export type UpdateUserNaimixWorkStatusMutationHookResult = ReturnType<typeof useUpdateUserNaimixWorkStatusMutation>;
export type UpdateUserNaimixWorkStatusMutationResult = Apollo.MutationResult<UpdateUserNaimixWorkStatusMutation>;
export type UpdateUserNaimixWorkStatusMutationOptions = Apollo.BaseMutationOptions<UpdateUserNaimixWorkStatusMutation, UpdateUserNaimixWorkStatusMutationVariables>;