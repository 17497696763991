import Plus from 'assets/icons/plus';
import { Link, useParams } from 'react-router-dom';
import { useCreateNotifyLetterMutation } from 'pages/pushes/api/mutations/generated/CreateNotifyLetter';
import { getPushStatus } from 'utils/helpers/get/getPushStatus';
import clsx from 'clsx';

interface Props {
  notifications?: any[];
  draftId?: string;
}

const PushNotifications = ({ draftId, notifications }: Props) => {
  const [createPushNotification] = useCreateNotifyLetterMutation();
  const { settingId } = useParams();

  const addPushNotification = async (event: any) => {
    event.preventDefault();

    const id = await createPushNotification({
      variables: { input: { referralProgramId: settingId ? settingId : draftId ? draftId : '' } },
    }).then(({ data }) => data?.createNotifyLetter.id);

    if (id) {
      window.open(`/pushes/${id}`, '_blank');
    }
  };

  return (
    <>
      <span className="Subtitle2 text-smena_text-secondary">Пуш-уведомление</span>

      <div className="grid grid-cols-2 gap-10 overflow-hidden">
        <button
          className="flex justify-center items-center gap-3 w-full border-[1px] border-dashed border-primary rounded-[8px] text-primary h-[100px]"
          type="button"
          onClick={addPushNotification}
        >
          <span className="inline-flex">
            <Plus />
          </span>
          Добавить пуш-уведомление
        </button>

        {notifications?.map(notification => {
          const { statusRu, className } = getPushStatus(notification.status);

          return (
            <Link
              key={notification.id}
              to={`/pushes/${notification.id}`}
              className="p-5 border-1.5 rounded-[10px] w-full"
            >
              <div className="H4 mb-1 text-ellipsis overflow-hidden text-nowrap">{notification.name}</div>
              <div className={clsx(className, `w-[170px] text-center rounded-[10px]`)}>{statusRu}</div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default PushNotifications;
