import TrendSelects from './selects';
import { useMeRole } from 'store/hooks/useMeRole';
import FinishedAndGoodShiftsRatio from './finishedAndGoodShiftsRatio';
import { AcceptedShiftsChart } from './acceptedShifts';
import { UserLatenessChart } from './latecomers';
import FinishedAndGoodShifts from './finishedAndGoodShifts';
import { WorkerOnShiftChart } from './workerOnShift';
import { AllUsersChart } from './allUsers';
import { Role } from 'generated/graphql';

const Trends = () => {
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  return (
    <div className="trendsBlock">
      <div className="grid grid-cols-[min-content,_auto] col-span-full justify-between items-center">
        <span className="Subtitle1 text-smena_text-secondary">Тренды</span>
        <TrendSelects />
      </div>
      <div className="col-span-6 grid gap-8 grid-rows-3">
        <UserLatenessChart />
        <AcceptedShiftsChart />
        {isMeAdminOrSupervisor && <FinishedAndGoodShifts />}
      </div>
      <div className="col-span-6 grid gap-8 grid-rows-3">
        <WorkerOnShiftChart />
        {isMeAdminOrSupervisor && <AllUsersChart />}
        {isMeAdminOrSupervisor && <FinishedAndGoodShiftsRatio />}
      </div>
    </div>
  );
};
export default Trends;
