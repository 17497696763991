import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { StoryFragmentDoc } from '../../../../story/api/fragments/generated/Strory.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStoryPaginationListQueryVariables = Types.Exact<{
  input: Types.StoryListInput;
}>;


export type GetStoryPaginationListQuery = { __typename?: 'Query', getStoryPaginationList: { __typename?: 'PaginatedStory', pages: number, count: number, stories: Array<{ __typename?: 'Story', id: string, updatedAt: any, stage?: Types.StoryStage | null, title: string, erpSourceId?: string | null, erpGroupId?: string | null, iconBackgroundColor: string, iconTitle?: string | null, viewsCount: number, pushTitle?: string | null, pushMessage?: string | null, icon?: { __typename?: 'File', id: string, path: string } | null, publicCondition?: { __typename?: 'PublicCondition', recipientsCount: number, id: string, useCondition: boolean, pushToUnassigned: Types.PushToUnassignedFilter, indefinitely: boolean, publishFrom?: any | null, publishTo?: any | null, roles: Array<Types.Role>, interval?: number | null, cities?: Array<{ __typename?: 'City', id: string, name: string, latitude: string, longitude: string, timeZone: string, externalId?: string | null }> | null, facilities?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, facilityGroups?: Array<{ __typename?: 'FacilityGroup', id: string, name: string }> | null, positions?: Array<{ __typename?: 'Position', id: string, name: string }> | null } | null, storySlides?: Array<{ __typename?: 'StorySlide', id: string, type?: Types.StorySlideType | null, title: string, message: string, questionOne: string, questionTwo: string, questionThree: string, backgroundColor: string, buttonOneAction: string, buttonOneLabel: string, buttonOneType?: Types.StorySlideButtonType | null, buttonTwoAction: string, buttonTwoLabel: string, buttonTwoType?: Types.StorySlideButtonType | null, index: number, storyId: string, image?: { __typename?: 'File', path: string } | null }> | null }> } };


export const GetStoryPaginationListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStoryPaginationList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StoryListInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStoryPaginationList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"stories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Story"}}]}}]}}]}},...StoryFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetStoryPaginationListQuery__
 *
 * To run a query within a React component, call `useGetStoryPaginationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryPaginationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryPaginationListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoryPaginationListQuery(baseOptions: Apollo.QueryHookOptions<GetStoryPaginationListQuery, GetStoryPaginationListQueryVariables> & ({ variables: GetStoryPaginationListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoryPaginationListQuery, GetStoryPaginationListQueryVariables>(GetStoryPaginationListDocument, options);
      }
export function useGetStoryPaginationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoryPaginationListQuery, GetStoryPaginationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoryPaginationListQuery, GetStoryPaginationListQueryVariables>(GetStoryPaginationListDocument, options);
        }
export function useGetStoryPaginationListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStoryPaginationListQuery, GetStoryPaginationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStoryPaginationListQuery, GetStoryPaginationListQueryVariables>(GetStoryPaginationListDocument, options);
        }
export type GetStoryPaginationListQueryHookResult = ReturnType<typeof useGetStoryPaginationListQuery>;
export type GetStoryPaginationListLazyQueryHookResult = ReturnType<typeof useGetStoryPaginationListLazyQuery>;
export type GetStoryPaginationListSuspenseQueryHookResult = ReturnType<typeof useGetStoryPaginationListSuspenseQuery>;
export type GetStoryPaginationListQueryResult = Apollo.QueryResult<GetStoryPaginationListQuery, GetStoryPaginationListQueryVariables>;