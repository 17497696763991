import { Role, WorkPostStatus } from 'generated/graphql';
import { ClientUserFragment } from 'pages/users/api/fragments/generated/ClientUser.fragment';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';
import React, { useMemo } from 'react';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import uniqueArray from 'utils/helpers/uniqueArray';
import { userRole } from '../../../../main/lib/helpers/userRole';
import FacilityBubbleBlock from './FacilityBubbleBlock';

type Props = {
  user: UsersPaginatedFragment | ClientUserFragment;
  showAcross?: boolean;
};

const FacilityCell = ({ user, showAcross }: Props) => {
  const isUserWorker = userRole([Role.Worker], user);
  const isUserForeman = userRole([Role.Foreman], user);
  const isUserSupervisor = userRole([Role.Supervisor], user);
  const isUserClientManager = userRole([Role.ClientManager], user);

  const isMultiFacilities = useMemo(() => {
    if (isUserWorker || isUserForeman) {
      return !!uniqueArray({ array: user?.workposts, field: 'facilityId' }).length && !!user?.Facility_FOREMAN?.length;
    }
    return false;
  }, [isUserForeman, isUserWorker, user?.Facility_FOREMAN?.length, user?.workposts]);

  const userFacilities = useMemo(() => {
    if (isUserWorker) {
      return uniqueArray({
        array: user?.workposts?.filter(el => el.status === WorkPostStatus.Active),
        field: 'facilityId',
      }).map(el => el.facility);
    }
    if (isUserForeman) {
      return user.Facility_FOREMAN;
    }
    if (isUserClientManager) {
      return user.facilitiesManager;
    }
    if (isUserSupervisor) {
      return user.Facility_SUPERVISOR;
    }
  }, [
    isUserClientManager,
    isUserForeman,
    isUserSupervisor,
    isUserWorker,
    user.Facility_FOREMAN,
    user.Facility_SUPERVISOR,
    user.facilitiesManager,
    user?.workposts,
  ]);
  const userSecondaryFacilities = useMemo(() => {
    if (isUserWorker) {
      return user.Facility_FOREMAN;
    }
    if (isUserForeman) {
      return uniqueArray({
        array: user?.workposts?.filter(el => el.status === WorkPostStatus.Active),
        field: 'facilityId',
      }).map(el => el.facility);
    }
  }, [isUserForeman, isUserWorker, user.Facility_FOREMAN, user?.workposts]);
  const userSecondaryRole = userSecondaryFacilities?.length
    ? isUserWorker
      ? getRoleRu(Role.Foreman)
      : getRoleRu(Role.Worker)
    : false;
  if (!userFacilities?.length) return null;
  return (
    <div className={'flex flex-col gap-y-1'}>
      {isMultiFacilities ? (
        <span className={'Caption-small text-smena_text-secondary'}>{getRoleRu(user?.role)}</span>
      ) : null}
      <FacilityBubbleBlock
        userFacilities={userFacilities}
        user={user}
        isUserWorker={isUserWorker}
        showAcross={showAcross}
      />
      {userSecondaryRole ? (
        <>
          <span className={'Caption-small text-smena_text-secondary'}>{userSecondaryRole}</span>
          <FacilityBubbleBlock
            userFacilities={userSecondaryFacilities}
            user={user}
            isUserWorker={isUserWorker}
            showAcross={showAcross}
          />
        </>
      ) : null}
    </div>
  );
};

export default FacilityCell;
