import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useStore } from 'store';

import { successToast } from 'utils/helpers/notify';
import { writeUserShiftsCache } from './writeCache';
import { useRefuseScheduleMutation } from 'pages/userSchedule/api/mutations/generated/RefuseSchedule';
import { useCurrentUser } from '../../../../utils/api/useUser';
import Modal from 'ui/modal';

const EditScheduleButtons = () => {
  const [showModal, setShowModal] = useState(false);

  const { setSchedule, schedule } = useStore();
  const { user } = useCurrentUser();
  const [params] = useSearchParams();
  const dateFrom = params.get('dateFrom')
    ? dayjs(params.get('dateFrom')).startOf('day').valueOf().toString()
    : dayjs().startOf('month').valueOf().toString();
  const dateTo = params.get('dateTo')
    ? dayjs(params.get('dateTo')).endOf('day').valueOf().toString()
    : dayjs().endOf('month').valueOf().toString();

  const [refuseSchedule, { loading }] = useRefuseScheduleMutation();

  const showRefusePopup = useCallback(() => {
    setShowModal(true);
  }, []);

  const exitHandler = useCallback(() => {
    setSchedule({ id: '', action: '' });
  }, [setSchedule]);

  const removeSchedule = useCallback(() => {
    if (schedule) {
      refuseSchedule({
        variables: {
          id: schedule.id,
        },
        update: (store, { data }) => {
          if (user?.id) {
            writeUserShiftsCache({
              store,
              data: data?.refuseSchedule,
              variables: { dateFrom, dateTo, userId: user.id },
            });
          }
        },
      })
        .then(() => successToast('График отменен'))
        .finally(() => {
          setSchedule({ id: '', action: '' });
          setShowModal(false);
        });
    }
  }, [user?.id, dateFrom, dateTo, refuseSchedule, schedule, setSchedule]);

  return (
    <div className="flex flex-col items-start">
      <button className="btn-reject_secondary mb-2" onClick={showRefusePopup}>
        Отменить
      </button>
      <button className="btn-stroke_big" onClick={exitHandler}>
        Выход
      </button>

      <Modal
        type="delete"
        title="Отменить график?"
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={removeSchedule}
        okButtonProps={{ disabled: loading }}
        okText="Отменить"
      >
        Этот график подтвержден и у него есть исполнитель. Все участники получат уведомления об отмене.
      </Modal>
    </div>
  );
};

export default EditScheduleButtons;
