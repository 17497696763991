import * as Types from '../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveReferralProgramMutationVariables = Types.Exact<{
  input: Types.ArchiveReferralProgramInput;
}>;


export type ArchiveReferralProgramMutation = { __typename?: 'Mutation', archiveReferralProgram: boolean };


export const ArchiveReferralProgramDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ArchiveReferralProgram"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ArchiveReferralProgramInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"archiveReferralProgram"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type ArchiveReferralProgramMutationFn = Apollo.MutationFunction<ArchiveReferralProgramMutation, ArchiveReferralProgramMutationVariables>;

/**
 * __useArchiveReferralProgramMutation__
 *
 * To run a mutation, you first call `useArchiveReferralProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveReferralProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveReferralProgramMutation, { data, loading, error }] = useArchiveReferralProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveReferralProgramMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveReferralProgramMutation, ArchiveReferralProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveReferralProgramMutation, ArchiveReferralProgramMutationVariables>(ArchiveReferralProgramDocument, options);
      }
export type ArchiveReferralProgramMutationHookResult = ReturnType<typeof useArchiveReferralProgramMutation>;
export type ArchiveReferralProgramMutationResult = Apollo.MutationResult<ArchiveReferralProgramMutation>;
export type ArchiveReferralProgramMutationOptions = Apollo.BaseMutationOptions<ArchiveReferralProgramMutation, ArchiveReferralProgramMutationVariables>;