export const CLOTHING_MALE_SIZE = [
  { id: '44', name: '44 (XS)' },
  { id: '46', name: '46 (S)' },
  { id: '48', name: '48 (M)' },
  { id: '50', name: '50 (L)' },
  { id: '52', name: '52 (XL)' },
  { id: '54-56', name: '54-56 (XXL)' },
  { id: '58-60', name: '58-60 (XXXL)' },
];

export const CLOTHING_FEMALE_SIZE = [
  { id: '40', name: '40 (XS)' },
  { id: '42', name: '42 (S)' },
  { id: '44', name: '44 (M)' },
  { id: '46', name: '46 (L)' },
  { id: '48', name: '48 (XL)' },
  { id: '50-52', name: '50-52 (XXL)' },
  { id: '54-58', name: '54-58 (XXXL)' },
];
