import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';

import DatePickerIcon from 'assets/icons/DatePickerIcon';
import { ISO_DATE } from 'utils/helpers/constVariables';
import { URLSearchParamsInit } from 'react-router-dom';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import clsx from 'clsx';
import OutsideClick from 'components/outsideComponent';

interface IRequestCalendarPicker {
  date?: dayjs.Dayjs | null;
  params: URLSearchParams;
  setParams?: (params: URLSearchParamsInit) => void;
}

const RequestCalendarPicker: React.FC<IRequestCalendarPicker> = ({ date, params, setParams }) => {
  const calendarIcon = useRef<HTMLButtonElement>(null);

  const [openCalendar, setOpenCalendar] = useState(false);

  return (
    <div className="flex items-center gap-x-3">
      <button
        onClick={() => {
          if (setParams) {
            params?.set('date', dayjs(date).subtract(1, 'month').format(ISO_DATE));
            setParams(params);
          }
        }}
      >
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 15.5L1.5 8.5L8.5 1.5"
            stroke="#397DFF"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div className="text-smena_text text-center" style={{ width: '140px' }}>
        {dayjs(date).format('MMMM YYYY')}
      </div>
      <div className="flex items-end">
        <button
          onClick={() => {
            setOpenCalendar(openCalendar => !openCalendar);
          }}
          ref={calendarIcon}
        >
          <DatePickerIcon className="text-primary opacity-100" />
        </button>
        <OutsideClick
          setShow={setOpenCalendar}
          show={openCalendar}
          className={clsx('fixed left-1/2 z-30')}
          triggerRef={calendarIcon}
        >
          <DatePickerInput
            // check
            picker="month"
            placeholder="За весь период"
            value={date}
            divClassName={'z-50'}
            pickerClassName={'invisible absolute opacity-0'}
            onChange={newValue => {
              if (setParams) {
                params?.set('date', String(newValue?.startOf('month').format(ISO_DATE)));
                setParams(params);
              }
            }}
            maxDate={dayjs()}
            open={openCalendar}
          />
        </OutsideClick>
      </div>

      <span
        className="cursor-pointer"
        onClick={() => {
          if (setParams) {
            params?.set('date', dayjs(date).add(1, 'month').format(ISO_DATE));
            setParams(params);
          }
        }}
      >
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.5 1.5L8.5 8.5L1.5 15.5"
            stroke="#397DFF"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </div>
  );
};

export default RequestCalendarPicker;
