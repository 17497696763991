import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Plus from 'assets/icons/plus';
import { getActive } from 'utils/helpers/get/getActive';
import Filter from 'components/table/filters/filter';
import { getDayNightRu } from 'utils/helpers/get/getDayNightRu';
import clsx from 'clsx';
import { activeOptions, periodOptions } from 'utils/helpers/constVariables';
import SimpleSelect from 'ui/select';
import MultiSelect from 'ui/multiSelect';
import TableFilter from 'components/table/filters';
import { useCustomSearchParams } from '../../users/lib/useParams';
import Search from 'ui/search';
import { useFilteredFacilityGroups } from '../../publishStory/components/showSettings/hooks/useFilteredFacilityGroups';
import { useFilteredFacilities } from '../../publishStory/components/showSettings/hooks/useFilteredFacilities';
import { useFilteredCities } from '../../publishStory/components/showSettings/hooks/useFilteredCities';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { Table } from '@tanstack/react-table';
import { VacancyFragment } from 'pages/vacancy/api/fragments/generated/Vacancy.fragment';
import ColumnSettings from 'components/table/columnSettings';
import { useFacilityById } from 'utils/api/useFacility';
import { useCityById } from 'utils/api/useCity';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';

interface Props {
  table: Table<VacancyFragment>;
}

const VacancyFilter = ({ table }: Props) => {
  const { params, setParams, useParamsCount, handleParams, resetFilter } = useCustomSearchParams();
  const facilityGroupIdParam = params.get('groupId');
  const facilityParam = params.get('facilityId');
  const cityParam = params.get('cityId');
  const periodParam = params.get('period');
  const scheduleParam = params.get('schedule');
  const activeParam = params.get('isActive');

  const [groupId, setGroupId] = useState(facilityGroupIdParam);
  const [facilityId, setFacilityId] = useState(facilityParam);
  const [cityId, setCityId] = useState(cityParam);
  const [period, setPeriod] = useState(periodParam);
  const [schedule, setSchedule] = useState(scheduleParam ? scheduleParam.split(', ') : []);
  const [isActive, setIsActive] = useState(activeParam);
  const [showFilter, setShowFilter] = useState(false);

  const { facility } = useFacilityById(facilityParam);
  const { scheduleTypes } = useGetScheduleTypes();
  const { city } = useCityById(cityParam);
  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);

  const btnRef = useRef<HTMLButtonElement | null>(null);
  const paramsCount = useParamsCount();

  const filteredFacilityGroups = useFilteredFacilityGroups({ cities: cityId ? [cityId] : [] });
  const filteredFacilities = useFilteredFacilities({
    facilityGroups: groupId ? [groupId] : [],
    cities: cityId ? [cityId] : [],
  });
  const filteredCities = useFilteredCities({
    facilityGroupIds: groupId ? [groupId] : [],
    facilityIds: facilityId ? [facilityId] : [],
  });

  const options = useMemo(() => {
    return scheduleTypes.map(el => ({ label: el.name, value: el.id }));
  }, [scheduleTypes]);

  return (
    <div className="filter-bar justify-between flex xs:flex-row flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <div className={clsx('flex relative gap-x-2')}>
          <Search />
          <button
            type="button"
            ref={btnRef}
            className="btn-stroke flex gap-x-1 items-center"
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <ColumnSettings<VacancyFragment> table={table} />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <div className="flex flex-col gap-y-3">
              <SimpleSelect
                label="Город"
                onChange={value => setCityId(value)}
                value={cityId}
                allOption={'Все города'}
                placeholder={'Не выбрано'}
                options={filteredCities}
              />
              <SimpleSelect
                label="Группа"
                popupClassName={'max-w-[300px]'}
                onChange={value => {
                  setGroupId(value);
                  setFacilityId('');
                }}
                placeholder={'Не выбрано'}
                allOption={'Все группы'}
                value={groupId}
                options={filteredFacilityGroups}
              />

              <SimpleSelect
                label="Объект"
                onChange={value => {
                  setFacilityId(value);
                }}
                value={facilityId}
                placeholder="Не выбрано"
                allOption={'Все'}
                options={filteredFacilities}
              />

              <SimpleSelect
                label="День/Ночь"
                onChange={value => setPeriod(value)}
                value={period}
                placeholder={'Не выбрано'}
                allOption={'Все периоды'}
                options={periodOptions}
              />

              <MultiSelect
                divClassName={'w-full'}
                value={schedule}
                selectClassName={'w-full'}
                placeholder={'Выберите график'}
                label={'График'}
                name={'schedule'}
                options={[{ label: 'Все графики', value: 'all' }, ...options]}
                onChange={value => {
                  if (value[value.length - 1] === 'all') {
                    return setSchedule(schedule.length === scheduleTypes.length ? [] : scheduleTypes.map(el => el.id));
                  }
                  setSchedule(value);
                }}
              />

              <SimpleSelect
                label="Статус активности"
                onChange={value => {
                  setIsActive(value);
                }}
                value={isActive}
                allOption="Все"
                placeholder="Не выбрано"
                options={activeOptions}
              />

              <div className="flex gap-x-5">
                <button
                  className="btn-primary"
                  onClick={() => {
                    handleParams('groupId', groupId);
                    handleParams('facilityId', facilityId);
                    handleParams('cityId', cityId);
                    handleParams('schedule', schedule.join(', '));
                    handleParams('period', period);
                    handleParams('isActive', isActive);
                    setParams(params);
                    setShowFilter(false);
                  }}
                >
                  <span>Применить</span>
                </button>
                <button
                  className="btn-stroke xs:w-auto w-full"
                  onClick={() => {
                    setGroupId(null);
                    setFacilityId(null);
                    setCityId(null);
                    setPeriod(null);
                    setIsActive(null);
                    setSchedule([]);
                    setParams();
                    setShowFilter(false);
                  }}
                >
                  Сбросить
                </button>
              </div>
            </div>
          </TableFilter>
        </div>
        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!facilityGroupIdParam}
              content={'Группа ' + facilityGroupName}
              clickHandler={() => {
                setGroupId(null);
                resetFilter('groupId');
              }}
            />
            <Filter
              show={!!facilityParam}
              content={facility?.name}
              clickHandler={() => {
                setFacilityId(null);
                resetFilter('facilityId');
              }}
            />

            <Filter
              show={!!cityParam}
              content={'Город ' + city?.name}
              clickHandler={() => {
                setCityId(null);
                resetFilter('cityId');
              }}
            />
            <Filter
              show={!!periodParam}
              content={getDayNightRu(periodParam)}
              clickHandler={() => {
                setPeriod(null);
                resetFilter('period');
              }}
            />
            <Filter
              show={!!scheduleParam}
              content={scheduleParam ?? ''}
              clickHandler={() => {
                setSchedule([]);
                resetFilter('schedule');
              }}
            />
            <Filter
              show={!!activeParam}
              content={getActive(activeParam)}
              clickHandler={() => {
                setIsActive('');
                resetFilter('isActive');
              }}
            />
          </div>
        )}
      </div>
      <div className="flex self-start">
        <Link to={`/vacancies/create`}>
          <button type="button" className="btn-primary_big flex items-center">
            <span className="inline-flex mr-2">
              <Plus className="text-smena_white" />
            </span>
            Новая вакансия
          </button>
        </Link>
      </div>
    </div>
  );
};

export default VacancyFilter;
