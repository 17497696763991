import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { getPeriodRu } from 'utils/helpers/get/getPeriodRu';
import { MarkType, PayoutType, UnitType } from 'generated/graphql';
import { durationHoursAndMinutes } from 'utils/helpers/durationHoursAndMinutes';
import { ShiftFactFragment } from 'pages/moderationReport/api/fragments/generated/ShiftFact.fragment';
import { useGetModerationReport } from '../../../utils/api/useGetModerationReport';
import { useFacilityGroupById } from '../../../utils/api/useFacilityGroup';
import { unitSymbolFn } from '../../../utils/helpers/unitSymbol';
import { factValueHelper, markColorHelper } from './helpers';
import { IMarks } from 'interfaces/IEvent';

export const useGroups = () => {
  const { moderationReport } = useGetModerationReport();
  const { facilityGroupName } = useFacilityGroupById(moderationReport?.facility?.facilityGroupId);
  const isItOzonGroup = facilityGroupName === 'OZON';
  const isItPryamikomGroup = facilityGroupName === 'Прямиком';
  const isItGoodGroup = isItOzonGroup || isItPryamikomGroup;

  return { isItOzonGroup, isItPryamikomGroup, isItGoodGroup };
};

export const useModerationTitle = () => {
  const { moderationReport } = useGetModerationReport();
  return useMemo(() => {
    if (!moderationReport) return;
    const facilityName = moderationReport?.facility?.name || 'Объект';
    const dateFrom = dayjs(moderationReport?.dateStart).format('DD.MM.YY');
    const dateTo = dayjs(moderationReport?.dateEnd).format('DD.MM.YY');
    return facilityName + ', ' + dateFrom + '–' + dateTo + ', ' + getPeriodRu(moderationReport?.periodType);
  }, [
    moderationReport?.dateEnd,
    moderationReport?.dateStart,
    moderationReport?.facility?.name,
    moderationReport?.periodType,
  ]);
};

export const useModerationBreadcrumbs = () => {
  const { id } = useGetModerationReport();
  return useMemo(
    () => [
      { path: '/moderation', name: 'Модерация смен' },
      { path: `/moderation/${id}`, name: 'Период' },
    ],
    [id],
  );
};

export const useMarks = ({ marks, type }: { marks?: IMarks[] | null; type: MarkType }) => {
  const marksType = useMemo(() => marks?.filter(mark => mark.type === type).filter(mark => mark.time), [marks, type]);
  const marksColor = markColorHelper(marksType);
  return { marksColor, marksType };
};

export const moderationShiftDuration = (shift: ShiftFactFragment) => {
  const duration = !shift.durationFact ? shift.durationPlan : shift.durationFact;
  return durationHoursAndMinutes(Math.round(duration));
};

export const useProbationShift = (day: Dayjs, probationStart?: string | null, probationEnd?: string | null) => {
  const isShiftStartProbationPeriod = useMemo(
    () => (probationStart ? dayjs(day).startOf('day').isSame(probationStart) : false),
    [probationStart],
  );

  const isShiftEndProbationPeriod = useMemo(
    () => (probationEnd ? dayjs(day).startOf('day').isSame(probationEnd) : false),
    [probationEnd],
  );
  const isShiftInProbationPeriod = useMemo(() => {
    if (probationStart && probationEnd) {
      return dayjs(day).startOf('day').isAfter(probationStart) && dayjs(day).startOf('day').isBefore(probationEnd);
    }
    return false;
  }, [probationStart, probationEnd]);

  const probationBorder = useMemo(() => {
    if (isShiftStartProbationPeriod && isShiftEndProbationPeriod) return 'probationFullPeriod';
    if (isShiftStartProbationPeriod) return 'probationStartPeriod';
    if (isShiftInProbationPeriod) return 1;
    if (isShiftEndProbationPeriod) return 'probationEndPeriod';
    return '';
  }, [isShiftEndProbationPeriod, isShiftInProbationPeriod, isShiftStartProbationPeriod]);
  return { probationBorder };
};

export const useCurrentDayShifts = ({ shifts, day }: { shifts?: ShiftFactFragment[]; day?: Dayjs }) => {
  return useMemo(() => {
    if (!shifts) return [];
    return shifts.filter(shift => {
      return dayjs(shift.dateFrom).isSame(day, 'day');
    });
  }, [day, shifts]);
};

export const useShiftConditions = (shift: ShiftFactFragment) => {
  const { marks, moderation, unit, payout } = shift;
  const isMarks = marks.length > 2;
  const isShiftPayoutHourly = payout === PayoutType.Hourly;
  const isShiftUnitPice = unit === UnitType.Piece;
  const isShiftUnitPoint = unit === UnitType.Point;
  const isShiftUnitHour = unit === UnitType.Hour;
  const shiftResult = moderation || shift;
  const duration = moderationShiftDuration(shift);
  const unitSymbol = unitSymbolFn(unit);
  const factValue = factValueHelper(shift);
  const moneyString = isShiftUnitPice ? '₽/шт.' : isShiftUnitPoint ? '₽/%' : '₽/час';
  return {
    isMarks,
    isShiftPayoutHourly,
    isShiftUnitPice,
    isShiftUnitPoint,
    shiftResult,
    duration,
    unitSymbol,
    factValue,
    isShiftUnitHour,
    moneyString,
  };
};
