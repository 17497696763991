import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetModerationReportStatusMutationVariables = Types.Exact<{
  input: Types.SetStatusModerationInput;
}>;


export type SetModerationReportStatusMutation = { __typename?: 'Mutation', setModerationReportStatus: boolean };


export const SetModerationReportStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetModerationReportStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetStatusModerationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setModerationReportStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type SetModerationReportStatusMutationFn = Apollo.MutationFunction<SetModerationReportStatusMutation, SetModerationReportStatusMutationVariables>;

/**
 * __useSetModerationReportStatusMutation__
 *
 * To run a mutation, you first call `useSetModerationReportStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetModerationReportStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setModerationReportStatusMutation, { data, loading, error }] = useSetModerationReportStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetModerationReportStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetModerationReportStatusMutation, SetModerationReportStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetModerationReportStatusMutation, SetModerationReportStatusMutationVariables>(SetModerationReportStatusDocument, options);
      }
export type SetModerationReportStatusMutationHookResult = ReturnType<typeof useSetModerationReportStatusMutation>;
export type SetModerationReportStatusMutationResult = Apollo.MutationResult<SetModerationReportStatusMutation>;
export type SetModerationReportStatusMutationOptions = Apollo.BaseMutationOptions<SetModerationReportStatusMutation, SetModerationReportStatusMutationVariables>;