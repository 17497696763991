export const gqlQuery = async (
  url: RequestInfo,
  query: any,
  variables: any
) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query, variables }),
  });

  return res.json();
};
