import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityGroupsFragmentDoc } from '../../fragments/generated/FacilityGroups.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilityGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FacilityGroupsQuery = { __typename?: 'Query', facilityGroups: Array<{ __typename?: 'FacilityGroup', id: string, name: string, balance: number, logo?: { __typename?: 'File', id: string, mimetype: string, originalName?: string | null, path: string, size: number } | null }> };


export const FacilityGroupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FacilityGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilityGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityGroups"}}]}}]}},...FacilityGroupsFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useFacilityGroupsQuery__
 *
 * To run a query within a React component, call `useFacilityGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFacilityGroupsQuery(baseOptions?: Apollo.QueryHookOptions<FacilityGroupsQuery, FacilityGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilityGroupsQuery, FacilityGroupsQueryVariables>(FacilityGroupsDocument, options);
      }
export function useFacilityGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilityGroupsQuery, FacilityGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilityGroupsQuery, FacilityGroupsQueryVariables>(FacilityGroupsDocument, options);
        }
export function useFacilityGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FacilityGroupsQuery, FacilityGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FacilityGroupsQuery, FacilityGroupsQueryVariables>(FacilityGroupsDocument, options);
        }
export type FacilityGroupsQueryHookResult = ReturnType<typeof useFacilityGroupsQuery>;
export type FacilityGroupsLazyQueryHookResult = ReturnType<typeof useFacilityGroupsLazyQuery>;
export type FacilityGroupsSuspenseQueryHookResult = ReturnType<typeof useFacilityGroupsSuspenseQuery>;
export type FacilityGroupsQueryResult = Apollo.QueryResult<FacilityGroupsQuery, FacilityGroupsQueryVariables>;