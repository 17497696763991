import React, { SetStateAction, useState } from 'react';
import { flexRender, Header, Table } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { Column } from 'react-table';
import clsx from 'clsx';
import { UsersFragment } from 'pages/users/api/fragments/generated/Users.fragment';
import { reorderColumn } from '../utils/helpers/reorderColumn';
import { useSearchParams } from 'react-router-dom';
import { SortingWay } from 'generated/graphql';
import Up from 'assets/icons/sort/up';
import Down from 'assets/icons/sort/down';

interface Props<T> {
  header: Header<T, unknown>;
  table: Table<T>;
  hoverColumn: string;
  setHoverColumn: React.Dispatch<SetStateAction<string>>;
}

const DraggableColumnHeader = <T,>({ header, table, hoverColumn, setHoverColumn }: Props<T>) => {
  const [clicked, setClicked] = useState('');
  const [params, setParams] = useSearchParams();
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const desc = sortWay === SortingWay.Desc;
  const asc = sortWay === SortingWay.Asc;
  const meta = (table.options.meta as any) || false;
  const defaultSortBy = meta ? meta['defaultSort'] || 'updatedAt' : 'updatedAt';
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const color = (icon: string) => {
    const thisColumn = sortBy === column.id;
    if (!thisColumn) {
      return 'text-smena_text-disabled';
    }
    if (desc) {
      if (icon === 'up') {
        return 'text-smena_text-disabled';
      }
      return 'text-smena_text-helper';
    }
    if (asc) {
      if (icon === 'up') {
        return 'text-smena_text-helper';
      }
      return 'text-smena_text-disabled';
    }
    return 'text-smena_text-disabled';
  };
  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    if (meta[header.id]) {
      setClicked(column.id);
      params.set('sortBy', column.id);
      setParams(params);
      if (clicked !== (e.target as Element).id) {
        params.set('sortWay', 'desc');
        setParams(params);
      } else {
        if (sortWay === null) {
          params.set('sortWay', SortingWay.Desc);
          setParams(params);
        }
        if (desc) {
          params.set('sortWay', SortingWay.Asc);
          setParams(params);
        }
        if (asc) {
          params.set('sortBy', defaultSortBy);
          params.delete('sortWay');
          setParams(params);
        }
      }
    }
  };

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<UsersFragment>) => {
      const newColumnOrder = reorderColumn(draggedColumn.id!, column.id, columnOrder);
      setColumnOrder(newColumnOrder);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });

  return (
    <th
      ref={dropRef}
      className={clsx(
        'border-y first:border-l-0 border-l border-smena_gray-30',
        hoverColumn === column.id ? 'active-column' : 'bg-smena_white',
      )}
      colSpan={header.colSpan}
      style={{
        opacity: isDragging ? 0.5 : 1,
        width: header.getSize(),
      }}
      onMouseEnter={() => {
        setHoverColumn(column.id);
      }}
      onMouseLeave={() => {
        setHoverColumn('');
      }}
    >
      <div ref={previewRef}>
        <div
          ref={dragRef}
          className={clsx('table__header cursor-grab flex items-center gap-x-2')}
          onClick={onClickHandler}
        >
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          {meta[header.id] && (
            <span className="pointer-events-none flex flex-col gap-y-1">
              <Up className={color('up')} />
              <Down className={color('down')} />
            </span>
          )}
        </div>

        <div
          {...{
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            className: `resizer`,
          }}
        />
      </div>
    </th>
  );
};

export default DraggableColumnHeader;
