import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetReferralProgramPaymentDatetimeMutationVariables = Types.Exact<{
  input: Types.SetReferralProgramPaymentDatetimeInput;
}>;


export type SetReferralProgramPaymentDatetimeMutation = { __typename?: 'Mutation', setReferralProgramPaymentDatetime: { __typename?: 'Lead', referrerPaymentDatetime?: any | null } };


export const SetReferralProgramPaymentDatetimeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetReferralProgramPaymentDatetime"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetReferralProgramPaymentDatetimeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setReferralProgramPaymentDatetime"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referrerPaymentDatetime"}}]}}]}}]} as unknown as DocumentNode;
export type SetReferralProgramPaymentDatetimeMutationFn = Apollo.MutationFunction<SetReferralProgramPaymentDatetimeMutation, SetReferralProgramPaymentDatetimeMutationVariables>;

/**
 * __useSetReferralProgramPaymentDatetimeMutation__
 *
 * To run a mutation, you first call `useSetReferralProgramPaymentDatetimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReferralProgramPaymentDatetimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReferralProgramPaymentDatetimeMutation, { data, loading, error }] = useSetReferralProgramPaymentDatetimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReferralProgramPaymentDatetimeMutation(baseOptions?: Apollo.MutationHookOptions<SetReferralProgramPaymentDatetimeMutation, SetReferralProgramPaymentDatetimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReferralProgramPaymentDatetimeMutation, SetReferralProgramPaymentDatetimeMutationVariables>(SetReferralProgramPaymentDatetimeDocument, options);
      }
export type SetReferralProgramPaymentDatetimeMutationHookResult = ReturnType<typeof useSetReferralProgramPaymentDatetimeMutation>;
export type SetReferralProgramPaymentDatetimeMutationResult = Apollo.MutationResult<SetReferralProgramPaymentDatetimeMutation>;
export type SetReferralProgramPaymentDatetimeMutationOptions = Apollo.BaseMutationOptions<SetReferralProgramPaymentDatetimeMutation, SetReferralProgramPaymentDatetimeMutationVariables>;