import TreeSelect from 'antd/es/tree-select';
import { InitialValues } from '../utils/hooks';
import { FormikErrors } from 'formik/dist/types';
import { useGetFacilityGroupsWithFacilitiesQuery } from '../api/generated/GetFacilityGroupsWithFacilities';

interface Props {
  values: InitialValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<InitialValues>>;
}

const PublicationSettings = ({ setFieldValue, values }: Props) => {
  const { data } = useGetFacilityGroupsWithFacilitiesQuery();
  const { SHOW_PARENT } = TreeSelect;

  const tProps = {
    treeData: data?.getFacilityGroupsWithFacilities,
    fieldNames: {
      label: 'name',
      value: 'id',
      children: 'facilities',
    },
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Введите группу или объект',
    style: {
      width: '400px',
    },
  };

  const handleChange = (selectedIds: string[]) => {
    const groupIds: string[] = [];
    const facilityIds: string[] = [];

    selectedIds.forEach(id => {
      const group = data?.getFacilityGroupsWithFacilities.find(group => group.id === id);
      if (group) {
        groupIds.push(id);
      } else {
        facilityIds.push(id);
      }
    });

    setFieldValue('facilityGroupIds', groupIds);
    setFieldValue('facilityIds', facilityIds);
  };

  return (
    <div className="blockTemplate mb-6">
      <div className="blockTitleTemplate">Настройки публикации</div>
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          <span className="Subtitle2">Группа или объект</span>
          <TreeSelect
            {...tProps}
            onChange={handleChange}
            maxTagCount="responsive"
            value={[...values.facilityGroupIds, ...values.facilityIds]}
            filterTreeNode={(val, node) => node.name.includes(val)}
          />
        </div>
        <button className="btn-primary self-end">Опубликовать реферальную программу</button>
      </div>
    </div>
  );
};

export default PublicationSettings;
