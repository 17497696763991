import { useCallback, useState } from 'react';

export function useClientRect(scrollTop?: number) {
  const [rect, setRect] = useState<DOMRect | null>(null);
  const ref = useCallback(
    (node: any) => {
      if (node !== null && scrollTop !== undefined) {
        setRect(node.getBoundingClientRect());
      }
    },
    [scrollTop]
  );
  return { rect, ref };
}
