import { Role, ZayavkaChangesStatus, ZayavkaStage, ZayavkaToCancelStatus } from 'generated/graphql';
import { useCallback, useMemo } from 'react';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useChangesByStatus, useRequestStatus } from './index';
import { useMeRole } from 'store/hooks/useMeRole';
import {
  useAcceptRequestBySupervisorMutation
} from 'pages/facilityRequests/api/mutations/generated/AcceptRequestBySupervisor';
import { useCancelRequestMutation } from 'pages/facilityRequests/api/mutations/generated/CancelRequest';
import {
  useAcceptRequestByDirectorMutation
} from 'pages/facilityRequests/api/mutations/generated/AcceptRequestByDirector';
import { useSendChangesToSupervisorMutation } from 'pages/request/api/mutations/generated/SendChangesToSupervisor';
import { useSendChangesToDirectorMutation } from 'pages/request/api/mutations/generated/SendChangesToDirector';
import {
  useAcceptChangesBySupervisorMutation
} from 'pages/facilityRequests/api/mutations/generated/AcceptChangesBySupervisor';
import { useMe } from 'utils/api/useMe';
import { useMoveRequestToDirectorMutation } from 'pages/facilityRequests/api/mutations/generated/MoveRequestToDirector';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';

export const useAcceptByManager = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Draft] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Draft] });
  return changes && requestStatus;
};

export const useAcceptChangesByManager = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Draft] });
  return changes && requestStatus;
};

export const useAcceptByDirector = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Draft] });
  const changes = useChangesByStatus({
    status: [ZayavkaChangesStatus.Waiting, ZayavkaChangesStatus.Draft, ZayavkaChangesStatus.Accepted],
  });
  return changes && requestStatus;
};

export const useAcceptChangesByDirector = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working, ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Waiting, ZayavkaChangesStatus.Draft] });
  return changes && requestStatus;
};

export const useAcceptBySupervisor = () => {
  const me = useMe();
  const { request } = useCurrentRequest();
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeAdmin = useMeRole([Role.Admin]);
  const isSupervisorAuthorRequest = isMeSupervisor && me?.id === request?.authorId;
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Accepted] });
  const sendRequest = useAcceptByDirector();
  const acceptRequest = useAcceptChangesByDirector();
  return isSupervisorAuthorRequest || isMeAdmin
    ? sendRequest || acceptRequest || (changes && requestStatus)
    : changes && requestStatus;
};

export const useAcceptChangesBySupervisor = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working, ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.New] });
  return changes && requestStatus;
};

export const useSendButton = () => {
  const { id, request } = useCurrentRequest();
  const me = useMe();

  const [moveRequestToDirector, { loading: loadingMoveRequestToDirector }] = useMoveRequestToDirectorMutation();
  const [acceptRequestByDirector, { loading: loadingAcceptRequestByDirector }] = useAcceptRequestByDirectorMutation();
  const [acceptRequestBySupervisor, { loading: loadingAcceptRequestBySupervisor }] =
    useAcceptRequestBySupervisorMutation();
  const [sendChangesToDirector, { loading: loadingSendChangesToDirector }] = useSendChangesToDirectorMutation();
  const [cancelRequest, { loading: loadingCancelRequest }] = useCancelRequestMutation();
  const [sendChangesToSupervisor, { loading: loadingSendChangesToSupervisor }] = useSendChangesToSupervisorMutation();
  const [acceptChangesBySupervisorMutation, { loading: loadingAcceptChangesBySupervisor }] =
    useAcceptChangesBySupervisorMutation();

  const loading =
    loadingMoveRequestToDirector ||
    loadingAcceptRequestByDirector ||
    loadingAcceptRequestBySupervisor ||
    loadingSendChangesToDirector ||
    loadingCancelRequest ||
    loadingSendChangesToSupervisor ||
    loadingAcceptChangesBySupervisor;

  const isMeManager = useMeRole([Role.ClientManager]);
  const isMeDirector = useMeRole([Role.ClientDirector]);
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeAdmin = useMeRole([Role.Admin]);

  const isSupervisorAuthorRequest = isMeSupervisor && me?.id === request?.authorId;

  const acceptByManager = useAcceptByManager();
  const acceptChangesByManager = useAcceptChangesByManager();
  const acceptByDirector = useAcceptByDirector();
  const acceptChangesByDirector = useAcceptChangesByDirector();
  const acceptCancelByDirector = request?.toCancel === ZayavkaToCancelStatus.Waiting;
  const acceptBySupervisor = useAcceptBySupervisor();
  const acceptChangesBySupervisor = useAcceptChangesBySupervisor();

  const managerCondition = !acceptCancelByDirector && (acceptByManager || acceptChangesByManager);
  const directorCondition = acceptByDirector || acceptChangesByDirector || acceptCancelByDirector;
  const supervisorCondition = acceptBySupervisor || acceptChangesBySupervisor;

  const showSendButton = useMemo(() => {
    return (
      (isMeManager && managerCondition) ||
      (isMeDirector && directorCondition) ||
      ((isMeSupervisor || isMeAdmin) && supervisorCondition)
    );
  }, [directorCondition, isMeAdmin, isMeDirector, isMeManager, isMeSupervisor, managerCondition, supervisorCondition]);

  const sendButtonText = useMemo(() => {
    if (isMeManager || isMeDirector || isMeAdmin) {
      if (acceptCancelByDirector) {
        return 'Отменить';
      }
      if (acceptChangesByManager || acceptChangesByDirector) {
        return 'Отправить изменения';
      }
      return 'Отправить заявку';
    }
    if (isMeSupervisor || isMeAdmin) {
      if (isSupervisorAuthorRequest && acceptChangesByDirector) {
        return 'Отправить изменения';
      }
      if (request?.status === ZayavkaStage.New) {
        return 'Взять в работу';
      }
      if (acceptChangesBySupervisor) {
        return 'Принять изменения';
      }
      return 'Отправить заявку';
    }
  }, [
    acceptCancelByDirector,
    acceptChangesByDirector,
    acceptChangesByManager,
    acceptChangesBySupervisor,
    isMeAdmin,
    isMeDirector,
    isMeManager,
    isMeSupervisor,
    isSupervisorAuthorRequest,
    request?.status,
  ]);

  const sendRequest = useCallback(() => {
    if (!id) return;
    const variables = {
      variables: {
        id,
      },
    };
    if (isMeManager) {
      if (acceptChangesByManager) {
        return sendChangesToDirector(variables)
          .then(() => {
            successToast('Изменения отправлены на проверку директору');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      return moveRequestToDirector(variables)
        .then(() => {
          successToast('Заявка отправлена на проверку директору');
        })
        .catch(error => errorToast(errorHandler(error)));
    }
    if (isMeDirector) {
      if (acceptCancelByDirector) {
        return cancelRequest(variables)
          .then(() => {
            successToast('Заявка отклонена');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      if (acceptChangesByDirector) {
        return sendChangesToSupervisor(variables)
          .then(() => {
            successToast('Изменения отправлены супервайзеру');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      return acceptRequestByDirector(variables)
        .then(() => {
          successToast('Заявка отправлена');
        })
        .catch(error => errorToast(errorHandler(error)));
    }
    if (isMeSupervisor || isMeAdmin) {
      if (isSupervisorAuthorRequest || isMeAdmin) {
        if (acceptChangesByDirector) {
          return sendChangesToSupervisor(variables)
            .then(() => {
              successToast('Изменения отправлены');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        if (request?.status === ZayavkaStage.Draft) {
          return acceptRequestByDirector(variables)
            .then(() => {
              successToast('Заявка отправлена');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        if (acceptChangesByDirector) {
          return sendChangesToSupervisor(variables)
            .then(() => {
              successToast('Изменения отправлены');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
      }
      if (request?.status === ZayavkaStage.New) {
        return acceptRequestBySupervisor(variables)
          .then(() => {
            successToast('Заявка принята в работу');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      if (acceptChangesBySupervisor) {
        return acceptChangesBySupervisorMutation(variables)
          .then(() => {
            successToast('Изменения приняты');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      return acceptRequestBySupervisor(variables)
        .then(() => {
          successToast('Заявка принята в работу');
        })
        .catch(error => errorToast(errorHandler(error)));
    }
  }, [
    id,
    isMeManager,
    isMeDirector,
    isMeAdmin,
    isMeSupervisor,
    acceptChangesByManager,
    moveRequestToDirector,
    sendChangesToDirector,
    acceptCancelByDirector,
    acceptChangesByDirector,
    acceptRequestByDirector,
    cancelRequest,
    sendChangesToSupervisor,
    isSupervisorAuthorRequest,
    request?.status,
    acceptChangesBySupervisor,
    acceptRequestBySupervisor,
    acceptChangesBySupervisorMutation,
  ]);

  return { showSendButton, sendButtonText, sendRequest, loading };
};
