import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCasingMutationVariables = Types.Exact<{
  input: Types.CasingCardInput;
}>;


export type UpdateCasingMutation = { __typename?: 'Mutation', updateCasing: { __typename?: 'Casing', id: string, createdAt: any, facilityId: string, payout: Types.PayoutType, unit: Types.UnitType, hourRate: number, positionId: string, isSelfEmployed?: boolean | null, selfEmployedRate?: number | null, hasProbation: boolean, probationPeriod?: number | null, probationRate?: number | null, isMedBookRequired: boolean } };


export const UpdateCasingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCasing"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CasingCardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCasing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"facilityId"}},{"kind":"Field","name":{"kind":"Name","value":"payout"}},{"kind":"Field","name":{"kind":"Name","value":"unit"}},{"kind":"Field","name":{"kind":"Name","value":"hourRate"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}},{"kind":"Field","name":{"kind":"Name","value":"isSelfEmployed"}},{"kind":"Field","name":{"kind":"Name","value":"selfEmployedRate"}},{"kind":"Field","name":{"kind":"Name","value":"hasProbation"}},{"kind":"Field","name":{"kind":"Name","value":"probationPeriod"}},{"kind":"Field","name":{"kind":"Name","value":"probationRate"}},{"kind":"Field","name":{"kind":"Name","value":"isMedBookRequired"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateCasingMutationFn = Apollo.MutationFunction<UpdateCasingMutation, UpdateCasingMutationVariables>;

/**
 * __useUpdateCasingMutation__
 *
 * To run a mutation, you first call `useUpdateCasingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCasingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCasingMutation, { data, loading, error }] = useUpdateCasingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCasingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCasingMutation, UpdateCasingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCasingMutation, UpdateCasingMutationVariables>(UpdateCasingDocument, options);
      }
export type UpdateCasingMutationHookResult = ReturnType<typeof useUpdateCasingMutation>;
export type UpdateCasingMutationResult = Apollo.MutationResult<UpdateCasingMutation>;
export type UpdateCasingMutationOptions = Apollo.BaseMutationOptions<UpdateCasingMutation, UpdateCasingMutationVariables>;