import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { createPositionCache } from './api/updateCache';
import { useUpdatePositionMutation } from 'pages/position/api/mutations/generated/UpdatePosition';
import { useCreatePositionMutation } from 'pages/position/api/mutations/generated/CreatePosition';
import { usePosition } from 'utils/api/usePosition';

export const usePositionPage = () => {
  const navigate = useNavigate();
  const { id, position, loading: loadingPosition, error } = usePosition();

  const title = useMemo(() => {
    if (loadingPosition) {
      return '';
    }
    if (position?.name) {
      return `Профессия ${position.name}`;
    }
    return 'Создать профессию';
  }, [loadingPosition, position?.name]);

  const [createPosition, { loading: createPositionLoading }] = useCreatePositionMutation({
    update: (cache, { data }) => {
      createPositionCache({ cache, data: data?.createPosition });
    },
  });
  const [updatePosition, { loading: updatePositionLoading }] = useUpdatePositionMutation();

  const loading = useMemo(
    () => createPositionLoading || updatePositionLoading,
    [createPositionLoading, updatePositionLoading],
  );

  const breadcrumbs = useMemo(
    () => [
      { path: '/positions', name: 'Профессии' },
      id
        ? { path: '/positions' + id, name: `Профессия ${position?.name}` }
        : { path: '/positions/create', name: 'Новая профессия' },
    ],
    [position?.name, id],
  );

  const initialValues = useMemo(
    () => ({
      name: position?.name || '',
      isSelfEmployed: position?.isSelfEmployed || false,
      externalId: position?.externalId || '',
    }),
    [position?.externalId, position?.isSelfEmployed, position?.name],
  );

  const validate = useCallback((values: typeof initialValues) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Обязательное поле';
    }
    return errors;
  }, []);

  const onSubmit = useCallback(
    (values: typeof initialValues) => {
      const variablesPosition = {
        name: values.name.trim(),
        isSelfEmployed: values.isSelfEmployed,
        externalId: values.externalId.trim(),
      };
      if (id) {
        updatePosition({
          variables: {
            input: {
              id,
              ...variablesPosition,
            },
          },
        })
          .then(response => {
            if (response.data?.updatePosition) {
              successToast('Профессия обновлена');
            }
          })
          .catch(e => errorToast(errorHandler(e)));
      } else {
        createPosition({
          variables: {
            input: variablesPosition,
          },
        })
          .then(response => {
            const createPosition = response.data?.createPosition;
            if (createPosition) {
              successToast('Профессия создана');
              navigate(`/positions/${createPosition.id}`);
            }
          })
          .catch(e => {
            errorToast(errorHandler(e));
          });
      }
    },
    [createPosition, navigate, id, updatePosition],
  );
  return { title, initialValues, validate, onSubmit, loading, breadcrumbs, id, error, loadingPosition };
};
