import { CustomTagProps } from 'rc-select/lib/BaseSelect';

const TagRender = (props: CustomTagProps) => {
  const { label } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div
      onMouseDown={onPreventMouseDown}
      className={'py-0.5 px-[6px] bg-smena_bb-background Caption text-primary-dark rounded-2xl mr-1'}
    >
      {label}
    </div>
  );
};
export default TagRender;
