import React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { mapColumns } from 'utils/helpers/mapColumns';
import { IColumns } from 'interfaces/IColumns';
import Column from './column';

interface ITableHeader {
  columns: IColumns[];
  defaultSortBy?: string;
}

const TableHeader: React.FC<ITableHeader> = ({ columns, defaultSortBy = 'updatedAt' }) => {
  return (
    <ScrollSyncPane>
      <div
        className="grid rounded-t-lg bg-smena_white border-b border-smena_gray-30 z-20 sticky top-0"
        style={{
          gridTemplateColumns: mapColumns(columns),
        }}
      >
        {columns.map(column => (
          <Column key={column.name} defaultSortBy={defaultSortBy} column={column} />
        ))}
      </div>
    </ScrollSyncPane>
  );
};

export default TableHeader;
