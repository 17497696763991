import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useStore } from 'store';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { dateEndFn } from 'utils/helpers/dateEndFn';
import dateHour from '../../../../../userSchedule/components/sidebar/newSchedule/helpers/dateHour';
import { useUpdateRequestShiftByClientMutation } from '../../../../../facilityRequests/api/mutations/generated/UpdateRequestShiftByClient';

export const useEditShiftForm = () => {
  const [updateRequestShiftByClient] = useUpdateRequestShiftByClientMutation();
  const { requestShift, setRequestShift } = useStore();
  const initialValues = {
    editShiftStartTime: requestShift?.newDateFrom ? dayjs(requestShift?.newDateFrom) : dayjs(requestShift?.dateFrom),
    editShiftEndTime: requestShift?.newDateEnd ? dayjs(requestShift?.newDateEnd) : dayjs(requestShift?.dateEnd),
    lunchDuration: requestShift?.newLunchDuration ? requestShift?.newLunchDuration : requestShift?.lunchDuration,
  };
  const validate = (values: typeof initialValues) => {
    const errors: any = {};
    if (!values.editShiftStartTime) {
      errors.editShiftStartTime = 'Обязательное поле';
    }
    if (!values.editShiftEndTime) {
      errors.editShiftEndTime = 'Обязательное поле';
    }
    return errors;
  };
  const onSubmit = useCallback(
    (values: typeof initialValues) => {
      const day = dayjs(requestShift?.dateFrom);
      const dateEnd = dateEndFn({
        date: day,
        endTime: values.editShiftEndTime,
        startTime: values.editShiftStartTime,
      });
      const dateFrom = dateHour(day, values.editShiftStartTime);
      if (!requestShift?.id) return;
      updateRequestShiftByClient({
        variables: {
          id: requestShift.id,
          input: {
            lunchDuration: values.lunchDuration,
            dateFrom,
            dateEnd,
          },
        },
      })
        .then(res => {
          if (res.data?.updateZayavkaShiftByClient) {
            setRequestShift(res.data.updateZayavkaShiftByClient);
          }
          successToast('Смена обновлена');
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [requestShift?.dateFrom, requestShift?.id, setRequestShift, updateRequestShiftByClient],
  );
  return { initialValues, validate, onSubmit };
};
