import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useStore } from 'store';

import HideArrowMenu from 'assets/icons/menu/hideArrowMenu';
import Logo from 'ui/logo';
import MenuElements from './elements';
import { useState } from 'react';

const Menu = () => {
  const { showSidebarMenu, setShowSidebarMenu, setTooltip } = useStore();
  const [scrollTop, setScrollTop] = useState(0);

  const menuHandler = () => {
    setShowSidebarMenu(!showSidebarMenu);
    if (showSidebarMenu) {
      setTooltip(false);
    }
  };

  return (
    <div className={clsx('side-menu md:flex hidden', showSidebarMenu ? 'px-6 w-[260px]' : 'md:w-[56px]')}>
      <div
        className={clsx(
          'flex flex-col gap-y-12 h-full overflow-auto scrollbar-hide w-full',
          !showSidebarMenu ? 'items-center' : '',
        )}
        onScroll={(e: any) => {
          const {
            target: { scrollTop },
          } = e;
          setScrollTop(scrollTop);
        }}
      >
        <div
          className={clsx(
            'flex items-center justify-center bg-smena_white mt-6',
            showSidebarMenu ? 'justify-between' : 'justify-center',
          )}
        >
          {showSidebarMenu && (
            <Link to="/">
              <Logo />
            </Link>
          )}
          <span
            className={clsx(
              'cursor-pointer transition-transform h-[40px] w-[40px] flex items-center justify-center hover:bg-smena_bb-background rounded-full',
              showSidebarMenu ? 'rotate-180' : '',
            )}
            onClick={menuHandler}
          >
            <HideArrowMenu />
          </span>
        </div>

        <MenuElements scrollTop={scrollTop} />
      </div>
    </div>
  );
};

export default Menu;
