import * as Types from '../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { NotificationFragmentDoc } from '../../fragments/generated/Notification.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewNotificationSubSubscriptionVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type NewNotificationSubSubscription = { __typename?: 'Subscription', newNotificationSub: { __typename?: 'Notification', createdAt: any, appType: Types.AppType, body: string, id: string, isRead: boolean, route: string, routeId: string, success: number, title: string, userId: string } };


export const NewNotificationSubDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"NewNotificationSub"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newNotificationSub"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Notification"}}]}}]}},...NotificationFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useNewNotificationSubSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNewNotificationSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<NewNotificationSubSubscription, NewNotificationSubSubscriptionVariables> & ({ variables: NewNotificationSubSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewNotificationSubSubscription, NewNotificationSubSubscriptionVariables>(NewNotificationSubDocument, options);
      }
export type NewNotificationSubSubscriptionHookResult = ReturnType<typeof useNewNotificationSubSubscription>;
export type NewNotificationSubSubscriptionResult = Apollo.SubscriptionResult<NewNotificationSubSubscription>;