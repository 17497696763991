import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUserBrowserTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type SetUserBrowserTokenMutation = { __typename?: 'Mutation', setUserBrowserToken: { __typename?: 'User', id: string } };


export const SetUserBrowserTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetUserBrowserToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setUserBrowserToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type SetUserBrowserTokenMutationFn = Apollo.MutationFunction<SetUserBrowserTokenMutation, SetUserBrowserTokenMutationVariables>;

/**
 * __useSetUserBrowserTokenMutation__
 *
 * To run a mutation, you first call `useSetUserBrowserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserBrowserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserBrowserTokenMutation, { data, loading, error }] = useSetUserBrowserTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetUserBrowserTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetUserBrowserTokenMutation, SetUserBrowserTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserBrowserTokenMutation, SetUserBrowserTokenMutationVariables>(SetUserBrowserTokenDocument, options);
      }
export type SetUserBrowserTokenMutationHookResult = ReturnType<typeof useSetUserBrowserTokenMutation>;
export type SetUserBrowserTokenMutationResult = Apollo.MutationResult<SetUserBrowserTokenMutation>;
export type SetUserBrowserTokenMutationOptions = Apollo.BaseMutationOptions<SetUserBrowserTokenMutation, SetUserBrowserTokenMutationVariables>;