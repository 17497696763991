import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PromotionEntityFragmentDoc } from '../../fragments/generated/PromotionEntity.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPromotionsForFacilityGroupQueryVariables = Types.Exact<{
  input: Types.GetListPromotionsForFacilityGroupInput;
}>;


export type GetListPromotionsForFacilityGroupQuery = { __typename?: 'Query', getListPromotionsForFacilityGroup: Array<{ __typename?: 'PromotionEntity', id: string, badShiftCount: number, dateTimeEnd?: any | null, dateTimeStart: any, name: string, repeatCondition: Types.PromotionRepeatConditionEnum, requirementBonus: Types.PromotionRequirementBonusEnum, requirementShiftCount: number, rewardSum: number, rewardType: Types.PromotionRewardTypeEnum, status: Types.PromotionStatusEnum, childPromotions?: Array<{ __typename?: 'PromotionEntity', facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null }> };


export const GetListPromotionsForFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetListPromotionsForFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetListPromotionsForFacilityGroupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getListPromotionsForFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PromotionEntity"}}]}}]}},...PromotionEntityFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetListPromotionsForFacilityGroupQuery__
 *
 * To run a query within a React component, call `useGetListPromotionsForFacilityGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPromotionsForFacilityGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPromotionsForFacilityGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPromotionsForFacilityGroupQuery(baseOptions: Apollo.QueryHookOptions<GetListPromotionsForFacilityGroupQuery, GetListPromotionsForFacilityGroupQueryVariables> & ({ variables: GetListPromotionsForFacilityGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPromotionsForFacilityGroupQuery, GetListPromotionsForFacilityGroupQueryVariables>(GetListPromotionsForFacilityGroupDocument, options);
      }
export function useGetListPromotionsForFacilityGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPromotionsForFacilityGroupQuery, GetListPromotionsForFacilityGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPromotionsForFacilityGroupQuery, GetListPromotionsForFacilityGroupQueryVariables>(GetListPromotionsForFacilityGroupDocument, options);
        }
export function useGetListPromotionsForFacilityGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListPromotionsForFacilityGroupQuery, GetListPromotionsForFacilityGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListPromotionsForFacilityGroupQuery, GetListPromotionsForFacilityGroupQueryVariables>(GetListPromotionsForFacilityGroupDocument, options);
        }
export type GetListPromotionsForFacilityGroupQueryHookResult = ReturnType<typeof useGetListPromotionsForFacilityGroupQuery>;
export type GetListPromotionsForFacilityGroupLazyQueryHookResult = ReturnType<typeof useGetListPromotionsForFacilityGroupLazyQuery>;
export type GetListPromotionsForFacilityGroupSuspenseQueryHookResult = ReturnType<typeof useGetListPromotionsForFacilityGroupSuspenseQuery>;
export type GetListPromotionsForFacilityGroupQueryResult = Apollo.QueryResult<GetListPromotionsForFacilityGroupQuery, GetListPromotionsForFacilityGroupQueryVariables>;