import dayjs from 'dayjs';
import { PromotionRewardTypeEnum } from 'generated/graphql';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { usePromotionStore } from '../../../facilityGroup/useBonusProgramStore';
import { useEditPromotionInFacilityMutation } from 'pages/facility/api/mutations/generated/EditPromotionInFacility';
import { useFacility } from '../../../../utils/api/useFacility';

export type FacilityBonus = {
  id: string;
  name: string;
  status?: string;
  requirementShiftCount?: number;
  rewardType?: string;
  rewardSum?: number;
  badShiftCount?: number;
  dateFrom: dayjs.Dayjs | null;
  timeFrom: dayjs.Dayjs | null;
  dateTo: dayjs.Dayjs | null;
  timeTo: dayjs.Dayjs | null;
  endless: boolean;
  repeatCondition?: string;
  __typename?: string;
};

type Props = {
  el: FacilityBonus;
  setShowDefault: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const usePromotionForm = ({ el, setShowDefault, setShowEdit }: Props) => {
  const { id } = useFacility();
  const [editPromotionInFacility] = useEditPromotionInFacilityMutation();
  const changeBonuses = usePromotionStore(state => state.changePromotions);
  const initialValues: FacilityBonus = { ...el };

  const validate = (values: FacilityBonus) => {
    const errors: { [key: string]: string } = {};
    if (!values.name) {
      errors.name = 'Обязательное поле';
    }
    if (!values.requirementShiftCount) {
      errors.requirementShiftCount = 'Обязательное поле';
    }
    if (!values.rewardType) {
      errors.rewardType = 'Обязательное поле';
    }
    if (!values.rewardSum) {
      errors.rewardSum = 'Обязательное поле';
    }
    if (!values.badShiftCount) {
      errors.badShiftCount = 'Обязательное поле';
    }
    if (!values.repeatCondition) {
      errors.repeatCondition = 'Обязательное поле';
    }
    return errors;
  };

  const onSubmit = (values: FacilityBonus) => {
    if (!id) return;
    const variables = {
      name: values.name,
      requirementShiftCount: values.requirementShiftCount!,
      rewardType: values.rewardType as PromotionRewardTypeEnum,
      rewardSum: values.rewardSum!,
      badShiftCount: values.badShiftCount!,
    };
    editPromotionInFacility({
      variables: {
        input: { ...variables, facilityId: id, promotionId: el.id },
      },
    })
      .then(response => {
        if (!response.data) return;
        const res = response.data.editPromotionInFacility;
        successToast('Акция изменена');
        changeBonuses(el.id, {
          ...res,
          dateTo: dayjs(res.dateTimeStart),
          timeTo: dayjs(res.dateTimeStart),
          dateFrom: dayjs(res.dateTimeEnd),
          timeFrom: dayjs(res.dateTimeEnd),
          endless: false,
        });
      })
      .catch(err => {
        errorToast(errorHandler(err));
      })
      .finally(() => {
        setShowDefault(false);
        setShowEdit(false);
      });
  };
  return { initialValues, validate, onSubmit };
};

export default usePromotionForm;
