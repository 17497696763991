import { useSendButton } from './hooks/sendHooks';
import { useCurrentRequest } from '../../../../utils/api/useCurrentRequest';

const SendButton: React.FC = () => {
  const { request } = useCurrentRequest();
  const { sendButtonText, showSendButton, sendRequest, loading } = useSendButton();
  return (
    <>
      {showSendButton ? (
        <button
          className="btn-primary"
          type="button"
          onClick={sendRequest}
          disabled={request?.zayavkaSchedule?.length === 0 || loading}
        >
          {sendButtonText}
        </button>
      ) : null}
    </>
  );
};

export default SendButton;
