import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PaginatedRequestFragmentDoc } from '../../fragments/generated/PaginatedRequest.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPaginatedQueryVariables = Types.Exact<{
  input: Types.ZayavkaListInput;
}>;


export type RequestPaginatedQuery = { __typename?: 'Query', zayavkiPaginated: { __typename?: 'PaginatedZayavky', count: number, pages: number, zayavky: Array<{ __typename?: 'ZayavkaProgress', id: string, authorId: string, dateFrom: any, dateTo: string, hoursPlanCount: number, hoursScheduleCount: number, shiftsPlanCount: number, shiftsScheduleCount: number, number: number, toCancel: Types.ZayavkaToCancelStatus, status: Types.ZayavkaStage, facility?: { __typename?: 'Facility', id: string, name: string, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string } | null } | null, plans: Array<{ __typename?: 'ZayavkaPlan', id: string, positionId: string, hours: number, shiftCount: number, shiftDuration: number }>, zayavkaChanges?: Array<{ __typename?: 'ZayavkaChanges', authorId: string, id: string, stage: Types.ZayavkaChangesStatus, zayavkaId: string }> | null }> } };


export const RequestPaginatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RequestPaginated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ZayavkaListInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"zayavkiPaginated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"zayavky"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PaginatedRequest"}}]}}]}}]}},...PaginatedRequestFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useRequestPaginatedQuery__
 *
 * To run a query within a React component, call `useRequestPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPaginatedQuery(baseOptions: Apollo.QueryHookOptions<RequestPaginatedQuery, RequestPaginatedQueryVariables> & ({ variables: RequestPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestPaginatedQuery, RequestPaginatedQueryVariables>(RequestPaginatedDocument, options);
      }
export function useRequestPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestPaginatedQuery, RequestPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestPaginatedQuery, RequestPaginatedQueryVariables>(RequestPaginatedDocument, options);
        }
export function useRequestPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RequestPaginatedQuery, RequestPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequestPaginatedQuery, RequestPaginatedQueryVariables>(RequestPaginatedDocument, options);
        }
export type RequestPaginatedQueryHookResult = ReturnType<typeof useRequestPaginatedQuery>;
export type RequestPaginatedLazyQueryHookResult = ReturnType<typeof useRequestPaginatedLazyQuery>;
export type RequestPaginatedSuspenseQueryHookResult = ReturnType<typeof useRequestPaginatedSuspenseQuery>;
export type RequestPaginatedQueryResult = Apollo.QueryResult<RequestPaginatedQuery, RequestPaginatedQueryVariables>;