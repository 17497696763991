import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetEverythingIsPossibleBonusMutationVariables = Types.Exact<{
  input: Types.EverythingIsPossibleBonusInput;
}>;


export type SetEverythingIsPossibleBonusMutation = { __typename?: 'Mutation', setEverythingIsPossibleBonus: { __typename?: 'Facility', id: string, everythingIsPossibleBonus: boolean, possibleBonusPaymentSum: number, possibleBonusShiftCount: number, possibleBonusViewSum: number } };


export const SetEverythingIsPossibleBonusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetEverythingIsPossibleBonus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EverythingIsPossibleBonusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setEverythingIsPossibleBonus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"everythingIsPossibleBonus"}},{"kind":"Field","name":{"kind":"Name","value":"possibleBonusPaymentSum"}},{"kind":"Field","name":{"kind":"Name","value":"possibleBonusShiftCount"}},{"kind":"Field","name":{"kind":"Name","value":"possibleBonusViewSum"}}]}}]}}]} as unknown as DocumentNode;
export type SetEverythingIsPossibleBonusMutationFn = Apollo.MutationFunction<SetEverythingIsPossibleBonusMutation, SetEverythingIsPossibleBonusMutationVariables>;

/**
 * __useSetEverythingIsPossibleBonusMutation__
 *
 * To run a mutation, you first call `useSetEverythingIsPossibleBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEverythingIsPossibleBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEverythingIsPossibleBonusMutation, { data, loading, error }] = useSetEverythingIsPossibleBonusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEverythingIsPossibleBonusMutation(baseOptions?: Apollo.MutationHookOptions<SetEverythingIsPossibleBonusMutation, SetEverythingIsPossibleBonusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEverythingIsPossibleBonusMutation, SetEverythingIsPossibleBonusMutationVariables>(SetEverythingIsPossibleBonusDocument, options);
      }
export type SetEverythingIsPossibleBonusMutationHookResult = ReturnType<typeof useSetEverythingIsPossibleBonusMutation>;
export type SetEverythingIsPossibleBonusMutationResult = Apollo.MutationResult<SetEverythingIsPossibleBonusMutation>;
export type SetEverythingIsPossibleBonusMutationOptions = Apollo.BaseMutationOptions<SetEverythingIsPossibleBonusMutation, SetEverythingIsPossibleBonusMutationVariables>;