import clsx from 'clsx';
import React from 'react';

interface IViewScheduleFormElement {
  data?: any;
  newData?: any;
  isDeleted?: boolean;
  isNew?: boolean;
}

const ViewScheduleFormElement: React.FC<IViewScheduleFormElement> = ({ data, newData, isNew, isDeleted }) => {
  return (
    <>
      {newData ? (
        <div className={'flex flex-col'}>
          <span className={'text-primary Body2'}>{newData}</span>
          <span className={'line-through text-smena_text-secondary Body2'}>{data}</span>
        </div>
      ) : data ? (
        <span
          className={clsx(
            isDeleted ? 'line-through' : '',
            isNew ? 'text-primary' : 'text-smena_text-secondary',
            'Body2',
          )}
        >
          {data}
        </span>
      ) : null}
    </>
  );
};

export default ViewScheduleFormElement;
