export const SHORT_TYPES = new Set([
  'аобл',
  'респ',
  'вл',
  'г',
  'д',
  'двлд',
  'днп',
  'дор',
  'дп',
  'жт',
  'им',
  'к',
  'кв',
  'км',
  'комн',
  'кп',
  'лпх',
  'м',
  'мкр',
  'наб',
  'нп',
  'обл',
  'оф',
  'п',
  'пгт',
  'пер',
  'пл',
  'платф',
  'рзд',
  'рп',
  'с',
  'сл',
  'снт',
  'ст',
  'стр',
  'тер',
  'туп',
  'ул',
  'х',
  'ш',
]);

export function addDot(word: string) {
  if (SHORT_TYPES.has(word)) {
    return word + '.';
  }
  return word;
}

export function dotify(address: string) {
  const parts = address.split(',').map(function (part) {
    const words = part.split(' ').map(addDot);
    return words.join(' ');
  });
  return parts.join(',');
}
