import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { RichUserFragmentDoc } from '../../fragments/generated/RichUser.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeShortlyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeShortlyQuery = { __typename?: 'Query', meShortly: { __typename?: 'RichUser', id: string, role: Types.Role, phone: string, firstname: string, lastname: string, maxVersion?: string | null, Facility_SUPERVISOR?: Array<{ __typename?: 'Facility', name: string, id: string }> | null } };


export const MeShortlyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MeShortly"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meShortly"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RichUser"}}]}}]}},...RichUserFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useMeShortlyQuery__
 *
 * To run a query within a React component, call `useMeShortlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeShortlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeShortlyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeShortlyQuery(baseOptions?: Apollo.QueryHookOptions<MeShortlyQuery, MeShortlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeShortlyQuery, MeShortlyQueryVariables>(MeShortlyDocument, options);
      }
export function useMeShortlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeShortlyQuery, MeShortlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeShortlyQuery, MeShortlyQueryVariables>(MeShortlyDocument, options);
        }
export function useMeShortlySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeShortlyQuery, MeShortlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeShortlyQuery, MeShortlyQueryVariables>(MeShortlyDocument, options);
        }
export type MeShortlyQueryHookResult = ReturnType<typeof useMeShortlyQuery>;
export type MeShortlyLazyQueryHookResult = ReturnType<typeof useMeShortlyLazyQuery>;
export type MeShortlySuspenseQueryHookResult = ReturnType<typeof useMeShortlySuspenseQuery>;
export type MeShortlyQueryResult = Apollo.QueryResult<MeShortlyQuery, MeShortlyQueryVariables>;