import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { ScrollSyncPane } from 'react-scroll-sync';

import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';
import { useMeRole } from 'store/hooks/useMeRole';
import MoreDropdown from 'ui/moreDropdown';
import { RequestScheduleFragment } from 'pages/facilityRequests/api/fragments/generated/RequestSchedule.fragment';
import { useDeleteRequestScheduleMutation } from 'pages/facilityRequests/api/mutations/generated/DeleteRequestSchedule';
import { useMe } from 'utils/api/useMe';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { zayavkaConfigFn } from 'utils/graphqlConfigs/zayavkaConfig';
import { Role, ShiftTime } from 'generated/graphql';
import SunIcon from 'assets/icons/SunIcon';
import MoonIcon from 'assets/icons/MoonIcon';

interface IRequestProfession {
  requestSchedule: RequestScheduleFragment;
  setShowEditGraphicSidebar: (value: boolean) => void;
}

const RequestProfession: React.FC<IRequestProfession> = ({ requestSchedule, setShowEditGraphicSidebar }) => {
  const me = useMe();
  const setRequestSchedule = useStore(state => state.setRequestSchedule);
  const { request, id, isFinishedRequest } = useCurrentRequest();
  const requestsConfig = {
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfigFn(id || '')],
  };
  const [editGraphic, setEditGraphic] = useState(false);

  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeAuthor = request?.authorId === me?.id;

  const [deleteRequestSchedule, { loading }] = useDeleteRequestScheduleMutation(requestsConfig);

  const deleteRequestScheduleHandler = useCallback(() => {
    if (requestSchedule?.id) {
      deleteRequestSchedule({
        variables: {
          id: requestSchedule.id,
        },
      })
        .then(() => successToast('График удален'))
        .catch(e => errorToast(errorHandler(e)))
        .finally(() => setEditGraphic(false));
    }
  }, [deleteRequestSchedule, requestSchedule?.id]);

  const schedule = `${dayjs(requestSchedule.dateFrom).format('DD.MM')} – 
   ${dayjs(requestSchedule.dateTo).format('DD.MM')}`;

  return (
    <ScrollSyncPane>
      <div className={clsx('requestRows border-b group requestRow !pl-1 !pr-3')}>
        <div className="flex items-center justify-between relative">
          <p className="flex items-center gap-x-2 Caption-small">
            <span className={'max-w-[60px] break-words'} title={requestSchedule.position?.name}>
              {requestSchedule.position?.name}
            </span>
            <span className="text-smena_text-secondary max-w-fit">{schedule}</span>
          </p>

          {requestSchedule.timesOfDay === ShiftTime.Day ? (
            <SunIcon className={'text-smena_text-helper block'} />
          ) : requestSchedule.timesOfDay === ShiftTime.Night ? (
            <MoonIcon className={'text-smena_text-helper'} />
          ) : null}
          {!isFinishedRequest && (
            <MoreDropdown
              onShowDropdownHandler={() => {
                setEditGraphic(value => !value);
              }}
              showDropdown={editGraphic}
              setShowDropdown={setEditGraphic}
            >
              <div className={'flex flex-col'}>
                {isMeSupervisor && !isMeAuthor ? (
                  <button
                    className="px-3 py-2 Body1 text-left"
                    onClick={() => {
                      setShowEditGraphicSidebar(true);
                      setRequestSchedule(requestSchedule);
                    }}
                  >
                    Посмотреть график
                  </button>
                ) : (
                  <>
                    <button
                      className="px-3 py-2 Body1 text-left"
                      onClick={() => {
                        setShowEditGraphicSidebar(true);
                        setRequestSchedule(requestSchedule);
                      }}
                    >
                      Редактировать
                    </button>
                    <button
                      className="px-3 py-2 Body1 text-left text-smena_red"
                      onClick={deleteRequestScheduleHandler}
                      disabled={loading}
                    >
                      Удалить
                    </button>
                  </>
                )}
              </div>
            </MoreDropdown>
          )}
        </div>
      </div>
    </ScrollSyncPane>
  );
};

export default RequestProfession;
