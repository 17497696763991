import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityCasingFragmentDoc } from '../../../../facilityCasings/api/fragments/generated/FacilityCasing.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCasingMutationVariables = Types.Exact<{
  input: Types.CasingCardInput;
}>;


export type CreateCasingMutation = { __typename?: 'Mutation', createCasing: { __typename?: 'Casing', id: string, createdAt: any, facilityId: string, payout: Types.PayoutType, unit: Types.UnitType, hourRate: number, positionId: string, isSelfEmployed?: boolean | null, selfEmployedRate?: number | null, hasProbation: boolean, probationPeriod?: number | null, probationRate?: number | null, isMedBookRequired: boolean, hasEducation?: boolean | null, facility: { __typename?: 'Facility', name: string, cityId?: string | null, city?: { __typename?: 'City', name: string } | null }, probationRateHistory?: Array<{ __typename?: 'CasingRateEntity', rate: number, startDatetime: any }> | null, rateHistory?: Array<{ __typename?: 'CasingRateEntity', rate: number, startDatetime: any }> | null, plannedRate?: { __typename?: 'CasingRateEntity', id: string, rate: number, startDatetime: any } | null, plannedProbationRate?: { __typename?: 'CasingRateEntity', id: string, rate: number, startDatetime: any } | null } };


export const CreateCasingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCasing"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CasingCardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCasing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityCasing"}}]}}]}},...FacilityCasingFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateCasingMutationFn = Apollo.MutationFunction<CreateCasingMutation, CreateCasingMutationVariables>;

/**
 * __useCreateCasingMutation__
 *
 * To run a mutation, you first call `useCreateCasingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCasingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCasingMutation, { data, loading, error }] = useCreateCasingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCasingMutation(baseOptions?: Apollo.MutationHookOptions<CreateCasingMutation, CreateCasingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCasingMutation, CreateCasingMutationVariables>(CreateCasingDocument, options);
      }
export type CreateCasingMutationHookResult = ReturnType<typeof useCreateCasingMutation>;
export type CreateCasingMutationResult = Apollo.MutationResult<CreateCasingMutation>;
export type CreateCasingMutationOptions = Apollo.BaseMutationOptions<CreateCasingMutation, CreateCasingMutationVariables>;