import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemovePhotoInFacilityGalleryMutationVariables = Types.Exact<{
  photoIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type RemovePhotoInFacilityGalleryMutation = { __typename?: 'Mutation', removePhotoInFacilityGallery: boolean };


export const RemovePhotoInFacilityGalleryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemovePhotoInFacilityGallery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"photoIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removePhotoInFacilityGallery"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"photoIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"photoIds"}}}]}]}}]} as unknown as DocumentNode;
export type RemovePhotoInFacilityGalleryMutationFn = Apollo.MutationFunction<RemovePhotoInFacilityGalleryMutation, RemovePhotoInFacilityGalleryMutationVariables>;

/**
 * __useRemovePhotoInFacilityGalleryMutation__
 *
 * To run a mutation, you first call `useRemovePhotoInFacilityGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePhotoInFacilityGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePhotoInFacilityGalleryMutation, { data, loading, error }] = useRemovePhotoInFacilityGalleryMutation({
 *   variables: {
 *      photoIds: // value for 'photoIds'
 *   },
 * });
 */
export function useRemovePhotoInFacilityGalleryMutation(baseOptions?: Apollo.MutationHookOptions<RemovePhotoInFacilityGalleryMutation, RemovePhotoInFacilityGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePhotoInFacilityGalleryMutation, RemovePhotoInFacilityGalleryMutationVariables>(RemovePhotoInFacilityGalleryDocument, options);
      }
export type RemovePhotoInFacilityGalleryMutationHookResult = ReturnType<typeof useRemovePhotoInFacilityGalleryMutation>;
export type RemovePhotoInFacilityGalleryMutationResult = Apollo.MutationResult<RemovePhotoInFacilityGalleryMutation>;
export type RemovePhotoInFacilityGalleryMutationOptions = Apollo.BaseMutationOptions<RemovePhotoInFacilityGalleryMutation, RemovePhotoInFacilityGalleryMutationVariables>;