import ExclamationMark12 from 'assets/icons/ExclamationMark12';
import CheckCircleFill12 from 'assets/icons/CheckCircleFill12';
import Clock12 from 'assets/icons/Clock12';
import { EAutoSave } from 'enums/EAutoSave';

export const getAutoSaveRu = (status?: EAutoSave) => {
  const statuses = [
    {
      status: EAutoSave.SAVED,
      statusRu: 'Изменения сохранены',
      statusClassName: 'bg-smena_gray-5 text-smena_text-secondary',
      statusIcon: <CheckCircleFill12 />,
    },
    {
      status: EAutoSave.LOADING,
      statusRu: 'Сохраняем...',
      statusClassName: 'bg-smena_gray-5 text-smena_text-secondary',
      statusIcon: <Clock12 />,
    },
    {
      status: EAutoSave.ERROR,
      statusRu: 'Изменения не сохранены',
      statusClassName: 'bg-smena_red-extra_light text-smena_red',
      statusIcon: <ExclamationMark12 />,
    },
  ];

  const res = statuses.find(el => el.status === status);
  if (!res) return { statusRu: 'Статус не найден', statusClassName: '', statusIcon: null };
  return res;
};
