const StarIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        focusable="false"
        data-icon="star"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path d="M7.81053 1.12713C8.18302 -0.0280527 9.8174 -0.0280527 10.1899 1.12713L11.656 5.67379H16.4267C17.6353 5.67379 18.1403 7.21846 17.1651 7.93239L13.2973 10.764L14.7696 15.33C15.1415 16.4833 13.8193 17.438 12.8416 16.7222L9.00021 13.9099L5.15886 16.7222C4.18109 17.438 2.85889 16.4833 3.23078 15.33L4.70309 10.764L0.835295 7.93239C-0.139895 7.21845 0.365098 5.67379 1.57369 5.67379H6.34444L7.81053 1.12713Z" />
      </svg>
    </>
  );
};

export default StarIcon;
