import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBalanceOperationMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteBalanceOperationMutation = { __typename?: 'Mutation', deleteBalanceOperation: boolean };


export const DeleteBalanceOperationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteBalanceOperation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteBalanceOperation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type DeleteBalanceOperationMutationFn = Apollo.MutationFunction<DeleteBalanceOperationMutation, DeleteBalanceOperationMutationVariables>;

/**
 * __useDeleteBalanceOperationMutation__
 *
 * To run a mutation, you first call `useDeleteBalanceOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBalanceOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBalanceOperationMutation, { data, loading, error }] = useDeleteBalanceOperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBalanceOperationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBalanceOperationMutation, DeleteBalanceOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBalanceOperationMutation, DeleteBalanceOperationMutationVariables>(DeleteBalanceOperationDocument, options);
      }
export type DeleteBalanceOperationMutationHookResult = ReturnType<typeof useDeleteBalanceOperationMutation>;
export type DeleteBalanceOperationMutationResult = Apollo.MutationResult<DeleteBalanceOperationMutation>;
export type DeleteBalanceOperationMutationOptions = Apollo.BaseMutationOptions<DeleteBalanceOperationMutation, DeleteBalanceOperationMutationVariables>;