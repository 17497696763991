import { ApolloClient, from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import cache from './apollo/Cache';
import errorLink from './apollo/ErrorLink';
import authMiddleware from './apollo/AuthLink';
import WsLink from './apollo/WsLink';
import httpUploadLink from './apollo/HttpUploadLink';

interface Definition {
  kind: string;
  operation?: string;
}

export function createApolloClient() {
  const link = from([
    errorLink,
    split(
      ({ query }) => {
        const { kind, operation }: Definition = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      WsLink,
      httpUploadLink,
    ),
  ]);

  return new ApolloClient({
    link: authMiddleware.concat(link),
    cache,
    // connectToDevTools: !isProd,
    name: 'Smena',
  });
}
