import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ForemenQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ForemenQuery = { __typename?: 'Query', foremans: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string }> };


export const ForemenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Foremen"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"foremans"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstname"}},{"kind":"Field","name":{"kind":"Name","value":"lastname"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useForemenQuery__
 *
 * To run a query within a React component, call `useForemenQuery` and pass it any options that fit your needs.
 * When your component renders, `useForemenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForemenQuery({
 *   variables: {
 *   },
 * });
 */
export function useForemenQuery(baseOptions?: Apollo.QueryHookOptions<ForemenQuery, ForemenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForemenQuery, ForemenQueryVariables>(ForemenDocument, options);
      }
export function useForemenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForemenQuery, ForemenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForemenQuery, ForemenQueryVariables>(ForemenDocument, options);
        }
export function useForemenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ForemenQuery, ForemenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ForemenQuery, ForemenQueryVariables>(ForemenDocument, options);
        }
export type ForemenQueryHookResult = ReturnType<typeof useForemenQuery>;
export type ForemenLazyQueryHookResult = ReturnType<typeof useForemenLazyQuery>;
export type ForemenSuspenseQueryHookResult = ReturnType<typeof useForemenSuspenseQuery>;
export type ForemenQueryResult = Apollo.QueryResult<ForemenQuery, ForemenQueryVariables>;