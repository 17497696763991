import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import MapPage from 'pages/map';
import PrivacyPolicyPage from 'pages/privacyPolicy';
import MapTwoPage from 'pages/mapTwo';
import NotFound from 'pages/404';
import Home from 'pages/main';
import UserPage from 'pages/users';
import QuestionnairePage from 'pages/userQuestionnaire';
import UserShifts from 'pages/userTracking';
import UserSchedule from 'pages/userSchedule';
import Facilities from 'pages/facilities';
import UpdateFacility from 'pages/facility';
import FacilityVacancies from 'pages/facilityVacancies';
import FacilityRequestsPage from 'pages/facilityRequests';
import FacilitySchedule from 'pages/facilityRequestsSchedule';
import Groups from 'pages/facilityGroups';
import FacilitiesGroupPage from 'pages/facilityGroupFacilities';
import FacilityGroupBalance from 'pages/facilityGroupBalance';
import Vacancies from 'pages/vacancies';
import Vacancy from 'pages/vacancy';
import Positions from 'pages/positions';
import UpdatePositions from 'pages/position';
import Payments from 'pages/payments';
import Timesheet from 'pages/timesheets';
import Leads from 'pages/leads';
import EditLead from 'pages/lead';
import ModerationReportsPage from 'pages/moderationReports';
import CreateReport from 'pages/createModerationReport';
import BalancePage from 'pages/balance';
import Requests from 'pages/requests';
import EditRequest from 'pages/request';
import ProtectedRoutes from 'app/routes/protectRoute';
import Login from 'pages/login';
import StoriesPage from 'pages/stories';
import StocksPage from 'pages/stocks';
import TwentyOnePage from 'pages/twentyOne';
import PushPage from 'pages/push';
import PushesPage from 'pages/pushes';
import FacilityGroupCasings from 'pages/facilityGroupCasings';
import FacilityGroupCasing from 'pages/facilityGroupCasing';
import FacilityGroupVacanciesPage from 'pages/facilityGroupVacancies';
import FacilityCasings from 'pages/facilityCasings';
import FacilityCasing from 'pages/facilityCasing';
import FiredUser from 'pages/firedUsers';
import FacilityFiredUsers from 'pages/facilityFiredUsers';
import FacilityUsers from 'pages/facilityUsers';
import Loading from 'components/loading';
import VacanciesMapPage from 'pages/vacanciesMap';
// import UsersNavigate from '../pages/users/navigate';
import Cities from '../pages/cities';
import UpdateCity from 'pages/city';
import { Role } from 'generated/graphql';
import UserContactsPage from 'pages/userContacts';
import UpdateUserPage from 'pages/userEdit';
import FacilityGroup from 'pages/facilityGroup';
import PromotionsPage from 'pages/promotions';
import SettingLeads from 'pages/settingLeads';
import ReferralPrograms from 'pages/referralPrograms';
import CreateReferralProgram from 'pages/referralProgramCreate';

const CreateUserPage = lazy(() => import('pages/userCreate'));
const UpdateUser = lazy(() => import('pages/user'));
const UserPersonalDataPage = lazy(() => import('pages/userPersonalData'));
const UserAdditionalDocumentsPage = lazy(() => import('pages/userAdditionalDocuments'));
const PayInfoPage = lazy(() => import('pages/userPayment'));
const UpdateRequest = lazy(() => import('pages/facilityUpdateRequest'));
const PublishStory = lazy(() => import('pages/publishStory'));
const Story = lazy(() => import('pages/story'));
const Offer = lazy(() => import('pages/helpfull/offer'));
const Alfa = lazy(() => import('pages/helpfull/alfa'));
const MoiNalog = lazy(() => import('pages/helpfull/moiNalog'));
const UpdateModerationReport = lazy(() => import('pages/moderationReport'));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
      <Route
        path="offer"
        element={
          <React.Suspense fallback={<Loading />}>
            <Offer />
          </React.Suspense>
        }
      />
      <Route
        path="alfa-bank"
        element={
          <React.Suspense fallback={<Loading />}>
            <Alfa />
          </React.Suspense>
        }
      />
      <Route
        path="moi-nalog"
        element={
          <React.Suspense fallback={<Loading />}>
            <MoiNalog />
          </React.Suspense>
        }
      />
      <Route path="login" element={<Login />} />
      <Route path="map" element={<MapPage />} />
      <Route path="map-two" element={<MapTwoPage />} />
      <Route
        element={
          <ProtectedRoutes allowedRoles={[Role.Admin, Role.Supervisor, Role.ClientDirector, Role.ClientManager]} />
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="timesheet" element={<Timesheet />} />
        <Route path="users">
          <Route index element={<UserPage />} />
          <Route path="fired-users">
            <Route index element={<FiredUser />} />
          </Route>
          <Route path="create">
            <Route
              index
              element={
                <React.Suspense fallback={null}>
                  <CreateUserPage />
                </React.Suspense>
              }
            />
          </Route>
          <Route path=":userId">
            <Route
              index
              element={
                <React.Suspense fallback={null}>
                  <UpdateUser />
                </React.Suspense>
              }
            />
            <Route
              path="edit"
              element={
                <React.Suspense fallback={null}>
                  <UpdateUserPage />
                </React.Suspense>
              }
            />
            <Route
              path="personal-data"
              element={
                <React.Suspense fallback={<Loading />}>
                  <UserPersonalDataPage />
                </React.Suspense>
              }
            />
            <Route
              path="additional-documents"
              element={
                <React.Suspense fallback={<Loading />}>
                  <UserAdditionalDocumentsPage />
                </React.Suspense>
              }
            />
            <Route
              path="payment-info"
              element={
                <React.Suspense fallback={<Loading />}>
                  <PayInfoPage />
                </React.Suspense>
              }
            />
            <Route path="questionnaire" element={<QuestionnairePage />} />
            <Route path="shifts" element={<UserShifts />} />
            <Route path="schedule" element={<UserSchedule />} />
          </Route>
        </Route>
        <Route
          path="contracts"
          element={
            <React.Suspense fallback={<Loading />}>
              <UserContactsPage />
            </React.Suspense>
          }
        />
        <Route path="requests">
          <Route index element={<Requests />} />
          <Route path=":requestId" element={<EditRequest />} />
          <Route
            path="create"
            element={
              <React.Suspense fallback={<Loading />}>
                <UpdateRequest />
              </React.Suspense>
            }
          />
          <Route
            path="edit/:requestId"
            element={
              <React.Suspense fallback={<Loading />}>
                <UpdateRequest />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="facilities">
          <Route index element={<Facilities />} />
          <Route path=":facilityId">
            <Route index element={<UpdateFacility />} />
            <Route path="fired-users" element={<FacilityFiredUsers />} />
            <Route path="users" element={<FacilityUsers />} />
            <Route path="requests">
              <Route index element={<FacilityRequestsPage />} />
              <Route path=":requestId" element={<EditRequest />} />
              <Route
                path="create"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <UpdateRequest />
                  </React.Suspense>
                }
              />
              <Route
                path="edit/:requestId"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <UpdateRequest />
                  </React.Suspense>
                }
              />
            </Route>
            <Route path="schedule">
              <Route index element={<FacilitySchedule />} />
            </Route>
            <Route path="casings">
              <Route index element={<FacilityCasings />} />
              <Route path={'create'} element={<FacilityCasing />} />
              <Route path={':casingId'} element={<FacilityCasing />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoutes allowedRoles={[Role.Admin, Role.Supervisor]} />}>
        <Route path="users">
          <Route index element={<UserPage />} />
        </Route>
        <Route path="moderation">
          <Route index element={<ModerationReportsPage />} />
          <Route path="create" element={<CreateReport />} />
          <Route
            path=":id"
            element={
              <React.Suspense fallback={<span className={'load-line'}></span>}>
                <UpdateModerationReport />
              </React.Suspense>
            }
          />
        </Route>

        <Route path="payments" element={<Payments />} />
      </Route>

      <Route path="facilities">
        <Route element={<ProtectedRoutes allowedRoles={[Role.Admin]} />}>
          <Route path="create" element={<UpdateFacility />} />
        </Route>
        <Route path=":facilityId">
          <Route element={<ProtectedRoutes allowedRoles={[Role.Admin]} />}>
            <Route path="vacancies">
              <Route index element={<FacilityVacancies />} />
              <Route path={':vacancyId'} element={<Vacancy />} />
              <Route path={'create'} element={<Vacancy />} />
            </Route>
            <Route path={'vacancies-map'} element={<VacanciesMapPage />} />
          </Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoutes allowedRoles={[Role.Admin]} />}>
        <Route path="groups">
          <Route index element={<Groups />} />
          <Route path="create" element={<FacilityGroup />} />
          <Route path=":id">
            <Route index element={<FacilityGroup />} />
            <Route path="facilities">
              <Route path="create" element={<UpdateFacility />} />
              <Route index element={<FacilitiesGroupPage />} />
              <Route path=":facilityId">
                <Route index element={<UpdateFacility />} />
                <Route path="fired-users" element={<FacilityFiredUsers />} />
                <Route path="users" element={<FacilityUsers />} />
                <Route path="requests">
                  <Route index element={<FacilityRequestsPage />} />
                  <Route path=":requestId" element={<EditRequest />} />
                  <Route
                    path="create"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <UpdateRequest />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="edit/:requestId"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <UpdateRequest />
                      </React.Suspense>
                    }
                  />
                </Route>
                <Route path="schedule">
                  <Route index element={<FacilitySchedule />} />
                </Route>
                <Route path="casings">
                  <Route index element={<FacilityCasings />} />
                  <Route path={'create'} element={<FacilityCasing />} />
                  <Route path={':casingId'} element={<FacilityCasing />} />
                </Route>
                <Route path="vacancies">
                  <Route index element={<FacilityVacancies />} />
                  <Route path={':vacancyId'} element={<Vacancy />} />
                  <Route path={'create'} element={<Vacancy />} />
                </Route>
                <Route path={'vacancies-map'} element={<VacanciesMapPage />} />
              </Route>
            </Route>
            <Route path="casings">
              <Route index element={<FacilityGroupCasings />} />
              <Route path={':casingId'} element={<FacilityGroupCasing />} />
              <Route path={'create'} element={<FacilityGroupCasing />} />
            </Route>
            <Route path="vacancies">
              <Route index element={<FacilityGroupVacanciesPage />} />
              <Route path={':vacancyId'} element={<Vacancy />} />
              <Route path={'create'} element={<Vacancy />} />
            </Route>
            <Route path={'vacancies-map'} element={<VacanciesMapPage />} />
            <Route path="balance" element={<FacilityGroupBalance />} />
          </Route>
        </Route>
        <Route path="vacancies">
          <Route index element={<Vacancies />} />
          <Route path="create" element={<Vacancy />} />
          <Route path=":vacancyId" element={<Vacancy />} />
        </Route>
        <Route path={'vacancies-map'} element={<VacanciesMapPage />} />

        <Route path="positions">
          <Route index element={<Positions />} />
          <Route path="create" element={<UpdatePositions />} />
          <Route path=":positionId" element={<UpdatePositions />} />
        </Route>
        <Route path="cities">
          <Route index element={<Cities />} />
          <Route path="create" element={<UpdateCity />} />
          <Route path=":id" element={<UpdateCity />} />
        </Route>

        <Route path="stories">
          <Route index element={<StoriesPage />} />
          <Route
            path=":id"
            element={
              <React.Suspense fallback={<Loading />}>
                <Story />
              </React.Suspense>
            }
          />
          <Route
            path=":id/publish"
            element={
              <React.Suspense fallback={<Loading />}>
                <PublishStory />
              </React.Suspense>
            }
          />
        </Route>

        <Route path="pushes">
          <Route index element={<PushesPage />} />
          <Route path=":id" element={<PushPage />} />
        </Route>

        <Route path="offers">
          <Route index element={<StocksPage />} />
          <Route path="referrals">
            <Route index element={<ReferralPrograms />} />
            <Route path="create" element={<CreateReferralProgram />} />
            <Route path=":settingId" element={<Leads />} />
          </Route>
          <Route path="leads">
            <Route index element={<Leads />} />
            <Route path="setting" element={<SettingLeads />}>
              <Route path=":settingId" element={<SettingLeads />} />
            </Route>
            <Route path=":leadId" element={<EditLead />} />
          </Route>
          <Route path="twenty-one">
            <Route index element={<TwentyOnePage />} />
          </Route>
          <Route path="promotions">
            <Route index element={<PromotionsPage />} />
          </Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoutes allowedRoles={[Role.Admin, Role.ClientDirector]} />}>
        <Route path="balance">
          <Route index element={<BalancePage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
