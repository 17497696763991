import { PayoutType, UnitType } from 'generated/graphql';

import { errorToast, infoToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useCallback } from 'react';
import { FacilityCasingFragment } from 'pages/facilityCasings/api/fragments/generated/FacilityCasing.fragment';
import { useRemoveCasingMutation } from 'pages/facilityCasing/api/mutations/generated/RemoveCasing';
import { useUpdateCasingMutation } from 'pages/facilityCasing/api/mutations/generated/UpdateCasing';

interface Props {
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  casing?: FacilityCasingFragment;
}

export const useUpdateProfession = ({ casing, setShow }: Props) => {
  const [removeCasing, { loading: removeCasingLoading }] = useRemoveCasingMutation({
    update(cache) {
      //TODO: change casign to data
      if (casing) {
        const id = cache.identify(casing);
        cache.evict({ id });
      }
    },
  });
  const [updateCasing, { loading: updateCasingLoading }] = useUpdateCasingMutation();

  const initialValues = {
    positionId: String(casing?.positionId),
    payout: String(casing?.payout),
    unit: String(casing?.unit),
    hourRate: casing?.hourRate || 0,
    isSelfEmployed: Boolean(casing?.isSelfEmployed),
    selfEmployedRate: casing?.selfEmployedRate || 0,
    hasProbation: Boolean(casing?.hasProbation),
    probationPeriod: casing?.probationPeriod,
    probationRate: casing?.probationRate,
    isMedBookRequired: Boolean(casing?.isMedBookRequired),
  };

  const validate = (values: typeof initialValues) => {
    const errors: any = {};
    if (!values.hourRate) {
      errors.hourRate = 'Вы не указали ставку';
    }
    if (Number(values.hourRate) === 0) {
      errors.hourRate = 'Ставка не может быть равна 0';
    }
    if (!/^\d+[.]?\d{1,2}$/.test(String(values.hourRate))) {
      errors.hourRate = 'Некорректный ввод';
    }
    if (Number(values.hourRate) > 1000) {
      errors.hourRate = 'Ставка не может быть больше 1000';
    }
    if (Number(values.selfEmployedRate) > 1000) {
      errors.selfEmployedRate = 'Бонус не может быть больше 1000';
    }
    if (values.hasProbation) {
      if (!values.probationPeriod) {
        errors.probationPeriod = 'Вы не указали период';
      }
      if (!values.probationRate) {
        errors.probationRate = 'Вы не указали ставку';
      }
    }
    return errors;
  };
  const onSubmit = useCallback(
    (values: typeof initialValues) => {
      if (casing) {
        updateCasing({
          variables: {
            input: {
              facilityId: casing.facilityId,
              hourRate: Number(values.hourRate),
              payout: values?.payout as PayoutType,
              unit: values?.unit as UnitType,
              positionId: values.positionId,
              isSelfEmployed: values.isSelfEmployed,
              selfEmployedRate: Number(values.selfEmployedRate),
              hasProbation: values.hasProbation,
              probationPeriod: values.hasProbation ? Number(values.probationPeriod) : null,
              probationRate: values.hasProbation ? Number(values.probationRate) : null,
              isMedBookRequired: values.isMedBookRequired,
            },
          },
        })
          .then(res => {
            if (res.data?.updateCasing) {
              successToast('Должность изменена');
            }
          })
          .catch(e => {
            errorToast(errorHandler(e));
          });
      }
    },
    [casing, updateCasing]
  );
  const removeHandler = useCallback(() => {
    if (!casing) return;
    const { positionId, facilityId } = casing;
    removeCasing({
      variables: {
        facilityId,
        positionId,
      },
    })
      .then(res => {
        if (res.data?.removeCasing) {
          infoToast('Профессия удалена');
        }
      })
      .catch(e => {
        errorToast(errorHandler(e));
      })
      .finally(() => {
        if (setShow) {
          setShow(true);
        }
      });
  }, [casing, removeCasing, setShow]);
  return { removeCasingLoading, updateCasingLoading, initialValues, validate, onSubmit, removeHandler };
};
