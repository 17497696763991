import { useCallback, useMemo } from 'react';
import { useFacility } from '../../../../utils/api/useFacility';
import dayjs from 'dayjs';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useChangeFacilityPenaltyMutation } from '../../../facilities/api/mutations/generated/ChangeFacilityPenalty';
import { writeFacilityPenaltyCache } from './writeCache';
import { useRemoveFacilityPenaltyMutation } from '../../api/mutations/generated/RemoveFacilityPenalty';

export const useFacilityPenalty = () => {
  const { facility, id } = useFacility();
  const [changeFacilityPenalty, { loading }] = useChangeFacilityPenaltyMutation();
  const [removeFacilityPenalty] = useRemoveFacilityPenaltyMutation();

  const penalty = useMemo(() => {
    if (!facility?.penalty?.length) return null;
    const activePenalty = facility.penalty.filter(el => !el.isDeleted);
    if (!activePenalty) return null;
    return activePenalty[0];
  }, [facility?.penalty]);
  const initialValues = useMemo(
    () => ({
      penaltySwitch: penalty ? !penalty.isDeleted : false,
      penaltyTime: penalty ? dayjs().set('hour', penalty.applyTimeInMinutes / 60) : null,
      penaltyCost: penalty ? String(penalty.cost) : '',
    }),
    [penalty]
  );
  const validate = (values: typeof initialValues) => {
    const error: any = {};
    if (Number(values.penaltyCost) > 100000 || Number(values.penaltyCost) < 1) {
      error.penaltyCost = 'Допустимый размер удержания от 1 до 100.000 рублей';
    }
    if (!values.penaltyTime) {
      error.penaltyTime = 'Обязательное поле';
    }
    return error;
  };
  const onSubmit = useCallback(
    (values: typeof initialValues) => {
      if (!id) return;
      changeFacilityPenalty({
        variables: {
          applyTimeInMinutes: dayjs(values.penaltyTime).get('hours') * 60,
          cost: Number(values.penaltyCost),
          facilityId: id,
        },
      })
        .then(() => {
          successToast('Удержание обновлено');
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    },
    [changeFacilityPenalty, id]
  );
  const removePenalty = useCallback(() => {
    if (!id) return;
    if (penalty) {
      removeFacilityPenalty({
        variables: {
          facilityId: id,
        },
        update: cache => {
          writeFacilityPenaltyCache({
            penaltyId: penalty.id,
            cache,
          });
        },
      })
        .then(e => {
          if (e.data?.removeFacilityPenalty) {
            successToast('Удержание удалено');
          }
        })
        .catch(() => {
          errorToast('Произошла ошибка при удалении удержания');
        });
    }
  }, [id, penalty, removeFacilityPenalty]);

  return { initialValues, validate, onSubmit, removePenalty, loading };
};
