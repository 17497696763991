import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteStoryAndFilesMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteStoryAndFilesMutation = { __typename?: 'Mutation', deleteStoryAndFiles: boolean };


export const DeleteStoryAndFilesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteStoryAndFiles"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteStoryAndFiles"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type DeleteStoryAndFilesMutationFn = Apollo.MutationFunction<DeleteStoryAndFilesMutation, DeleteStoryAndFilesMutationVariables>;

/**
 * __useDeleteStoryAndFilesMutation__
 *
 * To run a mutation, you first call `useDeleteStoryAndFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryAndFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryAndFilesMutation, { data, loading, error }] = useDeleteStoryAndFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoryAndFilesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryAndFilesMutation, DeleteStoryAndFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoryAndFilesMutation, DeleteStoryAndFilesMutationVariables>(DeleteStoryAndFilesDocument, options);
      }
export type DeleteStoryAndFilesMutationHookResult = ReturnType<typeof useDeleteStoryAndFilesMutation>;
export type DeleteStoryAndFilesMutationResult = Apollo.MutationResult<DeleteStoryAndFilesMutation>;
export type DeleteStoryAndFilesMutationOptions = Apollo.BaseMutationOptions<DeleteStoryAndFilesMutation, DeleteStoryAndFilesMutationVariables>;