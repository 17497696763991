import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { UserIntersectFragmentDoc } from '../../../../user/api/fragments/generated/UserIntersect.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetWorkersForRequestShiftQueryVariables = Types.Exact<{
  searchQuery?: Types.InputMaybe<Types.Scalars['String']['input']>;
  zayavkaShiftId: Types.Scalars['String']['input'];
}>;


export type GetWorkersForRequestShiftQuery = { __typename?: 'Query', getWorkersForZayavkaShift: Array<{ __typename?: 'UserIntersect', id: string, firstname: string, lastname: string, patronymic?: string | null, requisite?: { __typename?: 'Requisite', medicalAttestationEnd?: string | null, medicalInvoiceIssueDate?: any | null } | null, myDocumentsReviews: Array<{ __typename?: 'DocumentReview', id: string, status: Types.DocumentStatus, type: Types.ReviewType }>, intersect?: Array<{ __typename?: 'Shift', id: string, dateFrom: any, dateEnd: any, comment?: string | null, duration: string, lunchDuration?: number | null, facilityId: string, hourRate?: number | null, positionId: string, stage: Types.ShiftStage, updatedAt: any, userId: string, user?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null } | null }> | null }> };


export const GetWorkersForRequestShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetWorkersForRequestShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"searchQuery"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"zayavkaShiftId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getWorkersForZayavkaShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"zayavkaShiftId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"zayavkaShiftId"}}},{"kind":"Argument","name":{"kind":"Name","value":"searchQuery"},"value":{"kind":"Variable","name":{"kind":"Name","value":"searchQuery"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserIntersect"}}]}}]}},...UserIntersectFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetWorkersForRequestShiftQuery__
 *
 * To run a query within a React component, call `useGetWorkersForRequestShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkersForRequestShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkersForRequestShiftQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      zayavkaShiftId: // value for 'zayavkaShiftId'
 *   },
 * });
 */
export function useGetWorkersForRequestShiftQuery(baseOptions: Apollo.QueryHookOptions<GetWorkersForRequestShiftQuery, GetWorkersForRequestShiftQueryVariables> & ({ variables: GetWorkersForRequestShiftQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkersForRequestShiftQuery, GetWorkersForRequestShiftQueryVariables>(GetWorkersForRequestShiftDocument, options);
      }
export function useGetWorkersForRequestShiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkersForRequestShiftQuery, GetWorkersForRequestShiftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkersForRequestShiftQuery, GetWorkersForRequestShiftQueryVariables>(GetWorkersForRequestShiftDocument, options);
        }
export function useGetWorkersForRequestShiftSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkersForRequestShiftQuery, GetWorkersForRequestShiftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWorkersForRequestShiftQuery, GetWorkersForRequestShiftQueryVariables>(GetWorkersForRequestShiftDocument, options);
        }
export type GetWorkersForRequestShiftQueryHookResult = ReturnType<typeof useGetWorkersForRequestShiftQuery>;
export type GetWorkersForRequestShiftLazyQueryHookResult = ReturnType<typeof useGetWorkersForRequestShiftLazyQuery>;
export type GetWorkersForRequestShiftSuspenseQueryHookResult = ReturnType<typeof useGetWorkersForRequestShiftSuspenseQuery>;
export type GetWorkersForRequestShiftQueryResult = Apollo.QueryResult<GetWorkersForRequestShiftQuery, GetWorkersForRequestShiftQueryVariables>;