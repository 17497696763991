import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { IProtectedRoutes } from 'interfaces/IProtectedRoutes';
import { useMe } from 'utils/api/useMe';

const ProtectedRoutes = ({ allowedRoles }: IProtectedRoutes) => {
  const me = useMe();
  const location = useLocation();

  return me ? (
    allowedRoles.includes(me.role) ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
