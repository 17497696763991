import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNotifyLetterMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CreateNotifyLetterInput>;
}>;


export type CreateNotifyLetterMutation = { __typename?: 'Mutation', createNotifyLetter: { __typename?: 'NotifyLetter', id: string } };


export const CreateNotifyLetterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateNotifyLetter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateNotifyLetterInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createNotifyLetter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type CreateNotifyLetterMutationFn = Apollo.MutationFunction<CreateNotifyLetterMutation, CreateNotifyLetterMutationVariables>;

/**
 * __useCreateNotifyLetterMutation__
 *
 * To run a mutation, you first call `useCreateNotifyLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotifyLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotifyLetterMutation, { data, loading, error }] = useCreateNotifyLetterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotifyLetterMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotifyLetterMutation, CreateNotifyLetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotifyLetterMutation, CreateNotifyLetterMutationVariables>(CreateNotifyLetterDocument, options);
      }
export type CreateNotifyLetterMutationHookResult = ReturnType<typeof useCreateNotifyLetterMutation>;
export type CreateNotifyLetterMutationResult = Apollo.MutationResult<CreateNotifyLetterMutation>;
export type CreateNotifyLetterMutationOptions = Apollo.BaseMutationOptions<CreateNotifyLetterMutation, CreateNotifyLetterMutationVariables>;