import { useMemo } from 'react';
import { useMeRole } from 'store/hooks/useMeRole';
import useSupervisorWorkposts from './useSupervisorWorkposts';
import { Role } from 'generated/graphql';

const useSuperFacilities = () => {
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const supervisorWorkposts = useSupervisorWorkposts();
  return useMemo(() => {
    if (isMeSupervisor) {
      return supervisorWorkposts;
    }
    return [];
  }, [isMeSupervisor, supervisorWorkposts]);
};

export default useSuperFacilities;
