import React from 'react';
import { Form, Formik } from 'formik';

import MainLayout from 'components/layout/main';
import { useCityPage } from './hooks';
import MapBlock from './components/mapBlock';
import SimpleInput from 'ui/input';
import { clsx } from 'clsx';

const UpdateCity = () => {
  const {
    title,
    initialValues,
    validate,
    onSubmit,
    loading,
    breadcrumbs,
    id,
    loadingPosition,
    onDelete,
    removeCityLoading,
  } = useCityPage();

  return (
    <MainLayout title={title} breadCrumbs={breadcrumbs} loading={loadingPosition}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, handleChange }) => {
          return (
            <Form className="flex flex-col gap-y-5 max-w-[650px]">
              <div className="px-6 pb-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
                <span className="Subtitle1 text-smena_text-secondary pt-5">Основная информация</span>
                <SimpleInput
                  label={'Название города'}
                  type={'text'}
                  value={values.name}
                  onChange={handleChange}
                  name={'name'}
                  placeholder={'Название города'}
                  error={touched.name && errors.name ? errors.name : ''}
                />
              </div>
              <MapBlock
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
              />
              <div className={clsx('flex', id ? 'justify-between' : 'justify-end')}>
                {Boolean(id) && (
                  <button
                    type="button"
                    onClick={onDelete}
                    disabled={removeCityLoading}
                    className="btn-stroke_reject_big"
                  >
                    {removeCityLoading ? 'Загрузка' : 'Удалить'}
                  </button>
                )}
                <button type="submit" disabled={loading} className="btn-primary_big">
                  {loading ? 'Загрузка' : id ? 'Сохранить' : 'Создать'}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateCity;
