import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserNaimixContractQueryVariables = Types.Exact<{
  input: Types.GetUserNaimixContractInput;
}>;


export type GetUserNaimixContractQuery = { __typename?: 'Query', getUserNaimixContract: { __typename?: 'GetNaimixContractNaimixModel', fileContent: string } };


export const GetUserNaimixContractDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserNaimixContract"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetUserNaimixContractInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserNaimixContract"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileContent"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetUserNaimixContractQuery__
 *
 * To run a query within a React component, call `useGetUserNaimixContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNaimixContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNaimixContractQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserNaimixContractQuery(baseOptions: Apollo.QueryHookOptions<GetUserNaimixContractQuery, GetUserNaimixContractQueryVariables> & ({ variables: GetUserNaimixContractQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNaimixContractQuery, GetUserNaimixContractQueryVariables>(GetUserNaimixContractDocument, options);
      }
export function useGetUserNaimixContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNaimixContractQuery, GetUserNaimixContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNaimixContractQuery, GetUserNaimixContractQueryVariables>(GetUserNaimixContractDocument, options);
        }
export function useGetUserNaimixContractSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserNaimixContractQuery, GetUserNaimixContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserNaimixContractQuery, GetUserNaimixContractQueryVariables>(GetUserNaimixContractDocument, options);
        }
export type GetUserNaimixContractQueryHookResult = ReturnType<typeof useGetUserNaimixContractQuery>;
export type GetUserNaimixContractLazyQueryHookResult = ReturnType<typeof useGetUserNaimixContractLazyQuery>;
export type GetUserNaimixContractSuspenseQueryHookResult = ReturnType<typeof useGetUserNaimixContractSuspenseQuery>;
export type GetUserNaimixContractQueryResult = Apollo.QueryResult<GetUserNaimixContractQuery, GetUserNaimixContractQueryVariables>;