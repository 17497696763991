import { create } from 'zustand';

interface MapState {
  latitude: string | null;
  longitude: string | null;
  setLatitude: (latitude: string | null) => void;
  setLongitude: (longitude: string | null) => void;
}

export const useMapStore = create<MapState>(set => ({
  latitude: '59.928036',
  longitude: '30.341975',
  setLatitude: latitude => set({ latitude }),
  setLongitude: longitude => set({ longitude }),
}));
