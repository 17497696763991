import MainLayout from 'components/layout/main';
import GroupsFilter from 'pages/facilityGroups/components/GroupsFilter';
import GroupElement from 'pages/facilityGroups/components/GroupElement';
import FacilityGroupsSkeleton from './components/FacilityGroupsSkeleton';
import { useFacilityGroups } from 'utils/api/useFacilityGroups';

const GroupsPage = () => {
  const { facilitiesGroups, loading, error } = useFacilityGroups();

  return (
    <MainLayout title="Группы" breadCrumbs={[{ path: '/groups', name: 'Группы' }]} error={error}>
      <div className="grid gap-y-5">
        <GroupsFilter />

        {loading ? (
          <FacilityGroupsSkeleton />
        ) : (
          <div className="grid grid-cols-12 gap-6 pb-5">
            {facilitiesGroups?.map(group => <GroupElement key={group.id} group={group} />)}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default GroupsPage;
