import React from 'react';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';

import { Switch } from 'ui/switch';
import SimpleInput from 'ui/input';

import TimePickerInput from 'ui/pickers/TimePickerInput';
import { useFacility } from 'utils/api/useFacility';
import { useFacilityPenalty } from './hooks';

const FacilityPenalty = () => {
  const { id } = useFacility();
  const { initialValues, validate, onSubmit, loading, removePenalty } = useFacilityPenalty();

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {({ values, errors, touched, handleChange, setFieldValue }) => {
        return (
          <Form className="facilityPageBlock blockTemplate">
            <div className="grid grid-cols-8 gap-5 gap">
              <div className="flex gap-x-5 items-center col-span-full">
                <span className="Subtitle1 text-smena_text-secondary">Удержание при отмене смены</span>
                <Switch
                  name="penaltySwitch"
                  checked={values.penaltySwitch}
                  disabled={!id}
                  onChange={({ target: { checked } }) => {
                    setFieldValue('penaltySwitch', checked);
                    if (!checked) {
                      removePenalty();
                    }
                  }}
                />
              </div>
              {values.penaltySwitch && (
                <>
                  <span className="col-span-full block Body2 text-smena_text-secondary">
                    Задайте время до начала смены, после которого будет назначено удержание за отмену смены.
                  </span>
                  <TimePickerInput
                    label="Порог срабатывания"
                    className="md:col-span-2 sm:col-span-4 col-span-full"
                    value={values.penaltyTime}
                    format="HH"
                    onChange={value => {
                      setFieldValue('penaltyTime', value);
                    }}
                    popupClassName={'absolute'}
                    placeholder={dayjs().format('mm')}
                    error={touched.penaltyTime && errors.penaltyTime ? errors.penaltyTime : ''}
                  />
                  <SimpleInput
                    divClassName="md:col-span-2 sm:col-span-4 col-span-full"
                    placeholder="1000"
                    label="Сумма удержания, ₽"
                    regText
                    onChange={handleChange}
                    value={values.penaltyCost}
                    type="text"
                    name="penaltyCost"
                    error={touched.penaltyCost && errors.penaltyCost ? errors.penaltyCost : ''}
                  />
                  <div className="col-span-2 pt-6">
                    <button className="btn-primary_small" type="submit" disabled={loading}>
                      Сохранить
                    </button>
                  </div>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FacilityPenalty;
