import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityGroupFragmentDoc } from '../../fragments/generated/FacilityGroup.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFacilityGroupMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
}>;


export type CreateFacilityGroupMutation = { __typename?: 'Mutation', createFacilityGroup: { __typename?: 'FacilityGroup', id: string, name: string, balance: number, logo?: { __typename?: 'File', id: string, mimetype: string, originalName?: string | null, path: string, size: number } | null, clientDirectors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null, groupManagers?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null } };


export const CreateFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityGroup"}}]}}]}},...FacilityGroupFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateFacilityGroupMutationFn = Apollo.MutationFunction<CreateFacilityGroupMutation, CreateFacilityGroupMutationVariables>;

/**
 * __useCreateFacilityGroupMutation__
 *
 * To run a mutation, you first call `useCreateFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFacilityGroupMutation, { data, loading, error }] = useCreateFacilityGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateFacilityGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateFacilityGroupMutation, CreateFacilityGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFacilityGroupMutation, CreateFacilityGroupMutationVariables>(CreateFacilityGroupDocument, options);
      }
export type CreateFacilityGroupMutationHookResult = ReturnType<typeof useCreateFacilityGroupMutation>;
export type CreateFacilityGroupMutationResult = Apollo.MutationResult<CreateFacilityGroupMutation>;
export type CreateFacilityGroupMutationOptions = Apollo.BaseMutationOptions<CreateFacilityGroupMutation, CreateFacilityGroupMutationVariables>;