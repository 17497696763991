import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeclineRequestByDirectorMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeclineRequestByDirectorMutation = { __typename?: 'Mutation', declineZayavkaByDirector: boolean };


export const DeclineRequestByDirectorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclineRequestByDirector"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineZayavkaByDirector"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type DeclineRequestByDirectorMutationFn = Apollo.MutationFunction<DeclineRequestByDirectorMutation, DeclineRequestByDirectorMutationVariables>;

/**
 * __useDeclineRequestByDirectorMutation__
 *
 * To run a mutation, you first call `useDeclineRequestByDirectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineRequestByDirectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineRequestByDirectorMutation, { data, loading, error }] = useDeclineRequestByDirectorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeclineRequestByDirectorMutation(baseOptions?: Apollo.MutationHookOptions<DeclineRequestByDirectorMutation, DeclineRequestByDirectorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineRequestByDirectorMutation, DeclineRequestByDirectorMutationVariables>(DeclineRequestByDirectorDocument, options);
      }
export type DeclineRequestByDirectorMutationHookResult = ReturnType<typeof useDeclineRequestByDirectorMutation>;
export type DeclineRequestByDirectorMutationResult = Apollo.MutationResult<DeclineRequestByDirectorMutation>;
export type DeclineRequestByDirectorMutationOptions = Apollo.BaseMutationOptions<DeclineRequestByDirectorMutation, DeclineRequestByDirectorMutationVariables>;