const RussiaIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9532_68)">
        <path
          d="M20 15.0001C20 15.5894 19.7659 16.1547 19.3491 16.5714C18.9324 16.9881 18.3671 17.2223 17.7778 17.2223H2.22222C1.63285 17.2223 1.06762 16.9881 0.650874 16.5714C0.234126 16.1547 0 15.5894 0 15.0001V12.7778H20V15.0001Z"
          fill="#CE2028"
        />
        <path d="M0 7.22217H20V12.7777H0V7.22217Z" fill="#22408C" />
        <path
          d="M17.7778 2.77783H2.22222C1.63285 2.77783 1.06762 3.01196 0.650874 3.42871C0.234126 3.84545 0 4.41068 0 5.00005L0 7.22228H20V5.00005C20 4.41068 19.7659 3.84545 19.3491 3.42871C18.9324 3.01196 18.3671 2.77783 17.7778 2.77783Z"
          fill="#EEEEEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_9532_68">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RussiaIcon;
