import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import MainSidebar from 'ui/sidebar';

import { useStore } from 'store';
import { isTest, RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import { positionName } from 'utils/helpers/filters/filtersName';
import { usePositions } from 'utils/api/usePositions';
import { useGetRequestByFacilityId } from 'utils/api/useGetRequestByFacilityId';
import FreeShiftForUser from './freeShiftForUser';
import AddedUser from './addedUser';
import EmptyShift from './emptyShift';
import Skeleton from 'antd/es/skeleton';
import FreeShiftWithoutUsers from './freeShiftWithoutUsers';

const ShiftSidebar = () => {
  const showShiftSidebar = useStore(state => state.showShiftSidebar);
  const setShowShiftSidebar = useStore(state => state.setShowShiftSidebar);
  const { positions } = usePositions();
  const requestShift = useStore(state => state.requestShift);
  const setRequestShift = useStore(state => state.setRequestShift);
  const { sortedRequests } = useGetRequestByFacilityId();

  const request = useMemo(
    () => sortedRequests?.find(el => el.id === requestShift?.zayavkaId),
    [sortedRequests, requestShift?.zayavkaId],
  );
  const title = `Смена ${dayjs(requestShift?.dateFrom).format(RU_DATE)}`;

  return (
    <MainSidebar
      title={title}
      show={showShiftSidebar}
      setShow={setShowShiftSidebar}
      onClose={() => {
        setRequestShift(undefined);
      }}
    >
      {!requestShift ? (
        <Skeleton active />
      ) : (
        <div className="flex flex-col gap-y-2 mb-5">
          <div className="flex gap-x-1 Subtitle1">
            <span>Номер заявки:</span>
            <Link to={`/requests/${requestShift?.zayavkaId}`} className="text-primary">
              {request?.number}
            </Link>
          </div>
          {isTest && (
            <>
              <div className="flex gap-x-1 Subtitle1">
                <span>ID смены: </span>
                <span>{requestShift?.shiftId}</span>
              </div>
              <div className="flex gap-x-1 Subtitle1">
                <span>ID смены заявки: </span>
                <span>{requestShift?.id}</span>
              </div>
              <div className="flex gap-x-1 Subtitle1">
                <span>ID графика этой смены: </span>
                <span>{requestShift?.zayavkaScheduleId}</span>
              </div>
            </>
          )}
          <div className="flex flex-col gap-y-3">
            <h4 className="H4">{positionName(positions, requestShift?.positionId)}</h4>
            <div className="flex gap-x-1 Subtitle2">
              <span>{dayjs(requestShift?.dateFrom).format(RU_DATE)}</span>
              <span>·</span>
              <span>
                {dayjs(requestShift?.dateFrom).format(TIME_DATE)} - {dayjs(requestShift?.dateEnd).format(TIME_DATE)}
              </span>
            </div>
            {requestShift?.shiftId ? (
              <AddedUser />
            ) : requestShift?.isFree ? (
              requestShift?.requestShiftSuggestions.length ? (
                <FreeShiftForUser />
              ) : (
                <FreeShiftWithoutUsers />
              )
            ) : (
              <EmptyShift />
            )}
          </div>
        </div>
      )}
    </MainSidebar>
  );
};

export default ShiftSidebar;
