import ErrorRobotIcon from '../../assets/icons/Error';

const Error = () => {
  return (
    <div className={'h-full flex flex-col justify-center items-center'}>
      <ErrorRobotIcon />
      <span>Ой, что-то пошло не так...</span>
    </div>
  );
};

export default Error;
