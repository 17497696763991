import { ClientUserFragment } from 'pages/users/api/fragments/generated/ClientUser.fragment';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';
import React, { useState } from 'react';
import FacilityBubble from './FacilityBubble';

type Props = {
  userFacilities?: any[] | null;
  isUserWorker: boolean;
  user: UsersPaginatedFragment | ClientUserFragment;
  showAcross?: boolean;
};
const FacilityBubbleBlock = ({ userFacilities, isUserWorker, user, showAcross }: Props) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      {userFacilities?.map((facility, index) => {
        const condition = !showAll ? index < 2 : true;
        if (condition) {
          return (
            <FacilityBubble
              canUnassign={isUserWorker}
              facility={facility}
              index={index}
              showAll={showAll}
              setShowAll={setShowAll}
              key={facility.id}
              userFacilities={userFacilities}
              user={user}
              isCollaborate={showAcross === undefined ? true : showAcross}
            />
          );
        }
      })}
    </>
  );
};

export default FacilityBubbleBlock;
