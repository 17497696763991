import dayjs from 'dayjs';
import clsx from 'clsx';
import { ScrollSyncPane } from 'react-scroll-sync';

import RequestProfessionDay from './days';
import { FacilityRequestSchedulesFragment } from 'pages/facilityRequests/api/fragments/generated/FacilityRequestSchedules.fragment';

interface Props {
  requestSchedule: FacilityRequestSchedulesFragment;
  days: dayjs.Dayjs[];
}
const RequestProfessionDates = ({ requestSchedule, days }: Props) => {
  return (
    <ScrollSyncPane>
      <div
        className={clsx('gap-x-1 requestRows border-b requestRow w-fit')}
        style={{
          gridTemplateColumns: `repeat(${days.length}, 80px)`,
        }}
      >
        {days.map(day => (
          <RequestProfessionDay key={day.valueOf()} day={day} requestSchedule={requestSchedule} />
        ))}
      </div>
    </ScrollSyncPane>
  );
};

export default RequestProfessionDates;
