import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilitiesFragmentDoc } from '../../fragments/generated/Facilities.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FacilitiesQuery = { __typename?: 'Query', facilities: Array<{ __typename?: 'Facility', id: string, name: string, facilityGroupId?: string | null, cityId?: string | null, latitude: string, longitude: string, radius: number }> };


export const FacilitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Facilities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Facilities"}}]}}]}},...FacilitiesFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useFacilitiesQuery__
 *
 * To run a query within a React component, call `useFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFacilitiesQuery(baseOptions?: Apollo.QueryHookOptions<FacilitiesQuery, FacilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilitiesQuery, FacilitiesQueryVariables>(FacilitiesDocument, options);
      }
export function useFacilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilitiesQuery, FacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilitiesQuery, FacilitiesQueryVariables>(FacilitiesDocument, options);
        }
export function useFacilitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FacilitiesQuery, FacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FacilitiesQuery, FacilitiesQueryVariables>(FacilitiesDocument, options);
        }
export type FacilitiesQueryHookResult = ReturnType<typeof useFacilitiesQuery>;
export type FacilitiesLazyQueryHookResult = ReturnType<typeof useFacilitiesLazyQuery>;
export type FacilitiesSuspenseQueryHookResult = ReturnType<typeof useFacilitiesSuspenseQuery>;
export type FacilitiesQueryResult = Apollo.QueryResult<FacilitiesQuery, FacilitiesQueryVariables>;