import * as Types from '../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReadAllNotificationMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ReadAllNotificationMutation = { __typename?: 'Mutation', readAllNotification: boolean };


export const ReadAllNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ReadAllNotification"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"readAllNotification"}}]}}]} as unknown as DocumentNode;
export type ReadAllNotificationMutationFn = Apollo.MutationFunction<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>;

/**
 * __useReadAllNotificationMutation__
 *
 * To run a mutation, you first call `useReadAllNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllNotificationMutation, { data, loading, error }] = useReadAllNotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useReadAllNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>(ReadAllNotificationDocument, options);
      }
export type ReadAllNotificationMutationHookResult = ReturnType<typeof useReadAllNotificationMutation>;
export type ReadAllNotificationMutationResult = Apollo.MutationResult<ReadAllNotificationMutation>;
export type ReadAllNotificationMutationOptions = Apollo.BaseMutationOptions<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>;