import React, { useCallback, useEffect, useState } from 'react';
import { ChartType, StatisticPeriod } from 'generated/graphql';
import { useStore } from 'store';
import { getTimePeriodCharts } from 'utils/helpers/get/getTimeePeriodCharts';
import { chartTimes } from 'utils/helpers/lists/chartTimes';
import SimpleSelect from 'ui/select';
import { ITooltipStatus } from 'interfaces/ITooltipStatus';
import { lastTimeLength } from 'utils/helpers/charts/lastTimeLength';
import CustomBarChart from 'pages/main/components/customBarChart';
import Skeleton from 'antd/es/skeleton';
import { useGetStatisticResponse, useIsSendRequest } from '../hooks';
import { useGetStatisticLazyQuery } from 'pages/main/api/queries/generated/GetStatistic';

export const AcceptedShiftsChart = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const [time, setTime] = useState(StatisticPeriod.Week);
  const [focusBar, setFocusBar] = useState<undefined | number>(undefined);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });
  const isSendRequest = useIsSendRequest();

  const [loadData, { data, loading }] = useGetStatisticLazyQuery();

  const handleTimeChange = useCallback((value: string) => {
    setTime(value as StatisticPeriod);
  }, []);

  const res = useGetStatisticResponse(data);

  useEffect(() => {
    if (!isSendRequest) return;
    loadData({
      variables: {
        input: {
          facilityId: trendsFacilityId,
          facilityGroupId: trendsGroupId,
          period: time,
          types: [ChartType.AcceptedShifts],
        },
      },
    });
  }, [isSendRequest, loadData, time, trendsFacilityId, trendsGroupId]);

  return (
    <div className={'row-span-1 grid gap-y-[5px]'}>
      <div className="grid grid-cols-[auto,_auto] justify-between items-center row-span-1">
        <span className="Subtitle2 text-smena_text-secondary">Подтвержденные смены</span>
        <SimpleSelect
          divClassName="chart-filter"
          label=""
          onChange={handleTimeChange}
          value={time}
          sort={false}
          options={chartTimes}
        />
      </div>
      <div className="flex gap-x-1 items-end">
        {loading ? (
          <Skeleton.Button active />
        ) : (
          <span className="ChartNumber text-smena_text">{lastTimeLength(time, res)}</span>
        )}
        <span className="Caption text-smena_text-secondary">за {getTimePeriodCharts(time)}</span>
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="h-[240px]">
          <CustomBarChart
            setFocusBar={setFocusBar}
            fillColorOpacity="#BD6CFE40"
            fillColor="#BD6CFE"
            tooltipStatus={tooltipStatus}
            setTooltipStatus={setTooltipStatus}
            formatter="Подтвержденных смен"
            focusBar={focusBar}
            res={res}
            time={time}
          />
        </div>
      )}
    </div>
  );
};
