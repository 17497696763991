import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { NotifyLetterFragmentDoc } from '../../fragments/generated/NotifyLetter.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateNotifyLetterMutationVariables = Types.Exact<{
  input: Types.UpdateNotifyLetterInput;
}>;


export type UpdateNotifyLetterMutation = { __typename?: 'Mutation', updateNotifyLetter: { __typename?: 'NotifyLetter', id: string, name: string, title: string, body?: string | null, status: Types.NotifyLetterStatus, route?: Types.PushRoute | null, updatedAt: any, publicCondition?: { __typename?: 'PublicCondition', recipientsCount: number, useCondition: boolean, pushToUnassigned: Types.PushToUnassignedFilter, id: string, indefinitely: boolean, publishFrom?: any | null, publishTo?: any | null, roles: Array<Types.Role>, interval?: number | null, cities?: Array<{ __typename?: 'City', id: string, name: string }> | null, facilities?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, facilityGroups?: Array<{ __typename?: 'FacilityGroup', id: string, name: string }> | null, positions?: Array<{ __typename?: 'Position', id: string, name: string }> | null } | null } };


export const UpdateNotifyLetterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateNotifyLetter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateNotifyLetterInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateNotifyLetter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotifyLetter"}}]}}]}},...NotifyLetterFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateNotifyLetterMutationFn = Apollo.MutationFunction<UpdateNotifyLetterMutation, UpdateNotifyLetterMutationVariables>;

/**
 * __useUpdateNotifyLetterMutation__
 *
 * To run a mutation, you first call `useUpdateNotifyLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotifyLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotifyLetterMutation, { data, loading, error }] = useUpdateNotifyLetterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotifyLetterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotifyLetterMutation, UpdateNotifyLetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotifyLetterMutation, UpdateNotifyLetterMutationVariables>(UpdateNotifyLetterDocument, options);
      }
export type UpdateNotifyLetterMutationHookResult = ReturnType<typeof useUpdateNotifyLetterMutation>;
export type UpdateNotifyLetterMutationResult = Apollo.MutationResult<UpdateNotifyLetterMutation>;
export type UpdateNotifyLetterMutationOptions = Apollo.BaseMutationOptions<UpdateNotifyLetterMutation, UpdateNotifyLetterMutationVariables>;