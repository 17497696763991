import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ReportFragmentDoc } from '../../../../timesheets/api/fragments/generated/Report.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLeadsReportMutationVariables = Types.Exact<{
  input: Types.LeadListInput;
}>;


export type CreateLeadsReportMutation = { __typename?: 'Mutation', createLeadsReport: { __typename?: 'Report', id: string, type: Types.ReportType, filePath: string } };


export const CreateLeadsReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateLeadsReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadListInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createLeadsReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Report"}}]}}]}},...ReportFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateLeadsReportMutationFn = Apollo.MutationFunction<CreateLeadsReportMutation, CreateLeadsReportMutationVariables>;

/**
 * __useCreateLeadsReportMutation__
 *
 * To run a mutation, you first call `useCreateLeadsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeadsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeadsReportMutation, { data, loading, error }] = useCreateLeadsReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLeadsReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateLeadsReportMutation, CreateLeadsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLeadsReportMutation, CreateLeadsReportMutationVariables>(CreateLeadsReportDocument, options);
      }
export type CreateLeadsReportMutationHookResult = ReturnType<typeof useCreateLeadsReportMutation>;
export type CreateLeadsReportMutationResult = Apollo.MutationResult<CreateLeadsReportMutation>;
export type CreateLeadsReportMutationOptions = Apollo.BaseMutationOptions<CreateLeadsReportMutation, CreateLeadsReportMutationVariables>;