import React from 'react';
import MainLayout from '../../components/layout/main';
import { useUserHeaderMenu } from '../../utils/hooks/headerMenu/useUserHeaderMenu';
import useUserBreadcrumbs from '../../utils/hooks/breadcrumbs/useUserBreadcrumbs';
import { Form, Formik } from 'formik';
import { useUpdateUserForm } from './utils/form';
import { MainInfo } from 'pages/userCreate/components/MainInfo';
import { useUserTitle } from '../user/lib/hooks';

const UpdateUserPage = () => {
  const headerMenu = useUserHeaderMenu();
  const breadCrumbs = useUserBreadcrumbs();
  const title = useUserTitle();
  const { initialValues, onSubmit, validate } = useUpdateUserForm();
  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, handleChange }) => {
          return (
            <Form className={'grid grid-cols-12 gap-6'}>
              <MainInfo
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
              />
              <div className={'userPageBlock flex justify-end'}>
                <button className={'btn-primary'} type={'submit'}>
                  Изменить
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateUserPage;
