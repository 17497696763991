import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReferralProgramsPaginatedQueryVariables = Types.Exact<{
  input: Types.GetReferralProgramsPaginatedInput;
}>;


export type GetReferralProgramsPaginatedQuery = { __typename?: 'Query', getReferralProgramsPaginated: { __typename?: 'GetReferralProgramsPaginatedModel', count: number, pages: number, referralPrograms: Array<{ __typename?: 'ReferralProgramEntity', id: string, status: Types.ReferralProgramStatusEnum, name?: string | null, description?: string | null, startDatetime?: any | null, endDatetime?: any | null, facility?: { __typename?: 'Facility', id: string, name: string } | null, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string } | null }> } };


export const GetReferralProgramsPaginatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetReferralProgramsPaginated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetReferralProgramsPaginatedInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getReferralProgramsPaginated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralPrograms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"startDatetime"}},{"kind":"Field","name":{"kind":"Name","value":"endDatetime"}},{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"facilityGroup"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetReferralProgramsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetReferralProgramsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralProgramsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralProgramsPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReferralProgramsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<GetReferralProgramsPaginatedQuery, GetReferralProgramsPaginatedQueryVariables> & ({ variables: GetReferralProgramsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferralProgramsPaginatedQuery, GetReferralProgramsPaginatedQueryVariables>(GetReferralProgramsPaginatedDocument, options);
      }
export function useGetReferralProgramsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferralProgramsPaginatedQuery, GetReferralProgramsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferralProgramsPaginatedQuery, GetReferralProgramsPaginatedQueryVariables>(GetReferralProgramsPaginatedDocument, options);
        }
export function useGetReferralProgramsPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReferralProgramsPaginatedQuery, GetReferralProgramsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReferralProgramsPaginatedQuery, GetReferralProgramsPaginatedQueryVariables>(GetReferralProgramsPaginatedDocument, options);
        }
export type GetReferralProgramsPaginatedQueryHookResult = ReturnType<typeof useGetReferralProgramsPaginatedQuery>;
export type GetReferralProgramsPaginatedLazyQueryHookResult = ReturnType<typeof useGetReferralProgramsPaginatedLazyQuery>;
export type GetReferralProgramsPaginatedSuspenseQueryHookResult = ReturnType<typeof useGetReferralProgramsPaginatedSuspenseQuery>;
export type GetReferralProgramsPaginatedQueryResult = Apollo.QueryResult<GetReferralProgramsPaginatedQuery, GetReferralProgramsPaginatedQueryVariables>;