import ClientUsersPage from 'pages/users/components/clientUsers';
import OurUsersPage from 'pages/users/components/ourUsers';
import { useMeRole } from 'store/hooks/useMeRole';
import { Role } from 'generated/graphql';
import React from 'react';

const UsersPage = () => {
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  if (isMeAdminOrSupervisor) {
    return <OurUsersPage />;
  }
  return <ClientUsersPage />;
};

export default UsersPage;
