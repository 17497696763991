import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { successToast } from 'utils/helpers/notify';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import CopyIcon from 'assets/icons/CopyIcon';
import { ContractFragment } from 'pages/userContacts/api/fragments/generated/contract.fragment';
import { userFullName } from 'utils/helpers/userFullName';
import dayjs from 'dayjs';
import { getContractStatusRu, getContractTypeRu } from '../../../pages/userContacts/utils/getContractTypeRu';
import { clsx } from 'clsx';
import DownloadContract from 'pages/userContacts/components/downloadContract';

export const useUserContractsTable = (data: ContractFragment[]) => {
  const columns: ColumnDef<ContractFragment>[] = useMemo(() => {
    return [
      {
        id: 'lastname',
        header: 'Исполнитель',
        size: 400,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/users/${original.user.id}`} className="link">
              {original.user.lastname ? userFullName(original.user) : 'Нет информации'}
            </Link>
          );
        },
      },
      {
        id: 'type',
        header: 'Тип документа',
        size: 250,
        cell: ({ row: { original } }) => getContractTypeRu(original.type),
      },
      {
        id: 'phone',
        header: 'Телефон',
        minSize: 190,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex items-center gap-x-2 group/number min-h-[24px]'}>
              <span>{convertPhoneNumberToNormal(original.user.phone)}</span>
              <button
                className={'group-hover/number:block hidden'}
                onClick={() => {
                  navigator.clipboard.writeText(original.user.phone).then(() => {
                    successToast('Номер скопирован', 1000);
                  });
                }}
              >
                <CopyIcon />
              </button>
            </div>
          );
        },
      },
      {
        id: 'status',
        header: 'Статус',
        cell: ({ row: { original } }) => {
          const { nameRu, className } = getContractStatusRu(original.status) || {};
          return <span className={clsx(className, 'px-2 py-0.5 rounded-full')}>{nameRu}</span>;
        },
      },
      {
        id: 'createdAt',
        header: 'Дата создания',
        size: 180,
        minSize: 180,
        cell: ({ row: { original } }) => dayjs(original.createdAt).format('DD.MM.YYYY HH:mm'),
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 135,
        minSize: 135,
        cell: ({ row: { original } }) => {
          return original.url ? <DownloadContract contractId={original.id} /> : null;
        },
      },
    ];
  }, []);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
    meta: {
      lastname: true,
      createdAt: true,
      status: true,
      type: true,
    },
  });
};
