import TechMaintenance from 'assets/icons/TechMaintenance';
import LogoIcon from 'assets/icons/LogoIcon';

const BlockScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-32 h-screen">
      <LogoIcon />
      <div className="flex gap-x-32 items-center">
        <TechMaintenance />
        <div className="flex flex-col gap-y-6">
          <h2 className="H2">Технические работы</h2>
          <span className="Body1 text-smena_text-secondary">
            В сервисе SMENA ведутся технические работы.
            <br /> Приносим свои извинения за доставленные неудобства.
            <br /> Мы скоро закончим!
          </span>
          <span className="Body1  text-smena_text-secondary">Обновите страницу, чтобы проверить работу</span>
        </div>
      </div>
    </div>
  );
};

export default BlockScreen;
