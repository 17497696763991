import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSupervisorsFacilitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSupervisorsFacilitiesQuery = { __typename?: 'Query', getSupervisorsFacilities: Array<{ __typename?: 'Facility', id: string }> };


export const GetSupervisorsFacilitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSupervisorsFacilities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSupervisorsFacilities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetSupervisorsFacilitiesQuery__
 *
 * To run a query within a React component, call `useGetSupervisorsFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorsFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorsFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupervisorsFacilitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetSupervisorsFacilitiesQuery, GetSupervisorsFacilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupervisorsFacilitiesQuery, GetSupervisorsFacilitiesQueryVariables>(GetSupervisorsFacilitiesDocument, options);
      }
export function useGetSupervisorsFacilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupervisorsFacilitiesQuery, GetSupervisorsFacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupervisorsFacilitiesQuery, GetSupervisorsFacilitiesQueryVariables>(GetSupervisorsFacilitiesDocument, options);
        }
export function useGetSupervisorsFacilitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSupervisorsFacilitiesQuery, GetSupervisorsFacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSupervisorsFacilitiesQuery, GetSupervisorsFacilitiesQueryVariables>(GetSupervisorsFacilitiesDocument, options);
        }
export type GetSupervisorsFacilitiesQueryHookResult = ReturnType<typeof useGetSupervisorsFacilitiesQuery>;
export type GetSupervisorsFacilitiesLazyQueryHookResult = ReturnType<typeof useGetSupervisorsFacilitiesLazyQuery>;
export type GetSupervisorsFacilitiesSuspenseQueryHookResult = ReturnType<typeof useGetSupervisorsFacilitiesSuspenseQuery>;
export type GetSupervisorsFacilitiesQueryResult = Apollo.QueryResult<GetSupervisorsFacilitiesQuery, GetSupervisorsFacilitiesQueryVariables>;