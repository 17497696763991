import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCasingGalleryByFacilityAndPositionQueryVariables = Types.Exact<{
  facilityId: Types.Scalars['String']['input'];
  positionId: Types.Scalars['String']['input'];
}>;


export type GetCasingGalleryByFacilityAndPositionQuery = { __typename?: 'Query', getCasingGalleryByFacilityAndPosition: Array<{ __typename?: 'CasingGalleryEntity', id: string, file?: { __typename?: 'File', id: string, path: string } | null }> };


export const GetCasingGalleryByFacilityAndPositionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCasingGalleryByFacilityAndPosition"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"positionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCasingGalleryByFacilityAndPosition"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}},{"kind":"Argument","name":{"kind":"Name","value":"positionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"positionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetCasingGalleryByFacilityAndPositionQuery__
 *
 * To run a query within a React component, call `useGetCasingGalleryByFacilityAndPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasingGalleryByFacilityAndPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasingGalleryByFacilityAndPositionQuery({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useGetCasingGalleryByFacilityAndPositionQuery(baseOptions: Apollo.QueryHookOptions<GetCasingGalleryByFacilityAndPositionQuery, GetCasingGalleryByFacilityAndPositionQueryVariables> & ({ variables: GetCasingGalleryByFacilityAndPositionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCasingGalleryByFacilityAndPositionQuery, GetCasingGalleryByFacilityAndPositionQueryVariables>(GetCasingGalleryByFacilityAndPositionDocument, options);
      }
export function useGetCasingGalleryByFacilityAndPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCasingGalleryByFacilityAndPositionQuery, GetCasingGalleryByFacilityAndPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCasingGalleryByFacilityAndPositionQuery, GetCasingGalleryByFacilityAndPositionQueryVariables>(GetCasingGalleryByFacilityAndPositionDocument, options);
        }
export function useGetCasingGalleryByFacilityAndPositionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCasingGalleryByFacilityAndPositionQuery, GetCasingGalleryByFacilityAndPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCasingGalleryByFacilityAndPositionQuery, GetCasingGalleryByFacilityAndPositionQueryVariables>(GetCasingGalleryByFacilityAndPositionDocument, options);
        }
export type GetCasingGalleryByFacilityAndPositionQueryHookResult = ReturnType<typeof useGetCasingGalleryByFacilityAndPositionQuery>;
export type GetCasingGalleryByFacilityAndPositionLazyQueryHookResult = ReturnType<typeof useGetCasingGalleryByFacilityAndPositionLazyQuery>;
export type GetCasingGalleryByFacilityAndPositionSuspenseQueryHookResult = ReturnType<typeof useGetCasingGalleryByFacilityAndPositionSuspenseQuery>;
export type GetCasingGalleryByFacilityAndPositionQueryResult = Apollo.QueryResult<GetCasingGalleryByFacilityAndPositionQuery, GetCasingGalleryByFacilityAndPositionQueryVariables>;