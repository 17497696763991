import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { UserReviewFragmentDoc } from '../../fragments/generated/UserReview.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserReviewMutationVariables = Types.Exact<{
  input: Types.CreateUserReviewInput;
}>;


export type CreateUserReviewMutation = { __typename?: 'Mutation', createUserReview: { __typename?: 'UserReview', id: string, createdAt: any, positionId: string, isGood?: boolean | null, text: string, author: { __typename?: 'User', firstname: string, lastname: string, role: Types.Role }, facility?: { __typename?: 'Facility', id: string, name: string, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string } | null } | null } };


export const CreateUserReviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserReview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateUserReviewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUserReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserReview"}}]}}]}},...UserReviewFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateUserReviewMutationFn = Apollo.MutationFunction<CreateUserReviewMutation, CreateUserReviewMutationVariables>;

/**
 * __useCreateUserReviewMutation__
 *
 * To run a mutation, you first call `useCreateUserReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserReviewMutation, { data, loading, error }] = useCreateUserReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserReviewMutation, CreateUserReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserReviewMutation, CreateUserReviewMutationVariables>(CreateUserReviewDocument, options);
      }
export type CreateUserReviewMutationHookResult = ReturnType<typeof useCreateUserReviewMutation>;
export type CreateUserReviewMutationResult = Apollo.MutationResult<CreateUserReviewMutation>;
export type CreateUserReviewMutationOptions = Apollo.BaseMutationOptions<CreateUserReviewMutation, CreateUserReviewMutationVariables>;