import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { LikeIcon } from 'assets/icons/LikeIcon';
import SimpleSelect from 'ui/select';
import MainSidebar from 'ui/sidebar';
import TextArea from 'ui/textArea';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';
// import { userFullName } from 'utils/userFullName';
import RateComponent from 'ui/rate';
import { useCreateUserGradeMutation } from 'pages/userQuestionnaire/api/mutations/generated/CreateUserGrade';
import { useCreateUserReviewMutation } from 'pages/userQuestionnaire/api/mutations/generated/CreateUserReview';
import { useCurrentUser } from 'utils/api/useUser';
import { useUserFacilitiesWorkpostsOptions, useUserPositionsWorkpostsOptions } from './hooks';

interface Props {
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
  btnRef: React.RefObject<HTMLButtonElement>;
}

const CreateReviewSidebar = ({ showSidebar, setShowSidebar, btnRef }: Props) => {
  const { user: currentUser } = useCurrentUser();

  const [facilityId, setFacilityId] = useState<string | undefined>();
  const [facilityIdError, setFacilityIdError] = useState('');
  const [positionId, setPositionId] = useState<string | undefined>();
  const [positionIdError, setPositionIdError] = useState('');
  const [speed, setSpeed] = useState(0);
  const [responsiveness, setResponsiveness] = useState(0);
  const [character, setCharacter] = useState(0);
  const [text, setText] = useState('');
  const [textError, setTextError] = useState('');
  const [isGood, setIsGood] = useState<null | boolean>(null);

  const [createUserGrade, { loading: createUserGradeLoading }] = useCreateUserGradeMutation();
  const [createUserReview, { loading: createUserReviewLoading }] = useCreateUserReviewMutation();

  const facilityAndPositionValidation = useCallback(() => {
    if (!facilityId) {
      setFacilityIdError('Обязательное поле');
    } else {
      setFacilityIdError('');
    }
    if (!positionId) {
      setPositionIdError('Обязательное поле');
    } else {
      setPositionIdError('');
    }
  }, [facilityId, positionId]);

  const facilityReviewValidation = useCallback(() => {
    if (!speed || !responsiveness || !character) {
      errorToast('Заполните оценки');
    }
  }, [character, responsiveness, speed]);

  const canCreateUserGrade = currentUser && facilityId && positionId && speed && responsiveness && character;

  const createUserGradeHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      facilityAndPositionValidation();
      facilityReviewValidation();
      if (canCreateUserGrade) {
        createUserGrade({
          variables: {
            input: {
              facilityId,
              positionId,
              character,
              responsiveness,
              speed,
              userId: currentUser.id,
            },
          },
        })
          .then(() => successToast('Оценка отправлена'))
          .catch(error => errorToast(errorHandler(error)));
      }
    },
    [
      canCreateUserGrade,
      character,
      createUserGrade,
      facilityAndPositionValidation,
      facilityId,
      facilityReviewValidation,
      positionId,
      responsiveness,
      speed,
      currentUser?.id,
    ],
  );
  const createUserReviewHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      facilityAndPositionValidation();
      if (!text) {
        setTextError('Обязательное поле');
      } else {
        setTextError('');
      }
      if (isGood === null) {
        return errorToast('Выберите общую оценку');
      }
      if (currentUser?.id && facilityId && positionId && text) {
        createUserReview({
          variables: {
            input: {
              facilityId,
              positionId,
              userId: currentUser.id,
              text,
              isGood,
            },
          },
        })
          .then(() => {
            successToast('Отзыв отправлен');
            setIsGood(null);
          })
          .catch(error => errorToast(errorHandler(error)));
      }
    },
    [createUserReview, facilityAndPositionValidation, facilityId, isGood, positionId, text, currentUser?.id],
  );

  const userFacilitiesWorkpostsOptions = useUserFacilitiesWorkpostsOptions();

  const userPositionsWorkpostsOptions = useUserPositionsWorkpostsOptions(facilityId);

  return (
    <MainSidebar title="Новый отзыв" show={showSidebar} setShow={setShowSidebar} triggerRef={btnRef}>
      <div className="flex flex-col gap-y-5">
        {/*<h4 className="H4">{userFullName(currentUser)}</h4>*/}
        <div className="flex justify-between gap-x-5">
          <SimpleSelect
            divClassName="w-[200px]"
            label="Объект"
            onChange={value => {
              setFacilityId(value);
              setPositionId(undefined);
            }}
            value={facilityId}
            options={userFacilitiesWorkpostsOptions}
            error={facilityIdError}
            placeholder="Выберите объект"
          />
          <SimpleSelect
            divClassName="w-[200px]"
            label="Профессия"
            onChange={value => setPositionId(value)}
            value={positionId}
            options={userPositionsWorkpostsOptions}
            error={positionIdError}
            placeholder="Выберите профессию"
          />
        </div>
        <div className="flex flex-col gap-y-4">
          <form onSubmit={createUserGradeHandler} className="flex flex-col gap-y-7">
            <div className="flex flex-col p-4 gap-y-2 shadow-smena rounded-lg">
              <span className="Subtitle2">Оценка</span>
              <div className="gap-y-1">
                <div className="flex justify-between ">
                  <span className="Body2 text-smena_text-secondary">Скорость</span>
                  <RateComponent value={speed} onChange={value => setSpeed(value)} />
                </div>
                <div className="flex justify-between">
                  <span className="Body2 text-smena_text-secondary">Ответственность</span>
                  <RateComponent value={responsiveness} onChange={value => setResponsiveness(value)} />
                </div>
                <div className="flex justify-between">
                  <span className="Body2 text-smena_text-secondary">Характер</span>
                  <RateComponent value={character} onChange={value => setCharacter(value)} />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button type="submit" disabled={createUserGradeLoading} className="btn-primary">
                Отправить
              </button>
            </div>
          </form>
          <form onSubmit={createUserReviewHandler} className="flex flex-col gap-y-7">
            <div className="flex flex-col p-4 gap-y-2 shadow-smena rounded-lg">
              <TextArea
                divClassName=""
                label="Отзыв"
                name="comment"
                placeholder="Напишите что-нибудь."
                value={text}
                onChange={({ target: { value } }) => {
                  setText(value);
                }}
                error={textError}
              />
              <div className="flex flex-col gap-y-2">
                <span className="Subtitle2 text-smena_text-secondary">Общая оценка</span>
                <div className="flex gap-x-2">
                  <button
                    type="button"
                    className={clsx(
                      'flex items-center gap-x-2 px-2 py-1 rounded-lg text-smena_green-dark bg-smena_green-extra_light Body2',
                      isGood && ' border border-smena_green-dark',
                    )}
                    onClick={() => {
                      if (isGood) {
                        setIsGood(null);
                      } else {
                        setIsGood(true);
                      }
                    }}
                  >
                    <span>
                      <LikeIcon />
                    </span>
                    <span>Рекомендую</span>
                  </button>
                  <button
                    type="button"
                    className={clsx(
                      'flex items-center gap-x-2 px-2 py-1 rounded-lg text-smena_red bg-smena_red-extra_light Body2',
                      isGood === false && 'border border-smena_red-dark',
                    )}
                    onClick={() => {
                      if (isGood === false) {
                        setIsGood(null);
                      } else {
                        setIsGood(false);
                      }
                    }}
                  >
                    <span className="transform rotate-180">
                      <LikeIcon />
                    </span>
                    <span>Не рекомендую</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button type="submit" disabled={createUserReviewLoading} className="btn-primary">
                Отправить
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainSidebar>
  );
};

export default CreateReviewSidebar;
