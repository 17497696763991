import dayjs from 'dayjs';
import { StatisticPeriod } from 'generated/graphql';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';

export const labelFormatterHandler = ({ label, time }: { label: string; time: StatisticPeriod }) => {
  switch (time) {
    case StatisticPeriod.Day:
      return dayjs(label).format(TIME_DATE);
    case StatisticPeriod.Week:
      return dayjs(label).format('DD MMMM');
    case StatisticPeriod.Month:
      return dayjs(label).format('DD MMMM');
    case StatisticPeriod.HalfYear:
      return dayjs(label).format('MMMM YYYY');
    case StatisticPeriod.Year:
      return dayjs(label).format('MMMM YYYY');
    default:
      return dayjs(label).format(RU_DATE);
  }
};
