const ArmeniaIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9532_6900)">
        <path
          d="M17.7778 2.77783H2.22222C1.63285 2.77783 1.06762 3.01196 0.650874 3.42871C0.234126 3.84545 0 4.41068 0 5.00005L0 7.22228H20V5.00005C20 4.41068 19.7659 3.84545 19.3491 3.42871C18.9324 3.01196 18.3671 2.77783 17.7778 2.77783Z"
          fill="#D90012"
        />
        <path
          d="M2.22222 17.2223H17.7778C18.3671 17.2223 18.9324 16.9881 19.3491 16.5714C19.7659 16.1547 20 15.5894 20 15.0001V12.7778H0V15.0001C0 15.5894 0.234126 16.1547 0.650874 16.5714C1.06762 16.9881 1.63285 17.2223 2.22222 17.2223Z"
          fill="#F2A800"
        />
        <path d="M0 7.22217H20V12.7777H0V7.22217Z" fill="#0033A0" />
      </g>
      <defs>
        <clipPath id="clip0_9532_6900">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArmeniaIcon;
