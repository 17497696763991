import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { LeadFragmentDoc } from '../../fragments/generated/Lead.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LeadQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type LeadQuery = { __typename?: 'Query', lead: { __typename?: 'Lead', id: string, createdAt: any, isProcessed: boolean, status: Types.LeadStatus, updatedAt: any, userId: string, date?: any | null, inviteFriendDate?: any | null, referrerPaymentDatetime?: any | null, referralProgram?: { __typename?: 'ReferralProgramEntity', id: string, rewardForTheReferer?: number | null, numberOfGoodShifts?: number | null, name?: string | null, facility?: { __typename?: 'Facility', id: string, name: string } | null, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string } | null } | null, user?: { __typename?: 'User', id: string, phone: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role, goodShiftCount?: number | null, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', userId: string, status: Types.WorkPostStatus, positionId: string, facilityId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null, referrer?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role, phone: string, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', userId: string, status: Types.WorkPostStatus, positionId: string, facilityId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null } | null } | null } };


export const LeadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Lead"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Lead"}}]}}]}},...LeadFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useLeadQuery__
 *
 * To run a query within a React component, call `useLeadQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeadQuery(baseOptions: Apollo.QueryHookOptions<LeadQuery, LeadQueryVariables> & ({ variables: LeadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadQuery, LeadQueryVariables>(LeadDocument, options);
      }
export function useLeadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadQuery, LeadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadQuery, LeadQueryVariables>(LeadDocument, options);
        }
export function useLeadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LeadQuery, LeadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LeadQuery, LeadQueryVariables>(LeadDocument, options);
        }
export type LeadQueryHookResult = ReturnType<typeof useLeadQuery>;
export type LeadLazyQueryHookResult = ReturnType<typeof useLeadLazyQuery>;
export type LeadSuspenseQueryHookResult = ReturnType<typeof useLeadSuspenseQuery>;
export type LeadQueryResult = Apollo.QueryResult<LeadQuery, LeadQueryVariables>;