import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PromotionEntityFragmentDoc } from '../../fragments/generated/PromotionEntity.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditPromotionInFacilityGroupMutationVariables = Types.Exact<{
  input: Types.EditPromotionInFacilityGroupInput;
}>;


export type EditPromotionInFacilityGroupMutation = { __typename?: 'Mutation', editPromotionInFacilityGroup: { __typename?: 'PromotionEntity', id: string, badShiftCount: number, dateTimeEnd?: any | null, dateTimeStart: any, name: string, repeatCondition: Types.PromotionRepeatConditionEnum, requirementBonus: Types.PromotionRequirementBonusEnum, requirementShiftCount: number, rewardSum: number, rewardType: Types.PromotionRewardTypeEnum, status: Types.PromotionStatusEnum, childPromotions?: Array<{ __typename?: 'PromotionEntity', facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null } };


export const EditPromotionInFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditPromotionInFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditPromotionInFacilityGroupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editPromotionInFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PromotionEntity"}}]}}]}},...PromotionEntityFragmentDoc.definitions]} as unknown as DocumentNode;
export type EditPromotionInFacilityGroupMutationFn = Apollo.MutationFunction<EditPromotionInFacilityGroupMutation, EditPromotionInFacilityGroupMutationVariables>;

/**
 * __useEditPromotionInFacilityGroupMutation__
 *
 * To run a mutation, you first call `useEditPromotionInFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPromotionInFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPromotionInFacilityGroupMutation, { data, loading, error }] = useEditPromotionInFacilityGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPromotionInFacilityGroupMutation(baseOptions?: Apollo.MutationHookOptions<EditPromotionInFacilityGroupMutation, EditPromotionInFacilityGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPromotionInFacilityGroupMutation, EditPromotionInFacilityGroupMutationVariables>(EditPromotionInFacilityGroupDocument, options);
      }
export type EditPromotionInFacilityGroupMutationHookResult = ReturnType<typeof useEditPromotionInFacilityGroupMutation>;
export type EditPromotionInFacilityGroupMutationResult = Apollo.MutationResult<EditPromotionInFacilityGroupMutation>;
export type EditPromotionInFacilityGroupMutationOptions = Apollo.BaseMutationOptions<EditPromotionInFacilityGroupMutation, EditPromotionInFacilityGroupMutationVariables>;