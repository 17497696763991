import { create } from 'zustand';
import { Bonus } from '../components/promotionBlock/form';
import { PromotionStatusEnum } from '../../../generated/graphql';

type PromotionType = {
  promotions: Bonus[];
  setPromotions: (value: Bonus[]) => void;
  addPromotions: (value: Bonus[]) => void;
  changePromotions: (id: string, bonus: Bonus) => void;
  removePromotions: (id: string) => void;
};

export const usePromotionStore = create<PromotionType>(set => ({
  promotions: [],
  setPromotions: promotions => set({ promotions }),
  addPromotions: promotions =>
    set(state => {
      return {
        promotions: [...state.promotions, ...promotions],
      };
    }),
  changePromotions: (id, bonus) => {
    set(state => {
      const promotions = [...state.promotions].filter(el => el.id !== id);
      return {
        promotions: [...promotions, bonus],
      };
    });
  },
  removePromotions: id => {
    set(state => {
      const promotions = [...state.promotions].map(el => {
        if (el.id === id) {
          return { ...el, status: PromotionStatusEnum.Deleted };
        }
        return el;
      });
      return {
        promotions,
      };
    });
  },
}));
