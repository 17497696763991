import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { SearchCityFragmentDoc } from '../../fragment/generated/SearchCity.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCitiesWithFacilitiesQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  facilityGroupId: Types.Scalars['String']['input'];
}>;


export type SearchCitiesWithFacilitiesQuery = { __typename?: 'Query', searchCitiesWithFacilities: Array<{ __typename?: 'City', id: string, name: string, facilities: Array<{ __typename?: 'Facility', id: string, name: string }> }> };


export const SearchCitiesWithFacilitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchCitiesWithFacilities"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchCitiesWithFacilities"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}},{"kind":"Argument","name":{"kind":"Name","value":"facilityGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityGroupId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SearchCity"}}]}}]}},...SearchCityFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useSearchCitiesWithFacilitiesQuery__
 *
 * To run a query within a React component, call `useSearchCitiesWithFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCitiesWithFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCitiesWithFacilitiesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      facilityGroupId: // value for 'facilityGroupId'
 *   },
 * });
 */
export function useSearchCitiesWithFacilitiesQuery(baseOptions: Apollo.QueryHookOptions<SearchCitiesWithFacilitiesQuery, SearchCitiesWithFacilitiesQueryVariables> & ({ variables: SearchCitiesWithFacilitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCitiesWithFacilitiesQuery, SearchCitiesWithFacilitiesQueryVariables>(SearchCitiesWithFacilitiesDocument, options);
      }
export function useSearchCitiesWithFacilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCitiesWithFacilitiesQuery, SearchCitiesWithFacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCitiesWithFacilitiesQuery, SearchCitiesWithFacilitiesQueryVariables>(SearchCitiesWithFacilitiesDocument, options);
        }
export function useSearchCitiesWithFacilitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCitiesWithFacilitiesQuery, SearchCitiesWithFacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCitiesWithFacilitiesQuery, SearchCitiesWithFacilitiesQueryVariables>(SearchCitiesWithFacilitiesDocument, options);
        }
export type SearchCitiesWithFacilitiesQueryHookResult = ReturnType<typeof useSearchCitiesWithFacilitiesQuery>;
export type SearchCitiesWithFacilitiesLazyQueryHookResult = ReturnType<typeof useSearchCitiesWithFacilitiesLazyQuery>;
export type SearchCitiesWithFacilitiesSuspenseQueryHookResult = ReturnType<typeof useSearchCitiesWithFacilitiesSuspenseQuery>;
export type SearchCitiesWithFacilitiesQueryResult = Apollo.QueryResult<SearchCitiesWithFacilitiesQuery, SearchCitiesWithFacilitiesQueryVariables>;