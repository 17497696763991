import React, { useState } from 'react';
import { flexRender, Table } from '@tanstack/react-table';
import clsx from 'clsx';
import NewSkeletonLayout from '../newSkeleton';
import { useSearchParams } from 'react-router-dom';
import DraggableColumnHeader from '../header/draggableColumnHeader';

interface Props<T> {
  table: Table<T>;
  length: number;
  loading: boolean;
  headerMenu: boolean;
}

const NewTable = <T,>({ table, loading, length, headerMenu }: Props<T>) => {
  const [params] = useSearchParams();
  const searchParam = params.get('search');
  const [column, setColumn] = useState('');
  return (
    <div className="overflow-auto">
      {loading ? (
        <NewSkeletonLayout<T> table={table} headerMenu={headerMenu} />
      ) : length ? (
        <table
          {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <DraggableColumnHeader
                    key={header.id}
                    header={header}
                    table={table}
                    hoverColumn={column}
                    setHoverColumn={setColumn}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className="table__row">
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className={clsx('table__content', column === cell.column.id ? 'active-column' : '')}
                      onMouseEnter={() => {
                        setColumn(cell.column.id);
                      }}
                      onMouseLeave={() => {
                        setColumn('');
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex justify-center my-5">
          <h3 className="text-smena_text Body1">
            По вашему запросу <span className="font-bold">{searchParam}</span> ничего не нашлось
          </h3>
        </div>
      )}
    </div>
  );
};

export default NewTable;
