import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveCasingMutationVariables = Types.Exact<{
  facilityId: Types.Scalars['String']['input'];
  positionId: Types.Scalars['String']['input'];
}>;


export type RemoveCasingMutation = { __typename?: 'Mutation', removeCasing: boolean };


export const RemoveCasingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveCasing"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"positionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeCasing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}},{"kind":"Argument","name":{"kind":"Name","value":"positionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"positionId"}}}]}]}}]} as unknown as DocumentNode;
export type RemoveCasingMutationFn = Apollo.MutationFunction<RemoveCasingMutation, RemoveCasingMutationVariables>;

/**
 * __useRemoveCasingMutation__
 *
 * To run a mutation, you first call `useRemoveCasingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCasingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCasingMutation, { data, loading, error }] = useRemoveCasingMutation({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useRemoveCasingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCasingMutation, RemoveCasingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCasingMutation, RemoveCasingMutationVariables>(RemoveCasingDocument, options);
      }
export type RemoveCasingMutationHookResult = ReturnType<typeof useRemoveCasingMutation>;
export type RemoveCasingMutationResult = Apollo.MutationResult<RemoveCasingMutation>;
export type RemoveCasingMutationOptions = Apollo.BaseMutationOptions<RemoveCasingMutation, RemoveCasingMutationVariables>;