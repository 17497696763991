import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityGroupBalanceOperationFragmentDoc } from '../../../../balance/api/fragments/generated/FacilityGroupBalanceOperation.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBalanceOperationMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  input: Types.BalanceOperationCardInput;
}>;


export type UpdateBalanceOperationMutation = { __typename?: 'Mutation', updateBalanceOperation: { __typename?: 'FacilityGroupBalanceOperation', id: string, amount: number, balanceAfter: number, balanceBefore: number, createdAt: any, dayAndTime: any, description: string, facilityGroupId: string, operation?: Types.BalanceOperationType | null } };


export const UpdateBalanceOperationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateBalanceOperation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BalanceOperationCardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateBalanceOperation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityGroupBalanceOperation"}}]}}]}},...FacilityGroupBalanceOperationFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateBalanceOperationMutationFn = Apollo.MutationFunction<UpdateBalanceOperationMutation, UpdateBalanceOperationMutationVariables>;

/**
 * __useUpdateBalanceOperationMutation__
 *
 * To run a mutation, you first call `useUpdateBalanceOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBalanceOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBalanceOperationMutation, { data, loading, error }] = useUpdateBalanceOperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBalanceOperationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBalanceOperationMutation, UpdateBalanceOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBalanceOperationMutation, UpdateBalanceOperationMutationVariables>(UpdateBalanceOperationDocument, options);
      }
export type UpdateBalanceOperationMutationHookResult = ReturnType<typeof useUpdateBalanceOperationMutation>;
export type UpdateBalanceOperationMutationResult = Apollo.MutationResult<UpdateBalanceOperationMutation>;
export type UpdateBalanceOperationMutationOptions = Apollo.BaseMutationOptions<UpdateBalanceOperationMutation, UpdateBalanceOperationMutationVariables>;