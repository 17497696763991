import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCasingsInBatchMutationVariables = Types.Exact<{
  input: Types.BatchCasingCardInput;
}>;


export type CreateCasingsInBatchMutation = { __typename?: 'Mutation', createCasingsInBatch: Array<{ __typename?: 'Casing', id: string, facilityId: string, positionId: string }> };


export const CreateCasingsInBatchDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCasingsInBatch"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BatchCasingCardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCasingsInBatch"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"facilityId"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}}]}}]}}]} as unknown as DocumentNode;
export type CreateCasingsInBatchMutationFn = Apollo.MutationFunction<CreateCasingsInBatchMutation, CreateCasingsInBatchMutationVariables>;

/**
 * __useCreateCasingsInBatchMutation__
 *
 * To run a mutation, you first call `useCreateCasingsInBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCasingsInBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCasingsInBatchMutation, { data, loading, error }] = useCreateCasingsInBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCasingsInBatchMutation(baseOptions?: Apollo.MutationHookOptions<CreateCasingsInBatchMutation, CreateCasingsInBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCasingsInBatchMutation, CreateCasingsInBatchMutationVariables>(CreateCasingsInBatchDocument, options);
      }
export type CreateCasingsInBatchMutationHookResult = ReturnType<typeof useCreateCasingsInBatchMutation>;
export type CreateCasingsInBatchMutationResult = Apollo.MutationResult<CreateCasingsInBatchMutation>;
export type CreateCasingsInBatchMutationOptions = Apollo.BaseMutationOptions<CreateCasingsInBatchMutation, CreateCasingsInBatchMutationVariables>;