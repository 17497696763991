import React from 'react';
import { Switch } from 'ui/switch';
import SimpleInput from 'ui/input';
import { useFacility } from 'utils/api/useFacility';
import { Form, Formik } from 'formik';
import { useFacilityMedicalInvoice } from './hooks';

const FacilityMedicalInvoice = () => {
  const { id, facility } = useFacility();
  const { promotionSwitchOffHandler, validate, onSubmit, initialValues } = useFacilityMedicalInvoice();

  return (
    <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, setFieldValue }) => {
        return (
          <Form className="facilityPageBlock blockTemplate">
            <div className="grid grid-cols-8 gap-5 gap">
              <div className="flex gap-x-5 items-center col-span-full">
                <span className="Subtitle1 text-smena_text-secondary">Ожидание мед. книжки</span>
                <Switch
                  name="isMedInvoiceAllowed"
                  checked={values.isMedInvoiceAllowed}
                  onChange={({ target: { checked } }) => {
                    setFieldValue('isMedInvoiceAllowed', checked);
                    if (!checked && facility?.isMedInvoiceAllowed) {
                      promotionSwitchOffHandler();
                    }
                  }}
                  disabled={!id}
                />
              </div>
              {values.isMedInvoiceAllowed && (
                <>
                  <div className="col-span-full block Body2">
                    <span className={'inline-flex'}>
                      Исполнители смогут работать по профессиям, где требуется медицинская книжка, предоставить <br />{' '}
                      справку о прохождение медицинского осмотра.
                    </span>
                  </div>
                  <div className="col-span-full block Body2 text-smena_text-secondary">
                    <span className={'inline-flex'}>Укажите количество дней действия медицинской справки.</span>
                  </div>
                  <SimpleInput
                    divClassName="md:col-span-2 sm:col-span-4 col-span-full"
                    label={'Количество дней'}
                    placeholder={'5'}
                    onChange={handleChange}
                    value={values.numberDaysWaitMedical}
                    name={'numberDaysWaitMedical'}
                    type={'text'}
                    regText
                    error={
                      touched.numberDaysWaitMedical && errors.numberDaysWaitMedical ? errors.numberDaysWaitMedical : ''
                    }
                  />
                  <div className="col-span-2 pt-6">
                    <button className="btn-primary_small" type="submit">
                      Сохранить
                    </button>
                  </div>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FacilityMedicalInvoice;
