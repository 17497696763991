const MoldovaIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9559_1882)">
        <path
          d="M17.7775 2.77783H13.333V17.2223H17.7775C18.3668 17.2223 18.9321 16.9882 19.3488 16.5714C19.7655 16.1547 19.9997 15.5894 19.9997 15.0001V5.00005C19.9997 4.41068 19.7655 3.84545 19.3488 3.42871C18.9321 3.01196 18.3668 2.77783 17.7775 2.77783Z"
          fill="#DD2E44"
        />
        <path
          d="M2.22222 2.77783C1.63285 2.77783 1.06762 3.01196 0.650874 3.42871C0.234126 3.84545 0 4.41068 0 5.00005L0 15.0001C0 15.5894 0.234126 16.1547 0.650874 16.5714C1.06762 16.9882 1.63285 17.2223 2.22222 17.2223H6.66667V2.77783H2.22222Z"
          fill="#265FB5"
        />
        <path d="M6.66699 2.77783H13.3337V17.2223H6.66699V2.77783Z" fill="#FFCC4D" />
        <path d="M8.88867 8.33325H11.1109V9.99992H8.88867V8.33325Z" fill="#DD2E44" />
        <path d="M8.88867 10V11.1111L9.99978 11.6667L11.1109 11.1111V10H8.88867Z" fill="#226699" />
        <path
          d="M8.88932 6.11108V6.66664H9.44488V7.77775H10.556V6.11108H8.88932ZM10.556 11.6666L10.0004 12.0139L9.44488 11.6666L8.88932 13.3333L10.0004 13.8889L11.1115 13.3333L10.556 11.6666ZM8.33377 7.2222L7.77821 6.66664L7.22266 7.2222V12.7778L8.33377 11.6666V7.77775H8.88932L8.33377 7.2222ZM12.2227 6.66664L11.6671 7.2222L11.1115 7.77775H11.6671V11.6666L12.7782 12.7778V7.2222L12.2227 6.66664Z"
          fill="#A0724A"
        />
        <path
          d="M9.01855 9.77441H9.31355V9.99997H9.01855V9.77441ZM10.6852 9.77441H10.9802V9.99997H10.6852V9.77441Z"
          fill="#FFCC4D"
        />
        <path
          d="M8.68681 12.635C8.62792 12.6694 8.5407 12.6294 8.49237 12.5466L7.25237 10.3989C7.20459 10.3155 7.21348 10.2205 7.27292 10.1861L7.34848 10.1427C7.40737 10.1083 7.49459 10.1483 7.54237 10.2311L8.78237 12.3794C8.8307 12.4622 8.82126 12.5572 8.76237 12.5916L8.68681 12.635Z"
          fill="#77B255"
        />
        <path
          d="M12.6765 10.2182C12.736 10.2521 12.746 10.346 12.6982 10.4277L11.481 12.5377C11.4332 12.6194 11.3476 12.6577 11.2876 12.6238L11.2126 12.5805C11.1538 12.546 11.1438 12.4527 11.191 12.3705L12.4088 10.2605C12.456 10.1794 12.5421 10.1399 12.6015 10.1749L12.6765 10.2182Z"
          fill="#FFCC4D"
        />
        <path
          d="M8.88856 11.6667L8.33301 12.2223V12.7779L8.88856 12.2223V11.6667ZM11.1108 11.6667V12.2223L11.6663 12.7779V12.2223L11.1108 11.6667Z"
          fill="#DD2E44"
        />
        <path
          d="M9.99989 9.44447L9.44434 8.88892V10.5322L9.99989 11.1111L10.5554 10.5322V8.88892L9.99989 9.44447Z"
          fill="#FFCC4D"
        />
      </g>
      <defs>
        <clipPath id="clip0_9559_1882">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoldovaIcon;
