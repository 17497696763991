import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SimpleFacilityGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type SimpleFacilityGroupQuery = { __typename?: 'Query', facilityGroup: { __typename?: 'FacilityGroup', id: string, name: string } };


export const SimpleFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SimpleFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useSimpleFacilityGroupQuery__
 *
 * To run a query within a React component, call `useSimpleFacilityGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleFacilityGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleFacilityGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimpleFacilityGroupQuery(baseOptions: Apollo.QueryHookOptions<SimpleFacilityGroupQuery, SimpleFacilityGroupQueryVariables> & ({ variables: SimpleFacilityGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimpleFacilityGroupQuery, SimpleFacilityGroupQueryVariables>(SimpleFacilityGroupDocument, options);
      }
export function useSimpleFacilityGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimpleFacilityGroupQuery, SimpleFacilityGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimpleFacilityGroupQuery, SimpleFacilityGroupQueryVariables>(SimpleFacilityGroupDocument, options);
        }
export function useSimpleFacilityGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SimpleFacilityGroupQuery, SimpleFacilityGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SimpleFacilityGroupQuery, SimpleFacilityGroupQueryVariables>(SimpleFacilityGroupDocument, options);
        }
export type SimpleFacilityGroupQueryHookResult = ReturnType<typeof useSimpleFacilityGroupQuery>;
export type SimpleFacilityGroupLazyQueryHookResult = ReturnType<typeof useSimpleFacilityGroupLazyQuery>;
export type SimpleFacilityGroupSuspenseQueryHookResult = ReturnType<typeof useSimpleFacilityGroupSuspenseQuery>;
export type SimpleFacilityGroupQueryResult = Apollo.QueryResult<SimpleFacilityGroupQuery, SimpleFacilityGroupQueryVariables>;