import React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import clsx from 'clsx';
import dayjs from 'dayjs';
import RequestProfessionDates from '../requestProfessionDates';
import { useFilterRequestWithSchedule } from 'pages/facilityRequestsSchedule/hooks';
import RequestProfession from '../requestProfession';

interface Props {
  days: dayjs.Dayjs[];
}

const ScheduleTable: React.FC<Props> = React.memo(({ days }) => {
  const filterRequestWithSchedule = useFilterRequestWithSchedule();
  return (
    <div className="requestGraphic">
      <div className="border-r border-smena_gray-30 ">
        <div className="border-b requestScheduleProfessionsRows sticky top-0 z-20 bg-smena_white">
          <span className="Tag">Профессии</span>
        </div>
        <ScrollSyncPane>
          <div className="requestScheduleProfessions scrollbar-hide">
            {filterRequestWithSchedule?.map(requestSchedule => (
              <RequestProfession key={requestSchedule?.id} requestSchedule={requestSchedule} />
            ))}
          </div>
        </ScrollSyncPane>
      </div>

      <div>
        <ScrollSyncPane>
          <div
            className={clsx(
              'gap-x-2 requestScheduleProfessionsRows border-b sticky top-0 z-20 bg-smena_white w-full overflow-x-auto scrollbar-hide',
            )}
            style={{
              gridTemplateColumns: `repeat(${days.length}, 80px)`,
            }}
          >
            {days.map(day => (
              <div className="flex justify-center items-center Tag" key={day.valueOf()}>
                <span className={clsx('px-2', day.isToday() && 'bg-primary rounded-lg text-smena_white')}>
                  {dayjs(day).format('D, dd')}
                </span>
              </div>
            ))}
          </div>
        </ScrollSyncPane>
        <ScrollSyncPane>
          <div className="requestScheduleDates">
            {filterRequestWithSchedule?.map(requestSchedule => (
              <RequestProfessionDates key={requestSchedule?.id} requestSchedule={requestSchedule} days={days} />
            ))}
          </div>
        </ScrollSyncPane>
      </div>
    </div>
  );
});

export default ScheduleTable;
