import clsx from 'clsx';
import { EAutoSave } from 'enums/EAutoSave';
import React from 'react';
import { getAutoSaveRu } from './getAutoSaveRu';

interface Props {
  status?: EAutoSave;
}
const AutoSave = React.memo(({ status }: Props) => {
  const { statusRu, statusIcon, statusClassName } = getAutoSaveRu(status);
  return (
    <span className={clsx('flex items-center gap-x-2 rounded-lg px-2 py-0.5 Table-small', statusClassName)}>
      {statusIcon}
      <span>{statusRu}</span>
    </span>
  );
});

export default AutoSave;
