import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemovePhotoInCasingGalleryMutationVariables = Types.Exact<{
  photoIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type RemovePhotoInCasingGalleryMutation = { __typename?: 'Mutation', removePhotoInCasingGallery: boolean };


export const RemovePhotoInCasingGalleryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemovePhotoInCasingGallery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"photoIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removePhotoInCasingGallery"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"photoIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"photoIds"}}}]}]}}]} as unknown as DocumentNode;
export type RemovePhotoInCasingGalleryMutationFn = Apollo.MutationFunction<RemovePhotoInCasingGalleryMutation, RemovePhotoInCasingGalleryMutationVariables>;

/**
 * __useRemovePhotoInCasingGalleryMutation__
 *
 * To run a mutation, you first call `useRemovePhotoInCasingGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePhotoInCasingGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePhotoInCasingGalleryMutation, { data, loading, error }] = useRemovePhotoInCasingGalleryMutation({
 *   variables: {
 *      photoIds: // value for 'photoIds'
 *   },
 * });
 */
export function useRemovePhotoInCasingGalleryMutation(baseOptions?: Apollo.MutationHookOptions<RemovePhotoInCasingGalleryMutation, RemovePhotoInCasingGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePhotoInCasingGalleryMutation, RemovePhotoInCasingGalleryMutationVariables>(RemovePhotoInCasingGalleryDocument, options);
      }
export type RemovePhotoInCasingGalleryMutationHookResult = ReturnType<typeof useRemovePhotoInCasingGalleryMutation>;
export type RemovePhotoInCasingGalleryMutationResult = Apollo.MutationResult<RemovePhotoInCasingGalleryMutation>;
export type RemovePhotoInCasingGalleryMutationOptions = Apollo.BaseMutationOptions<RemovePhotoInCasingGalleryMutation, RemovePhotoInCasingGalleryMutationVariables>;