import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVacancyStartTimeQueryVariables = Types.Exact<{
  facilityGroupId: Types.Scalars['String']['input'];
}>;


export type GetVacancyStartTimeQuery = { __typename?: 'Query', getVacancyStartTime: { __typename?: 'IVacancyStartTime', timesEnd: Array<string>, timesStart: Array<string> } };


export const GetVacancyStartTimeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetVacancyStartTime"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getVacancyStartTime"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityGroupId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timesEnd"}},{"kind":"Field","name":{"kind":"Name","value":"timesStart"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetVacancyStartTimeQuery__
 *
 * To run a query within a React component, call `useGetVacancyStartTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacancyStartTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacancyStartTimeQuery({
 *   variables: {
 *      facilityGroupId: // value for 'facilityGroupId'
 *   },
 * });
 */
export function useGetVacancyStartTimeQuery(baseOptions: Apollo.QueryHookOptions<GetVacancyStartTimeQuery, GetVacancyStartTimeQueryVariables> & ({ variables: GetVacancyStartTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVacancyStartTimeQuery, GetVacancyStartTimeQueryVariables>(GetVacancyStartTimeDocument, options);
      }
export function useGetVacancyStartTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVacancyStartTimeQuery, GetVacancyStartTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVacancyStartTimeQuery, GetVacancyStartTimeQueryVariables>(GetVacancyStartTimeDocument, options);
        }
export function useGetVacancyStartTimeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVacancyStartTimeQuery, GetVacancyStartTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVacancyStartTimeQuery, GetVacancyStartTimeQueryVariables>(GetVacancyStartTimeDocument, options);
        }
export type GetVacancyStartTimeQueryHookResult = ReturnType<typeof useGetVacancyStartTimeQuery>;
export type GetVacancyStartTimeLazyQueryHookResult = ReturnType<typeof useGetVacancyStartTimeLazyQuery>;
export type GetVacancyStartTimeSuspenseQueryHookResult = ReturnType<typeof useGetVacancyStartTimeSuspenseQuery>;
export type GetVacancyStartTimeQueryResult = Apollo.QueryResult<GetVacancyStartTimeQuery, GetVacancyStartTimeQueryVariables>;