import { ApolloCache, Reference, StoreObject } from '@apollo/client';

interface Props {
  userId: string;
  facilityId: string;
}

export const removeForemanCache = (cache: ApolloCache<any>, { userId, facilityId }: Props) => {
  cache.modify({
    id: `RatingUser:${userId}`,
    fields: {
      Facility_FOREMAN(prev = [], { readField }) {
        return prev.filter((el?: Reference | StoreObject) => readField('id', el) !== facilityId);
      },
    },
  });
  cache.modify({
    id: `Facility:${facilityId}`,
    fields: {
      foremen(prev = [], { readField }) {
        return prev.filter((el?: Reference | StoreObject) => readField('id', el) !== userId);
      },
    },
  });
};
