import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PaginationModerationReportFragmentDoc } from '../../fragments/generated/PaginationModerationReport.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModerationReportsPaginatedQueryVariables = Types.Exact<{
  input: Types.ModerationReportListInput;
}>;


export type ModerationReportsPaginatedQuery = { __typename?: 'Query', moderationReportsPaginated: { __typename?: 'PaginatedModerationReportModel', count: number, pages: number, reports: Array<{ __typename?: 'ModerationReport', id: string, dateStart: any, dateEnd: any, isImportFromFile?: boolean | null, noModerated?: number | null, periodType: Types.PeriodType, status: Types.ModerationStatus, statusDate?: any | null, updatedAt: any, createdAt: any, workersCount?: number | null, shiftCount?: number | null, facility?: { __typename?: 'Facility', id: string, name: string, facilityGroupId?: string | null, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string } | null } | null }> } };


export const ModerationReportsPaginatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ModerationReportsPaginated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ModerationReportListInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moderationReportsPaginated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"reports"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PaginationModerationReport"}}]}}]}}]}},...PaginationModerationReportFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useModerationReportsPaginatedQuery__
 *
 * To run a query within a React component, call `useModerationReportsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useModerationReportsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModerationReportsPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModerationReportsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<ModerationReportsPaginatedQuery, ModerationReportsPaginatedQueryVariables> & ({ variables: ModerationReportsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModerationReportsPaginatedQuery, ModerationReportsPaginatedQueryVariables>(ModerationReportsPaginatedDocument, options);
      }
export function useModerationReportsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModerationReportsPaginatedQuery, ModerationReportsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModerationReportsPaginatedQuery, ModerationReportsPaginatedQueryVariables>(ModerationReportsPaginatedDocument, options);
        }
export function useModerationReportsPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ModerationReportsPaginatedQuery, ModerationReportsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ModerationReportsPaginatedQuery, ModerationReportsPaginatedQueryVariables>(ModerationReportsPaginatedDocument, options);
        }
export type ModerationReportsPaginatedQueryHookResult = ReturnType<typeof useModerationReportsPaginatedQuery>;
export type ModerationReportsPaginatedLazyQueryHookResult = ReturnType<typeof useModerationReportsPaginatedLazyQuery>;
export type ModerationReportsPaginatedSuspenseQueryHookResult = ReturnType<typeof useModerationReportsPaginatedSuspenseQuery>;
export type ModerationReportsPaginatedQueryResult = Apollo.QueryResult<ModerationReportsPaginatedQuery, ModerationReportsPaginatedQueryVariables>;