import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PromotionEntityFragmentDoc } from '../../../../facilityGroup/api/fragments/generated/PromotionEntity.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPromotionsForFacilityQueryVariables = Types.Exact<{
  input: Types.GetListPromotionsForFacilityInput;
}>;


export type GetListPromotionsForFacilityQuery = { __typename?: 'Query', getListPromotionsForFacility: Array<{ __typename?: 'PromotionEntity', id: string, badShiftCount: number, dateTimeEnd?: any | null, dateTimeStart: any, name: string, repeatCondition: Types.PromotionRepeatConditionEnum, requirementBonus: Types.PromotionRequirementBonusEnum, requirementShiftCount: number, rewardSum: number, rewardType: Types.PromotionRewardTypeEnum, status: Types.PromotionStatusEnum, childPromotions?: Array<{ __typename?: 'PromotionEntity', facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null }> };


export const GetListPromotionsForFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetListPromotionsForFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetListPromotionsForFacilityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getListPromotionsForFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PromotionEntity"}}]}}]}},...PromotionEntityFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetListPromotionsForFacilityQuery__
 *
 * To run a query within a React component, call `useGetListPromotionsForFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPromotionsForFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPromotionsForFacilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPromotionsForFacilityQuery(baseOptions: Apollo.QueryHookOptions<GetListPromotionsForFacilityQuery, GetListPromotionsForFacilityQueryVariables> & ({ variables: GetListPromotionsForFacilityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPromotionsForFacilityQuery, GetListPromotionsForFacilityQueryVariables>(GetListPromotionsForFacilityDocument, options);
      }
export function useGetListPromotionsForFacilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPromotionsForFacilityQuery, GetListPromotionsForFacilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPromotionsForFacilityQuery, GetListPromotionsForFacilityQueryVariables>(GetListPromotionsForFacilityDocument, options);
        }
export function useGetListPromotionsForFacilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListPromotionsForFacilityQuery, GetListPromotionsForFacilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListPromotionsForFacilityQuery, GetListPromotionsForFacilityQueryVariables>(GetListPromotionsForFacilityDocument, options);
        }
export type GetListPromotionsForFacilityQueryHookResult = ReturnType<typeof useGetListPromotionsForFacilityQuery>;
export type GetListPromotionsForFacilityLazyQueryHookResult = ReturnType<typeof useGetListPromotionsForFacilityLazyQuery>;
export type GetListPromotionsForFacilitySuspenseQueryHookResult = ReturnType<typeof useGetListPromotionsForFacilitySuspenseQuery>;
export type GetListPromotionsForFacilityQueryResult = Apollo.QueryResult<GetListPromotionsForFacilityQuery, GetListPromotionsForFacilityQueryVariables>;