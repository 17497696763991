import React, { useMemo } from 'react';
import { BalanceBlock } from '../balance';
import { Today } from '../today';
import Trends from '../trends';
import MainPageNoData from 'assets/icons/MainPageNoData';
import { UserRegistrationChart } from '..';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { useMeRole } from 'store/hooks/useMeRole';
import { Role } from 'generated/graphql';
import MetricsLoader from './metricsLoader';

export const Metrics = () => {
  const { facilities, loading: facilitiesLoading } = useSelectFacilities();
  const { facilitiesGroups, loading } = useSelectFacilityGroups();

  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  const isMeClientDirector = useMeRole([Role.ClientDirector]);

  const showMetric = useMemo(() => {
    if (facilities && facilitiesGroups) {
      return Boolean(facilities.length) && Boolean(facilitiesGroups.length);
    }
    return false;
  }, [facilities, facilitiesGroups]);

  if (loading || facilitiesLoading) {
    return <MetricsLoader />;
  }

  return (
    <>
      {showMetric ? (
        <>
          <div className={'grid gap-5 grid-cols-12'}>
            {isMeClientDirector && <BalanceBlock />}
            <Today />
            {isMeAdminOrSupervisor && <UserRegistrationChart />}
          </div>
          <Trends />
        </>
      ) : (
        <div className={'flex justify-center'}>
          <MainPageNoData />
        </div>
      )}
    </>
  );
};
