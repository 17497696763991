import { useCallback, useEffect, useMemo, useState } from 'react';
import ResponsibleUser from 'pages/facility/components/responsibleUsers';
import AutocompleteBlock from 'ui/autocompleteBlock';

import { IOption } from 'interfaces/IOption';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { options } from './helpers';
import { useMeRole } from 'store/hooks/useMeRole';
import { useAddForemanToFacilityMutation } from 'pages/user/api/mutations/generated/AddForemanToFacility';
import { useAddSupervisorToFacilityMutation } from 'pages/user/api/mutations/generated/AddSupervisorToFacility';
import { useForemenLazyQuery } from 'pages/facility/api/queries/generated/Foremen';
import { useSupervisorsLazyQuery } from 'pages/facility/api/queries/generated/Supervisors';
import { useFacility } from 'utils/api/useFacility';
import sortBy from 'lodash/sortBy';
import { addForemanCache } from 'pages/facility/api/cache/addForemanCache';
import { addSupervisorCache } from '../../api/cache/addSupervisorCache';
import { Role } from 'generated/graphql';

const FacilityForemenSupervisors = () => {
  const { facility, id } = useFacility();
  const isMeAdmin = useMeRole([Role.Admin]);
  const facilitySupervisors = useMemo(() => sortBy(facility?.supervisors, el => el.lastname), [facility?.supervisors]);
  const facilityForemen = useMemo(() => sortBy(facility?.foremen, el => el.lastname), [facility?.foremen]);
  const [foreman, setForeman] = useState<IOption | null>(null);
  const [supervisor, setSupervisor] = useState<IOption | null>(null);
  const [getForemen, { data: dataForemen }] = useForemenLazyQuery();
  const [getSupervisors, { data: dataSupervisors }] = useSupervisorsLazyQuery();

  const foremenOfFacility = useMemo(() => options(facilityForemen), [facilityForemen]);

  const foremenOptions = useMemo(
    () => options(dataForemen?.foremans)?.filter(foreman => !foremenOfFacility?.find(el => el.id === foreman.id)),
    [dataForemen?.foremans, foremenOfFacility],
  );

  const supervisorsOfFacility = useMemo(() => options(facilitySupervisors), [facilitySupervisors]);

  const supervisorsOptions = useMemo(
    () =>
      options(dataSupervisors?.supervisors)?.filter(
        foreman => !supervisorsOfFacility?.find(el => el.id === foreman.id),
      ),
    [dataSupervisors?.supervisors, supervisorsOfFacility],
  );

  useEffect(() => {
    getForemen();
    getSupervisors();
  }, [getForemen, getSupervisors]);

  const [addForeman, { loading: loadingForeman }] = useAddForemanToFacilityMutation({
    update: (cache, { data }) => {
      if (!id) return;
      addForemanCache(cache, { data, facilityId: id });
    },
  });
  const [addSupervisor, { loading: loadingSupervisor }] = useAddSupervisorToFacilityMutation({
    update: (cache, { data }) => {
      if (!id) return;
      addSupervisorCache(cache, { data, facilityId: id });
    },
  });

  const addSupervisorHandler = useCallback(
    (userId: string) => {
      if (!id) return;
      addSupervisor({
        variables: {
          facilityId: id,
          userId,
        },
      })
        .then(response => {
          if (response.data?.addSupervisorToFacility) {
            successToast('Супервайзер прикреплен');
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [addSupervisor, id],
  );

  const addForemanHandler = useCallback(
    (userId: string) => {
      if (!id) return;
      addForeman({
        variables: {
          facilityId: id,
          userId,
        },
      })
        .then(response => {
          if (response.data?.addForemanToFacility) {
            successToast('Бригадир прикреплен');
          }
        })
        .catch(error => errorToast(errorHandler(error)));
    },
    [addForeman, id],
  );

  return (
    <div className="facilityPageBlock blockTemplate">
      <div className="grid grid-cols-8 gap-5">
        <span className="Subtitle1 text-smena_text-secondary inline-block col-span-full">Ответственные</span>
        <div className="col-span-full grid grid-cols-8 gap-x-5 pb-10 mb-5 border-b border-smena_gray-30">
          <div className="sm:col-span-4 col-span-8">
            <div className="grid grid-cols-[max-content_1fr] gap-x-2 items-baseline">
              <span className="Subtitle2">Супервайзер</span>
              <div className={'grid gap-y-1'}>
                {facilitySupervisors?.length ? (
                  facilitySupervisors.map(supervisor => (
                    <ResponsibleUser key={supervisor.id} user={supervisor} canRemove={isMeAdmin} />
                  ))
                ) : (
                  <span className="Body2 text-smena_text-secondary">Не назначен</span>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-4 col-span-8">
            <div className="grid grid-cols-[max-content_1fr] gap-x-2 items-baseline">
              <span className="Subtitle2">Бригадир</span>
              <div className={'grid gap-y-1'}>
                {facilityForemen?.length ? (
                  facilityForemen.map(foreman => <ResponsibleUser key={foreman.id} user={foreman} />)
                ) : (
                  <span className="Body2 text-smena_text-secondary">Не назначен</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {isMeAdmin && (
          <AutocompleteBlock
            id="supervisorId"
            label="Супервайзер"
            onChangeHandler={value => {
              setSupervisor(value);
            }}
            value={supervisor?.name}
            options={supervisorsOptions}
            buttonHandler={() => {
              if (supervisor) {
                addSupervisorHandler(supervisor.id);
              }
            }}
            loading={loadingSupervisor}
          />
        )}
        <AutocompleteBlock
          id="foremanId"
          label="Бригадир"
          className={'sm:col-start-5'}
          onChangeHandler={value => setForeman(value)}
          value={foreman?.name}
          options={foremenOptions}
          buttonHandler={() => {
            if (foreman) {
              addForemanHandler(foreman.id);
            }
          }}
          loading={loadingForeman}
        />
      </div>
    </div>
  );
};
export default FacilityForemenSupervisors;
