import { ApolloCache } from '@apollo/client';
import { ShiftFragment } from 'pages/moderationReport/api/fragments/generated/Shift.fragment';
import { UserShiftsDocument, UserShiftsQuery } from 'pages/userSchedule/api/queries/generated/UserShifts';

interface Props {
  store: ApolloCache<any>;
  data?: ShiftFragment;
  variables: { userId: string; dateFrom: string; dateTo: string };
}
export const writeUserShiftsCache = ({ store, data, variables }: Props) => {
  const facilityCache = store.readQuery<UserShiftsQuery>({
    query: UserShiftsDocument,
    variables: {
      input: variables,
    },
  });

  const userShiftsFn = () => {
    if (facilityCache && data) {
      return facilityCache.userShifts.map(shift => {
        const currentShift = data.id === shift.id;
        if (currentShift) {
          return { ...shift, stage: data.stage };
        }
        return shift;
      });
    }
    return [];
  };

  const userShifts = userShiftsFn();

  store.writeQuery<UserShiftsQuery>({
    query: UserShiftsDocument,
    variables: {
      input: variables,
    },
    data: {
      userShifts,
    },
  });
};
