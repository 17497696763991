import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ScheduleTypeFragmentDoc } from '../../fragments/generated/ScheduleType.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScheduleTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetScheduleTypesQuery = { __typename?: 'Query', getScheduleTypes: Array<{ __typename?: 'ScheduleType', id: string, name: string }> };


export const GetScheduleTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetScheduleTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getScheduleTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ScheduleType"}}]}}]}},...ScheduleTypeFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetScheduleTypesQuery__
 *
 * To run a query within a React component, call `useGetScheduleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScheduleTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetScheduleTypesQuery, GetScheduleTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleTypesQuery, GetScheduleTypesQueryVariables>(GetScheduleTypesDocument, options);
      }
export function useGetScheduleTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleTypesQuery, GetScheduleTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleTypesQuery, GetScheduleTypesQueryVariables>(GetScheduleTypesDocument, options);
        }
export function useGetScheduleTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScheduleTypesQuery, GetScheduleTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScheduleTypesQuery, GetScheduleTypesQueryVariables>(GetScheduleTypesDocument, options);
        }
export type GetScheduleTypesQueryHookResult = ReturnType<typeof useGetScheduleTypesQuery>;
export type GetScheduleTypesLazyQueryHookResult = ReturnType<typeof useGetScheduleTypesLazyQuery>;
export type GetScheduleTypesSuspenseQueryHookResult = ReturnType<typeof useGetScheduleTypesSuspenseQuery>;
export type GetScheduleTypesQueryResult = Apollo.QueryResult<GetScheduleTypesQuery, GetScheduleTypesQueryVariables>;