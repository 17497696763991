export const Image = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 0C2.70435 0 1.94129 0.31607 1.37868 0.87868C0.81607 1.44129 0.5 2.20435 0.5 3V17C0.5 17.7957 0.81607 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H4.49902C4.49967 20 4.50033 20 4.50098 20H17.5C18.2957 20 19.0587 19.6839 19.6213 19.1213C20.1839 18.5587 20.5 17.7957 20.5 17V3C20.5 2.20435 20.1839 1.44129 19.6213 0.87868C19.0587 0.31607 18.2957 0 17.5 0H3.5ZM6.91421 18H17.5C17.7652 18 18.0196 17.8946 18.2071 17.7071C18.3946 17.5196 18.5 17.2652 18.5 17V12.4142L15.5 9.41421L6.91421 18ZM18.5 9.58579V3C18.5 2.73478 18.3946 2.48043 18.2071 2.29289C18.0196 2.10536 17.7652 2 17.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V17C2.5 17.2652 2.60536 17.5196 2.79289 17.7071C2.98043 17.8946 3.23478 18 3.5 18H4.08579L14.7929 7.29289C15.1834 6.90237 15.8166 6.90237 16.2071 7.29289L18.5 9.58579ZM8 4C7.33696 4 6.70107 4.26339 6.23223 4.73223C5.76339 5.20107 5.5 5.83696 5.5 6.5C5.5 7.16304 5.76339 7.79893 6.23223 8.26777C6.70107 8.73661 7.33696 9 8 9C8.66304 9 9.29893 8.73661 9.76777 8.26777C10.2366 7.79893 10.5 7.16304 10.5 6.5C10.5 5.83696 10.2366 5.20107 9.76777 4.73223C9.29893 4.26339 8.66304 4 8 4ZM7.64645 6.14645C7.74021 6.05268 7.86739 6 8 6C8.13261 6 8.25979 6.05268 8.35355 6.14645C8.44732 6.24021 8.5 6.36739 8.5 6.5C8.5 6.63261 8.44732 6.75979 8.35355 6.85355C8.25979 6.94732 8.13261 7 8 7C7.86739 7 7.74021 6.94732 7.64645 6.85355C7.55268 6.75979 7.5 6.63261 7.5 6.5C7.5 6.36739 7.55268 6.24021 7.64645 6.14645Z"
        fill="#397DFF"
      />
    </svg>
  );
};
