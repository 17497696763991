import React from 'react';
import SimpleInput from 'ui/input';
import FacilityMap from 'pages/facility/components/mapAndCoordinates/map';
import { reqCoordinates } from 'utils/helpers/constVariables';
import { useMeRole } from 'store/hooks/useMeRole';
import { useMapStore } from '../../lib/hooks/useMapStore';
import { Role } from 'generated/graphql';
import SimpleSelect from 'ui/select';
import { objectDurationTypes } from 'utils/helpers/lists/objectDurationList';

interface IFacilityMapAndCoordinates {
  values: any;
  touched: any;
  errors: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any) => void;
}

const FacilityMapAndCoordinates = React.memo(
  ({ setFieldValue, handleChange, values, touched, errors }: IFacilityMapAndCoordinates) => {
    const isUserClient = useMeRole([Role.ClientDirector, Role.ClientManager]);
    const { setLongitude, setLatitude, latitude, longitude } = useMapStore();
    return (
      <div className={'flex flex-col gap-y-6'}>
        <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">Местоположение</span>
        <div className="h-52 w-full relative">
          <FacilityMap />
        </div>
        <div className="col-span-full grid grid-cols-8 gap-x-5 gap-y-7">
          <SimpleInput
            divClassName="sm:col-span-3 col-span-4"
            placeholder="Введите широту"
            label="Широта"
            onChange={e => {
              const value = e.target.value;
              if (reqCoordinates.test(value) || value === '') {
                setLatitude(value);
              }
            }}
            value={latitude ?? ''}
            type="text"
            name="latitude"
            showText={isUserClient}
            error={errors.latitude ?? ''}
          />

          <SimpleInput
            divClassName="sm:col-span-3 col-span-4"
            placeholder="Введите долготу"
            label="Долгота"
            onChange={e => {
              const value = e.target.value;
              if (reqCoordinates.test(value) || value === '') {
                setLongitude(value);
              }
            }}
            value={longitude ?? ''}
            type="text"
            name="geo_lon"
            showText={isUserClient}
            error={errors.longitude ?? ''}
          />
          <SimpleInput
            divClassName="sm:col-span-2 col-span-4"
            placeholder="Введите радиус"
            label="Радиус (м.)"
            onChange={handleChange}
            value={values.radius}
            type="text"
            name="radius"
            regText
            showText={isUserClient}
            error={touched.radius && errors.radius ? errors.radius : ''}
          />
        </div>

        <div className="col-span-full grid grid-cols-4 gap-x-5 gap-y-7">
          <SimpleSelect
            divClassName="sm:col-span-2 col-span-4"
            sort={false}
            label="Для чекина"
            value={values.checkInMeasurement}
            options={objectDurationTypes}
            onChange={value => {
              setFieldValue('checkInMeasurement', Number(value));
            }}
            error={touched.checkInMeasurement && errors.checkInMeasurement ? errors.checkInMeasurement : ''}
          />

          <SimpleSelect
            divClassName="sm:col-span-2 col-span-4"
            sort={false}
            label="Для чекаута"
            value={values.checkOutMeasurement}
            options={objectDurationTypes}
            onChange={value => {
              setFieldValue('checkOutMeasurement', Number(value));
            }}
            error={touched.checkOutMeasurement && errors.checkOutMeasurement ? errors.checkOutMeasurement : ''}
          />
        </div>
      </div>
    );
  },
);

export default FacilityMapAndCoordinates;
