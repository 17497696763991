import { useCreateModerationReportMutation } from '../moderationReport/api/mutations/generated/CreateModerationReport';
import { PeriodType } from 'generated/graphql';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from '../../utils/helpers/errorHandler';
import { useNavigate } from 'react-router-dom';
import { DayOrNullArray } from 'interfaces/CustomTypes';
import { ISO_DATE } from '../../utils/helpers/constVariables';

interface Props {
  groupId?: string;
  facilityId?: string;
  period?: string;
  date: DayOrNullArray;
}

export const useCreateModerationReport = () => {
  const [createReport, { loading }] = useCreateModerationReportMutation();

  const navigate = useNavigate();
  const initialValues: Props = {
    groupId: undefined,
    facilityId: undefined,
    period: undefined,
    date: null,
  };
  const validate = (values: typeof initialValues) => {
    const errors: any = {};
    if (!values.facilityId) {
      errors.facilityId = 'Обязательное поле';
    }
    if (!values.period) {
      errors.period = 'Обязательное поле';
    }
    if (!values.date) {
      errors.date = 'Обязательное поле';
    }
    return errors;
  };
  const onSubmit = (values: typeof initialValues) => {
    const mutationVariables = {
      dateStart: values.date && values.date[0]?.format(ISO_DATE),
      dateEnd: values.date && values.date[1]?.format(ISO_DATE),
      facilityId: values.facilityId!,
      periodType: values.period as PeriodType,
    };
    createReport({
      variables: {
        input: mutationVariables,
      },
    })
      .then(response => {
        if (response.data?.createModerationReport) {
          successToast('Период создан');
          navigate(`/moderation/${response.data?.createModerationReport.id}`);
        }
      })
      .catch(e => {
        errorToast(errorHandler(e));
      });
  };
  return { initialValues, validate, onSubmit, loading };
};
