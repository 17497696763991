import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadModerationReportMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DownloadModerationReportMutation = { __typename?: 'Mutation', downloadModerationReport: { __typename?: 'Report', filePath: string, id: string, type: Types.ReportType } };


export const DownloadModerationReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DownloadModerationReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"downloadModerationReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"filePath"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode;
export type DownloadModerationReportMutationFn = Apollo.MutationFunction<DownloadModerationReportMutation, DownloadModerationReportMutationVariables>;

/**
 * __useDownloadModerationReportMutation__
 *
 * To run a mutation, you first call `useDownloadModerationReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadModerationReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadModerationReportMutation, { data, loading, error }] = useDownloadModerationReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadModerationReportMutation(baseOptions?: Apollo.MutationHookOptions<DownloadModerationReportMutation, DownloadModerationReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadModerationReportMutation, DownloadModerationReportMutationVariables>(DownloadModerationReportDocument, options);
      }
export type DownloadModerationReportMutationHookResult = ReturnType<typeof useDownloadModerationReportMutation>;
export type DownloadModerationReportMutationResult = Apollo.MutationResult<DownloadModerationReportMutation>;
export type DownloadModerationReportMutationOptions = Apollo.BaseMutationOptions<DownloadModerationReportMutation, DownloadModerationReportMutationVariables>;