import React from 'react';

const DragIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49992 2.5C6.57944 2.5 5.83325 3.24619 5.83325 4.16667C5.83325 5.08714 6.57944 5.83333 7.49992 5.83333C8.42039 5.83333 9.16659 5.08714 9.16659 4.16667C9.16659 3.24619 8.42039 2.5 7.49992 2.5Z"
        fill="#A4A5B1"
      />
      <path
        d="M7.49992 8.33333C6.57944 8.33333 5.83325 9.07953 5.83325 10C5.83325 10.9205 6.57944 11.6667 7.49992 11.6667C8.42039 11.6667 9.16659 10.9205 9.16659 10C9.16659 9.07953 8.42039 8.33333 7.49992 8.33333Z"
        fill="#A4A5B1"
      />
      <path
        d="M5.83325 15.8333C5.83325 14.9129 6.57944 14.1667 7.49992 14.1667C8.42039 14.1667 9.16659 14.9129 9.16659 15.8333C9.16659 16.7538 8.42039 17.5 7.49992 17.5C6.57944 17.5 5.83325 16.7538 5.83325 15.8333Z"
        fill="#A4A5B1"
      />
      <path
        d="M12.4999 8.33333C11.5794 8.33333 10.8333 9.07953 10.8333 10C10.8333 10.9205 11.5794 11.6667 12.4999 11.6667C13.4204 11.6667 14.1666 10.9205 14.1666 10C14.1666 9.07953 13.4204 8.33333 12.4999 8.33333Z"
        fill="#A4A5B1"
      />
      <path
        d="M10.8333 4.16667C10.8333 3.24619 11.5794 2.5 12.4999 2.5C13.4204 2.5 14.1666 3.24619 14.1666 4.16667C14.1666 5.08714 13.4204 5.83333 12.4999 5.83333C11.5794 5.83333 10.8333 5.08714 10.8333 4.16667Z"
        fill="#A4A5B1"
      />
      <path
        d="M12.4999 14.1667C11.5794 14.1667 10.8333 14.9129 10.8333 15.8333C10.8333 16.7538 11.5794 17.5 12.4999 17.5C13.4204 17.5 14.1666 16.7538 14.1666 15.8333C14.1666 14.9129 13.4204 14.1667 12.4999 14.1667Z"
        fill="#A4A5B1"
      />
    </svg>
  );
};

export default DragIcon;
