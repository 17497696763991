import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/layout/main';
import PageNotFound from '../../assets/icons/404';

export default function Custom404() {
  const navigate = useNavigate();
  return (
    <MainLayout title={'Страница не найдена'} breadCrumbs={[{ path: '/404', name: '404' }]}>
      <div className={'md:w-4/5 w-full flex justify-between flex-wrap items-center mx-auto'}>
        <div className={'flex flex-col justify-center md:items-start items-center md:w-auto w-full gap-y-4'}>
          <span className={'H2'}>Ууупс!</span>
          <span className={'H2'}>Страница не найдена</span>
          <div>
            <button
              className={'btn-primary'}
              onClick={() => {
                navigate('/');
              }}
            >
              На главную
            </button>
          </div>
        </div>
        <div className={'flex max-w-[600px] max-h-[500px] w-full h-full'}>
          <PageNotFound />
        </div>
      </div>
    </MainLayout>
  );
}
