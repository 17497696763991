import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import { useCallback, useMemo, useState } from 'react';
import { FacilityPaginatedFragment } from 'pages/facilities/api/fragments/generated/FacilityPaginated.fragment';
import { cityName } from 'utils/helpers/filters/filtersName';
import { updateFacilityCache } from 'pages/facilities/api/updateCache';
import { useCities } from 'utils/api/useCities';
import { useChangeFacilityStatusMutation } from 'pages/facilities/api/mutations/generated/ChangeFacilityStatus';
import { FacilityFieldsSorting, Role } from 'generated/graphql';
import GarbageArrow from 'assets/icons/GarbageArrow';
import GarbageArrowLess from 'assets/icons/GarbageArrowLess';
import { useMeRole } from '../../../store/hooks/useMeRole';
import Tooltip from 'antd/es/tooltip';

export const useFacilitiesTable = (data: FacilityPaginatedFragment[]) => {
  const isMeAdmin = useMeRole([Role.Admin]);
  const [changeStatus, { loading }] = useChangeFacilityStatusMutation({
    update: updateFacilityCache,
  });
  const { cities } = useCities();
  const buttonHandler = useCallback(
    (id: string) => {
      changeStatus({
        variables: {
          id,
        },
      });
    },
    [changeStatus],
  );
  const columns: ColumnDef<FacilityPaginatedFragment>[] = useMemo(() => {
    return [
      {
        id: 'name',
        header: 'Объект',
        size: 210,
        minSize: 180,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/facilities/${original.id}`} className="link">
              {original.name}
            </Link>
          );
        },
      },
      {
        id: 'facilityGroup',
        header: 'Группа',
        size: 210,
        minSize: 180,
        cell: ({ row: { original } }) => {
          return isMeAdmin ? (
            <Link to={`/groups/${original.facilityGroupId}`} className="link">
              {original.facilityGroup?.name}
            </Link>
          ) : (
            <span>{original.facilityGroup?.name}</span>
          );
        },
      },
      {
        id: 'city',
        header: 'Город',
        size: 200,
        minSize: 170,
        cell: ({ row: { original } }) =>
          original.address?.city ? original.address.city : cityName(cities, original.cityId),
      },
      {
        id: 'address',
        header: 'Адрес',
        size: 250,
        minSize: 250,
        cell: ({ row: { original } }) => original.addressString || original.address?.address,
      },
      {
        id: FacilityFieldsSorting.Metro,
        header: 'Метро',
        size: 250,
        minSize: 250,
        cell: ({ row: { original } }) => original.metroStations?.map(el => el.name).join(', '),
      },
      {
        id: 'status',
        header: 'Статус',
        size: 87,
        minSize: 87,
        cell: ({ row: { original } }) =>
          original.is_active ? <Check className="text-smena_green-dark" /> : <Across className="text-smena_red" />,
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 115,
        minSize: 115,
        cell: ({ row: { original } }) => {
          return (
            <Tooltip title={'Изменить статус активности'}>
              <button disabled={loading} onClick={() => buttonHandler(original.id)}>
                {original.is_active ? <GarbageArrow /> : <GarbageArrowLess />}
              </button>
            </Tooltip>
          );
        },
      },
    ];
  }, [buttonHandler, cities, isMeAdmin, loading]);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    meta: {
      name: true,
      metro: true,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};
