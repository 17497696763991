import { ISwitch } from 'interfaces/ISwitch';

export const Switch = ({ name, checked, onChange, disabled }: ISwitch) => {
  return (
    <label className="switch_btn">
      <input type="checkbox" name={name} checked={checked} onChange={onChange} disabled={disabled} />
      <span className="slider"></span>
    </label>
  );
};
