import { Role, ZayavkaChangesStatus, ZayavkaStage, ZayavkaToCancelStatus } from 'generated/graphql';
import { useCallback, useMemo } from 'react';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useChangesByStatus, useRequestStatus } from './index';
import { useMeRole } from 'store/hooks/useMeRole';
import {
  useDeclineRequestBySupervisorMutation
} from 'pages/facilityRequests/api/mutations/generated/DeclineRequestBySupervisor';
import {
  useDeclineChangesByDirectorMutation
} from 'pages/facilityRequests/api/mutations/generated/DeclineChangesByDirector';
import {
  useDeclineRequestByDirectorMutation
} from 'pages/facilityRequests/api/mutations/generated/DeclineRequestByDirector';
import {
  useDeclineChangesBySupervisorMutation
} from 'pages/facilityRequests/api/mutations/generated/DeclineChangesBySupervisor';
import {
  useDeclineCancelRequestByDirectorMutation
} from 'pages/facilityRequests/api/mutations/generated/DeclineCancelZayavkaByDirector';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';

export const useDeclineByDirector = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Draft] });
  const changes = useChangesByStatus({
    status: [ZayavkaChangesStatus.Waiting],
  });
  return changes && requestStatus;
};

export const useDeclineChangesByDirector = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working, ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Waiting, ZayavkaChangesStatus.Draft] });
  return changes && requestStatus;
};

export const useDeclineBySupervisor = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Accepted] });
  return changes && requestStatus;
};

export const useDeclineChangesBySupervisor = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working, ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.New] });
  return changes && requestStatus;
};

export const useDeclineButton = () => {
  const { request } = useCurrentRequest();
  const isMeDirector = useMeRole([Role.ClientDirector]);
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeAdmin = useMeRole([Role.Admin]);

  const [declineRequestByDirector, { loading: loadingDeclineRequestByDirector }] =
    useDeclineRequestByDirectorMutation();
  const [declineRequestBySupervisor, { loading: loadingDeclineRequestBySupervisor }] =
    useDeclineRequestBySupervisorMutation();
  const [declineChangesByDirectorMutation, { loading: loadingDeclineChangesByDirector }] =
    useDeclineChangesByDirectorMutation();
  const [declineChangesBySupervisorMutation, { loading: loadingDeclineChangesBySupervisor }] =
    useDeclineChangesBySupervisorMutation();
  const [declineCancelRequestByDirector, { loading: loadingDeclineCancelRequestByDirector }] =
    useDeclineCancelRequestByDirectorMutation();

  const loading =
    loadingDeclineRequestByDirector ||
    loadingDeclineRequestBySupervisor ||
    loadingDeclineChangesByDirector ||
    loadingDeclineChangesBySupervisor ||
    loadingDeclineCancelRequestByDirector;

  const declineByDirector = useDeclineByDirector();
  const declineChangesByDirector = useDeclineChangesByDirector();
  const declineCancelByDirector = request?.toCancel === ZayavkaToCancelStatus.Waiting;
  const declineBySupervisor = useDeclineBySupervisor();
  const declineChangesBySupervisor = useDeclineChangesBySupervisor();

  const directorCondition = declineByDirector || declineChangesByDirector || declineCancelByDirector;
  const supervisorCondition = declineBySupervisor || declineChangesBySupervisor;

  const showDeclineButton = useMemo(() => {
    return (isMeDirector && directorCondition) || ((isMeSupervisor || isMeAdmin) && supervisorCondition);
  }, [directorCondition, isMeAdmin, isMeDirector, isMeSupervisor, supervisorCondition]);

  const requestStatus = useRequestStatus({ status: [ZayavkaStage.New] });
  const declineButtonText = useMemo(() => {
    if (requestStatus) {
      return 'Отклонить';
    }
    if (declineChangesByDirector || declineChangesBySupervisor) {
      return 'Отклонить изменения';
    }
    return 'Отклонить';
  }, [declineChangesByDirector, declineChangesBySupervisor, requestStatus]);

  const declineButtonHandler = useCallback(() => {
    if (!request) return;
    const variables = {
      variables: {
        id: request.id,
      },
    };
    if (isMeDirector) {
      if (declineCancelByDirector) {
        return declineCancelRequestByDirector(variables)
          .then(() => {
            successToast('Изменения отклонены');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      if (declineChangesByDirector) {
        return declineChangesByDirectorMutation(variables)
          .then(() => {
            successToast('Изменения отклонены');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      return declineRequestByDirector(variables)
        .then(() => {
          successToast('Заявка отклонена');
        })
        .catch(error => errorToast(errorHandler(error)));
    }
    if (isMeSupervisor || isMeAdmin) {
      if (declineChangesBySupervisor) {
        return declineChangesBySupervisorMutation(variables)
          .then(() => {
            successToast('Изменения отклонены');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      return declineRequestBySupervisor(variables)
        .then(() => {
          successToast('Заявка отклонена');
        })
        .catch(error => errorToast(errorHandler(error)));
    }
  }, [
    declineCancelByDirector,
    declineCancelRequestByDirector,
    declineChangesByDirector,
    declineChangesByDirectorMutation,
    declineChangesBySupervisor,
    declineChangesBySupervisorMutation,
    declineRequestByDirector,
    declineRequestBySupervisor,
    isMeAdmin,
    isMeDirector,
    isMeSupervisor,
    request,
  ]);

  return { showDeclineButton, declineButtonText, declineButtonHandler, loading };
};
