import Plus from 'assets/icons/plus';
import { useCreateStoryMutation } from 'pages/stories/api/mutations/generated/CreateStory';
import { Link, useParams } from 'react-router-dom';
import { getImagePath } from 'utils/helpers/get/getImagePath';
import { getStoryStatus } from 'utils/helpers/get/getStoryStatus';
import clsx from 'clsx';

interface Props {
  stories?: any[];
  draftId?: string;
}

const Story = ({ draftId, stories }: Props) => {
  const [createStory] = useCreateStoryMutation();
  const { settingId } = useParams();

  const addStory = async (event: any) => {
    event.preventDefault();

    const id = await createStory({
      variables: { input: { referralProgramId: settingId ? settingId : draftId ? draftId : '' } },
    }).then(({ data }) => data?.createStory.id);

    if (id) {
      window.open(`/stories/${id}`, '_blank');
    }
  };

  return (
    <>
      <span className="Subtitle2 text-smena_text-secondary">История</span>

      <div className="grid grid-cols-2 gap-10 overflow-hidden">
        <button
          className="flex justify-center items-center gap-3 w-full border-[1px] border-dashed border-primary rounded-[8px] text-primary min-h-[100px]"
          onClick={addStory}
        >
          <span className="inline-flex">
            <Plus />
          </span>
          Добавить историю
        </button>

        {stories?.map(story => {
          const { name, className } = getStoryStatus(story.stage);

          return (
            <Link
              key={story.id}
              className="flex items-start gap-8 p-5 border-1.5 rounded-[10px] w-full cursor-pointer"
              to={`/stories/${story.id}`}
            >
              <div
                className={`w-20 h-full bg-cover bg-no-repeat bg-center rounded-lg`}
                style={
                  story.icon
                    ? {
                        backgroundImage: `url(${getImagePath(story.icon?.path)}`,
                      }
                    : {
                        backgroundColor: story.iconBackgroundColor,
                      }
                }
              />

              <div className="flex flex-col">
                <div className="H4 mb-1 text-ellipsis overflow-hidden text-nowrap">
                  {story.iconTitle || story.title}
                </div>
                <div className="Subtitle2 text-smena_text-secondary mb-1">
                  Кол-во слайдов: <span className="text-smena_text">{story.viewsCount}</span>
                </div>
                <div className={clsx(className, `text-center rounded-[10px] w-[180px]`)}>{name}</div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default Story;
