import React from 'react';

import MainLayout from 'components/layout/main';
import UsersFilters from 'pages/users/components/ourUsers/UsersFilters';
import CustomPagination from 'ui/pagination';
import NewTable from 'components/table/newTable';
import { useUsersTable } from 'components/table/columns/users';
import { useUsersHeaderMenu } from 'utils/hooks/breadcrumbs/useUsersBreadcrumbs';
import { useUsersPaginated } from 'utils/api/useUsersPaginated';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';

const OurUsersPage = () => {
  const { userPaginated, users, loadingUsers, error, page } = useUsersPaginated(true);
  const headerMenu = useUsersHeaderMenu();
  const table = useUsersTable(users);

  return (
    <MainLayout
      title="Пользователи"
      breadCrumbs={[{ path: '/users', name: 'Пользователи' }]}
      headerMenu={headerMenu}
      error={error}
    >
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <UsersFilters table={table} />
        <NewTable<UsersPaginatedFragment>
          table={table}
          length={users.length}
          loading={loadingUsers}
          headerMenu={true}
        />
        {userPaginated && <CustomPagination total={userPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default OurUsersPage;
