import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Plus from 'assets/icons/plus';
import { getActive } from 'utils/helpers/get/getActive';
import Filter from 'components/table/filters/filter';
import { getDayNightRu } from 'utils/helpers/get/getDayNightRu';
import MultiSelect from 'ui/multiSelect';
import SimpleSelect from 'ui/select';
import { activeOptions, periodOptions } from 'utils/helpers/constVariables';
import TableFilter from 'components/table/filters';
import { useCustomSearchParams } from '../../users/lib/useParams';
import Search from 'ui/search';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { Table } from '@tanstack/react-table';
import { VacancyFragment } from 'pages/vacancy/api/fragments/generated/Vacancy.fragment';
import ColumnSettings from 'components/table/columnSettings';

interface Props {
  table: Table<VacancyFragment>;
}

const FacilityVacanciesFilter = ({ table }: Props) => {
  const { params, setParams, useParamsCount, handleParams, resetFilter } = useCustomSearchParams();
  const { scheduleTypes } = useGetScheduleTypes();
  const periodParam = params.get('period');
  const scheduleParam = params.get('schedule');
  const activeParam = params.get('active');

  const [period, setPeriod] = useState(periodParam);
  const [schedule, setSchedule] = useState(scheduleParam ? scheduleParam.split(', ') : []);
  const [isActive, setIsActive] = useState(activeParam);
  const [showFilter, setShowFilter] = useState(false);

  const btnRef = useRef<HTMLButtonElement>(null);
  const paramsCount = useParamsCount();

  const options = useMemo(() => {
    return scheduleTypes.map(el => ({ label: el.name, value: el.id }));
  }, [scheduleTypes]);

  return (
    <div className="filter-bar justify-between flex xs:flex-row flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <div className={'flex relative gap-x-2'}>
          <Search />
          <button
            type="button"
            className="btn-stroke flex gap-x-1 items-center"
            ref={btnRef}
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <ColumnSettings<VacancyFragment> table={table} />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              divClassName=""
              label="День/Ночь"
              onChange={value => setPeriod(value)}
              value={period}
              allOption={'Все периоды'}
              placeholder={'Не выбрано'}
              options={periodOptions}
            />

            <MultiSelect
              divClassName={'w-full'}
              value={schedule}
              selectClassName={'w-full'}
              placeholder={'Выберите график'}
              label={'График'}
              name={'schedule'}
              options={[{ label: 'Все графики', value: 'all' }, ...options]}
              onChange={value => {
                if (value[value.length - 1] === 'all') {
                  return setSchedule(schedule.length === scheduleTypes.length ? [] : scheduleTypes.map(el => el.id));
                }
                setSchedule(value);
              }}
            />

            <SimpleSelect
              divClassName=""
              label="Статус активности"
              onChange={value => {
                setIsActive(value);
              }}
              value={isActive}
              allOption="Все"
              placeholder="Не выбрано"
              options={activeOptions}
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('schedule', schedule.join(', '));
                  handleParams('period', period);
                  handleParams('active', isActive);
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke"
                onClick={() => {
                  setPeriod(null);
                  setIsActive(null);
                  setSchedule([]);
                  setParams();
                  setShowFilter(false);
                }}
              >
                <span>Сбросить</span>
              </button>
            </div>
          </TableFilter>
        </div>
        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!periodParam}
              content={getDayNightRu(periodParam)}
              clickHandler={() => {
                setPeriod(null);
                resetFilter('period');
              }}
            />

            <Filter
              show={!!scheduleParam}
              content={scheduleParam ?? ''}
              clickHandler={() => {
                setSchedule([]);
                resetFilter('schedule');
              }}
            />

            <Filter
              show={!!activeParam}
              content={getActive(activeParam)}
              clickHandler={() => {
                setIsActive(null);
                resetFilter('active');
              }}
            />
          </div>
        )}
      </div>
      <div className="flex self-start">
        <Link to={`create`}>
          <button type="button" className="btn-primary_big">
            <span className="flex items-center">
              <span className="inline-flex mr-2">
                <Plus className="text-smena_white" />
              </span>
              <span>Новая вакансия</span>
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default FacilityVacanciesFilter;
