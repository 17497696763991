import clsx from 'clsx';
import React from 'react';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

const CustomLink = React.memo(({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      to={to}
      className={clsx(match ? 'menu__element menu__element-active' : 'menu__element text-smena_text-secondary')}
      {...props}
    >
      {children}
    </Link>
  );
});

export default CustomLink;
