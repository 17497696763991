import * as Types from '../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { NotificationFragmentDoc } from '../../fragments/generated/Notification.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkAsNewNotificationMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type MarkAsNewNotificationMutation = { __typename?: 'Mutation', markAsNewNotification: { __typename?: 'Notification', createdAt: any, appType: Types.AppType, body: string, id: string, isRead: boolean, route: string, routeId: string, success: number, title: string, userId: string } };


export const MarkAsNewNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MarkAsNewNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"markAsNewNotification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Notification"}}]}}]}},...NotificationFragmentDoc.definitions]} as unknown as DocumentNode;
export type MarkAsNewNotificationMutationFn = Apollo.MutationFunction<MarkAsNewNotificationMutation, MarkAsNewNotificationMutationVariables>;

/**
 * __useMarkAsNewNotificationMutation__
 *
 * To run a mutation, you first call `useMarkAsNewNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsNewNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsNewNotificationMutation, { data, loading, error }] = useMarkAsNewNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAsNewNotificationMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsNewNotificationMutation, MarkAsNewNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsNewNotificationMutation, MarkAsNewNotificationMutationVariables>(MarkAsNewNotificationDocument, options);
      }
export type MarkAsNewNotificationMutationHookResult = ReturnType<typeof useMarkAsNewNotificationMutation>;
export type MarkAsNewNotificationMutationResult = Apollo.MutationResult<MarkAsNewNotificationMutation>;
export type MarkAsNewNotificationMutationOptions = Apollo.BaseMutationOptions<MarkAsNewNotificationMutation, MarkAsNewNotificationMutationVariables>;