import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ShiftFragmentDoc } from '../../fragments/generated/Shift.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditShiftMutationVariables = Types.Exact<{
  input: Types.EditShiftInput;
}>;


export type EditShiftMutation = { __typename?: 'Mutation', editShift: { __typename?: 'Shift', id: string, dateFrom: any, dateEnd: any, comment?: string | null, duration: string, lunchDuration?: number | null, facilityId: string, hourRate?: number | null, positionId: string, stage: Types.ShiftStage, updatedAt: any, userId: string, user?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null } | null } };


export const EditShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditShiftInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Shift"}}]}}]}},...ShiftFragmentDoc.definitions]} as unknown as DocumentNode;
export type EditShiftMutationFn = Apollo.MutationFunction<EditShiftMutation, EditShiftMutationVariables>;

/**
 * __useEditShiftMutation__
 *
 * To run a mutation, you first call `useEditShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShiftMutation, { data, loading, error }] = useEditShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShiftMutation(baseOptions?: Apollo.MutationHookOptions<EditShiftMutation, EditShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShiftMutation, EditShiftMutationVariables>(EditShiftDocument, options);
      }
export type EditShiftMutationHookResult = ReturnType<typeof useEditShiftMutation>;
export type EditShiftMutationResult = Apollo.MutationResult<EditShiftMutation>;
export type EditShiftMutationOptions = Apollo.BaseMutationOptions<EditShiftMutation, EditShiftMutationVariables>;