import React, { useState } from 'react';
import { Formik } from 'formik';

import { errorHandler } from 'utils/helpers/errorHandler';
import { months } from 'utils/helpers/lists/months';
import { errorToast } from 'utils/helpers/notify';
import TimesheetLoader from 'pages/timesheets/ui/Loader';
import SimpleSelect from 'ui/select';
import { useCreatePollsReportMutation } from '../api/mutations/generated/CreatePollsReport';

const PollsReport = () => {
  const [resLink, setResLink] = useState('');

  const [createPollsReport, { loading }] = useCreatePollsReportMutation();

  return (
    <div className="xs:col-span-5 xs:col-start-1 col-span-full h-fit p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
      <span className="Subtitle1 text-smena_text-secondary">Новые пользователи, отклики, подписки</span>
      <Formik
        initialValues={{
          month: 0,
        }}
        onSubmit={values => {
          createPollsReport({
            variables: {
              input: {
                month: Number(values.month),
              },
            },
          })
            .then(response => {
              if (response.data?.createPollsReport) {
                const filePath = response.data.createPollsReport.filePath;
                const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
                setResLink(url);
              }
            })
            .catch(e => {
              errorToast(errorHandler(e));
            });
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="flex xs:flex-row flex-col xs:items-end gap-x-5 gap-y-3 flex-wrap">
              <SimpleSelect
                divClassName="w-[180px]"
                label="Месяц"
                onChange={value => {
                  setFieldValue('month', Number(value));
                  setResLink('');
                }}
                value={values.month}
                sort={false}
                options={months}
              />
              <div className="">
                <button type="submit" disabled={Boolean(resLink) || loading} className="btn-secondary">
                  Сформировать
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
      <TimesheetLoader loading={loading} resLink={resLink} />
    </div>
  );
};

export default PollsReport;
