import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityGroupFragmentDoc } from '../../../../facilityGroup/api/fragments/generated/FacilityGroup.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDirectorOrManagerOrSupervisorGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDirectorOrManagerOrSupervisorGroupsQuery = { __typename?: 'Query', getDirectorOrManagerOrSupervisorGroups: Array<{ __typename?: 'FacilityGroup', id: string, name: string, balance: number, logo?: { __typename?: 'File', id: string, mimetype: string, originalName?: string | null, path: string, size: number } | null, clientDirectors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null, groupManagers?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null }> };


export const GetDirectorOrManagerOrSupervisorGroupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDirectorOrManagerOrSupervisorGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getDirectorOrManagerOrSupervisorGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityGroup"}}]}}]}},...FacilityGroupFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetDirectorOrManagerOrSupervisorGroupsQuery__
 *
 * To run a query within a React component, call `useGetDirectorOrManagerOrSupervisorGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectorOrManagerOrSupervisorGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectorOrManagerOrSupervisorGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDirectorOrManagerOrSupervisorGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetDirectorOrManagerOrSupervisorGroupsQuery, GetDirectorOrManagerOrSupervisorGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectorOrManagerOrSupervisorGroupsQuery, GetDirectorOrManagerOrSupervisorGroupsQueryVariables>(GetDirectorOrManagerOrSupervisorGroupsDocument, options);
      }
export function useGetDirectorOrManagerOrSupervisorGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectorOrManagerOrSupervisorGroupsQuery, GetDirectorOrManagerOrSupervisorGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectorOrManagerOrSupervisorGroupsQuery, GetDirectorOrManagerOrSupervisorGroupsQueryVariables>(GetDirectorOrManagerOrSupervisorGroupsDocument, options);
        }
export function useGetDirectorOrManagerOrSupervisorGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDirectorOrManagerOrSupervisorGroupsQuery, GetDirectorOrManagerOrSupervisorGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDirectorOrManagerOrSupervisorGroupsQuery, GetDirectorOrManagerOrSupervisorGroupsQueryVariables>(GetDirectorOrManagerOrSupervisorGroupsDocument, options);
        }
export type GetDirectorOrManagerOrSupervisorGroupsQueryHookResult = ReturnType<typeof useGetDirectorOrManagerOrSupervisorGroupsQuery>;
export type GetDirectorOrManagerOrSupervisorGroupsLazyQueryHookResult = ReturnType<typeof useGetDirectorOrManagerOrSupervisorGroupsLazyQuery>;
export type GetDirectorOrManagerOrSupervisorGroupsSuspenseQueryHookResult = ReturnType<typeof useGetDirectorOrManagerOrSupervisorGroupsSuspenseQuery>;
export type GetDirectorOrManagerOrSupervisorGroupsQueryResult = Apollo.QueryResult<GetDirectorOrManagerOrSupervisorGroupsQuery, GetDirectorOrManagerOrSupervisorGroupsQueryVariables>;