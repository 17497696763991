import React from 'react';
import SimpleInput from 'ui/input';
import { erpReg } from 'utils/helpers/constVariables';
import { FormikHandlers } from 'formik';

interface Props {
  values: any;
  handleChange: FormikHandlers['handleChange'];
}

const Erp = ({ values, handleChange }: Props) => {
  return (
    <div className={'flex flex-col'}>
      <hr className={'bg-smena_gray-30 mb-6'} />
      <span className={'Subtitle1 text-smena_text-secondary mb-2'}>Альтернативный идентификаторы для отклика</span>
      <span className={'Body2 text-smena_text-secondary mb-4'}>
        Укажите, если это необходимо, альтернативные ID источника и группы в ERP для отправки отклика. Если поля
        оставить пустыми, отклик отправится в источник и группу по умолчанию
      </span>
      <div className={'grid grid-cols-3 gap-x-6'}>
        <SimpleInput
          divClassName="col-span-1"
          label="ERP ID источника"
          placeholder="Введите ID источника"
          onChange={e => {
            const value = e.target.value;
            if (erpReg.test(value) || value === '') {
              handleChange(e);
            }
          }}
          value={values.erpSourceId}
          type="text"
          name="erpSourceId"
        />
        <SimpleInput
          divClassName="col-span-1"
          label="ERP ID группы"
          placeholder="Введите ID группы"
          onChange={e => {
            const value = e.target.value;
            if (erpReg.test(value) || value === '') {
              handleChange(e);
            }
          }}
          value={values.erpGroupId}
          type="text"
          name="erpGroupId"
        />
      </div>
    </div>
  );
};

export default Erp;
