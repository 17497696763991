import dayjs from 'dayjs';
import dateHour from 'pages/userSchedule/components/sidebar/newSchedule/helpers/dateHour';
import { DayjsOrNull } from 'interfaces/CustomTypes';

interface Props {
  date: DayjsOrNull;
  endTime: DayjsOrNull;
  startTime: DayjsOrNull;
}

export const dateEndFn = ({ date, endTime, startTime }: Props) => {
  if (endTime?.isBefore(startTime)) {
    return dayjs(dateHour(date, endTime)).add(1, 'day');
  }
  return dateHour(date, endTime);
};
