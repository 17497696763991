import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddSupervisorToFacilityMutationVariables = Types.Exact<{
  facilityId: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type AddSupervisorToFacilityMutation = { __typename?: 'Mutation', addSupervisorToFacility: { __typename?: 'User', id: string, lastname: string, firstname: string, patronymic?: string | null, role: Types.Role } };


export const AddSupervisorToFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddSupervisorToFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addSupervisorToFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastname"}},{"kind":"Field","name":{"kind":"Name","value":"firstname"}},{"kind":"Field","name":{"kind":"Name","value":"patronymic"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode;
export type AddSupervisorToFacilityMutationFn = Apollo.MutationFunction<AddSupervisorToFacilityMutation, AddSupervisorToFacilityMutationVariables>;

/**
 * __useAddSupervisorToFacilityMutation__
 *
 * To run a mutation, you first call `useAddSupervisorToFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupervisorToFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupervisorToFacilityMutation, { data, loading, error }] = useAddSupervisorToFacilityMutation({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddSupervisorToFacilityMutation(baseOptions?: Apollo.MutationHookOptions<AddSupervisorToFacilityMutation, AddSupervisorToFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupervisorToFacilityMutation, AddSupervisorToFacilityMutationVariables>(AddSupervisorToFacilityDocument, options);
      }
export type AddSupervisorToFacilityMutationHookResult = ReturnType<typeof useAddSupervisorToFacilityMutation>;
export type AddSupervisorToFacilityMutationResult = Apollo.MutationResult<AddSupervisorToFacilityMutation>;
export type AddSupervisorToFacilityMutationOptions = Apollo.BaseMutationOptions<AddSupervisorToFacilityMutation, AddSupervisorToFacilityMutationVariables>;