import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleSelect from 'ui/select';
import Filter from 'components/table/filters/filter';
import Plus from 'assets/icons/plus';
import clsx from 'clsx';
import { requestsStatuses } from 'utils/helpers/lists/requestsStatuses';
import { ISO_DATE, RU_DATE } from 'utils/helpers/constVariables';
import RangePickerInput from 'ui/pickers/RangePickerInput';
import { DayOrNullArray } from 'interfaces/CustomTypes';
import dayjs from 'dayjs';
import { getStatusRu } from 'utils/helpers/get/getStatusRu';
import TableFilter from 'components/table/filters';
import { useMe } from 'utils/api/useMe';
import { useCustomSearchParams } from '../../users/lib/useParams';
import Search from 'ui/search';
import { Table } from '@tanstack/react-table';
import ColumnSettings from 'components/table/columnSettings';
import { PaginatedRequestFragment } from '../api/fragments/generated/PaginatedRequest.fragment';

interface Props {
  table: Table<PaginatedRequestFragment>;
}

const RequestsFacilityFilter = ({ table }: Props) => {
  const [value, setValue] = useState<DayOrNullArray>(null);
  const me = useMe();
  const { params, setParams, useParamsCount, handleParams, resetFilter } = useCustomSearchParams();
  const statusParams = params.get('status');
  const dateFrom = params.get('dateFrom');
  const dateTo = params.get('dateTo');
  const [status, setStatus] = useState(statusParams);
  const [showFilter, setShowFilter] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const paramsCount = useParamsCount();

  useEffect(() => {
    if (params.has('dateFrom') && params.has('dateTo')) {
      setValue([dayjs(dateFrom), dayjs(dateTo)]);
      setParams(params);
    } else {
      setValue(null);
    }
  }, [dateFrom, dateTo, params, setParams, setValue]);

  return (
    <div className="filter-bar justify-between flex xs:flex-row flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <div className={clsx('flex relative gap-x-2')}>
          <Search />
          <button
            type="button"
            ref={btnRef}
            className="btn-stroke flex gap-x-1 items-center"
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {paramsCount}
              </span>
            )}
          </button>
          <ColumnSettings<PaginatedRequestFragment> table={table} />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <RangePickerInput
              label="Период"
              value={value && [value[0], value[1]]}
              onChange={newValue => {
                setValue(newValue);
              }}
            />

            <SimpleSelect
              divClassName=""
              label="Статус"
              onChange={value => {
                setStatus(value);
              }}
              value={status}
              placeholder={'Не выбрано'}
              allOption={'Все'}
              options={requestsStatuses}
            />
            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('status', status);
                  if (value) {
                    params.set('dateFrom', String(dayjs(value[0]).format(ISO_DATE)));
                    params.set('dateTo', String(dayjs(value[1]).format(ISO_DATE)));
                  } else {
                    if (params.has('dateFrom') && params.has('dateTo')) {
                      params.delete('dateFrom');
                      params.delete('dateTo');
                    }
                  }
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                Применить
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setValue(null);
                  setStatus(null);
                  setParams();
                  setShowFilter(false);
                }}
              >
                Сбросить
              </button>
            </div>
          </TableFilter>
        </div>
        {Boolean(paramsCount) && (
          <div className="flex">
            <Filter
              show={!!(dateFrom || dateTo)}
              content={dayjs(dateFrom).format(RU_DATE) + ' - ' + dayjs(dateTo).format(RU_DATE)}
              clickHandler={() => {
                setValue(null);
                params.delete('dateFrom');
                params.delete('dateTo');
                params.delete('page');
                setParams(params);
              }}
            />
            <Filter
              show={!!statusParams}
              content={getStatusRu(status, me?.role)}
              clickHandler={() => {
                setStatus(null);
                resetFilter('status');
              }}
            />
          </div>
        )}
      </div>
      <div className="flex self-start">
        <Link to="create">
          <button type="button" className="btn-primary_big flex items-center gap-x-2">
            <span className="inline-flex">
              <Plus className="text-smena_white" />
            </span>
            Новая заявка
          </button>
        </Link>
      </div>
    </div>
  );
};

export default RequestsFacilityFilter;
