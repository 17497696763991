import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SupervisorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SupervisorsQuery = { __typename?: 'Query', supervisors: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string }> };


export const SupervisorsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Supervisors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"supervisors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstname"}},{"kind":"Field","name":{"kind":"Name","value":"lastname"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useSupervisorsQuery__
 *
 * To run a query within a React component, call `useSupervisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupervisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupervisorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupervisorsQuery(baseOptions?: Apollo.QueryHookOptions<SupervisorsQuery, SupervisorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupervisorsQuery, SupervisorsQueryVariables>(SupervisorsDocument, options);
      }
export function useSupervisorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupervisorsQuery, SupervisorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupervisorsQuery, SupervisorsQueryVariables>(SupervisorsDocument, options);
        }
export function useSupervisorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SupervisorsQuery, SupervisorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupervisorsQuery, SupervisorsQueryVariables>(SupervisorsDocument, options);
        }
export type SupervisorsQueryHookResult = ReturnType<typeof useSupervisorsQuery>;
export type SupervisorsLazyQueryHookResult = ReturnType<typeof useSupervisorsLazyQuery>;
export type SupervisorsSuspenseQueryHookResult = ReturnType<typeof useSupervisorsSuspenseQuery>;
export type SupervisorsQueryResult = Apollo.QueryResult<SupervisorsQuery, SupervisorsQueryVariables>;