import { Link } from 'react-router-dom';
import Search from 'ui/search';
import Plus from 'assets/icons/plus';
import TableFilter from 'components/table/filters';
import { useMemo, useRef, useState } from 'react';
import SimpleSelect from 'ui/select';
import { useCustomSearchParams } from 'pages/users/lib/useParams';
import { useFacilityById } from 'utils/api/useFacility';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import { referralProgramStatusOptions } from 'utils/helpers/constVariables';
import Filter from 'components/table/filters/filter';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';
import { getReferralProgramStatus } from 'utils/helpers/get/getReferralProgramStatus';

const ReferralProgramsFilters = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const [showFilter, setShowFilter] = useState(false);

  const { params, handleParams, setParams, resetFilter } = useCustomSearchParams();

  const statusParam = params.get('status');
  const facilityGroupIdParam = params.get('facilityGroupId');
  const facilityIdParam = params.get('facilityId');

  const [status, setStatus] = useState(statusParam);
  const [facilityGroupId, setFacilityGroupId] = useState(facilityGroupIdParam);
  const [facilityId, setFacilityId] = useState(facilityIdParam);
  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);
  const { facilities } = useSelectFacilities();
  const { facility } = useFacilityById(facilityIdParam);
  const { facilitiesGroups } = useSelectFacilityGroups();
  const filteredFacilities = useMemo(
    () => filterFacilities(facilityGroupId, facilities),
    [facilityGroupId, facilities],
  );

  const handleFilterVisible = () => {
    setShowFilter(prevState => !prevState);
  };

  const handleFilterSubmit = () => {
    handleParams('status', status);
    handleParams('facilityGroupId', facilityGroupId);
    handleParams('facilityId', facilityId);
    setParams(params);
    setShowFilter(false);
  };

  const handleResetFilter = () => {
    setStatus(null);
    setFacilityGroupId(null);
    setFacilityId(null);
    setShowFilter(false);
    setParams();
  };

  let paramsCount = 0;

  const paramCounter = (name: string) => {
    if (params.has(name)) {
      return paramsCount++;
    }
  };

  paramCounter('status');
  paramCounter('facilityGroupId');
  paramCounter('facilityId');

  return (
    <div className="filter-bar flex justify-between">
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-2 self-center relative">
          <Search />
          <button
            type="button"
            ref={btnRef}
            onClick={handleFilterVisible}
            className="btn-stroke flex gap-x-1 items-center"
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {paramsCount}
              </span>
            )}
          </button>

          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              label="Статус"
              onChange={value => {
                setStatus(value);
              }}
              value={status}
              placeholder="Не выбрано"
              allOption="Все"
              options={referralProgramStatusOptions}
            />
            <SimpleSelect
              label="Группа"
              onChange={value => {
                setFacilityGroupId(value);
              }}
              value={facilityGroupId}
              placeholder="Не выбрано"
              allOption="Все"
              options={facilitiesGroups}
            />
            <SimpleSelect
              label="Объект"
              onChange={value => {
                setFacilityId(value);
              }}
              value={facilityId}
              placeholder="Не выбрано"
              allOption="Все"
              options={filteredFacilities}
            />
            <div className="flex gap-x-5">
              <button className="btn-primary" onClick={handleFilterSubmit}>
                Применить
              </button>
              <button className="btn-stroke xs:w-auto w-full" onClick={handleResetFilter}>
                Сбросить
              </button>
            </div>
          </TableFilter>
        </div>

        {Boolean(paramsCount) && (
          <div className="flex gap-2">
            <Filter
              show={!!statusParam}
              content={getReferralProgramStatus(status)}
              clickHandler={() => {
                setStatus(null);
                resetFilter('status');
              }}
            />
            <Filter
              show={!!facilityGroupIdParam}
              content={facilityGroupName}
              clickHandler={() => {
                setFacilityGroupId(null);
                resetFilter('facilityGroupId');
              }}
            />
            <Filter
              show={!!facilityIdParam}
              content={facility?.name}
              clickHandler={() => {
                setFacilityId(null);
                resetFilter('facilityId');
              }}
            />
          </div>
        )}
      </div>
      <Link className="self-center" to="/offers/referrals/create">
        <button className="btn-primary flex items-center gap-x-2">
          <Plus className="text-smena_white" />
          Новая реферальная программа
        </button>
      </Link>
    </div>
  );
};

export default ReferralProgramsFilters;
