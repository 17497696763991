import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserNameAndCitizenshipMutationVariables = Types.Exact<{
  input: Types.UpdateUserNameAndCitizenshipInput;
}>;


export type UpdateUserNameAndCitizenshipMutation = { __typename?: 'Mutation', updateUserNameAndCitizenship: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null, citizenshipId?: string | null } };


export const UpdateUserNameAndCitizenshipDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserNameAndCitizenship"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserNameAndCitizenshipInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserNameAndCitizenship"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstname"}},{"kind":"Field","name":{"kind":"Name","value":"lastname"}},{"kind":"Field","name":{"kind":"Name","value":"patronymic"}},{"kind":"Field","name":{"kind":"Name","value":"citizenshipId"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateUserNameAndCitizenshipMutationFn = Apollo.MutationFunction<UpdateUserNameAndCitizenshipMutation, UpdateUserNameAndCitizenshipMutationVariables>;

/**
 * __useUpdateUserNameAndCitizenshipMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameAndCitizenshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameAndCitizenshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameAndCitizenshipMutation, { data, loading, error }] = useUpdateUserNameAndCitizenshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNameAndCitizenshipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNameAndCitizenshipMutation, UpdateUserNameAndCitizenshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserNameAndCitizenshipMutation, UpdateUserNameAndCitizenshipMutationVariables>(UpdateUserNameAndCitizenshipDocument, options);
      }
export type UpdateUserNameAndCitizenshipMutationHookResult = ReturnType<typeof useUpdateUserNameAndCitizenshipMutation>;
export type UpdateUserNameAndCitizenshipMutationResult = Apollo.MutationResult<UpdateUserNameAndCitizenshipMutation>;
export type UpdateUserNameAndCitizenshipMutationOptions = Apollo.BaseMutationOptions<UpdateUserNameAndCitizenshipMutation, UpdateUserNameAndCitizenshipMutationVariables>;