import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ModerationReportFragmentDoc } from '../../fragments/generated/ModerationReport.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateModerationReportMutationVariables = Types.Exact<{
  input: Types.CreateModerationReportInput;
}>;


export type CreateModerationReportMutation = { __typename?: 'Mutation', createModerationReport: { __typename?: 'ModerationReport', id: string, dateStart: any, dateEnd: any, isImportFromFile?: boolean | null, noModerated?: number | null, shiftCount?: number | null, workersCount?: number | null, periodType: Types.PeriodType, status: Types.ModerationStatus, updatedAt: any, createdAt: any, facility?: { __typename?: 'Facility', id: string, name: string, facilityGroupId?: string | null, everythingIsPossibleBonus: boolean, penalty?: Array<{ __typename?: 'Penalty', id: string, cost: number, type: Types.PenaltyType }> | null } | null } };


export const CreateModerationReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateModerationReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateModerationReportInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createModerationReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ModerationReport"}}]}}]}},...ModerationReportFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateModerationReportMutationFn = Apollo.MutationFunction<CreateModerationReportMutation, CreateModerationReportMutationVariables>;

/**
 * __useCreateModerationReportMutation__
 *
 * To run a mutation, you first call `useCreateModerationReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModerationReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModerationReportMutation, { data, loading, error }] = useCreateModerationReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModerationReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateModerationReportMutation, CreateModerationReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModerationReportMutation, CreateModerationReportMutationVariables>(CreateModerationReportDocument, options);
      }
export type CreateModerationReportMutationHookResult = ReturnType<typeof useCreateModerationReportMutation>;
export type CreateModerationReportMutationResult = Apollo.MutationResult<CreateModerationReportMutation>;
export type CreateModerationReportMutationOptions = Apollo.BaseMutationOptions<CreateModerationReportMutation, CreateModerationReportMutationVariables>;