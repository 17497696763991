import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersOnMapByFacility2QueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UsersOnMapByFacility2Query = { __typename?: 'Query', usersOnMapByFacility2: Array<{ __typename?: 'MapPlaceModel', address?: string | null, city?: string | null, name: string, point?: string | null, shopName: string, positions?: Array<{ __typename?: 'MapProfessionModel', name: string, erp: { __typename?: 'MapMetrikaModel', in4km: number, in10km: number, inCity: number }, smena: { __typename?: 'MapMetrikaModel', in4km: number, in10km: number, inCity: number } }> | null }> };


export const UsersOnMapByFacility2Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UsersOnMapByFacility2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersOnMapByFacility2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"point"}},{"kind":"Field","name":{"kind":"Name","value":"shopName"}},{"kind":"Field","name":{"kind":"Name","value":"positions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"erp"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"in4km"}},{"kind":"Field","name":{"kind":"Name","value":"in10km"}},{"kind":"Field","name":{"kind":"Name","value":"inCity"}}]}},{"kind":"Field","name":{"kind":"Name","value":"smena"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"in4km"}},{"kind":"Field","name":{"kind":"Name","value":"in10km"}},{"kind":"Field","name":{"kind":"Name","value":"inCity"}}]}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUsersOnMapByFacility2Query__
 *
 * To run a query within a React component, call `useUsersOnMapByFacility2Query` and pass it any options that fit your needs.
 * When your component renders, `useUsersOnMapByFacility2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersOnMapByFacility2Query({
 *   variables: {
 *   },
 * });
 */
export function useUsersOnMapByFacility2Query(baseOptions?: Apollo.QueryHookOptions<UsersOnMapByFacility2Query, UsersOnMapByFacility2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersOnMapByFacility2Query, UsersOnMapByFacility2QueryVariables>(UsersOnMapByFacility2Document, options);
      }
export function useUsersOnMapByFacility2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersOnMapByFacility2Query, UsersOnMapByFacility2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersOnMapByFacility2Query, UsersOnMapByFacility2QueryVariables>(UsersOnMapByFacility2Document, options);
        }
export function useUsersOnMapByFacility2SuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersOnMapByFacility2Query, UsersOnMapByFacility2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersOnMapByFacility2Query, UsersOnMapByFacility2QueryVariables>(UsersOnMapByFacility2Document, options);
        }
export type UsersOnMapByFacility2QueryHookResult = ReturnType<typeof useUsersOnMapByFacility2Query>;
export type UsersOnMapByFacility2LazyQueryHookResult = ReturnType<typeof useUsersOnMapByFacility2LazyQuery>;
export type UsersOnMapByFacility2SuspenseQueryHookResult = ReturnType<typeof useUsersOnMapByFacility2SuspenseQuery>;
export type UsersOnMapByFacility2QueryResult = Apollo.QueryResult<UsersOnMapByFacility2Query, UsersOnMapByFacility2QueryVariables>;