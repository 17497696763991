import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientDirectorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientDirectorsQuery = { __typename?: 'Query', clientDirectors: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> };


export const ClientDirectorsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ClientDirectors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientDirectors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"firstname"}},{"kind":"Field","name":{"kind":"Name","value":"lastname"}},{"kind":"Field","name":{"kind":"Name","value":"patronymic"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useClientDirectorsQuery__
 *
 * To run a query within a React component, call `useClientDirectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDirectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDirectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientDirectorsQuery(baseOptions?: Apollo.QueryHookOptions<ClientDirectorsQuery, ClientDirectorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientDirectorsQuery, ClientDirectorsQueryVariables>(ClientDirectorsDocument, options);
      }
export function useClientDirectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientDirectorsQuery, ClientDirectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientDirectorsQuery, ClientDirectorsQueryVariables>(ClientDirectorsDocument, options);
        }
export function useClientDirectorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientDirectorsQuery, ClientDirectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientDirectorsQuery, ClientDirectorsQueryVariables>(ClientDirectorsDocument, options);
        }
export type ClientDirectorsQueryHookResult = ReturnType<typeof useClientDirectorsQuery>;
export type ClientDirectorsLazyQueryHookResult = ReturnType<typeof useClientDirectorsLazyQuery>;
export type ClientDirectorsSuspenseQueryHookResult = ReturnType<typeof useClientDirectorsSuspenseQuery>;
export type ClientDirectorsQueryResult = Apollo.QueryResult<ClientDirectorsQuery, ClientDirectorsQueryVariables>;