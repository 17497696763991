import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { NotifyLetterPaginatedFragmentDoc } from '../../fragments/generated/NotifyLetterPaginated.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotifyLettersPaginatedQueryVariables = Types.Exact<{
  input: Types.NotifyLettersPaginatedInput;
}>;


export type GetNotifyLettersPaginatedQuery = { __typename?: 'Query', getNotifyLettersPaginated: { __typename?: 'NotifyLettersPaginatedList', count: number, pages: number, notifyLetters: Array<{ __typename?: 'NotifyLetter', id: string, name: string, status: Types.NotifyLetterStatus, publicCondition?: { __typename?: 'PublicCondition', recipientsCount: number, publishFrom?: any | null, publishTo?: any | null, interval?: number | null, indefinitely: boolean } | null }> } };


export const GetNotifyLettersPaginatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNotifyLettersPaginated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NotifyLettersPaginatedInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getNotifyLettersPaginated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"notifyLetters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotifyLetterPaginated"}}]}}]}}]}},...NotifyLetterPaginatedFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetNotifyLettersPaginatedQuery__
 *
 * To run a query within a React component, call `useGetNotifyLettersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotifyLettersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotifyLettersPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNotifyLettersPaginatedQuery(baseOptions: Apollo.QueryHookOptions<GetNotifyLettersPaginatedQuery, GetNotifyLettersPaginatedQueryVariables> & ({ variables: GetNotifyLettersPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotifyLettersPaginatedQuery, GetNotifyLettersPaginatedQueryVariables>(GetNotifyLettersPaginatedDocument, options);
      }
export function useGetNotifyLettersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotifyLettersPaginatedQuery, GetNotifyLettersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotifyLettersPaginatedQuery, GetNotifyLettersPaginatedQueryVariables>(GetNotifyLettersPaginatedDocument, options);
        }
export function useGetNotifyLettersPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotifyLettersPaginatedQuery, GetNotifyLettersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotifyLettersPaginatedQuery, GetNotifyLettersPaginatedQueryVariables>(GetNotifyLettersPaginatedDocument, options);
        }
export type GetNotifyLettersPaginatedQueryHookResult = ReturnType<typeof useGetNotifyLettersPaginatedQuery>;
export type GetNotifyLettersPaginatedLazyQueryHookResult = ReturnType<typeof useGetNotifyLettersPaginatedLazyQuery>;
export type GetNotifyLettersPaginatedSuspenseQueryHookResult = ReturnType<typeof useGetNotifyLettersPaginatedSuspenseQuery>;
export type GetNotifyLettersPaginatedQueryResult = Apollo.QueryResult<GetNotifyLettersPaginatedQuery, GetNotifyLettersPaginatedQueryVariables>;