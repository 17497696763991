const TimePickerClock = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 3.5C5.18629 3.5 2.5 6.18629 2.5 9.5C2.5 12.8137 5.18629 15.5 8.5 15.5C11.8137 15.5 14.5 12.8137 14.5 9.5C14.5 6.18629 11.8137 3.5 8.5 3.5ZM1 9.5C1 5.35786 4.35786 2 8.5 2C12.6421 2 16 5.35786 16 9.5C16 13.6421 12.6421 17 8.5 17C4.35786 17 1 13.6421 1 9.5ZM8.5 5.75C8.91421 5.75 9.25 6.08579 9.25 6.5V9.18934L11.2803 11.2197C11.5732 11.5126 11.5732 11.9874 11.2803 12.2803C10.9874 12.5732 10.5126 12.5732 10.2197 12.2803L7.96967 10.0303C7.82902 9.88968 7.75 9.69891 7.75 9.5V6.5C7.75 6.08579 8.08579 5.75 8.5 5.75Z"
        fill="#1B1F3B"
        fillOpacity="0.4"
      />
    </svg>
  );
};

export default TimePickerClock;
