import { useMemo } from 'react';
import { useCurrentUser } from '../../api/useUser';
import { userNameSurname } from '../../helpers/userFullName';
import { useMeRole } from '../../../store/hooks/useMeRole';
import { Role } from 'generated/graphql';

const useUserBreadcrumbs = () => {
  const { user } = useCurrentUser();

  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  return useMemo(
    () => [
      {
        path: '/users',
        name: isMeAdminOrSupervisor ? 'Пользователи' : 'Мои исполнители',
      },
      user
        ? {
            path: '/users/' + user.id,
            name: `${userNameSurname(user)}`,
          }
        : {
            path: '/users/create',
            name: isMeAdminOrSupervisor ? 'Новый пользователь' : 'Новый исполнитель',
          },
    ],
    [isMeAdminOrSupervisor, user],
  );
};

export default useUserBreadcrumbs;
