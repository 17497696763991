import React from 'react';
import Logo from '../../ui/logo';

const Loading = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center bg-gray-02">
      <Logo />
    </div>
  );
};

export default Loading;
