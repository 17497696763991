import * as Types from '../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateManyReferralProgramsMutationVariables = Types.Exact<{
  input: Types.CreateManyReferralProgramsInput;
}>;


export type CreateManyReferralProgramsMutation = { __typename?: 'Mutation', createManyReferralPrograms: number };


export const CreateManyReferralProgramsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateManyReferralPrograms"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateManyReferralProgramsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createManyReferralPrograms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type CreateManyReferralProgramsMutationFn = Apollo.MutationFunction<CreateManyReferralProgramsMutation, CreateManyReferralProgramsMutationVariables>;

/**
 * __useCreateManyReferralProgramsMutation__
 *
 * To run a mutation, you first call `useCreateManyReferralProgramsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyReferralProgramsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyReferralProgramsMutation, { data, loading, error }] = useCreateManyReferralProgramsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyReferralProgramsMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyReferralProgramsMutation, CreateManyReferralProgramsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyReferralProgramsMutation, CreateManyReferralProgramsMutationVariables>(CreateManyReferralProgramsDocument, options);
      }
export type CreateManyReferralProgramsMutationHookResult = ReturnType<typeof useCreateManyReferralProgramsMutation>;
export type CreateManyReferralProgramsMutationResult = Apollo.MutationResult<CreateManyReferralProgramsMutation>;
export type CreateManyReferralProgramsMutationOptions = Apollo.BaseMutationOptions<CreateManyReferralProgramsMutation, CreateManyReferralProgramsMutationVariables>;