import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityGroupBalanceOperationFragmentDoc } from '../../fragments/generated/FacilityGroupBalanceOperation.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFacilityGroupOperationListQueryVariables = Types.Exact<{
  facilityGroupId: Types.Scalars['String']['input'];
}>;


export type GetFacilityGroupOperationListQuery = { __typename?: 'Query', getFacilityGroupOperationList: Array<{ __typename?: 'FacilityGroupBalanceOperation', id: string, amount: number, balanceAfter: number, balanceBefore: number, createdAt: any, dayAndTime: any, description: string, facilityGroupId: string, operation?: Types.BalanceOperationType | null }> };


export const GetFacilityGroupOperationListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFacilityGroupOperationList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getFacilityGroupOperationList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityGroupId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityGroupBalanceOperation"}}]}}]}},...FacilityGroupBalanceOperationFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetFacilityGroupOperationListQuery__
 *
 * To run a query within a React component, call `useGetFacilityGroupOperationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityGroupOperationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityGroupOperationListQuery({
 *   variables: {
 *      facilityGroupId: // value for 'facilityGroupId'
 *   },
 * });
 */
export function useGetFacilityGroupOperationListQuery(baseOptions: Apollo.QueryHookOptions<GetFacilityGroupOperationListQuery, GetFacilityGroupOperationListQueryVariables> & ({ variables: GetFacilityGroupOperationListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFacilityGroupOperationListQuery, GetFacilityGroupOperationListQueryVariables>(GetFacilityGroupOperationListDocument, options);
      }
export function useGetFacilityGroupOperationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFacilityGroupOperationListQuery, GetFacilityGroupOperationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFacilityGroupOperationListQuery, GetFacilityGroupOperationListQueryVariables>(GetFacilityGroupOperationListDocument, options);
        }
export function useGetFacilityGroupOperationListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFacilityGroupOperationListQuery, GetFacilityGroupOperationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFacilityGroupOperationListQuery, GetFacilityGroupOperationListQueryVariables>(GetFacilityGroupOperationListDocument, options);
        }
export type GetFacilityGroupOperationListQueryHookResult = ReturnType<typeof useGetFacilityGroupOperationListQuery>;
export type GetFacilityGroupOperationListLazyQueryHookResult = ReturnType<typeof useGetFacilityGroupOperationListLazyQuery>;
export type GetFacilityGroupOperationListSuspenseQueryHookResult = ReturnType<typeof useGetFacilityGroupOperationListSuspenseQuery>;
export type GetFacilityGroupOperationListQueryResult = Apollo.QueryResult<GetFacilityGroupOperationListQuery, GetFacilityGroupOperationListQueryVariables>;