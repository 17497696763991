import { ShiftStage } from 'generated/graphql';
import { useMemo } from 'react';
import { useStore } from 'store';
import dayjs from 'dayjs';
import { TIME_DATE } from 'utils/helpers/constVariables';
import { FacilityRequestShiftFragment } from 'pages/facilityRequests/api/fragments/generated/FacilityRequestShift.fragment';

interface Props {
  requestShift?: FacilityRequestShiftFragment | null;
}
interface Props2 extends Props {
  day?: dayjs.Dayjs;
  requestScheduleId?: string;
}

export const useBackgroundAndBorder = ({ requestShift }: Props) => {
  return useMemo(() => {
    if (requestShift?.shift) {
      if ([ShiftStage.Ineligible, ShiftStage.Refused, ShiftStage.Wasted].includes(requestShift.shift.stage)) {
        return {
          backgroundColor: 'bg-smena_red-extra_light',
          border: `border-smena_red`,
        };
      }
      if (requestShift.shift.isProbation) {
        return {
          backgroundColor: 'bg-smena_purple-light',
          border: `border-smena_purple`,
        };
      }
      switch (requestShift.shift.stage) {
        case ShiftStage.New:
          return {
            backgroundColor: 'bg-smena_orange-extra_light',
            border: `border-smena_orange`,
          };
        case ShiftStage.Accepted:
          return {
            backgroundColor: 'bg-smena_green-extra_light',
            border: `border-smena_green`,
          };
        default:
          return {
            backgroundColor: 'bg-smena_bb-background',
            border: `border-primary-light`,
          };
      }
    }
    if (requestShift?.requestShiftSuggestions.length) {
      return {
        backgroundColor: 'bg-smena_yellow-extra_light',
        border: `border-smena_yellow`,
      };
    }
    if (requestShift?.isFree) {
      return {
        backgroundColor: 'bg-primary-sky',
        border: `border-smena_bb-border_sky`,
      };
    }
    return {
      backgroundColor: 'bg-smena_bb-background',
      border: `border-primary-light`,
    };
  }, [requestShift]);
};

export const useCellClassName = ({ requestShift }: Props) => {
  const requestShiftStore = useStore(state => state.requestShift);

  const borderWidth = requestShiftStore?.id === requestShift?.id ? 'border-2' : 'border-0';

  const { border, backgroundColor } = useBackgroundAndBorder({ requestShift });

  return useMemo(() => {
    if (requestShift?.isDeleted) {
      return `${borderWidth} bg-smena_gray-5 line-through`;
    }
    if (requestShift?.newDateFrom && requestShift?.newDateEnd) {
      return `${borderWidth} bg-smena_bb-background text-primary`;
    }

    return `${borderWidth} ${backgroundColor} ${border}`;
  }, [backgroundColor, border, border, requestShift, borderWidth]);
};

export const useRequestShift = ({ requestShift, day }: Props2) => {
  const requestDayAtRow = useStore(state => state.requestDayAtRow);
  const cellClassName = useCellClassName({ requestShift });

  const isSameDay = dayjs(day).valueOf() === dayjs(requestDayAtRow?.day).valueOf();
  const isSameRequestScheduleId = requestDayAtRow?.requestScheduleId === requestShift?.zayavkaScheduleId;

  const emptyCellBorder = isSameDay && isSameRequestScheduleId ? 'border-2' : 'border-0.5';

  if (requestShift) {
    const isPastShift = requestShift.newDateFrom
      ? dayjs(requestShift.newDateFrom).valueOf() < dayjs().valueOf()
        ? ' opacity-60'
        : ''
      : dayjs(requestShift.dateFrom).valueOf() < dayjs().valueOf()
        ? ' opacity-60'
        : '';
    const className = cellClassName + isPastShift;

    return {
      requestShift,
      content:
        requestShift.newDateFrom && requestShift.newDateEnd
          ? dayjs(requestShift?.newDateFrom).format(TIME_DATE) +
            ' - ' +
            dayjs(requestShift?.newDateEnd).format(TIME_DATE)
          : dayjs(requestShift?.dateFrom).format(TIME_DATE) + ' - ' + dayjs(requestShift?.dateEnd).format(TIME_DATE),
      className,
    };
  }
  return {
    requestShift: null,
    content: null,
    className: emptyCellBorder + ' border-smena_gray-5 bg-smena_white',
  };
};
