import { isProd } from '../../utils/helpers/constVariables';
import * as Sentry from '@sentry/react';

export const initializeSentry = () => {
  if (isProd) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: false,
        }),
      ],
      tracesSampler: () => (isProd ? 1 : 0.2),
    });
  }
};
