import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ScheduleFragmentDoc } from '../../fragments/generated/Schedule.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeScheduleMutationVariables = Types.Exact<{
  input: Types.ChangeScheduleInput;
}>;


export type ChangeScheduleMutation = { __typename?: 'Mutation', changeSchedule: { __typename?: 'Schedule', id: string, createdAt: any, facilityId: string, updatedAt: any, userId: string, shifts?: Array<{ __typename?: 'Shift', id: string, dateFrom: any, dateEnd: any, duration: string, lunchDuration?: number | null, scheduleId?: string | null, comment?: string | null, authorId: string, stage: Types.ShiftStage, rejectedById?: string | null, positionId: string, userId: string, isProbation: boolean, zayavkaId?: string | null, schedule?: { __typename?: 'Schedule', id: string, scheduleTypeId?: string | null, dayInterleaving?: number | null, dateTo?: any | null, dateFrom?: any | null, isCustom: boolean } | null, marks: Array<{ __typename?: 'Mark', status: Types.MarkStatus, statusMessage: string, type: Types.MarkType, time: any, latitude?: string | null, longitude?: string | null, distance?: number | null }>, facility?: { __typename?: 'Facility', id: string, name: string, latitude: string, longitude: string, radius: number, facilityGroupId?: string | null } | null, zayavka?: { __typename?: 'Zayavka', number: number } | null }> | null } };


export const ChangeScheduleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeSchedule"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangeScheduleInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeSchedule"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Schedule"}}]}}]}},...ScheduleFragmentDoc.definitions]} as unknown as DocumentNode;
export type ChangeScheduleMutationFn = Apollo.MutationFunction<ChangeScheduleMutation, ChangeScheduleMutationVariables>;

/**
 * __useChangeScheduleMutation__
 *
 * To run a mutation, you first call `useChangeScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeScheduleMutation, { data, loading, error }] = useChangeScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeScheduleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeScheduleMutation, ChangeScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeScheduleMutation, ChangeScheduleMutationVariables>(ChangeScheduleDocument, options);
      }
export type ChangeScheduleMutationHookResult = ReturnType<typeof useChangeScheduleMutation>;
export type ChangeScheduleMutationResult = Apollo.MutationResult<ChangeScheduleMutation>;
export type ChangeScheduleMutationOptions = Apollo.BaseMutationOptions<ChangeScheduleMutation, ChangeScheduleMutationVariables>;