import clsx from 'clsx';
import { Form, Formik } from 'formik';
import SimpleInput from 'ui/input';

import { genders } from 'utils/helpers/lists/gendersList';
import { CLOTHING_FEMALE_SIZE, CLOTHING_MALE_SIZE } from 'utils/helpers/lists/сlothingSizeList';
import { FOOT_FEMALE_SIZE, FOOT_MALE_SIZE } from 'utils/helpers/lists/shoeSizeList';
import SimpleSelect from 'ui/select';
import { useMeRole } from 'store/hooks/useMeRole';
import { useQuestionnairePage } from '../hook/useQuestionnairePage';
import { Role, Sex } from 'generated/graphql';

const Questionnaire = () => {
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  const { validate, onSubmit, initialValues, loading } = useQuestionnairePage();

  return (
    <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ values, touched, errors, handleChange, setFieldValue }) => {
        const changeWithResetValues = (value: string) => {
          setFieldValue('sex', value);
          setFieldValue('clothingSize', undefined);
          setFieldValue('footSize', undefined);
        };

        return (
          <Form className="userPageBlock blockTemplate">
            <span className="blockTitleTemplate">Анкета</span>

            <div className={clsx(isMeAdminOrSupervisor ? 'flex flex-wrap' : 'grid grid-cols-2', 'gap-x-6 gap-y-4')}>
              {isMeAdminOrSupervisor ? (
                <SimpleSelect
                  divClassName="w-[180px]"
                  label="Пол"
                  onChange={changeWithResetValues}
                  value={values.sex}
                  placeholder="Не выбрано"
                  options={genders}
                />
              ) : (
                <div className="flex justify-between items-center flex-wrap">
                  <label className="Tag text-smena_text-secondary">Пол</label>
                  <span className="Body1 w-[251px]">{genders.find(gender => gender.id === values.sex)?.name}</span>
                </div>
              )}
              {isMeAdminOrSupervisor ? (
                <SimpleInput
                  divClassName="w-[180px]"
                  type="text"
                  regText
                  value={values.growth}
                  label="Рост (см)"
                  name="growth"
                  onChange={handleChange}
                  placeholder="165"
                  error={touched.growth && errors.growth ? errors.growth : ''}
                />
              ) : (
                <div className="flex justify-between items-center flex-wrap">
                  <label className="Tag text-smena_text-secondary">Рост (см)</label>
                  <span className="Body1 w-[251px]">{values.growth}</span>
                </div>
              )}
              {isMeAdminOrSupervisor ? (
                <SimpleSelect
                  divClassName="w-[180px]"
                  value={values.clothingSize === '' ? undefined : values.clothingSize}
                  label="Размер одежды"
                  onChange={value => {
                    setFieldValue('clothingSize', value);
                  }}
                  options={values.sex === Sex.Male ? CLOTHING_MALE_SIZE : CLOTHING_FEMALE_SIZE}
                  placeholder="Выберите размер одежды"
                  error={touched.clothingSize && errors.clothingSize ? errors.clothingSize : ''}
                />
              ) : (
                <div className="flex justify-between items-center flex-wrap">
                  <label className="Tag text-smena_text-secondary">Размер одежды</label>
                  <span className="Body1 w-[251px]">
                    {values.sex === Sex.Male
                      ? CLOTHING_MALE_SIZE.find(clothe => clothe.id === values.clothingSize)?.name
                      : CLOTHING_FEMALE_SIZE.find(clothe => clothe.id === values.clothingSize)?.name}
                  </span>
                </div>
              )}
              {isMeAdminOrSupervisor ? (
                <SimpleSelect
                  divClassName="w-[180px]"
                  value={values.footSize === 0 ? undefined : values.footSize}
                  label="Размер обуви"
                  onChange={value => {
                    setFieldValue('footSize', value);
                  }}
                  options={values.sex === Sex.Male ? FOOT_MALE_SIZE : FOOT_FEMALE_SIZE}
                  placeholder="Выберите размер обуви"
                  error={touched.footSize && errors.footSize ? errors.footSize : ''}
                />
              ) : (
                <div className="flex justify-between items-center flex-wrap">
                  <label className="Tag text-smena_text-secondary">Размер обуви</label>
                  <span className="Body1 w-[250px]">{values.footSize}</span>
                </div>
              )}
            </div>
            {isMeAdminOrSupervisor && (
              <div className="lg:col-span-6 md:col-span-10 col-span-full xs:col-start-1 flex justify-end">
                <button type="submit" className="btn-primary_big" disabled={loading}>
                  {loading ? 'Загрузка' : 'Сохранить'}
                </button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default Questionnaire;
