import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';
import { getReferralProgramStatusColor } from 'utils/helpers/get/getReferralProgramStatusColor';
import { Link } from 'react-router-dom';
import { getReferralProgramStatus } from 'utils/helpers/get/getReferralProgramStatus';

export const useReferralProgramsTable = (data: any[]) => {
  const columns: ColumnDef<any>[] = useMemo(() => {
    return [
      {
        id: 'name',
        header: 'Программа',
        size: 1300,
        minSize: 1300,
        cell: ({ row: { original } }) => (
          <div>
            <Link to={`/offers/leads/setting/${original.id}`} className="H4 mb-2">
              {original.name ? original.name : 'Без названия'}
            </Link>
            <div className="Body1 text-smena_text-secondary">{original.description}</div>
          </div>
        ),
      },
      {
        id: 'status',
        header: 'Статус',
        size: 300,
        minSize: 300,
        cell: ({ row: { original } }) => (
          <span
            className={`${getReferralProgramStatusColor(original.status)} rounded-2xl pr-3 pl-3 pt-0.5 pb-0.5 text-center`}
          >
            {getReferralProgramStatus(original.status)}
          </span>
        ),
      },
      {
        id: 'area',
        header: 'Область действия',
        size: 300,
        minSize: 300,
        cell: ({ row: { original } }) => (
          <div>
            {original.facilityGroup && (
              <Link to={`/groups/${original.facilityGroup?.id}`}>
                Группа: <span className="text-primary">{original.facilityGroup?.name}</span>
              </Link>
            )}

            {original.facility && (
              <Link to={`/facilities/${original.facility?.id}`}>
                Объект: <span className="text-primary">{original.facility?.name}</span>
              </Link>
            )}

            {!original.facilityGroup && !original.facility && 'Все группы и объекты'}
          </div>
        ),
      },
      {
        id: 'period',
        header: 'Период действия',
        size: 300,
        minSize: 300,
        cell: ({ row: { original } }) => (
          <div className="flex flex-col gap-1">
            <div>
              <span className="text-smena_text-secondary">Начало:</span>{' '}
              {original.startDatetime ? dayjs(original.startDatetime).format(`${RU_DATE} ${TIME_DATE}`) : 'Не указано'}
            </div>
            <div>
              <span className="text-smena_text-secondary">Конец: </span>
              {original.endDatetime
                ? dayjs(original.endDatetime).format(`${RU_DATE} ${TIME_DATE}`)
                : original.status === 'DRAFT'
                  ? 'Не указано'
                  : 'Бессрочно'}
            </div>
          </div>
        ),
      },
    ];
  }, []);

  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
    meta: {
      lastname: true,
      requisiteUpdatedAt: true,
      personalInfo: true,
      taxPartnerInfo: true,
      paymentInfo: true,
      version: true,
      currentVersion: true,
    },
  });
};
