import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import { useStore } from 'store';

import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';
import { IPopup } from 'interfaces/IPopup';
import { writeScheduleShiftsCache } from '../../api/updateCache';
import { useChangeCustomScheduleMutation } from 'pages/userSchedule/api/mutations/generated/ChangeCustomSchedule';
import { useCurrentUser } from 'utils/api/useUser';
import Modal from 'ui/modal';

const SchedulePopup = ({ setPopup, popup }: IPopup) => {
  const customScheduleEvents = useStore(state => state.customScheduleEvents);
  const setCustomScheduleEvents = useStore(state => state.setCustomScheduleEvents);
  const setTypeSchedule = useStore(state => state.setTypeSchedule);
  const { user } = useCurrentUser();
  const [params] = useSearchParams();
  const dateFromParam = params.get('dateFrom');
  const dateToParam = params.get('dateTo');

  const dateFrom = dateFromParam ? dayjs(dateFromParam) : dayjs().startOf('month');
  const dateTo = dateToParam ? dayjs(dateToParam).endOf('day') : dayjs().endOf('month');

  const [changeCustomScheduleMutation, { loading }] = useChangeCustomScheduleMutation();

  const customScheduleValue = useMemo(
    () =>
      customScheduleEvents.map(event => {
        const {
          resource: { userId, lunchDuration, dateFrom, dateEnd, positionId, facility },
        } = event;

        return {
          userId,
          facilityId: facility?.id || '',
          positionId,
          dateFrom,
          shiftEndDate: dateEnd,
          lunchDuration: lunchDuration || 0,
        };
      }),
    [customScheduleEvents],
  );

  const saveCustomSchedule = useCallback(() => {
    if (!customScheduleEvents.length) {
      return errorToast('Добавьте смены на график');
    }

    changeCustomScheduleMutation({
      variables: {
        inputs: customScheduleValue,
      },
      update: (store, { data }) => {
        if (!user?.id) return;
        writeScheduleShiftsCache({
          userId: user.id,
          dateFrom: dateFrom,
          dateTo: dateTo,
          cache: store,
          data: data?.changeCustomSchedule,
        });
      },
    })
      .then(() => {
        setCustomScheduleEvents([]);
        setTypeSchedule(1);
        successToast('Вы успешно предложили график');
      })
      .catch(e => errorToast(`Произошла ошибка: ${errorHandler(e)}`))
      .finally(() => {
        setPopup(false);
      });
  }, [
    changeCustomScheduleMutation,
    user?.id,
    customScheduleEvents.length,
    customScheduleValue,
    dateFrom,
    dateTo,
    setCustomScheduleEvents,
    setPopup,
    setTypeSchedule,
  ]);

  return (
    <Modal
      title="Сохранить новые смены?"
      open={popup}
      onCancel={() => setPopup(false)}
      onOk={saveCustomSchedule}
      okButtonProps={{ disabled: loading }}
      okText="Сохранить"
    >
      После сохранения смены будут добавлены в график и исполнитель увидит их в своем приложении.
    </Modal>
  );
};

export default SchedulePopup;
