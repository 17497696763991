import React from 'react';
import { useSearchParams } from 'react-router-dom';

import MainLayout from 'components/layout/main';
import VacancyFilter from 'pages/vacancies/components/VacancyFilter';
import { ShiftTime, SortingWay, VacancyFieldsSorting } from 'generated/graphql';
import { activeHandler } from 'utils/helpers/isActiveHandler';
import CustomPagination from 'ui/pagination';
import { useVacanciesPaginatedQuery } from './api/queries/generated/VacanciesPaginated';
import { useVacanciesBreadcrumbs, useVacanciesHeaderMenu } from './hooks';
import { useVacanciesTable } from 'components/table/columns/vacancies';
import NewTable from 'components/table/newTable';
import { VacancyFragment } from 'pages/vacancy/api/fragments/generated/Vacancy.fragment';

const VacanciesPage = () => {
  const [params] = useSearchParams();

  const groupId = params.get('groupId');
  const facilityId = params.get('facilityId');
  const cityId = params.get('cityId');
  const periodParam = params.get('period');
  const scheduleParam = params.get('schedule');
  const activeParam = params.get('isActive');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;
  const schedule = scheduleParam ? scheduleParam.split(', ') : [];

  const {
    data: dataVacancies,
    loading: loadingVacancies,
    error,
  } = useVacanciesPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        searchQuery,
        sortBy: sortBy as VacancyFieldsSorting,
        sortWay: sortWay as SortingWay,
        groupId,
        facilityId,
        cityId,
        period: periodParam as ShiftTime | undefined,
        schedule,
        isActive: activeHandler(activeParam),
        size,
      },
    },
  });

  const vacanciesPaginated = dataVacancies?.vacanciesPaginated;
  const vacancies = vacanciesPaginated?.vacancies || [];
  const table = useVacanciesTable(vacancies);
  const breadCrumbs = useVacanciesBreadcrumbs();
  const headerMenu = useVacanciesHeaderMenu();

  return (
    <MainLayout title="Вакансии" breadCrumbs={breadCrumbs} error={error} headerMenu={headerMenu}>
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <VacancyFilter table={table} />
        <NewTable<VacancyFragment>
          table={table}
          length={vacancies.length}
          loading={loadingVacancies}
          headerMenu={true}
        />
        {vacanciesPaginated && <CustomPagination total={vacanciesPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default VacanciesPage;
