import { DayjsOrNull } from 'interfaces/CustomTypes';

export const timeDifference = (start: DayjsOrNull, end: DayjsOrNull) => {
  if (start && end) {
    if (end.get('hours') < start.get('hours')) {
      return 24 * 60 - (start.get('hours') * 60 + start.get('minutes')) + end.get('hours') * 60 + end.get('minutes');
    }
    return end.get('hours') * 60 + end.get('minutes') - (start.get('hours') * 60 + start.get('minutes'));
  }
  return 0;
};
