import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useCallback, useMemo } from 'react';
import { labelFormatterHandler } from 'utils/helpers/charts/getLabelFormaterCharts';
import { intervalChart } from 'utils/helpers/charts/intervalChart';
import { tickFormatterHandlerFn } from 'utils/helpers/charts/tickFormatter';
import { StatisticPeriod } from 'generated/graphql';
import { ITooltipStatus } from 'interfaces/ITooltipStatus';
import { onClickHandler, onMouseMoveHandler } from 'pages/main/components/customBarChart/helpers';
import { CategoricalChartState } from 'recharts/types/chart/types';

interface Props {
  res?: any[];
  time: StatisticPeriod;
  tooltipStatus: ITooltipStatus;
  setTooltipStatus: React.Dispatch<React.SetStateAction<ITooltipStatus>>;
  focusBar?: number;
  formatter: string;
  fillColor: string;
  fillColorOpacity: string;
  setFocusBar: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const CustomBarChart = ({
  res,
  time,
  tooltipStatus,
  setTooltipStatus,
  focusBar,
  setFocusBar,
  formatter,
  fillColor,
  fillColorOpacity,
}: Props) => {
  const interval = useMemo(() => intervalChart(time), [time]);

  const tickFormatterHandler = useCallback(
    (value: any) => {
      if (value && value !== 'auto') {
        return tickFormatterHandlerFn(value, time);
      }
      return '0';
    },
    [time],
  );

  const onMouseMove = useCallback(
    (state: CategoricalChartState) => onMouseMoveHandler({ state, tooltipStatus, setFocusBar }),
    [setFocusBar, tooltipStatus],
  );

  const onClick = useCallback(
    (state: CategoricalChartState) => onClickHandler({ state, tooltipStatus, setTooltipStatus, setFocusBar }),
    [setFocusBar, setTooltipStatus, tooltipStatus],
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={res} onClick={onClick} onMouseMove={onMouseMove}>
        <XAxis
          dataKey={res ? 'createdAt' : undefined}
          interval={interval}
          tickFormatter={tickFormatterHandler}
          height={30}
          angle={-25}
          tickMargin={10}
        />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          cursor={false}
          trigger={tooltipStatus.status}
          labelFormatter={(label: string) => labelFormatterHandler({ label, time })}
          formatter={(value: string) => [value, formatter]}
          separator=""
        />
        <Bar dataKey="value">
          {res?.map((_, index) => (
            <Cell key={`cell-${index}`} fill={focusBar === index ? fillColor : fillColorOpacity} stroke={fillColor} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;
