import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { TwentyOneRefererFragmentDoc } from '../../fragments/generated/TwentyOneReferrs.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTwentyOneReferrersPaginatedQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.OchkoReferrersListInput>;
}>;


export type GetTwentyOneReferrersPaginatedQuery = { __typename?: 'Query', getOchkoReferrersPaginated: { __typename?: 'PaginatedOchkoReferrersList', count: number, pages: number, referrers: Array<{ __typename?: 'OchkoReferrerOutput', id: string, firstname: string, lastname: string, patronymic?: string | null, phone: string, role: Types.Role, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', status: Types.WorkPostStatus, userId: string, facilityId: string, positionId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null, leads: Array<{ __typename?: 'LeadOutput', id: string, inviteFriendDate?: any | null, ochkoDate?: any | null, status: Types.LeadStatus, user?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null, phone: string, role: Types.Role } | null, inviteFriendProgress: { __typename?: 'InviteFriendProgressOutput', currentShifts: number, targetShifts: number } }>, progress: { __typename?: 'OchkoProgressOutput', currentReferrals: number, currentShifts?: number | null, sum: number, targetReferrals: number, targetShifts: number } }> } };


export const GetTwentyOneReferrersPaginatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTwentyOneReferrersPaginated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"OchkoReferrersListInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getOchkoReferrersPaginated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"referrers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TwentyOneReferer"}}]}}]}}]}},...TwentyOneRefererFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetTwentyOneReferrersPaginatedQuery__
 *
 * To run a query within a React component, call `useGetTwentyOneReferrersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTwentyOneReferrersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTwentyOneReferrersPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTwentyOneReferrersPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetTwentyOneReferrersPaginatedQuery, GetTwentyOneReferrersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTwentyOneReferrersPaginatedQuery, GetTwentyOneReferrersPaginatedQueryVariables>(GetTwentyOneReferrersPaginatedDocument, options);
      }
export function useGetTwentyOneReferrersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTwentyOneReferrersPaginatedQuery, GetTwentyOneReferrersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTwentyOneReferrersPaginatedQuery, GetTwentyOneReferrersPaginatedQueryVariables>(GetTwentyOneReferrersPaginatedDocument, options);
        }
export function useGetTwentyOneReferrersPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTwentyOneReferrersPaginatedQuery, GetTwentyOneReferrersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTwentyOneReferrersPaginatedQuery, GetTwentyOneReferrersPaginatedQueryVariables>(GetTwentyOneReferrersPaginatedDocument, options);
        }
export type GetTwentyOneReferrersPaginatedQueryHookResult = ReturnType<typeof useGetTwentyOneReferrersPaginatedQuery>;
export type GetTwentyOneReferrersPaginatedLazyQueryHookResult = ReturnType<typeof useGetTwentyOneReferrersPaginatedLazyQuery>;
export type GetTwentyOneReferrersPaginatedSuspenseQueryHookResult = ReturnType<typeof useGetTwentyOneReferrersPaginatedSuspenseQuery>;
export type GetTwentyOneReferrersPaginatedQueryResult = Apollo.QueryResult<GetTwentyOneReferrersPaginatedQuery, GetTwentyOneReferrersPaginatedQueryVariables>;