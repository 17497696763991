import { twentyOneColumns, twentyOneLeadColumns } from 'components/table/columns';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { mapColumns } from 'utils/helpers/mapColumns';
import clsx from 'clsx';
import ChevronRight from 'assets/icons/ChevronRight';
import { TwentyOneRefererFragment } from '../api/fragments/generated/TwentyOneReferrs.fragment';
import { userFullName } from 'utils/helpers/userFullName';
import TwentyOneLead from './TwentyOneLead';
import dayjs from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';
import TwentyOneLeadColumn from './TwentyOneLeadColumn';
import { SortingWay } from 'generated/graphql';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import FacilityCell from '../../leads/components/facilityCell';
import { convertPhoneNumberToNormal } from '../../../utils/helpers/convertPhone';
import { successToast } from '../../../utils/helpers/notify';
import CopyIcon from '../../../assets/icons/CopyIcon';

interface Props {
  refer?: TwentyOneRefererFragment;
}

const TwentyOneRow = ({ refer }: Props) => {
  const [show, setShow] = useState(false);
  const [sortWay, setSortWay] = useState<undefined | string>();
  const desc = sortWay === SortingWay.Desc;
  const asc = sortWay === SortingWay.Asc;
  const leads = useMemo(() => {
    if (!refer?.leads) return [];
    if (asc) {
      return [...refer.leads].sort(
        (leadA, leadB) => dayjs(leadA.inviteFriendDate).valueOf() - dayjs(leadB.inviteFriendDate).valueOf(),
      );
    }
    if (desc) {
      return [...refer.leads].sort(
        (leadA, leadB) => dayjs(leadB.inviteFriendDate).valueOf() - dayjs(leadA.inviteFriendDate).valueOf(),
      );
    }
    return refer.leads;
  }, [asc, desc, refer?.leads]);

  return (
    <div className="bg-smena_white rounded-lg relative border border-smena_gray-30">
      <div
        className="grid"
        style={{
          gridTemplateColumns: mapColumns(twentyOneColumns),
        }}
        onClick={() => setShow(value => !value)}
      >
        <div className={twentyOneColumns[0].className}>
          <Link to={`/users/${refer?.id}`} className={'link'}>
            {refer?.lastname ? userFullName(refer) : 'Нет информации'}
          </Link>
        </div>
        <div className={twentyOneColumns[1].className}>{getRoleRu(refer?.role)}</div>
        <div className={twentyOneColumns[2].className}>
          <FacilityCell user={refer} />
        </div>
        <div className={twentyOneColumns[3].className}>
          <span>{refer?.progress.currentReferrals}</span>/<span>{refer?.progress.targetReferrals}</span>
        </div>
        <div className={twentyOneColumns[4].className}>
          {
            <div className={'flex items-center gap-x-2 group/number min-h-[24px]'}>
              <span>{convertPhoneNumberToNormal(refer?.phone)}</span>
              <button
                className={'group-hover/number:block hidden'}
                onClick={e => {
                  e.stopPropagation();
                  if (!refer?.phone) return;
                  navigator.clipboard.writeText(refer?.phone).then(() => {
                    successToast('Номер скопирован', 1000);
                  });
                }}
              >
                <CopyIcon />
              </button>
            </div>
          }
        </div>
        <div className={twentyOneColumns[5].className}>
          {refer?.leads[0].ochkoDate ? dayjs(refer?.leads[0].ochkoDate).format(RU_DATE) : null}
        </div>
        <div className={clsx(twentyOneColumns[6].className)}>
          <ChevronRight className={clsx(show ? '-rotate-90' : 'rotate-90', 'inline-flex text-smena_text')} />
        </div>
      </div>
      {show && (
        <div className={'py-4 col-span-full'}>
          <div
            className={'grid gap-x-[10px] border-b border-smena_gray-5 px-4'}
            style={{
              gridTemplateColumns: mapColumns(twentyOneLeadColumns),
            }}
          >
            {twentyOneLeadColumns.map(column => (
              <TwentyOneLeadColumn key={column.name} column={column} sortWay={sortWay} setSortWay={setSortWay} />
            ))}
          </div>
          {leads.map(lead => (
            <TwentyOneLead key={lead.id} lead={lead} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TwentyOneRow;
