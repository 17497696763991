import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { UserGradeFragmentDoc } from '../../fragments/generated/UserGrade.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserGradeMutationVariables = Types.Exact<{
  input: Types.CreateUserGradeInput;
}>;


export type CreateUserGradeMutation = { __typename?: 'Mutation', createUserGrade: { __typename?: 'UserGrade', id: string, createdAt: any, positionId: string, character: number, responsiveness: number, speed: number, author: { __typename?: 'User', firstname: string, lastname: string, role: Types.Role }, facility?: { __typename?: 'Facility', id: string, name: string, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string } | null } | null } };


export const CreateUserGradeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserGrade"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateUserGradeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUserGrade"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserGrade"}}]}}]}},...UserGradeFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateUserGradeMutationFn = Apollo.MutationFunction<CreateUserGradeMutation, CreateUserGradeMutationVariables>;

/**
 * __useCreateUserGradeMutation__
 *
 * To run a mutation, you first call `useCreateUserGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGradeMutation, { data, loading, error }] = useCreateUserGradeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserGradeMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGradeMutation, CreateUserGradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGradeMutation, CreateUserGradeMutationVariables>(CreateUserGradeDocument, options);
      }
export type CreateUserGradeMutationHookResult = ReturnType<typeof useCreateUserGradeMutation>;
export type CreateUserGradeMutationResult = Apollo.MutationResult<CreateUserGradeMutation>;
export type CreateUserGradeMutationOptions = Apollo.BaseMutationOptions<CreateUserGradeMutation, CreateUserGradeMutationVariables>;