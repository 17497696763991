import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleSelect from 'ui/select';
import { undefStr } from 'interfaces/CustomTypes';
import { convertMoney } from 'utils/helpers/convertMoney';
import { SkeletonLayout } from 'ui/loader/skeletonLayput';
import { useGetDirectorOrManagerOrSupervisorGroupsQuery } from 'pages/main/api/queries/generated/GetDirectorOrManagerOrSupervisorGroups';
import { useFacilityGroupLazyQuery } from 'pages/facilityGroup/api/queries/generated/FacilityGroup';
import { sortObj } from 'utils/helpers/sortObject';

export const BalanceBlock = () => {
  const [value, setValue] = useState<undefStr>();
  const { data, loading } = useGetDirectorOrManagerOrSupervisorGroupsQuery({
    onCompleted: data => {
      const options = sortObj(data.getDirectorOrManagerOrSupervisorGroups);
      if (!options.length) return;
      setValue(options[0].id);
      loadFacility({
        variables: {
          id: options[0].id,
        },
      });
    },
  });
  const [loadFacility, { data: facilityGroupData }] = useFacilityGroupLazyQuery();

  const options = useMemo(() => {
    return data?.getDirectorOrManagerOrSupervisorGroups;
  }, [data?.getDirectorOrManagerOrSupervisorGroups]);

  const facilityGroup = facilityGroupData?.facilityGroup;

  const onChange = useCallback(
    (e: string) => {
      setValue(e);
      loadFacility({
        variables: {
          id: e,
        },
      });
    },
    [loadFacility],
  );

  if (loading) {
    return <SkeletonLayout />;
  }

  return (
    <div className={'grid grid-rows-[min-content,_auto] col-span-6 gap-y-6 shadow-smena rounded-lg bg-smena_white p-5'}>
      <div className={'flex justify-between'}>
        <span className={'Subtitle1 text-smena_text-secondary'}>Баланс</span>
        <SimpleSelect
          divClassName="w-[160px]"
          label=""
          onChange={onChange}
          value={value}
          placeholder="Все группы"
          options={options}
        />
      </div>
      <div className={'flex flex-col gap-y-2'}>
        <div className={'flex justify-between'}>
          <span className={'Subtitle2 text-smena_text-secondary'}>Баланс на текущий момент</span>
          <Link to={'/balance'} className={'Button1 text-primary'}>
            Перейти к транзакциям
          </Link>
        </div>

        <span className={'ChartBigNumber'}>{facilityGroup ? `${convertMoney(facilityGroup?.balance)} ₽` : ''}</span>
      </div>
    </div>
  );
};
