import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityVacancyFragmentDoc } from '../../fragments/generated/FacilityVacancy.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchFacilitiesVacanciesQueryVariables = Types.Exact<{
  input: Types.SearchVacanciesFilterInput;
}>;


export type SearchFacilitiesVacanciesQuery = { __typename?: 'Query', searchFacilitiesVacancies: Array<{ __typename?: 'Facility', id: string, name: string, addressString?: string | null, latitude: string, longitude: string, facilityGroup?: { __typename?: 'FacilityGroup', name: string, id: string, logo?: { __typename?: 'File', path: string } | null } | null, address?: { __typename?: 'Address', address: string, city?: string | null, createdAt: any, fiasId?: string | null, id: string, latitude?: number | null, longitude?: number | null, timezone: string, updatedAt: any, distance?: number | null } | null, vacancies?: Array<{ __typename?: 'Vacancy', averageCost: number, citizenshipsIds?: Array<string> | null, createdAt: any, description?: string | null, erpGroupId?: string | null, erpSourceId?: string | null, facilityId: string, hourRate: number, id: string, lunchDuration?: number | null, isPublishCostOfShift: boolean, isRangeCost: boolean, is_active: boolean, maxAge?: number | null, minAge?: number | null, notes?: string | null, payout?: Types.PayoutType | null, payoutFrequency?: Types.PayoutFrequency | null, period: Types.ShiftTime, positionId: string, requirements?: string | null, responsibilities?: string | null, section?: string | null, sex?: Types.Sex | null, scheduleTypesIds?: Array<string> | null, shiftCostFrom: number, shiftCostUpTo: number, timeEnd: string, timeStart: string, transport?: string | null, unit?: Types.UnitType | null, updatedAt: any, workwear: boolean, requiredDocuments: Array<Types.CasingDocumentType>, documentsNotes?: string | null, hasEducation?: boolean | null, probationPeriod?: number | null, position?: { __typename?: 'Position', id: string, name: string } | null }> | null, metroStations?: Array<{ __typename?: 'MetroStation', color: string, name: string }> | null }> };


export const SearchFacilitiesVacanciesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchFacilitiesVacancies"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SearchVacanciesFilterInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchFacilitiesVacancies"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityVacancy"}}]}}]}},...FacilityVacancyFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useSearchFacilitiesVacanciesQuery__
 *
 * To run a query within a React component, call `useSearchFacilitiesVacanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFacilitiesVacanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFacilitiesVacanciesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchFacilitiesVacanciesQuery(baseOptions: Apollo.QueryHookOptions<SearchFacilitiesVacanciesQuery, SearchFacilitiesVacanciesQueryVariables> & ({ variables: SearchFacilitiesVacanciesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchFacilitiesVacanciesQuery, SearchFacilitiesVacanciesQueryVariables>(SearchFacilitiesVacanciesDocument, options);
      }
export function useSearchFacilitiesVacanciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFacilitiesVacanciesQuery, SearchFacilitiesVacanciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchFacilitiesVacanciesQuery, SearchFacilitiesVacanciesQueryVariables>(SearchFacilitiesVacanciesDocument, options);
        }
export function useSearchFacilitiesVacanciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchFacilitiesVacanciesQuery, SearchFacilitiesVacanciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchFacilitiesVacanciesQuery, SearchFacilitiesVacanciesQueryVariables>(SearchFacilitiesVacanciesDocument, options);
        }
export type SearchFacilitiesVacanciesQueryHookResult = ReturnType<typeof useSearchFacilitiesVacanciesQuery>;
export type SearchFacilitiesVacanciesLazyQueryHookResult = ReturnType<typeof useSearchFacilitiesVacanciesLazyQuery>;
export type SearchFacilitiesVacanciesSuspenseQueryHookResult = ReturnType<typeof useSearchFacilitiesVacanciesSuspenseQuery>;
export type SearchFacilitiesVacanciesQueryResult = Apollo.QueryResult<SearchFacilitiesVacanciesQuery, SearchFacilitiesVacanciesQueryVariables>;