import { useMemo } from 'react';
import { useStore } from 'store';
import { useMeRole } from 'store/hooks/useMeRole';
import { GetStatisticQuery } from 'pages/main/api/queries/generated/GetStatistic';
import sortBy from 'lodash/sortBy';
import { Role } from 'generated/graphql';

export const useIsSendRequest = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeDirector = useMeRole([Role.ClientDirector]);
  const isMeManager = useMeRole([Role.ClientManager]);
  return useMemo(() => {
    if (isMeDirector) {
      if (!trendsGroupId) return false;
    }
    if (isMeManager || isMeSupervisor) {
      if (!trendsGroupId || !trendsFacilityId) return false;
    }
    return true;
  }, [isMeDirector, isMeManager, isMeSupervisor, trendsFacilityId, trendsGroupId]);
};

export const useGetStatisticResponse = (data?: GetStatisticQuery) => {
  return useMemo(() => {
    return sortBy(data?.getStatistic?.map(el => el.data).flat(), el => el.createdAt);
  }, [data?.getStatistic]);
};
