import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import { ZayavkaFieldsSorting } from 'generated/graphql';
import { RU_DATE } from 'utils/helpers/constVariables';
import { percentColor, progressPercentObj } from 'utils/helpers/progressPercentHelper';
import dayjs from 'dayjs';
import { usePositions } from 'utils/api/usePositions';
import ProgressComponent from 'ui/progress';
import RequestStatus from 'pages/request/components/status';
import ActionColumn from './actionColumn';
import { PaginatedRequestFragment } from 'pages/facilityRequests/api/fragments/generated/PaginatedRequest.fragment';

export const useRequestsTable = (data: PaginatedRequestFragment[]) => {
  const { positions } = usePositions();
  const requestPositions = useCallback(
    (request: PaginatedRequestFragment) => {
      return request.plans
        .map((requestPlan: { positionId: string }, index: number) => {
          if (index < 3) {
            return positions?.find(position => position.id === requestPlan.positionId)?.name;
          }
          return null;
        })
        .filter(Boolean);
    },
    [positions],
  );
  const counter = useCallback((plansLength: number) => {
    if (plansLength > 3) {
      return plansLength - 3;
    }
    return null;
  }, []);

  const columns: ColumnDef<PaginatedRequestFragment>[] = useMemo(() => {
    return [
      {
        id: ZayavkaFieldsSorting.Number,
        header: 'Номер',
        size: 105,
        minSize: 100,
        cell: ({ row: { original } }) => {
          return (
            <Link to={original.id} className={'link'}>
              {original.number}
            </Link>
          );
        },
      },
      {
        id: ZayavkaFieldsSorting.DateFrom,
        header: 'Период',
        size: 180,
        minSize: 180,
        cell: ({ row: { original } }) =>
          dayjs(original.dateFrom).format(RU_DATE) + ' - ' + dayjs(original.dateTo).format(RU_DATE),
      },
      {
        id: 'group',
        header: 'Группа',
        size: 175,
        minSize: 175,
        cell: ({ row: { original } }) => original.facility?.facilityGroup?.name,
      },
      {
        id: 'facility',
        header: 'Объект',
        size: 250,
        minSize: 250,
        cell: ({ row: { original } }) => original.facility?.name,
      },
      {
        id: 'positions',
        header: 'Профессии',
        size: 250,
        minSize: 250,
        cell: ({ row: { original } }) => {
          const count = counter(original.plans.length);
          return (
            <>
              <span>{requestPositions(original).join(', ') + (count ? ', ' : '')}</span>
              <span className="text-primary">{count ? `+${count}` : ''}</span>
            </>
          );
        },
      },
      {
        id: 'shifts',
        header: 'Кол-во смен',
        size: 145,
        minSize: 145,
        cell: ({ row: { original } }) => {
          const { shiftsProgress } = progressPercentObj(original);
          const shiftsStroke = percentColor(shiftsProgress);
          return (
            <div className={'flex gap-x-2 items-center'}>
              <ProgressComponent type="circle" percent={shiftsProgress} width={16} strokeColor={shiftsStroke} />
              <div>
                <span>{original.shiftsScheduleCount}</span>
                <span>/</span>
                <span>{original.shiftsPlanCount}</span>
              </div>
            </div>
          );
        },
      },
      {
        id: 'hours',
        header: 'Кол-во часов',
        size: 145,
        minSize: 145,
        cell: ({ row: { original } }) => {
          const { hoursProgress } = progressPercentObj(original);
          const hoursStroke = percentColor(hoursProgress);
          return (
            <div className={'flex gap-x-2 items-center'}>
              <ProgressComponent type="circle" percent={hoursProgress} width={16} strokeColor={hoursStroke} />
              <div>
                <span>{original.hoursScheduleCount}</span>
                <span>/</span>
                <span>{original.hoursPlanCount}</span>
              </div>
            </div>
          );
        },
      },
      {
        id: 'status',
        header: 'Статус',
        minSize: 175,
        cell: ({ row: { original } }) => {
          return <RequestStatus request={original} />;
        },
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 115,
        minSize: 115,
        cell: ({ row: { original } }) => {
          return <ActionColumn original={original} />;
        },
      },
    ];
  }, [counter, requestPositions]);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    meta: {
      number: true,
      dateFrom: true,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};
