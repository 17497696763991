import { useRef, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import Filter from 'components/table/filters/filter';
import Plus from 'assets/icons/plus';
import { clientUsersRole } from 'utils/helpers/lists/roleArray';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { ratingList } from 'utils/helpers/lists/ratingList';
import { getActive } from 'utils/helpers/get/getActive';
import SimpleSelect from 'ui/select';
import { activeOptions } from 'utils/helpers/constVariables';
import { useMeRole } from 'store/hooks/useMeRole';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useCustomSearchParams } from '../../lib/useParams';
import Search from 'ui/search';
import { Table } from '@tanstack/react-table';
import { ClientUserFragment } from 'pages/users/api/fragments/generated/ClientUser.fragment';
import ColumnSettings from 'components/table/columnSettings';
import TableFilter from 'components/table/filters';
import { Role } from 'generated/graphql';
import { useFacilityById } from 'utils/api/useFacility';

interface Props {
  table: Table<ClientUserFragment>;
}

const ClientUsersFilters = ({ table }: Props) => {
  const { handleParams, params, setParams, useParamsCount, resetFilter } = useCustomSearchParams();
  const facilityIdParam = params.get('facilityId');
  const roleParam = params.get('role');
  const ratingParam = params.get('rating');
  const activeShiftsParam = params.get('activeShifts');
  const [facilityId, setFacilityId] = useState<string | null>(facilityIdParam);
  const [role, setRole] = useState<string | null>(roleParam);
  const [rating, setRating] = useState<string | null>(ratingParam);
  const [activeShifts, setActiveShifts] = useState<string | null>(activeShiftsParam);
  const [showFilter, setShowFilter] = useState(false);
  const { facilities } = useSelectFacilities();
  const { facility } = useFacilityById(facilityIdParam);
  const paramsCount = useParamsCount();
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const isMeAdmin = useMeRole([Role.Admin]);

  return (
    <div className="filter-bar flex xs:flex-row flex-col xs:flex-wrap gap-y-2 justify-between">
      <div className="flex flex-col gap-y-2">
        <div className={clsx('flex relative gap-x-2')}>
          <Search />
          <button
            type="button"
            ref={btnRef}
            className="btn-stroke flex gap-x-1 items-center"
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <ColumnSettings<ClientUserFragment> table={table} />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              label="Объект"
              onChange={value => {
                setFacilityId(value);
              }}
              value={facilityId}
              placeholder="Не выбрано"
              allOption={'Все'}
              options={facilities}
            />
            <SimpleSelect
              label="Роль"
              sort={false}
              onChange={value => {
                setRole(value);
              }}
              value={role}
              placeholder="Не выбрано"
              allOption={'Все'}
              options={clientUsersRole.map(el => ({ id: el.value, name: el.name }))}
            />
            <SimpleSelect
              label="Рейтинг"
              onChange={value => {
                setRating(value);
              }}
              value={rating}
              placeholder="Не выбрано"
              sort={false}
              options={ratingList.map(el => ({ id: el.value, name: el.name }))}
            />
            <SimpleSelect
              label="Статус активности"
              onChange={value => {
                setActiveShifts(value);
              }}
              value={activeShifts}
              allOption="Все"
              placeholder="Не выбрано"
              options={activeOptions}
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('facilityId', facilityId);
                  handleParams('role', role);
                  handleParams('rating', rating);
                  handleParams('activeShifts', activeShifts);
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                Применить
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setFacilityId(null);
                  setRole(null);
                  setRating(null);
                  setActiveShifts(null);
                  setShowFilter(false);
                  setParams();
                }}
              >
                <span>Сбросить</span>
              </button>
            </div>
          </TableFilter>
        </div>
        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!facilityIdParam}
              content={facility?.name}
              clickHandler={() => {
                setFacilityId(null);
                resetFilter('facilityId');
              }}
            />
            <Filter
              show={!!roleParam}
              content={getRoleRu(roleParam)}
              clickHandler={() => {
                setRole(null);
                resetFilter('role');
              }}
            />
            <Filter
              show={!!ratingParam}
              content={ratingList.find(el => el.value === ratingParam)?.name}
              clickHandler={() => {
                setRating(null);
                resetFilter('rating');
              }}
            />
            <Filter
              show={!!activeShiftsParam}
              content={getActive(activeShiftsParam)}
              clickHandler={() => {
                setActiveShifts(null);
                resetFilter('activeShifts');
              }}
            />
          </div>
        )}
        {isMeAdmin && (
          <div className="flex self-start">
            <Link to="/users/create">
              <button type="button" className="btn-primary_big flex items-center">
                <span className="inline-flex mr-2">
                  <Plus className="text-smena_white" />
                </span>
                Новый пользователь
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientUsersFilters;
