import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveDirectorFromFacilityGroupMutationVariables = Types.Exact<{
  groupId: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type RemoveDirectorFromFacilityGroupMutation = { __typename?: 'Mutation', removeDirectorFromFacilityGroup: boolean };


export const RemoveDirectorFromFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveDirectorFromFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"groupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeDirectorFromFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"groupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"groupId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}]}]}}]} as unknown as DocumentNode;
export type RemoveDirectorFromFacilityGroupMutationFn = Apollo.MutationFunction<RemoveDirectorFromFacilityGroupMutation, RemoveDirectorFromFacilityGroupMutationVariables>;

/**
 * __useRemoveDirectorFromFacilityGroupMutation__
 *
 * To run a mutation, you first call `useRemoveDirectorFromFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDirectorFromFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDirectorFromFacilityGroupMutation, { data, loading, error }] = useRemoveDirectorFromFacilityGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveDirectorFromFacilityGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDirectorFromFacilityGroupMutation, RemoveDirectorFromFacilityGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDirectorFromFacilityGroupMutation, RemoveDirectorFromFacilityGroupMutationVariables>(RemoveDirectorFromFacilityGroupDocument, options);
      }
export type RemoveDirectorFromFacilityGroupMutationHookResult = ReturnType<typeof useRemoveDirectorFromFacilityGroupMutation>;
export type RemoveDirectorFromFacilityGroupMutationResult = Apollo.MutationResult<RemoveDirectorFromFacilityGroupMutation>;
export type RemoveDirectorFromFacilityGroupMutationOptions = Apollo.BaseMutationOptions<RemoveDirectorFromFacilityGroupMutation, RemoveDirectorFromFacilityGroupMutationVariables>;