import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Plus from 'assets/icons/plus';
import { getActive } from 'utils/helpers/get/getActive';
import Filter from 'components/table/filters/filter';
import SimpleSelect from 'ui/select';
import TableFilter from 'components/table/filters';
import { useMeRole } from 'store/hooks/useMeRole';
import { useCities } from 'utils/api/useCities';
import { useCustomSearchParams } from 'pages/users/lib/useParams';
import Search from 'ui/search';
import { Table } from '@tanstack/react-table';
import { FacilityPaginatedFragment } from 'pages/facilities/api/fragments/generated/FacilityPaginated.fragment';
import ColumnSettings from 'components/table/columnSettings';
import { Role } from 'generated/graphql';
import { useCityById } from 'utils/api/useCity';
import { useFacilityGroupFacilitiesStore } from '../store';
import StatusOptions from './popups/statusOptions';
import ChangeStatus from './popups/changeStatus';
import { useGetLinkedNaimixObjects } from '../../../utils/api/useLinkedNaimixObjects';

interface Props {
  table: Table<FacilityPaginatedFragment>;
}

const GroupFacilitiesFilter = ({ table }: Props) => {
  const showStatusOptionsPopup = useFacilityGroupFacilitiesStore(state => state.showStatusOptionsPopup);
  const showChangeStatusPopup = useFacilityGroupFacilitiesStore(state => state.showChangeStatusPopup);
  const setShowStatusOptionsPopup = useFacilityGroupFacilitiesStore(state => state.setShowStatusOptionsPopup);
  const { params, setParams, useParamsCount, resetFilter, handleParams } = useCustomSearchParams();
  const { namixObjects } = useGetLinkedNaimixObjects();
  const activeParam = params.get('active');
  const cityParam = params.get('cityId');
  const namixObjectIdParam = params.get('namixObjectId');
  const [isActive, setIsActive] = useState(activeParam);
  const [cityId, setCityId] = useState(cityParam);
  const [namixObjectId, setNamixObjectId] = useState(namixObjectIdParam);
  const [showFilter, setShowFilter] = useState(false);

  const isMeAdmin = useMeRole([Role.Admin]);
  const { city } = useCityById(cityParam);
  const { cities } = useCities();

  const paramsCount = useParamsCount();

  const btnRef = useRef<HTMLButtonElement>(null);

  const activeOptions = useMemo(
    () => [
      { id: 'true', name: 'Активный' },
      { id: 'false', name: 'Не активный' },
    ],
    [],
  );
  const showBtns = !!table.getSelectedRowModel().rows.length;
  const showStatusOptionsHandler = () => {
    setShowStatusOptionsPopup(!showStatusOptionsPopup);
  };

  return (
    <div className="filter-bar flex xs:flex-row flex-col flex-wrap gap-y-2 justify-between">
      <div className="flex flex-col gap-y-2">
        <div className={`flex relative gap-x-2`}>
          <Search />
          <button
            type="button"
            ref={btnRef}
            className="btn-stroke flex gap-x-1 items-center"
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <ColumnSettings<FacilityPaginatedFragment> table={table} />
          {showBtns && (
            <>
              <div className={'border-l border-smena_gray-30 flex gap-x-2 pl-2 h-[32px] relative'}>
                <button className={'Button2 text-primary px-3'} onClick={showStatusOptionsHandler}>
                  Изменить статус
                </button>
                {showStatusOptionsPopup && <StatusOptions />}
                {showChangeStatusPopup && <ChangeStatus table={table} />}
              </div>
            </>
          )}
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              label="Город"
              onChange={value => setCityId(value)}
              value={cityId}
              allOption={'Все города'}
              placeholder={'Не выбрано'}
              options={cities}
            />
            <SimpleSelect
              label="Объект в наймикс"
              onChange={value => setNamixObjectId(value)}
              value={namixObjectId}
              allOption={'Все юр. лица'}
              placeholder={'Не выбрано'}
              options={namixObjects}
            />
            <SimpleSelect
              label="Статус активности"
              onChange={value => {
                setIsActive(value);
              }}
              value={isActive}
              allOption="Все"
              placeholder="Не выбрано"
              options={activeOptions}
            />
            <div className="flex gap-x-5 xs:flex-nowrap flex-wrap gap-y-2">
              <button
                className="btn-primary xs:w-auto w-full"
                onClick={() => {
                  handleParams('active', isActive);
                  handleParams('cityId', cityId);
                  handleParams('namixObjectId', namixObjectId);
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setCityId(null);
                  setIsActive(null);
                  setNamixObjectId(null);
                  setParams();
                }}
              >
                Сбросить
              </button>
            </div>
          </TableFilter>
        </div>
        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!cityParam}
              content={'Город ' + city?.name}
              clickHandler={() => {
                setCityId(null);
                resetFilter('cityId');
              }}
            />
            <Filter
              show={!!namixObjectId}
              content={namixObjects?.find(el => el.id === namixObjectId)?.name}
              clickHandler={() => {
                setNamixObjectId(null);
                resetFilter('namixObjectId');
              }}
            />
            <Filter
              show={!!activeParam}
              content={getActive(activeParam)}
              clickHandler={() => {
                setIsActive(null);
                resetFilter('active');
              }}
            />
          </div>
        )}
      </div>
      {isMeAdmin && (
        <Link to={`create`}>
          <button type="button" className="btn-primary_big flex items-center gap-x-2">
            <Plus className="text-smena_white" />
            Новый объект
          </button>
        </Link>
      )}
    </div>
  );
};

export default GroupFacilitiesFilter;
