import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetWaitingMedicalOnFacilityMutationVariables = Types.Exact<{
  facilityId: Types.Scalars['String']['input'];
  isMedInvoiceAllowed: Types.Scalars['Boolean']['input'];
  numberDaysWaitMedical: Types.Scalars['Int']['input'];
}>;


export type SetWaitingMedicalOnFacilityMutation = { __typename?: 'Mutation', setWaitingMedicalOnFacility: { __typename?: 'Facility', id: string, isMedInvoiceAllowed: boolean, numberDaysWaitMedical: number } };


export const SetWaitingMedicalOnFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetWaitingMedicalOnFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"isMedInvoiceAllowed"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"numberDaysWaitMedical"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setWaitingMedicalOnFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}},{"kind":"Argument","name":{"kind":"Name","value":"isMedInvoiceAllowed"},"value":{"kind":"Variable","name":{"kind":"Name","value":"isMedInvoiceAllowed"}}},{"kind":"Argument","name":{"kind":"Name","value":"numberDaysWaitMedical"},"value":{"kind":"Variable","name":{"kind":"Name","value":"numberDaysWaitMedical"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isMedInvoiceAllowed"}},{"kind":"Field","name":{"kind":"Name","value":"numberDaysWaitMedical"}}]}}]}}]} as unknown as DocumentNode;
export type SetWaitingMedicalOnFacilityMutationFn = Apollo.MutationFunction<SetWaitingMedicalOnFacilityMutation, SetWaitingMedicalOnFacilityMutationVariables>;

/**
 * __useSetWaitingMedicalOnFacilityMutation__
 *
 * To run a mutation, you first call `useSetWaitingMedicalOnFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWaitingMedicalOnFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWaitingMedicalOnFacilityMutation, { data, loading, error }] = useSetWaitingMedicalOnFacilityMutation({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      isMedInvoiceAllowed: // value for 'isMedInvoiceAllowed'
 *      numberDaysWaitMedical: // value for 'numberDaysWaitMedical'
 *   },
 * });
 */
export function useSetWaitingMedicalOnFacilityMutation(baseOptions?: Apollo.MutationHookOptions<SetWaitingMedicalOnFacilityMutation, SetWaitingMedicalOnFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWaitingMedicalOnFacilityMutation, SetWaitingMedicalOnFacilityMutationVariables>(SetWaitingMedicalOnFacilityDocument, options);
      }
export type SetWaitingMedicalOnFacilityMutationHookResult = ReturnType<typeof useSetWaitingMedicalOnFacilityMutation>;
export type SetWaitingMedicalOnFacilityMutationResult = Apollo.MutationResult<SetWaitingMedicalOnFacilityMutation>;
export type SetWaitingMedicalOnFacilityMutationOptions = Apollo.BaseMutationOptions<SetWaitingMedicalOnFacilityMutation, SetWaitingMedicalOnFacilityMutationVariables>;