import { IUser } from 'interfaces/IUser';

const firstLetter = (str: string) => str.charAt(0).toUpperCase();

export const userFullName = (user?: IUser | null) => {
  if (!user) return '';
  const { lastname, firstname, patronymic } = user;
  return `${lastname} ${firstname} ${patronymic ?? ''}`;
};

export const userNameSurname = (user?: IUser | null) => {
  if (!user) return '';
  const { lastname, firstname } = user;
  return `${lastname} ${firstname}`;
};

export const userInitials = (user?: IUser) => {
  if (!user) return '';
  const { lastname, firstname } = user;
  return `${firstLetter(lastname)}${firstLetter(firstname)}`;
};

export const getUserFIO = (user?: IUser | null) => {
  if (!user) return '';
  const { lastname, firstname, patronymic } = user;
  return `${lastname} ${firstLetter(firstname)}. ${patronymic ? `${firstLetter(patronymic)}.` : ''}`;
};
