import React from 'react';
import { useVacanciesMap } from '../store';
import Filters from './filters';
import VacanciesList from './sidebar/vacanciesList';
import ActiveFacilityVacancy from './sidebar/activeVacancy';
import SkeletonVacancy from './sidebar/SkeletonVacancy';

const Sidebar = () => {
  const facilityVacancies = useVacanciesMap(state => state.facilityVacancies);
  const loading = useVacanciesMap(state => state.loading);
  const activeFacilityVacancy = facilityVacancies.find(el => el.isActive);

  return (
    <div
      className={
        'h-full max-w-[400px] flex-[1_0_400px] z-10 bg-smena_white border-r border-smena_gray-30 flex flex-col'
      }
    >
      <Filters />
      {loading ? (
        <SkeletonVacancy />
      ) : activeFacilityVacancy ? (
        <ActiveFacilityVacancy activeFacilityVacancy={activeFacilityVacancy} />
      ) : (
        <VacanciesList />
      )}
    </div>
  );
};

export default Sidebar;
