import clsx from 'clsx';
import { FacilityPaginatedFragment } from 'pages/facilities/api/fragments/generated/FacilityPaginated.fragment';
import React from 'react';

const Checkbox = ({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean; original?: FacilityPaginatedFragment } & React.HTMLProps<HTMLInputElement>) => {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return <input type="checkbox" ref={ref} className={clsx(className, 'checkbox-primary cursor-pointer')} {...rest} />;
};

export default Checkbox;
