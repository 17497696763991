import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeclineRequestBySupervisorMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeclineRequestBySupervisorMutation = { __typename?: 'Mutation', declineZayavkaBySupervisor: boolean };


export const DeclineRequestBySupervisorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclineRequestBySupervisor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineZayavkaBySupervisor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type DeclineRequestBySupervisorMutationFn = Apollo.MutationFunction<DeclineRequestBySupervisorMutation, DeclineRequestBySupervisorMutationVariables>;

/**
 * __useDeclineRequestBySupervisorMutation__
 *
 * To run a mutation, you first call `useDeclineRequestBySupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineRequestBySupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineRequestBySupervisorMutation, { data, loading, error }] = useDeclineRequestBySupervisorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeclineRequestBySupervisorMutation(baseOptions?: Apollo.MutationHookOptions<DeclineRequestBySupervisorMutation, DeclineRequestBySupervisorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineRequestBySupervisorMutation, DeclineRequestBySupervisorMutationVariables>(DeclineRequestBySupervisorDocument, options);
      }
export type DeclineRequestBySupervisorMutationHookResult = ReturnType<typeof useDeclineRequestBySupervisorMutation>;
export type DeclineRequestBySupervisorMutationResult = Apollo.MutationResult<DeclineRequestBySupervisorMutation>;
export type DeclineRequestBySupervisorMutationOptions = Apollo.BaseMutationOptions<DeclineRequestBySupervisorMutation, DeclineRequestBySupervisorMutationVariables>;