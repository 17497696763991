import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';

import ChevronDown from 'assets/icons/chevron-down';
import { useStore } from 'store';
import OutsideClick from 'components/outsideComponent';
import { MenuItemProps } from '../list';
import { useClientRect } from './hooks/getMeasure';

interface Props {
  item: MenuItemProps;
  scrollTop?: number;
}

const SubMenu = ({ item, scrollTop }: Props) => {
  const location = useLocation();
  const { showSidebarMenu } = useStore();
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { rect, ref } = useClientRect(scrollTop);

  const onMouseMove = useCallback(() => {
    setShowDropdown(value => !value);
  }, []);

  const onMouseEnter = useCallback(() => {
    setShowDropdown(true);
  }, []);

  const onClick = () => {
    setShowDropdown(true);
  };

  const isSubMenuElActive = useMemo(() => {
    return item.menus?.map(el => el.url).includes(location.pathname);
  }, [item.menus, location.pathname]);

  const isMenuOpen = showSidebarMenu ? 'rounded-lg' : 'rounded-full';

  const activeStyle = isSubMenuElActive ? 'nav-link_active' : 'nav-link';

  useEffect(() => {
    if (showSidebarMenu || !showSidebarMenu) setSubMenuActive(false);
  }, [showSidebarMenu]);

  useEffect(() => {
    const menusLinks = item.menus?.map(menu => menu.url);
    if (menusLinks?.includes(window.location.pathname) && showSidebarMenu) {
      setSubMenuActive(true);
    }
  }, [item.menus, showSidebarMenu]);

  return (
    <>
      <ul
        className={clsx('flex flex-col gap-y-1', showSidebarMenu ? '' : 'items-center', item.className)}
        onMouseEnter={onMouseMove}
        onMouseLeave={onMouseMove}
        onClick={onClick}
        ref={ref}
      >
        <li
          className={clsx(activeStyle, isMenuOpen, 'group z-10')}
          onClick={() => {
            if (!showSidebarMenu) return;
            setSubMenuActive(!subMenuActive);
          }}
        >
          <div className="flex justify-between items-center w-full">
            <div className="flex relative gap-x-4">
              {item.icon}
              <span className={clsx(showSidebarMenu ? '' : 'hidden')}>{item.name}</span>
            </div>
            <ChevronDown
              className={clsx(
                'transition-transform transform',
                subMenuActive ? 'rotate-180' : '',
                showSidebarMenu ? '' : 'hidden',
              )}
            />
          </div>
        </li>
        {showSidebarMenu && (
          <li>
            <ul
              className={clsx(
                'flex flex-col ml-5 gap-y-1 transition-all duration-300 origin-top',
                subMenuActive ? 'scale-y-100' : 'scale-y-0 h-0',
              )}
            >
              {item.menus?.map(el => (
                <li key={el.name} className="submenu-item relative">
                  <NavLink
                    to={el.url}
                    className={({ isActive }) => clsx(isMenuOpen, isActive ? 'nav-link_active' : 'nav-link', 'ml-2')}
                  >
                    <span className={clsx('ml-[15px]')}>{el.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
      <OutsideClick
        show={showDropdown}
        setShow={setShowDropdown}
        // triggerRef={ref}
        className={clsx(
          'absolute transform translate-x-full translate-y-1 bg-smena_white shadow-smena rounded-lg z-10 transition-all',
          !showSidebarMenu && showDropdown ? 'opacity-100' : 'opacity-0 pointer-events-none',
        )}
        style={{
          top: rect?.y || 0,
          right: 8,
        }}
      >
        <ul className={clsx('flex flex-col gap-y-1 p-2')} onMouseEnter={onMouseEnter} onMouseLeave={onMouseMove}>
          {item.menus?.map(el => (
            <li key={el.name}>
              <NavLink
                to={el.url}
                style={{ marginLeft: '0px' }}
                className={({ isActive }) => clsx(isActive ? 'nav-link_active' : 'nav-link', 'rounded-lg')}
              >
                <span className={clsx('pointer-events-none block')}>{el.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </OutsideClick>
    </>
  );
};

export default SubMenu;
