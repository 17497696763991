import clsx from 'clsx';
import React from 'react';
import { TIcon } from '../../interfaces/TIcon';

const Clock12 = ({ className }: TIcon) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0002 11.2992C8.65116 11.2992 10.8002 9.15019 10.8002 6.49922C10.8002 3.84825 8.65116 1.69922 6.0002 1.69922C3.34923 1.69922 1.2002 3.84825 1.2002 6.49922C1.2002 9.15019 3.34923 11.2992 6.0002 11.2992ZM6.6002 4.09922C6.6002 3.76785 6.33157 3.49922 6.0002 3.49922C5.66882 3.49922 5.4002 3.76785 5.4002 4.09922V6.49922C5.4002 6.65835 5.46341 6.81096 5.57593 6.92348L7.27299 8.62054C7.5073 8.85485 7.8872 8.85485 8.12152 8.62054C8.35583 8.38623 8.35583 8.00633 8.12152 7.77201L6.6002 6.25069V4.09922Z"
      />
    </svg>
  );
};

export default Clock12;
