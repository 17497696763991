import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { NotifyLetterFragmentDoc } from '../../fragments/generated/NotifyLetter.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ToRemoveNotifyLetterMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ToRemoveNotifyLetterMutation = { __typename?: 'Mutation', toRemoveNotifyLetter: { __typename?: 'NotifyLetter', id: string, name: string, title: string, body?: string | null, status: Types.NotifyLetterStatus, route?: Types.PushRoute | null, updatedAt: any, publicCondition?: { __typename?: 'PublicCondition', recipientsCount: number, useCondition: boolean, pushToUnassigned: Types.PushToUnassignedFilter, id: string, indefinitely: boolean, publishFrom?: any | null, publishTo?: any | null, roles: Array<Types.Role>, interval?: number | null, cities?: Array<{ __typename?: 'City', id: string, name: string }> | null, facilities?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, facilityGroups?: Array<{ __typename?: 'FacilityGroup', id: string, name: string }> | null, positions?: Array<{ __typename?: 'Position', id: string, name: string }> | null } | null } };


export const ToRemoveNotifyLetterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ToRemoveNotifyLetter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"toRemoveNotifyLetter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotifyLetter"}}]}}]}},...NotifyLetterFragmentDoc.definitions]} as unknown as DocumentNode;
export type ToRemoveNotifyLetterMutationFn = Apollo.MutationFunction<ToRemoveNotifyLetterMutation, ToRemoveNotifyLetterMutationVariables>;

/**
 * __useToRemoveNotifyLetterMutation__
 *
 * To run a mutation, you first call `useToRemoveNotifyLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToRemoveNotifyLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toRemoveNotifyLetterMutation, { data, loading, error }] = useToRemoveNotifyLetterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToRemoveNotifyLetterMutation(baseOptions?: Apollo.MutationHookOptions<ToRemoveNotifyLetterMutation, ToRemoveNotifyLetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToRemoveNotifyLetterMutation, ToRemoveNotifyLetterMutationVariables>(ToRemoveNotifyLetterDocument, options);
      }
export type ToRemoveNotifyLetterMutationHookResult = ReturnType<typeof useToRemoveNotifyLetterMutation>;
export type ToRemoveNotifyLetterMutationResult = Apollo.MutationResult<ToRemoveNotifyLetterMutation>;
export type ToRemoveNotifyLetterMutationOptions = Apollo.BaseMutationOptions<ToRemoveNotifyLetterMutation, ToRemoveNotifyLetterMutationVariables>;