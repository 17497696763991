import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getLink } from 'utils/helpers/get/getLink';

export const useReferralProgramHeaderMenu = () => {
  const { settingId } = useParams();

  const CREATE_REFERRAL_ROUTE = '/offers/referrals/create';
  const SETTING_MAIN_REFERRAL_ROUTE = '/offers/leads/setting';

  const { pathname } = useLocation();

  const isCreateRoute = pathname === CREATE_REFERRAL_ROUTE;
  const isSettingMainRoute = pathname === SETTING_MAIN_REFERRAL_ROUTE;

  const information = settingId
    ? getLink(`/offers/leads/setting/${settingId}`)
    : getLink(isCreateRoute ? CREATE_REFERRAL_ROUTE : isSettingMainRoute ? SETTING_MAIN_REFERRAL_ROUTE : '');
  const referrals = getLink(`/offers/referrals/${settingId}`);

  return useMemo(() => {
    const array: { name: string; link: string; disabled?: boolean }[] = [
      {
        name: 'Информация',
        link: information,
      },

      {
        name: 'Рефералы',
        link: referrals,
        disabled: !settingId,
      },
    ];

    return array;
  }, [information, referrals, settingId]);
};
