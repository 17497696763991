import * as Types from '../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFacilityGroupsWithFacilitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFacilityGroupsWithFacilitiesQuery = { __typename?: 'Query', getFacilityGroupsWithFacilities: Array<{ __typename?: 'FacilityGroup', id: string, name: string, facilities: Array<{ __typename?: 'Facility', id: string, name: string }> }> };


export const GetFacilityGroupsWithFacilitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFacilityGroupsWithFacilities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getFacilityGroupsWithFacilities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"facilities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetFacilityGroupsWithFacilitiesQuery__
 *
 * To run a query within a React component, call `useGetFacilityGroupsWithFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityGroupsWithFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityGroupsWithFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFacilityGroupsWithFacilitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetFacilityGroupsWithFacilitiesQuery, GetFacilityGroupsWithFacilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFacilityGroupsWithFacilitiesQuery, GetFacilityGroupsWithFacilitiesQueryVariables>(GetFacilityGroupsWithFacilitiesDocument, options);
      }
export function useGetFacilityGroupsWithFacilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFacilityGroupsWithFacilitiesQuery, GetFacilityGroupsWithFacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFacilityGroupsWithFacilitiesQuery, GetFacilityGroupsWithFacilitiesQueryVariables>(GetFacilityGroupsWithFacilitiesDocument, options);
        }
export function useGetFacilityGroupsWithFacilitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFacilityGroupsWithFacilitiesQuery, GetFacilityGroupsWithFacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFacilityGroupsWithFacilitiesQuery, GetFacilityGroupsWithFacilitiesQueryVariables>(GetFacilityGroupsWithFacilitiesDocument, options);
        }
export type GetFacilityGroupsWithFacilitiesQueryHookResult = ReturnType<typeof useGetFacilityGroupsWithFacilitiesQuery>;
export type GetFacilityGroupsWithFacilitiesLazyQueryHookResult = ReturnType<typeof useGetFacilityGroupsWithFacilitiesLazyQuery>;
export type GetFacilityGroupsWithFacilitiesSuspenseQueryHookResult = ReturnType<typeof useGetFacilityGroupsWithFacilitiesSuspenseQuery>;
export type GetFacilityGroupsWithFacilitiesQueryResult = Apollo.QueryResult<GetFacilityGroupsWithFacilitiesQuery, GetFacilityGroupsWithFacilitiesQueryVariables>;