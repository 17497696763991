import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendChangesToSupervisorMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type SendChangesToSupervisorMutation = { __typename?: 'Mutation', sendChangesToSupervisor: boolean };


export const SendChangesToSupervisorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendChangesToSupervisor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendChangesToSupervisor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type SendChangesToSupervisorMutationFn = Apollo.MutationFunction<SendChangesToSupervisorMutation, SendChangesToSupervisorMutationVariables>;

/**
 * __useSendChangesToSupervisorMutation__
 *
 * To run a mutation, you first call `useSendChangesToSupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChangesToSupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChangesToSupervisorMutation, { data, loading, error }] = useSendChangesToSupervisorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendChangesToSupervisorMutation(baseOptions?: Apollo.MutationHookOptions<SendChangesToSupervisorMutation, SendChangesToSupervisorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendChangesToSupervisorMutation, SendChangesToSupervisorMutationVariables>(SendChangesToSupervisorDocument, options);
      }
export type SendChangesToSupervisorMutationHookResult = ReturnType<typeof useSendChangesToSupervisorMutation>;
export type SendChangesToSupervisorMutationResult = Apollo.MutationResult<SendChangesToSupervisorMutation>;
export type SendChangesToSupervisorMutationOptions = Apollo.BaseMutationOptions<SendChangesToSupervisorMutation, SendChangesToSupervisorMutationVariables>;