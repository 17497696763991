import { ApolloCache } from '@apollo/client';
import {
  FacilitiesPaginatedDocument,
  FacilitiesPaginatedQuery
} from '../../../facilities/api/queries/generated/FacilitiesPaginated';
import { CreateFacilityMutation } from 'pages/facilities/api/mutations/generated/CreateFacility';
import { FacilityFragment } from '../fragments/generated/Facility.fragment';

interface Props {
  data?: CreateFacilityMutation | null;
  groupId?: string | null;
}

export const createFacilityCache = (cache: ApolloCache<any>, { data, groupId }: Props) => {
  const variables = {
    input: {
      page: 1,
      groupId,
      cityId: null,
      isActive: null,
      searchQuery: null,
      sortBy: null,
      sortWay: null,
    },
  };
  const facilitiesPaginationQuery = cache.readQuery<FacilitiesPaginatedQuery>({
    query: FacilitiesPaginatedDocument,
    variables,
  });
  if (!facilitiesPaginationQuery) return;
  if (!data?.createFacility) return;

  const {
    facilitiesPaginated: { count, pages, facilities },
  } = facilitiesPaginationQuery;
  const newFacilities = [...facilities];
  if (newFacilities.length === 25) {
    newFacilities.splice(-1);
  }
  const newFacility = data.createFacility as unknown as FacilityFragment;
  cache.writeQuery<FacilitiesPaginatedQuery>({
    query: FacilitiesPaginatedDocument,
    variables,
    data: {
      facilitiesPaginated: {
        count,
        pages,
        facilities: [newFacility, ...newFacilities],
      },
    },
  });
};
