import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import UserAtSidebar from '../../userAtSidebar';
import Magnifier from 'assets/icons/magnifier';
import { debounce } from 'lodash';
import Skeleton from 'antd/es/skeleton/Skeleton';
import { sortedUsersFn } from '../helpers';
import { useGetWorkersForRequestScheduleLazyQuery } from 'pages/facilityRequests/api/queries/generated/GetWorkersForRequestSchedule';
import { getUserFIO } from 'utils/helpers/userFullName';
import { useSendFreeShiftsByRequestScheduleIdMutation } from '../../../api/mutations/generated/SendFreeShiftsByRequestScheduleId';
import { useFacility } from '../../../../../utils/api/useFacility';

type Props = {
  setShowPopup: (value: boolean) => void;
};

const ScheduleInfo = ({ setShowPopup }: Props) => {
  const [search, setSearch] = useState('');
  const setShowGraphicSidebar = useStore(state => state.setShowGraphicSidebar);
  const { id } = useFacility();
  const { requestSchedule, userIntersect } = useStore();

  const [loadUserIntersectData, { data: usersIntersectData, loading }] = useGetWorkersForRequestScheduleLazyQuery();

  const users = usersIntersectData?.getWorkersForZayavkaSchedule;

  const [sendFreeShiftsByRequestScheduleId, { loading: sendFreeScheduleLoading }] =
    useSendFreeShiftsByRequestScheduleIdMutation();
  const scheduleHasWorkers = useMemo(() => {
    return requestSchedule?.zayavkaShift?.filter(shift => shift.shiftId);
  }, [requestSchedule]);

  const uniqueUsers = useMemo(
    () => [...new Map(scheduleHasWorkers?.map(worker => [worker.shift?.userId, worker])).values()],
    [scheduleHasWorkers],
  );

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const debounceOnChange = debounce(handleChangeSearch, 600);

  const sortedUsers = useMemo(() => sortedUsersFn(users), [users]);

  const handleFreeSchedule = () => {
    if (!requestSchedule || !id) return;
    return sendFreeShiftsByRequestScheduleId({
      variables: {
        input: {
          requestScheduleId: requestSchedule.id,
          facilityId: id,
        },
      },
    })
      .then(() => {
        successToast('График стал свободным');
      })
      .catch(err => {
        errorToast(errorHandler(err));
      })
      .finally(() => {
        setShowGraphicSidebar(false);
      });
  };

  useEffect(() => {
    if (requestSchedule?.id) {
      loadUserIntersectData({
        variables: {
          zayavkaScheduleId: requestSchedule.id,
          searchQuery: search,
        },
      });
    }
  }, [loadUserIntersectData, requestSchedule?.id, search]);

  return (
    <>
      <div className="flex flex-col gap-y-6">
        {!uniqueUsers.length && (
          <div className="relative">
            <span className="absolute top-0 left-0 transform translate-x-1 translate-y-1">
              <Magnifier />
            </span>
            <input
              className="input-primary pl-8"
              onChange={debounceOnChange}
              type="search"
              name="search"
              id="search"
              placeholder="Поиск"
            />
          </div>
        )}

        <div className="grid scheduleHasWorkers gap-x-1">
          <span className="Subtitle1">Исполнители:</span>
          <div className="col-span-1">
            {uniqueUsers.length ? (
              uniqueUsers.map((worker, index, array) => (
                <React.Fragment key={worker.shift?.userId}>
                  <Link className="Subtitle1 text-primary" to={`/users/${worker.shift?.userId}`}>
                    {getUserFIO(worker.shift?.user)}
                  </Link>
                  {index !== array.length - 1 && <span className="Subtitle1 text-primary">, </span>}
                </React.Fragment>
              ))
            ) : (
              <span className="Subtitle1 text-smena_text-secondary">отсутствуют</span>
            )}
          </div>
        </div>
        {uniqueUsers.length ? (
          <div className="flex justify-end">
            <button className="btn-reject_secondary" type="button" onClick={() => setShowPopup(true)}>
              Освободить весь график
            </button>
          </div>
        ) : loading ? (
          <Skeleton active />
        ) : users && users.length ? (
          <>
            <div className="border-b border-smena_gray-30">
              <div className="graphicSearchUserTable p-4 border-t border-b border-smena_gray-30 Table-H1 text-smena_text-secondary">
                <span>Исполнитель</span>
                <span>Занятость</span>
              </div>
              <div className={'overflow-auto max-h-[345px]'}>
                {sortedUsers?.map(user => <UserAtSidebar key={user.id} user={user} />)}
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <button className="btn-primary self-end" type="submit" disabled={!userIntersect}>
                Назначить на весь график
              </button>
            </div>
          </>
        ) : (
          <span className="Subtitle1">
            По вашему запросу <span className="font-bold">{search}</span> ничего не нашлось
          </span>
        )}
        <button
          className="btn-stroke self-end"
          type="button"
          disabled={sendFreeScheduleLoading}
          onClick={handleFreeSchedule}
        >
          Сделать график свободным
        </button>
      </div>
    </>
  );
};

export default ScheduleInfo;
