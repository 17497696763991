import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersOnMapByFacilityQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UsersOnMapByFacilityQuery = { __typename?: 'Query', usersOnMapByFacility: Array<{ __typename?: 'MapPlaceModel', address?: string | null, city?: string | null, name: string, point?: string | null, shopName: string, positions?: Array<{ __typename?: 'MapProfessionModel', name: string, erp: { __typename?: 'MapMetrikaModel', in4km: number, in10km: number, inCity: number }, smena: { __typename?: 'MapMetrikaModel', in4km: number, in10km: number, inCity: number } }> | null }> };


export const UsersOnMapByFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UsersOnMapByFacility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersOnMapByFacility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"point"}},{"kind":"Field","name":{"kind":"Name","value":"shopName"}},{"kind":"Field","name":{"kind":"Name","value":"positions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"erp"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"in4km"}},{"kind":"Field","name":{"kind":"Name","value":"in10km"}},{"kind":"Field","name":{"kind":"Name","value":"inCity"}}]}},{"kind":"Field","name":{"kind":"Name","value":"smena"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"in4km"}},{"kind":"Field","name":{"kind":"Name","value":"in10km"}},{"kind":"Field","name":{"kind":"Name","value":"inCity"}}]}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUsersOnMapByFacilityQuery__
 *
 * To run a query within a React component, call `useUsersOnMapByFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersOnMapByFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersOnMapByFacilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersOnMapByFacilityQuery(baseOptions?: Apollo.QueryHookOptions<UsersOnMapByFacilityQuery, UsersOnMapByFacilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersOnMapByFacilityQuery, UsersOnMapByFacilityQueryVariables>(UsersOnMapByFacilityDocument, options);
      }
export function useUsersOnMapByFacilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersOnMapByFacilityQuery, UsersOnMapByFacilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersOnMapByFacilityQuery, UsersOnMapByFacilityQueryVariables>(UsersOnMapByFacilityDocument, options);
        }
export function useUsersOnMapByFacilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersOnMapByFacilityQuery, UsersOnMapByFacilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersOnMapByFacilityQuery, UsersOnMapByFacilityQueryVariables>(UsersOnMapByFacilityDocument, options);
        }
export type UsersOnMapByFacilityQueryHookResult = ReturnType<typeof useUsersOnMapByFacilityQuery>;
export type UsersOnMapByFacilityLazyQueryHookResult = ReturnType<typeof useUsersOnMapByFacilityLazyQuery>;
export type UsersOnMapByFacilitySuspenseQueryHookResult = ReturnType<typeof useUsersOnMapByFacilitySuspenseQuery>;
export type UsersOnMapByFacilityQueryResult = Apollo.QueryResult<UsersOnMapByFacilityQuery, UsersOnMapByFacilityQueryVariables>;