import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ShiftFragmentDoc } from '../../fragments/generated/Shift.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefuseShiftMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type RefuseShiftMutation = { __typename?: 'Mutation', refuseShift: { __typename?: 'Shift', id: string, dateFrom: any, dateEnd: any, comment?: string | null, duration: string, lunchDuration?: number | null, facilityId: string, hourRate?: number | null, positionId: string, stage: Types.ShiftStage, updatedAt: any, userId: string, user?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null } | null } };


export const RefuseShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RefuseShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refuseShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Shift"}}]}}]}},...ShiftFragmentDoc.definitions]} as unknown as DocumentNode;
export type RefuseShiftMutationFn = Apollo.MutationFunction<RefuseShiftMutation, RefuseShiftMutationVariables>;

/**
 * __useRefuseShiftMutation__
 *
 * To run a mutation, you first call `useRefuseShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefuseShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refuseShiftMutation, { data, loading, error }] = useRefuseShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefuseShiftMutation(baseOptions?: Apollo.MutationHookOptions<RefuseShiftMutation, RefuseShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefuseShiftMutation, RefuseShiftMutationVariables>(RefuseShiftDocument, options);
      }
export type RefuseShiftMutationHookResult = ReturnType<typeof useRefuseShiftMutation>;
export type RefuseShiftMutationResult = Apollo.MutationResult<RefuseShiftMutation>;
export type RefuseShiftMutationOptions = Apollo.BaseMutationOptions<RefuseShiftMutation, RefuseShiftMutationVariables>;