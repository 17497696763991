import { Link } from 'react-router-dom';
import Plus from 'assets/icons/plus';

const GroupsFilter = () => {
  return (
    <div className="filter-bar shadow-smena">
      <Link to="/groups/create" className="inline-flex">
        <button type="button" className="btn-primary_big flex items-center gap-x-2">
          <Plus className="text-smena_white" />
          <span className="Button1">Новая группа</span>
        </button>
      </Link>
    </div>
  );
};

export default GroupsFilter;
