import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useStore } from 'store';
import { getNavLinkClassname } from 'utils/helpers/get/getNavLinkClassname';
import { useRef, useState } from 'react';
import { MenuItemProps } from '../list';
import Tooltip from 'antd/lib/tooltip';

interface Props {
  item: MenuItemProps;
  target?: React.HTMLAttributeAnchorTarget;
}

const MenuElement = ({ item, target }: Props) => {
  const { showSidebarMenu } = useStore();
  const [open, setOpen] = useState(false);
  const { url, name, icon, className } = item;
  const linkRef = useRef<HTMLAnchorElement>(null);

  return (
    <Tooltip
      placement="right"
      title={name}
      open={open}
      onOpenChange={open => {
        if (showSidebarMenu) return;
        setOpen(open);
      }}
    >
      <NavLink
        to={url}
        className={({ isActive }) =>
          clsx(
            getNavLinkClassname(isActive, showSidebarMenu),
            className,
            showSidebarMenu ? '' : 'justify-center',
            'relative gap-x-[15px]',
          )
        }
        end={name === 'Главная'}
        target={target}
        ref={linkRef}
      >
        <div className="flex gap-x-4">
          {icon}
          <span className={clsx(showSidebarMenu ? '' : 'hidden')}>{name}</span>
        </div>
      </NavLink>
    </Tooltip>
  );
};

export default MenuElement;
