import { StatisticPeriod } from 'generated/graphql';

export const lastTimeLength = (time: StatisticPeriod, sortedData?: any[]) => {
  if (sortedData) {
    if (time === StatisticPeriod.Year) {
      const yearArray = sortedData.slice(6, 12).map(data => data.value);
      if (yearArray.length) {
        return yearArray.reduce((prev, curr) => prev + curr);
      }
      return 0;
    }
    if (time === StatisticPeriod.AllTime) {
      const sortedDataLength = sortedData.length;
      const allTimeArray = sortedData.slice(sortedDataLength - 12, sortedDataLength).map(data => data.value);
      if (allTimeArray.length) {
        return allTimeArray.reduce((prev, curr) => prev + curr);
      }
      return 0;
    }
    return sortedData.length ? sortedData[sortedData.length - 1].value : 0;
  }
  return 0;
};
