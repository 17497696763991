import { useGetLegalEntitiesLazyQuery } from 'pages/facility/api/queries/generated/GetLegalEntities';
import { useEffect } from 'react';

export const useLegalEntities = () => {
  const [getLegalEntities, { data, loading, error }] = useGetLegalEntitiesLazyQuery();

  useEffect(() => {
    getLegalEntities();
  }, [getLegalEntities]);

  const legalEntities = data?.getLegalEntities;
  return { legalEntities, loading, error };
};
