import React, { useRef, useState } from 'react';
import { Map, YMaps, YMapsApi } from 'react-yandex-maps';
import SearchPin from './searchPin';
import ZoomComponentIn from './zoomComponentIn';
import ZoomComponentOut from './zoomComponentOut';
import { useVacanciesMap } from '../../store';
import CustomCluster from './cluster';

const YandexMap = () => {
  const [map, setMap] = useState<YMapsApi | null>(null);
  const pin = useVacanciesMap(state => state.searchPinLocation);
  const center = useVacanciesMap(state => state.center);
  const zoom = useVacanciesMap(state => state.zoom);
  const mapRef = useRef<any>();
  return (
    <div className={'relative h-full w-full'}>
      <YMaps query={{ apikey: process.env.REACT_APP_YA_MAP_API }}>
        <Map
          onLoad={ymaps => {
            if (ymaps && !map) {
              setMap(ymaps);
            }
          }}
          instanceRef={ref => {
            mapRef.current = ref;
          }}
          state={{ center, zoom }}
          width="100%"
          height="100%"
          modules={['clusterer.addon.balloon', 'templateLayoutFactory']}
        >
          <CustomCluster ymaps={map} mapRef={mapRef} />
          {pin ? <SearchPin pin={pin} ymaps={map} /> : null}
          <ZoomComponentIn ymaps={map} />
          <ZoomComponentOut ymaps={map} />
        </Map>
      </YMaps>
    </div>
  );
};

export default YandexMap;
