import { NavLink } from 'react-router-dom';

import { getNavLinkClassNameMobile } from 'utils/helpers/get/getNavLinkClassname';
import { MenuItemProps } from '../list';
import React from 'react';

const MenuElementMobile = React.memo(({ item }: { item: MenuItemProps }) => {
  const { id, url, name, icon, className } = item;

  return (
    <NavLink
      to={url}
      key={id}
      className={({ isActive }) => getNavLinkClassNameMobile(isActive, className)}
      end={name === 'Главная'}
    >
      <div className="relative">{icon}</div>
      <span className="ml-[15px]">{name}</span>
    </NavLink>
  );
});

export default MenuElementMobile;
