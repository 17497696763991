import StarIcon from 'assets/icons/StarIcon';
import Rate from 'antd/es/rate';

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  defaultValue?: number;
  disabled?: boolean;
}

const RateComponent = ({ onChange, value, defaultValue, disabled }: Props) => {
  return (
    <Rate
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      character={<StarIcon />}
      style={{ color: '#F9CA3E', fontSize: 10 }}
      disabled={disabled}
    />
  );
};

export default RateComponent;
