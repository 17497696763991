import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ConfigProvider from 'antd/es/config-provider';
import locale from 'antd/lib/locale/ru_RU';
import AppRoutes from './routes';
import { ApolloProvider } from '@apollo/client';
import dayjs from 'dayjs';
import { initializeSentry } from './config/sentry';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createApolloClient } from './config/apolloClient';
import { Slide, ToastContainer } from 'react-toastify';

dayjs.locale('ru');
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const client = createApolloClient();
initializeSentry();

const App = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ConfigProvider
          locale={locale}
          theme={{ token: { motion: false } }}
          getPopupContainer={(triggerNode?: HTMLElement) => {
            return (triggerNode?.parentNode as HTMLElement) || document.body;
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <AppRoutes />
            <ToastContainer
              stacked
              transition={Slide}
              position="top-center"
              autoClose={5000}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              draggable
              pauseOnHover
            />
          </DndProvider>
        </ConfigProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
