import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ReportFragmentDoc } from '../../fragments/generated/Report.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDailyReportMutationVariables = Types.Exact<{
  input: Types.CreateDailyReportInput;
}>;


export type CreateDailyReportMutation = { __typename?: 'Mutation', createDailyReport: { __typename?: 'Report', id: string, type: Types.ReportType, filePath: string } };


export const CreateDailyReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateDailyReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateDailyReportInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createDailyReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Report"}}]}}]}},...ReportFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateDailyReportMutationFn = Apollo.MutationFunction<CreateDailyReportMutation, CreateDailyReportMutationVariables>;

/**
 * __useCreateDailyReportMutation__
 *
 * To run a mutation, you first call `useCreateDailyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDailyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDailyReportMutation, { data, loading, error }] = useCreateDailyReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDailyReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateDailyReportMutation, CreateDailyReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDailyReportMutation, CreateDailyReportMutationVariables>(CreateDailyReportDocument, options);
      }
export type CreateDailyReportMutationHookResult = ReturnType<typeof useCreateDailyReportMutation>;
export type CreateDailyReportMutationResult = Apollo.MutationResult<CreateDailyReportMutation>;
export type CreateDailyReportMutationOptions = Apollo.BaseMutationOptions<CreateDailyReportMutation, CreateDailyReportMutationVariables>;