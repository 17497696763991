import { useEffect, useMemo, useState } from 'react';
import { FormikHandlers, FormikState } from 'formik';
import SimpleSelect from 'ui/select';
import clsx from 'clsx';
import TreeSelect from 'antd/es/tree-select';
import OutsideClick from 'components/outsideComponent';
import SimpleInput from 'ui/input';
import SimpleCheckbox from 'ui/checkbox';
import { periodOptions } from 'utils/helpers/constVariables';
import { usePositions } from 'utils/api/usePositions';
import { useParams } from 'react-router-dom';
import useVacancyPage from '../util/hooks';
import { useSearchCitiesWithFacilitiesLazyQuery } from '../../facilityCasing/api/query/generated/SearchCitiesWithFacilities';
import { SearchCityFragment } from '../../facilityGroupCasing/api/fragment/generated/SearchCity.fragment';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { useVacancy } from 'utils/api/useVacancy';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { IMask } from 'react-imask';
import { useGetVacancyStartTimeLazyQuery } from '../api/queries/generated/GetVacancyStartTime';
import { auchanLunchDurationTypes, lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import { timeDifference } from 'utils/helpers/timeDifference';
import dayjs from 'dayjs';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';

interface Props {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikState<any>['errors'];
  touched: FormikState<any>['touched'];
  setFieldValue: (name: string, value: any) => void;
  setCasingId: any;
  setFaciliesIds: any;
}

const MainInfo = ({ values, handleChange, setFieldValue, errors, touched, setCasingId, setFaciliesIds }: Props) => {
  const param = useParams();
  const facilityGroupId = param.id;
  const facilityId = param.facilityId;
  const { positions } = usePositions();
  const { facilitiesGroups } = useSelectFacilityGroups();
  const { facilities } = useSelectFacilities();
  const { vacancyId } = useVacancy();
  const { facGroupId } = useVacancyPage();
  const [startTime, setStartTime] = useState<string[]>([]);
  const [endTime, setEndTime] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<SearchCityFragment[]>([]);
  const [showStartTimeSuggestions, setShowStartTimeSuggestions] = useState(false);
  const [showEndTimeSuggestions, setShowEndTimeSuggestions] = useState(false);

  const filteredFacilities = filterFacilities(values?.facilityGroupId, facilities);

  const { isAuchan } = useFacilityGroupById(values.facilityGroupId);

  setCasingId(values.positionId);

  const [loadTime] = useGetVacancyStartTimeLazyQuery({
    onCompleted: data => {
      if (data) {
        setStartTime(data.getVacancyStartTime.timesStart);
        setEndTime(data.getVacancyStartTime.timesEnd);
      }
    },
  });

  const [findData] = useSearchCitiesWithFacilitiesLazyQuery({
    onCompleted: data => {
      setTreeData(data?.searchCitiesWithFacilities || []);
    },
  });

  const { SHOW_PARENT } = TreeSelect;
  const tProps = {
    treeData,
    fieldNames: {
      label: 'name',
      value: 'id',
      children: 'facilities',
    },
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Введите название города или объекта',
    style: {
      width: '100%',
    },
  };

  const masked = IMask.createMask({
    mask: '00:00',
  });

  const lunchOptions = useMemo(() => (isAuchan ? auchanLunchDurationTypes : lunchDurationTypes), [isAuchan]);
  const duration = timeDifference(dayjs(values.timeStart, 'HH:mm'), dayjs(values.timeEnd, 'HH:mm'));

  const changeLunchDuration = () => {
    if (isAuchan) {
      setFieldValue('lunchDuration', lunchDurationHandle(duration));
    }
  };

  useEffect(() => {
    if (!facGroupId) return;
    loadTime({
      variables: {
        facilityGroupId: facGroupId,
      },
    });
  }, [facGroupId, loadTime]);

  const handleCityAndFacilityChange = (value: string[]) => {
    setFieldValue('cityAndFacility', value);

    const selectedFacilitiesIds: any[] = [];
    value.forEach(val => {
      const city = treeData.find(city => city.id === val);
      if (city && city.facilities) {
        city.facilities.forEach(facility => selectedFacilitiesIds.push(facility.id));
      } else {
        selectedFacilitiesIds.push(val);
      }
    });

    setFaciliesIds(selectedFacilitiesIds);
  };

  return (
    <>
      <span className="Subtitle1 text-smena_text-secondary">Основная информация</span>
      <div className="flex flex-col gap-y-4">
        <div className="grid grid-cols-3 gap-4">
          <SimpleSelect
            divClassName="col-span-1"
            label="Профессия"
            onChange={value => {
              setFieldValue('positionId', value);
            }}
            value={values.positionId}
            placeholder="Не выбран"
            options={positions}
            validation
            error={touched.positionId && errors.positionId ? errors.positionId : ''}
          />
          {!facilityId && !facilityGroupId && (
            <SimpleSelect
              divClassName="col-span-1"
              label="Группа"
              popupClassName={'max-w-[300px]'}
              onChange={value => {
                setFieldValue('facilityGroupId', value);
                loadTime({
                  variables: {
                    facilityGroupId: value,
                  },
                });
                findData({
                  variables: {
                    facilityGroupId: value,
                  },
                });
              }}
              value={values.facilityGroupId}
              placeholder="Не выбран"
              options={facilitiesGroups}
              validation
              error={touched.facilityGroupId && errors.facilityGroupId ? errors.facilityGroupId : ''}
            />
          )}
          {vacancyId && !facilityId && (
            <SimpleSelect
              divClassName={clsx(!facilityId && !facilityGroupId ? 'col-span-1' : 'col-span-2')}
              label="Объект"
              onChange={value => {
                setFieldValue('facilityId', value);
              }}
              value={values.facilityId}
              placeholder="Не выбран"
              options={filteredFacilities}
              validation
              error={touched.facilityId && errors.facilityId ? errors.facilityId : ''}
            />
          )}
          {!vacancyId && !facilityId && (
            <div className={clsx(!facilityId && !facilityGroupId ? 'col-span-1' : 'col-span-2')}>
              <span className={'Subtitle2'}>Город и объект</span>
              <TreeSelect
                {...tProps}
                disabled={!values.facilityGroupId}
                filterTreeNode={(val, node) => {
                  return node.name.includes(val);
                }}
                onChange={handleCityAndFacilityChange}
                maxTagCount={'responsive'}
                value={values.cityAndFacility}
              />
            </div>
          )}
        </div>
        <div className="flex gap-4">
          <SimpleSelect
            divClassName="flex-[1_0]"
            label="Обед"
            placeholder={'Обед'}
            sort={false}
            onChange={value => {
              if (!isAuchan) {
                setFieldValue('lunchDuration', Number(value));
              }
            }}
            value={values.lunchDuration}
            options={lunchOptions}
            disabled={isAuchan}
          />
          <OutsideClick show={showStartTimeSuggestions} setShow={setShowStartTimeSuggestions} className={'flex-[1_0]'}>
            <div className={'relative'}>
              <SimpleInput
                label="Начало смены"
                required={false}
                onChange={e => {
                  const input = e.target as HTMLInputElement;
                  masked.resolve(input.value);
                  setFieldValue('timeStart', masked.value);
                }}
                onBlur={changeLunchDuration}
                onFocus={() => {
                  setShowStartTimeSuggestions(true);
                }}
                autoComplete={'off'}
                maxLength={5}
                value={values.timeStart}
                type="text"
                name="timeStart"
                placeholder="06:00"
                error={touched.timeStart && errors.timeStart ? errors.timeStart : ''}
              />
              {showStartTimeSuggestions && startTime.length ? (
                <div
                  className={
                    'flex flex-col absolute top-14 left-0 bg-smena_white smena_shadow border rounded-lg w-full mt-1 py-2 px-1 max-h-[200px] overflow-auto z-10'
                  }
                >
                  {startTime.map(el => {
                    if (el.includes(values.timeStart)) {
                      return (
                        <span
                          key={el}
                          className={'p-2 Body2 cursor-pointer hover:bg-smena_bb-background_light rounded-lg'}
                          onClick={() => {
                            setFieldValue('timeStart', el);
                            changeLunchDuration();
                            setShowStartTimeSuggestions(false);
                          }}
                        >
                          {el}
                        </span>
                      );
                    }
                  })}
                </div>
              ) : null}
            </div>
          </OutsideClick>
          <OutsideClick show={showEndTimeSuggestions} setShow={setShowEndTimeSuggestions} className={'flex-[1_0]'}>
            <div className={'relative'}>
              <SimpleInput
                label="Окончание смены"
                autoComplete={'off'}
                placeholder="20:00"
                required={false}
                onChange={e => {
                  const input = e.target as HTMLInputElement;
                  masked.resolve(input.value);
                  setFieldValue('timeEnd', masked.value);
                }}
                onBlur={changeLunchDuration}
                onFocus={() => {
                  setShowEndTimeSuggestions(true);
                }}
                maxLength={5}
                value={values.timeEnd}
                type="text"
                name="timeEnd"
                error={touched.timeEnd && errors.timeEnd ? errors.timeEnd : ''}
              />
              {showEndTimeSuggestions && endTime.length ? (
                <div
                  className={
                    'flex flex-col absolute top-14 left-0 bg-smena_white smena_shadow border rounded-lg w-full mt-1 py-2 px-1 max-h-[200px] overflow-auto z-10'
                  }
                >
                  {endTime.map(el => {
                    if (el.includes(values.timeEnd)) {
                      return (
                        <span
                          key={el}
                          className={'p-2 Body2 cursor-pointer hover:bg-smena_bb-background_light rounded-lg'}
                          onClick={() => {
                            setFieldValue('timeEnd', el);
                            changeLunchDuration();
                            setShowEndTimeSuggestions(false);
                          }}
                        >
                          {el}
                        </span>
                      );
                    }
                  })}
                </div>
              ) : null}
            </div>
          </OutsideClick>

          <SimpleSelect
            divClassName="flex-[1_0]"
            label="Период"
            onChange={value => {
              setFieldValue('period', value);
            }}
            value={values.period}
            options={periodOptions}
            placeholder={'День/Ночь'}
            error={touched.period && errors.period ? errors.period : ''}
          />
        </div>

        <SimpleCheckbox
          divClassName="flex items-center"
          label="Активная"
          required={false}
          onChange={handleChange}
          checked={values.isActive}
          name="isActive"
        />
      </div>
    </>
  );
};

export default MainInfo;
