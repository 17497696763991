import { Bonus } from './form';
import { PromotionEntityFragment } from 'pages/facilityGroup/api/fragments/generated/PromotionEntity.fragment';
import { Link } from 'react-router-dom';
import Modal from 'ui/modal';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  promotion: Bonus | PromotionEntityFragment;
};

const FacilitiesPopup = ({ show, setShow, promotion }: Props) => {
  const showHandler = () => {
    setShow(value => !value);
  };
  return (
    <Modal
      title="Где были изменены настройки"
      open={show}
      onOk={showHandler}
      footer={[
        <button key={'ok'} className="btn-primary" type="button" onClick={showHandler}>
          Ок
        </button>,
      ]}
    >
      <ol className="pl-4">
        {promotion.childPromotions?.map(el => (
          <li key={el.facility?.id} className={'list-decimal link'}>
            <Link to={`facilities/${el.facility!.id}`} target={'_blank'}>
              {el.facility?.name}
            </Link>
          </li>
        ))}
      </ol>
    </Modal>
  );
};

export default FacilitiesPopup;
