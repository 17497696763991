import React, { useState } from 'react';
import { errorToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import TimesheetLoader from 'pages/timesheets/ui/Loader';
import dayjs from 'dayjs';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import { ISO_DATE } from 'utils/helpers/constVariables';
import { useCreateReportEverythingIsPossibleBonusMutation } from '../api/mutations/generated/CreateReportEverythingIsPossibleBonus';

const EverythingIsPossibleReport = () => {
  const [value, setValue] = useState<DayjsOrNull>(null);
  const [error, setError] = useState('');
  const [resLink, setResLink] = useState('');
  const [createReportEverythingIsPossibleBonus, { loading }] = useCreateReportEverythingIsPossibleBonusMutation();
  return (
    <div className="xs:col-span-5 col-span-full h-fit p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
      <span className="Subtitle1 text-smena_text-secondary">Акция “Все возможно”</span>
      <form
        className="flex xs:flex-row flex-col xs:items-end gap-x-5 gap-y-3 flex-wrap"
        onSubmit={e => {
          e.preventDefault();
          if (value) {
            setError('');
            createReportEverythingIsPossibleBonus({
              variables: { startOfMonth: value.startOf('months').format(ISO_DATE) },
            })
              .then(response => {
                const data = response.data?.createReportEverythingIsPossibleBonus;
                if (!data) return;
                const filePath = data.filePath;
                const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
                setResLink(url);
              })
              .catch(e => {
                errorToast(errorHandler(e));
              });
          } else {
            setError('Обязательное поле');
          }
        }}
      >
        <DatePickerInput
          divClassName="w-[180px]"
          label="Год и месяц"
          format="MMM YYYY"
          value={value}
          placeholder={dayjs().format('MM.YYYY')}
          onChange={newValue => {
            setValue(newValue);
            setResLink('');
          }}
          minDate={dayjs('2020-01')}
          maxDate={dayjs()}
          picker="month"
          error={error}
        />
        <div className="">
          <button type="submit" disabled={loading || Boolean(resLink)} className="btn-secondary">
            Сформировать
          </button>
        </div>
      </form>

      <TimesheetLoader loading={loading} resLink={resLink} />
    </div>
  );
};

export default EverythingIsPossibleReport;
