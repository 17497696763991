import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ScheduleFragmentDoc } from '../../fragments/generated/Schedule.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeCustomScheduleMutationVariables = Types.Exact<{
  inputs: Array<Types.SingleShiftInput> | Types.SingleShiftInput;
}>;


export type ChangeCustomScheduleMutation = { __typename?: 'Mutation', changeCustomSchedule: { __typename?: 'Schedule', id: string, createdAt: any, facilityId: string, updatedAt: any, userId: string, shifts?: Array<{ __typename?: 'Shift', id: string, dateFrom: any, dateEnd: any, duration: string, lunchDuration?: number | null, scheduleId?: string | null, comment?: string | null, authorId: string, stage: Types.ShiftStage, rejectedById?: string | null, positionId: string, userId: string, isProbation: boolean, zayavkaId?: string | null, schedule?: { __typename?: 'Schedule', id: string, scheduleTypeId?: string | null, dayInterleaving?: number | null, dateTo?: any | null, dateFrom?: any | null, isCustom: boolean } | null, marks: Array<{ __typename?: 'Mark', status: Types.MarkStatus, statusMessage: string, type: Types.MarkType, time: any, latitude?: string | null, longitude?: string | null, distance?: number | null }>, facility?: { __typename?: 'Facility', id: string, name: string, latitude: string, longitude: string, radius: number, facilityGroupId?: string | null } | null, zayavka?: { __typename?: 'Zayavka', number: number } | null }> | null } };


export const ChangeCustomScheduleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeCustomSchedule"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inputs"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SingleShiftInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeCustomSchedule"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inputs"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inputs"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Schedule"}}]}}]}},...ScheduleFragmentDoc.definitions]} as unknown as DocumentNode;
export type ChangeCustomScheduleMutationFn = Apollo.MutationFunction<ChangeCustomScheduleMutation, ChangeCustomScheduleMutationVariables>;

/**
 * __useChangeCustomScheduleMutation__
 *
 * To run a mutation, you first call `useChangeCustomScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCustomScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCustomScheduleMutation, { data, loading, error }] = useChangeCustomScheduleMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useChangeCustomScheduleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCustomScheduleMutation, ChangeCustomScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCustomScheduleMutation, ChangeCustomScheduleMutationVariables>(ChangeCustomScheduleDocument, options);
      }
export type ChangeCustomScheduleMutationHookResult = ReturnType<typeof useChangeCustomScheduleMutation>;
export type ChangeCustomScheduleMutationResult = Apollo.MutationResult<ChangeCustomScheduleMutation>;
export type ChangeCustomScheduleMutationOptions = Apollo.BaseMutationOptions<ChangeCustomScheduleMutation, ChangeCustomScheduleMutationVariables>;