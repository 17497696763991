import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptRequestByDirectorMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type AcceptRequestByDirectorMutation = { __typename?: 'Mutation', acceptZayavkaByDirector: boolean };


export const AcceptRequestByDirectorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptRequestByDirector"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptZayavkaByDirector"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type AcceptRequestByDirectorMutationFn = Apollo.MutationFunction<AcceptRequestByDirectorMutation, AcceptRequestByDirectorMutationVariables>;

/**
 * __useAcceptRequestByDirectorMutation__
 *
 * To run a mutation, you first call `useAcceptRequestByDirectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptRequestByDirectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptRequestByDirectorMutation, { data, loading, error }] = useAcceptRequestByDirectorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptRequestByDirectorMutation(baseOptions?: Apollo.MutationHookOptions<AcceptRequestByDirectorMutation, AcceptRequestByDirectorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptRequestByDirectorMutation, AcceptRequestByDirectorMutationVariables>(AcceptRequestByDirectorDocument, options);
      }
export type AcceptRequestByDirectorMutationHookResult = ReturnType<typeof useAcceptRequestByDirectorMutation>;
export type AcceptRequestByDirectorMutationResult = Apollo.MutationResult<AcceptRequestByDirectorMutation>;
export type AcceptRequestByDirectorMutationOptions = Apollo.BaseMutationOptions<AcceptRequestByDirectorMutation, AcceptRequestByDirectorMutationVariables>;