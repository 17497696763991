import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { WorkerFacilityWorkPostsFragmentDoc } from '../../../../user/api/fragments/generated/WorkerFacilityWorkPosts.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnassignedWorkerFromFacilityGroupMutationVariables = Types.Exact<{
  input: Types.UnassignWorkerFromFacilityGroupInput;
}>;


export type UnassignedWorkerFromFacilityGroupMutation = { __typename?: 'Mutation', unassignWorkerFromFacilityGroup: Array<{ __typename?: 'WorkerFacilityWorkPostsOutput', workposts: Array<{ __typename?: 'WorkPostExperienceOutput', assignedAt: any, positionId: string, facilityId: string, userId: string, status: Types.WorkPostStatus, userExperience: Array<{ __typename?: 'UserExperience', assignedAt?: any | null, unassignAt?: any | null }> }> }> };


export const UnassignedWorkerFromFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnassignedWorkerFromFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UnassignWorkerFromFacilityGroupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unassignWorkerFromFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WorkerFacilityWorkPosts"}}]}}]}},...WorkerFacilityWorkPostsFragmentDoc.definitions]} as unknown as DocumentNode;
export type UnassignedWorkerFromFacilityGroupMutationFn = Apollo.MutationFunction<UnassignedWorkerFromFacilityGroupMutation, UnassignedWorkerFromFacilityGroupMutationVariables>;

/**
 * __useUnassignedWorkerFromFacilityGroupMutation__
 *
 * To run a mutation, you first call `useUnassignedWorkerFromFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignedWorkerFromFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignedWorkerFromFacilityGroupMutation, { data, loading, error }] = useUnassignedWorkerFromFacilityGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignedWorkerFromFacilityGroupMutation(baseOptions?: Apollo.MutationHookOptions<UnassignedWorkerFromFacilityGroupMutation, UnassignedWorkerFromFacilityGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignedWorkerFromFacilityGroupMutation, UnassignedWorkerFromFacilityGroupMutationVariables>(UnassignedWorkerFromFacilityGroupDocument, options);
      }
export type UnassignedWorkerFromFacilityGroupMutationHookResult = ReturnType<typeof useUnassignedWorkerFromFacilityGroupMutation>;
export type UnassignedWorkerFromFacilityGroupMutationResult = Apollo.MutationResult<UnassignedWorkerFromFacilityGroupMutation>;
export type UnassignedWorkerFromFacilityGroupMutationOptions = Apollo.BaseMutationOptions<UnassignedWorkerFromFacilityGroupMutation, UnassignedWorkerFromFacilityGroupMutationVariables>;