import React from 'react';
import { IColumns } from 'interfaces/IColumns';
import clsx from 'clsx';

interface Props {
  column: IColumns;
}

const PromotionColumn = ({ column }: Props) => {
  return (
    <div
      className={clsx(column.header_className, column.sorted ? 'cursor-pointer' : '', 'flex gap-x-2')}
      id={column.name}
    >
      <span className="pointer-events-none">{column.Header}</span>
    </div>
  );
};

export default PromotionColumn;
