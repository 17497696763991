import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartType, Role, StatisticPeriod } from 'generated/graphql';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import SimpleSelect from 'ui/select';
import ColoredCircle from './ColoredCircle';
import { getAllUsersChartLengthHandler, getChartLengthHandler } from './helpers';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import TodaySkeleton from '../../ui/skeleton/TodaySkeleton';
import isNull from 'lodash/isNull';
import { useMeRole } from 'store/hooks/useMeRole';
import { useGetStatisticLazyQuery } from 'pages/main/api/queries/generated/GetStatistic';

export const Today = () => {
  const localStorageFacility = localStorage.getItem('rightNowChartFacilityId');
  const localStorageGroup = localStorage.getItem('rightNowChartFacilityGroup');

  const { facilities } = useSelectFacilities();
  const { facilitiesGroups } = useSelectFacilityGroups();

  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  const isMeAdmin = useMeRole([Role.Admin]);
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeDirector = useMeRole([Role.ClientDirector]);
  const isMeManager = useMeRole([Role.ClientManager]);

  const [facilityGroupId, setFacilityGroupId] = useState<string | null | undefined>(undefined);
  const [facilityId, setFacilityId] = useState<string | null | undefined>(undefined);

  const filteredFacilities = useMemo(
    () => filterFacilities(facilityGroupId, facilities),
    [facilities, facilityGroupId],
  );

  const [loadData, { data, loading }] = useGetStatisticLazyQuery();

  const acceptedShifts = useMemo(() => getChartLengthHandler({ data, chartType: ChartType.AcceptedShifts }), [data]);
  const usersAtShift = useMemo(() => getChartLengthHandler({ data, chartType: ChartType.WorkingShifts }), [data]);
  const latecomers = useMemo(() => getChartLengthHandler({ data, chartType: ChartType.Latecomers }), [data]);
  const getAllUsersChartLength = useMemo(
    () =>
      getAllUsersChartLengthHandler({
        data,
        chartTypes: [ChartType.CountWorkers, ChartType.CountForemen, ChartType.CountSupervisors],
      }),
    [data],
  );

  const handleFacilityChange = useCallback(
    (value?: string) => {
      if (typeof value === 'string') {
        setFacilityId(value);
        localStorage.setItem('rightNowChartFacilityId', value);
      } else {
        setFacilityId(null);
        localStorage.removeItem('rightNowChartFacilityId');
      }
    },
    [setFacilityId],
  );

  const handleGroupChange = useCallback(
    (value?: string) => {
      if (typeof value === 'string') {
        setFacilityGroupId(value);
        localStorage.setItem('rightNowChartFacilityGroup', value);
      } else {
        setFacilityGroupId(null);
        localStorage.removeItem('rightNowChartFacilityGroup');
      }
      setFacilityId(null);
      localStorage.removeItem('rightNowChartFacilityId');
    },
    [setFacilityId, setFacilityGroupId],
  );

  useEffect(() => {
    if (!isNull(localStorageGroup)) {
      setFacilityGroupId(localStorageGroup);
    } else if (facilitiesGroups.length && !isMeAdmin && !facilityGroupId) {
      setFacilityGroupId(facilitiesGroups[0].id);
    }
  }, [facilitiesGroups, facilityGroupId, isMeAdmin, localStorageGroup]);

  useEffect(() => {
    if (!isNull(localStorageFacility)) {
      setFacilityId(localStorageFacility);
    } else if (facilityGroupId && (isMeManager || isMeSupervisor) && filteredFacilities.length) {
      setFacilityId(filteredFacilities[0].id);
    }
  }, [facilityGroupId, filteredFacilities, isMeManager, isMeSupervisor, localStorageFacility]);

  useEffect(() => {
    if (isMeDirector) {
      if (!facilityGroupId) return;
    }
    if (isMeManager || isMeSupervisor) {
      if (!facilityGroupId || !facilityId) return;
    }
    loadData({
      variables: {
        input: {
          facilityId,
          facilityGroupId,
          period: StatisticPeriod.Day,
          types: [
            ChartType.Latecomers,
            ChartType.AcceptedShifts,
            ChartType.WorkingShifts,
            ChartType.CountWorkers,
            ChartType.CountForemen,
            ChartType.CountSupervisors,
          ],
        },
      },
    });
  }, [facilityGroupId, facilityId, isMeAdmin, isMeDirector, isMeManager, isMeSupervisor, loadData]);

  return (
    <div className="grid col-span-6 grid-rows-[min-content,_auto] gap-y-5 shadow-smena rounded-lg p-5">
      <div className="grid grid-cols-[min-content,_auto] justify-between items-center">
        <span className="Subtitle1 text-smena_text-secondary">Сегодня</span>
        <div className="flex gap-x-5">
          <SimpleSelect
            divClassName="chart-filter"
            placeholder="Выберите группу"
            popupClassName="max-w-[300px]"
            onChange={handleGroupChange}
            value={facilityGroupId}
            options={facilitiesGroups}
            allOption={!isMeAdmin ? undefined : 'Все группы'}
          />
          <SimpleSelect
            divClassName="chart-filter"
            placeholder={'Выберите объект'}
            onChange={handleFacilityChange}
            value={facilityId}
            options={filteredFacilities}
            allOption={isMeSupervisor || isMeManager ? undefined : 'Все объекты'}
          />
        </div>
      </div>
      <div
        className={clsx(
          'grid h-full',
          isMeAdminOrSupervisor ? 'grid-cols-2 gap-2' : 'gap-y-2 gap-x-10 grid-cols-[repeat(3,_max-content)]',
        )}
      >
        <div className="col-span-1 gap-y-2">
          <span className="Subtitle2 text-smena_text-secondary">Подтвержденные смены</span>
          <div className="flex items-center gap-x-2">
            <ColoredCircle color="bg-smena_purple" />
            <TodaySkeleton loading={loading}>
              <span className="ChartBigNumber text-smena_text">{acceptedShifts}</span>
            </TodaySkeleton>
          </div>
        </div>
        <div className="col-span-1 gap-y-2">
          <span className="Subtitle2 text-smena_text-secondary">Отработанное количество часов</span>
          <div className="flex items-center gap-x-2">
            <ColoredCircle color="bg-smena_teal" />
            <TodaySkeleton loading={loading}>
              <span className="ChartBigNumber text-smena_text">{usersAtShift}</span>
            </TodaySkeleton>
          </div>
        </div>
        <div className="col-span-1 gap-y-2">
          <span className="Subtitle2 text-smena_text-secondary">Опоздания</span>
          <div className="flex items-center gap-x-2">
            <ColoredCircle color="bg-smena_gold" />
            <TodaySkeleton loading={loading}>
              <span className="ChartBigNumber text-smena_text">{latecomers}</span>
            </TodaySkeleton>
          </div>
        </div>
        {isMeAdminOrSupervisor && (
          <div className="col-span-1 gap-y-2">
            <span className="Subtitle2 text-smena_text-secondary">Пользователи</span>
            <div className="flex items-center gap-x-2">
              <ColoredCircle color="bg-smena_pink" />
              <TodaySkeleton loading={loading}>
                <span className="ChartBigNumber text-smena_text">{getAllUsersChartLength}</span>
              </TodaySkeleton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
