import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import MainSidebar from 'ui/sidebar';
import { useStore } from 'store';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import SimpleSelect from 'ui/select';
import { auchanLunchDurationTypes, getLunchDuration, lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { positionName } from 'utils/helpers/filters/filtersName';
import { timeDifference } from 'utils/helpers/timeDifference';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import uniqueArray from 'utils/helpers/uniqueArray';
import { useMeRole } from 'store/hooks/useMeRole';
import ViewScheduleFormElement from '../viewElement';
import { useDeleteRequestScheduleMutation } from 'pages/facilityRequests/api/mutations/generated/DeleteRequestSchedule';
import { useMe } from 'utils/api/useMe';
import { usePositions } from 'utils/api/usePositions';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { Form, Formik } from 'formik';
import { useEditScheduleForm } from './useEditScheduleForm';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { getScheduleTypeName } from 'utils/api/useGetScheduleTypes/getScheduleTypeName';
import { Role } from 'generated/graphql';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';

interface IEditSchedule {
  showEditGraphicSidebar: boolean;
  setShowEditGraphicSidebar: (value: boolean) => void;
}

const EditSchedule: React.FC<IEditSchedule> = ({ showEditGraphicSidebar, setShowEditGraphicSidebar }) => {
  const me = useMe();
  const { request } = useCurrentRequest();
  const { requestSchedule } = useStore();
  const { positions } = usePositions();

  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeRequestAuthor = me?.id === request?.authorId;

  const { initialValues, validate, onSubmit, loading } = useEditScheduleForm();
  const { scheduleTypes } = useGetScheduleTypes();
  const scheduleOptions = useMemo(() => {
    return scheduleTypes.map(el => ({ name: el.name, id: el.id }));
  }, [scheduleTypes]);

  const requestPositions = useMemo(
    () =>
      uniqueArray({
        field: 'id',
        array: request?.plans.map(plan => ({
          id: plan.position.id,
          name: plan.position.name,
        })),
      }),
    [request?.plans],
  );

  const [deleteRequestSchedule, { loading: deleteRequestLoading }] = useDeleteRequestScheduleMutation();

  const deleteRequestScheduleHandler = useCallback(() => {
    if (requestSchedule?.id) {
      deleteRequestSchedule({
        variables: {
          id: requestSchedule.id,
        },
      })
        .then(() => successToast('График удален'))
        .catch(e => errorToast(errorHandler(e)));
    }
  }, [deleteRequestSchedule, requestSchedule?.id]);

  const { isAuchan } = useFacilityGroupById(request?.facility?.facilityGroup?.id);

  const changes =
    Boolean(requestSchedule?.newDateFrom) ||
    Boolean(requestSchedule?.newDateTo) ||
    Boolean(requestSchedule?.newDayInterleaving) ||
    Boolean(requestSchedule?.newLunchDuration) ||
    Boolean(requestSchedule?.newShiftDuration);

  const isUserCanEditSchedule = useMemo(() => {
    const regularTerm = dayjs().isBefore(dayjs(requestSchedule?.dateTo));
    if (isMeSupervisor) {
      return regularTerm && isMeRequestAuthor;
    }
    return regularTerm;
  }, [isMeRequestAuthor, isMeSupervisor, requestSchedule?.dateTo]);

  const scheduleTime = useMemo(() => {
    return dayjs(requestSchedule?.newDateFrom).isValid() && dayjs(requestSchedule?.newDateTo).isValid()
      ? `${dayjs(requestSchedule?.newDateFrom).format(TIME_DATE)} – ${dayjs(requestSchedule?.newDateTo).format(
          TIME_DATE,
        )}`
      : false;
  }, [requestSchedule?.newDateFrom, requestSchedule?.newDateTo]);

  const title = useMemo(
    () =>
      isMeSupervisor
        ? requestSchedule?.isNew
          ? 'Новый график'
          : changes
            ? 'Изменения в графике'
            : 'Редактировать график'
        : 'Редактировать график',
    [changes, isMeSupervisor, requestSchedule?.isNew],
  );

  return (
    <MainSidebar title={title} show={showEditGraphicSidebar} setShow={setShowEditGraphicSidebar}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form className="relative flex flex-col gap-y-6">
              <div>
                <span className="text-smena_text-secondary">
                  {dayjs(requestSchedule?.dateFrom).format(RU_DATE)} – {dayjs(requestSchedule?.dateTo).format(RU_DATE)}
                </span>
              </div>
              <div className="flex flex-col gap-y-1">
                <span className="H4">{positionName(positions, requestSchedule?.positionId)}</span>
                <div className="flex gap-x-2 Subtitle2 text-smena_text-secondary">
                  <ViewScheduleFormElement
                    data={getScheduleTypeName(scheduleTypes, requestSchedule?.scheduleTypeId)}
                    newData={
                      requestSchedule?.newScheduleTypeId
                        ? getScheduleTypeName(scheduleTypes, requestSchedule?.newScheduleTypeId)
                        : false
                    }
                    isNew={requestSchedule?.isNew}
                    isDeleted={requestSchedule?.isDeleted}
                  />
                  {requestSchedule?.scheduleTypeId ? <span>·</span> : null}
                  <ViewScheduleFormElement
                    data={`${dayjs(requestSchedule?.dateFrom).format(TIME_DATE)} – ${dayjs(
                      requestSchedule?.dateTo,
                    ).format(TIME_DATE)}`}
                    newData={scheduleTime}
                    isNew={requestSchedule?.isNew}
                    isDeleted={requestSchedule?.isDeleted}
                  />
                  <span>·</span>
                  <ViewScheduleFormElement
                    data={getLunchDuration(requestSchedule?.lunchDuration)}
                    newData={
                      requestSchedule?.newLunchDuration ? getLunchDuration(requestSchedule?.newLunchDuration) : false
                    }
                    isNew={requestSchedule?.isNew}
                    isDeleted={requestSchedule?.isDeleted}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                {isUserCanEditSchedule ? (
                  <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                    <SimpleSelect
                      divClassName="col-span-1"
                      label="Профессия"
                      onChange={value => {
                        setFieldValue('positionId', value);
                      }}
                      value={values.positionId}
                      validation
                      options={requestPositions}
                      placeholder="Не выбрано"
                      error={errors.positionId && touched.positionId ? errors.positionId : ''}
                    />
                    <SimpleSelect
                      divClassName="col-span-1"
                      label="График"
                      onChange={value => {
                        setFieldValue('scheduleType', value);
                      }}
                      value={values.scheduleType}
                      validation
                      placeholder="Выберите график"
                      options={scheduleOptions}
                    />
                    <TimePickerInput
                      label="Начало смены"
                      className="col-span-1"
                      popupClassName="fixed"
                      value={values.scheduleStartTime}
                      onChange={timeValue => {
                        setFieldValue('scheduleStartTime', timeValue);
                        if (isAuchan) {
                          setFieldValue(
                            'lunchDuration',
                            lunchDurationHandle(timeDifference(timeValue, values.scheduleEndTime)),
                          );
                        }
                      }}
                      error={errors.scheduleStartTime && touched.scheduleStartTime ? errors.scheduleStartTime : ''}
                    />
                    <TimePickerInput
                      label="Конец смены"
                      className="col-span-1"
                      popupClassName="fixed"
                      value={values.scheduleEndTime}
                      onChange={timeValue => {
                        setFieldValue('scheduleEndTime', timeValue);
                        if (isAuchan) {
                          setFieldValue(
                            'lunchDuration',
                            lunchDurationHandle(timeDifference(values.scheduleStartTime, timeValue)),
                          );
                        }
                      }}
                      error={errors.scheduleEndTime && touched.scheduleEndTime ? errors.scheduleEndTime : ''}
                    />
                    <DatePickerInput
                      label="Дата начала"
                      divClassName="col-span-1"
                      popupClassName="fixed"
                      value={values.scheduleStartDate}
                      onChange={selectedDate => setFieldValue('scheduleStartDate', selectedDate)}
                      minDate={dayjs(request?.dateFrom)}
                      maxDate={dayjs(request?.dateTo)}
                      placeholder={dayjs().startOf('month').format(RU_DATE)}
                      error={errors.scheduleStartDate && touched.scheduleStartDate ? errors.scheduleStartDate : ''}
                    />
                    <DatePickerInput
                      label="Дата конца"
                      divClassName="col-span-1"
                      popupClassName="fixed"
                      value={values.scheduleEndDate}
                      onChange={selectedDate => setFieldValue('scheduleEndDate', selectedDate)}
                      minDate={dayjs(request?.dateFrom)}
                      maxDate={dayjs(request?.dateTo)}
                      placeholder={dayjs().startOf('month').format(RU_DATE)}
                      error={errors.scheduleEndDate && touched.scheduleEndDate ? errors.scheduleEndDate : ''}
                    />
                    <SimpleSelect
                      divClassName="col-span-1 mb-6"
                      label="Обед"
                      onChange={value => {
                        if (!isAuchan) {
                          setFieldValue('lunchDuration', Number(value));
                        }
                      }}
                      sort={false}
                      value={values.lunchDuration}
                      options={isAuchan ? auchanLunchDurationTypes : lunchDurationTypes}
                      disabled={isAuchan}
                    />
                  </div>
                ) : null}
              </div>
              {isMeSupervisor && requestSchedule?.isDeleted && (
                <span className="Subtitle1 text-smena_red">График удален</span>
              )}
              {isUserCanEditSchedule && (
                <div className="flex justify-end gap-x-6">
                  <button
                    className="btn-stroke_reject"
                    type="button"
                    onClick={deleteRequestScheduleHandler}
                    disabled={deleteRequestLoading}
                  >
                    Удалить
                  </button>
                  <button className="btn-primary" type="submit" disabled={loading}>
                    Изменить график
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </MainSidebar>
  );
};

export default EditSchedule;
