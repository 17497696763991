import { UserContractStatus, UserContractType } from 'generated/graphql';

export const contractTypes = [
  { name: UserContractType.AgentContract, nameRu: 'Агентский договор' },
  { name: UserContractType.FrameContract, nameRu: 'Рамочный договор НПД' },
  { name: UserContractType.ProcessPersonalInfoContract, nameRu: 'Согласие на обработку ПД' },
];

export const contractStatuses = [
  { name: UserContractStatus.Archived, nameRu: 'Архив', className: 'bg-smena_gray-5 text-smena_text-secondary' },
  {
    name: UserContractStatus.Signed,
    nameRu: 'Действующий',
    className: 'bg-smena_green-extra_light text-smena_green-dark',
  },
  {
    name: UserContractStatus.Unsigned,
    nameRu: 'Не подписан',
    className: 'bg-smena_red-extra_light text-smena_red-dark',
  },
];
