import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const WsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_PUBLIC_WSS_DOMAIN}/graphql`,
    retryAttempts: 5,
    keepAlive: 5000,
  }),
);

export default WsLink;
