import { RequestProgressFragment } from '../../../facilityUpdateRequest/api/fragmetns/generated/RequestProgress.fragment';
import { ApolloCache } from '@apollo/client';
import { ZayavkaStage, ZayavkaToCancelStatus } from 'generated/graphql';
import { PaginatedRequestFragment } from 'pages/facilityRequests/api/fragments/generated/PaginatedRequest.fragment';

export const cancelRequestCache = (
  cache: ApolloCache<any>,
  request?: RequestProgressFragment | PaginatedRequestFragment,
) => {
  if (request) {
    cache.modify({
      id: cache.identify(request),
      fields: {
        status() {
          return ZayavkaStage.Canceled;
        },
        toCancel() {
          return ZayavkaToCancelStatus.Accepted;
        },
      },
    });
  }
};

export const cancelRequestByManagerCache = (
  cache: ApolloCache<any>,
  request?: RequestProgressFragment | PaginatedRequestFragment,
) => {
  if (request) {
    cache.modify({
      id: cache.identify(request),
      fields: {
        toCancel() {
          return ZayavkaToCancelStatus.Waiting;
        },
      },
    });
  }
};
