import React, { useEffect, useMemo } from 'react';
import { useStore } from 'store';
import uniqueArray from 'utils/helpers/uniqueArray';

interface PaymentsHeaderInterface {
  pays?: number;
}
const PaymentsHeader: React.FC<PaymentsHeaderInterface> = ({ pays }) => {
  const {
    manualAccrual,
    paymentCheckbox,
    setPaymentCheckbox,
    paymentCheckboxes,
    setPaymentCheckboxes,
    setManualAccrualUserIds,
  } = useStore();
  const columns = useMemo(
    () => [
      { id: 1, name: 'Профессия' },
      { id: 2, name: 'Ставка' },
      { id: 3, name: 'Сумма выплаты по ставке ₽' },
      { id: 4, name: 'Надбавка "Трудяга" ₽' },
      { id: 5, name: '"Ударник месяца" ₽' },
      { id: 6, name: '"Супер Трудяга" ₽' },
      { id: 7, name: 'Итого' },
    ],
    [],
  );
  const disabledUserArray = useMemo(
    () => uniqueArray({ array: manualAccrual?.manualUserAccruals, field: 'userId' }),
    [manualAccrual?.manualUserAccruals],
  );

  const usersId: string[] = [];
  useEffect(() => {
    if (paymentCheckboxes > 0) {
      if (pays === paymentCheckboxes) {
        setPaymentCheckbox(true);
      }
    } else {
      setPaymentCheckbox(false);
    }
  }, [paymentCheckboxes, pays, setPaymentCheckbox]);

  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentCheckbox(e.target.checked);
    if (e.target.checked) {
      if (pays) {
        setPaymentCheckboxes(pays);
      }
      manualAccrual?.facility?.workPosts?.forEach(x => {
        if (x.user) {
          usersId.push(x.user.id);
        }
      });

      setManualAccrualUserIds(usersId);
    } else {
      setPaymentCheckboxes(0);
      setManualAccrualUserIds([]);
    }
  };

  const checkboxState = disabledUserArray.length === manualAccrual?.facility?.workPosts?.length;

  return (
    <>
      <div className="grid " style={{ gridTemplateColumns: '50px 2fr repeat(6, 1fr)' }}>
        <input
          type="checkbox"
          name="checkbox"
          id="checkbox"
          disabled={checkboxState}
          className="mx-4 my-2 checkbox-primary"
          style={checkboxState ? { cursor: 'not-allowed' } : {}}
          checked={checkboxState ? false : paymentCheckbox}
          onChange={checkboxHandler}
        />
        {columns.map(column => (
          <div
            key={column.id}
            className="p-2 text-left text-xs font-medium text-smena_gray-50 uppercase tracking-wider"
          >
            {column.name}
          </div>
        ))}
      </div>
    </>
  );
};

export default PaymentsHeader;
