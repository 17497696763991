import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast } from 'utils/helpers/notify';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';

import SimpleSelect from 'ui/select';
import { filterCities } from 'utils/helpers/filters/filterCities';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import { useCreateTimesheetByMonthAndFacilityMutation } from '../api/mutations/generated/CreateTimesheetByMonthAndFiclity';
import { useCreateTimesheetByMonthAndFacilityGroupMutation } from '../api/mutations/generated/CreateTimesheetByMonthAndFacilityGroup';
import TimesheetLoader from '../ui/Loader';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { useCities } from 'utils/api/useCities';
import { filterFacilityGroups } from 'utils/helpers/filters/filterFacilityGroups';
import { ISO_DATE } from 'utils/helpers/constVariables';

const Timesheet = () => {
  const [resLink, setResLink] = useState('');

  const { cities } = useCities();
  const { facilitiesGroups } = useSelectFacilityGroups();
  const { facilities } = useSelectFacilities();

  const [createTimesheet, { loading }] = useCreateTimesheetByMonthAndFacilityMutation();
  const [createGroupTimesheet, { loading: createGroupLoading }] = useCreateTimesheetByMonthAndFacilityGroupMutation();

  const onSubmit = useCallback(
    (values: any) => {
      const facilityResult = {
        facilityId: values.facility,
        startOfMonth: values.date.startOf('month').format(ISO_DATE),
      };
      const groupResult = {
        facilityGroupId: values.group,
        cityId: values.city,
        startOfMonth: values.date.startOf('month').format(ISO_DATE),
      };
      if (!values.facility) {
        createGroupTimesheet({
          variables: {
            input: groupResult,
          },
        })
          .then(response => {
            if (response.data?.createTimesheetByMonthAndFacilityGroup) {
              const filePath = response.data.createTimesheetByMonthAndFacilityGroup.filePath;
              const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
              setResLink(url);
            }
          })
          .catch(e => {
            errorToast(errorHandler(e));
          });
      } else {
        createTimesheet({
          variables: {
            input: facilityResult,
          },
        })
          .then(response => {
            if (response.data?.createTimesheetByMonthAndFacility) {
              const filePath = response.data.createTimesheetByMonthAndFacility.filePath;
              const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
              setResLink(url);
            }
          })
          .catch(e => {
            errorToast(errorHandler(e));
          });
      }
    },
    [createTimesheet, createGroupTimesheet],
  );

  return (
    <div className="xs:col-span-10 col-span-full p-5 gap-y-5 border rounded-lg shadow-smena bg-smena_white flex flex-col">
      <span className="Subtitle1 text-smena_text-secondary">Табель</span>
      <Formik
        initialValues={{
          group: undefined,
          city: '',
          facility: '',
          date: null,
        }}
        validate={values => {
          const errors: any = {};

          if (!values.facility && !values.group) {
            errors.group = 'Обязательное поле';
          }
          if (!values.date) {
            errors.date = 'Обязательное поле';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, touched, errors }) => {
          const filteredFacilities = filterFacilities(values.group, facilities, values.city);
          const filteredCities = filterCities(values.group, facilities, cities);
          const filteredFacilityGroups = filterFacilityGroups({ facilitiesGroups, facilities, cityId: values.city });
          return (
            <div className={'flex flex-col gap-y-5'}>
              <Form className="flex xs:flex-row flex-col xs:items-end gap-x-5 gap-y-3 flex-wrap">
                <SimpleSelect
                  divClassName="flex-[1_0]"
                  popupClassName={'max-w-[300px]'}
                  label="Группа"
                  onChange={value => {
                    setFieldValue('group', value);
                    setResLink('');
                    setFieldValue('facility', '');
                  }}
                  value={values.group}
                  placeholder="Группа"
                  options={filteredFacilityGroups}
                  validation
                  error={touched.group && errors.group ? errors.group : ''}
                />

                <SimpleSelect
                  divClassName="flex-[1_0]"
                  label="Город"
                  onChange={value => {
                    setFieldValue('city', value);
                    setResLink('');
                    setFieldValue('facility', '');
                  }}
                  value={values.city}
                  allOption="Все города"
                  options={filteredCities}
                  validation
                  error={touched.city && errors.city ? errors.city : ''}
                />

                <SimpleSelect
                  divClassName="flex-[1_0]"
                  label="Объект"
                  onChange={value => {
                    setFieldValue('facility', value);
                    setResLink('');
                  }}
                  value={values.facility}
                  allOption="Все объекты"
                  options={filteredFacilities}
                  validation
                  error={touched.facility && errors.facility ? errors.facility : ''}
                />
                <DatePickerInput
                  divClassName="w-[200px]"
                  label="Год и месяц"
                  format="MMM YYYY"
                  value={values.date}
                  placeholder={dayjs().format('MM.YYYY')}
                  onChange={newValue => {
                    setFieldValue('date', newValue);
                    setResLink('');
                  }}
                  minDate={dayjs('2020-01')}
                  maxDate={dayjs()}
                  picker="month"
                  error={touched.date && errors.date ? errors.date : ''}
                />
                <div className="">
                  <button
                    type="submit"
                    disabled={loading || createGroupLoading || Boolean(resLink) || filteredFacilities?.length === 0}
                    className="btn-secondary"
                  >
                    Сформировать
                  </button>
                </div>
              </Form>
              <TimesheetLoader loading={loading || createGroupLoading} resLink={resLink} />
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default Timesheet;
