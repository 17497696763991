import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVacancyGalleryQueryVariables = Types.Exact<{
  input: Types.GetVacancyGalleryInput;
}>;


export type GetVacancyGalleryQuery = { __typename?: 'Query', getVacancyGallery: { __typename?: 'VacancyGallery', casingGallery: Array<{ __typename?: 'CasingGalleryEntity', id: string, file?: { __typename?: 'File', id: string, path: string } | null }>, facilityGallery: Array<{ __typename?: 'FacilityGalleryEntity', file?: { __typename?: 'File', id: string, path: string } | null }> } };


export const GetVacancyGalleryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetVacancyGallery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetVacancyGalleryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getVacancyGallery"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"casingGallery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"facilityGallery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetVacancyGalleryQuery__
 *
 * To run a query within a React component, call `useGetVacancyGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacancyGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacancyGalleryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVacancyGalleryQuery(baseOptions: Apollo.QueryHookOptions<GetVacancyGalleryQuery, GetVacancyGalleryQueryVariables> & ({ variables: GetVacancyGalleryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVacancyGalleryQuery, GetVacancyGalleryQueryVariables>(GetVacancyGalleryDocument, options);
      }
export function useGetVacancyGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVacancyGalleryQuery, GetVacancyGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVacancyGalleryQuery, GetVacancyGalleryQueryVariables>(GetVacancyGalleryDocument, options);
        }
export function useGetVacancyGallerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVacancyGalleryQuery, GetVacancyGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVacancyGalleryQuery, GetVacancyGalleryQueryVariables>(GetVacancyGalleryDocument, options);
        }
export type GetVacancyGalleryQueryHookResult = ReturnType<typeof useGetVacancyGalleryQuery>;
export type GetVacancyGalleryLazyQueryHookResult = ReturnType<typeof useGetVacancyGalleryLazyQuery>;
export type GetVacancyGallerySuspenseQueryHookResult = ReturnType<typeof useGetVacancyGallerySuspenseQuery>;
export type GetVacancyGalleryQueryResult = Apollo.QueryResult<GetVacancyGalleryQuery, GetVacancyGalleryQueryVariables>;