import React from 'react';
import Skeleton from 'antd/es/skeleton';

const SkeletonVacancy = () => {
  const rows = [...Array(3).keys()];
  return (
    <>
      {rows.map(row => (
        <div key={row} className="p-5 flex justify-between rounded-lg bg-smena_white">
          <div className={'flex flex-col gap-y-2'}>
            <Skeleton.Button
              active
              style={{
                width: '260px',
                height: '25px',
              }}
            />
            <Skeleton.Button
              active
              style={{
                width: '260px',
                height: '30px',
              }}
            />
            <Skeleton.Button
              active
              style={{
                width: '260px',
                height: '24px',
              }}
            />
          </div>
          <Skeleton.Avatar
            active
            style={{
              width: '48px',
              height: '48px',
            }}
          />
        </div>
      ))}
    </>
  );
};

export default SkeletonVacancy;
