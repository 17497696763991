import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { UserIntersectFragmentDoc } from '../../../../user/api/fragments/generated/UserIntersect.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetWorkersForRequestScheduleQueryVariables = Types.Exact<{
  zayavkaScheduleId: Types.Scalars['String']['input'];
  searchQuery?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetWorkersForRequestScheduleQuery = { __typename?: 'Query', getWorkersForZayavkaSchedule: Array<{ __typename?: 'UserIntersect', id: string, firstname: string, lastname: string, patronymic?: string | null, requisite?: { __typename?: 'Requisite', medicalAttestationEnd?: string | null, medicalInvoiceIssueDate?: any | null } | null, myDocumentsReviews: Array<{ __typename?: 'DocumentReview', id: string, status: Types.DocumentStatus, type: Types.ReviewType }>, intersect?: Array<{ __typename?: 'Shift', id: string, dateFrom: any, dateEnd: any, comment?: string | null, duration: string, lunchDuration?: number | null, facilityId: string, hourRate?: number | null, positionId: string, stage: Types.ShiftStage, updatedAt: any, userId: string, user?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null } | null }> | null }> };


export const GetWorkersForRequestScheduleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetWorkersForRequestSchedule"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"zayavkaScheduleId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"searchQuery"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getWorkersForZayavkaSchedule"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"zayavkaScheduleId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"zayavkaScheduleId"}}},{"kind":"Argument","name":{"kind":"Name","value":"searchQuery"},"value":{"kind":"Variable","name":{"kind":"Name","value":"searchQuery"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserIntersect"}}]}}]}},...UserIntersectFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetWorkersForRequestScheduleQuery__
 *
 * To run a query within a React component, call `useGetWorkersForRequestScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkersForRequestScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkersForRequestScheduleQuery({
 *   variables: {
 *      zayavkaScheduleId: // value for 'zayavkaScheduleId'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGetWorkersForRequestScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetWorkersForRequestScheduleQuery, GetWorkersForRequestScheduleQueryVariables> & ({ variables: GetWorkersForRequestScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkersForRequestScheduleQuery, GetWorkersForRequestScheduleQueryVariables>(GetWorkersForRequestScheduleDocument, options);
      }
export function useGetWorkersForRequestScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkersForRequestScheduleQuery, GetWorkersForRequestScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkersForRequestScheduleQuery, GetWorkersForRequestScheduleQueryVariables>(GetWorkersForRequestScheduleDocument, options);
        }
export function useGetWorkersForRequestScheduleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkersForRequestScheduleQuery, GetWorkersForRequestScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWorkersForRequestScheduleQuery, GetWorkersForRequestScheduleQueryVariables>(GetWorkersForRequestScheduleDocument, options);
        }
export type GetWorkersForRequestScheduleQueryHookResult = ReturnType<typeof useGetWorkersForRequestScheduleQuery>;
export type GetWorkersForRequestScheduleLazyQueryHookResult = ReturnType<typeof useGetWorkersForRequestScheduleLazyQuery>;
export type GetWorkersForRequestScheduleSuspenseQueryHookResult = ReturnType<typeof useGetWorkersForRequestScheduleSuspenseQuery>;
export type GetWorkersForRequestScheduleQueryResult = Apollo.QueryResult<GetWorkersForRequestScheduleQuery, GetWorkersForRequestScheduleQueryVariables>;