import React from 'react';
import { Placemark, YMapsApi } from 'react-yandex-maps';
import { ISearchPinLocation } from '../../store';
import pinImg from 'assets/images/facilityVacancyPin.svg';

type Props = {
  ymaps: YMapsApi | null;
  pin: ISearchPinLocation;
};
const SearchPin: React.FC<Props> = ({ ymaps, pin }) => {
  if (!ymaps) return null;
  const layout = ymaps.templateLayoutFactory.createClass(
    `<div class='search-pin' style='background-image: url("${pinImg}")'></div>`,
    {
      build: function () {
        layout.superclass.build.call(this);
        this.getData().options.set('shape', {
          type: 'Rectangle',
          coordinates: [
            [-20, -45],
            [25, 0],
          ],
        });
      },
    },
  );

  return (
    <Placemark
      geometry={[pin.lat, pin.lon]}
      options={{
        iconLayout: layout,
      }}
    />
  );
};

export default SearchPin;
