import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityGroupFragmentDoc } from '../../fragments/generated/FacilityGroup.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFacilityGroupMutationVariables = Types.Exact<{
  input: Types.FacilityGroupCardInput;
}>;


export type UpdateFacilityGroupMutation = { __typename?: 'Mutation', updateFacilityGroup: { __typename?: 'FacilityGroup', id: string, name: string, balance: number, logo?: { __typename?: 'File', id: string, mimetype: string, originalName?: string | null, path: string, size: number } | null, clientDirectors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null, groupManagers?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null } };


export const UpdateFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FacilityGroupCardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityGroup"}}]}}]}},...FacilityGroupFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateFacilityGroupMutationFn = Apollo.MutationFunction<UpdateFacilityGroupMutation, UpdateFacilityGroupMutationVariables>;

/**
 * __useUpdateFacilityGroupMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityGroupMutation, { data, loading, error }] = useUpdateFacilityGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacilityGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacilityGroupMutation, UpdateFacilityGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacilityGroupMutation, UpdateFacilityGroupMutationVariables>(UpdateFacilityGroupDocument, options);
      }
export type UpdateFacilityGroupMutationHookResult = ReturnType<typeof useUpdateFacilityGroupMutation>;
export type UpdateFacilityGroupMutationResult = Apollo.MutationResult<UpdateFacilityGroupMutation>;
export type UpdateFacilityGroupMutationOptions = Apollo.BaseMutationOptions<UpdateFacilityGroupMutation, UpdateFacilityGroupMutationVariables>;