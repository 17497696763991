import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { VacancyFragmentDoc } from '../../fragments/generated/Vacancy.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VacancyQueryVariables = Types.Exact<{
  vacancyId: Types.Scalars['String']['input'];
}>;


export type VacancyQuery = { __typename?: 'Query', vacancy: { __typename?: 'Vacancy', coverPhotoId?: string | null, id: string, facilityId: string, lunchDuration?: number | null, erpSourceId?: string | null, erpGroupId?: string | null, timeStart: string, timeEnd: string, hourRate: number, hasEducation?: boolean | null, probationPeriod?: number | null, is_active: boolean, period: Types.ShiftTime, positionId: string, documentsNotes?: string | null, requiredDocuments: Array<Types.CasingDocumentType>, section?: string | null, payout?: Types.PayoutType | null, unit?: Types.UnitType | null, isPublishCostOfShift: boolean, isRangeCost: boolean, averageCost: number, shiftCostFrom: number, shiftCostUpTo: number, payoutFrequency?: Types.PayoutFrequency | null, description?: string | null, maxAge?: number | null, minAge?: number | null, notes?: string | null, requirements?: string | null, responsibilities?: string | null, sex?: Types.Sex | null, citizenshipsIds?: Array<string> | null, transport?: string | null, workwear: boolean, coverPhoto?: { __typename?: 'File', id: string, path: string } | null, facility: { __typename?: 'Facility', name: string, cityId?: string | null, facilityGroupId?: string | null }, position?: { __typename?: 'Position', name: string } | null } };


export const VacancyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Vacancy"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"vacancyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vacancy"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"vacancyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"vacancyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Vacancy"}},{"kind":"Field","name":{"kind":"Name","value":"coverPhotoId"}},{"kind":"Field","name":{"kind":"Name","value":"coverPhoto"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]}},...VacancyFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useVacancyQuery__
 *
 * To run a query within a React component, call `useVacancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacancyQuery({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useVacancyQuery(baseOptions: Apollo.QueryHookOptions<VacancyQuery, VacancyQueryVariables> & ({ variables: VacancyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VacancyQuery, VacancyQueryVariables>(VacancyDocument, options);
      }
export function useVacancyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacancyQuery, VacancyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VacancyQuery, VacancyQueryVariables>(VacancyDocument, options);
        }
export function useVacancySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VacancyQuery, VacancyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VacancyQuery, VacancyQueryVariables>(VacancyDocument, options);
        }
export type VacancyQueryHookResult = ReturnType<typeof useVacancyQuery>;
export type VacancyLazyQueryHookResult = ReturnType<typeof useVacancyLazyQuery>;
export type VacancySuspenseQueryHookResult = ReturnType<typeof useVacancySuspenseQuery>;
export type VacancyQueryResult = Apollo.QueryResult<VacancyQuery, VacancyQueryVariables>;