import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadPhotoInFacilityGalleryMutationVariables = Types.Exact<{
  facilityId: Types.Scalars['String']['input'];
  image: Types.Scalars['Upload']['input'];
}>;


export type UploadPhotoInFacilityGalleryMutation = { __typename?: 'Mutation', uploadPhotoInFacilityGallery: { __typename?: 'FacilityGalleryEntity', file?: { __typename?: 'File', path: string } | null } };


export const UploadPhotoInFacilityGalleryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadPhotoInFacilityGallery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"image"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadPhotoInFacilityGallery"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}},{"kind":"Argument","name":{"kind":"Name","value":"image"},"value":{"kind":"Variable","name":{"kind":"Name","value":"image"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UploadPhotoInFacilityGalleryMutationFn = Apollo.MutationFunction<UploadPhotoInFacilityGalleryMutation, UploadPhotoInFacilityGalleryMutationVariables>;

/**
 * __useUploadPhotoInFacilityGalleryMutation__
 *
 * To run a mutation, you first call `useUploadPhotoInFacilityGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPhotoInFacilityGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPhotoInFacilityGalleryMutation, { data, loading, error }] = useUploadPhotoInFacilityGalleryMutation({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadPhotoInFacilityGalleryMutation(baseOptions?: Apollo.MutationHookOptions<UploadPhotoInFacilityGalleryMutation, UploadPhotoInFacilityGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPhotoInFacilityGalleryMutation, UploadPhotoInFacilityGalleryMutationVariables>(UploadPhotoInFacilityGalleryDocument, options);
      }
export type UploadPhotoInFacilityGalleryMutationHookResult = ReturnType<typeof useUploadPhotoInFacilityGalleryMutation>;
export type UploadPhotoInFacilityGalleryMutationResult = Apollo.MutationResult<UploadPhotoInFacilityGalleryMutation>;
export type UploadPhotoInFacilityGalleryMutationOptions = Apollo.BaseMutationOptions<UploadPhotoInFacilityGalleryMutation, UploadPhotoInFacilityGalleryMutationVariables>;