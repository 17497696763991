import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PenaltyFragmentDoc } from '../../fragments/generated/Penalty.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeFacilityPenaltyMutationVariables = Types.Exact<{
  applyTimeInMinutes: Types.Scalars['Int']['input'];
  cost: Types.Scalars['Int']['input'];
  facilityId: Types.Scalars['String']['input'];
}>;


export type ChangeFacilityPenaltyMutation = { __typename?: 'Mutation', changeFacilityPenalty: { __typename?: 'Penalty', id: string, createdAt: any, applyTimeInMinutes: number, comment?: string | null, cost: number, facilityId?: string | null, type: Types.PenaltyType, isDeleted: boolean } };


export const ChangeFacilityPenaltyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeFacilityPenalty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"applyTimeInMinutes"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cost"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeFacilityPenalty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"applyTimeInMinutes"},"value":{"kind":"Variable","name":{"kind":"Name","value":"applyTimeInMinutes"}}},{"kind":"Argument","name":{"kind":"Name","value":"cost"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cost"}}},{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Penalty"}}]}}]}},...PenaltyFragmentDoc.definitions]} as unknown as DocumentNode;
export type ChangeFacilityPenaltyMutationFn = Apollo.MutationFunction<ChangeFacilityPenaltyMutation, ChangeFacilityPenaltyMutationVariables>;

/**
 * __useChangeFacilityPenaltyMutation__
 *
 * To run a mutation, you first call `useChangeFacilityPenaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFacilityPenaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFacilityPenaltyMutation, { data, loading, error }] = useChangeFacilityPenaltyMutation({
 *   variables: {
 *      applyTimeInMinutes: // value for 'applyTimeInMinutes'
 *      cost: // value for 'cost'
 *      facilityId: // value for 'facilityId'
 *   },
 * });
 */
export function useChangeFacilityPenaltyMutation(baseOptions?: Apollo.MutationHookOptions<ChangeFacilityPenaltyMutation, ChangeFacilityPenaltyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeFacilityPenaltyMutation, ChangeFacilityPenaltyMutationVariables>(ChangeFacilityPenaltyDocument, options);
      }
export type ChangeFacilityPenaltyMutationHookResult = ReturnType<typeof useChangeFacilityPenaltyMutation>;
export type ChangeFacilityPenaltyMutationResult = Apollo.MutationResult<ChangeFacilityPenaltyMutation>;
export type ChangeFacilityPenaltyMutationOptions = Apollo.BaseMutationOptions<ChangeFacilityPenaltyMutation, ChangeFacilityPenaltyMutationVariables>;