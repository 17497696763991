import React from 'react';
import { FormikState } from 'formik';
import { PushFormProps, usePushForm } from 'pages/push/utils/hooks/usePushForm';
import SimpleSelect from 'ui/select';
import { pushRoutes } from 'pages/push/utils/const';
import { usePush } from '../../../../utils/api/usePush';

export interface PushDestinationProps {
  values: PushFormProps;
  setFieldValue: (name: string, value: any) => void;
  errors: FormikState<PushFormProps>['errors'];
}

const PushDestination = ({ values, setFieldValue, errors }: PushDestinationProps) => {
  const { isPushIsNotNew } = usePush();
  const { onSave } = usePushForm();
  return (
    <div className={'grid gap-y-4'}>
      <span className="Subtitle1 text-smena_text-secondary">Куда вести</span>
      <span className="Body2 text-smena_text-secondary">
        Выберите раздел приложения SMENA, в который будет вести пуш-уведомление
      </span>
      <SimpleSelect
        divClassName={'w-[200px]'}
        label={'Раздел в приложении'}
        placeholder={'Выберите раздел'}
        onChange={e => {
          setFieldValue('link', e);
        }}
        showText={isPushIsNotNew}
        onBlur={() => {
          onSave(values);
        }}
        value={values.link}
        options={pushRoutes}
        error={errors.link ? errors.link : ''}
      />
    </div>
  );
};

export default PushDestination;
