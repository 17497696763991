import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PromotionEntityFragmentDoc } from '../../../../facilityGroup/api/fragments/generated/PromotionEntity.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditPromotionInFacilityMutationVariables = Types.Exact<{
  input: Types.EditPromotionInFacilityInput;
}>;


export type EditPromotionInFacilityMutation = { __typename?: 'Mutation', editPromotionInFacility: { __typename?: 'PromotionEntity', id: string, badShiftCount: number, dateTimeEnd?: any | null, dateTimeStart: any, name: string, repeatCondition: Types.PromotionRepeatConditionEnum, requirementBonus: Types.PromotionRequirementBonusEnum, requirementShiftCount: number, rewardSum: number, rewardType: Types.PromotionRewardTypeEnum, status: Types.PromotionStatusEnum, childPromotions?: Array<{ __typename?: 'PromotionEntity', facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null } };


export const EditPromotionInFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditPromotionInFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditPromotionInFacilityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editPromotionInFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PromotionEntity"}}]}}]}},...PromotionEntityFragmentDoc.definitions]} as unknown as DocumentNode;
export type EditPromotionInFacilityMutationFn = Apollo.MutationFunction<EditPromotionInFacilityMutation, EditPromotionInFacilityMutationVariables>;

/**
 * __useEditPromotionInFacilityMutation__
 *
 * To run a mutation, you first call `useEditPromotionInFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPromotionInFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPromotionInFacilityMutation, { data, loading, error }] = useEditPromotionInFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPromotionInFacilityMutation(baseOptions?: Apollo.MutationHookOptions<EditPromotionInFacilityMutation, EditPromotionInFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPromotionInFacilityMutation, EditPromotionInFacilityMutationVariables>(EditPromotionInFacilityDocument, options);
      }
export type EditPromotionInFacilityMutationHookResult = ReturnType<typeof useEditPromotionInFacilityMutation>;
export type EditPromotionInFacilityMutationResult = Apollo.MutationResult<EditPromotionInFacilityMutation>;
export type EditPromotionInFacilityMutationOptions = Apollo.BaseMutationOptions<EditPromotionInFacilityMutation, EditPromotionInFacilityMutationVariables>;