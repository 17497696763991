import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { RequestShiftFragmentDoc } from '../../fragments/generated/RequestShift.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignRequestShiftMutationVariables = Types.Exact<{
  workerId: Types.Scalars['String']['input'];
  zayavkaShiftId: Types.Scalars['String']['input'];
}>;


export type AssignRequestShiftMutation = { __typename?: 'Mutation', assignZayavkaShift: { __typename?: 'ZayavkaShift', id: string, authorId: string, createdAt: any, dateEnd: any, dateFrom: any, facilityId: string, duration: number, lunchDuration?: number | null, payout?: Types.PayoutType | null, positionId: string, shiftId?: string | null, isFree: boolean, zayavkaScheduleId: string, unit?: Types.UnitType | null, updatedAt: any, zayavkaId: string, newDateFrom?: any | null, newDateEnd?: any | null, newLunchDuration?: number | null, isDeleted: boolean, isEdited: boolean, isNew: boolean, shift?: { __typename?: 'Shift', id: string, stage: Types.ShiftStage, userId: string, isProbation: boolean, user?: { __typename?: 'User', id: string, lastname: string, firstname: string, patronymic?: string | null } | null } | null } };


export const AssignRequestShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AssignRequestShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"workerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"zayavkaShiftId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assignZayavkaShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"workerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"workerId"}}},{"kind":"Argument","name":{"kind":"Name","value":"zayavkaShiftId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"zayavkaShiftId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RequestShift"}}]}}]}},...RequestShiftFragmentDoc.definitions]} as unknown as DocumentNode;
export type AssignRequestShiftMutationFn = Apollo.MutationFunction<AssignRequestShiftMutation, AssignRequestShiftMutationVariables>;

/**
 * __useAssignRequestShiftMutation__
 *
 * To run a mutation, you first call `useAssignRequestShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRequestShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRequestShiftMutation, { data, loading, error }] = useAssignRequestShiftMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      zayavkaShiftId: // value for 'zayavkaShiftId'
 *   },
 * });
 */
export function useAssignRequestShiftMutation(baseOptions?: Apollo.MutationHookOptions<AssignRequestShiftMutation, AssignRequestShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignRequestShiftMutation, AssignRequestShiftMutationVariables>(AssignRequestShiftDocument, options);
      }
export type AssignRequestShiftMutationHookResult = ReturnType<typeof useAssignRequestShiftMutation>;
export type AssignRequestShiftMutationResult = Apollo.MutationResult<AssignRequestShiftMutation>;
export type AssignRequestShiftMutationOptions = Apollo.BaseMutationOptions<AssignRequestShiftMutation, AssignRequestShiftMutationVariables>;