import { FacilityGroupFragment } from 'pages/facilityGroup/api/fragments/generated/FacilityGroup.fragment';
import { FacilitiesFragment } from 'pages/main/api/fragments/generated/Facilities.fragment';
import { PositionFragment } from 'pages/position/api/fragments/generated/Position.fragment';
import { CityFragment } from 'pages/city/api/fragments/generated/City.fragment';
import { getCity } from '../get/getCity';
import { getFacilityGroup } from '../get/getFacilityGroup';
import { getFacility } from '../get/getFacility';

export const cityName = (cities: CityFragment[], cityId?: string | null) => {
  const city = getCity({ cityId, cities });
  if (!city) return '';
  return city.name;
};

export const facilityName = (facilities: FacilitiesFragment[], facilityId?: string | null) => {
  const facility = getFacility({ facilityId, facilities });
  if (facility) return facility.name;
  return '';
};

export const positionName = (positions?: PositionFragment[], positionId?: string | null) => {
  const position = positions?.find(position => position.id === positionId);
  if (position) return position.name;
  return '';
};

export const groupName = (facilitiesGroups: FacilityGroupFragment[], facilityGroupId?: string | null) => {
  const group = getFacilityGroup({ facilitiesGroups, facilityGroupId });
  if (group) return group.name;
  else return '';
};
