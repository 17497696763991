import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadPhotoInCasingGalleryMutationVariables = Types.Exact<{
  casingId: Types.Scalars['String']['input'];
  image: Types.Scalars['Upload']['input'];
}>;


export type UploadPhotoInCasingGalleryMutation = { __typename?: 'Mutation', uploadPhotoInCasingGallery: { __typename?: 'CasingGalleryEntity', file?: { __typename?: 'File', path: string } | null } };


export const UploadPhotoInCasingGalleryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadPhotoInCasingGallery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"casingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"image"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadPhotoInCasingGallery"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"casingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"casingId"}}},{"kind":"Argument","name":{"kind":"Name","value":"image"},"value":{"kind":"Variable","name":{"kind":"Name","value":"image"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UploadPhotoInCasingGalleryMutationFn = Apollo.MutationFunction<UploadPhotoInCasingGalleryMutation, UploadPhotoInCasingGalleryMutationVariables>;

/**
 * __useUploadPhotoInCasingGalleryMutation__
 *
 * To run a mutation, you first call `useUploadPhotoInCasingGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPhotoInCasingGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPhotoInCasingGalleryMutation, { data, loading, error }] = useUploadPhotoInCasingGalleryMutation({
 *   variables: {
 *      casingId: // value for 'casingId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadPhotoInCasingGalleryMutation(baseOptions?: Apollo.MutationHookOptions<UploadPhotoInCasingGalleryMutation, UploadPhotoInCasingGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPhotoInCasingGalleryMutation, UploadPhotoInCasingGalleryMutationVariables>(UploadPhotoInCasingGalleryDocument, options);
      }
export type UploadPhotoInCasingGalleryMutationHookResult = ReturnType<typeof useUploadPhotoInCasingGalleryMutation>;
export type UploadPhotoInCasingGalleryMutationResult = Apollo.MutationResult<UploadPhotoInCasingGalleryMutation>;
export type UploadPhotoInCasingGalleryMutationOptions = Apollo.BaseMutationOptions<UploadPhotoInCasingGalleryMutation, UploadPhotoInCasingGalleryMutationVariables>;