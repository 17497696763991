import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendManualAccrualsMutationVariables = Types.Exact<{
  input: Types.ManualAccrualSenderInput;
}>;


export type SendManualAccrualsMutation = { __typename?: 'Mutation', sendManualAccurals: boolean };


export const SendManualAccrualsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendManualAccruals"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ManualAccrualSenderInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendManualAccurals"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type SendManualAccrualsMutationFn = Apollo.MutationFunction<SendManualAccrualsMutation, SendManualAccrualsMutationVariables>;

/**
 * __useSendManualAccrualsMutation__
 *
 * To run a mutation, you first call `useSendManualAccrualsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendManualAccrualsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendManualAccrualsMutation, { data, loading, error }] = useSendManualAccrualsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendManualAccrualsMutation(baseOptions?: Apollo.MutationHookOptions<SendManualAccrualsMutation, SendManualAccrualsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendManualAccrualsMutation, SendManualAccrualsMutationVariables>(SendManualAccrualsDocument, options);
      }
export type SendManualAccrualsMutationHookResult = ReturnType<typeof useSendManualAccrualsMutation>;
export type SendManualAccrualsMutationResult = Apollo.MutationResult<SendManualAccrualsMutation>;
export type SendManualAccrualsMutationOptions = Apollo.BaseMutationOptions<SendManualAccrualsMutation, SendManualAccrualsMutationVariables>;