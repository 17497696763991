export const ratingList = [
  {
    value: '',
    name: 'Все оценки',
  },
  {
    value: '0,3',
    name: '0-3',
  },
  {
    value: '3,4',
    name: '3-4',
  },
  {
    value: '4,5',
    name: '4-5',
  },
  {
    value: 'null',
    name: 'Без оценки',
  },
];
