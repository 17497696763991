import { ApolloCache } from '@apollo/client';

interface Props {
  penaltyId: string;
  cache: ApolloCache<any>;
}
export const writeFacilityPenaltyCache = ({ penaltyId, cache }: Props) => {
  cache.modify({
    id: `Penalty:${penaltyId}`,
    fields: {
      isDeleted() {
        return true;
      },
    },
  });
};
