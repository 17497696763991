import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentSelfEmployedTaxQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentSelfEmployedTaxQuery = { __typename?: 'Query', getCurrentSelfEmployedTax: number };


export const GetCurrentSelfEmployedTaxDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCurrentSelfEmployedTax"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCurrentSelfEmployedTax"}}]}}]} as unknown as DocumentNode;

/**
 * __useGetCurrentSelfEmployedTaxQuery__
 *
 * To run a query within a React component, call `useGetCurrentSelfEmployedTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSelfEmployedTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSelfEmployedTaxQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSelfEmployedTaxQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentSelfEmployedTaxQuery, GetCurrentSelfEmployedTaxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentSelfEmployedTaxQuery, GetCurrentSelfEmployedTaxQueryVariables>(GetCurrentSelfEmployedTaxDocument, options);
      }
export function useGetCurrentSelfEmployedTaxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSelfEmployedTaxQuery, GetCurrentSelfEmployedTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentSelfEmployedTaxQuery, GetCurrentSelfEmployedTaxQueryVariables>(GetCurrentSelfEmployedTaxDocument, options);
        }
export function useGetCurrentSelfEmployedTaxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentSelfEmployedTaxQuery, GetCurrentSelfEmployedTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentSelfEmployedTaxQuery, GetCurrentSelfEmployedTaxQueryVariables>(GetCurrentSelfEmployedTaxDocument, options);
        }
export type GetCurrentSelfEmployedTaxQueryHookResult = ReturnType<typeof useGetCurrentSelfEmployedTaxQuery>;
export type GetCurrentSelfEmployedTaxLazyQueryHookResult = ReturnType<typeof useGetCurrentSelfEmployedTaxLazyQuery>;
export type GetCurrentSelfEmployedTaxSuspenseQueryHookResult = ReturnType<typeof useGetCurrentSelfEmployedTaxSuspenseQuery>;
export type GetCurrentSelfEmployedTaxQueryResult = Apollo.QueryResult<GetCurrentSelfEmployedTaxQuery, GetCurrentSelfEmployedTaxQueryVariables>;