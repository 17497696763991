import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveSupervisorFromFacilityMutationVariables = Types.Exact<{
  facilityId: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type RemoveSupervisorFromFacilityMutation = { __typename?: 'Mutation', removeSupervisorFromFacility: boolean };


export const RemoveSupervisorFromFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveSupervisorFromFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeSupervisorFromFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}]}]}}]} as unknown as DocumentNode;
export type RemoveSupervisorFromFacilityMutationFn = Apollo.MutationFunction<RemoveSupervisorFromFacilityMutation, RemoveSupervisorFromFacilityMutationVariables>;

/**
 * __useRemoveSupervisorFromFacilityMutation__
 *
 * To run a mutation, you first call `useRemoveSupervisorFromFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupervisorFromFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupervisorFromFacilityMutation, { data, loading, error }] = useRemoveSupervisorFromFacilityMutation({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveSupervisorFromFacilityMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupervisorFromFacilityMutation, RemoveSupervisorFromFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupervisorFromFacilityMutation, RemoveSupervisorFromFacilityMutationVariables>(RemoveSupervisorFromFacilityDocument, options);
      }
export type RemoveSupervisorFromFacilityMutationHookResult = ReturnType<typeof useRemoveSupervisorFromFacilityMutation>;
export type RemoveSupervisorFromFacilityMutationResult = Apollo.MutationResult<RemoveSupervisorFromFacilityMutation>;
export type RemoveSupervisorFromFacilityMutationOptions = Apollo.BaseMutationOptions<RemoveSupervisorFromFacilityMutation, RemoveSupervisorFromFacilityMutationVariables>;