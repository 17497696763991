import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveUserQuestionnaireMutationVariables = Types.Exact<{
  input: Types.QuestionnaireInput;
}>;


export type SaveUserQuestionnaireMutation = { __typename?: 'Mutation', saveUserQuestionnaire: { __typename?: 'User', id: string } };


export const SaveUserQuestionnaireDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveUserQuestionnaire"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionnaireInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveUserQuestionnaire"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type SaveUserQuestionnaireMutationFn = Apollo.MutationFunction<SaveUserQuestionnaireMutation, SaveUserQuestionnaireMutationVariables>;

/**
 * __useSaveUserQuestionnaireMutation__
 *
 * To run a mutation, you first call `useSaveUserQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserQuestionnaireMutation, { data, loading, error }] = useSaveUserQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserQuestionnaireMutation, SaveUserQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserQuestionnaireMutation, SaveUserQuestionnaireMutationVariables>(SaveUserQuestionnaireDocument, options);
      }
export type SaveUserQuestionnaireMutationHookResult = ReturnType<typeof useSaveUserQuestionnaireMutation>;
export type SaveUserQuestionnaireMutationResult = Apollo.MutationResult<SaveUserQuestionnaireMutation>;
export type SaveUserQuestionnaireMutationOptions = Apollo.BaseMutationOptions<SaveUserQuestionnaireMutation, SaveUserQuestionnaireMutationVariables>;