import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityRequestShiftFragmentDoc } from '../../../../facilityRequests/api/fragments/generated/FacilityRequestShift.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersWhoRespondedToTheRequestShiftQueryVariables = Types.Exact<{
  input: Types.GetUsersWhoRespondedToTheRequestShiftInput;
}>;


export type GetUsersWhoRespondedToTheRequestShiftQuery = { __typename?: 'Query', getUsersWhoRespondedToTheRequestShift: { __typename?: 'ZayavkaShift', id: string, authorId: string, createdAt: any, dateEnd: any, dateFrom: any, facilityId: string, duration: number, lunchDuration?: number | null, payout?: Types.PayoutType | null, positionId: string, shiftId?: string | null, isFree: boolean, zayavkaScheduleId: string, unit?: Types.UnitType | null, updatedAt: any, zayavkaId: string, newDateFrom?: any | null, newDateEnd?: any | null, newLunchDuration?: number | null, isDeleted: boolean, isEdited: boolean, isNew: boolean, requestShiftSuggestions: Array<{ __typename?: 'RequestShiftSuggestionEntity', status: Types.ZayavkaShiftSuggestionsStatusEnum, id: string, user: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null } }>, shift?: { __typename?: 'Shift', id: string, stage: Types.ShiftStage, userId: string, isProbation: boolean, user?: { __typename?: 'User', id: string, lastname: string, firstname: string, patronymic?: string | null } | null } | null } };


export const GetUsersWhoRespondedToTheRequestShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUsersWhoRespondedToTheRequestShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetUsersWhoRespondedToTheRequestShiftInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUsersWhoRespondedToTheRequestShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityRequestShift"}}]}}]}},...FacilityRequestShiftFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetUsersWhoRespondedToTheRequestShiftQuery__
 *
 * To run a query within a React component, call `useGetUsersWhoRespondedToTheRequestShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWhoRespondedToTheRequestShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWhoRespondedToTheRequestShiftQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersWhoRespondedToTheRequestShiftQuery(baseOptions: Apollo.QueryHookOptions<GetUsersWhoRespondedToTheRequestShiftQuery, GetUsersWhoRespondedToTheRequestShiftQueryVariables> & ({ variables: GetUsersWhoRespondedToTheRequestShiftQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersWhoRespondedToTheRequestShiftQuery, GetUsersWhoRespondedToTheRequestShiftQueryVariables>(GetUsersWhoRespondedToTheRequestShiftDocument, options);
      }
export function useGetUsersWhoRespondedToTheRequestShiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersWhoRespondedToTheRequestShiftQuery, GetUsersWhoRespondedToTheRequestShiftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersWhoRespondedToTheRequestShiftQuery, GetUsersWhoRespondedToTheRequestShiftQueryVariables>(GetUsersWhoRespondedToTheRequestShiftDocument, options);
        }
export function useGetUsersWhoRespondedToTheRequestShiftSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersWhoRespondedToTheRequestShiftQuery, GetUsersWhoRespondedToTheRequestShiftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersWhoRespondedToTheRequestShiftQuery, GetUsersWhoRespondedToTheRequestShiftQueryVariables>(GetUsersWhoRespondedToTheRequestShiftDocument, options);
        }
export type GetUsersWhoRespondedToTheRequestShiftQueryHookResult = ReturnType<typeof useGetUsersWhoRespondedToTheRequestShiftQuery>;
export type GetUsersWhoRespondedToTheRequestShiftLazyQueryHookResult = ReturnType<typeof useGetUsersWhoRespondedToTheRequestShiftLazyQuery>;
export type GetUsersWhoRespondedToTheRequestShiftSuspenseQueryHookResult = ReturnType<typeof useGetUsersWhoRespondedToTheRequestShiftSuspenseQuery>;
export type GetUsersWhoRespondedToTheRequestShiftQueryResult = Apollo.QueryResult<GetUsersWhoRespondedToTheRequestShiftQuery, GetUsersWhoRespondedToTheRequestShiftQueryVariables>;