import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveManagerFromFacilityMutationVariables = Types.Exact<{
  facilityId: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type RemoveManagerFromFacilityMutation = { __typename?: 'Mutation', removeManagerFromFacility: boolean };


export const RemoveManagerFromFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveManagerFromFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeManagerFromFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}]}]}}]} as unknown as DocumentNode;
export type RemoveManagerFromFacilityMutationFn = Apollo.MutationFunction<RemoveManagerFromFacilityMutation, RemoveManagerFromFacilityMutationVariables>;

/**
 * __useRemoveManagerFromFacilityMutation__
 *
 * To run a mutation, you first call `useRemoveManagerFromFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManagerFromFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManagerFromFacilityMutation, { data, loading, error }] = useRemoveManagerFromFacilityMutation({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveManagerFromFacilityMutation(baseOptions?: Apollo.MutationHookOptions<RemoveManagerFromFacilityMutation, RemoveManagerFromFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveManagerFromFacilityMutation, RemoveManagerFromFacilityMutationVariables>(RemoveManagerFromFacilityDocument, options);
      }
export type RemoveManagerFromFacilityMutationHookResult = ReturnType<typeof useRemoveManagerFromFacilityMutation>;
export type RemoveManagerFromFacilityMutationResult = Apollo.MutationResult<RemoveManagerFromFacilityMutation>;
export type RemoveManagerFromFacilityMutationOptions = Apollo.BaseMutationOptions<RemoveManagerFromFacilityMutation, RemoveManagerFromFacilityMutationVariables>;