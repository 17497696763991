import React, { useCallback, useRef, useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import clsx from 'clsx';

import Plus from 'assets/icons/plus';

import MainLayout from 'components/layout/main';

import RequestProfession from './components/editRequest/requestProfession';
import RequestProfessionDates from './components/editRequest/requestProfessionDates';
import CreateRequestSchedule from 'pages/request/components/editRequest/sidebar/createRequestSchedule';
import EditSchedule from 'pages/request/components/editRequest/sidebar/editSchedule';
import RequestPlan from './components/RequestPlan';
import { useBreadcrumbs, useCanEditRequest, useRequestDate } from '../facilityUpdateRequest/lib/hooks';
import RequestDay from './components/days';
import EditShift from 'pages/request/components/editRequest/sidebar/editShift';
import NewShift from 'pages/request/components/editRequest/sidebar/newShift';
import { useDaysCount } from 'utils/helpers/differenceBetweenDays';
import Legend from 'assets/icons/Legend';
import ChevronDown from 'assets/icons/chevron-down';
import LegendPopup from 'pages/request/components/legendPopup';
import RequestInformation from './components/information';
import { useCurrentRequest } from '../../utils/api/useCurrentRequest';

const EditRequest = () => {
  const [showEditGraphicSidebar, setShowEditGraphicSidebar] = useState(false);
  const [showCreateGraphicSidebar, setShowCreateGraphicSidebar] = useState(false);
  const [showLegendPopup, setShowLegendPopup] = useState(false);

  const { request, loading, error, isFinishedRequest } = useCurrentRequest();
  const days = useDaysCount({ dateFrom: request?.dateFrom, dateTo: request?.dateTo });
  const title = request ? `Заявка №${request.number}` : '';
  const requestDate = useRequestDate();
  const canEditRequest = useCanEditRequest();
  const breadcrumbs = useBreadcrumbs();

  const showLegendHandler = useCallback(() => {
    setShowLegendPopup(value => !value);
  }, []);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <MainLayout title={title} breadCrumbs={breadcrumbs} loading={loading} error={error}>
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-11 col-span-full p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-6">
          <h3 className="Subtitle1 text-smena_text-secondary">Информация</h3>
          <RequestInformation />
          <hr className="border-smena_gray-30" />
          <div className="flex gap-x-6">{request?.plans.map(plan => <RequestPlan key={plan.id} plan={plan} />)}</div>
        </div>
        <div className="xl:col-span-11 col-span-full pb-6 p-5 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-6">
          <div className="flex items-center justify-between relative">
            <h3 className="Subtitle1 text-smena_text-secondary">Планирование заявки</h3>
            <button
              className="Button1 text-primary flex items-center gap-x-2"
              onClick={showLegendHandler}
              ref={buttonRef}
            >
              <Legend />
              <span>Обозначения</span>
              <span className={clsx('inline-block transition-all', showLegendPopup && 'rotate-180 ')}>
                <ChevronDown />
              </span>
            </button>
            <LegendPopup
              showLegendPopup={showLegendPopup}
              setShowLegendPopup={setShowLegendPopup}
              triggerRef={buttonRef}
            />
          </div>
          <div className="flex justify-end">
            <span>{requestDate}</span>
          </div>
          {!request?.zayavkaSchedule?.length ? (
            <div className="flex flex-col items-center gap-y-2 py-4">
              <div className="Subtitle2 text-smena_text-secondary">В заявке пока нет графиков</div>
              <div className="Body2 text-smena_text-secondary w-56 text-center">
                Чтобы добавить график, нажмите «Добавить график смен»
              </div>
            </div>
          ) : (
            <div className="requestGraphic">
              <div>
                <div className="border-b py-0.5 pl-1 flex">
                  <span className="Tag">Профессии</span>
                </div>
                {request.zayavkaSchedule?.map(requestSchedule => (
                  <RequestProfession
                    key={requestSchedule.id}
                    requestSchedule={requestSchedule}
                    setShowEditGraphicSidebar={setShowEditGraphicSidebar}
                  />
                ))}
              </div>
              <ScrollSync>
                <div className="overflow-auto max-w-max">
                  <ScrollSyncPane>
                    <div
                      className={clsx(
                        'gap-x-1 grid grid-flow-col py-0.5 border-b sticky top-0 z-20 bg-smena_white w-fit',
                      )}
                      style={{
                        gridTemplateColumns: `repeat(${days.length}, 80px)`,
                      }}
                    >
                      {days.map(day => (
                        <RequestDay day={day} key={day.valueOf()} />
                      ))}
                    </div>
                  </ScrollSyncPane>
                  {request.zayavkaSchedule?.map(requestSchedule => (
                    <RequestProfessionDates key={requestSchedule.id} requestSchedule={requestSchedule} days={days} />
                  ))}
                </div>
              </ScrollSync>
            </div>
          )}

          {canEditRequest && !isFinishedRequest && (
            <div>
              <button
                className="flex items-center gap-x-2 Button1 text-primary"
                onClick={() => {
                  setShowCreateGraphicSidebar(value => !value);
                  setShowEditGraphicSidebar(false);
                }}
              >
                <Plus className="text-primary" />
                <span>Добавить график смен</span>
              </button>
            </div>
          )}
        </div>
      </div>
      <CreateRequestSchedule
        showCreateGraphicSidebar={showCreateGraphicSidebar}
        setShowCreateGraphicSidebar={setShowCreateGraphicSidebar}
      />
      <EditSchedule
        showEditGraphicSidebar={showEditGraphicSidebar}
        setShowEditGraphicSidebar={setShowEditGraphicSidebar}
      />
      <EditShift />
      <NewShift />
    </MainLayout>
  );
};

export default EditRequest;
