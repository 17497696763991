import { ZayavkaStage } from '../../../generated/graphql';

export const requestsStatuses = [
  { id: ZayavkaStage.Canceled, name: 'Отмененные' },
  { id: ZayavkaStage.Draft, name: 'Не отправлена' },
  { id: ZayavkaStage.Finished, name: 'Завершена' },
  { id: ZayavkaStage.New, name: 'Новая' },
  { id: ZayavkaStage.Refused, name: 'Отклонена' },
  { id: ZayavkaStage.Working, name: 'В работе' },
];

export const clientRequestsStatuses = [
  { id: ZayavkaStage.Canceled, name: 'Отмененные' },
  { id: ZayavkaStage.Draft, name: 'Не отправлена' },
  { id: ZayavkaStage.Finished, name: 'Завершена' },
  { id: ZayavkaStage.New, name: 'Отправлена' },
  { id: ZayavkaStage.Refused, name: 'Отклонена' },
  { id: ZayavkaStage.Working, name: 'В работе' },
];
