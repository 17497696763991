import { ITooltipStatus } from 'interfaces/ITooltipStatus';
import { CategoricalChartState } from 'recharts/types/chart/types';
import { chartClick, chartHover } from 'utils/helpers/charts/chartHandlers';

interface Props {
  state: CategoricalChartState;
  tooltipStatus: { status?: string; barId?: number };
}

interface IOnMouseMove extends Props {
  setFocusBar: React.Dispatch<React.SetStateAction<number | undefined>>;
}

interface IOnClick extends IOnMouseMove {
  setTooltipStatus: React.Dispatch<React.SetStateAction<ITooltipStatus>>;
}

export const onMouseMoveHandler = ({ state, tooltipStatus, setFocusBar }: IOnMouseMove) => {
  if (state !== null) {
    if (tooltipStatus.status === 'hover') {
      if (state.isTooltipActive) {
        setFocusBar(state.activeTooltipIndex);
      } else {
        setFocusBar(undefined);
      }
    }
  }
};

export const onClickHandler = ({ state, tooltipStatus, setFocusBar, setTooltipStatus }: IOnClick) => {
  if (state !== null) {
    if (tooltipStatus.barId === state.activeTooltipIndex) {
      setTooltipStatus(chartHover(state));
    } else {
      setTooltipStatus(chartClick(state));
    }
    setFocusBar(state.activeTooltipIndex);
  }
};
