export const timeObject = (time: { hours: number; minutes: number }) => {
  if (time.minutes < 10) return time.hours + ':0' + time.minutes;
  return time.hours + ':' + time.minutes;
};
export const padZeroTime = (time: { hours: number; minutes: number }) => {
  return {
    hours: time.hours < 10 ? time.hours.toString().padStart(2, '0') : time.hours,
    minutes: time.minutes < 10 ? time.minutes.toString().padStart(2, '0') : time.minutes,
  };
};
