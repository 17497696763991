import ReactQuill from 'react-quill';
import SimpleInput from 'ui/input';
import SimpleCheckbox from 'ui/checkbox';
import { FormikHandlers } from 'formik';
import MultiSelect from 'ui/multiSelect';
import { CasingDocumentTypes } from '../util/const';

interface Props {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (name: string, value: any) => void;
}

const VacancyDescription = ({ values, handleChange, setFieldValue }: Props) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'link', 'strike', 'blockquote'],
      ['clean'],
    ],
  };

  return (
    <>
      <span className="Subtitle1 text-smena_text-secondary">Описание вакансии</span>
      <div>
        <div className="label-primary">Описание</div>
        <ReactQuill
          className="mb-10 h-[400px] custom-quill"
          value={values.description}
          onChange={value => setFieldValue('description', value)}
          placeholder="Введите описание"
          modules={modules}
        />
        <span className="Caption-small text-smena_text-secondary inline-flex pt-1 max-h-10">
          Не более 1500 символов
        </span>
      </div>

      <div className={'flex gap-4'}>
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Отдел или секция"
          placeholder={'Введите отдел или секцию'}
          onChange={handleChange}
          value={values.section}
          type="text"
          name="section"
        />

        <MultiSelect
          divClassName="flex-[1_0]"
          value={values.requiredDocuments}
          selectClassName={'w-full'}
          label={'Необходимые документы'}
          name={'requiredDocuments'}
          placeholder={'Не выбран'}
          options={CasingDocumentTypes}
          onChange={value => {
            setFieldValue('requiredDocuments', value);
          }}
        />
        <div className={'flex-[1_0]'}></div>
      </div>
      <div className={'flex gap-x-4'}>
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Пробный период"
          placeholder="4"
          onChange={handleChange}
          regText
          value={values.probationPeriod}
          type="number"
          name="probationPeriod"
        />
        <div className="flex-[1_0]"></div>
        <div className="flex-[1_0]"></div>
      </div>
      <SimpleCheckbox
        divClassName="flex items-center"
        label="Обучение"
        onChange={handleChange}
        checked={values.hasEducation}
        name="hasEducation"
      />
      <SimpleCheckbox
        divClassName="flex items-center"
        label="Спец. одежда"
        required={false}
        onChange={handleChange}
        checked={values.workwear}
        name="workwear"
      />
    </>
  );
};

export default VacancyDescription;
