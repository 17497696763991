import { useMemo } from 'react';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { getFacilityGroup } from 'utils/helpers/get/getFacilityGroup';
import { sortObj } from 'utils/helpers/sortObject';

interface Props {
  cities: string[];
}

export const useFilteredFacilityGroups = ({ cities }: Props) => {
  const { facilities } = useSelectFacilities();
  const { facilitiesGroups } = useSelectFacilityGroups();
  return useMemo(() => {
    return sortObj([
      ...new Set(
        facilities
          .filter(facility => {
            return cities.length ? cities.some(city => city === facility.cityId) : true;
          })
          .map(facility => getFacilityGroup({ facilityGroupId: facility.facilityGroupId, facilitiesGroups }))
          .filter(Boolean),
      ),
    ]);
  }, [facilities, cities, facilitiesGroups]);
};
