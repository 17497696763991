import { MarkStatus, MarkType, UnitType } from 'generated/graphql';
import { ShiftFactFragment } from 'pages/moderationReport/api/fragments/generated/ShiftFact.fragment';
import { padZeroTime } from 'utils/helpers/timeObject';
import { moderationShiftDuration } from './hooks';

interface IMarks {
  status: MarkStatus;
  statusMessage: string;
  type: MarkType;
  time: string;
  distance?: number | null | undefined;
}

export const messageColorHelper = (message?: string | boolean) => {
  if (message) {
    return 'text-smena_red-dark';
  }
  return 'text-smena_text-secondary';
};

export const markColorHelper = (marks?: IMarks[]) => {
  const statusMessage = marks?.length ? marks[0].statusMessage : true;
  return messageColorHelper(statusMessage);
};

export const factValueHelper = (shift: ShiftFactFragment) => {
  if (shift.unit === UnitType.Hour) {
    const duration = moderationShiftDuration(shift);
    return `${padZeroTime(duration).hours}:${padZeroTime(duration).minutes}`;
  }
  return shift.moderation?.value;
};
