import { useCallback } from 'react';
import { ClientUserFragment } from 'pages/users/api/fragments/generated/ClientUser.fragment';
import { userFullName } from 'utils/helpers/userFullName';
import dayjs from 'dayjs';
import { ISO_DATE } from 'utils/helpers/constVariables';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useUnassignedWorkerFromFacilityGroupMutation } from 'pages/facilities/api/mutations/generated/UnassignWorkerFromFacilityGroup';
import { useStore } from 'store';
import { useUnassignedWorkerFromFacilityMutation } from 'pages/facilities/api/mutations/generated/UnassignedWorkerFromFacility';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';
import Modal from 'ui/modal';

interface Props {
  showStopCooperationPopup: boolean;
  setShowStopCooperationPopup: React.Dispatch<React.SetStateAction<boolean>>;
  user?: ClientUserFragment | UsersPaginatedFragment | null;
  facility?: {
    id: string;
    name: string;
  };
  facilities?:
    | {
        id: string;
        name: string;
        facilityGroupId: string;
      }[]
    | null;
}

const StopCooperationPopup: React.FC<Props> = ({
  showStopCooperationPopup,
  setShowStopCooperationPopup,
  user,
  facility,
  facilities,
}) => {
  const { stopCooperationComment, stopCooperationDate } = useStore();
  const [unassignedWorkerFromFacilityGroup] = useUnassignedWorkerFromFacilityGroupMutation();
  const [unassignedWorkerFromFacility] = useUnassignedWorkerFromFacilityMutation();
  const onCloseShowStopCooperationPopup = useCallback(
    () => setShowStopCooperationPopup(false),
    [setShowStopCooperationPopup],
  );

  const onClickShowStopCooperationPopup = () => {
    if (user?.id && facilities?.length) {
      unassignedWorkerFromFacilityGroup({
        variables: {
          input: {
            userId: user.id,
            facilityGroupId: facilities[0].facilityGroupId,
            unassignAt: dayjs(stopCooperationDate).format(ISO_DATE),
            unassignComment: stopCooperationComment,
          },
        },
      })
        .then(() => successToast('Сотрудник откреплен от всех профессий объекта всех объектов группы'))
        .catch(error => errorToast(errorHandler(error)))
        .finally(onCloseShowStopCooperationPopup);
    } else if (facility) {
      if (user?.id && facility) {
        unassignedWorkerFromFacility({
          variables: {
            input: {
              userId: user.id,
              facilityId: facility.id,
              unassignAt: dayjs(stopCooperationDate).format(ISO_DATE),
              unassignComment: stopCooperationComment,
            },
          },
        })
          .then(() => successToast('Сотрудник откреплен от всех профессий объекта'))
          .catch(error => errorToast(errorHandler(error)))
          .finally(onCloseShowStopCooperationPopup);
      }
    }
    setShowStopCooperationPopup(false);
  };
  return (
    <Modal
      title="Изменить статус?"
      open={showStopCooperationPopup}
      onCancel={onCloseShowStopCooperationPopup}
      onOk={onClickShowStopCooperationPopup}
    >
      {userFullName(user)} будет откреплен (-а) от всех профессий на объекте{' '}
      {facilities?.length ? facilities.map(fac => fac.name).join(', ') : facility?.name}. Все графики и смены будут
      отменены
    </Modal>
  );
};

export default StopCooperationPopup;
