const FacilityIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43438 2.15698C3.76566 1.54859 2 2.7842 2 4.56037V21.2042H14.5582V21.204H21.9998V12.3667C21.9998 10.9539 20.8545 9.80859 19.4417 9.80859H14.5582V7.27355C14.5582 6.19862 13.8862 5.23835 12.8762 4.87016L5.43438 2.15698ZM14.5582 19.8086V11.2039H19.4417C20.0839 11.2039 20.6045 11.7245 20.6045 12.3667V19.8086H14.5582ZM13.1628 9.80859V7.27355C13.1628 6.78494 12.8573 6.34846 12.3983 6.1811L4.95643 3.46792C4.19792 3.19138 3.39535 3.75302 3.39535 4.56037V19.8088H5.02317L5.02317 15.8545C5.02317 14.9554 5.75201 14.2266 6.65108 14.2266H9.67434C10.5734 14.2266 11.3022 14.9554 11.3022 15.8545V19.8088H13.1626V9.80859H13.1628ZM9.9069 15.8545C9.9069 15.726 9.80278 15.6219 9.67434 15.6219L6.65108 15.6219C6.52264 15.6219 6.41852 15.726 6.41852 15.8545L6.41852 19.808H9.9069L9.9069 15.8545ZM5.02336 8C4.63804 8 4.32568 8.31236 4.32568 8.69768C4.32568 9.08299 4.63804 9.39535 5.02336 9.39535H6.88383C7.26914 9.39535 7.5815 9.08299 7.5815 8.69768C7.5815 8.31236 7.26914 8 6.88383 8H5.02336ZM4.32568 11.0238C4.32568 10.6385 4.63804 10.3262 5.02336 10.3262H6.88383C7.26914 10.3262 7.5815 10.6385 7.5815 11.0238C7.5815 11.4092 7.26914 11.7215 6.88383 11.7215H5.02336C4.63804 11.7215 4.32568 11.4092 4.32568 11.0238ZM9.44182 10.3262C9.0565 10.3262 8.74414 10.6385 8.74414 11.0238C8.74414 11.4092 9.0565 11.7215 9.44182 11.7215H11.3023C11.6876 11.7215 12 11.4092 12 11.0238C12 10.6385 11.6876 10.3262 11.3023 10.3262H9.44182ZM15.9536 13.0649C15.9536 12.6795 16.266 12.3672 16.6513 12.3672H18.5118C18.8971 12.3672 19.2094 12.6795 19.2094 13.0649C19.2094 13.4502 18.8971 13.7625 18.5118 13.7625H16.6513C16.266 13.7625 15.9536 13.4502 15.9536 13.0649ZM9.44182 8C9.0565 8 8.74414 8.31236 8.74414 8.69768C8.74414 9.08299 9.0565 9.39535 9.44182 9.39535H11.3023C11.6876 9.39535 12 9.08299 12 8.69768C12 8.31236 11.6876 8 11.3023 8H9.44182Z"
      />
    </svg>
  );
};

export default FacilityIcon;
