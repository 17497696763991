import React, { useCallback, useEffect, useState } from 'react';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import TextArea from 'ui/textArea';
import MainSidebar from 'ui/sidebar';
import dayjs from 'dayjs';
import { useStore } from 'store';
import { ClientUserFragment } from 'pages/users/api/fragments/generated/ClientUser.fragment';
import { userFullName } from 'utils/helpers/userFullName';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';

interface Props {
  showStopCooperationSidebar: boolean;
  setShowStopCooperationSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  setShowStopCooperationPopup: React.Dispatch<React.SetStateAction<boolean>>;
  user?: ClientUserFragment | UsersPaginatedFragment | null;
  facility?: {
    id: string;
    name: string;
  };
  facilities?:
    | {
        id: string;
        name: string;
      }[]
    | null;
}

const StopCooperationSidebar: React.FC<Props> = ({
  showStopCooperationSidebar,
  setShowStopCooperationSidebar,
  setShowStopCooperationPopup,
  user,
  facility,
  facilities,
}) => {
  const stopCooperationComment = useStore(state => state.stopCooperationComment);
  const stopCooperationDate = useStore(state => state.stopCooperationDate);
  const setStopCooperationComment = useStore(state => state.setStopCooperationComment);
  const setStopCooperationDate = useStore(state => state.setStopCooperationDate);
  const [stopCooperationDateError, setStopCooperationDateError] = useState('');
  const onChangeStopCooperationDate = useCallback(
    (value: dayjs.Dayjs | null) => {
      setStopCooperationDate(value);
    },
    [setStopCooperationDate],
  );
  const onChangeStopCooperationComment = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => setStopCooperationComment(value),
    [setStopCooperationComment],
  );
  const onShowChangeStatus = useCallback(() => {
    if (!stopCooperationDate?.isValid()) {
      return setStopCooperationDateError('Обязательное поле');
    }
    setStopCooperationDateError('');
    setShowStopCooperationSidebar(false);
    setShowStopCooperationPopup(true);
  }, [setShowStopCooperationSidebar, setShowStopCooperationPopup, stopCooperationDate]);

  useEffect(() => {
    return () => {
      setStopCooperationComment('');
      setStopCooperationDate(null);
    };
  }, [setStopCooperationComment, setStopCooperationDate]);

  return (
    <MainSidebar
      show={showStopCooperationSidebar}
      setShow={setShowStopCooperationSidebar}
      title={'Прекратить сотрудничество'}
    >
      <div className={'flex flex-col gap-y-6'}>
        <div className={'flex gap-x-1 Subtitle1'}>
          <span>Исполнитель:</span>
          <Link to={`/users/${user?.id}`} className={'link'}>
            {userFullName(user)}
          </Link>
        </div>
        <div>
          <div className={clsx('flex gap-x-1 Subtitle1', facilities?.length ? 'flex-col gap-y-1' : 'flex-row')}>
            <span>Объект (-ы):</span>
            {facilities?.length ? (
              facilities.map(el => (
                <Link key={el.id} to={`/facilities/${el.id}`} className={'link truncate'} title={el.name}>
                  {el.name}
                </Link>
              ))
            ) : facility ? (
              <Link to={`/facilities/${facility.id}`} className={'link'}>
                {facility.name}
              </Link>
            ) : null}
          </div>
          <div className={'Caption text-smena_text-secondary'}>
            Исполнитель будет откреплен от всех профессий на этом (-их) объекте (-ах).{' '}
          </div>
        </div>

        <DatePickerInput
          divClassName={'flex-[1_0]'}
          label={'Дата конца сотрудничества'}
          onChange={onChangeStopCooperationDate}
          value={stopCooperationDate}
          error={stopCooperationDateError}
          placement={'topLeft'}
        />
        <TextArea
          style={{
            height: '75px',
          }}
          name={'stopCooperationComment'}
          label={'Комментарий (если нужно)'}
          value={stopCooperationComment}
          placeholder={'Оставьте комментарий'}
          onChange={onChangeStopCooperationComment}
        />
        <div className={'flex justify-end'}>
          <button className={'btn-primary_small'} onClick={onShowChangeStatus}>
            Изменить статус
          </button>
        </div>
      </div>
    </MainSidebar>
  );
};

export default StopCooperationSidebar;
