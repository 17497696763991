import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddDirectorToFacilityGroupMutationVariables = Types.Exact<{
  groupId: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type AddDirectorToFacilityGroupMutation = { __typename?: 'Mutation', addDirectorToFacilityGroup: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role } };


export const AddDirectorToFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddDirectorToFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"groupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addDirectorToFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"groupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"groupId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstname"}},{"kind":"Field","name":{"kind":"Name","value":"lastname"}},{"kind":"Field","name":{"kind":"Name","value":"patronymic"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode;
export type AddDirectorToFacilityGroupMutationFn = Apollo.MutationFunction<AddDirectorToFacilityGroupMutation, AddDirectorToFacilityGroupMutationVariables>;

/**
 * __useAddDirectorToFacilityGroupMutation__
 *
 * To run a mutation, you first call `useAddDirectorToFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDirectorToFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDirectorToFacilityGroupMutation, { data, loading, error }] = useAddDirectorToFacilityGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddDirectorToFacilityGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddDirectorToFacilityGroupMutation, AddDirectorToFacilityGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDirectorToFacilityGroupMutation, AddDirectorToFacilityGroupMutationVariables>(AddDirectorToFacilityGroupDocument, options);
      }
export type AddDirectorToFacilityGroupMutationHookResult = ReturnType<typeof useAddDirectorToFacilityGroupMutation>;
export type AddDirectorToFacilityGroupMutationResult = Apollo.MutationResult<AddDirectorToFacilityGroupMutation>;
export type AddDirectorToFacilityGroupMutationOptions = Apollo.BaseMutationOptions<AddDirectorToFacilityGroupMutation, AddDirectorToFacilityGroupMutationVariables>;