import clsx from 'clsx';
import React, { useCallback, useMemo, useRef } from 'react';
import GarbageArrowLess from 'assets/icons/GarbageArrowLess';
import PaperAirPlane from 'assets/icons/PaperAirPlane';
import PencilIcon from 'assets/icons/PencilIcon';
import { Role, ZayavkaChangesStatus, ZayavkaStage, ZayavkaToCancelStatus } from 'generated/graphql';
import { useMeRole } from 'store/hooks/useMeRole';
import { useMe } from 'utils/api/useMe';
import Tooltip from 'antd/lib/tooltip';

interface IStatusIcon {
  request?: {
    status: ZayavkaStage;
    zayavkaChanges?: { authorId: string; stage: ZayavkaChangesStatus }[] | null;
    toCancel: ZayavkaToCancelStatus;
  };
}
const StatusIcon = React.memo(({ request }: IStatusIcon) => {
  const me = useMe();

  const isMeManager = useMeRole([Role.ClientManager]);
  const isMeDirector = useMeRole([Role.ClientDirector]);
  const isMeSupervisor = useMeRole([Role.Supervisor]);

  const showPlaneChanges = request?.zayavkaChanges?.filter(
    change => change.stage === ZayavkaChangesStatus.Waiting || change.stage === ZayavkaChangesStatus.Ineligible,
  );

  const changesByStatus = useCallback(
    (status: ZayavkaChangesStatus) => request?.zayavkaChanges?.filter(change => change.stage === status),
    [request?.zayavkaChanges],
  );

  const isRequestStatusEqual = useCallback((status: ZayavkaStage) => request?.status === status, [request?.status]);

  const myChanges = useMemo(
    () => request?.zayavkaChanges?.find(change => change.authorId === me?.id),
    [request?.zayavkaChanges, me?.id],
  );
  const showPlaneChangesEl = useCallback(
    (status: ZayavkaChangesStatus) => {
      return showPlaneChanges?.find(change => change.stage === status);
    },
    [showPlaneChanges],
  );

  const showPlane =
    (isMeManager &&
      isRequestStatusEqual(ZayavkaStage.Draft) &&
      (myChanges?.stage === ZayavkaChangesStatus.Waiting || showPlaneChangesEl(ZayavkaChangesStatus.Ineligible))) ||
    (isMeDirector && request?.status === ZayavkaStage.Draft && Boolean(showPlaneChanges?.length));

  const showPencil = isMeSupervisor
    ? request?.status === ZayavkaStage.Working &&
      (Boolean(changesByStatus(ZayavkaChangesStatus.New)?.length) ||
        Boolean(changesByStatus(ZayavkaChangesStatus.Refused)?.length))
    : request?.status === ZayavkaStage.Working && Boolean(request?.zayavkaChanges?.length);

  const showGarbage =
    ((isMeManager || isMeDirector) && request?.toCancel === ZayavkaToCancelStatus.Waiting) ||
    request?.toCancel === ZayavkaToCancelStatus.Ineligible;

  const content = useMemo(() => {
    if (showGarbage) {
      if (request?.toCancel === ZayavkaToCancelStatus.Waiting) {
        return {
          text: 'Отмена ожидает подтверждения',
          textColor: 'text-smena_orange',
          icon: 'bg-smena_orange-extra_light',
          statusShowTooltip: true,
        };
      }
      if (request.toCancel === ZayavkaToCancelStatus.Ineligible) {
        return {
          text: 'Отмена отклонена',
          textColor: 'text-smena_red',
          icon: 'bg-smena_red-extra_light',
          statusShowTooltip: true,
        };
      }
    }
    if (showPlane) {
      if (myChanges?.stage === ZayavkaChangesStatus.Waiting || showPlaneChangesEl(ZayavkaChangesStatus.Waiting)) {
        return {
          text: 'Ожидает подтверждения',
          textColor: 'text-smena_orange',
          icon: 'border border-smena_orange',
          statusShowTooltip: true,
        };
      }
      if (myChanges?.stage === ZayavkaChangesStatus.Ineligible || showPlaneChangesEl(ZayavkaChangesStatus.Ineligible)) {
        return {
          text: 'Отклонено',
          textColor: 'text-smena_red',
          icon: 'border border-smena_red',
          statusShowTooltip: true,
        };
      }
    }
    if (showPencil) {
      if (changesByStatus(ZayavkaChangesStatus.Waiting)?.length) {
        return {
          text: 'Изменение ожидает подтверждения',
          textColor: 'text-smena_orange',
          icon: 'bg-smena_orange-extra_light',
          statusShowTooltip: true,
        };
      }
      if (changesByStatus(ZayavkaChangesStatus.Draft)?.length) {
        return {
          text: '',
          textColor: '',
          icon: 'border border-smena_text-secondary',
          statusShowTooltip: false,
        };
      }
      if (changesByStatus(ZayavkaChangesStatus.Refused)?.length) {
        return {
          text: isMeSupervisor ? 'Изменение отклонено' : 'Изменение отклонено супервайзером',
          textColor: 'text-smena_red',
          icon: 'bg-smena_red-extra_light',
          statusShowTooltip: true,
        };
      }
      if (changesByStatus(ZayavkaChangesStatus.Ineligible)?.length) {
        return {
          text: 'Изменение отклонено',
          textColor: 'text-smena_red',
          icon: 'bg-smena_red-extra_light',
          statusShowTooltip: true,
        };
      }
      if (changesByStatus(ZayavkaChangesStatus.New)?.length) {
        return {
          text: isMeSupervisor ? 'Новое изменение' : 'Изменение отправлено',
          textColor: 'text-primary',
          icon: 'bg-smena_bb-background',
          statusShowTooltip: true,
        };
      }
    }
    return {
      text: '',
      textColor: '',
      icon: '',
      statusShowTooltip: false,
    };
  }, [
    changesByStatus,
    isMeSupervisor,
    myChanges?.stage,
    request?.toCancel,
    showGarbage,
    showPencil,
    showPlane,
    showPlaneChangesEl,
  ]);

  const { icon, text, textColor } = content;

  const btnRef = useRef<HTMLButtonElement | null>(null);
  return (
    <>
      {(showPlane || showPencil || showGarbage) && (
        <button className="flex" ref={btnRef}>
          <Tooltip title={text} overlayInnerStyle={{ width: 'max-content' }}>
            <span className={clsx('w-6 h-6 rounded-full flex justify-center items-center', icon)}>
              {showGarbage ? (
                <GarbageArrowLess className={textColor} />
              ) : showPlane ? (
                <PaperAirPlane className={textColor} />
              ) : showPencil ? (
                <PencilIcon className={textColor} />
              ) : null}
            </span>
          </Tooltip>
        </button>
      )}
    </>
  );
});

export default StatusIcon;
