import { getLink } from 'utils/helpers/get/getLink';

export function useStocksHeaderMenu() {
  const referrals = '/offers/referrals';
  const leads = '/offers/leads';
  const twentyOne = '/offers/twenty-one';
  const promotions = '/offers/promotions';
  return [
    { name: 'Рефералы', link: getLink(leads) },
    { name: 'Реферальные программы', link: getLink(referrals) },
    { name: '21 друг', link: getLink(twentyOne) },
    { name: 'Бонусные программы', link: getLink(promotions) },
  ];
}
