const HideArrowMenu = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.31012 21.206C2.89663 20.7533 2.89663 20.0194 3.31012 19.5668L9.97319 12.2727L3.31012 4.97869C2.89663 4.52604 2.89663 3.79214 3.31012 3.33949C3.72362 2.88684 4.39403 2.88684 4.80752 3.33949L12.2193 11.4531C12.6328 11.9058 12.6328 12.6397 12.2193 13.0923L4.80752 21.206C4.39403 21.6586 3.72362 21.6586 3.31012 21.206Z"
        fill="#397DFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7789 21.206C11.3654 20.7533 11.3654 20.0194 11.7789 19.5668L18.4419 12.2727L11.7789 4.97869C11.3654 4.52604 11.3654 3.79214 11.7789 3.33949C12.1924 2.88684 12.8628 2.88684 13.2763 3.33949L20.688 11.4531C21.1015 11.9058 21.1015 12.6397 20.688 13.0923L13.2763 21.206C12.8628 21.6586 12.1924 21.6586 11.7789 21.206Z"
        fill="#397DFF"
      />
    </svg>
  );
};

export default HideArrowMenu;
