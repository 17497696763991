import { useCallback } from 'react';
import { useCurrentUser } from 'utils/api/useUser';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useSaveUserQuestionnaireMutation } from '../api/mutations/generated/SaveUserQuestionnaire';

export const useQuestionnairePage = () => {
  const { user, userId } = useCurrentUser();
  const questionnaireUser = user?.questionnaire;

  const [updateQuestionnaire, { loading }] = useSaveUserQuestionnaireMutation();
  const initialValues = {
    sex: questionnaireUser?.sex,
    growth: questionnaireUser?.growth ? String(questionnaireUser?.growth) : '',
    clothingSize: questionnaireUser?.clothingSize,
    footSize: questionnaireUser?.footSize,
  };

  const validate = (values: typeof initialValues) => {
    const errors: any = {};
    if (!values.sex) {
      errors.sex = 'Обязательное поле';
    }
    if (values.growth === '0') {
      errors.growth = 'Обязательное поле';
    }
    if (Number(values.growth) < 140 || Number(values.growth) > 220) {
      errors.growth = 'Введите корректный рост';
    }
    if (!values.clothingSize) {
      errors.clothingSize = 'Обязательное поле';
    }
    if (!values.footSize) {
      errors.footSize = 'Обязательное поле';
    }
    return errors;
  };
  const onSubmit = useCallback(
    (values: typeof initialValues) => {
      if (!userId || !user) return;
      updateQuestionnaire({
        variables: {
          input: {
            userId,
            sex: values.sex,
            growth: Number(values.growth),
            clothingSize: String(values.clothingSize),
            footSize: Number(values.footSize),
          },
        },
      })
        .then(e => {
          if (e.data?.saveUserQuestionnaire) {
            return successToast('Анкета обновлена');
          }
        })
        .catch(e => {
          return errorToast(errorHandler(e));
        });
    },
    [updateQuestionnaire, user, userId],
  );

  return { initialValues, validate, onSubmit, loading };
};
