import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeFacilityStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ChangeFacilityStatusMutation = { __typename?: 'Mutation', changeFacilityStatus: { __typename?: 'Facility', id: string, is_active?: boolean | null } };


export const ChangeFacilityStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeFacilityStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeFacilityStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"is_active"}}]}}]}}]} as unknown as DocumentNode;
export type ChangeFacilityStatusMutationFn = Apollo.MutationFunction<ChangeFacilityStatusMutation, ChangeFacilityStatusMutationVariables>;

/**
 * __useChangeFacilityStatusMutation__
 *
 * To run a mutation, you first call `useChangeFacilityStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFacilityStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFacilityStatusMutation, { data, loading, error }] = useChangeFacilityStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeFacilityStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeFacilityStatusMutation, ChangeFacilityStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeFacilityStatusMutation, ChangeFacilityStatusMutationVariables>(ChangeFacilityStatusDocument, options);
      }
export type ChangeFacilityStatusMutationHookResult = ReturnType<typeof useChangeFacilityStatusMutation>;
export type ChangeFacilityStatusMutationResult = Apollo.MutationResult<ChangeFacilityStatusMutation>;
export type ChangeFacilityStatusMutationOptions = Apollo.BaseMutationOptions<ChangeFacilityStatusMutation, ChangeFacilityStatusMutationVariables>;