import { useMemo } from 'react';
import { useFacilityGroupFacilitiesStore } from '../../store';
import { useUpdateFacilitiesActivityMutation } from '../../api/mutations/generated/UpdateFacilitiesActivity';
import { Table } from '@tanstack/react-table';
import { FacilityPaginatedFragment } from 'pages/facilities/api/fragments/generated/FacilityPaginated.fragment';
import { successToast } from 'utils/helpers/notify';
import Modal from 'ui/modal';

type Props = { table: Table<FacilityPaginatedFragment> };

const ChangeStatus = ({ table }: Props) => {
  const showChangeStatusPopup = useFacilityGroupFacilitiesStore(state => state.showChangeStatusPopup);
  const setShowChangeStatusPopup = useFacilityGroupFacilitiesStore(state => state.setShowChangeStatusPopup);
  const newStatus = useFacilityGroupFacilitiesStore(state => state.newStatus);
  const showLegalEntityHandler = () => {
    setShowChangeStatusPopup(!showChangeStatusPopup);
  };
  const facilitiesIds = useMemo(() => table.getSelectedRowModel().rows.map(row => row.original.id), [table]);
  const [updateFacilitiesActivity] = useUpdateFacilitiesActivityMutation();
  const updateFacilitiesActivityHandle = () => {
    updateFacilitiesActivity({
      variables: {
        input: { is_active: newStatus, facilitiesIds },
      },
      update: cache => {
        facilitiesIds.forEach(el => {
          cache.modify({
            id: `Facility:${el}`,
            fields: {
              is_active() {
                return newStatus;
              },
            },
          });
        });
      },
    }).then(() => {
      successToast('Статусы активности обновлены');
      showLegalEntityHandler();
      table.toggleAllRowsSelected(false);
    });
  };
  return (
    <Modal
      title="Изменить статус?"
      open={showChangeStatusPopup}
      onCancel={showLegalEntityHandler}
      onOk={updateFacilitiesActivityHandle}
      okText="Изменить"
    >{`Вы уверены, что хотите изменить статус на ${newStatus ? 'активный' : 'не активный'}?`}</Modal>
  );
};

export default ChangeStatus;
