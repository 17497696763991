import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Magnifier from '../../../../../assets/icons/magnifier';
import Skeleton from 'antd/es/skeleton/Skeleton';
import UserAtSidebar from '../../userAtSidebar';
import { debounce } from 'lodash';
import { useStore } from '../../../../../store';
import { useGetWorkersForRequestShiftLazyQuery } from 'pages/facilityRequests/api/queries/generated/GetWorkersForRequestShift';
import { sortedUsersFn } from '../helpers';
import { useAssignRequestShiftMutation } from 'pages/facilityRequests/api/mutations/generated/AssignRequestShift';
import { errorToast, successToast } from '../../../../../utils/helpers/notify';
import { errorHandler } from '../../../../../utils/helpers/errorHandler';
import { useSendFreeShiftByRequestShiftIdMutation } from '../../../api/mutations/generated/SendFreeShiftByRequestShiftId';
import { useFacility } from '../../../../../utils/api/useFacility';

const EmptyShift = () => {
  const { id } = useFacility();
  const requestShift = useStore(state => state.requestShift);
  const userIntersect = useStore(state => state.userIntersect);
  const setShowShiftSidebar = useStore(state => state.setShowShiftSidebar);
  const setUserIntersect = useStore(state => state.setUserIntersect);
  const [search, setSearch] = useState('');
  const [loadUserIntersectData, { data: usersIntersectData, loading }] = useGetWorkersForRequestShiftLazyQuery();
  const [assignRequestShift, { loading: loadingAssignRequestShift }] = useAssignRequestShiftMutation();
  const users = usersIntersectData?.getWorkersForZayavkaShift;
  const sortedUsers = useMemo(() => sortedUsersFn(users), [users]);
  const handleChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const [sendFreeShiftByRequestShiftId] = useSendFreeShiftByRequestShiftIdMutation();

  const sendFreeShift = () => {
    if (!requestShift || !id) return;
    sendFreeShiftByRequestShiftId({
      variables: {
        input: {
          requestShiftId: requestShift.id,
          facilityId: id,
        },
      },
    })
      .then(() => {
        successToast('Смена стала свободной');
        setShowShiftSidebar(false);
      })
      .catch(err => errorToast(errorHandler(err)));
  };
  const debounceOnChange = debounce(handleChangeSearch, 600);

  const assignRequestShiftHandler = () => {
    if (requestShift && userIntersect) {
      assignRequestShift({
        variables: {
          workerId: userIntersect.id,
          zayavkaShiftId: requestShift.id,
        },
      })
        .then(() => {
          successToast('Пользователь назначен на смену');
          setShowShiftSidebar(false);
          setUserIntersect(undefined);
        })
        .catch(error => {
          errorToast(errorHandler(error));
        });
    } else {
      errorToast('Выберите исполнителя');
    }
  };

  useEffect(() => {
    if (requestShift?.id && !requestShift?.shiftId) {
      loadUserIntersectData({
        variables: {
          zayavkaShiftId: requestShift.id,
          searchQuery: search,
        },
      });
    }
  }, [loadUserIntersectData, requestShift?.id, requestShift?.shiftId, search]);
  return (
    <>
      <div className="relative">
        <span className="absolute top-0 left-0 transform translate-x-1 translate-y-1">
          <Magnifier />
        </span>
        <input
          className="input-primary pl-8"
          onChange={debounceOnChange}
          type="search"
          name="search"
          id="search"
          placeholder="Поиск"
        />
      </div>

      {loading ? (
        <Skeleton active />
      ) : users?.length ? (
        <div className="border-b border-smena_gray-30">
          <div className="graphicSearchUserTable p-4 border-t border-b border-smena_gray-30">
            <span className="Table-H1 text-smena_text-secondary">Исполнитель</span>
            <span className="Table-H1 text-smena_text-secondary">Занятость</span>
          </div>
          <div className={'overflow-auto max-h-[345px]'}>
            {sortedUsers?.map(user => <UserAtSidebar key={user.id} user={user} />)}
          </div>
        </div>
      ) : (
        <span className="Subtitle1">
          По вашему запросу <span className="font-bold">{search}</span> ничего не нашлось
        </span>
      )}
      <button
        className="btn-primary self-end mt-5"
        type="button"
        onClick={assignRequestShiftHandler}
        disabled={loadingAssignRequestShift || !userIntersect}
      >
        Назначить на смену
      </button>
      <button className={'btn-stroke self-end'} type={'button'} onClick={sendFreeShift}>
        Сделать смену свободной
      </button>
    </>
  );
};

export default EmptyShift;
