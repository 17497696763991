import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Plus from 'assets/icons/plus';
import { positionName } from 'utils/helpers/filters/filtersName';
import SimpleSelect from 'ui/select';
import { useMeRole } from 'store/hooks/useMeRole';
import Search from 'ui/search';
import { useCustomSearchParams } from 'pages/users/lib/useParams';
import TableFilter from 'components/table/filters';
import Filter from 'components/table/filters/filter';
import { usePositions } from 'utils/api/usePositions';
import { useFacilityCasings } from 'utils/api/useFacilityCasings';
import { getPosition } from 'utils/helpers/get/getPosition';
import { Role } from 'generated/graphql';

const FacilityCasingsFilter = () => {
  const { handleParams, params, setParams, useParamsCount, resetFilter } = useCustomSearchParams();
  const { facilityCasings } = useFacilityCasings();
  const positionIdParam = params.get('positionId');
  const [positionId, setPositionId] = useState<string | null>(positionIdParam);
  const [showFilter, setShowFilter] = useState(false);
  const { positions } = usePositions();
  const positionOptions = facilityCasings.map(el => getPosition(el.positionId, positions));

  const paramsCount = useParamsCount();
  const btnRef = useRef<HTMLButtonElement>(null);
  const isMeAdmin = useMeRole([Role.Admin]);

  return (
    <div className="filter-bar flex xs:flex-row flex-col gap-y-2 justify-between shadow-smena">
      <div className="flex flex-col gap-y-2">
        <div className={'flex relative gap-x-2'}>
          <button
            type="button"
            ref={btnRef}
            className="btn-stroke flex gap-x-1 items-center"
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <Search />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              label="Профессия"
              onChange={value => setPositionId(value)}
              value={positionId}
              allOption={'Все профессии'}
              placeholder={'Не выбрано'}
              options={positionOptions}
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('positionId', positionId);
                  params.delete('page');
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setPositionId(null);
                  setParams();
                  setShowFilter(false);
                }}
              >
                Сбросить
              </button>
            </div>
          </TableFilter>
        </div>

        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!positionIdParam}
              content={positionName(positions, positionId)}
              clickHandler={() => {
                setPositionId(null);
                resetFilter('positionId');
              }}
            />
          </div>
        )}
      </div>
      {isMeAdmin && (
        <div className="flex self-start">
          <Link to={`create`}>
            <button type="button" className="btn-primary_big">
              <span className="flex items-center">
                <span className="inline-flex mr-2">
                  <Plus className="text-smena_white" />
                </span>
                Новая профессия
              </span>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default FacilityCasingsFilter;
