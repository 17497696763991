import { useParams, useSearchParams } from 'react-router-dom';

import { LeadFieldsForSorting, LeadStatus, ReferralProgramRoleEnum, Role, SortingWay } from 'generated/graphql';

import MainLayout from 'components/layout/main';
import LeadsFilters from 'pages/leads/components/LeadsFilters';
import CustomPagination from 'ui/pagination';
import { useStocksHeaderMenu } from './utils/hooks/headerMenu';
import dayjs from 'dayjs';
import { useLeadsTable } from '../../components/table/columns/leads';
import NewTable from '../../components/table/newTable';
import { useLeadsStore } from './store';
import Modal from 'ui/modal';
import { LeadFragment } from 'pages/lead/api/fragments/generated/Lead.fragment';
import { usegetLeadsV2Query } from './api/queries/generated/leads';
import { useReferralProgramHeaderMenu } from 'utils/hooks/headerMenu/useReferralProgramHeaderMenu';

const LeadsPage = () => {
  const { setShowPayModal, showPayModal, callbackModal, leadId } = useLeadsStore(state => state);

  const { settingId } = useParams();

  const [params] = useSearchParams();
  const facilityGroupIdParam = params.get('facilityGroupId');
  const facilityIdParam = params.get('facilityId');
  const statusParams = params.get('status');
  const roleParams = params.get('role');
  const dateFromParam = params.get('dateFrom');
  const dateToParam = params.get('dateTo');
  const achievementDateFromParam = params.get('achievementDateFrom');
  const achievementDateToParam = params.get('achievementDateTo');
  const searchParams = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParams = params.get('page');
  const page = pageParams ? +pageParams : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const dateFrom = dateFromParam ? dayjs(dateFromParam) : null;
  const dateTo = dateToParam ? dayjs(dateToParam).endOf('day') : null;
  const achievementDateFrom = achievementDateFromParam ? dayjs(achievementDateFromParam) : null;
  const achievementDateTo = achievementDateToParam ? dayjs(achievementDateToParam).endOf('day') : null;

  const { data, loading } = usegetLeadsV2Query({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        referralProgramId: settingId,
        page,
        searchQuery: searchParams,
        role: roleParams as Role,
        status: statusParams as LeadStatus,
        sortBy: sortBy as LeadFieldsForSorting,
        sortWay: sortWay as SortingWay,
        inviteDateFrom: dateFrom,
        inviteDateTo: dateTo,
        achieveDateFrom: achievementDateFrom,
        achieveDateTo: achievementDateTo,
        facilityId: facilityIdParam,
        facilityGroupId: facilityGroupIdParam,
        size,
      },
    },
  });
  const leadsPagination = data?.getLeadsV2;
  const leads = leadsPagination?.leads || [];
  const table = useLeadsTable(leads);
  const headerMenu = useStocksHeaderMenu();
  const referralHeaderMenu = useReferralProgramHeaderMenu();

  const handlePay = () => {
    if (leadId) {
      callbackModal({
        variables: { input: { setFor: ReferralProgramRoleEnum.Referrer, leadId } },
      }).then(setShowPayModal(false));
    }
  };

  return (
    <MainLayout
      title="Акции"
      breadCrumbs={[{ path: '/offers/leads', name: 'Акции' }]}
      headerMenu={settingId ? referralHeaderMenu : headerMenu}
    >
      <div className="flex flex-col shadow-smena rounded bg-smena_white">
        <LeadsFilters table={table} />
        <NewTable<LeadFragment> table={table} length={leads.length} loading={loading} headerMenu={true} />
        {leadsPagination && <CustomPagination total={leadsPagination.pages} currentPage={page} />}
      </div>

      <Modal
        title="Отправить в оплату?"
        open={showPayModal}
        okText="Отправить"
        onCancel={() => setShowPayModal(false)}
        onOk={handlePay}
      >
        Это действие необратимое. Проверьте, что реферал выполнил все условия. Отправить в оплату?
      </Modal>
    </MainLayout>
  );
};

export default LeadsPage;
