import { useMemo } from 'react';
import dayjs from 'dayjs';
import { ShiftStage } from 'generated/graphql';
import { setEndDateSchedule } from 'utils/helpers/setEndDateSchedule';
import { shiftTitle } from 'utils/helpers/get/getShiftTitle';
import { UserShiftsFragment } from 'pages/user/api/fragments/generated/UserShifts.fragment';

const useTimekeepingShifts = (userShifts: UserShiftsFragment[]) =>
  useMemo(() => {
    return userShifts
      .map(shift => ({
        title: shiftTitle(shift.marks),
        start: dayjs(shift.dateFrom).toDate(),
        end: setEndDateSchedule(dayjs(shift.dateFrom).toDate(), dayjs(shift.dateEnd).toDate()),
        resource: {
          id: shift.id,
          authorId: shift.authorId || '',
          userId: shift.userId || '',
          dateFrom: shift.dateFrom,
          dateEnd: shift.dateEnd || '',
          facility: shift.facility,
          positionId: shift.positionId || '',
          marks: shift.marks,
          lunchDuration: shift.lunchDuration,
          comment: shift.comment,
          stage: shift.stage,
          rejectedById: shift.rejectedById || '',
          isProbation: shift.isProbation,
        },
      }))
      .filter(
        shift =>
          dayjs().endOf('day').diff(dayjs(shift.resource.dateFrom)) > 0 &&
          ![ShiftStage.Refused, ShiftStage.Ineligible, ShiftStage.New].includes(shift.resource.stage),
      );
  }, [userShifts]);

export default useTimekeepingShifts;
