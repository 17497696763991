import * as Types from '../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { SettingFragmentDoc } from '../../fragments/generated/Setting.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetSettingMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  value: Types.Scalars['String']['input'];
}>;


export type SetSettingMutation = { __typename?: 'Mutation', setSetting: { __typename?: 'Setting', id: string, name: string, value: string } };


export const SetSettingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetSetting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"value"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setSetting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"value"},"value":{"kind":"Variable","name":{"kind":"Name","value":"value"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Setting"}}]}}]}},...SettingFragmentDoc.definitions]} as unknown as DocumentNode;
export type SetSettingMutationFn = Apollo.MutationFunction<SetSettingMutation, SetSettingMutationVariables>;

/**
 * __useSetSettingMutation__
 *
 * To run a mutation, you first call `useSetSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSettingMutation, { data, loading, error }] = useSetSettingMutation({
 *   variables: {
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetSettingMutation(baseOptions?: Apollo.MutationHookOptions<SetSettingMutation, SetSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSettingMutation, SetSettingMutationVariables>(SetSettingDocument, options);
      }
export type SetSettingMutationHookResult = ReturnType<typeof useSetSettingMutation>;
export type SetSettingMutationResult = Apollo.MutationResult<SetSettingMutation>;
export type SetSettingMutationOptions = Apollo.BaseMutationOptions<SetSettingMutation, SetSettingMutationVariables>;