import React from 'react';

import { ErrorNotification } from '../errorNotification';
import { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import TagRender from './Tag';
import ChevronDown from 'assets/icons/chevron-down';
import Select from 'antd/es/select';
import clsx from 'clsx';

type FormInput = {
  divClassName: string;
  popupClassName?: string;
  label: string;
  onChange: (
    value: string[],
    option: BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[],
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  disabledOption?: string;
  required?: boolean;
  placeholder?: string;
  selectClassName?: string;
  checkSuperFac?: any[];
  value: string[];
  sort?: boolean;
  options?: any[];
  name: string;
  autoComplete?: string;
  error?: any;
  disabled?: boolean;
  showSearch?: boolean;
  setFieldValue?: (name: string, array: string[]) => void;
  labelInValue?: boolean;
};

const MultiSelect = React.memo(
  ({
    divClassName,
    selectClassName,
    label,
    onChange,
    placeholder,
    value,
    options,
    name,
    error,
    onBlur,
    labelInValue = false,
    disabled,
    popupClassName,
    showSearch = true,
  }: FormInput) => {
    return (
      <div className={clsx(divClassName, 'flex flex-col gap-y-0.5')}>
        <label htmlFor={name} className="label-primary">
          {label}
        </label>
        <Select
          labelInValue={labelInValue}
          showSearch={showSearch}
          filterOption={(input, option: any) => {
            if (showSearch) {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }
            return true;
          }}
          mode="multiple"
          suffixIcon={<ChevronDown />}
          disabled={disabled}
          allowClear
          tagRender={TagRender}
          popupClassName={popupClassName}
          placeholder={placeholder}
          maxTagCount={'responsive'}
          className={selectClassName}
          removeIcon={null}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          options={options}
        />
        {error && <ErrorNotification text={error} />}
      </div>
    );
  },
);

export default MultiSelect;
