import React, { useEffect, useState } from 'react';

import MainLayout from 'components/layout/main';
import SimpleSelect from 'ui/select';
import { Form, Formik } from 'formik';
import { usePositions } from 'utils/api/usePositions';
import { hourlyUnit, units } from 'utils/helpers/lists/units';
import { payoutTypes } from 'utils/helpers/lists/payoutTypes';
import SimpleInput from 'ui/input';
import SimpleCheckbox from 'ui/checkbox';
import { useFacilityCasingForm } from './utils/form';
import { useParams } from 'react-router-dom';
import { positionName } from '../../utils/helpers/filters/filtersName';
import DatePickerIcon from 'assets/icons/DatePickerIcon';
import useFacilityBreadcrumbs from '../../utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import { GetCasingDocument, useGetCasingLazyQuery } from './api/query/generated/GetCasing';
import PhotoList from 'components/PhotoList';
import { useUploadPhotoInCasingGalleryMutation } from './api/mutations/generated/UploadPhotoInCasingGallery';
import Modal from 'ui/modal';
import { useRemovePhotoInCasingGalleryMutation } from './api/mutations/generated/RemovePhotoInCasingGallery';
import { successToast } from 'utils/helpers/notify';
import { Popover } from 'antd';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import { useGetCurrentSelfEmployedTaxQuery } from 'pages/facilityGroupCasing/api/query/generated/GetCurrentSelfEmployedTax';
import Tax from './components/Tax';
import dayjs from 'dayjs';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import HistoryRateChanges from './components/HistoryRateChanges';
import CancelChangeRate from './components/CancelChangeRate';
import clsx from 'clsx';
import { usePlanCasingRateMutation } from './api/mutations/generated/PlanCasingRate';

const FacilityCasing = () => {
  const param = useParams();
  const id = param.casingId;

  const [photoIds, setPhotoIds] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [deletePhotoInCasing] = useRemovePhotoInCasingGalleryMutation();

  const { data: currentSelfTax } = useGetCurrentSelfEmployedTaxQuery();
  const [planCasingRateMutation] = usePlanCasingRateMutation({ refetchQueries: [GetCasingDocument] });
  const [getCasing, { data, refetch: refetchCasing }] = useGetCasingLazyQuery({ fetchPolicy: 'no-cache' });
  const [uploadPhotoInCasingGallery] = useUploadPhotoInCasingGalleryMutation({ refetchQueries: [GetCasingDocument] });
  const casing = data?.getCasing;
  const { positions } = usePositions();
  const breadcrumb = casing
    ? { name: positionName(positions, casing.positionId), path: 'edit' }
    : { name: 'Новая профессия', path: 'create' };

  const breadcrumbs = [...useFacilityBreadcrumbs(), breadcrumb];
  const title = casing ? `Редактировать профессию ${positionName(positions, casing?.positionId)}` : 'Новая профессия';

  const [planCasingRate, setPlanCasingRate] = useState<{
    rate?: number;
    startDateTime: DayjsOrNull;
    isPlanned: boolean;
    isProbation: boolean;
  }>({
    rate: casing?.plannedRate?.rate,
    startDateTime: casing?.plannedRate?.startDatetime,
    isProbation: false,
    isPlanned: casing?.plannedRate ? true : false,
  });

  const [planCasingRateProbation, setPlanCasingRateProbation] = useState<{
    rate?: number;
    startDateTime: DayjsOrNull;
    isPlanned: boolean;
    isProbation: boolean;
  }>({
    rate: casing?.plannedProbationRate?.rate,
    startDateTime: casing?.plannedProbationRate?.startDatetime,
    isProbation: true,
    isPlanned: casing?.plannedProbationRate ? true : false,
  });

  const { initialValues, validate, onSubmit } = useFacilityCasingForm();

  useEffect(() => {
    if (id) {
      getCasing({ variables: { id } }).then(data => {
        const plannedRate = data.data?.getCasing.plannedRate;
        const plannedProbationRate = data.data?.getCasing.plannedProbationRate;

        if (plannedRate?.rate && plannedRate?.startDatetime) {
          setPlanCasingRate({
            rate: plannedRate?.rate,
            startDateTime: plannedRate?.startDatetime,
            isPlanned: true,
            isProbation: false,
          });
        }

        if (plannedProbationRate?.rate && plannedProbationRate?.startDatetime) {
          setPlanCasingRateProbation({
            rate: plannedProbationRate?.rate,
            startDateTime: plannedProbationRate?.startDatetime,
            isPlanned: true,
            isProbation: true,
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainLayout title={title} breadCrumbs={breadcrumbs}>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
        {({ values, errors, setFieldValue, touched, handleChange }) => {
          return (
            <Form className="grid grid-cols-10 gap-5">
              <div className={'blockTemplate col-span-5'}>
                <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">
                  Основная информация
                </span>
                <div className="flex gap-4">
                  <SimpleSelect
                    divClassName="max-w-xs flex-1"
                    label="Профессия"
                    onChange={value => {
                      setFieldValue('positionId', value);
                    }}
                    value={values.positionId}
                    placeholder="Не выбрана"
                    options={positions}
                    validation
                    error={touched.positionId && errors.positionId ? errors.positionId : ''}
                  />

                  <SimpleCheckbox
                    divClassName="flex items-end"
                    label="НПД"
                    onChange={handleChange}
                    checked={values.isSelfEmployed}
                    name="isSelfEmployed"
                  />
                </div>

                <hr />

                <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">Вознаграждение</span>
                <div className="flex gap-4 flex-col flex-wrap">
                  <div className="flex gap-4">
                    <SimpleSelect
                      divClassName="max-w-xs flex-1"
                      label="Тип вознаграждения"
                      placeholder="Не выбран"
                      onChange={value => {
                        setFieldValue('payout', value);
                        if (value === 'PIECEWORK') {
                          setFieldValue('unit', 'PIECE');
                        } else {
                          setFieldValue('unit', 'HOUR');
                        }
                      }}
                      value={values.payout}
                      options={payoutTypes}
                      validation
                      error={touched.payout && errors.payout ? errors.payout : ''}
                    />
                    <SimpleSelect
                      divClassName="max-w-xs flex-1"
                      placeholder="Не выбраны"
                      label="Ед. измерения"
                      onChange={value => {
                        setFieldValue('unit', value);
                      }}
                      value={values.unit}
                      options={values.payout === 'HOURLY' ? hourlyUnit : units}
                      validation
                      error={touched.unit && errors.unit ? errors.unit : ''}
                    />
                  </div>

                  <div className="flex gap-4 items-end">
                    <SimpleInput
                      divClassName="max-w-xs flex-1"
                      label="Ставка, ₽"
                      onChange={handleChange}
                      placeholder="Введите сумму ставки"
                      float
                      value={values.hourRate}
                      type="text"
                      name="hourRate"
                      error={touched.hourRate && errors.hourRate ? errors.hourRate : ''}
                    />

                    <Tax
                      currentSelfTax={currentSelfTax?.getCurrentSelfEmployedTax}
                      x={values.hourRate}
                      withNpd={values.isSelfEmployed}
                    />
                  </div>

                  {id && <HistoryRateChanges history={casing?.rateHistory} />}

                  {id && !planCasingRate.isPlanned && (
                    <>
                      <Popover
                        placement="bottomLeft"
                        open={planCasingRate.isPlanned ? false : undefined}
                        title={<div className="H3 mb-5">Запланировать изменение ставки</div>}
                        content={
                          <div className="flex flex-col gap-4">
                            <DatePickerInput
                              label="Дата"
                              value={planCasingRate.startDateTime}
                              onChange={date =>
                                setPlanCasingRate(prevState => ({
                                  ...prevState,
                                  startDateTime: dayjs(date).startOf('day'),
                                }))
                              }
                            />

                            <SimpleInput
                              label="Ставка, ₽"
                              onChange={e =>
                                setPlanCasingRate(prevState => ({ ...prevState, rate: Number(e.target.value) }))
                              }
                              float
                              placeholder="Введите сумму ставки"
                              value={planCasingRate.rate}
                              type="text"
                              name="planCasingRate"
                            />

                            <Tax
                              currentSelfTax={currentSelfTax?.getCurrentSelfEmployedTax}
                              x={planCasingRate.rate}
                              withNpd={values.isSelfEmployed}
                            />

                            <button
                              type="button"
                              disabled={!planCasingRate.rate || !planCasingRate.startDateTime}
                              onClick={() => {
                                setPlanCasingRate(prevState => ({ ...prevState, isPlanned: true }));
                                planCasingRate.rate &&
                                  planCasingRate.startDateTime &&
                                  !casing?.plannedRate?.rate &&
                                  !casing?.plannedRate?.startDatetime &&
                                  planCasingRateMutation({
                                    variables: {
                                      input: {
                                        casingId: id || '',
                                        isProbation: false,
                                        rate: planCasingRate.rate,
                                        startDatetime: planCasingRate.startDateTime.format('YYYY-MM-DDT00:00:00'),
                                      },
                                    },
                                  });
                              }}
                              className="btn-primary self-end mt-2"
                            >
                              Запланировать
                            </button>
                          </div>
                        }
                        trigger="click"
                      >
                        <div className="Subtitle2 text-primary cursor-pointer flex items-center self-start gap-2">
                          <DatePickerIcon className="text-primary" />
                          Запланировать изменение ставки
                        </div>
                      </Popover>
                    </>
                  )}
                </div>

                {planCasingRate.isPlanned && (
                  <div className="Body2 text-smena_text-secondary">
                    С <span className="text-black">{dayjs(planCasingRate.startDateTime).format('DD.MM.YYYY')}</span>{' '}
                    ставка изменится и составит <span className="text-black">{planCasingRate.rate}</span> ₽
                  </div>
                )}
                {planCasingRate.isPlanned && (
                  <CancelChangeRate
                    isRateHave={casing?.plannedRate?.rate && casing.plannedRate.startDatetime}
                    casingRateId={casing?.plannedRate?.id || ''}
                    handleCancel={() => {
                      setPlanCasingRate({ isPlanned: false, rate: 0, isProbation: true, startDateTime: null });
                    }}
                  />
                )}

                <hr />

                <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">
                  Пробный период (опционально)
                </span>

                <SimpleInput
                  divClassName="max-w-xs flex-1"
                  label="Кол-во дней"
                  onChange={handleChange}
                  placeholder="Введите кол-во дней"
                  float
                  value={values.probationPeriod}
                  type="text"
                  name="probationPeriod"
                  error={touched.probationPeriod && errors.probationPeriod ? errors.probationPeriod : ''}
                />
                <div className="flex gap-4 items-end">
                  <SimpleInput
                    divClassName="max-w-xs flex-1"
                    label="Ставка, ₽"
                    onChange={handleChange}
                    placeholder="Введите сумму ставки"
                    float
                    value={values.probationRate}
                    type="text"
                    name="probationRate"
                    error={touched.probationRate && errors.probationRate ? errors.probationRate : ''}
                  />

                  <Tax
                    currentSelfTax={currentSelfTax?.getCurrentSelfEmployedTax}
                    x={values.probationRate}
                    withNpd={values.isSelfEmployed}
                  />
                </div>

                {id && (
                  <HistoryRateChanges
                    history={casing?.probationRateHistory}
                    disabled={!casing?.probationPeriod && !casing?.probationRate}
                  />
                )}

                {id && !planCasingRateProbation.isPlanned && (
                  <>
                    <Popover
                      open={
                        !casing?.probationPeriod && !casing?.probationRate
                          ? false
                          : planCasingRateProbation.isPlanned
                            ? false
                            : undefined
                      }
                      placement="bottomLeft"
                      title={<div className="H3 mb-5">Запланировать изменение ставки</div>}
                      content={
                        <div className="flex flex-col gap-4">
                          <DatePickerInput
                            label="Дата"
                            value={planCasingRateProbation.startDateTime}
                            onChange={date =>
                              setPlanCasingRateProbation(prevState => ({
                                ...prevState,
                                startDateTime: dayjs(date).startOf('day'),
                              }))
                            }
                          />

                          <SimpleInput
                            label="Ставка, ₽"
                            onChange={e =>
                              setPlanCasingRateProbation(prevState => ({ ...prevState, rate: Number(e.target.value) }))
                            }
                            float
                            placeholder="Введите сумму ставки"
                            value={planCasingRateProbation.rate}
                            type="text"
                            name="hourRate"
                            error={touched.hourRate && errors.hourRate ? errors.hourRate : ''}
                          />

                          <Tax
                            currentSelfTax={currentSelfTax?.getCurrentSelfEmployedTax}
                            x={planCasingRateProbation.rate}
                            withNpd={values.isSelfEmployed}
                          />

                          <button
                            type="button"
                            disabled={!planCasingRateProbation.rate || !planCasingRateProbation.startDateTime}
                            onClick={() => {
                              setPlanCasingRateProbation(prevState => ({ ...prevState, isPlanned: true }));
                              planCasingRateProbation.rate &&
                                planCasingRateProbation.startDateTime &&
                                !casing?.plannedProbationRate?.rate &&
                                !casing?.plannedProbationRate?.startDatetime &&
                                planCasingRateMutation({
                                  variables: {
                                    input: {
                                      casingId: id || '',
                                      isProbation: true,
                                      rate: planCasingRateProbation.rate,
                                      startDatetime:
                                        planCasingRateProbation.startDateTime.format('YYYY-MM-DDT00:00:00'),
                                    },
                                  },
                                });
                            }}
                            className="btn-primary self-end mt-2"
                          >
                            Запланировать
                          </button>
                        </div>
                      }
                      trigger="click"
                    >
                      <div
                        className={clsx('Subtitle2 text-primary flex items-center self-start gap-2', {
                          ['text-smena_text-disabled cursor-default']:
                            !casing?.probationPeriod && !casing?.probationRate,
                          ['cursor-pointer']: casing?.probationPeriod && casing?.probationRate,
                        })}
                      >
                        <DatePickerIcon
                          className={clsx('text-primary', {
                            ['text-smena_text-disabled']: !casing?.probationPeriod && !casing?.probationRate,
                          })}
                        />
                        Запланировать изменение ставки
                      </div>
                    </Popover>
                  </>
                )}

                {planCasingRateProbation.isPlanned && (
                  <div className="Body2 text-smena_text-secondary">
                    С{' '}
                    <span className="text-black">
                      {dayjs(planCasingRateProbation.startDateTime).format('DD.MM.YYYY')}
                    </span>{' '}
                    ставка изменится и составит <span className="text-black">{planCasingRateProbation.rate}</span> ₽
                  </div>
                )}
                {planCasingRateProbation.isPlanned && (
                  <CancelChangeRate
                    isRateHave={casing?.plannedProbationRate?.rate && casing.plannedProbationRate.startDatetime}
                    casingRateId={casing?.plannedProbationRate?.id || ''}
                    handleCancel={() => {
                      setPlanCasingRateProbation({ isPlanned: false, rate: 0, isProbation: true, startDateTime: null });
                    }}
                  />
                )}

                {id && (
                  <>
                    <hr />
                    <PhotoList
                      casingGallery={casing?.casingGallery || []}
                      facilityGallery={[]}
                      onDownload={image => {
                        uploadPhotoInCasingGallery({ variables: { casingId: id || '', image } });
                      }}
                      isDownload={!casing?.casingGallery?.length || casing.casingGallery.length < 5}
                      handleDelete={photoId => {
                        setPhotoIds(prevState => {
                          if (prevState.includes(photoId)) {
                            return prevState.filter(id => id !== photoId);
                          }
                          return [...prevState, photoId];
                        });
                      }}
                      isDelete
                      photoIds={photoIds}
                    />

                    {photoIds.length > 0 && (
                      <button
                        type="button"
                        className="btn-reject_secondary self-end"
                        onClick={() => setModalOpen(true)}
                      >
                        Удалить {photoIds.length} фото
                      </button>
                    )}
                  </>
                )}

                <hr />

                <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">Дополнительно</span>
                <SimpleCheckbox
                  divClassName="flex items-center"
                  label="Обучение"
                  onChange={handleChange}
                  checked={values.hasEducation}
                  name="hasEducation"
                />
                <SimpleCheckbox
                  divClassName="flex items-center"
                  label="Медицинская книжка"
                  onChange={handleChange}
                  checked={values.isMedBookRequired}
                  name="isMedBookRequired"
                />

                <hr />

                <div className={'col-span-8 flex justify-end'}>
                  <button type="submit" className={'btn-primary'}>
                    {id ? 'Сохранить' : 'Создать'}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Modal
        type="delete"
        title="Удалить фото"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() =>
          deletePhotoInCasing({ variables: { photoIds } }).then(() => {
            setModalOpen(false);
            setPhotoIds([]);
            successToast('Фото успешно удалены');
            refetchCasing();
          })
        }
      >
        Вы уверены, что хотите удалить фото?
      </Modal>
    </MainLayout>
  );
};

export default FacilityCasing;
