import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PromotionEntityFragmentDoc } from '../../fragments/generated/PromotionEntity.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNewPromotionMutationVariables = Types.Exact<{
  input: Types.CreateNewPromotionInput;
}>;


export type CreateNewPromotionMutation = { __typename?: 'Mutation', createNewPromotion: { __typename?: 'PromotionEntity', id: string, badShiftCount: number, dateTimeEnd?: any | null, dateTimeStart: any, name: string, repeatCondition: Types.PromotionRepeatConditionEnum, requirementBonus: Types.PromotionRequirementBonusEnum, requirementShiftCount: number, rewardSum: number, rewardType: Types.PromotionRewardTypeEnum, status: Types.PromotionStatusEnum, childPromotions?: Array<{ __typename?: 'PromotionEntity', facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null } };


export const CreateNewPromotionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateNewPromotion"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateNewPromotionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createNewPromotion"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PromotionEntity"}}]}}]}},...PromotionEntityFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateNewPromotionMutationFn = Apollo.MutationFunction<CreateNewPromotionMutation, CreateNewPromotionMutationVariables>;

/**
 * __useCreateNewPromotionMutation__
 *
 * To run a mutation, you first call `useCreateNewPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewPromotionMutation, { data, loading, error }] = useCreateNewPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewPromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewPromotionMutation, CreateNewPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewPromotionMutation, CreateNewPromotionMutationVariables>(CreateNewPromotionDocument, options);
      }
export type CreateNewPromotionMutationHookResult = ReturnType<typeof useCreateNewPromotionMutation>;
export type CreateNewPromotionMutationResult = Apollo.MutationResult<CreateNewPromotionMutation>;
export type CreateNewPromotionMutationOptions = Apollo.BaseMutationOptions<CreateNewPromotionMutation, CreateNewPromotionMutationVariables>;