import { YMapsApi } from 'react-yandex-maps';
import { morph } from 'utils/helpers/morph';
import { FacilityVacancies, useVacanciesMap } from '../store';

export const createPinTemplateFactory = (ymaps: YMapsApi) => {
  return ({
    img,
    isHover,
    isActive,
    vacanciesLength,
    name,
    pin,
  }: {
    img: string;
    isHover: boolean;
    isActive: boolean;
    vacanciesLength: number;
    name: string;
    pin: FacilityVacancies;
  }) => {
    const setActiveFacilityVacancy = useVacanciesMap(state => state.setActiveFacilityVacancy);
    const changeStatus = useVacanciesMap(state => state.changeFacilityVacancyStatus);
    const mouseEnter = () => {
      changeStatus(pin.id);
    };
    const mouseLeave = () => {
      if (isHover) {
        changeStatus(pin.id);
      }
    };
    const onClick = () => {
      setActiveFacilityVacancy(pin.id);
    };
    const rightSide = name.length > 9 ? (name.length > 18 ? 185 : (name.length - 9) * 10 + 95) : 95;
    const layout = ymaps.templateLayoutFactory.createClass(
      `<div class='facility-pin'>
        <div class='facility-pin__placemark'>
          <div class='facility-pin__image' style='background-image: url("${img}")'></div>  
        </div>
        <div class='facility-pin__description text-smena_text
         ${
           isActive ? 'bg-primary' : isHover ? 'bg-smena_bb-background bg-opacity-90' : 'bg-smena_white bg-opacity-90'
         }'>
           <span class='label-primary ${isActive ? 'text-smena_white' : ''}'>${name}</span>
           <span class='font-inter text-[12px] leading-[14px] tracking-[0.4px] ${
             isActive ? 'text-smena_white' : vacanciesLength ? 'text-primary' : 'text-smena_text-helper'
           }'>
              ${vacanciesLength} ${morph(vacanciesLength, ['вакансия', 'вакансии', 'вакансий'])}
            </span>
        </div>
      </div>`,
      {
        onClick: function () {
          this.getData().geometry._map.setCenter([Number(pin.latitude), Number(pin.longitude)]);
          this.getData().geometry._map.setZoom(15, { checkZoomRange: true });
        },
        build: function () {
          layout.superclass.build.call(this);
          this.getData().options.set('shape', {
            type: 'Rectangle',
            coordinates: [
              [-20, -45],
              [rightSide, 0],
            ],
          });
          this.getData().geoObject.events.add('mouseenter', mouseEnter, this);
          this.getData().geoObject.events.add('mouseleave', mouseLeave, this);
          this.getData().geoObject.events.add('click', this.onClick, this);
          this.getData().geoObject.events.add('click', onClick, this);
        },
        clear: function () {
          this.getData().geoObject.events.remove('mouseenter', mouseEnter, this);
          this.getData().geoObject.events.remove('mouseleave', mouseLeave, this);
          this.getData().geoObject.events.remove('click', this.onClick, this);
          this.getData().geoObject.events.remove('click', onClick, this);
          layout.superclass.clear.call(this);
        },
      },
    );
    return layout;
  };
};
