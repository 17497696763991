import type { FormikHandlers, FormikState } from 'formik';
import SimpleCheckbox from 'ui/checkbox';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import TimePickerInput from 'ui/pickers/TimePickerInput';

type Props = {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (name: string, value: any) => void;
  touched: FormikState<any>['touched'];
  errors: FormikState<any>['errors'];
  isMain: boolean;
};

const Dates = (props: Props) => {
  const { values, setFieldValue, handleChange, touched, errors, isMain } = props;

  return (
    <>
      <span className="Subtitle2 text-smena_text-secondary">Сроки проведения</span>

      <div className="flex gap-6">
        <div className="w-full">
          <DatePickerInput
            divClassName="w-full mb-5"
            label="Дата начала"
            popupClassName="fixed"
            value={values.startDate}
            onChange={startDate => {
              setFieldValue('startDate', startDate);
            }}
            error={touched.startDate && errors.startDate ? errors.startDate : ''}
          />

          {!isMain && (
            <DatePickerInput
              divClassName="w-full"
              label="Дата завершения"
              popupClassName="fixed"
              disabled={values.indefinitely}
              value={values.endDate}
              onChange={endDate => {
                setFieldValue('endDate', endDate);
              }}
              error={touched.endDate && errors.endDate ? errors.endDate : ''}
            />
          )}
        </div>

        <div className="w-full">
          <TimePickerInput
            label="Время начала"
            className="w-full mb-5"
            popupClassName="fixed"
            value={values.startTime}
            onChange={startTime => {
              setFieldValue('startTime', startTime);
            }}
            error={touched.startTime && errors.startTime ? errors.startTime : ''}
          />

          {!isMain && (
            <TimePickerInput
              label="Время завершения"
              className="w-full"
              popupClassName="fixed"
              disabled={values.indefinitely}
              value={values.endTime}
              onChange={endTime => {
                setFieldValue('endTime', endTime);
              }}
              error={touched.endTime && errors.endTime ? errors.endTime : ''}
            />
          )}
        </div>
      </div>
      {!isMain && (
        <SimpleCheckbox label="Бессрочно" onChange={handleChange} checked={values.indefinitely} name="indefinitely" />
      )}
    </>
  );
};

export default Dates;
