import { FacilityBonus } from './form';
import { PromotionEntityFragment } from 'pages/facilityGroup/api/fragments/generated/PromotionEntity.fragment';
import { useResetToDefaultPromotionSettingsMutation } from 'pages/facility/api/mutations/generated/ResetToDefaultPromotionSettings';
import { errorToast, successToast } from '../../../../utils/helpers/notify';
import { errorHandler } from '../../../../utils/helpers/errorHandler';
import dayjs from 'dayjs';
import { usePromotionStore } from '../../../facilityGroup/useBonusProgramStore';
import Modal from 'ui/modal';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  promotion: FacilityBonus | PromotionEntityFragment;
};

const DefaultPromotionPopup = ({ show, setShow, promotion }: Props) => {
  const [resetToDefaultPromotionSettings] = useResetToDefaultPromotionSettingsMutation();
  const changeBonuses = usePromotionStore(state => state.changePromotions);
  const showHandler = () => {
    setShow(value => !value);
  };
  const resetHandler = () => {
    resetToDefaultPromotionSettings({ variables: { input: { promotionId: promotion.id } } })
      .then(response => {
        const res = response.data?.resetToDefaultPromotionSettings;
        if (!res) return;
        changeBonuses(promotion.id, {
          ...res,
          dateTo: dayjs(res.dateTimeStart),
          timeTo: dayjs(res.dateTimeStart),
          dateFrom: dayjs(res.dateTimeEnd),
          timeFrom: dayjs(res.dateTimeEnd),
          endless: false,
        });
        successToast('Акция сброшена на параметры по умолчанию');
      })
      .catch(err => {
        errorToast(errorHandler(err));
      })
      .finally(showHandler);
  };
  return (
    <Modal
      type="delete"
      open={show}
      title="Параметры по умолчанию?"
      okText="По умолчанию"
      onCancel={showHandler}
      onOk={resetHandler}
    >
      Вы уверены, что хотите вернуть параметры бонус. программы по умолчанию?
    </Modal>
  );
};

export default DefaultPromotionPopup;
