import * as Types from '../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FileFragmentDoc } from '../../fragments/generated/File.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadCropImageMutationVariables = Types.Exact<{
  fileId: Types.Scalars['String']['input'];
  cropData: Types.CropDataInput;
}>;


export type UploadCropImageMutation = { __typename?: 'Mutation', uploadCropImage: { __typename?: 'File', id: string, mimetype: string, originalName?: string | null, path: string, size: number } };


export const UploadCropImageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadCropImage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cropData"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CropDataInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadCropImage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"fileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fileId"}}},{"kind":"Argument","name":{"kind":"Name","value":"cropData"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cropData"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"File"}}]}}]}},...FileFragmentDoc.definitions]} as unknown as DocumentNode;
export type UploadCropImageMutationFn = Apollo.MutationFunction<UploadCropImageMutation, UploadCropImageMutationVariables>;

/**
 * __useUploadCropImageMutation__
 *
 * To run a mutation, you first call `useUploadCropImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCropImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCropImageMutation, { data, loading, error }] = useUploadCropImageMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      cropData: // value for 'cropData'
 *   },
 * });
 */
export function useUploadCropImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadCropImageMutation, UploadCropImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCropImageMutation, UploadCropImageMutationVariables>(UploadCropImageDocument, options);
      }
export type UploadCropImageMutationHookResult = ReturnType<typeof useUploadCropImageMutation>;
export type UploadCropImageMutationResult = Apollo.MutationResult<UploadCropImageMutation>;
export type UploadCropImageMutationOptions = Apollo.BaseMutationOptions<UploadCropImageMutation, UploadCropImageMutationVariables>;