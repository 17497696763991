import { useCountriesQuery } from 'graphql/queries/generated/Countries';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

export const useCountries = () => {
  const { data, loading, error } = useCountriesQuery();

  const countries = useMemo(() => sortBy(data?.countries, el => el.name), [data?.countries]);

  return { countries, loading, error };
};
