import MainLayout from 'components/layout/main';
import NewTable from 'components/table/newTable';
import CustomPagination from 'ui/pagination';
import { useUserContractsPaginated } from './api';
import { ContractFragment } from './api/fragments/generated/contract.fragment';
import { useUserContractsTable } from 'components/table/columns/contracts';
import UserContractsFilters from './components/userContractsFilter';

const UserContactsPage = () => {
  const { userContractsPaginated, contracts, error, page, loadingUsers } = useUserContractsPaginated();
  const table = useUserContractsTable(contracts);
  return (
    <MainLayout title="Документы" breadCrumbs={[{ path: '/contracts', name: 'Документы' }]} error={error}>
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <UserContractsFilters table={table} />
        <NewTable<ContractFragment> table={table} length={contracts.length} loading={loadingUsers} headerMenu={true} />
        {userContractsPaginated && <CustomPagination total={userContractsPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default UserContactsPage;
