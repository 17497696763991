import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFacilitiesPaginatedQuery } from './api/queries/generated/FacilitiesPaginated';
import { activeHandler } from '../../utils/helpers/isActiveHandler';
import MainLayout from 'components/layout/main';
import FacilitiesFilter from './components/facilitiesFilter';
import CustomPagination from 'ui/pagination';
import { FacilityFieldsSorting, Role, SortingWay } from 'generated/graphql';
import { useFacilitiesTable } from 'components/table/columns/facilities';
import NewTable from 'components/table/newTable';
import { FacilityPaginatedFragment } from './api/fragments/generated/FacilityPaginated.fragment';
import { useFacilitiesClientTable } from 'components/table/columns/clientFacilities';
import { useMe } from 'utils/api/useMe';
import { userRole } from 'pages/main/lib/helpers/userRole';

const FacilitiesPage = () => {
  const me = useMe();
  const isMeAdminOrSupervisor = userRole([Role.Admin, Role.Supervisor], me);
  const [params] = useSearchParams();

  const sizeParam = params.get('size');
  const searchQuery = params.get('search');
  const groupId = params.get('groupId');
  const cityId = params.get('cityId');
  const activeParam = params.get('active');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const size = sizeParam ? +sizeParam : 25;

  const {
    data: dataFacilities,
    loading: loadingFacilities,
    error,
  } = useFacilitiesPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        groupId,
        cityId,
        size,
        isActive: activeHandler(activeParam),
        searchQuery,
        sortBy: sortBy as FacilityFieldsSorting,
        sortWay: sortWay as SortingWay,
      },
    },
  });

  const facilitiesPaginated = dataFacilities?.facilitiesPaginated;
  const facilities = facilitiesPaginated?.facilities || [];
  const table = useFacilitiesTable(facilities);
  const clientTable = useFacilitiesClientTable(facilities);

  return (
    <MainLayout title="Объекты" breadCrumbs={[{ path: '/facilities', name: 'Объекты' }]} error={error}>
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <FacilitiesFilter table={table} />
        {isMeAdminOrSupervisor ? (
          <NewTable<FacilityPaginatedFragment>
            table={table}
            length={facilities.length}
            loading={loadingFacilities}
            headerMenu={true}
          />
        ) : (
          <NewTable<FacilityPaginatedFragment>
            table={clientTable}
            length={facilities.length}
            loading={loadingFacilities}
            headerMenu={true}
          />
        )}

        {facilitiesPaginated && <CustomPagination currentPage={page} total={facilitiesPaginated.pages} />}
      </div>
    </MainLayout>
  );
};

export default FacilitiesPage;
