import React from 'react';

const Legend = () => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 5.984a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2h-10a1 1 0 0 1-1-1ZM9.375 17.984a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2h-10a1 1 0 0 1-1-1ZM9.375 11.984a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2h-10a1 1 0 0 1-1-1Z"
        fill="#397DFF"
      />
      <path
        d="M4.132 4.234a.5.5 0 0 1 .866 0l1.732 3a.5.5 0 0 1-.433.75H2.833a.5.5 0 0 1-.433-.75l1.732-3Z"
        fill="#397DFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.066 10.016a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3Z"
        fill="#397DFF"
      />
      <circle cx={4.566} cy={17.984} r={2.25} fill="#397DFF" />
    </svg>
  );
};

export default Legend;
