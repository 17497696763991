import { PromotionRepeatConditionEnum, PromotionRewardTypeEnum } from 'generated/graphql';

export const rewardTypeOptions = [
  { id: PromotionRewardTypeEnum.BonusForGoodShifts, name: 'Надбавка к хорошим сменам' },
  { id: PromotionRewardTypeEnum.SinglePaymentForTheNextGoodShift, name: 'Единоразовая выплата к след. хор. смене' },
  { id: PromotionRewardTypeEnum.SurchargeToTheRate, name: 'Надбавка к ставке' },
];
export const repeatConditionOptions = [
  { id: PromotionRepeatConditionEnum.OneTime, name: 'Без сброса' },
  { id: PromotionRepeatConditionEnum.OnceAMonth, name: 'Раз в месяц' },
  { id: PromotionRepeatConditionEnum.OnceEvery_2Month, name: 'Раз в 2 месяца' },
  { id: PromotionRepeatConditionEnum.OnceEvery_3Month, name: 'Раз в 3 месяца' },
  { id: PromotionRepeatConditionEnum.OnceEvery_6Month, name: 'Раз в полгода' },
  { id: PromotionRepeatConditionEnum.OnceEvery_12Month, name: 'Раз в год' },
];
