import { IUser } from 'interfaces/IUser';
import { getUserFIO } from 'utils/helpers/userFullName';

export const userNameFacilitySchedule = (user?: IUser | null) => {
  if (!user) return '';
  if (user.lastname.length > 6) {
    return `${user.lastname.slice(0, 5)}... ${user.firstname.charAt(0)}. ${
      user.patronymic ? user.patronymic.charAt(0) + '.' : ''
    }`;
  }
  return getUserFIO(user);
};
