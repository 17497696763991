import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { LegalEntityFragmentDoc } from '../../../../facilityGroup/api/fragments/generated/LegalEntity.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLegalEntitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLegalEntitiesQuery = { __typename?: 'Query', getLegalEntities: Array<{ __typename?: 'LegalEntity', id: string, name: string }> };


export const GetLegalEntitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLegalEntities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLegalEntities"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LegalEntity"}}]}}]}},...LegalEntityFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetLegalEntitiesQuery__
 *
 * To run a query within a React component, call `useGetLegalEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLegalEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetLegalEntitiesQuery, GetLegalEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLegalEntitiesQuery, GetLegalEntitiesQueryVariables>(GetLegalEntitiesDocument, options);
      }
export function useGetLegalEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLegalEntitiesQuery, GetLegalEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLegalEntitiesQuery, GetLegalEntitiesQueryVariables>(GetLegalEntitiesDocument, options);
        }
export function useGetLegalEntitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLegalEntitiesQuery, GetLegalEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLegalEntitiesQuery, GetLegalEntitiesQueryVariables>(GetLegalEntitiesDocument, options);
        }
export type GetLegalEntitiesQueryHookResult = ReturnType<typeof useGetLegalEntitiesQuery>;
export type GetLegalEntitiesLazyQueryHookResult = ReturnType<typeof useGetLegalEntitiesLazyQuery>;
export type GetLegalEntitiesSuspenseQueryHookResult = ReturnType<typeof useGetLegalEntitiesSuspenseQuery>;
export type GetLegalEntitiesQueryResult = Apollo.QueryResult<GetLegalEntitiesQuery, GetLegalEntitiesQueryVariables>;