import { ApolloCache } from '@apollo/client';
import { UpdateLeadMutation } from '../mutations/generated/UpdateLead';

interface Props {
  cache: ApolloCache<any>;
  data?: UpdateLeadMutation | null;
  leadId: string;
}

export const updateLeadCache = ({ cache, data, leadId }: Props) => {
  if (leadId && data) {
    cache.modify({
      id: `LeadOutput:${leadId}`,
      fields: {
        isProcessed() {
          return data.updateLead.isProcessed;
        },
        status() {
          return data.updateLead.status;
        },
        date() {
          return data.updateLead.date;
        },
      },
    });
  }
};
