import { ApolloCache } from '@apollo/client';
import { ScheduleFragment } from '../fragments/generated/Schedule.fragment';
import { UserShiftsDocument, UserShiftsQuery, UserShiftsQueryVariables } from '../queries/generated/UserShifts';
import { FacilitiesFragment } from '../../../main/api/fragments/generated/Facilities.fragment';
import dayjs from 'dayjs';
import { GetFacilityFragmentDoc } from '../fragments/generated/GetFacility.fragment';

interface Props {
  cache: ApolloCache<any>;
  userId: string;
  dateFrom?: dayjs.Dayjs;
  dateTo?: dayjs.Dayjs;
  data?: ScheduleFragment;
}
export const writeScheduleShiftsCache = ({ userId, dateFrom, dateTo, cache, data }: Props) => {
  const id = `Facility:${data?.facilityId}`;
  const currentFacility = cache.readFragment<FacilitiesFragment>({
    id,
    fragment: GetFacilityFragmentDoc,
    fragmentName: 'GetFacility',
  });
  const variables = {
    input: {
      userId,
      dateFrom,
      dateTo,
    },
  };
  const cacheList = cache.readQuery<UserShiftsQuery, UserShiftsQueryVariables>({
    query: UserShiftsDocument,
    variables,
  });

  if (cacheList && data?.shifts && currentFacility) {
    cache.writeQuery<UserShiftsQuery, UserShiftsQueryVariables>({
      query: UserShiftsDocument,
      variables,
      data: {
        userShifts: [
          ...cacheList.userShifts,
          ...data.shifts.map(el => ({
            ...el,
            facility: {
              id: data.facilityId,
              facilityGroupId: currentFacility?.facilityGroupId,
              name: currentFacility.name,
              latitude: currentFacility.latitude,
              radius: currentFacility.radius,
              longitude: currentFacility.longitude,
            },
          })),
        ],
      },
    });
  }
};
