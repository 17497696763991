import dayjs from 'dayjs';
import { useMemo } from 'react';

import { Spoiler } from 'ui/spoiler';
import GradeElement from './gradeBlock';
import ReviewElement from './reviewElement';
import { useCurrentUser } from '../../../../utils/api/useUser';

const ReviewBlock = () => {
  const { user } = useCurrentUser();
  const reviewAndGrade = useMemo(() => {
    if (user?.userReview && user.userGrade) {
      return [
        ...user.userReview.map(review => ({
          createdAt: review.createdAt,
          type: 'REVIEW',
          el: review,
        })),
        ...user.userGrade.map(grade => ({
          createdAt: grade.createdAt,
          type: 'GRADE',
          el: grade,
        })),
      ].sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf());
    }
  }, [user?.userGrade, user?.userReview]);

  return (
    <Spoiler
      className="userPageBlock blockTemplate"
      header={
        <div className="flex justify-between">
          <h2 className="blockTitleTemplate">Отзывы</h2>
        </div>
      }
    >
      <div className={'flex flex-col gap-y-6 pb-6 border-b border-smena_gray-5 last:border-b-0'}>
        {reviewAndGrade?.length ? (
          reviewAndGrade.map(el => {
            if (el.type === 'REVIEW') {
              return <ReviewElement key={el.el.id} review={el.el} />;
            }
            return <GradeElement key={el.el.id} grade={el.el} />;
          })
        ) : (
          <span className={'Body2 text-center text-smena_text-secondary'}>У пользователя нет отзывов</span>
        )}
      </div>
    </Spoiler>
  );
};
export default ReviewBlock;
