import { create } from 'zustand';

interface LeadsStoreProps {
  showPayModal: boolean;
  setShowPayModal: (showPayModal: boolean) => void;
  leadId: string | null;
  setLeadId: (leadId: string) => void;
  callbackModal: any;
  setCallbackModal: (callback: any) => void;
}

export const useLeadsStore = create<LeadsStoreProps>(set => ({
  showPayModal: false,
  setShowPayModal: showPayModal => set({ showPayModal }),
  leadId: null,
  setLeadId: leadId => set({ leadId }),
  callbackModal: () => {},
  setCallbackModal: callback => set({ callbackModal: callback }),
}));
