import React from 'react';
import { YMapsApi, ZoomControl } from 'react-yandex-maps';

type Props = {
  ymaps: YMapsApi | null;
};

const ZoomComponentIn: React.FC<Props> = ({ ymaps }) => {
  if (!ymaps) return null;
  const ZoomLayout = ymaps.templateLayoutFactory.createClass(
    `<button class='zoom-in w-[40px] h-[40px] bg-white border-b border-smena_gray-30 rounded-t-md flex items-center justify-center shadow-smena text-smena_text'>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            class='fill-current'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z'
            />
          </svg>
        </button>`,
    {
      onClick: function () {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() + 1, { checkZoomRange: true });
      },
      build: function () {
        ZoomLayout.superclass.build.call(this);
        this.getData().control.events.add('click', this.onClick.bind(this), this);
      },

      clear: function () {
        this.getData().control.events.remove('click', this.onClick.bind(this), this);
        ZoomLayout.superclass.clear.call(this);
      },
    },
  );

  return <ZoomControl options={{ layout: ZoomLayout, position: { right: 16, top: 250 } }} />;
};
export default ZoomComponentIn;
