import { StatisticPeriod } from 'generated/graphql';
import dayjs from 'dayjs';
import { TIME_DATE } from '../constVariables';

export const tickFormatterHandlerFn = (value: any, time: StatisticPeriod) => {
  switch (time) {
    case StatisticPeriod.Day:
      return dayjs(value).format(TIME_DATE);
    case StatisticPeriod.Week:
      return dayjs(value).format('dd');
    case StatisticPeriod.Month:
      return dayjs(value).format('DD.MM.YY');
    case StatisticPeriod.HalfYear:
      return dayjs(value).format('MMMM');
    case StatisticPeriod.Year:
      return dayjs(value).format('MMM');
    default:
      return dayjs(value).format('MMM, YY');
  }
};
