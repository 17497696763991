import { FacilityCasingFragment } from 'pages/facilityCasings/api/fragments/generated/FacilityCasing.fragment';
import { positionName } from 'utils/helpers/filters/filtersName';
import { usePositions } from 'utils/api/usePositions';
import { useUpdateProfession } from './hooks';
import { useFacilityById } from 'utils/api/useFacility';
import Modal from 'ui/modal';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  casing: FacilityCasingFragment;
}

const RemovePopup = ({ show, setShow, casing }: Props) => {
  const { positions } = usePositions();
  const { removeHandler } = useUpdateProfession({ casing, setShow });

  const showHandler = () => {
    setShow(value => !value);
  };
  const casingName = positionName(positions, casing.positionId);
  const { facility } = useFacilityById(casing.facilityId);
  return (
    <Modal
      type="delete"
      title="Удалить профессию?"
      open={show}
      onCancel={showHandler}
      onOk={removeHandler}
    >{`Вы уверены, что хотите редактировать профессию «${casingName}» в объекте «${facility?.name}»?`}</Modal>
  );
};

export default RemovePopup;
