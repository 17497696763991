import { ApolloCache } from '@apollo/client';
import { FacilitiesFragmentDoc } from '../../../main/api/fragments/generated/Facilities.fragment';
import { AddManagerToFacilityMutation } from '../mutations/generated/AddManagerToFacility';

interface Props {
  facilityId: string;
  data?: AddManagerToFacilityMutation | null;
}

export const addManagerToFacilityCache = (cache: ApolloCache<any>, { data, facilityId }: Props) => {
  const facilityRefId = `Facility:${facilityId}`;
  const userRefId = `RatingUser:${data?.addManagerToFacility.id}`;

  cache.modify({
    id: userRefId,
    fields: {
      facilitiesManager(prev = []) {
        const facility = cache.readFragment({
          id: facilityRefId,
          fragment: FacilitiesFragmentDoc,
          fragmentName: 'Facilities',
        });
        return [...prev, facility];
      },
    },
  });

  cache.modify({
    id: facilityRefId,
    fields: {
      clientManagers(prev = []) {
        return [...prev, data?.addManagerToFacility];
      },
    },
  });
};
