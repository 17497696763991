import { useState } from 'react';
import { Dayjs } from 'dayjs';

import { errorHandler } from 'utils/helpers/errorHandler';

import { errorToast } from 'utils/helpers/notify';
import TimesheetLoader from 'pages/timesheets/ui/Loader';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import { useSmsReportMutation } from '../api/mutations/generated/SmsReport';

const SmsReport = () => {
  const [value, setValue] = useState<Dayjs | null>(null);
  const [resLink, setResLink] = useState('');
  const [smsReport, { loading }] = useSmsReportMutation();
  return (
    <div className="xs:col-span-5 col-span-full h-fit p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
      <span className="Subtitle1 text-smena_text-secondary">Отправленные СМС</span>
      <form
        className="flex xs:flex-row flex-col xs:items-end gap-x-5 gap-y-3 flex-wrap"
        onSubmit={e => {
          e.preventDefault();
          smsReport()
            .then(response => {
              if (response.data?.smsReport) {
                const filePath = response.data.smsReport.filePath;
                const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
                setResLink(url);
              }
            })
            .catch(e => {
              errorToast(errorHandler(e));
            });
        }}
      >
        <DatePickerInput
          divClassName="w-[180px]"
          label="Период"
          value={value}
          disabled
          placeholder="За весь период"
          onChange={selectedDate => {
            setValue(selectedDate);
            setResLink('');
          }}
        />
        <div className="">
          <button type="submit" disabled={loading || Boolean(resLink)} className="btn-secondary">
            Сформировать
          </button>
        </div>
      </form>
      <TimesheetLoader loading={loading} resLink={resLink} />
    </div>
  );
};

export default SmsReport;
