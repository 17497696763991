import React from 'react';
import OutsideClick from 'components/outsideComponent';
import clsx from 'clsx';
import Across from 'assets/icons/Across';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  children: React.ReactNode;
  triggerRef?: React.RefObject<HTMLElement>;
}

const TableFilter: React.FC<Props> = React.memo(({ show, setShow, triggerRef, children }) => {
  return (
    <OutsideClick
      show={show}
      setShow={setShow}
      triggerRef={triggerRef}
      className={clsx('filter__popup', show ? 'opacity-100' : 'opacity-0 pointer-events-none')}
    >
      <button
        className="absolute top-[15px] right-[15px]"
        onClick={() => {
          setShow(false);
        }}
      >
        <Across className="text-smena_text" />
      </button>
      <span className="H4 text-smena_text">Фильтры</span>
      {children}
    </OutsideClick>
  );
});

export default TableFilter;
