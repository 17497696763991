import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ReportFragmentDoc } from '../../fragments/generated/Report.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SmsReportMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SmsReportMutation = { __typename?: 'Mutation', smsReport: { __typename?: 'Report', id: string, type: Types.ReportType, filePath: string } };


export const SmsReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SmsReport"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"smsReport"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Report"}}]}}]}},...ReportFragmentDoc.definitions]} as unknown as DocumentNode;
export type SmsReportMutationFn = Apollo.MutationFunction<SmsReportMutation, SmsReportMutationVariables>;

/**
 * __useSmsReportMutation__
 *
 * To run a mutation, you first call `useSmsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smsReportMutation, { data, loading, error }] = useSmsReportMutation({
 *   variables: {
 *   },
 * });
 */
export function useSmsReportMutation(baseOptions?: Apollo.MutationHookOptions<SmsReportMutation, SmsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SmsReportMutation, SmsReportMutationVariables>(SmsReportDocument, options);
      }
export type SmsReportMutationHookResult = ReturnType<typeof useSmsReportMutation>;
export type SmsReportMutationResult = Apollo.MutationResult<SmsReportMutation>;
export type SmsReportMutationOptions = Apollo.BaseMutationOptions<SmsReportMutation, SmsReportMutationVariables>;