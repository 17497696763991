import { useGetNaimixObjectsLazyQuery } from 'pages/facility/api/queries/generated/GetNaimixObjects';
import { useEffect, useMemo } from 'react';

export const useGetNaimixObjects = (legalEntityId?: string) => {
  const [loadData, { data, loading, error }] = useGetNaimixObjectsLazyQuery();

  const namixObjects = useMemo(
    () => data?.getNaimixObjects?.map(el => ({ id: el.naimixObjectId, name: el.naimixObjectName })),
    [data?.getNaimixObjects],
  );

  useEffect(() => {
    if (!legalEntityId) return;
    loadData({
      variables: {
        legalEntityId,
      },
    });
  }, [legalEntityId, loadData]);

  return { namixObjects, loading, error };
};
