import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStatisticQueryVariables = Types.Exact<{
  input: Types.GetStatisticInput;
}>;


export type GetStatisticQuery = { __typename?: 'Query', getStatistic?: Array<{ __typename?: 'ChartOutput', type: Types.ChartType, data: Array<{ __typename?: 'ChartDataOutput', createdAt: any, value: number }> }> | null };


export const GetStatisticDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStatistic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetStatisticInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStatistic"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetStatisticQuery__
 *
 * To run a query within a React component, call `useGetStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStatisticQuery(baseOptions: Apollo.QueryHookOptions<GetStatisticQuery, GetStatisticQueryVariables> & ({ variables: GetStatisticQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatisticQuery, GetStatisticQueryVariables>(GetStatisticDocument, options);
      }
export function useGetStatisticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticQuery, GetStatisticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatisticQuery, GetStatisticQueryVariables>(GetStatisticDocument, options);
        }
export function useGetStatisticSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStatisticQuery, GetStatisticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStatisticQuery, GetStatisticQueryVariables>(GetStatisticDocument, options);
        }
export type GetStatisticQueryHookResult = ReturnType<typeof useGetStatisticQuery>;
export type GetStatisticLazyQueryHookResult = ReturnType<typeof useGetStatisticLazyQuery>;
export type GetStatisticSuspenseQueryHookResult = ReturnType<typeof useGetStatisticSuspenseQuery>;
export type GetStatisticQueryResult = Apollo.QueryResult<GetStatisticQuery, GetStatisticQueryVariables>;