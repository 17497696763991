import React from 'react';
import clsx from 'clsx';
import { TIcon } from '../../interfaces/TIcon';

const TelegramIcon = ({ className }: TIcon) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7071 0.292921C21.9788 0.564559 22.0708 0.967791 21.9439 1.33038L14.9439 21.3304C14.8084 21.7174 14.4504 21.9825 14.0408 21.9992C13.6311 22.0159 13.2527 21.7808 13.0862 21.4062L9.2424 12.7576L0.593867 8.91384C0.219186 8.74731 -0.0158775 8.36894 0.000836285 7.95926C0.0175501 7.54959 0.282654 7.19162 0.669655 7.05617L20.6697 0.0561695C21.0322 -0.0707359 21.4355 0.0212832 21.7071 0.292921ZM11.1928 12.2215L13.8894 18.289L18.6085 4.80571L11.1928 12.2215ZM17.1943 3.3915L3.71108 8.11063L9.77854 10.8073L17.1943 3.3915Z"
      />
    </svg>
  );
};

export default TelegramIcon;
