import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveFacilityPenaltyMutationVariables = Types.Exact<{
  facilityId: Types.Scalars['String']['input'];
}>;


export type RemoveFacilityPenaltyMutation = { __typename?: 'Mutation', removeFacilityPenalty: boolean };


export const RemoveFacilityPenaltyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveFacilityPenalty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeFacilityPenalty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"facilityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}}]}]}}]} as unknown as DocumentNode;
export type RemoveFacilityPenaltyMutationFn = Apollo.MutationFunction<RemoveFacilityPenaltyMutation, RemoveFacilityPenaltyMutationVariables>;

/**
 * __useRemoveFacilityPenaltyMutation__
 *
 * To run a mutation, you first call `useRemoveFacilityPenaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFacilityPenaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFacilityPenaltyMutation, { data, loading, error }] = useRemoveFacilityPenaltyMutation({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *   },
 * });
 */
export function useRemoveFacilityPenaltyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFacilityPenaltyMutation, RemoveFacilityPenaltyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFacilityPenaltyMutation, RemoveFacilityPenaltyMutationVariables>(RemoveFacilityPenaltyDocument, options);
      }
export type RemoveFacilityPenaltyMutationHookResult = ReturnType<typeof useRemoveFacilityPenaltyMutation>;
export type RemoveFacilityPenaltyMutationResult = Apollo.MutationResult<RemoveFacilityPenaltyMutation>;
export type RemoveFacilityPenaltyMutationOptions = Apollo.BaseMutationOptions<RemoveFacilityPenaltyMutation, RemoveFacilityPenaltyMutationVariables>;