import { useFacilityGroupLazyQuery } from 'pages/facilityGroup/api/queries/generated/FacilityGroup';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useSimpleFacilityGroupLazyQuery } from 'pages/facilityGroup/api/queries/generated/SimpleFacilityGroup';

export const useFacilityGroupExtended = () => {
  const router = useParams();
  const id = router.id;
  const [load, { data, loading, error }] = useFacilityGroupLazyQuery();
  useEffect(() => {
    if (id) {
      load({
        variables: {
          id,
        },
      });
    }
  }, [data?.facilityGroup, error, id, load, loading]);

  const facilityGroup = data?.facilityGroup;
  return { facilityGroup, loading, error, id };
};
export const useFacilityGroup = () => {
  const router = useParams();
  const id = router.id;
  const [load, { data, loading, error }] = useSimpleFacilityGroupLazyQuery();
  useEffect(() => {
    if (id) {
      load({
        variables: {
          id,
        },
      });
    }
  }, [data?.facilityGroup, error, id, load, loading]);

  const facilityGroup = data?.facilityGroup;
  return { facilityGroup, loading, error, id };
};
export const useFacilityGroupById = (id?: string | null) => {
  const [load, { data, loading, error }] = useSimpleFacilityGroupLazyQuery();
  useEffect(() => {
    if (id) {
      load({
        variables: {
          id,
        },
      });
    }
  }, [data?.facilityGroup, error, id, load, loading]);

  const facilityGroup = data?.facilityGroup;
  const isAuchan = facilityGroup?.name === 'Ашан';
  const facilityGroupName = facilityGroup?.name;
  return { facilityGroup, facilityGroupName, loading, error, id, isAuchan };
};
