import React from 'react';
import { Clusterer, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import { useStore } from 'store';

interface IOurMap {
  points?: {
    geometry: { type: string; coordinates: number[] };
    properties: { balloonContentHeader: string; name: string; balloonContentBody: string };
    options: { preset: string; iconColor: string };
  }[];
  loading: boolean;
}

export const OurMap = React.memo(({ loading, points }: IOurMap) => {
  const { cluster } = useStore();
  return (
    <Map
      defaultState={{ center: [55.75, 37.57], zoom: 5 }}
      width="100%"
      height="100%"
      modules={['clusterer.addon.balloon', 'geoObject.addon.balloon', 'layout.ImageWithContent']}
    >
      <ZoomControl />
      {cluster ? (
        <Clusterer
          options={{
            preset: 'islands#dotIcon',
            clusterDisableClickZoom: true,
            clusterOpenBalloonOnClick: true,
          }}
        >
          {!loading &&
            points?.map(point => {
              return (
                <Placemark
                  key={point.geometry.coordinates.join('') + point.properties.name}
                  geometry={point.geometry.coordinates}
                  properties={point.properties}
                  options={point.options}
                />
              );
            })}
        </Clusterer>
      ) : (
        !loading &&
        points?.map(point => {
          return (
            <Placemark
              key={point.geometry.coordinates.join('') + point.properties.name}
              geometry={point.geometry.coordinates}
              properties={point.properties}
              options={point.options}
            />
          );
        })
      )}
    </Map>
  );
});
