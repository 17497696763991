import React, { useMemo } from 'react';
import MultiSelect from 'ui/multiSelect';
import { multiSelectOptionsFromArrayOfObjects, multiSelectRolesOptions } from 'utils/helpers/MultiSelectOpotions';
import { rolesForStoryAndPush } from 'utils/helpers/lists/roleArray';
import { PushFormProps, usePushForm } from '../../utils/hooks/usePushForm';
import { Switch } from 'ui/switch';
import { FormikHandlers } from 'formik';
import { usePush } from 'utils/api/usePush';
import { useGetPushRecipientsCountQuery } from 'pages/publishStory/api/queries/generated/GetPushRecipientsCount';
import { convertNumber } from 'utils/helpers/convertNumber';
import { useFilteredFacilities } from '../../../publishStory/components/showSettings/hooks/useFilteredFacilities';
import { useFilteredFacilityGroups } from '../../../publishStory/components/showSettings/hooks/useFilteredFacilityGroups';
import { useFilteredCities } from '../../../publishStory/components/showSettings/hooks/useFilteredCities';
import { useGetPositionsByFacilitiesAndCitiesQuery } from '../../api/queries/generated/GetPositionsByFacilitiesAndCities';
import { sortObj } from 'utils/helpers/sortObject';
import SimpleSelect from 'ui/select';
import { firedUserStatuses } from '../../../publishStory/utlis/helpers/const';

interface Props {
  values: PushFormProps;
  setFieldValue: (name: string, value: any) => void;
  handleChange: FormikHandlers['handleChange'];
}

const ShowSettings = ({ values, setFieldValue, handleChange }: Props) => {
  const { isPushPublished, isPushRemoved, isPushPending } = usePush();
  const { data: positionsData } = useGetPositionsByFacilitiesAndCitiesQuery({
    variables: {
      cityIds: values.cities,
      facilityIds: values.facilities,
      facilityGroupIds: values.facilityGroups,
    },
  });
  const filteredFacilityGroups = useFilteredFacilityGroups({ cities: values.cities });
  const filteredFacilities = useFilteredFacilities({ facilityGroups: values.facilityGroups, cities: values.cities });
  const filteredCities = useFilteredCities({ facilityGroupIds: values.facilityGroups, facilityIds: values.facilities });
  const roleOptions = useMemo(() => {
    return multiSelectRolesOptions(rolesForStoryAndPush);
  }, []);
  const cityOptions = useMemo(() => multiSelectOptionsFromArrayOfObjects(filteredCities), [filteredCities]);
  const facilityOptions = useMemo(() => multiSelectOptionsFromArrayOfObjects(filteredFacilities), [filteredFacilities]);
  const facilityGroupOptions = useMemo(
    () => multiSelectOptionsFromArrayOfObjects(filteredFacilityGroups),
    [filteredFacilityGroups],
  );
  const positionOptions = useMemo(
    () => multiSelectOptionsFromArrayOfObjects(sortObj(positionsData?.getPositionsByFacilitiesAndCities)),
    [positionsData?.getPositionsByFacilitiesAndCities],
  );
  const { onSave } = usePushForm();
  const { data } = useGetPushRecipientsCountQuery({
    variables: {
      input: {
        cities: values.cities,
        facilities: values.facilities,
        facilityGroups: values.facilityGroups,
        positions: values.positions,
        roles: values.roles,
        useCondition: values.userSettings,
        pushToUnassigned: values.pushToUnassigned,
      },
    },
  });
  const disabled = isPushPublished || isPushRemoved || isPushPending;
  return (
    <div className={'grid gap-y-4'}>
      <div className={'flex justify-between'}>
        <span className="Subtitle1 text-smena_text-secondary">Кому отправлять</span>
        <span className={'Subtitle2'}>
          {data?.getPushRecipientsCount ? convertNumber(data.getPushRecipientsCount) : 0} получателей
        </span>
      </div>
      <div className={'flex items-center gap-x-2'}>
        <Switch
          name={'userSettings'}
          disabled={disabled}
          onChange={e => {
            handleChange(e);
            if (!e.target.checked) {
              onSave({
                ...values,
                userSettings: e.target.checked,
                cities: [],
                facilities: [],
                facilityGroups: [],
                roles: [],
                positions: [],
              });
            } else {
              onSave({ ...values, userSettings: e.target.checked });
            }
          }}
          checked={values.userSettings}
        />
        <span className={'Subtitle2'}>Показать группе пользователей</span>
      </div>
      {values.userSettings && (
        <div className={'grid grid-cols-[200px_200px_280px] gap-6'}>
          <MultiSelect
            placeholder={'Выберите город'}
            divClassName={'w-[200px]'}
            selectClassName={'w-full'}
            label={'Город'}
            onChange={e => {
              setFieldValue('cities', e);
            }}
            disabled={disabled}
            onBlur={() => {
              onSave(values);
            }}
            value={values.cities}
            name={'cities'}
            options={cityOptions}
          />
          <MultiSelect
            placeholder={'Выберите группу'}
            popupClassName={'max-w-[300px]'}
            divClassName={'w-[200px]'}
            selectClassName={'w-full'}
            label={'Группа'}
            onChange={e => {
              setFieldValue('facilityGroups', e);
            }}
            disabled={disabled}
            onBlur={() => {
              onSave(values);
            }}
            value={values.facilityGroups}
            name={'facilityGroups'}
            options={facilityGroupOptions}
          />
          <MultiSelect
            placeholder={'Выберите объект'}
            divClassName={'w-[280px]'}
            selectClassName={'w-full'}
            label={'Объект'}
            onChange={e => {
              setFieldValue('facilities', e);
            }}
            disabled={disabled}
            onBlur={() => {
              onSave(values);
            }}
            value={values.facilities}
            name={'facilities'}
            options={facilityOptions}
          />
          <MultiSelect
            placeholder={'Выберите роль'}
            divClassName={'w-[200px]'}
            selectClassName={'w-full'}
            label={'Роль'}
            onChange={e => {
              setFieldValue('roles', e);
            }}
            disabled={disabled}
            onBlur={() => {
              onSave(values);
            }}
            value={values.roles}
            name={'roles'}
            options={roleOptions}
          />
          <MultiSelect
            placeholder={'Выберите профессию'}
            divClassName={'w-[200px]'}
            selectClassName={'w-full'}
            label={'Профессия'}
            sort
            onChange={e => {
              setFieldValue('positions', e);
            }}
            disabled={disabled}
            onBlur={() => {
              onSave(values);
            }}
            value={values.positions}
            name={'positions'}
            options={positionOptions}
          />
          <SimpleSelect
            divClassName="w-[280px]"
            label="Более не сотрудничают"
            onChange={value => {
              setFieldValue('pushToUnassigned', value);
            }}
            disabled={disabled}
            sort={false}
            onBlur={() => {
              onSave(values);
            }}
            placeholder={'Не выбрано'}
            value={values.pushToUnassigned}
            options={firedUserStatuses}
          />
        </div>
      )}
    </div>
  );
};

export default ShowSettings;
