import { Link } from 'react-router-dom';

import { FacilityGroupFragment } from 'pages/facilityGroup/api/fragments/generated/FacilityGroup.fragment';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { getImagePath } from 'utils/helpers/get/getImagePath';

interface TableInterface {
  group?: FacilityGroupFragment;
}

const GroupElement = ({ group }: TableInterface) => {
  const { facilities } = useSelectFacilities();
  const facInGroup = facilities?.filter(fac => fac.facilityGroupId === group?.id);
  const imagePath = getImagePath(group?.logo?.path);

  return (
    <Link
      className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-full p-5 flex gap-x-1 justify-between items-center
            shadow-smena rounded-lg bg-smena_white border border-transparent hover:border-primary"
      to={`/groups/${group?.id}`}
    >
      <div className="flex flex-col justify-center groupContent h-[114px]">
        <span className="H3 groupName">{group?.name}</span>
        <div className="Caption text-smena_text-secondary">
          Объектов:
          <span className="text-smena_text ml-1">{facInGroup?.length}</span>
        </div>
      </div>
      <img
        src={imagePath}
        alt="group_image"
        className="max-w-[74px] max-h-[74px] w-full h-full rounded-full bg-smena_bb-background_light object-cover"
      />
    </Link>
  );
};

export default GroupElement;
