import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFacilitiesActivityMutationVariables = Types.Exact<{
  input: Types.UpdateFacilitiesActivityInput;
}>;


export type UpdateFacilitiesActivityMutation = { __typename?: 'Mutation', updateFacilitiesActivity: boolean };


export const UpdateFacilitiesActivityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFacilitiesActivity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFacilitiesActivityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFacilitiesActivity"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type UpdateFacilitiesActivityMutationFn = Apollo.MutationFunction<UpdateFacilitiesActivityMutation, UpdateFacilitiesActivityMutationVariables>;

/**
 * __useUpdateFacilitiesActivityMutation__
 *
 * To run a mutation, you first call `useUpdateFacilitiesActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilitiesActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilitiesActivityMutation, { data, loading, error }] = useUpdateFacilitiesActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacilitiesActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacilitiesActivityMutation, UpdateFacilitiesActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacilitiesActivityMutation, UpdateFacilitiesActivityMutationVariables>(UpdateFacilitiesActivityDocument, options);
      }
export type UpdateFacilitiesActivityMutationHookResult = ReturnType<typeof useUpdateFacilitiesActivityMutation>;
export type UpdateFacilitiesActivityMutationResult = Apollo.MutationResult<UpdateFacilitiesActivityMutation>;
export type UpdateFacilitiesActivityMutationOptions = Apollo.BaseMutationOptions<UpdateFacilitiesActivityMutation, UpdateFacilitiesActivityMutationVariables>;