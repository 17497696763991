import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendDownloadSmsMutationVariables = Types.Exact<{
  phone: Types.Scalars['String']['input'];
}>;


export type SendDownloadSmsMutation = { __typename?: 'Mutation', sendDownloadSms: string };


export const SendDownloadSmsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendDownloadSms"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phone"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendDownloadSms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phone"}}}]}]}}]} as unknown as DocumentNode;
export type SendDownloadSmsMutationFn = Apollo.MutationFunction<SendDownloadSmsMutation, SendDownloadSmsMutationVariables>;

/**
 * __useSendDownloadSmsMutation__
 *
 * To run a mutation, you first call `useSendDownloadSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDownloadSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDownloadSmsMutation, { data, loading, error }] = useSendDownloadSmsMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useSendDownloadSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendDownloadSmsMutation, SendDownloadSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDownloadSmsMutation, SendDownloadSmsMutationVariables>(SendDownloadSmsDocument, options);
      }
export type SendDownloadSmsMutationHookResult = ReturnType<typeof useSendDownloadSmsMutation>;
export type SendDownloadSmsMutationResult = Apollo.MutationResult<SendDownloadSmsMutation>;
export type SendDownloadSmsMutationOptions = Apollo.BaseMutationOptions<SendDownloadSmsMutation, SendDownloadSmsMutationVariables>;