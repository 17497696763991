import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { RU_DATE } from 'utils/helpers/constVariables';
import React, { useMemo } from 'react';
import { RowCheckbox } from 'pages/facilityRequestsSchedule/components/rowCheckbox';
import ProgressComponent from 'ui/progress';
import { useRequestPositions } from 'components/table/row/hooks';
import { mapColumns } from 'utils/helpers/mapColumns';
import { IColumns } from 'interfaces/IColumns';
import { RequestProgressFragment } from 'pages/facilityUpdateRequest/api/fragmetns/generated/RequestProgress.fragment';
import { percentColor, progressPercentObj } from 'utils/helpers/progressPercentHelper';

interface TableInterface {
  columns: IColumns[];
  request: RequestProgressFragment;
}

const ScheduleRequestRow = ({ columns, request }: TableInterface) => {
  const requestPositions = useRequestPositions(request);
  const { shiftsProgress, hoursProgress } = progressPercentObj(request);
  const shiftsStroke = percentColor(shiftsProgress);
  const hoursStroke = percentColor(hoursProgress);

  const counter = useMemo(() => {
    if (request.plans.length > 3) {
      return request.plans.length - 3;
    }
    return null;
  }, [request.plans.length]);

  return (
    <div
      key={request.id}
      className="grid table__row xs:overflow-x-auto overflow-x-scroll"
      style={{
        gridTemplateColumns: mapColumns(columns),
      }}
    >
      <div className={columns[0].className}>
        <RowCheckbox id={request.id} />
      </div>
      <div className={columns[1].className}>
        <Link to={`/requests/${request.id}`}>{request.number}</Link>
      </div>
      <div className={columns[2].className}>
        {dayjs(request.dateFrom).format(RU_DATE) + ' - ' + dayjs(request.dateTo).format(RU_DATE)}
      </div>
      <div className={columns[3].className}>
        <span>{requestPositions.join(', ') + (counter ? ', ' : '')}</span>
        <span className="text-primary">{counter ? `+${counter}` : ''}</span>
      </div>
      <div className={columns[4].className}>
        <div className="Body2 flex items-center gap-x-1">
          <ProgressComponent type="circle" percent={shiftsProgress} width={16} strokeColor={shiftsStroke} />
          <div className="flex">
            <span>{request?.shiftsScheduleCount}</span>
            <span>/</span>
            <span>{request?.shiftsPlanCount}</span>
          </div>
        </div>
      </div>
      <div className={columns[5].className}>
        <div className="Body2 flex items-center gap-x-1">
          <ProgressComponent type="circle" percent={hoursProgress} width={16} strokeColor={hoursStroke} />
          <div className="flex">
            <span>{request?.hoursScheduleCount}</span>
            <span>/</span>
            <span>{request?.hoursPlanCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleRequestRow;
