import CalendarMain from './main';
import ShiftsSidebar from './shiftsSidebar';
import EditShiftSidebar from './sidebar/editShift';
import EditScheduleSidebar from './sidebar/editSchedule';
import CalendarHeader from '../../../components/calendar/calendarHeader';

const Calendar = () => {
  return (
    <>
      <CalendarHeader />
      <CalendarMain />
      <ShiftsSidebar />
      <EditShiftSidebar />
      <EditScheduleSidebar />
    </>
  );
};

export default Calendar;
