import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Role, SortingWay, UserFieldsForSorting } from 'generated/graphql';
import ClientUsersFilters from 'pages/users/components/clientUsers/ClientUsersFilters';
import { nullHandler } from 'utils/helpers/isNullHandler';
import { activeHandler } from 'utils/helpers/isActiveHandler';
import CustomPagination from 'ui/pagination';
import MainLayout from 'components/layout/main';
import { useClientUsersQuery } from 'pages/users/api/queries/generated/ClientUsers';
import { useClientsUsersTable } from 'components/table/columns/clientUsers';
import NewTable from 'components/table/newTable';
import { ClientUserFragment } from 'pages/users/api/fragments/generated/ClientUser.fragment';
import { convertSearch } from '../../../../utils/helpers/searchString';

const ClientUsersPage = () => {
  const [params] = useSearchParams();

  const facilityId = params.get('facilityId');
  const roleParam = params.get('role');
  const ratingParam = params.get('rating');
  const activeShiftsParam = params.get('activeShifts');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParams = params.get('page');
  const page = pageParams ? +pageParams : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const {
    data: dataUsers,
    loading: loadingUsers,
    error,
  } = useClientUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        searchQuery: convertSearch(searchQuery),
        sortBy: sortBy as UserFieldsForSorting,
        sortWay: sortWay as SortingWay,
        facilityId,
        rating: nullHandler(ratingParam),
        activeShifts: activeHandler(activeShiftsParam),
        role: roleParam as Role,
        size,
      },
    },
  });

  const clientUsers = dataUsers?.clientUsers;
  const users = useMemo(() => clientUsers?.users || [], [clientUsers?.users]);
  const table = useClientsUsersTable(users);

  return (
    <MainLayout title="Мои исполнители" breadCrumbs={[{ path: '/users', name: 'Мои исполнители' }]} error={error}>
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <ClientUsersFilters table={table} />
        <NewTable<ClientUserFragment> table={table} length={users.length} loading={loadingUsers} headerMenu={true} />
        {clientUsers && <CustomPagination currentPage={page} total={clientUsers.pages} />}
      </div>
    </MainLayout>
  );
};

export default ClientUsersPage;
