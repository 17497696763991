import React from 'react';

const LogoIcon = () => {
  return (
    <svg width="233" height="72" viewBox="0 0 233 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M69.6365 37.2769C67.39 37.2769 65.1938 37.0087 63.0479 36.4722C60.9021 35.9022 59.1921 35.1981 57.918 34.3599L60.5333 28.7269C61.7403 29.4981 63.1988 30.1352 64.9088 30.6381C66.6188 31.1075 68.2953 31.3422 69.9382 31.3422C73.2576 31.3422 74.9173 30.5207 74.9173 28.8778C74.9173 28.1066 74.4647 27.5534 73.5594 27.2181C72.6541 26.8828 71.2626 26.5978 69.385 26.3631C67.1721 26.0278 65.3447 25.6422 63.9029 25.2064C62.4612 24.7705 61.2038 23.9993 60.1309 22.8928C59.0915 21.7864 58.5718 20.2105 58.5718 18.1652C58.5718 16.4552 59.058 14.9464 60.0303 13.6388C61.0362 12.2976 62.478 11.2582 64.3556 10.5205C66.2668 9.78288 68.5132 9.41406 71.095 9.41406C73.0061 9.41406 74.9006 9.632 76.7782 10.0679C78.6894 10.4702 80.2652 11.0402 81.5058 11.7779L78.8905 17.3605C76.51 16.0193 73.9114 15.3487 71.095 15.3487C69.4185 15.3487 68.1612 15.5834 67.3229 16.0529C66.4847 16.5223 66.0656 17.1258 66.0656 17.8634C66.0656 18.7017 66.5182 19.2884 67.4235 19.6237C68.3288 19.959 69.7706 20.2776 71.7488 20.5793C73.9617 20.9481 75.7723 21.3505 77.1805 21.7864C78.5888 22.1887 79.8126 22.9431 80.852 24.0496C81.8914 25.1561 82.4111 26.6984 82.4111 28.6766C82.4111 30.3531 81.9082 31.8452 80.9023 33.1528C79.8964 34.4604 78.4211 35.4831 76.4764 36.2207C74.5653 36.9248 72.2853 37.2769 69.6365 37.2769Z"
        fill="#397DFF"
      />
      <path
        d="M121.904 9.41406C125.291 9.41406 127.973 10.4199 129.951 12.4317C131.963 14.4099 132.969 17.394 132.969 21.384V36.8745H125.123V22.5911C125.123 20.4452 124.671 18.8526 123.765 17.8131C122.894 16.7402 121.636 16.2037 119.993 16.2037C118.149 16.2037 116.691 16.8073 115.618 18.0143C114.545 19.1878 114.008 20.9481 114.008 23.2952V36.8745H106.162V22.5911C106.162 18.3329 104.452 16.2037 101.032 16.2037C99.2219 16.2037 97.7801 16.8073 96.7072 18.0143C95.6342 19.1878 95.0978 20.9481 95.0978 23.2952V36.8745H87.2519V9.81641H94.7457V12.9346C95.7516 11.7946 96.9754 10.9229 98.4172 10.3194C99.8924 9.71583 101.502 9.41406 103.245 9.41406C105.157 9.41406 106.883 9.79965 108.426 10.5708C109.968 11.3085 111.209 12.3982 112.147 13.8399C113.254 12.4317 114.645 11.342 116.322 10.5708C118.032 9.79965 119.893 9.41406 121.904 9.41406Z"
        fill="#397DFF"
      />
      <path
        d="M166.801 23.4461C166.801 23.5467 166.751 24.2508 166.65 25.5584H146.181C146.549 27.2349 147.421 28.5593 148.796 29.5316C150.171 30.504 151.881 30.9902 153.926 30.9902C155.334 30.9902 156.575 30.789 157.648 30.3866C158.754 29.9508 159.777 29.2802 160.716 28.3749L164.89 32.9013C162.342 35.8184 158.62 37.2769 153.725 37.2769C150.674 37.2769 147.974 36.6901 145.627 35.5166C143.28 34.3096 141.47 32.6499 140.196 30.5375C138.922 28.4252 138.285 26.0278 138.285 23.3455C138.285 20.6967 138.905 18.3161 140.145 16.2037C141.42 14.0579 143.146 12.3982 145.326 11.2246C147.539 10.0176 150.003 9.41406 152.719 9.41406C155.368 9.41406 157.765 9.98406 159.911 11.1241C162.057 12.2641 163.733 13.907 164.94 16.0529C166.181 18.1652 166.801 20.6296 166.801 23.4461ZM152.769 15.3487C150.992 15.3487 149.5 15.8517 148.293 16.8576C147.086 17.8634 146.348 19.2381 146.08 20.9817H159.408C159.14 19.2717 158.402 17.9137 157.195 16.9079C155.988 15.8684 154.513 15.3487 152.769 15.3487Z"
        fill="#397DFF"
      />
      <path
        d="M188.471 9.41406C191.824 9.41406 194.523 10.4199 196.568 12.4317C198.647 14.4435 199.686 17.4276 199.686 21.384V36.8745H191.84V22.5911C191.84 20.4452 191.371 18.8526 190.432 17.8131C189.493 16.7402 188.136 16.2037 186.358 16.2037C184.38 16.2037 182.804 16.824 181.631 18.0646C180.457 19.2717 179.871 21.0823 179.871 23.4964V36.8745H172.025V9.81641H179.518V12.9849C180.558 11.8449 181.849 10.9732 183.391 10.3696C184.933 9.73259 186.627 9.41406 188.471 9.41406Z"
        fill="#397DFF"
      />
      <path
        d="M217.712 9.41406C221.903 9.41406 225.122 10.4199 227.368 12.4317C229.615 14.4099 230.738 17.4108 230.738 21.4343V36.8745H223.395V33.5049C221.92 36.0196 219.171 37.2769 215.147 37.2769C213.068 37.2769 211.258 36.9248 209.715 36.2207C208.206 35.5166 207.05 34.5443 206.245 33.3037C205.44 32.0631 205.038 30.6549 205.038 29.079C205.038 26.5643 205.977 24.5861 207.854 23.1443C209.766 21.7025 212.699 20.9817 216.656 20.9817H222.892C222.892 19.2717 222.373 17.964 221.333 17.0587C220.294 16.1199 218.735 15.6505 216.656 15.6505C215.214 15.6505 213.789 15.8852 212.381 16.3546C211.006 16.7905 209.833 17.394 208.86 18.1652L206.044 12.6832C207.519 11.6438 209.279 10.8391 211.325 10.2691C213.403 9.69906 215.533 9.41406 217.712 9.41406ZM217.108 31.996C218.45 31.996 219.64 31.6943 220.679 31.0907C221.719 30.4537 222.456 29.5316 222.892 28.3246V25.5584H217.511C214.292 25.5584 212.683 26.6146 212.683 28.7269C212.683 29.7328 213.068 30.5375 213.839 31.141C214.644 31.711 215.734 31.996 217.108 31.996Z"
        fill="#397DFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2871 20.9544C34.2871 14.0102 28.6578 8.38087 21.7136 8.38087C14.7695 8.38087 9.14013 14.0102 9.14013 20.9544C9.14013 27.8985 14.7695 33.5278 21.7136 33.5278C28.6578 33.5278 34.2871 27.8985 34.2871 20.9544ZM42.6694 20.9544C42.6694 9.38079 33.2872 -0.00144719 21.7136 -0.00144669C10.14 -0.00144618 0.757811 9.38079 0.757812 20.9544C0.757812 32.5279 10.14 41.9102 21.7136 41.9102C33.2872 41.9102 42.6694 32.5279 42.6694 20.9544Z"
        fill="#397DFF"
      />
      <path
        d="M8.45466 68.1676C7.55773 68.1676 6.73555 67.9638 5.98811 67.5561C5.25426 67.1348 4.66311 66.5165 4.21464 65.7011C3.77977 64.8857 3.56233 63.8665 3.56233 62.6434C3.56233 61.4203 3.77298 60.4011 4.19426 59.5857C4.62913 58.7703 5.21349 58.1588 5.94734 57.7511C6.69478 57.3434 7.53055 57.1395 8.45466 57.1395C9.51466 57.1395 10.4524 57.3706 11.2677 57.8326C12.0831 58.2947 12.7286 58.9402 13.2043 59.7692C13.6799 60.5845 13.9178 61.5426 13.9178 62.6434C13.9178 63.7442 13.6799 64.709 13.2043 65.538C12.7286 66.367 12.0831 67.0125 11.2677 67.4746C10.4524 67.9366 9.51466 68.1676 8.45466 68.1676ZM2.72656 72V57.2415H4.60195V60.1565L4.47965 62.6638L4.68349 65.1711V72H2.72656ZM8.29158 66.4553C8.98466 66.4553 9.603 66.2991 10.1466 65.9865C10.7038 65.6739 11.1386 65.2323 11.4512 64.6615C11.7774 64.0771 11.9404 63.4044 11.9404 62.6434C11.9404 61.8688 11.7774 61.2029 11.4512 60.6457C11.1386 60.0749 10.7038 59.6333 10.1466 59.3207C9.603 59.0081 8.98466 58.8518 8.29158 58.8518C7.61209 58.8518 6.99376 59.0081 6.43657 59.3207C5.89298 59.6333 5.45811 60.0749 5.13195 60.6457C4.81939 61.2029 4.66311 61.8688 4.66311 62.6434C4.66311 63.4044 4.81939 64.0771 5.13195 64.6615C5.45811 65.2323 5.89298 65.6739 6.43657 65.9865C6.99376 66.2991 7.61209 66.4553 8.29158 66.4553Z"
        fill="#397DFF"
      />
      <path
        d="M23.6941 68.0453V65.7623L23.5922 65.3342V61.4407C23.5922 60.6117 23.3476 59.973 22.8583 59.5245C22.3827 59.0625 21.6624 58.8315 20.6976 58.8315C20.0588 58.8315 19.4337 58.9402 18.8222 59.1576C18.2106 59.3615 17.6942 59.6401 17.2729 59.9934L16.4576 58.5257C17.0147 58.0772 17.6806 57.7375 18.4553 57.5065C19.2435 57.2618 20.0656 57.1395 20.9218 57.1395C22.4031 57.1395 23.5446 57.4997 24.3464 58.2199C25.1482 58.9402 25.5491 60.041 25.5491 61.5222V68.0453H23.6941ZM20.1472 68.1676C19.3454 68.1676 18.6387 68.0317 18.0272 67.76C17.4292 67.4882 16.9672 67.1144 16.641 66.6388C16.3149 66.1496 16.1518 65.5992 16.1518 64.9876C16.1518 64.4033 16.2877 63.8733 16.5595 63.3976C16.8449 62.922 17.3001 62.5415 17.9253 62.2561C18.564 61.9707 19.4201 61.828 20.4937 61.828H23.9183V63.2346H20.5753C19.5968 63.2346 18.9377 63.3976 18.5979 63.7238C18.2582 64.0499 18.0883 64.444 18.0883 64.9061C18.0883 65.4361 18.299 65.8642 18.7203 66.1903C19.1415 66.5029 19.7259 66.6592 20.4733 66.6592C21.2072 66.6592 21.8459 66.4961 22.3895 66.1699C22.9467 65.8438 23.3476 65.3682 23.5922 64.743L23.9795 66.0884C23.7213 66.7271 23.266 67.2367 22.6137 67.6173C21.9614 67.9842 21.1392 68.1676 20.1472 68.1676Z"
        fill="#397DFF"
      />
      <path
        d="M34.4749 68.2084C33.578 68.2084 32.783 68.0657 32.0899 67.7803C31.4104 67.495 30.826 67.0737 30.3368 66.5165C29.8476 65.9457 29.4739 65.2323 29.2157 64.3761C28.971 63.5063 28.8487 62.4871 28.8487 61.3184C28.8487 60.3943 28.9303 59.5653 29.0933 58.8315C29.2564 58.0976 29.4875 57.4453 29.7864 56.8745C30.099 56.3038 30.4795 55.8077 30.928 55.3865C31.39 54.9652 31.9064 54.6186 32.4772 54.3468C33.0616 54.0614 33.7003 53.844 34.3934 53.6945L39.3265 52.5938L39.6118 54.408L35.0661 55.3865C34.7943 55.4408 34.4681 55.5223 34.0876 55.6311C33.7071 55.7398 33.3198 55.9097 32.9257 56.1407C32.5316 56.3581 32.1646 56.6639 31.8249 57.058C31.4852 57.4521 31.2134 57.9685 31.0095 58.6072C30.8057 59.2324 30.7037 60.007 30.7037 60.9311C30.7037 61.1893 30.7105 61.3863 30.7241 61.5222C30.7377 61.6581 30.7513 61.794 30.7649 61.9299C30.7921 62.0658 30.8125 62.2697 30.826 62.5415L30.0107 61.7057C30.2281 60.9583 30.5746 60.306 31.0503 59.7488C31.5259 59.1916 32.0967 58.7635 32.7626 58.4645C33.4421 58.152 34.1827 57.9957 34.9845 57.9957C35.9902 57.9957 36.8803 58.2131 37.6549 58.648C38.4431 59.0829 39.0615 59.6808 39.5099 60.4419C39.9584 61.2029 40.1826 62.0726 40.1826 63.0511C40.1826 64.0431 39.9448 64.9333 39.4692 65.7215C39.0071 66.4961 38.348 67.1076 37.4918 67.5561C36.6357 67.991 35.63 68.2084 34.4749 68.2084ZM34.6584 66.5776C35.3514 66.5776 35.963 66.4282 36.493 66.1292C37.023 65.8166 37.4375 65.4021 37.7365 64.8857C38.0354 64.3557 38.1849 63.7646 38.1849 63.1122C38.1849 62.4599 38.0354 61.8824 37.7365 61.3795C37.4375 60.8767 37.023 60.4826 36.493 60.1972C35.963 59.8983 35.3379 59.7488 34.6176 59.7488C33.9381 59.7488 33.3266 59.8915 32.783 60.1769C32.2394 60.4486 31.8181 60.836 31.5191 61.3388C31.2202 61.828 31.0707 62.3988 31.0707 63.0511C31.0707 63.7034 31.2202 64.3014 31.5191 64.8449C31.8317 65.3749 32.253 65.7962 32.783 66.1088C33.3266 66.4214 33.9517 66.5776 34.6584 66.5776Z"
        fill="#397DFF"
      />
      <path
        d="M48.0126 68.1676C46.9254 68.1676 45.9605 67.9298 45.1179 67.4542C44.2754 66.9785 43.6095 66.3262 43.1202 65.4973C42.631 64.6547 42.3864 63.7034 42.3864 62.6434C42.3864 61.5698 42.631 60.6185 43.1202 59.7895C43.6095 58.9606 44.2754 58.3151 45.1179 57.853C45.9605 57.3774 46.9254 57.1395 48.0126 57.1395C49.0862 57.1395 50.0442 57.3774 50.8868 57.853C51.743 58.3151 52.4089 58.9606 52.8845 59.7895C53.3737 60.6049 53.6183 61.5562 53.6183 62.6434C53.6183 63.717 53.3737 64.6683 52.8845 65.4973C52.4089 66.3262 51.743 66.9785 50.8868 67.4542C50.0442 67.9298 49.0862 68.1676 48.0126 68.1676ZM48.0126 66.4553C48.7056 66.4553 49.324 66.2991 49.8676 65.9865C50.4247 65.6739 50.8596 65.2323 51.1722 64.6615C51.4848 64.0771 51.641 63.4044 51.641 62.6434C51.641 61.8688 51.4848 61.2029 51.1722 60.6457C50.8596 60.0749 50.4247 59.6333 49.8676 59.3207C49.324 59.0081 48.7056 58.8518 48.0126 58.8518C47.3195 58.8518 46.7011 59.0081 46.1576 59.3207C45.614 59.6333 45.1791 60.0749 44.8529 60.6457C44.5268 61.2029 44.3637 61.8688 44.3637 62.6434C44.3637 63.4044 44.5268 64.0771 44.8529 64.6615C45.1791 65.2323 45.614 65.6739 46.1576 65.9865C46.7011 66.2991 47.3195 66.4553 48.0126 66.4553Z"
        fill="#397DFF"
      />
      <path
        d="M58.5941 68.0453V58.4442L59.0834 58.9538H54.6395V57.2415H64.5057V58.9538H60.0822L60.5511 58.4442V68.0453H58.5941Z"
        fill="#397DFF"
      />
      <path
        d="M73.5394 68.0453V65.7623L73.4375 65.3342V61.4407C73.4375 60.6117 73.1929 59.973 72.7037 59.5245C72.228 59.0625 71.5078 58.8315 70.5429 58.8315C69.9042 58.8315 69.279 58.9402 68.6675 59.1576C68.056 59.3615 67.5395 59.6401 67.1183 59.9934L66.3029 58.5257C66.8601 58.0772 67.526 57.7375 68.3006 57.5065C69.0888 57.2618 69.911 57.1395 70.7671 57.1395C72.2484 57.1395 73.3899 57.4997 74.1917 58.2199C74.9935 58.9402 75.3944 60.041 75.3944 61.5222V68.0453H73.5394ZM69.9925 68.1676C69.1907 68.1676 68.484 68.0317 67.8725 67.76C67.2745 67.4882 66.8125 67.1144 66.4863 66.6388C66.1602 66.1496 65.9971 65.5992 65.9971 64.9876C65.9971 64.4033 66.133 63.8733 66.4048 63.3976C66.6902 62.922 67.1454 62.5415 67.7706 62.2561C68.4093 61.9707 69.2654 61.828 70.339 61.828H73.7637V63.2346H70.4206C69.4421 63.2346 68.783 63.3976 68.4433 63.7238C68.1035 64.0499 67.9336 64.444 67.9336 64.9061C67.9336 65.4361 68.1443 65.8642 68.5656 66.1903C68.9869 66.5029 69.5712 66.6592 70.3187 66.6592C71.0525 66.6592 71.6912 66.4961 72.2348 66.1699C72.792 65.8438 73.1929 65.3682 73.4375 64.743L73.8248 66.0884C73.5666 66.7271 73.1114 67.2367 72.459 67.6173C71.8067 67.9842 70.9846 68.1676 69.9925 68.1676Z"
        fill="#397DFF"
      />
      <path
        d="M85.2081 68.0453V57.2415H87.165V65.1099L93.79 57.2415H95.5635V68.0453H93.6066V60.1769L87.0019 68.0453H85.2081Z"
        fill="#397DFF"
      />
      <path
        d="M105.652 68.0453V57.2415H115.661V68.0453H113.704V58.4645L114.172 58.9538H107.14L107.609 58.4645V68.0453H105.652Z"
        fill="#397DFF"
      />
      <path
        d="M124.482 68.1676C123.395 68.1676 122.43 67.9298 121.588 67.4542C120.745 66.9785 120.079 66.3262 119.59 65.4973C119.101 64.6547 118.856 63.7034 118.856 62.6434C118.856 61.5698 119.101 60.6185 119.59 59.7895C120.079 58.9606 120.745 58.3151 121.588 57.853C122.43 57.3774 123.395 57.1395 124.482 57.1395C125.556 57.1395 126.514 57.3774 127.356 57.853C128.213 58.3151 128.878 58.9606 129.354 59.7895C129.843 60.6049 130.088 61.5562 130.088 62.6434C130.088 63.717 129.843 64.6683 129.354 65.4973C128.878 66.3262 128.213 66.9785 127.356 67.4542C126.514 67.9298 125.556 68.1676 124.482 68.1676ZM124.482 66.4553C125.175 66.4553 125.794 66.2991 126.337 65.9865C126.894 65.6739 127.329 65.2323 127.642 64.6615C127.954 64.0771 128.111 63.4044 128.111 62.6434C128.111 61.8688 127.954 61.2029 127.642 60.6457C127.329 60.0749 126.894 59.6333 126.337 59.3207C125.794 59.0081 125.175 58.8518 124.482 58.8518C123.789 58.8518 123.171 59.0081 122.627 59.3207C122.084 59.6333 121.649 60.0749 121.323 60.6457C120.996 61.2029 120.833 61.8688 120.833 62.6434C120.833 63.4044 120.996 64.0771 121.323 64.6615C121.649 65.2323 122.084 65.6739 122.627 65.9865C123.171 66.2991 123.789 66.4553 124.482 66.4553Z"
        fill="#397DFF"
      />
      <path
        d="M140.621 67.128V58.9538H135.933L135.831 61.033C135.804 61.7125 135.756 62.3716 135.688 63.0103C135.634 63.6355 135.539 64.213 135.403 64.743C135.28 65.273 135.097 65.7079 134.852 66.0476C134.608 66.3874 134.281 66.598 133.874 66.6796L131.937 66.333C132.359 66.3466 132.698 66.2107 132.956 65.9253C133.215 65.6264 133.412 65.2187 133.548 64.7023C133.697 64.1858 133.806 63.6015 133.874 62.9492C133.942 62.2833 133.996 61.597 134.037 60.8903L134.2 57.2415H142.578V67.128H140.621ZM131.509 70.5527V66.333H144.229V70.5527H142.395V68.0453H133.364V70.5527H131.509Z"
        fill="#397DFF"
      />
      <path
        d="M152.914 68.1676C152.017 68.1676 151.195 67.9638 150.448 67.5561C149.714 67.1348 149.123 66.5165 148.674 65.7011C148.239 64.8857 148.022 63.8665 148.022 62.6434C148.022 61.4203 148.233 60.4011 148.654 59.5857C149.089 58.7703 149.673 58.1588 150.407 57.7511C151.154 57.3434 151.99 57.1395 152.914 57.1395C153.974 57.1395 154.912 57.3706 155.727 57.8326C156.543 58.2947 157.188 58.9402 157.664 59.7692C158.14 60.5845 158.377 61.5426 158.377 62.6434C158.377 63.7442 158.14 64.709 157.664 65.538C157.188 66.367 156.543 67.0125 155.727 67.4746C154.912 67.9366 153.974 68.1676 152.914 68.1676ZM147.186 72V57.2415H149.062V60.1565L148.939 62.6638L149.143 65.1711V72H147.186ZM152.751 66.4553C153.444 66.4553 154.063 66.2991 154.606 65.9865C155.163 65.6739 155.598 65.2323 155.911 64.6615C156.237 64.0771 156.4 63.4044 156.4 62.6434C156.4 61.8688 156.237 61.2029 155.911 60.6457C155.598 60.0749 155.163 59.6333 154.606 59.3207C154.063 59.0081 153.444 58.8518 152.751 58.8518C152.072 58.8518 151.453 59.0081 150.896 59.3207C150.353 59.6333 149.918 60.0749 149.592 60.6457C149.279 61.2029 149.123 61.8688 149.123 62.6434C149.123 63.4044 149.279 64.0771 149.592 64.6615C149.918 65.2323 150.353 65.6739 150.896 65.9865C151.453 66.2991 152.072 66.4553 152.751 66.4553Z"
        fill="#397DFF"
      />
      <path
        d="M168.154 68.0453V65.7623L168.052 65.3342V61.4407C168.052 60.6117 167.807 59.973 167.318 59.5245C166.842 59.0625 166.122 58.8315 165.157 58.8315C164.519 58.8315 163.893 58.9402 163.282 59.1576C162.67 59.3615 162.154 59.6401 161.733 59.9934L160.917 58.5257C161.474 58.0772 162.14 57.7375 162.915 57.5065C163.703 57.2618 164.525 57.1395 165.382 57.1395C166.863 57.1395 168.004 57.4997 168.806 58.2199C169.608 58.9402 170.009 60.041 170.009 61.5222V68.0453H168.154ZM164.607 68.1676C163.805 68.1676 163.098 68.0317 162.487 67.76C161.889 67.4882 161.427 67.1144 161.101 66.6388C160.775 66.1496 160.611 65.5992 160.611 64.9876C160.611 64.4033 160.747 63.8733 161.019 63.3976C161.305 62.922 161.76 62.5415 162.385 62.2561C163.024 61.9707 163.88 61.828 164.953 61.828H168.378V63.2346H165.035C164.057 63.2346 163.397 63.3976 163.058 63.7238C162.718 64.0499 162.548 64.444 162.548 64.9061C162.548 65.4361 162.759 65.8642 163.18 66.1903C163.601 66.5029 164.186 66.6592 164.933 66.6592C165.667 66.6592 166.306 66.4961 166.849 66.1699C167.406 65.8438 167.807 65.3682 168.052 64.743L168.439 66.0884C168.181 66.7271 167.726 67.2367 167.073 67.6173C166.421 67.9842 165.599 68.1676 164.607 68.1676Z"
        fill="#397DFF"
      />
      <path
        d="M178.935 68.2084C178.038 68.2084 177.243 68.0657 176.55 67.7803C175.87 67.495 175.286 67.0737 174.797 66.5165C174.307 65.9457 173.934 65.2323 173.675 64.3761C173.431 63.5063 173.308 62.4871 173.308 61.3184C173.308 60.3943 173.39 59.5653 173.553 58.8315C173.716 58.0976 173.947 57.4453 174.246 56.8745C174.559 56.3038 174.939 55.8077 175.388 55.3865C175.85 54.9652 176.366 54.6186 176.937 54.3468C177.521 54.0614 178.16 53.844 178.853 53.6945L183.786 52.5938L184.072 54.408L179.526 55.3865C179.254 55.4408 178.928 55.5223 178.547 55.6311C178.167 55.7398 177.779 55.9097 177.385 56.1407C176.991 56.3581 176.624 56.6639 176.285 57.058C175.945 57.4521 175.673 57.9685 175.469 58.6072C175.265 59.2324 175.163 60.007 175.163 60.9311C175.163 61.1893 175.17 61.3863 175.184 61.5222C175.197 61.6581 175.211 61.794 175.225 61.9299C175.252 62.0658 175.272 62.2697 175.286 62.5415L174.47 61.7057C174.688 60.9583 175.034 60.306 175.51 59.7488C175.986 59.1916 176.556 58.7635 177.222 58.4645C177.902 58.152 178.642 57.9957 179.444 57.9957C180.45 57.9957 181.34 58.2131 182.115 58.648C182.903 59.0829 183.521 59.6808 183.97 60.4419C184.418 61.2029 184.642 62.0726 184.642 63.0511C184.642 64.0431 184.404 64.9333 183.929 65.7215C183.467 66.4961 182.808 67.1076 181.952 67.5561C181.095 67.991 180.09 68.2084 178.935 68.2084ZM179.118 66.5776C179.811 66.5776 180.423 66.4282 180.953 66.1292C181.483 65.8166 181.897 65.4021 182.196 64.8857C182.495 64.3557 182.645 63.7646 182.645 63.1122C182.645 62.4599 182.495 61.8824 182.196 61.3795C181.897 60.8767 181.483 60.4826 180.953 60.1972C180.423 59.8983 179.798 59.7488 179.077 59.7488C178.398 59.7488 177.786 59.8915 177.243 60.1769C176.699 60.4486 176.278 60.836 175.979 61.3388C175.68 61.828 175.53 62.3988 175.53 63.0511C175.53 63.7034 175.68 64.3014 175.979 64.8449C176.291 65.3749 176.713 65.7962 177.243 66.1088C177.786 66.4214 178.411 66.5776 179.118 66.5776Z"
        fill="#397DFF"
      />
      <path
        d="M192.472 68.1676C191.385 68.1676 190.42 67.9298 189.578 67.4542C188.735 66.9785 188.069 66.3262 187.58 65.4973C187.091 64.6547 186.846 63.7034 186.846 62.6434C186.846 61.5698 187.091 60.6185 187.58 59.7895C188.069 58.9606 188.735 58.3151 189.578 57.853C190.42 57.3774 191.385 57.1395 192.472 57.1395C193.546 57.1395 194.504 57.3774 195.346 57.853C196.203 58.3151 196.869 58.9606 197.344 59.7895C197.833 60.6049 198.078 61.5562 198.078 62.6434C198.078 63.717 197.833 64.6683 197.344 65.4973C196.869 66.3262 196.203 66.9785 195.346 67.4542C194.504 67.9298 193.546 68.1676 192.472 68.1676ZM192.472 66.4553C193.165 66.4553 193.784 66.2991 194.327 65.9865C194.884 65.6739 195.319 65.2323 195.632 64.6615C195.944 64.0771 196.101 63.4044 196.101 62.6434C196.101 61.8688 195.944 61.2029 195.632 60.6457C195.319 60.0749 194.884 59.6333 194.327 59.3207C193.784 59.0081 193.165 58.8518 192.472 58.8518C191.779 58.8518 191.161 59.0081 190.617 59.3207C190.074 59.6333 189.639 60.0749 189.313 60.6457C188.986 61.2029 188.823 61.8688 188.823 62.6434C188.823 63.4044 188.986 64.0771 189.313 64.6615C189.639 65.2323 190.074 65.6739 190.617 65.9865C191.161 66.2991 191.779 66.4553 192.472 66.4553Z"
        fill="#397DFF"
      />
      <path
        d="M203.054 68.0453V58.4442L203.543 58.9538H199.099V57.2415H208.965V58.9538H204.542L205.011 58.4442V68.0453H203.054Z"
        fill="#397DFF"
      />
      <path
        d="M219.202 68.0453L215.023 62.7861L216.633 61.8076L221.505 68.0453H219.202ZM211.374 68.0453V57.2415H213.331V68.0453H211.374ZM212.74 63.4792V61.8076H216.307V63.4792H212.74ZM216.796 62.8676L214.982 62.623L219.12 57.2415H221.22L216.796 62.8676Z"
        fill="#397DFF"
      />
      <path
        d="M230.391 68.0453V65.7623L230.289 65.3342V61.4407C230.289 60.6117 230.044 59.973 229.555 59.5245C229.079 59.0625 228.359 58.8315 227.394 58.8315C226.756 58.8315 226.13 58.9402 225.519 59.1576C224.907 59.3615 224.391 59.6401 223.97 59.9934L223.154 58.5257C223.711 58.0772 224.377 57.7375 225.152 57.5065C225.94 57.2618 226.762 57.1395 227.618 57.1395C229.1 57.1395 230.241 57.4997 231.043 58.2199C231.845 58.9402 232.246 60.041 232.246 61.5222V68.0453H230.391ZM226.844 68.1676C226.042 68.1676 225.335 68.0317 224.724 67.76C224.126 67.4882 223.664 67.1144 223.338 66.6388C223.012 66.1496 222.848 65.5992 222.848 64.9876C222.848 64.4033 222.984 63.8733 223.256 63.3976C223.542 62.922 223.997 62.5415 224.622 62.2561C225.261 61.9707 226.117 61.828 227.19 61.828H230.615V63.2346H227.272C226.293 63.2346 225.634 63.3976 225.295 63.7238C224.955 64.0499 224.785 64.444 224.785 64.9061C224.785 65.4361 224.996 65.8642 225.417 66.1903C225.838 66.5029 226.423 66.6592 227.17 66.6592C227.904 66.6592 228.543 66.4961 229.086 66.1699C229.643 65.8438 230.044 65.3682 230.289 64.743L230.676 66.0884C230.418 66.7271 229.963 67.2367 229.31 67.6173C228.658 67.9842 227.836 68.1676 226.844 68.1676Z"
        fill="#397DFF"
      />
    </svg>
  );
};

export default LogoIcon;
