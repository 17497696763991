import { getTokens } from '../auth';
import { ApolloLink } from '@apollo/client';

const authMiddleware = new ApolloLink((operation, forward) => {
  const { accessToken } = getTokens();
  // return the headers to the context so httpLink can read them
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : null,
      'Apollo-Require-Preflight': 'true',
    },
  }));
  return forward(operation);
});

export default authMiddleware;
