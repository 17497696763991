import { useMemo } from 'react';
import { ShiftStage } from 'generated/graphql';
import { useStore } from 'store';
import { useUserShifts } from 'pages/userTracking/lib/hooks/useUserShifts';

const useNotAcceptedByForemanShifts = () => {
  const { hideCanceledShifts, facilityId, workpostId } = useStore();
  const { notProbationUserShift, shiftsWithoutCanceled } = useUserShifts();

  return useMemo(() => {
    const shifts = (hideCanceledShifts ? shiftsWithoutCanceled : notProbationUserShift) || [];
    return shifts.filter(shift => {
      const isStageFitsAndUserIsAuthor = shift.stage === ShiftStage.New && shift.authorId === shift.userId;
      const isPositionFits = workpostId ? shift.positionId === workpostId : true;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId === facilityId : true;
      return isStageFitsAndUserIsAuthor && isPositionFits && isFacilityFits;
    });
  }, [facilityId, hideCanceledShifts, notProbationUserShift, shiftsWithoutCanceled, workpostId]);
};

export default useNotAcceptedByForemanShifts;
