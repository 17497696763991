import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelRequestByManagerMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type CancelRequestByManagerMutation = { __typename?: 'Mutation', cancelZayavkaByManager: boolean };


export const CancelRequestByManagerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelRequestByManager"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelZayavkaByManager"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode;
export type CancelRequestByManagerMutationFn = Apollo.MutationFunction<CancelRequestByManagerMutation, CancelRequestByManagerMutationVariables>;

/**
 * __useCancelRequestByManagerMutation__
 *
 * To run a mutation, you first call `useCancelRequestByManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRequestByManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRequestByManagerMutation, { data, loading, error }] = useCancelRequestByManagerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelRequestByManagerMutation(baseOptions?: Apollo.MutationHookOptions<CancelRequestByManagerMutation, CancelRequestByManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRequestByManagerMutation, CancelRequestByManagerMutationVariables>(CancelRequestByManagerDocument, options);
      }
export type CancelRequestByManagerMutationHookResult = ReturnType<typeof useCancelRequestByManagerMutation>;
export type CancelRequestByManagerMutationResult = Apollo.MutationResult<CancelRequestByManagerMutation>;
export type CancelRequestByManagerMutationOptions = Apollo.BaseMutationOptions<CancelRequestByManagerMutation, CancelRequestByManagerMutationVariables>;