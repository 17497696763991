import dayjs from 'dayjs';
import * as React from 'react';
import TimePicker from 'antd/es/time-picker';
import clsx from 'clsx';

import { ErrorNotification } from '../errorNotification';
import TimePickerClock from 'assets/icons/TimePickerClock';
import { TIME_DATE } from 'utils/helpers/constVariables';
import { IMask } from 'react-imask';

declare type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;
export declare type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

interface Props {
  value: dayjs.Dayjs | null;
  onChange: (time: dayjs.Dayjs | null) => void;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  format?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  popupClassName?: string;
  minuteStep?: IntRange<1, 59>;
  error?: any;
  showText?: boolean;
}

const TimePickerInput: React.FC<Props> = React.memo(
  ({
    onChange,
    onBlur,
    value,
    placeholder = dayjs().format(TIME_DATE),
    error,
    className,
    popupClassName = 'fixed',
    label,
    disabled,
    format = TIME_DATE,
    minuteStep = 5,
    showText,
  }) => {
    const masked = IMask.createMask({
      mask: '00:00',
    });
    return (
      <>
        {showText ? (
          <div className={clsx('flex flex-col gap-y-0.5', className)}>
            {Boolean(label) && <div className="Subtitle2 mb-0.5">{label}</div>}
            <span className={'Body2 text-smena_text-secondary'}>
              {value ? dayjs(value).format(TIME_DATE) : 'Отсутствует'}
            </span>
          </div>
        ) : (
          <div className={clsx('flex flex-col gap-y-0.5', className)}>
            {Boolean(label) && <label className="label-primary">{label}</label>}
            <TimePicker
              inputRender={props => (
                <input
                  onKeyUp={e => {
                    const input = e.target as HTMLInputElement;
                    masked.resolve(input.value);
                    input.value = masked.value;
                  }}
                  {...props}
                />
              )}
              changeOnBlur
              onBlur={onBlur}
              format={format}
              value={value}
              disabled={disabled}
              minuteStep={minuteStep}
              className={clsx('custom__picker w-full', error ? '!border-smena_red' : 'border-smena_gray-40')}
              suffixIcon={<TimePickerClock />}
              placeholder={placeholder}
              popupClassName={popupClassName}
              showNow={false}
              onChange={onChange}
            />
            {error && <ErrorNotification text={error} />}
          </div>
        )}
      </>
    );
  },
);

export default TimePickerInput;
