import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ReportFragmentDoc } from '../../fragments/generated/Report.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePollsReportMutationVariables = Types.Exact<{
  input: Types.CreatePollsReportInput;
}>;


export type CreatePollsReportMutation = { __typename?: 'Mutation', createPollsReport: { __typename?: 'Report', id: string, type: Types.ReportType, filePath: string } };


export const CreatePollsReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePollsReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePollsReportInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPollsReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Report"}}]}}]}},...ReportFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreatePollsReportMutationFn = Apollo.MutationFunction<CreatePollsReportMutation, CreatePollsReportMutationVariables>;

/**
 * __useCreatePollsReportMutation__
 *
 * To run a mutation, you first call `useCreatePollsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePollsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPollsReportMutation, { data, loading, error }] = useCreatePollsReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePollsReportMutation(baseOptions?: Apollo.MutationHookOptions<CreatePollsReportMutation, CreatePollsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePollsReportMutation, CreatePollsReportMutationVariables>(CreatePollsReportDocument, options);
      }
export type CreatePollsReportMutationHookResult = ReturnType<typeof useCreatePollsReportMutation>;
export type CreatePollsReportMutationResult = Apollo.MutationResult<CreatePollsReportMutation>;
export type CreatePollsReportMutationOptions = Apollo.BaseMutationOptions<CreatePollsReportMutation, CreatePollsReportMutationVariables>;