import { ApolloCache } from '@apollo/client';
import { ChangeUserStatusMutation } from '../mutations/generated/ChangeUserStatus';
import { UsersPaginatedFragment } from '../fragments/generated/UsersPaginated.fragment';

interface Props {
  cache: ApolloCache<any>;
  data?: ChangeUserStatusMutation | null;
  user: UsersPaginatedFragment;
}

export const updateUserPaginationRowCache = ({ cache, data, user }: Props) => {
  if (data) {
    cache.modify({
      id: cache.identify(user),
      fields: {
        isActive() {
          return data.changeUserStatus.isActive;
        },
      },
    });
  }
};
