import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPushRecipientsCountQueryVariables = Types.Exact<{
  input: Types.PushRecipientsCountInput;
}>;


export type GetPushRecipientsCountQuery = { __typename?: 'Query', getPushRecipientsCount: number };


export const GetPushRecipientsCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPushRecipientsCount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PushRecipientsCountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPushRecipientsCount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;

/**
 * __useGetPushRecipientsCountQuery__
 *
 * To run a query within a React component, call `useGetPushRecipientsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPushRecipientsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPushRecipientsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPushRecipientsCountQuery(baseOptions: Apollo.QueryHookOptions<GetPushRecipientsCountQuery, GetPushRecipientsCountQueryVariables> & ({ variables: GetPushRecipientsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPushRecipientsCountQuery, GetPushRecipientsCountQueryVariables>(GetPushRecipientsCountDocument, options);
      }
export function useGetPushRecipientsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPushRecipientsCountQuery, GetPushRecipientsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPushRecipientsCountQuery, GetPushRecipientsCountQueryVariables>(GetPushRecipientsCountDocument, options);
        }
export function useGetPushRecipientsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPushRecipientsCountQuery, GetPushRecipientsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPushRecipientsCountQuery, GetPushRecipientsCountQueryVariables>(GetPushRecipientsCountDocument, options);
        }
export type GetPushRecipientsCountQueryHookResult = ReturnType<typeof useGetPushRecipientsCountQuery>;
export type GetPushRecipientsCountLazyQueryHookResult = ReturnType<typeof useGetPushRecipientsCountLazyQuery>;
export type GetPushRecipientsCountSuspenseQueryHookResult = ReturnType<typeof useGetPushRecipientsCountSuspenseQuery>;
export type GetPushRecipientsCountQueryResult = Apollo.QueryResult<GetPushRecipientsCountQuery, GetPushRecipientsCountQueryVariables>;