import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePromotionInFacilityGroupMutationVariables = Types.Exact<{
  input: Types.DeletePromotionInFacilityGroupInput;
}>;


export type DeletePromotionInFacilityGroupMutation = { __typename?: 'Mutation', deletePromotionInFacilityGroup: boolean };


export const DeletePromotionInFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePromotionInFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeletePromotionInFacilityGroupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePromotionInFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type DeletePromotionInFacilityGroupMutationFn = Apollo.MutationFunction<DeletePromotionInFacilityGroupMutation, DeletePromotionInFacilityGroupMutationVariables>;

/**
 * __useDeletePromotionInFacilityGroupMutation__
 *
 * To run a mutation, you first call `useDeletePromotionInFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromotionInFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromotionInFacilityGroupMutation, { data, loading, error }] = useDeletePromotionInFacilityGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePromotionInFacilityGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeletePromotionInFacilityGroupMutation, DeletePromotionInFacilityGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePromotionInFacilityGroupMutation, DeletePromotionInFacilityGroupMutationVariables>(DeletePromotionInFacilityGroupDocument, options);
      }
export type DeletePromotionInFacilityGroupMutationHookResult = ReturnType<typeof useDeletePromotionInFacilityGroupMutation>;
export type DeletePromotionInFacilityGroupMutationResult = Apollo.MutationResult<DeletePromotionInFacilityGroupMutation>;
export type DeletePromotionInFacilityGroupMutationOptions = Apollo.BaseMutationOptions<DeletePromotionInFacilityGroupMutation, DeletePromotionInFacilityGroupMutationVariables>;