import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ModerationReportListSorting, ModerationStatus, PeriodType, SortingWay } from 'generated/graphql';

import MainLayout from 'components/layout/main';
import ModerationReportsFilter from 'pages/moderationReports/components/ModerationReportsFilter';

import CustomPagination from 'ui/pagination';
import { useModerationReportsPaginatedQuery } from './api/queries/generated/ModerationReportsPaginated';
import dayjs from 'dayjs';
import useModerationTable from '../../components/table/columns/moderation';
import NewTable from '../../components/table/newTable';
import { PaginationModerationReportFragment } from './api/fragments/generated/PaginationModerationReport.fragment';

const ModerationReportsPage = () => {
  const [params] = useSearchParams();
  const dateFromParam = params.get('dateFrom');
  const dateToParam = params.get('dateTo');
  const groupIdParam = params.get('groupId');
  const facilityIdParam = params.get('facilityId');
  const periodParam = params.get('period') as PeriodType;
  const statusParam = params.get('status') as ModerationStatus;
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const dateStart = dateFromParam ? dayjs(dateFromParam) : null;
  const dateEnd = dateToParam ? dayjs(dateToParam).endOf('day') : null;

  const {
    data: dataReports,
    loading: loadingReports,
    error,
  } = useModerationReportsPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        dateStart,
        dateEnd,
        sortBy: sortBy as ModerationReportListSorting,
        sortWay: sortWay as SortingWay,
        groupId: groupIdParam,
        facilityId: facilityIdParam,
        periodType: periodParam as PeriodType,
        status: statusParam as ModerationStatus,
        size,
      },
    },
  });

  const reportsPaginated = dataReports?.moderationReportsPaginated;
  const reports = useMemo(() => reportsPaginated?.reports || [], [reportsPaginated?.reports]);
  const table = useModerationTable(reports);

  useEffect(() => {
    table.toggleAllRowsSelected(false);
  }, [page, table]);

  return (
    <MainLayout
      title="Модерация смен"
      breadCrumbs={[{ path: '/moderation-period', name: 'Модерация смен' }]}
      error={error}
    >
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <ModerationReportsFilter table={table} />
        <NewTable<PaginationModerationReportFragment>
          table={table}
          length={reports.length}
          loading={loadingReports}
          headerMenu={true}
        />
        {reportsPaginated && <CustomPagination total={reportsPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default ModerationReportsPage;
