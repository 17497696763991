import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ReportFragmentDoc } from '../../fragments/generated/Report.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateReportEverythingIsPossibleBonusMutationVariables = Types.Exact<{
  startOfMonth: Types.Scalars['String']['input'];
}>;


export type CreateReportEverythingIsPossibleBonusMutation = { __typename?: 'Mutation', createReportEverythingIsPossibleBonus: { __typename?: 'Report', id: string, type: Types.ReportType, filePath: string } };


export const CreateReportEverythingIsPossibleBonusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateReportEverythingIsPossibleBonus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"startOfMonth"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createReportEverythingIsPossibleBonus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"startOfMonth"},"value":{"kind":"Variable","name":{"kind":"Name","value":"startOfMonth"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Report"}}]}}]}},...ReportFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateReportEverythingIsPossibleBonusMutationFn = Apollo.MutationFunction<CreateReportEverythingIsPossibleBonusMutation, CreateReportEverythingIsPossibleBonusMutationVariables>;

/**
 * __useCreateReportEverythingIsPossibleBonusMutation__
 *
 * To run a mutation, you first call `useCreateReportEverythingIsPossibleBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportEverythingIsPossibleBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportEverythingIsPossibleBonusMutation, { data, loading, error }] = useCreateReportEverythingIsPossibleBonusMutation({
 *   variables: {
 *      startOfMonth: // value for 'startOfMonth'
 *   },
 * });
 */
export function useCreateReportEverythingIsPossibleBonusMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportEverythingIsPossibleBonusMutation, CreateReportEverythingIsPossibleBonusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportEverythingIsPossibleBonusMutation, CreateReportEverythingIsPossibleBonusMutationVariables>(CreateReportEverythingIsPossibleBonusDocument, options);
      }
export type CreateReportEverythingIsPossibleBonusMutationHookResult = ReturnType<typeof useCreateReportEverythingIsPossibleBonusMutation>;
export type CreateReportEverythingIsPossibleBonusMutationResult = Apollo.MutationResult<CreateReportEverythingIsPossibleBonusMutation>;
export type CreateReportEverythingIsPossibleBonusMutationOptions = Apollo.BaseMutationOptions<CreateReportEverythingIsPossibleBonusMutation, CreateReportEverythingIsPossibleBonusMutationVariables>;