import { CategoricalChartState } from 'recharts/types/chart/types';

interface IChartBarStatus {
  barId?: number;
  status?: 'click' | 'hover';
}

export const chartClick = (state: CategoricalChartState): IChartBarStatus => ({
  barId: state.activeTooltipIndex,
  status: 'click',
});
export const chartHover = (state: CategoricalChartState): IChartBarStatus => ({
  barId: state.activeTooltipIndex,
  status: 'hover',
});
