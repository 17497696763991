import { ShiftTime } from 'generated/graphql';

export const getDayNightRu = (period?: string | null) => {
  const periodList = [
    {
      period: ShiftTime.Undefined,
      periodRu: 'Не указан',
    },
    {
      period: ShiftTime.Night,
      periodRu: 'Ночь',
    },
    {
      period: ShiftTime.Day,
      periodRu: 'День',
    },
  ];

  return periodList.find(item => period === item.period)?.periodRu;
};
