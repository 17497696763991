import React from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { twentyOneColumns } from 'components/table/columns';
import MainLayout from 'components/layout/main';
import { useStocksHeaderMenu } from '../leads/utils/hooks/headerMenu';
import TwentyOneRow from './components/TwentyOneRow';
import { useGetTwentyOneReferrersPaginatedQuery } from './api/queries/generated/GetTwentyOneReferrersPaginated';
import { OchkoReferrersSorting, Role, SortingWay } from 'generated/graphql';
import TwentyOneTable from './components/TwentyOneTable';
import CustomPagination from 'ui/pagination';
import TwentyOneFilter from './components/TwentyOneFilter';

const TwentyOnePage = () => {
  const headerMenu = useStocksHeaderMenu();
  const [params] = useSearchParams();

  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const dateFromParam = params.get('dateFrom');
  const dateToParam = params.get('dateTo');
  const roleParams = params.get('role');
  const facilityGroupIdParam = params.get('facilityGroupId');
  const facilityIdParam = params.get('facilityId');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const dateFrom = dateFromParam ? dayjs(dateFromParam) : null;
  const dateTo = dateToParam ? dayjs(dateToParam).endOf('day') : null;

  const { data, loading, error } = useGetTwentyOneReferrersPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        searchQuery,
        role: roleParams as Role,
        sortBy: sortBy as OchkoReferrersSorting,
        sortWay: sortWay as SortingWay,
        facilityGroupId: facilityGroupIdParam,
        facilityId: facilityIdParam,
        dateFrom,
        dateTo,
        size,
      },
    },
  });

  const twentyOnePaginated = data?.getOchkoReferrersPaginated;
  const twentyOneRefers = twentyOnePaginated?.referrers;
  return (
    <MainLayout
      title="Акции"
      breadCrumbs={[{ path: '/offers/twentyOne', name: 'Акции' }]}
      headerMenu={headerMenu}
      error={error}
    >
      <div className={'grid gap-y-6'}>
        <TwentyOneFilter />
        <TwentyOneTable
          data={twentyOneRefers}
          columns={twentyOneColumns}
          loading={loading}
          defaultSortBy={OchkoReferrersSorting.OchkoDate}
          headerMenu={true}
        >
          {twentyOneRefers?.map(refer => <TwentyOneRow key={`${refer.id}${refer.leads[0].id}`} refer={refer} />)}
        </TwentyOneTable>
        {twentyOnePaginated && <CustomPagination currentPage={page} total={twentyOnePaginated.pages} />}
      </div>
    </MainLayout>
  );
};

export default TwentyOnePage;
