import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLinkedNaimixObjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLinkedNaimixObjectsQuery = { __typename?: 'Query', getLinkedNaimixObjects: Array<{ __typename?: 'NaimixObjectOutput', naimixObjectName?: string | null, naimixObjectId?: string | null }> };


export const GetLinkedNaimixObjectsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLinkedNaimixObjects"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLinkedNaimixObjects"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"naimixObjectName"}},{"kind":"Field","name":{"kind":"Name","value":"naimixObjectId"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetLinkedNaimixObjectsQuery__
 *
 * To run a query within a React component, call `useGetLinkedNaimixObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkedNaimixObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkedNaimixObjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLinkedNaimixObjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetLinkedNaimixObjectsQuery, GetLinkedNaimixObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLinkedNaimixObjectsQuery, GetLinkedNaimixObjectsQueryVariables>(GetLinkedNaimixObjectsDocument, options);
      }
export function useGetLinkedNaimixObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinkedNaimixObjectsQuery, GetLinkedNaimixObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLinkedNaimixObjectsQuery, GetLinkedNaimixObjectsQueryVariables>(GetLinkedNaimixObjectsDocument, options);
        }
export function useGetLinkedNaimixObjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLinkedNaimixObjectsQuery, GetLinkedNaimixObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLinkedNaimixObjectsQuery, GetLinkedNaimixObjectsQueryVariables>(GetLinkedNaimixObjectsDocument, options);
        }
export type GetLinkedNaimixObjectsQueryHookResult = ReturnType<typeof useGetLinkedNaimixObjectsQuery>;
export type GetLinkedNaimixObjectsLazyQueryHookResult = ReturnType<typeof useGetLinkedNaimixObjectsLazyQuery>;
export type GetLinkedNaimixObjectsSuspenseQueryHookResult = ReturnType<typeof useGetLinkedNaimixObjectsSuspenseQuery>;
export type GetLinkedNaimixObjectsQueryResult = Apollo.QueryResult<GetLinkedNaimixObjectsQuery, GetLinkedNaimixObjectsQueryVariables>;