import { useFacilitiesLazyQuery } from 'pages/main/api/queries/generated/Facilities';
import { useEffect, useMemo } from 'react';
import { sortObj } from '../../helpers/sortObject';

export const useSelectFacilities = () => {
  const [getFacilities, { data, loading, error }] = useFacilitiesLazyQuery();

  useEffect(() => {
    getFacilities();
  }, [getFacilities]);

  const facilities = useMemo(() => sortObj(data?.facilities), [data?.facilities]);

  return { facilities, loading, error };
};
