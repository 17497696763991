import { Spoiler } from 'ui/spoiler';
import { useUserExperience } from 'pages/userQuestionnaire/hook';
import ExperienceElement from './ExperienceElement';

const HistoryBlock = () => {
  const userExperience = useUserExperience();
  return (
    <Spoiler
      className="userPageBlock blockTemplate"
      header={
        <div className="flex justify-between">
          <h2 className="blockTitleTemplate">Опыт</h2>
        </div>
      }
    >
      <div className={'flex flex-col gap-y-6 pb-6'}>
        {userExperience.length ? (
          userExperience.map(experience => <ExperienceElement key={experience.id} experience={experience} />)
        ) : (
          <span className={'Body2 text-center text-smena_text-secondary'}>У пользователя нет опыта</span>
        )}
      </div>
    </Spoiler>
  );
};
export default HistoryBlock;
