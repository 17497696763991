import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelPlanCasingRateMutationVariables = Types.Exact<{
  input: Types.CancelPlanCasingRateInput;
}>;


export type CancelPlanCasingRateMutation = { __typename?: 'Mutation', cancelPlanCasingRate: boolean };


export const CancelPlanCasingRateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelPlanCasingRate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelPlanCasingRateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelPlanCasingRate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type CancelPlanCasingRateMutationFn = Apollo.MutationFunction<CancelPlanCasingRateMutation, CancelPlanCasingRateMutationVariables>;

/**
 * __useCancelPlanCasingRateMutation__
 *
 * To run a mutation, you first call `useCancelPlanCasingRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPlanCasingRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPlanCasingRateMutation, { data, loading, error }] = useCancelPlanCasingRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelPlanCasingRateMutation(baseOptions?: Apollo.MutationHookOptions<CancelPlanCasingRateMutation, CancelPlanCasingRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPlanCasingRateMutation, CancelPlanCasingRateMutationVariables>(CancelPlanCasingRateDocument, options);
      }
export type CancelPlanCasingRateMutationHookResult = ReturnType<typeof useCancelPlanCasingRateMutation>;
export type CancelPlanCasingRateMutationResult = Apollo.MutationResult<CancelPlanCasingRateMutation>;
export type CancelPlanCasingRateMutationOptions = Apollo.BaseMutationOptions<CancelPlanCasingRateMutation, CancelPlanCasingRateMutationVariables>;