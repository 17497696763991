import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';

import MainLayout from '../../components/layout/main';
import { usePushBreadcrumbs } from './utils/hooks';
import MainInformation from './components/mainInformation';
import { usePushForm } from './utils/hooks/usePushForm';
import PushNotification from './components/pushNotification';
import PushDestination from './components/pushDestination';
import ShowSettings from './components/showSettings';
import PushShowTime from './components/showTime';
import { useStoriesTitle } from '../pushes/hooks';
import { useStore } from 'store';
import { EAutoSave } from 'enums/EAutoSave';
import BlockButton from './components/buttonBlock';

const PushPage = () => {
  const breadcrumbs = usePushBreadcrumbs();
  const title = useStoriesTitle();
  const { initialValues, validate, onSubmit } = usePushForm();
  const { autoSaveState, setAutoSaveState } = useStore();

  useEffect(() => {
    setAutoSaveState(EAutoSave.SAVED);
  }, [setAutoSaveState]);

  return (
    <MainLayout title={title} breadCrumbs={breadcrumbs} autoSave={autoSaveState}>
      <div className={'grid grid-cols-12 gap-6'}>
        <Formik
          enableReinitialize
          validateOnBlur
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          validateOnChange={false}
        >
          {({ values, handleChange, errors, setFieldValue, validateForm }) => {
            return (
              <div className={'grid col-span-8 gap-6'}>
                <Form className={'shadow-smena rounded-lg bg-smena_white p-6 flex flex-col gap-y-6'}>
                  <MainInformation values={values} handleChange={handleChange} errors={errors} />
                  <hr className={'bg-smena_gray-30'} />
                  <PushNotification values={values} handleChange={handleChange} errors={errors} />
                  <hr className={'bg-smena_gray-30'} />
                  <PushDestination values={values} setFieldValue={setFieldValue} errors={errors} />
                  <hr className={'bg-smena_gray-30'} />
                  <ShowSettings values={values} setFieldValue={setFieldValue} handleChange={handleChange} />
                  <hr className={'bg-smena_gray-30'} />
                  <PushShowTime values={values} setFieldValue={setFieldValue} errors={errors} />
                  <BlockButton values={values} validateForm={validateForm} />
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </MainLayout>
  );
};

export default PushPage;
