import React from 'react';

const TechMaintenance = () => {
  return (
    <svg width="401" height="285" viewBox="0 0 401 285" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4118_78936)">
        <rect width="401" height="285" fill="#E6EEFF" />
        <path
          d="M353 33V15.5M353 15.5H376C378.209 15.5 380 17.2909 380 19.5V66.5C380 68.7091 378.209 70.5 376 70.5H346.5C344.291 70.5 342.5 72.2909 342.5 74.5V98.25M353 15.5H263M280.5 240.5H356C358.209 240.5 360 238.709 360 236.5V212M280.5 240.5V288M280.5 240.5H242C239.791 240.5 238 242.291 238 244.5V255C238 257.209 236.209 259 234 259H193M342.5 98.25V122C342.5 124.209 344.291 126 346.5 126H356C358.209 126 360 127.791 360 130V162V181.625M342.5 98.25H405.5M360 181.625V201.25V212M360 181.625H251M263 15.5V-7M263 15.5V44C263 46.2091 261.209 48 259 48H233.5M193 48H233.5M233.5 48V86M360 212H405.5"
          stroke="#B2CDFF"
          strokeWidth="3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243 143C243 119.804 224.196 101 201 101C177.804 101 159 119.804 159 143C159 166.196 177.804 185 201 185C224.196 185 243 166.196 243 143ZM271 143C271 104.34 239.66 73 201 73C162.34 73 131 104.34 131 143C131 181.66 162.34 213 201 213C239.66 213 271 181.66 271 143Z"
          fill="#397DFF"
        />
        <path
          d="M344.5 32.5C344.5 36.9183 348.082 40.5 352.5 40.5C356.918 40.5 360.5 36.9183 360.5 32.5C360.5 28.0817 356.918 24.5 352.5 24.5C348.082 24.5 344.5 28.0817 344.5 32.5ZM351.5 155C351.5 159.418 355.082 163 359.5 163C363.918 163 367.5 159.418 367.5 155C367.5 150.582 363.918 147 359.5 147C355.082 147 351.5 150.582 351.5 155ZM234.5 89.5H309V86.5H234.5V89.5ZM314.5 84V36.5H311.5V84H314.5ZM317 34H352.5V31H317V34ZM258.5 133H285.5V130H258.5V133ZM288 135.5V151H291V135.5H288ZM293.5 156.5H359.5V153.5H293.5V156.5ZM285.5 133C286.881 133 288 134.119 288 135.5H291C291 132.462 288.538 130 285.5 130V133ZM314.5 36.5C314.5 35.1193 315.619 34 317 34V31C313.962 31 311.5 33.4624 311.5 36.5H314.5ZM288 151C288 154.038 290.462 156.5 293.5 156.5V153.5C292.119 153.5 291 152.381 291 151H288ZM309 89.5C312.038 89.5 314.5 87.0376 314.5 84H311.5C311.5 85.3807 310.381 86.5 309 86.5V89.5Z"
          fill="#397DFF"
        />
        <path
          d="M314 240.5C314 244.918 317.582 248.5 322 248.5C326.418 248.5 330 244.918 330 240.5C330 236.082 326.418 232.5 322 232.5C317.582 232.5 314 236.082 314 240.5ZM236.5 185V207H239.5V185H236.5ZM242 212.5H318V209.5H242V212.5ZM320.5 215V240.5H323.5V215H320.5ZM318 212.5C319.381 212.5 320.5 213.619 320.5 215H323.5C323.5 211.962 321.038 209.5 318 209.5V212.5ZM236.5 207C236.5 210.038 238.962 212.5 242 212.5V209.5C240.619 209.5 239.5 208.381 239.5 207H236.5Z"
          fill="#397DFF"
        />
        <g clipPath="url(#clip1_4118_78936)">
          <path d="M0 0H201V285H0V0Z" fill="white" />
          <circle cx="24" cy="24" r="8" fill="#EB3527" />
          <circle cx="48" cy="24" r="8" fill="#EF8840" />
          <circle cx="72" cy="24" r="8" fill="#64C5B0" />
          <rect x="16" y="63" width="191" height="20" rx="4" fill="#F5F8FF" />
          <rect x="16" y="91" width="191" height="57" rx="4" fill="#F5F8FF" />
          <rect x="16" y="156" width="191" height="57" rx="4" fill="#F5F8FF" />
          <rect x="16" y="221" width="191" height="77" rx="4" fill="#F5F8FF" />
        </g>
      </g>
      <rect x="0.5" y="0.5" width="400" height="284" rx="15.5" stroke="#397DFF" />
      <defs>
        <clipPath id="clip0_4118_78936">
          <rect width="401" height="285" rx="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_4118_78936">
          <rect width="201" height="285" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TechMaintenance;
