import React from 'react';
import { TIcon } from '../../interfaces/TIcon';

const UnassignWorker = ({ className }: TIcon) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        d="M10.1213 8.12132C9.55871 8.68393 8.79565 9 8 9C7.20435 9 6.44129 8.68393 5.87868 8.12132C5.31607 7.55871 5 6.79565 5 6C5 5.20435 5.31607 4.44129 5.87868 3.87868C6.44129 3.31607 7.20435 3 8 3C8.79565 3 9.55871 3.31607 10.1213 3.87868C10.6839 4.44129 11 5.20435 11 6C11 6.79565 10.6839 7.55871 10.1213 8.12132Z"
        // fill="#393B55"
      />
      <path
        d="M12.2426 12.7574C11.1174 11.6321 9.5913 11 8 11C6.4087 11 4.88258 11.6321 3.75736 12.7574C2.63214 13.8826 2 15.4087 2 17H14C14 15.4087 13.3679 13.8826 12.2426 12.7574Z"
        // fill="#393B55"
      />
      <path
        d="M14.2933 6.87868C14.1058 6.69114 13.8515 6.58579 13.5862 6.58579C13.321 6.58579 13.0667 6.69114 12.8791 6.87868C12.6916 7.06622 12.5862 7.32057 12.5862 7.58579C12.5862 7.851 12.6916 8.10536 12.8791 8.29289L13.5862 9L12.8791 9.70711C12.6916 9.89464 12.5862 10.149 12.5862 10.4142C12.5862 10.6794 12.6916 10.9338 12.8791 11.1213C13.0667 11.3089 13.321 11.4142 13.5862 11.4142C13.8515 11.4142 14.1058 11.3089 14.2933 11.1213L15.0005 10.4142L15.7076 11.1213C15.8951 11.3089 16.1495 11.4142 16.4147 11.4142C16.6799 11.4142 16.9342 11.3089 17.1218 11.1213C17.3093 10.9338 17.4147 10.6794 17.4147 10.4142C17.4147 10.149 17.3093 9.89464 17.1218 9.70711L16.4147 9L17.1218 8.29289C17.3093 8.10536 17.4147 7.851 17.4147 7.58579C17.4147 7.32057 17.3093 7.06622 17.1218 6.87868C16.9342 6.69114 16.6799 6.58579 16.4147 6.58579C16.1495 6.58579 15.8951 6.69114 15.7076 6.87868L15.0005 7.58579L14.2933 6.87868Z"
        // fill="#393B55"
      />
    </svg>
  );
};

export default UnassignWorker;
