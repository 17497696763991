import Across from 'assets/icons/Across';
import clsx from 'clsx';
import React from 'react';

interface Props {
  show: boolean;
  content?: string | null;
  clickHandler: () => void;
}

const Filter = React.memo(({ content, clickHandler, show }: Props) => {
  return (
    <div
      className={clsx(
        'Caption text-smena_gray-80 px-2 py-1 rounded-lg bg-smena_bb-background flex items-center whitespace-nowrap',
        show ? 'opacity-100 visible' : 'opacity-0 invisible absolute',
      )}
    >
      <span className="mr-1">{content}</span>
      <span className="cursor-pointer" onClick={clickHandler}>
        <Across className="text-primary-light" />
      </span>
    </div>
  );
});

export default Filter;
