import { useRef, useState } from 'react';

import MainLayout from 'components/layout/main';
import ReviewOutlineIcon from 'assets/icons/ReviewOutlineIcon';
import { useUserHeaderMenu } from 'utils/hooks/headerMenu/useUserHeaderMenu';
import useUserBreadcrumbs from 'utils/hooks/breadcrumbs/useUserBreadcrumbs';
import Questionnaire from './components';
import RateBlock from './components/rate';
import HistoryBlock from './components/experience';
import CreateReviewSidebar from './components/createReviewSidebar';
import ReviewBlock from './components/review';
import { useCurrentUser } from 'utils/api/useUser';
import { useUserTitle } from '../user/lib/hooks';

const QuestionnairePage = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const { loading } = useCurrentUser();

  const title = useUserTitle();
  const headerMenu = useUserHeaderMenu();
  const breadCrumbs = useUserBreadcrumbs();
  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs} loading={loading}>
      <div className="grid grid-cols-12 gap-6">
        <div className="userPageBlock blockTemplate">
          <div className="grid justify-items-end">
            <button
              type="button"
              className="btn-primary_big flex gap-x-2"
              ref={btnRef}
              onClick={() => {
                setShowSidebar(value => !value);
              }}
            >
              <ReviewOutlineIcon />
              <span>Оценить</span>
            </button>
          </div>
        </div>

        <Questionnaire />
        <RateBlock />
        <ReviewBlock />
        <HistoryBlock />
      </div>
      <CreateReviewSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} btnRef={btnRef} />
    </MainLayout>
  );
};

export default QuestionnairePage;
