import { FacilityRequestShiftFragment } from 'pages/facilityRequests/api/fragments/generated/FacilityRequestShift.fragment';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { getUserFIO } from 'utils/helpers/userFullName';
import SimpleSelect from 'ui/select';
import { undefStr } from 'interfaces/CustomTypes';
import { useStore } from 'store';
import { Link } from 'react-router-dom';

type Props = {
  shift: FacilityRequestShiftFragment;
};

const WantedShift = React.memo(({ shift }: Props) => {
  const freeShiftUsers = useStore(state => state.freeShiftUsers);
  const setFreeShiftUsers = useStore(state => state.setFreeShiftUsers);
  const [checked, setChecked] = useState(false);
  const [selectedUser, setSelectedUser] = useState<undefStr>(undefined);
  const userOptions = useMemo(() => {
    return shift.requestShiftSuggestions.map(el => ({
      id: el.id,
      name: getUserFIO(el.user),
    }));
  }, [shift.requestShiftSuggestions]);

  useEffect(() => {
    if (!shift.requestShiftSuggestions.find(el => freeShiftUsers.includes(el.id))) {
      setChecked(false);
      setSelectedUser(undefined);
    }
  }, [freeShiftUsers, shift.requestShiftSuggestions]);
  return (
    <div className={'flex items-center gap-x-2'}>
      <input
        type="checkbox"
        name={`${shift.id}`}
        checked={checked}
        disabled={!!shift.shift?.user || !selectedUser}
        onChange={({ target: { checked } }) => {
          setChecked(checked);
          if (selectedUser) {
            setFreeShiftUsers(selectedUser);
          }
        }}
        id={`${shift.id}`}
        className={'checkbox-primary'}
      />
      <label htmlFor={`${shift.id}`} className={'label-checkbox w-[50px]'}>
        {dayjs(shift.dateFrom).format('DD, dd')}
      </label>
      <span>·</span>
      {shift.requestShiftSuggestions.length ? (
        <SimpleSelect
          divClassName={'flex-[1_0]'}
          placeholder={'Выберите исполнителя'}
          value={selectedUser}
          options={userOptions}
          onChange={value => {
            setSelectedUser(value);
          }}
        />
      ) : shift.shift?.user ? (
        <Link className={'link'} to={`/users/${shift.shift?.user.id}`}>
          {getUserFIO(shift.shift?.user)}
        </Link>
      ) : (
        <span className={'Subtitle1 text-smena_text-secondary'}>Свободная смена не занята</span>
      )}
    </div>
  );
});

export default WantedShift;
