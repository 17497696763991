import { Role, WorkPostStatus } from 'generated/graphql';
import { userNameSurname } from 'utils/helpers/userFullName';
import { useCurrentUser } from 'utils/api/useUser';
import { useMeRole } from 'store/hooks/useMeRole';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useUpdateUserNaimixWorkStatusMutation } from '../api/mutations/generated/UpdateUserNaimixWorkStatus';

export const getWorkpostStatusRu = (status: WorkPostStatus) => {
  const statuses = [
    { status: WorkPostStatus.Active, statusRu: 'Активен' },
    { status: WorkPostStatus.Inactive, statusRu: 'Прекращен' },
  ];
  const response = statuses.find(statusEl => statusEl.status === status)?.statusRu;
  if (response) return response;
  return '';
};

export const useUserTitle = () => {
  const { user, loading } = useCurrentUser();
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  const text = isMeAdminOrSupervisor ? 'Пользователь' : 'Исполнитель';
  if (loading) {
    return 'Загрузка';
  }
  if (user) {
    return `${text} ${userNameSurname(user)}`;
  }
  return 'Новый пользователь';
};

export const useUpdateUser = () => {
  const { user } = useCurrentUser();
  const [updateUserMutation] = useUpdateUserNaimixWorkStatusMutation();
  if (!user) return [];
  return [
    (checked: boolean) =>
      updateUserMutation({
        variables: {
          input: {
            userId: user.id,
            allowWorkWithoutNaimix: checked,
          },
        },
        update: cache => {
          cache.modify({
            id: `RatingUser:${user.id}`,
            fields: {
              allowWorkWithoutNaimix() {
                return checked;
              },
            },
          });
        },
      })
        .then(() => {
          successToast('Статус подключения к Наймикс изменен');
        })
        .catch(e => {
          errorToast(errorHandler(e));
        }),
  ];
};
