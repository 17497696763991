import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Plus from 'assets/icons/plus';
import Filter from 'components/table/filters/filter';

import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import { periodShift } from 'utils/helpers/lists/periodType';
import { getPeriodRu } from 'utils/helpers/get/getPeriodRu';
import { getModerationStatusRu, moderationStatus } from 'utils/helpers/lists/statusType';
import { ModerationStatus, PeriodType } from 'generated/graphql';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { reportsQueryConfig } from 'utils/graphqlConfigs/moderationReports';
import { ISO_DATE, RU_DATE } from 'utils/helpers/constVariables';
import RangePickerInput from 'ui/pickers/RangePickerInput';
import { DayOrNullArray } from 'interfaces/CustomTypes';
import SimpleSelect from 'ui/select';
import TableFilter from 'components/table/filters';
import SplitButtonSmena from 'ui/splitButton/SplitButton';
import { useSetModerationReportStatusMutation } from 'pages/moderationReport/api/mutations/generated/SetModerationReportStatus';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useCustomSearchParams } from '../../users/lib/useParams';
import { Table } from '@tanstack/react-table';
import { PaginationModerationReportFragment } from '../api/fragments/generated/PaginationModerationReport.fragment';
import ColumnSettings from 'components/table/columnSettings';
import { useFacilityById } from 'utils/api/useFacility';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';
import { useModerationStore } from '../../moderationReport/useModerationStore';

interface Props {
  table: Table<PaginationModerationReportFragment>;
}

const ModerationReportsFilter = ({ table }: Props) => {
  const [value, setValue] = useState<DayOrNullArray>(null);
  const { params, setParams, useParamsCount, handleParams, resetFilter } = useCustomSearchParams();
  const selectedOption = useModerationStore(state => state.selectedOption);
  const setSelectedOption = useModerationStore(state => state.setSelectedOption);
  const [showFilter, setShowFilter] = useState(false);
  const dateFrom = params.get('dateFrom');
  const dateTo = params.get('dateTo');
  const facilityGroupIdParam = params.get('groupId');
  const facilityParam = params.get('facilityId');
  const periodTypeParam = params.get('period');
  const statusParam = params.get('status');

  const [groupId, setGroupId] = useState(facilityGroupIdParam);
  const [facilityId, setFacilityId] = useState(facilityParam);
  const [period, setPeriod] = useState(periodTypeParam);
  const [status, setStatus] = useState(statusParam);

  const { facility } = useFacilityById(facilityParam);
  const { facilitiesGroups } = useSelectFacilityGroups();
  const { facilities } = useSelectFacilities();
  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);
  const btnRef = useRef<HTMLButtonElement>(null);
  const paramsCount = useParamsCount();
  const filteredObjects = useMemo(() => filterFacilities(groupId, facilities), [facilities, groupId]);

  const options = ['Отправлена', 'В работе'];
  //request falls by timeout
  const [changeStatus, { loading }] = useSetModerationReportStatusMutation({
    awaitRefetchQueries: true,
    refetchQueries: [reportsQueryConfig()],
  });

  const reportIds = table.getSelectedRowModel().rows.map(el => el.original.id);

  const mutation = useCallback(
    (status: string) => {
      changeStatus({
        variables: {
          input: {
            reportIds,
            status: status as ModerationStatus,
          },
        },
      })
        .then(e => {
          if (e.data?.setModerationReportStatus) {
            successToast('Статус успешно изменен');
            table.toggleAllRowsSelected(false);
          }
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    },
    [changeStatus, reportIds, table],
  );

  const handleClick = useCallback(() => {
    if (selectedOption === 0) {
      mutation('SENT');
    }
    if (selectedOption === 1) {
      mutation('IN_WORK');
    }
  }, [mutation, selectedOption]);

  useEffect(() => {
    setSelectedOption(0);
  }, [setSelectedOption]);

  return (
    <div className="filter-bar flex justify-between flex-wrap gap-y-2">
      <div className="flex flex-col gap-y-2">
        {table.getIsSomeRowsSelected() ? (
          <SplitButtonSmena
            options={options}
            buttonText={`Статус «${options[selectedOption]}»`}
            onButtonClick={handleClick}
            disabled={loading}
          />
        ) : (
          <div className={`flex relative gap-x-2`}>
            <button
              type="button"
              ref={btnRef}
              className="btn-stroke flex gap-x-1 items-center"
              onClick={() => {
                setShowFilter(value => !value);
              }}
            >
              Фильтр
              {Boolean(paramsCount) && (
                <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                  {String(paramsCount)}
                </span>
              )}
            </button>

            <ColumnSettings<PaginationModerationReportFragment> table={table} />
            <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
              <RangePickerInput
                label="Даты"
                value={value && [value[0], value[1]]}
                onChange={newValue => {
                  setValue(newValue);
                }}
              />
              <SimpleSelect
                label="Группа"
                popupClassName={'max-w-[300px]'}
                value={groupId}
                onChange={value => {
                  setGroupId(value);
                  setFacilityId('');
                }}
                allOption={'Все группы'}
                placeholder={'Не выбрано'}
                options={facilitiesGroups}
              />
              <SimpleSelect
                label="Объект"
                value={facilityId}
                onChange={value => {
                  setFacilityId(value);
                }}
                allOption={'Все объекты'}
                placeholder={'Не выбрано'}
                options={filteredObjects}
              />
              <SimpleSelect
                label="Период"
                value={period}
                onChange={value => {
                  setPeriod(value);
                  if (value === 'Все периоды') {
                    setPeriod(null);
                  }
                }}
                sort={false}
                allOption={'Все периоды'}
                placeholder={'Не выбрано'}
                options={periodShift.map(shit => ({ id: shit, name: getPeriodRu(shit) }))}
              />

              <SimpleSelect
                label="Статус"
                value={status}
                onChange={value => {
                  setStatus(value);
                  if (value === 'Все статусы') {
                    setStatus(null);
                  }
                }}
                allOption={'Все статусы'}
                placeholder={'Не выбрано'}
                options={moderationStatus.map(status => ({ id: status, name: getModerationStatusRu(status) }))}
              />

              <div className="flex gap-x-5">
                <button
                  className="btn-primary"
                  onClick={() => {
                    handleParams('groupId', groupId);
                    handleParams('facilityId', facilityId);
                    handleParams('period', period);
                    handleParams('status', status);
                    if (value) {
                      params.set('dateFrom', String(dayjs(value[0]).format(ISO_DATE)));
                      params.set('dateTo', String(dayjs(value[1]).format(ISO_DATE)));
                    } else {
                      if (params.has('dateFrom') && params.has('dateTo')) {
                        params.delete('dateFrom');
                        params.delete('dateTo');
                      }
                    }
                    setParams(params);
                    setShowFilter(false);
                  }}
                >
                  Применить
                </button>
                <button
                  className="btn-stroke"
                  onClick={() => {
                    setValue(null);
                    setGroupId(null);
                    setFacilityId(null);
                    setPeriod(null);
                    setStatus(null);
                    setParams();
                    setShowFilter(false);
                  }}
                >
                  Сбросить
                </button>
              </div>
            </TableFilter>
          </div>
        )}
        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!(dateFrom || dateTo)}
              content={dayjs(dateFrom).format(RU_DATE) + ' - ' + dayjs(dateTo).format(RU_DATE)}
              clickHandler={() => {
                setValue(null);
                params.delete('dateFrom');
                params.delete('dateTo');
                params.delete('page');
                setParams(params);
              }}
            />
            <Filter
              show={!!facilityGroupIdParam}
              content={'Группа ' + facilityGroupName}
              clickHandler={() => {
                setGroupId(null);
                resetFilter('groupId');
              }}
            />
            <Filter
              show={!!facilityParam}
              content={facility?.name}
              clickHandler={() => {
                setFacilityId(null);
                resetFilter('facilityId');
              }}
            />
            <Filter
              show={!!periodTypeParam}
              content={getPeriodRu(periodTypeParam as PeriodType)}
              clickHandler={() => {
                setPeriod(null);
                resetFilter('period');
              }}
            />
            <Filter
              show={!!statusParam}
              content={getModerationStatusRu(statusParam as ModerationStatus)}
              clickHandler={() => {
                setStatus(null);
                resetFilter('status');
              }}
            />
          </div>
        )}
      </div>
      <div className="flex self-start">
        <Link to="/moderation/create">
          <button type="button" className="btn-primary_big flex items-center">
            <span className="inline-flex mr-2">
              <Plus className="text-smena_white" />
            </span>
            Новый период
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ModerationReportsFilter;
