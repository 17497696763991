import clsx from 'clsx';
import { TIcon } from 'interfaces/TIcon';

const ChevronDown = ({ className }: TIcon) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29289 5.29289C3.68342 4.90237 4.31658 4.90237 4.70711 5.29289L8 8.58579L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L8.70711 10.7071C8.31658 11.0976 7.68342 11.0976 7.29289 10.7071L3.29289 6.70711C2.90237 6.31658 2.90237 5.68342 3.29289 5.29289Z"
      />
    </svg>
  );
};

export default ChevronDown;
