import React, { useCallback, useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { ChartType, StatisticPeriod } from 'generated/graphql';
import SimpleSelect from 'ui/select';
import { chartTimes } from 'utils/helpers/lists/chartTimes';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { tickFormatterHandlerFn } from 'utils/helpers/charts/tickFormatter';
import { chartClick, chartHover } from 'utils/helpers/charts/chartHandlers';
import { labelFormatterHandler } from 'utils/helpers/charts/getLabelFormaterCharts';
import { intervalChart } from 'utils/helpers/charts/intervalChart';
import { ITooltipStatus } from 'interfaces/ITooltipStatus';
import Skeleton from 'antd/es/skeleton';
import { useResponseArray } from './hooks';
import { useStore } from 'store';
import { useIsSendRequest } from '../hooks';
import { useGetStatisticLazyQuery } from 'pages/main/api/queries/generated/GetStatistic';

export const AllUsersChart = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const [time, setTime] = useState(StatisticPeriod.Month);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });
  const isSendRequest = useIsSendRequest();

  const [loadData, { data, loading }] = useGetStatisticLazyQuery();
  const arr = useResponseArray(data);

  const handleTimeChange = useCallback((value: string) => {
    setTime(value as StatisticPeriod);
  }, []);

  const tickFormatterHandler = useCallback(
    (value: any) => {
      if (value && value !== 'auto') {
        return tickFormatterHandlerFn(value, time);
      }
      return '0';
    },
    [time],
  );

  useEffect(() => {
    if (!isSendRequest) return;
    loadData({
      variables: {
        input: {
          facilityId: trendsFacilityId,
          facilityGroupId: trendsGroupId,
          period: time,
          types: [ChartType.CountWorkers, ChartType.CountForemen, ChartType.CountSupervisors],
        },
      },
    });
  }, [isSendRequest, loadData, time, trendsFacilityId, trendsGroupId]);

  return (
    <div className={'row-span-1 grid gap-y-[5px]'}>
      <div className="grid grid-cols-[auto,_auto] justify-between items-center">
        <span className="Subtitle2 text-smena_text-secondary">Пользователи</span>
        <SimpleSelect
          divClassName="chart-filter"
          label=""
          onChange={handleTimeChange}
          value={time}
          sort={false}
          options={chartTimes}
        />
      </div>
      <div className="flex gap-x-1 items-end opacity-0 invisible">
        {loading ? <Skeleton.Button active /> : <span className="ChartNumber text-smena_text">0</span>}
        <span className="Caption text-smena_text-secondary">0</span>
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="h-[240px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={arr}
              margin={{ right: 30, bottom: 5 }}
              onClick={state => {
                if (state !== null) {
                  if (tooltipStatus.barId === state.activeTooltipIndex) {
                    setTooltipStatus(chartHover(state));
                  } else {
                    setTooltipStatus(chartClick(state));
                  }
                }
              }}
            >
              <XAxis
                dataKey="createdAt"
                interval={intervalChart(time)}
                tickFormatter={tickFormatterHandler}
                height={30}
                angle={-25}
                tickMargin={10}
              />
              <YAxis />
              <Tooltip
                cursor={false}
                trigger={tooltipStatus.status}
                labelFormatter={(label: string) => labelFormatterHandler({ label, time })}
                formatter={(value: string, name: string) => {
                  const ruName = getRoleRu(name.toUpperCase());
                  return [value, ruName];
                }}
                separator={':'}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Area type="monotone" dataKey="worker" stroke="#FF9EB5" fill="#FF9EB540" />
              <Area type="monotone" dataKey="foreman" stroke="#FF7A99" fill="#FF7A9940" />
              <Area type="monotone" dataKey="supervisor" stroke="#FE426E" fill="#FE426E40" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
