import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ReportFragmentDoc } from '../../fragments/generated/Report.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTimesheetByMonthAndFacilityGroupMutationVariables = Types.Exact<{
  input: Types.CreateTimesheetByMonthAndFacilityGroupInput;
}>;


export type CreateTimesheetByMonthAndFacilityGroupMutation = { __typename?: 'Mutation', createTimesheetByMonthAndFacilityGroup: { __typename?: 'Report', id: string, type: Types.ReportType, filePath: string } };


export const CreateTimesheetByMonthAndFacilityGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTimesheetByMonthAndFacilityGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateTimesheetByMonthAndFacilityGroupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTimesheetByMonthAndFacilityGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Report"}}]}}]}},...ReportFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateTimesheetByMonthAndFacilityGroupMutationFn = Apollo.MutationFunction<CreateTimesheetByMonthAndFacilityGroupMutation, CreateTimesheetByMonthAndFacilityGroupMutationVariables>;

/**
 * __useCreateTimesheetByMonthAndFacilityGroupMutation__
 *
 * To run a mutation, you first call `useCreateTimesheetByMonthAndFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimesheetByMonthAndFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimesheetByMonthAndFacilityGroupMutation, { data, loading, error }] = useCreateTimesheetByMonthAndFacilityGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimesheetByMonthAndFacilityGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimesheetByMonthAndFacilityGroupMutation, CreateTimesheetByMonthAndFacilityGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimesheetByMonthAndFacilityGroupMutation, CreateTimesheetByMonthAndFacilityGroupMutationVariables>(CreateTimesheetByMonthAndFacilityGroupDocument, options);
      }
export type CreateTimesheetByMonthAndFacilityGroupMutationHookResult = ReturnType<typeof useCreateTimesheetByMonthAndFacilityGroupMutation>;
export type CreateTimesheetByMonthAndFacilityGroupMutationResult = Apollo.MutationResult<CreateTimesheetByMonthAndFacilityGroupMutation>;
export type CreateTimesheetByMonthAndFacilityGroupMutationOptions = Apollo.BaseMutationOptions<CreateTimesheetByMonthAndFacilityGroupMutation, CreateTimesheetByMonthAndFacilityGroupMutationVariables>;