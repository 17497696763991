import AutoComplete from 'antd/es/auto-complete';
import { IOption } from 'interfaces/IOption';
import { useMemo } from 'react';
import { clsx } from 'clsx';

interface IAutocompleteBlock {
  id: string;
  label: string;
  onChangeHandler: (value: IOption | null) => void;
  options?: IOption[];
  buttonHandler: () => void;
  loading: boolean;
  value?: string;
  className?: string;
}

const AutocompleteBlock = ({
  label,
  id,
  onChangeHandler,
  options,
  buttonHandler,
  loading,
  value,
  className,
}: IAutocompleteBlock) => {
  const autocompleteOptions = useMemo(() => options?.map(el => ({ label: el.name, value: el.id })), [options]);

  return (
    <div className={clsx(className, 'sm:col-span-4 col-span-full grid sm:grid-cols-4 grid-cols-5 sm:mb-0 mb-2')}>
      <div className="grid col-span-3 gap-y-1">
        <label htmlFor={id} className="label-primary">
          {label}
        </label>
        <AutoComplete
          popupMatchSelectWidth={false}
          className={'shadow-none Body2'}
          options={autocompleteOptions}
          placeholder="Не выбрано"
          filterOption={(inputValue, option) => option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          onChange={(_, option) => {
            const isArray = Array.isArray(option);
            if (isArray) {
              return;
            }
            onChangeHandler({ id: option.value, name: option.label });
          }}
          value={value}
        />
      </div>
      <div className="self-end sm:col-span-1 col-span-2 ml-2">
        <button className="btn-secondary_small w-full" onClick={buttonHandler} disabled={loading} type="button">
          <span>Добавить</span>
        </button>
      </div>
    </div>
  );
};

export default AutocompleteBlock;
