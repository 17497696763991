import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { TIME_DATE } from 'utils/helpers/constVariables';

import ChevronDown from 'assets/icons/chevron-down';
import { NotificationFragment } from 'graphql/fragments/generated/Notification.fragment';
import { useReadNotificationMutation } from 'graphql/mutations/generated/ReadNotification';
import { useMarkAsNewNotificationMutation } from 'graphql/mutations/generated/MarkAsNewNotification';
import { useNotificationsStore } from '../store/notificationsStore';

interface Props {
  notification: NotificationFragment;
  groupNotifications: NotificationFragment[];
  notificationRef?: React.LegacyRef<HTMLDivElement>;
}

const Notification: React.FC<Props> = ({ notification, groupNotifications, notificationRef }) => {
  const { isRead, createdAt, id, body, title, route, routeId } = notification;
  const { newCount, readCount, setNotificationReadOpposite, setNewCount, setReadCount } = useNotificationsStore();

  const [readNotification] = useReadNotificationMutation();
  const [markAsNew] = useMarkAsNewNotificationMutation();
  const markAsNewHandler = () => {
    markAsNew({
      variables: {
        id,
      },
    }).then(res => {
      if (res.data?.markAsNewNotification) {
        setNotificationReadOpposite(id);
        setNewCount(newCount + 1);
        setReadCount(readCount - 1);
      }
    });
  };

  const readNotificationHandler = (isRead: boolean) => {
    readNotification({
      variables: {
        id,
      },
    }).then(res => {
      if (res.data?.readNotification) {
        setNotificationReadOpposite(id);
        if (!isRead) {
          setReadCount(readCount + 1);
          setNewCount(newCount - 1);
        }
      }
    });
  };
  const dateTitle = useMemo(() => {
    if (dayjs(createdAt).isToday()) {
      if (groupNotifications[0].id === id) {
        return 'Сегодня, ' + dayjs(createdAt).format('DD MMMM');
      }
      return false;
    }
    if (dayjs(createdAt).isYesterday()) {
      if (groupNotifications[0].id === id) {
        return 'Вчера, ' + dayjs(createdAt).format('DD MMMM');
      }
      return false;
    }
    if (groupNotifications[0].id === id) {
      return dayjs(createdAt).format('DD MMMM');
    }
    return false;
  }, [createdAt, id, groupNotifications]);
  return (
    <div ref={notificationRef}>
      {dateTitle && <div className={'Subtitle2 py-3'}>{dateTitle}</div>}
      <div className="flex gap-x-2 items-baseline border-b border-smena_gray-30" key={id}>
        {!isRead && <span className="rounded-full wait_worker_schedule min-h-[8px] min-w-[8px]"></span>}
        <div className={clsx('flex flex-col py-2 w-full', isRead && 'ml-4')}>
          <div className="flex flex-col Body1 text-smena_gray-100">
            <span>{title}</span>
            <span>{body}</span>
            <div className="flex gap-x-1 text-smena_text-helper Body2 mt-1">
              <span>{dayjs(createdAt).format('DD MMM, YYYY')}</span>
              <span>·</span>
              <span>{dayjs(createdAt).format(TIME_DATE)}</span>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-3 pt-2 pb-4">
            {isRead && (
              <button className="Button3 text-primary" onClick={markAsNewHandler}>
                Отметить как новое
              </button>
            )}
            <Link
              to={`/${route}/${routeId}`}
              className="Button3 text-primary flex items-center"
              onClick={() => {readNotificationHandler(isRead)}}
            >
              Подробнее
              <span className={'inline-flex -rotate-90'}>
                <ChevronDown className={'text-primary'} />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
