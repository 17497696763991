import React from 'react';
import { TIcon } from 'interfaces/TIcon';

const TableSettings = ({ className }: TIcon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3C4.79086 3 3 4.79086 3 7C3 9.20914 4.79086 11 7 11C8.86658 11 10.4345 9.72147 10.8759 7.99238C10.9166 7.99741 10.958 8 11 8H20C20.5523 8 21 7.55228 21 7C21 6.44772 20.5523 6 20 6H11C10.958 6 10.9166 6.00259 10.8759 6.00762C10.4345 4.27853 8.86658 3 7 3ZM5 7C5 5.89543 5.89543 5 7 5C8.10457 5 9 5.89543 9 7C9 8.10457 8.10457 9 7 9C5.89543 9 5 8.10457 5 7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 17C4 16.4477 4.44772 16 5 16H13.126C13.5701 14.2748 15.1362 13 17 13C19.2091 13 21 14.7909 21 17C21 19.2091 19.2091 21 17 21C15.1362 21 13.5701 19.7252 13.126 18H5C4.44772 18 4 17.5523 4 17ZM17 19C15.8954 19 15 18.1046 15 17C15 15.8954 15.8954 15 17 15C18.1046 15 19 15.8954 19 17C19 18.1046 18.1046 19 17 19Z"
      />
    </svg>
  );
};

export default TableSettings;
