import React from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import TableHeader from './header';
import SkeletonLayout from './Skeleton';
import { useSearchParams } from 'react-router-dom';
import { IColumns } from 'interfaces/IColumns';

interface Props {
  data?: any[];
  loadingData: boolean;
  defaultSortBy?: any;
  columns: IColumns[];
  children: React.ReactNode;
  headerMenu?: boolean;
}

const Table = ({ data, defaultSortBy, columns, loadingData, headerMenu = false, children }: Props) => {
  const [params] = useSearchParams();
  const searchParam = params.get('search');
  return (
    <ScrollSync>
      <div className="grid relative overflow-y-auto">
        <TableHeader columns={columns} defaultSortBy={defaultSortBy} />
        {loadingData ? (
          <SkeletonLayout columns={columns} headerMenu={headerMenu} />
        ) : data?.length ? (
          <ScrollSyncPane>
            <>{children}</>
          </ScrollSyncPane>
        ) : (
          <div className="flex justify-center my-5">
            <h3 className="text-smena_text Body1">
              По вашему запросу <span className="font-bold">{searchParam}</span> ничего не нашлось
            </h3>
          </div>
        )}
      </div>
    </ScrollSync>
  );
};

export default Table;
