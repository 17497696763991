import { clsx } from 'clsx';

export const getNavLinkClassname = (isActive: boolean, menu: boolean) => {
  const isMenuOpen = menu ? 'rounded-lg' : 'rounded-full';

  return clsx(isActive ? 'nav-link_active' : 'nav-link', isMenuOpen);
};

export const getNavLinkClassNameMobile = (isActive: boolean, className?: string) => {
  return clsx('rounded-lg', isActive ? 'nav-link_active' : 'nav-link', className);
};
