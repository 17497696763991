import { useDeclineButton } from './hooks/declineHooks';
import { useCurrentRequest } from '../../../../utils/api/useCurrentRequest';

const DeclineButton: React.FC = () => {
  const { request } = useCurrentRequest();

  const { showDeclineButton, declineButtonText, declineButtonHandler, loading } = useDeclineButton();

  return (
    <>
      {showDeclineButton ? (
        <button
          className="btn-reject_secondary"
          type="button"
          onClick={declineButtonHandler}
          disabled={request?.zayavkaSchedule?.length === 0 || loading}
        >
          {declineButtonText}
        </button>
      ) : null}
    </>
  );
};
export default DeclineButton;
