import { DayjsOrNull, undefStr } from 'interfaces/CustomTypes';
import {
  useUpdateRequestScheduleMutation
} from '../../../../../facilityRequests/api/mutations/generated/UpdateRequestSchedule';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useStore } from 'store';
import { dateEndFn } from 'utils/helpers/dateEndFn';
import dateHour from '../../../../../userSchedule/components/sidebar/newSchedule/helpers/dateHour';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import dayjs from 'dayjs';

interface InitialValues {
  positionId: undefStr;
  scheduleStartTime: DayjsOrNull;
  scheduleEndTime: DayjsOrNull;
  scheduleStartDate: DayjsOrNull;
  scheduleEndDate: DayjsOrNull;
  lunchDuration: number;
  scheduleType?: string | null;
}

export const useEditScheduleForm = () => {
  const requestSchedule = useStore(state => state.requestSchedule);
  const setRequestSchedule = useStore(state => state.setRequestSchedule);
  const { request, id } = useCurrentRequest();
  const [updateRequestSchedule, { loading }] = useUpdateRequestScheduleMutation();
  const initialValues: InitialValues = {
    positionId: requestSchedule?.positionId,
    scheduleStartTime: requestSchedule?.newDateFrom
      ? dayjs(requestSchedule.newDateFrom)
      : dayjs(requestSchedule?.dateFrom),
    scheduleEndTime: requestSchedule?.newDateTo ? dayjs(requestSchedule.newDateTo) : dayjs(requestSchedule?.dateTo),
    scheduleStartDate: requestSchedule?.newDateFrom
      ? dayjs(requestSchedule.newDateFrom)
      : dayjs(requestSchedule?.dateFrom),
    scheduleEndDate: requestSchedule?.newDateTo ? dayjs(requestSchedule.newDateTo) : dayjs(requestSchedule?.dateTo),
    lunchDuration: requestSchedule?.newLunchDuration
      ? requestSchedule?.newLunchDuration
      : requestSchedule?.lunchDuration || 0,
    scheduleType: requestSchedule?.newScheduleTypeId
      ? requestSchedule?.newScheduleTypeId
      : requestSchedule?.scheduleTypeId,
  };

  const validate = (values: InitialValues) => {
    const errors: any = {};
    if (!values.positionId) {
      errors.positionId = 'Обязательное поле';
    }
    if (!values.scheduleStartTime) {
      errors.scheduleStartTime = 'Обязательное поле';
    }
    if (!values.scheduleEndTime) {
      errors.scheduleEndTime = 'Обязательное поле';
    }
    if (!values.scheduleStartDate) {
      errors.scheduleStartDate = 'Обязательное поле';
    }
    if (!values.scheduleEndDate) {
      errors.scheduleEndDate = 'Обязательное поле';
    }
    return errors;
  };

  const onSubmit = (values: InitialValues) => {
    const dateEnd = dateEndFn({
      date: values.scheduleEndDate,
      endTime: values.scheduleEndTime,
      startTime: values.scheduleStartTime,
    });
    const dateStart = dateHour(values.scheduleStartDate, values.scheduleStartTime);
    const shiftEndDateVariable = dateEndFn({
      date: values.scheduleStartDate,
      startTime: dateHour(values.scheduleStartDate, values.scheduleStartTime),
      endTime: dateHour(values.scheduleStartDate, values.scheduleEndTime),
    });
    if (!requestSchedule || !request?.facilityId || !id || !values.positionId) return;
    updateRequestSchedule({
      variables: {
        id: requestSchedule.id,
        input: {
          dateStart,
          dateEnd,
          facilityId: request.facilityId,
          positionId: values.positionId,
          zayavkaId: id,
          lunchDuration: values.lunchDuration,
          dayInterleavingId: values.scheduleType,
          shiftEndDate: shiftEndDateVariable,
        },
      },
    })
      .then(res => {
        if (!res.data?.updateZayavkaSchedule) return;
        setRequestSchedule(res.data.updateZayavkaSchedule);
        successToast('График обновлен');
      })
      .catch(e => errorToast(errorHandler(e)));
  };
  return { initialValues, validate, onSubmit, loading };
};
