import { clsx } from 'clsx';
import React, { FC, ReactNode } from 'react';
import Across from 'assets/icons/Across';
import OutsideClick from 'components/outsideComponent';

interface Sidebar {
  title: string;
  children: ReactNode;
  show: boolean;
  className?: string;
  titleClassName?: string;
  setShow: (show: boolean) => void;
  onClose?: () => void;
  triggerRef?: React.RefObject<HTMLElement>;
}

const MainSidebar: FC<Sidebar> = React.memo(
  ({ children, show, setShow, title, onClose, triggerRef, className, titleClassName }) => {
    return (
      <OutsideClick
        show={show}
        setShow={() => {
          setShow(false);
          if (onClose) {
            onClose();
          }
        }}
        className={clsx('sidebar shadow-smena', show ? 'right-0' : '-right-full invisible', className)}
        triggerRef={triggerRef}
      >
        <div className="flex justify-end relative">
          <span
            className="cursor-pointer mr-5"
            onClick={() => {
              setShow(false);
              if (onClose) {
                onClose();
              }
            }}
          >
            <Across className="text-smena_text" />
          </span>
        </div>
        <h3 className={clsx('H3 mb-6', titleClassName)}>{title}</h3>
        {children}
      </OutsideClick>
    );
  },
);

export default MainSidebar;
