import { Calendar } from 'pages/userTracking/components';
import MainLayout from 'components/layout/main';
import useUserBreadcrumbs from 'utils/hooks/breadcrumbs/useUserBreadcrumbs';
import { useUserHeaderMenu } from 'utils/hooks/headerMenu/useUserHeaderMenu';
import { useCurrentUser } from 'utils/api/useUser';
import { useUserTitle } from '../user/lib/hooks';

const ShiftsPage = () => {
  const { loading } = useCurrentUser();
  const title = useUserTitle();

  const headerMenu = useUserHeaderMenu();

  const breadCrumbs = useUserBreadcrumbs();

  return (
    <MainLayout title={title} loading={loading} headerMenu={headerMenu} breadCrumbs={breadCrumbs}>
      <Calendar />
    </MainLayout>
  );
};

export default ShiftsPage;
