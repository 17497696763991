import { useState } from 'react';
import { clsx } from 'clsx';

import Across from 'assets/icons/Across';
import { payoutTypes } from 'utils/helpers/lists/payoutTypes';
import { allUnits } from 'utils/helpers/lists/units';
import { useMeRole } from 'store/hooks/useMeRole';
import { useUpdateProfession } from './hooks';
import { usePositions } from 'utils/api/usePositions';
import { positionName } from 'utils/helpers/filters/filtersName';
import RemovePopup from './removePopup';
import { Link } from 'react-router-dom';
import { Role } from 'generated/graphql';
import { useGetCurrentSelfEmployedTaxQuery } from 'pages/facilityGroupCasing/api/query/generated/GetCurrentSelfEmployedTax';
import CalendarOutlined from 'assets/icons/CalendarOutlined';
import Tooltip from 'antd/lib/tooltip';
import dayjs from 'dayjs';

interface Props {
  casing: any;
}

const CasingRow = ({ casing }: Props) => {
  const { data } = useGetCurrentSelfEmployedTaxQuery();
  const { positions } = usePositions();
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const { removeCasingLoading } = useUpdateProfession({
    casing,
  });
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  return (
    <div className={clsx('grid px-6 Body2 text-smena_text-secondary w-fit add_profession-facility')}>
      <Link className="pt-3 link" to={`${casing.id}`}>
        {positionName(positions, casing.positionId)}
      </Link>
      <span className="py-3">{payoutTypes.find(type => type.id === casing.payout)?.name}</span>
      <span className="py-3">{allUnits.find(unit => unit.id === casing.unit)?.name}</span>
      <span className="py-3 flex gap-2 items-center">
        {casing?.currentRate?.rate} ₽
        <Tooltip
          title={
            casing?.plannedRate
              ? `C ${dayjs(casing.plannedRate.startDatetime).format('DD.MM.YYYY')} ставка изменится
 и составит ${casing.plannedRate.rate} ₽`
              : ''
          }
        >
          {casing?.plannedRate && (
            <span className="text-primary cursor-pointer">
              <CalendarOutlined />
            </span>
          )}
        </Tooltip>
      </span>
      <span className="py-3 pr-4 pl-4 h-full mr-2 ml-2 bg-smena_bb-background_base">
        {casing?.isSelfEmployed && casing?.currentRate?.rate && data?.getCurrentSelfEmployedTax
          ? Math.round((casing?.currentRate?.rate / Number(data.getCurrentSelfEmployedTax)) * 100) / 100 + ' ₽'
          : null}
      </span>
      <span className="py-3">{casing.hasProbation ? 'Да' : 'Нет'}</span>
      <span className="py-3 h-full mr-2">{casing.probationPeriod}</span>
      <span className="py-3 flex gap-2 items-center">
        {casing?.currentProbationRate?.rate ? casing?.currentProbationRate?.rate + ' ₽' : null}
        {casing?.currentProbationRate?.rate && (
          <Tooltip
            title={
              casing?.plannedProbationRate
                ? `C ${dayjs(casing.plannedProbationRate.startDatetime).format('DD.MM.YYYY')} ставка изменится
и составит ${casing.plannedProbationRate.rate} ₽`
                : ''
            }
          >
            {casing?.plannedProbationRate && (
              <span className="text-primary cursor-pointer">
                <CalendarOutlined />
              </span>
            )}
          </Tooltip>
        )}
      </span>
      <span className="py-3 pl-4 pr-4 mr-2 ml-2 bg-smena_bb-background_base">
        {casing?.isSelfEmployed && casing?.currentProbationRate?.rate && data?.getCurrentSelfEmployedTax
          ? Math.round((casing?.currentProbationRate?.rate / Number(data.getCurrentSelfEmployedTax)) * 100) / 100 + ' ₽'
          : null}
      </span>
      <span className="py-3">{casing.isMedBookRequired ? 'Да' : 'Нет'}</span>
      {isMeAdminOrSupervisor && (
        <div className="flex pl-4 pr-4 gap-x-4 pt-2 h-full">
          <button
            className="inline-flex transform hover:scale-110"
            type="button"
            disabled={removeCasingLoading}
            onClick={() => {
              setShowRemovePopup(value => !value);
            }}
          >
            <Across className={'text-smena_red-mark'} />
          </button>
        </div>
      )}
      <RemovePopup casing={casing} show={showRemovePopup} setShow={setShowRemovePopup} />
    </div>
  );
};

export default CasingRow;
