import clsx from 'clsx';
import { TIcon } from 'interfaces/TIcon';

const PencilIcon = ({ className }: TIcon) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      // fill="none"
      className={clsx('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.56215 1.41847C9.1201 0.860511 10.0247 0.860511 10.5827 1.41847C11.1406 1.97642 11.1406 2.88105 10.5827 3.439L10.0163 4.00542L7.99573 1.98488L8.56215 1.41847Z" />
      <path d="M6.98546 2.99515L1 8.98061V11.0012H3.02054L9.006 5.01569L6.98546 2.99515Z" />
    </svg>
  );
};
export default PencilIcon;
