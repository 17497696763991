import Select from 'antd/es/select';
import clsx from 'clsx';
import React from 'react';
import { sortObj } from 'utils/helpers/sortObject';
import { ErrorNotification } from '../errorNotification';
import ChevronDown from 'assets/icons/chevron-down';
import { useMe } from 'utils/api/useMe';
import Skeleton from 'antd/es/skeleton';
import { HelperText } from '../helperText';

const { Option } = Select;

type FormInput = {
  divClassName?: string;
  divStyle?: any;
  label?: string;
  onChange: (value: string) => void | undefined;
  onBlur?: () => void;
  sort?: boolean;
  allOption?: string;
  disabledOption?: string;
  placeholder?: string;
  value?: any;
  options?: any[] | null;
  error?: any;
  helperText?: string;
  disabled?: boolean;
  validation?: boolean;
  showText?: boolean;
  open?: boolean;
  loading?: boolean;
  listHeight?: number;
  placement?: 'topLeft' | 'bottomLeft' | 'bottomRight' | 'topRight';
  popupClassName?: string;
  showSearch?: boolean;
};

const SimpleSelect = React.memo(
  ({
    divClassName,
    divStyle,
    label,
    onChange,
    sort = true,
    showSearch = true,
    onBlur,
    allOption,
    placeholder,
    value,
    options,
    error,
    helperText,
    disabled,
    showText,
    open,
    popupClassName,
    placement,
    listHeight,
    loading,
  }: FormInput) => {
    const me = useMe();
    const finalOptions = options && options.length ? (sort ? sortObj(options) : options) : null;
    return (
      <>
        {loading ? (
          <div className={clsx(divClassName, 'flex flex-col gap-y-1 justify-center')}>
            {Boolean(label) && <label className="label-primary">{label}</label>}
            <Skeleton.Input active />
          </div>
        ) : me && options && showText ? (
          <div className={clsx('flex flex-col gap-y-0.5', divClassName, 'relative')}>
            {Boolean(label) && <span className="Subtitle2 mb-0.5">{label}</span>}
            <span className={'Body2 text-smena_text-secondary'}>
              {value ? options.find(option => option.id === value)?.name : 'Отсутствует'}
            </span>
          </div>
        ) : (
          <div className={clsx('flex flex-col gap-y-0.5 relative', divClassName)} style={divStyle}>
            {Boolean(label) && <label className="label-primary">{label}</label>}
            <Select
              suffixIcon={<ChevronDown />}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={disabled}
              status={error ? 'error' : ''}
              placeholder={<span className={'Body2 text-smena_text-helper'}>{placeholder}</span>}
              popupMatchSelectWidth={false}
              popupClassName={popupClassName}
              listHeight={listHeight}
              open={open}
              // allowClear
              placement={placement}
              showSearch={showSearch}
              filterOption={(input, option: any) => {
                if (showSearch) {
                  return option.children.toLowerCase().includes(input.toLowerCase());
                }
                return true;
              }}
              className={clsx(disabled ? 'bg-smena_gray-5' : '', error ? 'select-error' : 'select-primary')}
              bordered={false}
            >
              {allOption ? (
                <Option key={undefined} value={undefined}>
                  {allOption}
                </Option>
              ) : null}

              {finalOptions?.map(option => (
                <Option key={option.id} value={option.id} disabled={option.disabled}>
                  {option.name}
                </Option>
              ))}
            </Select>
            {error && <ErrorNotification text={error} />}
            {helperText && !error && <HelperText text={helperText} />}
          </div>
        )}
      </>
    );
  },
);

export default SimpleSelect;
