import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityRequestSchedulesFragmentDoc } from '../../../../facilityRequests/api/fragments/generated/FacilityRequestSchedules.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeclineRequestShiftsSuggestionsMutationVariables = Types.Exact<{
  input: Types.DeclineRequestShiftSuggestionsInput;
}>;


export type DeclineRequestShiftsSuggestionsMutation = { __typename?: 'Mutation', declineRequestShiftsSuggestions: { __typename?: 'ZayavkaSchedule', authorId: string, createdAt: any, dateAccept?: any | null, dateFrom: any, dateTo: any, dayInterleaving?: number | null, facilityId: string, id: string, lunchDuration?: number | null, positionId: string, timesOfDay: Types.ShiftTime, scheduleId?: string | null, updatedAt: any, zayavkaId: string, newDateFrom?: any | null, newDateTo?: any | null, scheduleTypeId?: string | null, newScheduleTypeId?: string | null, newDayInterleaving?: number | null, newLunchDuration?: number | null, newShiftDuration?: number | null, isDeleted: boolean, isEdited: boolean, isNew: boolean, position?: { __typename?: 'Position', id: string, name: string } | null, zayavkaShift: Array<{ __typename?: 'ZayavkaShift', id: string, authorId: string, createdAt: any, dateEnd: any, dateFrom: any, facilityId: string, duration: number, lunchDuration?: number | null, payout?: Types.PayoutType | null, positionId: string, shiftId?: string | null, isFree: boolean, zayavkaScheduleId: string, unit?: Types.UnitType | null, updatedAt: any, zayavkaId: string, newDateFrom?: any | null, newDateEnd?: any | null, newLunchDuration?: number | null, isDeleted: boolean, isEdited: boolean, isNew: boolean, requestShiftSuggestions: Array<{ __typename?: 'RequestShiftSuggestionEntity', status: Types.ZayavkaShiftSuggestionsStatusEnum, id: string, user: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null } }>, shift?: { __typename?: 'Shift', id: string, stage: Types.ShiftStage, userId: string, isProbation: boolean, user?: { __typename?: 'User', id: string, lastname: string, firstname: string, patronymic?: string | null } | null } | null }> } };


export const DeclineRequestShiftsSuggestionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclineRequestShiftsSuggestions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeclineRequestShiftSuggestionsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineRequestShiftsSuggestions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityRequestSchedules"}}]}}]}},...FacilityRequestSchedulesFragmentDoc.definitions]} as unknown as DocumentNode;
export type DeclineRequestShiftsSuggestionsMutationFn = Apollo.MutationFunction<DeclineRequestShiftsSuggestionsMutation, DeclineRequestShiftsSuggestionsMutationVariables>;

/**
 * __useDeclineRequestShiftsSuggestionsMutation__
 *
 * To run a mutation, you first call `useDeclineRequestShiftsSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineRequestShiftsSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineRequestShiftsSuggestionsMutation, { data, loading, error }] = useDeclineRequestShiftsSuggestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineRequestShiftsSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<DeclineRequestShiftsSuggestionsMutation, DeclineRequestShiftsSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineRequestShiftsSuggestionsMutation, DeclineRequestShiftsSuggestionsMutationVariables>(DeclineRequestShiftsSuggestionsDocument, options);
      }
export type DeclineRequestShiftsSuggestionsMutationHookResult = ReturnType<typeof useDeclineRequestShiftsSuggestionsMutation>;
export type DeclineRequestShiftsSuggestionsMutationResult = Apollo.MutationResult<DeclineRequestShiftsSuggestionsMutation>;
export type DeclineRequestShiftsSuggestionsMutationOptions = Apollo.BaseMutationOptions<DeclineRequestShiftsSuggestionsMutation, DeclineRequestShiftsSuggestionsMutationVariables>;