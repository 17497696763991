import React from 'react';
import MainLayout from '../../components/layout/main';
import StoriesFilter from './components/StoriesFilter';
import CustomPagination from '../../ui/pagination';
import { useGetStoryPaginationListQuery } from './api/queries/generated/GetStoryPaginationList';
import { useSearchParams } from 'react-router-dom';
import { SortingWay, StoryFieldsSorting, StoryStage } from 'generated/graphql';
import { useStoriesTable } from '../../components/table/columns/stories';
import NewTable from '../../components/table/newTable';
import { StoryFragment } from '../story/api/fragments/generated/Strory.fragment';

const StoriesPage = () => {
  const [params] = useSearchParams();
  const searchQuery = params.get('search');
  const stage = params.get('stage');
  const sortWay = params.get('sortWay');
  const sortBy = params.get('sortBy');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const { data, loading, error } = useGetStoryPaginationListQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        searchQuery,
        sortWay: sortWay as SortingWay,
        sortBy: sortBy as StoryFieldsSorting,
        stage: stage as StoryStage,
        size,
      },
    },
  });
  const storiesPaginated = data?.getStoryPaginationList;
  const stories = storiesPaginated?.stories || [];
  const table = useStoriesTable(stories);
  return (
    <MainLayout title="Истории" breadCrumbs={[{ path: '/stories', name: 'Истории' }]} error={error}>
      <div className="flex flex-col shadow-smena rounded bg-smena_white">
        <StoriesFilter table={table} />
        <NewTable<StoryFragment> table={table} length={stories.length} loading={loading} headerMenu={true} />
        {storiesPaginated && <CustomPagination total={storiesPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default StoriesPage;
