import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityRequestSchedulesFragmentDoc } from '../../../../facilityRequests/api/fragments/generated/FacilityRequestSchedules.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptRequestShiftsSuggestionsMutationVariables = Types.Exact<{
  input: Types.AcceptRequestShiftsSuggestionsInput;
}>;


export type AcceptRequestShiftsSuggestionsMutation = { __typename?: 'Mutation', acceptRequestShiftsSuggestions: { __typename?: 'ZayavkaSchedule', authorId: string, createdAt: any, dateAccept?: any | null, dateFrom: any, dateTo: any, dayInterleaving?: number | null, facilityId: string, id: string, lunchDuration?: number | null, positionId: string, timesOfDay: Types.ShiftTime, scheduleId?: string | null, updatedAt: any, zayavkaId: string, newDateFrom?: any | null, newDateTo?: any | null, scheduleTypeId?: string | null, newScheduleTypeId?: string | null, newDayInterleaving?: number | null, newLunchDuration?: number | null, newShiftDuration?: number | null, isDeleted: boolean, isEdited: boolean, isNew: boolean, position?: { __typename?: 'Position', id: string, name: string } | null, zayavkaShift: Array<{ __typename?: 'ZayavkaShift', id: string, authorId: string, createdAt: any, dateEnd: any, dateFrom: any, facilityId: string, duration: number, lunchDuration?: number | null, payout?: Types.PayoutType | null, positionId: string, shiftId?: string | null, isFree: boolean, zayavkaScheduleId: string, unit?: Types.UnitType | null, updatedAt: any, zayavkaId: string, newDateFrom?: any | null, newDateEnd?: any | null, newLunchDuration?: number | null, isDeleted: boolean, isEdited: boolean, isNew: boolean, requestShiftSuggestions: Array<{ __typename?: 'RequestShiftSuggestionEntity', status: Types.ZayavkaShiftSuggestionsStatusEnum, id: string, user: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null } }>, shift?: { __typename?: 'Shift', id: string, stage: Types.ShiftStage, userId: string, isProbation: boolean, user?: { __typename?: 'User', id: string, lastname: string, firstname: string, patronymic?: string | null } | null } | null }> } };


export const AcceptRequestShiftsSuggestionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptRequestShiftsSuggestions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptRequestShiftsSuggestionsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptRequestShiftsSuggestions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityRequestSchedules"}}]}}]}},...FacilityRequestSchedulesFragmentDoc.definitions]} as unknown as DocumentNode;
export type AcceptRequestShiftsSuggestionsMutationFn = Apollo.MutationFunction<AcceptRequestShiftsSuggestionsMutation, AcceptRequestShiftsSuggestionsMutationVariables>;

/**
 * __useAcceptRequestShiftsSuggestionsMutation__
 *
 * To run a mutation, you first call `useAcceptRequestShiftsSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptRequestShiftsSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptRequestShiftsSuggestionsMutation, { data, loading, error }] = useAcceptRequestShiftsSuggestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptRequestShiftsSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptRequestShiftsSuggestionsMutation, AcceptRequestShiftsSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptRequestShiftsSuggestionsMutation, AcceptRequestShiftsSuggestionsMutationVariables>(AcceptRequestShiftsSuggestionsDocument, options);
      }
export type AcceptRequestShiftsSuggestionsMutationHookResult = ReturnType<typeof useAcceptRequestShiftsSuggestionsMutation>;
export type AcceptRequestShiftsSuggestionsMutationResult = Apollo.MutationResult<AcceptRequestShiftsSuggestionsMutation>;
export type AcceptRequestShiftsSuggestionsMutationOptions = Apollo.BaseMutationOptions<AcceptRequestShiftsSuggestionsMutation, AcceptRequestShiftsSuggestionsMutationVariables>;