import { ModerationStatus } from 'generated/graphql';

export const moderationStatus: ModerationStatus[] = [ModerationStatus.InWork, ModerationStatus.Sent];

export const getModerationStatusRu = (status: ModerationStatus) => {
  const statuses = [
    {
      status: ModerationStatus.InWork,
      statusRu: 'В работе',
    },
    {
      status: ModerationStatus.Sent,
      statusRu: 'Отправлена',
    },
  ];
  const statusRu = statuses.find(el => el.status === status);
  if (!statusRu) return '';
  return statusRu.statusRu;
};
