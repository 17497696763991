import React, { useMemo } from 'react';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import { PushFormProps, usePushForm } from '../../utils/hooks/usePushForm';
import { FormikState } from 'formik';
import { pushRepeats } from '../../../pushes/utils/constants';
import SimpleSelect from 'ui/select';
import ExclamationMark12 from 'assets/icons/ExclamationMark12';
import { usePush } from 'utils/api/usePush';
import SimpleCheckbox from 'ui/checkbox';

interface Props {
  values: PushFormProps;
  setFieldValue: (name: string, value: any) => void;
  errors: FormikState<PushFormProps>['errors'];
}

const PushShowTime = ({ values, setFieldValue, errors }: Props) => {
  const { isPushPublished, isPushRemoved, isPushPending } = usePush();
  const { onSave } = usePushForm();
  const options = useMemo(() => {
    if (isPushPublished) {
      return pushRepeats.filter(el => el.id);
    }
    return pushRepeats;
  }, [isPushPublished]);
  const disabled = isPushPublished || isPushRemoved || isPushPending;
  return (
    <div className={'grid gap-y-6'}>
      <span className={'Subtitle1 text-smena_text-secondary'}>Когда отправлять</span>
      <div>
        <div className={'flex gap-x-6'}>
          <DatePickerInput
            divClassName={'w-[200px]'}
            value={values.date}
            label={'Дата начала'}
            onChange={date => {
              setFieldValue('date', date);
              onSave({ ...values, date });
            }}
            disabled={disabled}
            error={errors.date ? errors.date : ''}
          />
          <TimePickerInput
            label={'Время начала'}
            className={'w-[200px]'}
            value={values.time}
            onChange={time => {
              setFieldValue('time', time);
              onSave({ ...values, time });
            }}
            disabled={disabled}
            error={errors.time ? errors.time : ''}
          />
          <SimpleSelect
            divClassName="w-[200px]"
            label="Повтор"
            onChange={value => {
              setFieldValue('interval', value);
            }}
            sort={false}
            placeholder={'Не выбрано'}
            value={values.interval}
            options={options}
            placement={'topLeft'}
            disabled={disabled}
          />
        </div>
        <div className={'flex gap-x-6 items-center'}>
          <DatePickerInput
            divClassName={'w-[200px]'}
            value={values.dateEnd}
            label={'Дата завершения'}
            onChange={date => {
              setFieldValue('dateEnd', date);
              onSave({ ...values, dateEnd: date });
            }}
            disabled={values.indefinitely || disabled}
            error={errors.dateEnd ? errors.dateEnd : ''}
          />
          <TimePickerInput
            label={'Время завершения'}
            className={'w-[200px]'}
            value={values.timeEnd}
            onChange={time => {
              setFieldValue('timeEnd', time);
              onSave({ ...values, timeEnd: time });
            }}
            disabled={values.indefinitely || disabled}
            error={errors.timeEnd ? errors.timeEnd : ''}
          />
          <SimpleCheckbox
            divClassName={'flex items-center'}
            label={'Бессрочно'}
            name={'indefinitely'}
            onChange={e => {
              setFieldValue('indefinitely', e.target.checked);
              onSave({ ...values, indefinitely: e.target.checked });
            }}
            checked={values.indefinitely}
            disabled={disabled}
          />
        </div>
        <div className={'flex gap-x-1 items-center Caption-small text-smena_text-secondary'}>
          <ExclamationMark12 />
          <span>Сообщения отправляются в течении 30 минут от выбранного времени отправки. </span>
        </div>
      </div>
    </div>
  );
};

export default PushShowTime;
