import { create } from 'zustand';

type FacilityGroupFacilitiesState = {
  showLegalEntityOptionsPopup: boolean;
  setShowLegalEntityOptionsPopup: (value: boolean) => void;
  showChangeLegalEntityPopup: boolean;
  setShowChangeLegalEntityPopup: (value: boolean) => void;
  newLegalEntity?: string;
  setNewLegalEntity: (value?: string) => void;
  showStatusOptionsPopup: boolean;
  setShowStatusOptionsPopup: (value: boolean) => void;
  showChangeStatusPopup: boolean;
  setShowChangeStatusPopup: (value: boolean) => void;
  newStatus: boolean;
  setNewStatus: (value: boolean) => void;
};

export const useFacilityGroupFacilitiesStore = create<FacilityGroupFacilitiesState>(set => ({
  showLegalEntityOptionsPopup: false,
  setShowLegalEntityOptionsPopup: showLegalEntityOptionsPopup => set({ showLegalEntityOptionsPopup }),
  showChangeLegalEntityPopup: false,
  setShowChangeLegalEntityPopup: showChangeLegalEntityPopup => set({ showChangeLegalEntityPopup }),
  newLegalEntity: undefined,
  setNewLegalEntity: newLegalEntity => set({ newLegalEntity }),
  showStatusOptionsPopup: false,
  setShowStatusOptionsPopup: showStatusOptionsPopup => set({ showStatusOptionsPopup }),
  showChangeStatusPopup: false,
  setShowChangeStatusPopup: showChangeStatusPopup => set({ showChangeStatusPopup }),
  newStatus: false,
  setNewStatus: newStatus => set({ newStatus }),
}));
