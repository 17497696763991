import { toast } from 'react-toastify';
import { useStore } from 'store';

interface Props {
  setPopup: (value: boolean) => void;
}
const CustomScheduleButtons = ({ setPopup }: Props) => {
  const setCustomScheduleEvents = useStore(state => state.setCustomScheduleEvents);
  return (
    <div className="w-[175px] flex flex-col gap-y-2">
      <button
        className="btn-secondary_big"
        onClick={() => {
          setPopup(true);
          toast.dismiss('clickYourDays');
        }}
      >
        Сохранить
      </button>
      <button
        className="btn-stroke_big w-full"
        onClick={() => {
          setCustomScheduleEvents([]);
        }}
      >
        Сбросить все
      </button>
    </div>
  );
};

export default CustomScheduleButtons;
