import React, { useRef, useState } from 'react';
// import clsx from 'clsx';
import ThreeDots24 from 'assets/icons/ThreeDots24';
import OutsideClick from '../../../outsideComponent';
import StoryPopup from 'pages/stories/components/StoryPopup';
import { StoryFragment } from 'pages/story/api/fragments/generated/Strory.fragment';

interface Props {
  story: StoryFragment;
}

const More = ({ story }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const onShowPopupHandle = () => {
    setShowPopup(value => !value);
  };
  const onShowMoreHandle = () => {
    setShowMore(value => !value);
  };
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <button
        onClick={onShowMoreHandle}
        // className={clsx(showMore ? 'visible' : 'group-hover/story:visible invisible')}
        ref={ref}
      >
        <ThreeDots24 className={'text-smena_text-helper'} />
      </button>
      {showMore && (
        <OutsideClick
          show={showMore}
          setShow={setShowMore}
          triggerRef={ref}
          className="absolute top-1/2 right-1/2 transform translate-y-1/3 -translate-x-1/3 bg-smena_white flex shadow-smena rounded-lg z-20 w-max"
        >
          <button className={'Body1 px-3 py-[9.5px] text-smena_red'} onClick={onShowPopupHandle}>
            Удалить
          </button>
        </OutsideClick>
      )}
      <StoryPopup showPopup={showPopup} setShowPopup={setShowPopup} id={story.id} />
    </>
  );
};

export default More;
