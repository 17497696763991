import React, { useEffect } from 'react';
import { Clusterer, YMapsApi } from 'react-yandex-maps';
import Pin from './pin';
import { useVacanciesMap } from '../../store';

interface Props {
  ymaps: YMapsApi | null;
  mapRef: React.MutableRefObject<any>;
}

const CustomCluster = ({ ymaps }: Props) => {
  const facilityVacancies = useVacanciesMap(state => state.facilityVacancies);
  const setActiveFacilityVacancy = useVacanciesMap(state => state.setActiveFacilityVacancy);

  useEffect(() => {
    function onClick(event: MouseEvent) {
      const target: any = event.target;
      const currentTarget = target.dataset['clusterelement'];
      if (!currentTarget) return;
      target.style.backgroundColor = 'red';
      setActiveFacilityVacancy(currentTarget);
    }

    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [setActiveFacilityVacancy, ymaps]);
  if (!ymaps) return null;
  const MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
    '<div class="bg-smena_white shadow-smena Subtitle2 w-[48px] h-[48px] rounded-full flex justify-center items-center">{{ properties.geoObjects.length }}</div>',
  );
  const customBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
    `<div class='w-[288px] max-h-[180px] overflow-auto rounded-lg p-1'>
        {% for geoObject in properties.geoObjects %}
          <div class='flex items-center gap-x-2 px-2 py-1 rounded hover:bg-smena_bb-background hover:bg-opacity-90 text-smena_text cursor-pointer data-[active=true]:bg-primary data-[active=true]:text-smena_white' data-active='{{geoObject.properties.isActive}}' data-clusterElement='{{geoObject.properties.pin.id}}'>
            <div class='min-w-[24px] w-6 h-6 rounded-full bg-contain pointer-events-none' style="background-image: url('{{geoObject.properties.img}}')"></div>
            <div class='flex flex-col truncate bg-contain pointer-events-none'>
               <span class='label-primary' title='{{geoObject.properties.name}}'>{{geoObject.properties.pin.name}}</span>
               <span class='font-inter text-[12px] leading-[14px] tracking-[0.4px]'>
                  {{geoObject.properties.vacanciesLength}} {{geoObject.properties.vacanciesMorph}}
               </span>
            </div>
          </div>
        {% endfor %}
      </div>`,
  );
  return (
    <Clusterer
      options={{
        clusterDisableClickZoom: true,
        clusterOpenBalloonOnClick: true,
        clusterIcons: [
          {
            size: [40, 40],
            offset: [-20, -20],
          },
        ],
        clusterIconContentLayout: MyIconContentLayout,
        clusterBalloonContentLayout: customBalloonContentLayout,
      }}
    >
      {facilityVacancies.map(el => {
        return <Pin key={el.id} pin={el} ymaps={ymaps} />;
      })}
    </Clusterer>
  );
};

export default CustomCluster;
