import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { UserShiftsFragmentDoc } from '../../../../user/api/fragments/generated/UserShifts.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserShiftsQueryVariables = Types.Exact<{
  input: Types.FindUserShiftsInput;
}>;


export type UserShiftsQuery = { __typename?: 'Query', userShifts: Array<{ __typename?: 'Shift', id: string, dateFrom: any, dateEnd: any, duration: string, lunchDuration?: number | null, scheduleId?: string | null, comment?: string | null, authorId: string, stage: Types.ShiftStage, rejectedById?: string | null, positionId: string, userId: string, isProbation: boolean, zayavkaId?: string | null, schedule?: { __typename?: 'Schedule', id: string, scheduleTypeId?: string | null, dayInterleaving?: number | null, dateTo?: any | null, dateFrom?: any | null, isCustom: boolean } | null, marks: Array<{ __typename?: 'Mark', status: Types.MarkStatus, statusMessage: string, type: Types.MarkType, time: any, latitude?: string | null, longitude?: string | null, distance?: number | null }>, facility?: { __typename?: 'Facility', id: string, name: string, latitude: string, longitude: string, radius: number, facilityGroupId?: string | null } | null, zayavka?: { __typename?: 'Zayavka', number: number } | null }> };


export const UserShiftsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserShifts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FindUserShiftsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userShifts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserShifts"}}]}}]}},...UserShiftsFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useUserShiftsQuery__
 *
 * To run a query within a React component, call `useUserShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserShiftsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserShiftsQuery(baseOptions: Apollo.QueryHookOptions<UserShiftsQuery, UserShiftsQueryVariables> & ({ variables: UserShiftsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserShiftsQuery, UserShiftsQueryVariables>(UserShiftsDocument, options);
      }
export function useUserShiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserShiftsQuery, UserShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserShiftsQuery, UserShiftsQueryVariables>(UserShiftsDocument, options);
        }
export function useUserShiftsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserShiftsQuery, UserShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserShiftsQuery, UserShiftsQueryVariables>(UserShiftsDocument, options);
        }
export type UserShiftsQueryHookResult = ReturnType<typeof useUserShiftsQuery>;
export type UserShiftsLazyQueryHookResult = ReturnType<typeof useUserShiftsLazyQuery>;
export type UserShiftsSuspenseQueryHookResult = ReturnType<typeof useUserShiftsSuspenseQuery>;
export type UserShiftsQueryResult = Apollo.QueryResult<UserShiftsQuery, UserShiftsQueryVariables>;