import { EAutoSave } from 'enums/EAutoSave';
import { create } from 'zustand';

interface SettingLeadsStoreProps {
  draftId: string;
  setDraftId: (draftId: string) => void;
  showPublishModal: boolean;
  setShowPublishModal: (showPublishModal: boolean) => void;
  autoSaveState: EAutoSave;
  setAutoSaveState: (autoSaveState: EAutoSave) => void;
  referralProgramTitle: string | null;
  setReferralProgramTitle: (title: string) => void;
}

export const useSettingLeadsStore = create<SettingLeadsStoreProps>(set => ({
  draftId: '',
  setDraftId: draftId => set({ draftId }),
  showPublishModal: false,
  setShowPublishModal: showPublishModal => set({ showPublishModal }),
  autoSaveState: EAutoSave.SAVED,
  setAutoSaveState: autoSaveState => set({ autoSaveState }),
  referralProgramTitle: null,
  setReferralProgramTitle: title => set({ referralProgramTitle: title }),
}));
