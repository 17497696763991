import React, { useState } from 'react';
import clsx from 'clsx';
import SimpleInput from 'ui/input';
import { FormikHandlers, FormikState } from 'formik';
import { PushFormProps, usePushForm } from 'pages/push/utils/hooks/usePushForm';
import MoreDropdown from 'ui/moreDropdown';
import PushPopup from '../pushPopup';
import { usePush } from 'utils/api/usePush';
import ThreeDots24 from 'assets/icons/ThreeDots24';
import { convertNumber } from '../../../../utils/helpers/convertNumber';
import { getPushStatus } from 'utils/helpers/get/getPushStatus';

export interface MainInformationProps {
  values: PushFormProps;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikState<PushFormProps>['errors'];
}

const MainInformation = ({ values, handleChange, errors }: MainInformationProps) => {
  const { id, isPushIsNotNew, push } = usePush();
  const { onSave } = usePushForm();
  const { statusRu, className } = getPushStatus(values.status);
  const [showMore, setShowMore] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const onShowMoreHandle = () => {
    setShowMore(value => !value);
  };
  const onShowPopupHandle = () => {
    setShowPopup(value => !value);
  };
  return (
    <div className={'grid gap-y-4'}>
      <div className={'flex justify-between relative'}>
        <span className="Subtitle1 text-smena_text-secondary">Общая информация</span>
        {!isPushIsNotNew && (
          <MoreDropdown
            showDropdown={showMore}
            setShowDropdown={setShowMore}
            onShowDropdownHandler={onShowMoreHandle}
            icon={<ThreeDots24 className={'text-smena_text-helper'} />}
          >
            <button className={'Body1 px-3 py-[9.5px] text-smena_red'} onClick={onShowPopupHandle} type={'button'}>
              Удалить
            </button>
          </MoreDropdown>
        )}
      </div>
      <div className={'flex gap-x-6'}>
        <div className={'flex flex-col gap-y-0.5'}>
          <span className={'Subtitle2'}>Статус</span>
          <div className={clsx(className, 'rounded-full inline-flex px-2 py-[2px] Table-small')}>{statusRu}</div>
        </div>
        <SimpleInput
          onBlur={() => {
            onSave(values);
          }}
          type={'text'}
          label={'Название'}
          placeholder={'Введите название'}
          onChange={handleChange}
          showText={isPushIsNotNew}
          name={'name'}
          value={values.name}
          error={errors.name ? errors.name : ''}
        />
        {isPushIsNotNew && (
          <div className={'flex flex-col gap-y-0.5'}>
            <span className={'Subtitle2'}>Получатели</span>
            <span className={'Body2 text-smena_text-secondary'}>
              {push?.publicCondition?.recipientsCount ? convertNumber(push.publicCondition.recipientsCount) : '-'}
            </span>
          </div>
        )}
      </div>
      <PushPopup pushId={id} showPopup={showPopup} setShowPopup={setShowPopup} />
    </div>
  );
};

export default MainInformation;
