import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ContractFragmentDoc } from '../../fragments/generated/contract.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchUserContractsQueryVariables = Types.Exact<{
  input: Types.SearchContractsInput;
}>;


export type SearchUserContractsQuery = { __typename?: 'Query', searchUserContracts: { __typename?: 'UserContractsPaginatedOutput', count: number, pages: number, contracts: Array<{ __typename?: 'UserContract', createdAt: any, id: string, type: Types.UserContractType, status: Types.UserContractStatus, url?: string | null, user: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null, phone: string } }> } };


export const SearchUserContractsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchUserContracts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SearchContractsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchUserContracts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"contracts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Contract"}}]}}]}}]}},...ContractFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useSearchUserContractsQuery__
 *
 * To run a query within a React component, call `useSearchUserContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchUserContractsQuery(baseOptions: Apollo.QueryHookOptions<SearchUserContractsQuery, SearchUserContractsQueryVariables> & ({ variables: SearchUserContractsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUserContractsQuery, SearchUserContractsQueryVariables>(SearchUserContractsDocument, options);
      }
export function useSearchUserContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUserContractsQuery, SearchUserContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUserContractsQuery, SearchUserContractsQueryVariables>(SearchUserContractsDocument, options);
        }
export function useSearchUserContractsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchUserContractsQuery, SearchUserContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchUserContractsQuery, SearchUserContractsQueryVariables>(SearchUserContractsDocument, options);
        }
export type SearchUserContractsQueryHookResult = ReturnType<typeof useSearchUserContractsQuery>;
export type SearchUserContractsLazyQueryHookResult = ReturnType<typeof useSearchUserContractsLazyQuery>;
export type SearchUserContractsSuspenseQueryHookResult = ReturnType<typeof useSearchUserContractsSuspenseQuery>;
export type SearchUserContractsQueryResult = Apollo.QueryResult<SearchUserContractsQuery, SearchUserContractsQueryVariables>;