import React from 'react';

import MainLayout from 'components/layout/main';
import Timesheet from './components/TimesheetReport';
import PollsReport from './components/PollsReport';
import SmsReport from './components/SmsReport';
import DailyReport from './components/DailyReport';
import EverythingIsPossibleReport from './components/EverythingIsPossibleReport';
import { useMeRole } from 'store/hooks/useMeRole';
import { Role } from 'generated/graphql';

const TimesheetPage = () => {
  const isMeAdmin = useMeRole([Role.Admin]);

  return (
    <MainLayout title="Отчёты" breadCrumbs={[{ path: '/Timesheet', name: 'Отчёты' }]}>
      <div className="grid grid-cols-12 gap-6">
        <Timesheet />
        {isMeAdmin && (
          <>
            <DailyReport />
            <SmsReport />
            <PollsReport />
            <EverythingIsPossibleReport />
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default TimesheetPage;
