import dayjs from 'dayjs';
import { useMemo } from 'react';

interface DaysCountProps {
  dateTo?: string;
  dateFrom?: string;
}

export const useDaysCount = ({ dateTo, dateFrom }: DaysCountProps) => {
  return useMemo(() => {
    const days: dayjs.Dayjs[] = [];
    const diffDays = dayjs(dateTo).startOf('day').diff(dayjs(dateFrom).startOf('day'), 'days');

    for (let index = 0; index <= diffDays; index++) {
      days.push(dayjs(dateFrom).add(index, 'd').startOf('d'));
    }
    return days;
  }, [dateFrom, dateTo]);
};
