import React from 'react';
import YandexMap from './yandexMap';
import DadataAddress from 'components/dadata/address';
import { FormikHandlers, FormikState } from 'formik';
import { useCityPageProps } from '../hooks';
import { useCityMap } from '../store';
import SimpleCheckbox from 'ui/checkbox';
import SimpleInput from '../../../ui/input';
import { reqCoordinates } from '../../../utils/helpers/constVariables';

type Props = {
  values: useCityPageProps;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikState<useCityPageProps>['errors'];
  touched: FormikState<useCityPageProps>['touched'];
  setFieldValue: (name: string, value: any) => void;
};

const MapBlock = ({ values, setFieldValue, handleChange, errors, touched }: Props) => {
  const setLat = useCityMap(state => state.setLat);
  const setLon = useCityMap(state => state.setLon);
  const lat = useCityMap(state => state.lat);
  const lon = useCityMap(state => state.lon);

  return (
    <div className="p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
      <span className="Subtitle1 text-smena_text-secondary">Местоположение</span>
      <YandexMap />
      <DadataAddress
        label={'Поиск города'}
        value={values.value}
        textValue={values.value?.data.city || ''}
        onChange={value => {
          if (!value) return;
          setFieldValue('value', value);
          setFieldValue('name', value.data.city);
          setLat(Number(value.data.geo_lat));
          setLon(Number(value.data.geo_lon));
        }}
        filterFrom="city"
        filterTo="city"
        placeholder="Выберите город"
      />
      <SimpleCheckbox
        name={'showCoordinates'}
        onChange={handleChange}
        checked={values.showCoordinates}
        label={'Определить по координатам'}
        error={touched.showCoordinates && errors.showCoordinates ? errors.showCoordinates : ''}
      />
      {values.showCoordinates && (
        <div className={'flex gap-x-6'}>
          <SimpleInput
            label={'Широта'}
            type={'text'}
            value={lat}
            onChange={({ target: { value } }) => {
              if (reqCoordinates.test(value) || value === '') {
                setLat(Number(value));
              }
            }}
            name={'lat'}
            placeholder={'59.938675'}
          />
          <SimpleInput
            label={'Долгота'}
            type={'text'}
            value={lon}
            onChange={({ target: { value } }) => {
              if (reqCoordinates.test(value) || value === '') {
                setLon(Number(value));
              }
            }}
            name={'lon'}
            placeholder={'30.314447'}
          />
        </div>
      )}
    </div>
  );
};

export default MapBlock;
