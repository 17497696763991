import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import React, { useMemo, useState } from 'react';
import FacilityCell from 'pages/users/components/ourUsers/facilityCell';
import { ClientUserFragment } from 'pages/users/api/fragments/generated/ClientUser.fragment';
import { autoRateColor } from 'utils/helpers/autoRateReduce';
import ReviewIcon from 'assets/icons/ReviewIcon';
import uniqueArray from 'utils/helpers/uniqueArray';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import { successToast } from 'utils/helpers/notify';
import CopyIcon from 'assets/icons/CopyIcon';

export const useClientsUsersTable = (data: ClientUserFragment[]) => {
  const columns: ColumnDef<ClientUserFragment>[] = useMemo(() => {
    return [
      {
        id: 'lastname',
        header: 'Фамилия',
        size: 200,
        minSize: 170,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/users/${original.id}`} className="link">
              {original.lastname ? original.lastname : 'Нет информации'}
            </Link>
          );
        },
      },
      {
        id: 'firstname',
        header: 'Имя',
        size: 200,
        minSize: 170,
        accessorKey: 'firstname',
      },
      {
        id: 'patronymic',
        header: 'Отчество',
        size: 200,
        minSize: 170,
        accessorKey: 'patronymic',
      },
      {
        id: 'role',
        header: 'Роль',
        size: 135,
        minSize: 130,
        cell: ({ row: { original } }) => getRoleRu(original.role),
      },
      {
        id: 'phone',
        header: 'Телефон',
        minSize: 180,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex items-center gap-x-2 group/number min-h-[24px]'}>
              <span>{convertPhoneNumberToNormal(original.phone)}</span>
              <button
                className={'group-hover/number:block hidden'}
                onClick={() => {
                  navigator.clipboard.writeText(original.phone).then(() => {
                    successToast('Номер скопирован', 1000);
                  });
                }}
              >
                <CopyIcon />
              </button>
            </div>
          );
        },
      },
      {
        id: 'facilityGroup',
        header: 'Группа',
        size: 200,
        minSize: 180,
        cell: ({ row: { original } }) => {
          return (
            <>
              {uniqueArray({ array: original.workposts?.map(el => el.facility), field: 'facilityGroupId' })?.map(el => {
                return (
                  <Link key={el.facilityGroupId} to={`/groups/${el.facilityGroupId}`} className="link">
                    {el.facilityGroup?.name}
                  </Link>
                );
              })}
            </>
          );
        },
      },
      {
        id: 'facility',
        header: 'Объект',
        size: 180,
        minSize: 180,
        cell: ({ row: { original } }) => {
          return <FacilityCell user={original} showAcross={false} />;
        },
      },
      {
        id: 'activeShifts',
        header: 'Активные смены',
        size: 135,
        minSize: 135,
        cell: ({ row: { original } }) => {
          return original.activeShifts ? (
            <Check className="text-smena_green-dark" />
          ) : (
            <Across className="text-smena_red" />
          );
        },
      },
      {
        id: 'rating',
        header: 'Рейтинг',
        size: 120,
        minSize: 120,
        cell: ({ row: { original } }) => {
          const userAutoRating = original?.userAutoRating;
          const classname = original.hideRating ? '' : autoRateColor(userAutoRating?.rating);
          return <div className={classname}>{original.hideRating ? 'Еще нет рейтинга' : userAutoRating?.rating}</div>;
        },
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 165,
        minSize: 130,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/users/${original.id}/questionnaire`} className="inline-block">
              <ReviewIcon />
            </Link>
          );
        },
      },
    ];
  }, []);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    meta: {
      lastname: true,
      activeShifts: true,
      rating: true,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};
