import * as Types from '../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDraftReferralProgramMutationVariables = Types.Exact<{
  input: Types.CreateDraftReferralProgramInput;
}>;


export type CreateDraftReferralProgramMutation = { __typename?: 'Mutation', createDraftReferralProgram: { __typename?: 'ReferralProgramEntity', id: string } };


export const CreateDraftReferralProgramDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateDraftReferralProgram"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateDraftReferralProgramInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createDraftReferralProgram"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type CreateDraftReferralProgramMutationFn = Apollo.MutationFunction<CreateDraftReferralProgramMutation, CreateDraftReferralProgramMutationVariables>;

/**
 * __useCreateDraftReferralProgramMutation__
 *
 * To run a mutation, you first call `useCreateDraftReferralProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftReferralProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftReferralProgramMutation, { data, loading, error }] = useCreateDraftReferralProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftReferralProgramMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftReferralProgramMutation, CreateDraftReferralProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDraftReferralProgramMutation, CreateDraftReferralProgramMutationVariables>(CreateDraftReferralProgramDocument, options);
      }
export type CreateDraftReferralProgramMutationHookResult = ReturnType<typeof useCreateDraftReferralProgramMutation>;
export type CreateDraftReferralProgramMutationResult = Apollo.MutationResult<CreateDraftReferralProgramMutation>;
export type CreateDraftReferralProgramMutationOptions = Apollo.BaseMutationOptions<CreateDraftReferralProgramMutation, CreateDraftReferralProgramMutationVariables>;