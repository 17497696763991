import { Tooltip } from 'antd';
import { LeadFragment } from 'pages/lead/api/fragments/generated/Lead.fragment';
import { useSetReferralProgramPaymentDatetimeMutation } from 'pages/leads/api/mutations/generated/SetReferralProgramPaymentDatetime';
import { useLeadsStore } from 'pages/leads/store';
import { stockCompleteFn } from 'pages/leads/utils/hooks/isStockCompleteFn';
import SimpleCheckbox from 'ui/checkbox';
import { getLeadsV2Document } from '../api/queries/generated/leads';

interface Props {
  original: LeadFragment;
}

const LeadPayCheckbox = ({ original }: Props) => {
  const { setShowPayModal, setCallbackModal, setLeadId } = useLeadsStore(state => state);

  const [setReferralProgramDatetime, { data }] = useSetReferralProgramPaymentDatetimeMutation({
    refetchQueries: [getLeadsV2Document],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (original.id) {
      setShowPayModal(true);
      setLeadId(original.id);
      setCallbackModal(setReferralProgramDatetime);
    }
  };

  return (
    <Tooltip
      title={
        stockCompleteFn(original) !== original.referralProgram?.numberOfGoodShifts &&
        'Реферал не выполнил все условия для выплаты'
      }
    >
      <div className="inline-block">
        <SimpleCheckbox
          checked={original.referrerPaymentDatetime || data?.setReferralProgramPaymentDatetime?.referrerPaymentDatetime}
          onChange={handleChange}
          name="checkbox"
          disabled={
            stockCompleteFn(original) !== original.referralProgram?.numberOfGoodShifts ||
            original.referrerPaymentDatetime ||
            data?.setReferralProgramPaymentDatetime?.referrerPaymentDatetime
          }
        />
      </div>
    </Tooltip>
  );
};

export default LeadPayCheckbox;
