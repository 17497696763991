import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useStore } from 'store';
import { positionName } from 'utils/helpers/filters/filtersName';
import { auchanLunchDurationTypes, lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { timeDifference } from 'utils/helpers/timeDifference';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import MainSidebar from 'ui/sidebar';
import { RU_DATE } from 'utils/helpers/constVariables';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import { dateEndFn } from 'utils/helpers/dateEndFn';
import SimpleSelect from 'ui/select';
import dateHour from 'pages/userSchedule/components/sidebar/newSchedule/helpers/dateHour';
import { useCreateRequestShiftByClientMutation } from 'pages/facilityRequests/api/mutations/generated/CreateRequestShiftByClient';
import { usePositions } from 'utils/api/usePositions';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';

const NewShift = () => {
  const { showCreateRequestShiftSidebar, setShowCreateRequestShiftSidebar, requestSchedule, requestDayAtRow } =
    useStore();
  const { request } = useCurrentRequest();
  const { positions } = usePositions();

  const day = dayjs(requestDayAtRow?.day);

  const [shiftStartTime, setShiftStartTime] = useState<DayjsOrNull>(null);
  const [shiftEndTime, setShiftEndTime] = useState<DayjsOrNull>(null);
  const [lunchDuration, setLunchDuration] = useState(0);

  const [createRequestShiftByClient] = useCreateRequestShiftByClientMutation();

  const dateEnd = useMemo(
    () => dateEndFn({ date: day, endTime: shiftEndTime, startTime: shiftStartTime }),
    [day, shiftEndTime, shiftStartTime],
  );
  const dateFrom = useMemo(() => dateHour(day, shiftStartTime), [day, shiftStartTime]);

  const createRequestShiftByClientHandler = useCallback(() => {
    if (requestSchedule?.id) {
      createRequestShiftByClient({
        variables: {
          zayavkaScheduleId: requestSchedule.id,
          input: {
            lunchDuration,
            dateFrom,
            dateEnd,
          },
        },
      })
        .then(() => {
          successToast('Смена добавлена');
          setShowCreateRequestShiftSidebar(false);
        })
        .catch(e => errorToast(errorHandler(e)));
    }
  }, [
    createRequestShiftByClient,
    dateEnd,
    dateFrom,
    lunchDuration,
    requestSchedule?.id,
    setShowCreateRequestShiftSidebar,
  ]);

  const { isAuchan } = useFacilityGroupById(request?.facility?.facilityGroup?.id);

  return (
    <MainSidebar title="Добавить смену" show={showCreateRequestShiftSidebar} setShow={setShowCreateRequestShiftSidebar}>
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-1">
          <span className="H4">{positionName(positions, requestDayAtRow?.positionId)}</span>
          <span className="Subtitle2 text-smena_text-secondary">{dayjs(requestDayAtRow?.day).format(RU_DATE)}</span>
        </div>
        <hr className="bg-smena_gray-30" />
        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
          <TimePickerInput
            label="Начало смены"
            className="col-span-1"
            popupClassName={'fixed'}
            value={shiftStartTime}
            onChange={timeValue => {
              setShiftStartTime(timeValue);
              if (isAuchan) {
                setLunchDuration(lunchDurationHandle(timeDifference(timeValue, shiftEndTime)));
              }
            }}
          />
          <TimePickerInput
            label="Конец смены"
            className="col-span-1"
            popupClassName={'fixed'}
            value={shiftEndTime}
            onChange={timeValue => {
              setShiftEndTime(timeValue);
              if (isAuchan) {
                setLunchDuration(lunchDurationHandle(timeDifference(shiftStartTime, timeValue)));
              }
            }}
          />
          <SimpleSelect
            divClassName="col-span-1"
            label="Обед"
            sort={false}
            onChange={value => {
              if (!isAuchan) {
                setLunchDuration(Number(value));
              }
            }}
            value={lunchDuration}
            options={isAuchan ? auchanLunchDurationTypes : lunchDurationTypes}
            disabled={isAuchan}
          />
        </div>
        <div className="flex justify-end gap-x-6">
          <button className="btn-primary" onClick={createRequestShiftByClientHandler}>
            Добавить смену
          </button>
        </div>
      </div>
    </MainSidebar>
  );
};

export default NewShift;
