import { useApolloClient } from '@apollo/client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckCircle from 'assets/icons/checkCircle';

import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import { removeMetricValues, removeTokens } from 'app/config/auth';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { errorToast, successToast } from 'utils/helpers/notify';
import { userInitials, userNameSurname } from 'utils/helpers/userFullName';
import CopyIcon from 'assets/icons/CopyIcon';
import OutsideClick from 'components/outsideComponent';
import { useMeRole } from 'store/hooks/useMeRole';
import clsx from 'clsx';
import { useSetSettingMutation } from 'graphql/mutations/generated/SetSetting';
import { useMe } from 'utils/api/useMe';
import { removeCurrentUserTokens } from '../helpers';
import { useSettingQuery } from '../../../graphql/queries/generated/Setting';
import { Role } from 'generated/graphql';
import Tooltip from 'antd/lib/tooltip';

const Profile: React.FC<{ setSettingsError: React.Dispatch<React.SetStateAction<boolean>> }> = React.memo(
  ({ setSettingsError }) => {
    const me = useMe();

    const { data } = useSettingQuery({
      variables: {
        name: 'version',
      },
      fetchPolicy: 'network-only',

      onError: err => {
        setSettingsError(err.message === 'Failed to fetch');
      },
    });

    useEffect(() => {
      if (data?.setting.value) setVersion(data?.setting.value);
    }, [data?.setting.value]);

    const isMeAdmin = useMeRole([Role.Admin]);
    const [showPopup, setShowPopup] = useState(false);
    const [version, setVersion] = useState('');

    const [setSettingMutation] = useSetSettingMutation();
    const apolloClient = useApolloClient();
    const navigate = useNavigate();

    const onClick = useCallback(() => {
      setShowPopup(value => !value);
    }, []);

    const onCopyClick = useCallback(() => {
      if (!me) return;
      navigator.clipboard.writeText(me.id).then(() => {
        successToast('Идентификатор скопирован', 1000);
      });
    }, [me]);

    const onClickLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      removeTokens();
      removeMetricValues();
      removeCurrentUserTokens();
      await apolloClient.clearStore();
      navigate('/login');
    };

    const buttonHandler = useCallback(() => {
      if (version) {
        if (!new RegExp('[^0-9.]').test(version)) {
          return setSettingMutation({
            variables: {
              value: version,
              name: 'version',
            },
          })
            .then(response => {
              if (response.data?.setSetting) {
                successToast('Версия успешно изменена');
              }
            })
            .catch(() => {
              errorToast('Произошла ошибка');
            });
        } else {
          errorToast('Некорректно введена версия');
        }
      }
    }, [setSettingMutation, version]);
    const inputHandler = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setVersion(value);
    };
    const phoneNumber = convertPhoneNumberToNormal(me?.phone);
    const profileRef = useRef<HTMLDivElement | null>(null);

    return (
      <div className="relative">
        <div
          className="h-12 w-12 rounded-full bg-smena_bb-background Subtitle1 flex items-center justify-center cursor-pointer"
          onClick={onClick}
          ref={profileRef}
        >
          <span className="uppercase">{userInitials(me)}</span>
        </div>
        <OutsideClick
          setShow={setShowPopup}
          show={showPopup}
          triggerRef={profileRef}
          className={clsx(
            'bg-smena_white absolute top-full translate-y-2 right-0 p-6 flex flex-col gap-y-10 shadow-smena rounded-lg z-40 transition-all',
            showPopup ? 'opacity-100' : 'opacity-0 pointer-events-none',
          )}
        >
          <div className="flex flex-col gap-y-6 w-[310px]">
            <div className="flex flex-col gap-y-1">
              <span className="Subtitle1 text-smena_text">{userNameSurname(me)}</span>
              <span className="Body1 text-smena_text-secondary">{getRoleRu(me?.role)}</span>
            </div>
            <div className="flex flex-col">
              <span className="Tag text-smena_text-secondary">Телефон</span>
              <span className="Body1 text-smena_text">{phoneNumber}</span>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="flex flex-col">
                <span className="Tag text-smena_text-secondary">Идентификатор</span>
                <span className="Body1 text-smena_text">{me?.id}</span>
              </div>
              <Tooltip title="Скопировать">
                <button type="button" className="relative" onClick={onCopyClick}>
                  <CopyIcon />
                </button>
              </Tooltip>
            </div>
            {isMeAdmin && (
              <div className="flex gap-x-3">
                <div className="flex flex-col w-full">
                  <span className="Tag text-smena_text-secondary">Минимальная версия приложения</span>
                  <input className="input-primary" type="text" value={version} onChange={inputHandler} />
                </div>
                <button className="self-end mb-1" onClick={buttonHandler}>
                  <CheckCircle className="text-primary" />
                </button>
              </div>
            )}
          </div>
          <button className="btn-primary w-full" type="button" onClick={onClickLogout}>
            Выйти
          </button>
        </OutsideClick>
      </div>
    );
  },
);

export default Profile;
