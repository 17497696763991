import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import MainLayout from 'components/layout/main';
import RequestsFilters from 'pages/requests/components/RequestsFilter';
import CustomPagination from 'ui/pagination';
import { useRequestPaginatedQuery } from 'pages/facilityRequests/api/queries/generated/RequestPaginated';
import { SortingWay, ZayavkaFieldsSorting } from 'generated/graphql';
import { useRefreshRequestsListSubscription } from './api/subscriptions/generated/RefreshRequestsList';
import { useRequestsTable } from '../../components/table/columns/requests/requests';
import NewTable from '../../components/table/newTable';
import { PaginatedRequestFragment } from 'pages/facilityRequests/api/fragments/generated/PaginatedRequest.fragment';

const RequestsPage: React.FC = () => {
  const [params] = useSearchParams();

  const status = params.get('status');
  const groupId = params.get('groupId');
  const facilityId = params.get('facilityId');
  const dateFromParam = params.get('dateFrom');
  const dateToParam = params.get('dateTo');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  // const dateFrom = dateFromParam ? dayjs(dateFromParam) : null;
  // const dateTo = dateToParam ? dayjs(dateToParam) : null;

  const { data: dataSub } = useRefreshRequestsListSubscription();

  const { data, loading, refetch } = useRequestPaginatedQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        page,
        searchQuery: searchQuery && Number(searchQuery) ? searchQuery : '',
        status,
        sortBy: sortBy as ZayavkaFieldsSorting,
        sortWay: sortWay as SortingWay,
        dateFrom: dateFromParam,
        dateTo: dateToParam,
        facilityId,
        groupId,
        size,
      },
    },
  });

  const requestsPaginated = data?.zayavkiPaginated;
  const requests = useMemo(() => requestsPaginated?.zayavky || [], [requestsPaginated?.zayavky]);
  const table = useRequestsTable(requests);

  useEffect(() => {
    if (dataSub) {
      refetch();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainLayout title="Заявки" breadCrumbs={[{ path: '/requests', name: 'Заявки' }]}>
      <div className={'grid gap-y-5 shadow-smena rounded bg-smena_white'}>
        <RequestsFilters table={table} />
        <NewTable<PaginatedRequestFragment>
          table={table}
          length={requests.length}
          loading={loading}
          headerMenu={true}
        />
        {requestsPaginated && <CustomPagination currentPage={page} total={requestsPaginated.pages} />}
      </div>
    </MainLayout>
  );
};

export default RequestsPage;
