import { UnitType } from 'generated/graphql';

export const unitSymbolFn = (unit?: UnitType | null) => {
  switch (unit) {
    case UnitType.Hour:
      return 'ч.';
    case UnitType.Piece:
      return ' шт.';
    case UnitType.Point:
      return '%';
    default:
      return '';
  }
};
