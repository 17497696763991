interface Props {
  array?: any[] | null;
  field: string;
}

const uniqueArray = ({ array, field }: Props) => {
  return array?.filter(Boolean)?.length ? [...new Map(array?.map(item => [item?.[field], item])).values()] : [];
};

export default uniqueArray;
