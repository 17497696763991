import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PositionFragmentDoc } from '../../../../position/api/fragments/generated/Position.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPositionsByFacilitiesAndCitiesQueryVariables = Types.Exact<{
  cityIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  facilityGroupIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  facilityIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type GetPositionsByFacilitiesAndCitiesQuery = { __typename?: 'Query', getPositionsByFacilitiesAndCities: Array<{ __typename?: 'Position', id: string, externalId?: string | null, name: string, isSelfEmployed: boolean }> };


export const GetPositionsByFacilitiesAndCitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPositionsByFacilitiesAndCities"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cityIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityGroupIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPositionsByFacilitiesAndCities"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cityIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cityIds"}}},{"kind":"Argument","name":{"kind":"Name","value":"facilityGroupIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityGroupIds"}}},{"kind":"Argument","name":{"kind":"Name","value":"facilityIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Position"}}]}}]}},...PositionFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetPositionsByFacilitiesAndCitiesQuery__
 *
 * To run a query within a React component, call `useGetPositionsByFacilitiesAndCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionsByFacilitiesAndCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionsByFacilitiesAndCitiesQuery({
 *   variables: {
 *      cityIds: // value for 'cityIds'
 *      facilityGroupIds: // value for 'facilityGroupIds'
 *      facilityIds: // value for 'facilityIds'
 *   },
 * });
 */
export function useGetPositionsByFacilitiesAndCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetPositionsByFacilitiesAndCitiesQuery, GetPositionsByFacilitiesAndCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPositionsByFacilitiesAndCitiesQuery, GetPositionsByFacilitiesAndCitiesQueryVariables>(GetPositionsByFacilitiesAndCitiesDocument, options);
      }
export function useGetPositionsByFacilitiesAndCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPositionsByFacilitiesAndCitiesQuery, GetPositionsByFacilitiesAndCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPositionsByFacilitiesAndCitiesQuery, GetPositionsByFacilitiesAndCitiesQueryVariables>(GetPositionsByFacilitiesAndCitiesDocument, options);
        }
export function useGetPositionsByFacilitiesAndCitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPositionsByFacilitiesAndCitiesQuery, GetPositionsByFacilitiesAndCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPositionsByFacilitiesAndCitiesQuery, GetPositionsByFacilitiesAndCitiesQueryVariables>(GetPositionsByFacilitiesAndCitiesDocument, options);
        }
export type GetPositionsByFacilitiesAndCitiesQueryHookResult = ReturnType<typeof useGetPositionsByFacilitiesAndCitiesQuery>;
export type GetPositionsByFacilitiesAndCitiesLazyQueryHookResult = ReturnType<typeof useGetPositionsByFacilitiesAndCitiesLazyQuery>;
export type GetPositionsByFacilitiesAndCitiesSuspenseQueryHookResult = ReturnType<typeof useGetPositionsByFacilitiesAndCitiesSuspenseQuery>;
export type GetPositionsByFacilitiesAndCitiesQueryResult = Apollo.QueryResult<GetPositionsByFacilitiesAndCitiesQuery, GetPositionsByFacilitiesAndCitiesQueryVariables>;