import clsx from 'clsx';
import React from 'react';
import { TIcon } from '../../interfaces/TIcon';

const ExclamationMark12 = ({ className }: TIcon) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8002 6.49922C10.8002 9.15019 8.65116 11.2992 6.0002 11.2992C3.34923 11.2992 1.2002 9.15019 1.2002 6.49922C1.2002 3.84825 3.34923 1.69922 6.0002 1.69922C8.65116 1.69922 10.8002 3.84825 10.8002 6.49922ZM6.6002 8.89922C6.6002 9.23059 6.33157 9.49922 6.0002 9.49922C5.66882 9.49922 5.4002 9.23059 5.4002 8.89922C5.4002 8.56785 5.66882 8.29922 6.0002 8.29922C6.33157 8.29922 6.6002 8.56785 6.6002 8.89922ZM6.0002 3.49922C5.66882 3.49922 5.4002 3.76785 5.4002 4.09922V6.49922C5.4002 6.83059 5.66882 7.09922 6.0002 7.09922C6.33157 7.09922 6.6002 6.83059 6.6002 6.49922V4.09922C6.6002 3.76785 6.33157 3.49922 6.0002 3.49922Z"
      />
    </svg>
  );
};

export default ExclamationMark12;
