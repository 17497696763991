import React, { useEffect, useState } from 'react';

import { ChartType, StatisticPeriod } from 'generated/graphql';
import { getTimePeriodCharts } from 'utils/helpers/get/getTimeePeriodCharts';
import { chartTimes } from 'utils/helpers/lists/chartTimes';
import SimpleSelect from 'ui/select';
import { lastTimeLength } from 'utils/helpers/charts/lastTimeLength';
import { ITooltipStatus } from 'interfaces/ITooltipStatus';
import CustomBarChart from '../customBarChart';
import Skeleton from 'antd/es/skeleton';
import { useGetStatisticLazyQuery } from 'pages/main/api/queries/generated/GetStatistic';
import { useGetStatisticResponse, useIsSendRequest } from '../trends/hooks';

export const UserRegistrationChart = () => {
  const [time, setTime] = useState<StatisticPeriod>(StatisticPeriod.Day);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });
  const [focusBar, setFocusBar] = useState<undefined | number>(undefined);
  const isSendRequest = useIsSendRequest();
  const [loadData, { data, loading }] = useGetStatisticLazyQuery();

  const res = useGetStatisticResponse(data);

  const handleTimeChange = (value: string) => {
    setTime(value as StatisticPeriod);
  };

  useEffect(() => {
    if (!isSendRequest) return;
    loadData({
      variables: {
        input: {
          period: time,
          types: [ChartType.Registrations],
        },
      },
    });
  }, [isSendRequest, loadData, time]);

  return (
    <div className="col-span-6 shadow-smena rounded-lg p-5">
      <div className="grid grid-cols-[auto,_auto] justify-between items-center">
        <span className="Subtitle2 text-smena_text-secondary">Регистрации пользователей</span>
        <div className="flex">
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleTimeChange}
            value={time}
            sort={false}
            options={chartTimes}
          />
        </div>
      </div>
      <div className="flex gap-x-1 items-end">
        {loading ? (
          <Skeleton.Button active />
        ) : (
          <span className="ChartNumber text-smena_text">{lastTimeLength(time, res)}</span>
        )}
        <span className="Caption text-smena_text-secondary">за {getTimePeriodCharts(time)}</span>
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="h-[240px]">
          <CustomBarChart
            setFocusBar={setFocusBar}
            fillColorOpacity="#CCDEFF"
            fillColor="#397DFF"
            tooltipStatus={tooltipStatus}
            setTooltipStatus={setTooltipStatus}
            formatter="Регистраций:"
            focusBar={focusBar}
            res={res}
            time={time}
          />
        </div>
      )}
    </div>
  );
};
export default UserRegistrationChart;
