import React from 'react';
import { FacilityVacancies, useVacanciesMap } from '../../store';
import clsx from 'clsx';
import { getImagePath } from 'utils/helpers/get/getImagePath';
import Vacancy from './vacancy';
import { ArrowRight } from 'assets/icons/ArrowRight';

interface Props {
  activeFacilityVacancy: FacilityVacancies;
}

const ActiveVacancy = ({ activeFacilityVacancy }: Props) => {
  const setActiveFacilityVacancy = useVacanciesMap(state => state.setActiveFacilityVacancy);
  return (
    <div className={'flex flex-col overflow-auto'}>
      <div
        className={clsx(
          'border-b border-smena_gray-30 py-3 px-6 flex items-center gap-x-2 cursor-pointer bg-smena_white sticky top-0',
        )}
      >
        <button
          onClick={() => {
            setActiveFacilityVacancy(activeFacilityVacancy.id);
          }}
        >
          <ArrowRight className={'text-primary transform rotate-180'} />
        </button>
        <img
          src={getImagePath(activeFacilityVacancy.facilityGroup?.logo?.path)}
          className={'w-[32px] h-[32px] object-cover rounded-full'}
          alt={'facility group image'}
        />
        <div className={'flex flex-col'}>
          <span className={'Subtitle1'}>{activeFacilityVacancy.name}</span>
          <span className={'Caption-small text-smena_text-secondary'}>
            {activeFacilityVacancy.addressString || activeFacilityVacancy.address?.address}
          </span>
        </div>
      </div>
      <div className={'flex flex-col'}>
        {activeFacilityVacancy.vacancies?.length ? (
          activeFacilityVacancy.vacancies?.map(vacancy => {
            return <Vacancy vacancy={vacancy} key={vacancy.id} />;
          })
        ) : (
          <div className={'h-[444px] w-full flex justify-center items-center Subtitle1 text-smena_text-secondary'}>
            Вакансии отсутствуют
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveVacancy;
