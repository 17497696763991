import { useMemo } from 'react';
import uniqueArray from 'utils/helpers/uniqueArray';
import { useCurrentUser } from 'utils/api/useUser';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';

export const useCurrentUserWorkposts = () => {
  const { user: currentUser } = useCurrentUser();
  return useMemo(() => uniqueArray({ array: currentUser?.workposts, field: 'facilityId' }), [currentUser?.workposts]);
};

export const useFilteredUserPositionsWorkposts = (facilityId?: string) => {
  const { user: currentUser } = useCurrentUser();
  return useMemo(() => {
    if (!facilityId) return [];
    return uniqueArray({
      array: currentUser?.workposts?.filter(el => el?.facilityId === facilityId),
      field: 'positionId',
    });
  }, [facilityId, currentUser?.workposts]);
};

export const useUserFacilitiesWorkpostsOptions = () => {
  const currentUserWorkposts = useCurrentUserWorkposts();
  const { facilities } = useSelectFacilities();
  return useMemo(
    () =>
      currentUserWorkposts
        ?.map(workpost => ({
          id: workpost?.facilityId,
          name: workpost?.facility?.name,
        }))
        .filter(workpost => facilities.some(facility => facility.id === workpost.id)),
    [currentUserWorkposts, facilities],
  );
};

export const useUserPositionsWorkpostsOptions = (facilityId?: string) => {
  const filteredUserPositionsWorkposts = useFilteredUserPositionsWorkposts(facilityId);
  return useMemo(() => {
    return filteredUserPositionsWorkposts.map(workpost => ({
      id: workpost?.positionId,
      name: workpost?.position?.name,
    }));
  }, [filteredUserPositionsWorkposts]);
};
