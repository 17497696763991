import * as Types from '../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSchedulerReferralProgramsQueryVariables = Types.Exact<{
  input: Types.GetSchedulerReferralProgramsInput;
}>;


export type GetSchedulerReferralProgramsQuery = { __typename?: 'Query', getSchedulerReferralPrograms: Array<{ __typename?: 'ReferralProgramEntity', id: string, name?: string | null, description?: string | null, numberOfGoodShifts?: number | null, rewardForTheReferer?: number | null, startDatetime?: any | null, endDatetime?: any | null, status: Types.ReferralProgramStatusEnum, stories: Array<{ __typename?: 'Story', id: string }>, notifyLetters: Array<{ __typename?: 'NotifyLetter', id: string }> }> };


export const GetSchedulerReferralProgramsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSchedulerReferralPrograms"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetSchedulerReferralProgramsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSchedulerReferralPrograms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"numberOfGoodShifts"}},{"kind":"Field","name":{"kind":"Name","value":"rewardForTheReferer"}},{"kind":"Field","name":{"kind":"Name","value":"startDatetime"}},{"kind":"Field","name":{"kind":"Name","value":"endDatetime"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"stories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"notifyLetters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetSchedulerReferralProgramsQuery__
 *
 * To run a query within a React component, call `useGetSchedulerReferralProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchedulerReferralProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchedulerReferralProgramsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSchedulerReferralProgramsQuery(baseOptions: Apollo.QueryHookOptions<GetSchedulerReferralProgramsQuery, GetSchedulerReferralProgramsQueryVariables> & ({ variables: GetSchedulerReferralProgramsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchedulerReferralProgramsQuery, GetSchedulerReferralProgramsQueryVariables>(GetSchedulerReferralProgramsDocument, options);
      }
export function useGetSchedulerReferralProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchedulerReferralProgramsQuery, GetSchedulerReferralProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchedulerReferralProgramsQuery, GetSchedulerReferralProgramsQueryVariables>(GetSchedulerReferralProgramsDocument, options);
        }
export function useGetSchedulerReferralProgramsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSchedulerReferralProgramsQuery, GetSchedulerReferralProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSchedulerReferralProgramsQuery, GetSchedulerReferralProgramsQueryVariables>(GetSchedulerReferralProgramsDocument, options);
        }
export type GetSchedulerReferralProgramsQueryHookResult = ReturnType<typeof useGetSchedulerReferralProgramsQuery>;
export type GetSchedulerReferralProgramsLazyQueryHookResult = ReturnType<typeof useGetSchedulerReferralProgramsLazyQuery>;
export type GetSchedulerReferralProgramsSuspenseQueryHookResult = ReturnType<typeof useGetSchedulerReferralProgramsSuspenseQuery>;
export type GetSchedulerReferralProgramsQueryResult = Apollo.QueryResult<GetSchedulerReferralProgramsQuery, GetSchedulerReferralProgramsQueryVariables>;