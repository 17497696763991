import clsx from 'clsx';
import React, { useCallback } from 'react';
import { ErrorNotification } from '../errorNotification';
import { useMe } from 'utils/api/useMe';
import { HelperText } from '../helperText';

type FormInput = {
  divClassName?: string;
  divStyle?: any;
  label?: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: any;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  value?: string | number;
  type?: string;
  regText?: boolean;
  float?: boolean;
  placeholder?: string;
  id?: string;
  name: string;
  autoComplete?: string;
  pattern?: string;
  maxLength?: number;
  error?: any;
  helperText?: string;
  disabled?: boolean;
  asterisk?: boolean;
  showText?: boolean;
  min?: number;
  max?: number;
  allowIntegerOnly?: boolean; // Новый пропс для контроля проверки целых чисел
};

const SimpleInput = React.memo(
  ({
    divClassName,
    divStyle,
    label,
    required,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    value,
    asterisk = false,
    type = 'text',
    regText,
    float,
    id,
    name,
    pattern,
    autoComplete,
    maxLength,
    error,
    disabled,
    showText,
    min,
    max,
    helperText,
    onKeyDown,
    onKeyUp,
    onPaste,
    allowIntegerOnly = false,
  }: FormInput) => {
    const me = useMe();

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
      onKeyDown && onKeyDown(e);
    };

    const onChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (allowIntegerOnly && value !== '') {
          if (!Number.isInteger(Number(value))) {
            return;
          }
        }

        if (regText) {
          if (/^\d+$/.test(value) || value === '') {
            onChange(e);
          }
          return;
        }
        if (float) {
          if (/^[\d.]+$/.test(value) || value === '') {
            onChange(e);
          }
          return;
        }
        onChange(e);
      },
      [onChange, regText, float, allowIntegerOnly],
    );

    return (
      <>
        {me && showText ? (
          <div className={clsx('flex flex-col gap-y-0.5', divClassName)} style={divStyle}>
            <span className="Subtitle2">{label}</span>
            <span className="Body2 text-smena_text-secondary">{value ? value : 'Отсутствует'}</span>
          </div>
        ) : (
          <div className={clsx('flex flex-col gap-y-0.5', divClassName)} style={divStyle}>
            {Boolean(label) && (
              <label htmlFor={id ? id : name} className="label-primary" title={label}>
                {label} {asterisk ? <span className={clsx(error ? 'text-smena_red' : '')}>*</span> : null}
              </label>
            )}
            <input
              required={required}
              onChange={onChangeHandler}
              onBlur={onBlur}
              onFocus={onFocus}
              onPaste={onPaste}
              placeholder={placeholder}
              onKeyUp={onKeyUp}
              maxLength={maxLength}
              value={value}
              type={type}
              name={name}
              id={id ? id : name}
              min={min}
              max={max}
              pattern={pattern}
              className={error ? 'input-error' : 'input-primary'}
              autoComplete={autoComplete}
              disabled={disabled}
              onKeyDown={onKeyDownHandler}
            />
            {error && <ErrorNotification text={error} />}
            {helperText && !error && <HelperText text={helperText} />}
          </div>
        )}
      </>
    );
  },
);

export default SimpleInput;
