import React from 'react';
import clsx from 'clsx';
import { positionName } from 'utils/helpers/filters/filtersName';
import dayjs from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { UserExperienceFragment } from '../../../user/api/fragments/generated/UserExperience.fragment';
import { useAddPaddingAndBorder, useUserExperienceFirstElement } from '../../hook';
import { useCurrentUser } from 'utils/api/useUser';
import { usePositions } from 'utils/api/usePositions';
import { getImagePath } from 'utils/helpers/get/getImagePath';

interface Props {
  experience: UserExperienceFragment;
}

const ExperienceElement = ({ experience }: Props) => {
  const { user } = useCurrentUser();
  const { positions } = usePositions();
  const logoPath = experience.facility?.facilityGroup?.logo?.path;
  const imagePath = logoPath ? getImagePath(logoPath) : false;
  const { sameFacilitiesIndex } = useUserExperienceFirstElement(experience);

  const addPaddingAndBorder = useAddPaddingAndBorder(experience);

  return (
    <div
      className={clsx(
        'flex flex-col gap-y-3',
        addPaddingAndBorder ? 'pb-6 border-b border-smena_gray-5 last:border-b-0' : '',
      )}
    >
      {sameFacilitiesIndex === 0 ? (
        <div className="flex items-center gap-x-1 Body1 text-smena_text-secondary">
          {imagePath && <img className="h-4 w-4 rounded-full object-contain" src={imagePath} alt="logo" />}
          <span>{experience.facility?.facilityGroup?.name}</span>
          <span>·</span>
          <span>{experience.facility?.name}</span>
        </div>
      ) : null}
      <div className="flex flex-col gap-y-3">
        <div>
          <div className="Caption1 text-smena_text-secondary">
            {dayjs(experience.assignedAt).format(RU_DATE)} –{' '}
            {experience.unassignAt ? dayjs(experience.unassignAt).format(RU_DATE) : 'настоящее время'}
          </div>
          <div className="Subtitle1 text-smena_text flex gap-x-1">
            {getRoleRu(user?.role)}
            <span className="text-smena_text-secondary">({positionName(positions, experience.positionId)})</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceElement;
