import { ShiftStage } from 'generated/graphql';
import { UserShiftsFragment } from 'pages/user/api/fragments/generated/UserShifts.fragment';
import { statusBg } from './scheduleBgStatus';

export const scheduleClassName = ({
  facilityId,
  workpostId,
  resource,
}: {
  facilityId: string;
  workpostId?: string;
  resource: {
    stage: ShiftStage | string;
    id: string;
    authorId: string;
    userId: string;
    facility?: { id: string } | null;
    positionId: string;
    isProbation: boolean;
    scheduleId?: string | null;
  };
  restShifts?: UserShiftsFragment[];
  isMeSupervisor?: boolean;
}) =>
  // shiftId?: string,
  // scheduleId?: string | null
  {
    const { userId, authorId, stage, positionId, facility, isProbation } = resource;

    // const selectedShifts = shiftId === id;
    // const selectedSchedule = scheduleId === resource.scheduleId;

    const newStage = () => {
      if (authorId !== userId) {
        return {
          className: 'bg-smena_orange-extra_light',
          // className: selectedShifts || selectedSchedule ? 'bg-smena_orange' : 'bg-smena_orange-extra_light',
        };
      } else {
        return {
          className: 'bg-smena_yellow-extra_light',
        };
      }
    };

    const shiftDoesntBelongToCondition = () => {
      if (isProbation) {
        if (stage === ShiftStage.Ineligible) {
          return {
            className: 'bg-smena_gray-5',
          };
        }
        if (stage === ShiftStage.Refused) {
          return {
            className: 'bg-smena_red-extra_light',
          };
        }
        return {
          className: 'bg-smena_purple-light',
        };
      }
      if (stage === ShiftStage.New) {
        return {
          className: newStage().className,
        };
      }
      return {
        className: statusBg(stage).className,
      };
    };

    if (!facilityId) {
      return shiftDoesntBelongToCondition();
    }
    if (facility?.id === facilityId) {
      if (!workpostId) return shiftDoesntBelongToCondition();
      if (positionId === workpostId) return shiftDoesntBelongToCondition();
    }
    return {
      className: shiftDoesntBelongToCondition().className + ' opacity-50',
    };
  };
