import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectFacilityGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectFacilityGroupsQuery = { __typename?: 'Query', facilityGroups: Array<{ __typename?: 'FacilityGroup', id: string, name: string }> };


export const SelectFacilityGroupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SelectFacilityGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilityGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useSelectFacilityGroupsQuery__
 *
 * To run a query within a React component, call `useSelectFacilityGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectFacilityGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectFacilityGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectFacilityGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SelectFacilityGroupsQuery, SelectFacilityGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectFacilityGroupsQuery, SelectFacilityGroupsQueryVariables>(SelectFacilityGroupsDocument, options);
      }
export function useSelectFacilityGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectFacilityGroupsQuery, SelectFacilityGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectFacilityGroupsQuery, SelectFacilityGroupsQueryVariables>(SelectFacilityGroupsDocument, options);
        }
export function useSelectFacilityGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelectFacilityGroupsQuery, SelectFacilityGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectFacilityGroupsQuery, SelectFacilityGroupsQueryVariables>(SelectFacilityGroupsDocument, options);
        }
export type SelectFacilityGroupsQueryHookResult = ReturnType<typeof useSelectFacilityGroupsQuery>;
export type SelectFacilityGroupsLazyQueryHookResult = ReturnType<typeof useSelectFacilityGroupsLazyQuery>;
export type SelectFacilityGroupsSuspenseQueryHookResult = ReturnType<typeof useSelectFacilityGroupsSuspenseQuery>;
export type SelectFacilityGroupsQueryResult = Apollo.QueryResult<SelectFacilityGroupsQuery, SelectFacilityGroupsQueryVariables>;