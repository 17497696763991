import React, { useCallback, useEffect } from 'react';

import MainLayout from 'components/layout/main';
import InviteUser from './feature/inviteUser';
import { Metrics } from './components/metrics';
import { useSetUserBrowserTokenMutation } from './api/mutations/generated/SetUserBrowserToken';
import { errorToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { fetchToken, onMessageListener } from 'utils/helpers/firebase';

const MainPage: React.FC = () => {
  const [setUserBrowserToken] = useSetUserBrowserTokenMutation();

  const setTokenHandler = useCallback(
    (token: string) => {
      setUserBrowserToken({
        variables: {
          token,
        },
      }).catch(err => {
        errorToast(errorHandler(err));
      });
    },
    [setUserBrowserToken],
  );

  useEffect(() => {
    fetchToken(setTokenHandler);
    onMessageListener().then((payload: any) => {
      new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
    });
  }, [setTokenHandler]);

  return (
    <MainLayout title="Статистика" bg={'bg-smena_white'}>
      <div className="grid gap-6 ">
        <InviteUser />
        <Metrics />
      </div>
    </MainLayout>
  );
};

export default MainPage;
