import { create } from 'zustand';

interface Props {
  showFeedbackPopup: boolean;
  setShowFeedbackPopup: (value: boolean) => void;
}

export const useMenuStore = create<Props>(set => ({
  showFeedbackPopup: false,
  setShowFeedbackPopup: showFeedbackPopup => set({ showFeedbackPopup }),
}));
