import { create } from 'zustand';

type MapState = {
  lat?: number;
  setLat: (lat: number) => void;
  lon?: number;
  setLon: (lon: number) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  center: number[];
  setCenter: (center: number[]) => void;
};

export const useCityMap = create<MapState>(set => ({
  lat: undefined,
  setLat: lat => set({ lat }),
  lon: undefined,
  setLon: lon => set({ lon }),
  zoom: 10,
  setZoom: zoom => set({ zoom }),
  center: [59.94044552268284, 30.314362834341292],
  setCenter: center => set({ center }),
}));
