import { ApolloCache } from '@apollo/client';
import { AddDirectorToFacilityGroupMutation } from '../../../user/api/mutations/generated/AddDirectorToFacilityGroup';
import { FacilityGroupsFragmentDoc } from '../../../main/api/fragments/generated/FacilityGroups.fragment';

interface Props {
  groupId: string;
  data?: AddDirectorToFacilityGroupMutation | null;
}

export const addDirectorToFacilityGroupCache = (cache: ApolloCache<any>, { data, groupId }: Props) => {
  const groupFragment = cache.readFragment({
    id: `FacilityGroup:${groupId}`,
    fragment: FacilityGroupsFragmentDoc,
    fragmentName: 'FacilityGroups',
  });
  cache.modify({
    id: `FacilityGroup:${groupId}`,
    fields: {
      clientDirectors(prev = []) {
        return [...prev, data?.addDirectorToFacilityGroup];
      },
    },
  });
  cache.modify({
    id: `RatingUser:${data?.addDirectorToFacilityGroup.id}`,
    fields: {
      groupsDirector(prev = []) {
        return [...prev, groupFragment];
      },
    },
  });
};
