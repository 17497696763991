import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FakeCodeMutationVariables = Types.Exact<{
  phone: Types.Scalars['String']['input'];
}>;


export type FakeCodeMutation = { __typename?: 'Mutation', fakeCode: string };


export const FakeCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FakeCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phone"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fakeCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phone"}}}]}]}}]} as unknown as DocumentNode;
export type FakeCodeMutationFn = Apollo.MutationFunction<FakeCodeMutation, FakeCodeMutationVariables>;

/**
 * __useFakeCodeMutation__
 *
 * To run a mutation, you first call `useFakeCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFakeCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fakeCodeMutation, { data, loading, error }] = useFakeCodeMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useFakeCodeMutation(baseOptions?: Apollo.MutationHookOptions<FakeCodeMutation, FakeCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FakeCodeMutation, FakeCodeMutationVariables>(FakeCodeDocument, options);
      }
export type FakeCodeMutationHookResult = ReturnType<typeof useFakeCodeMutation>;
export type FakeCodeMutationResult = Apollo.MutationResult<FakeCodeMutation>;
export type FakeCodeMutationOptions = Apollo.BaseMutationOptions<FakeCodeMutation, FakeCodeMutationVariables>;