export const autoRateColor = (rate?: number) => {
  if (!rate) return '';
  if (rate < 3) {
    return 'text-smena_red';
  } else if (rate < 4) {
    return 'text-smena_yellow-dark';
  } else if (rate <= 5) {
    return 'text-smena_green-dark';
  }
  return '';
};
