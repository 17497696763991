import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { PositionFragmentDoc } from '../../fragments/generated/Position.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PositionQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type PositionQuery = { __typename?: 'Query', getPosition: { __typename?: 'Position', id: string, externalId?: string | null, name: string, isSelfEmployed: boolean } };


export const PositionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Position"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPosition"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Position"}}]}}]}},...PositionFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __usePositionQuery__
 *
 * To run a query within a React component, call `usePositionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePositionQuery(baseOptions: Apollo.QueryHookOptions<PositionQuery, PositionQueryVariables> & ({ variables: PositionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositionQuery, PositionQueryVariables>(PositionDocument, options);
      }
export function usePositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositionQuery, PositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositionQuery, PositionQueryVariables>(PositionDocument, options);
        }
export function usePositionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PositionQuery, PositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PositionQuery, PositionQueryVariables>(PositionDocument, options);
        }
export type PositionQueryHookResult = ReturnType<typeof usePositionQuery>;
export type PositionLazyQueryHookResult = ReturnType<typeof usePositionLazyQuery>;
export type PositionSuspenseQueryHookResult = ReturnType<typeof usePositionSuspenseQuery>;
export type PositionQueryResult = Apollo.QueryResult<PositionQuery, PositionQueryVariables>;