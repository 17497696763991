import LeadsPage from 'pages/leads';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StocksPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/offers/leads');
  }, [navigate]);
  return <LeadsPage />;
};

export default StocksPage;
