import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveStatusFreeRequestShiftMutationVariables = Types.Exact<{
  input: Types.RemoveStatusFreeRequestShiftInput;
}>;


export type RemoveStatusFreeRequestShiftMutation = { __typename?: 'Mutation', removeStatusFreeRequestShift: boolean };


export const RemoveStatusFreeRequestShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveStatusFreeRequestShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveStatusFreeRequestShiftInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeStatusFreeRequestShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type RemoveStatusFreeRequestShiftMutationFn = Apollo.MutationFunction<RemoveStatusFreeRequestShiftMutation, RemoveStatusFreeRequestShiftMutationVariables>;

/**
 * __useRemoveStatusFreeRequestShiftMutation__
 *
 * To run a mutation, you first call `useRemoveStatusFreeRequestShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStatusFreeRequestShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStatusFreeRequestShiftMutation, { data, loading, error }] = useRemoveStatusFreeRequestShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStatusFreeRequestShiftMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStatusFreeRequestShiftMutation, RemoveStatusFreeRequestShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStatusFreeRequestShiftMutation, RemoveStatusFreeRequestShiftMutationVariables>(RemoveStatusFreeRequestShiftDocument, options);
      }
export type RemoveStatusFreeRequestShiftMutationHookResult = ReturnType<typeof useRemoveStatusFreeRequestShiftMutation>;
export type RemoveStatusFreeRequestShiftMutationResult = Apollo.MutationResult<RemoveStatusFreeRequestShiftMutation>;
export type RemoveStatusFreeRequestShiftMutationOptions = Apollo.BaseMutationOptions<RemoveStatusFreeRequestShiftMutation, RemoveStatusFreeRequestShiftMutationVariables>;