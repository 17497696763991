import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlanCasingRateMutationVariables = Types.Exact<{
  input: Types.PlanCasingRateInput;
}>;


export type PlanCasingRateMutation = { __typename?: 'Mutation', planCasingRate: { __typename?: 'CasingRateEntity', id: string } };


export const PlanCasingRateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PlanCasingRate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PlanCasingRateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"planCasingRate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type PlanCasingRateMutationFn = Apollo.MutationFunction<PlanCasingRateMutation, PlanCasingRateMutationVariables>;

/**
 * __usePlanCasingRateMutation__
 *
 * To run a mutation, you first call `usePlanCasingRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanCasingRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planCasingRateMutation, { data, loading, error }] = usePlanCasingRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlanCasingRateMutation(baseOptions?: Apollo.MutationHookOptions<PlanCasingRateMutation, PlanCasingRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanCasingRateMutation, PlanCasingRateMutationVariables>(PlanCasingRateDocument, options);
      }
export type PlanCasingRateMutationHookResult = ReturnType<typeof usePlanCasingRateMutation>;
export type PlanCasingRateMutationResult = Apollo.MutationResult<PlanCasingRateMutation>;
export type PlanCasingRateMutationOptions = Apollo.BaseMutationOptions<PlanCasingRateMutation, PlanCasingRateMutationVariables>;